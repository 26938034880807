import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import { LoginActions } from '../../store/actions/LoginActions';
import {
  LoginResponse,
  LoginPayload,
  RegisterClientResponse,
  RegisterUserPayload,
  UserCredential,
} from '../../services/login/LoginData';
import localStorage from '../../services/LocalStorage';
import {
  clearStorage,
  checkPreviousURL,
  registerClient,
  loginClient,
  redirect,
} from './LoginUtils';
import { useStyles } from './LoginStyles';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import { PropertyDetailsResponse } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import Welcome from './components/welcome/Welcome';
import LoginForm from './components/loginForm/LoginForm';
import SignupForm from './components/signupForm/SignupForm';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import ForgotPasswordForm from './components/forgotPasswordForm/ForgotPasswordForm';
import { MatchParams } from '../../helper/URLHelper';
import AboutSorted from './components/aboutSorted/AboutSorted';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { LABELS, TRIANGLES } from './LoginConstants';
import Triangle from './components/triangle/Triangle';
import VerifyOtp from './components/verifyOtp/VerifyOtp';

interface LoginProps extends RouteComponentProps<MatchParams> {
  masterprofile: SubmittedApplicationResponse;
  propertyDetails: PropertyDetailsResponse;
  loginDetails: LoginResponse | undefined;
  registerClientDetails: RegisterClientResponse | undefined;
  postRegisterClient: () => void;
  refreshMasterProfile: () => void;
}

export enum View {
  Login,
  Signup,
  Verify,
  ForgotPassword,
  VerifyOtp,
}

const Login: FC<LoginProps> = ({
  masterprofile,
  history,
  location,
  match,
  loginDetails,
  registerClientDetails,
  postRegisterClient,
  refreshMasterProfile,
}) => {
  const classes = useStyles();
  const [view, setView] = useState<View>(View.Login);
  const [userDetails, setUserDetails] = useState<RegisterUserPayload | null>(null);
  const [credentials, setCredentials] = useState<UserCredential | null>(null);

  useEffect(() => {
    clearStorage();

    // TODO: move this out to an higher up auth handler
    const clientId = localStorage.getItem('clientId');
    if (!clientId || clientId === 'undefined') {
      postRegisterClient();
    } else {
      const clientSecret = localStorage.getItem('clientSecret');
      const basicAuthHash = window.btoa(`${clientId}:${clientSecret}`);
      localStorage.setItem('authTokenBasic', basicAuthHash);
    }

    checkPreviousURL();
  }, []);

  useEffect(() => {
    registerClient(registerClientDetails);
    loginClient(loginDetails);
    if (loginDetails) {
      refreshMasterProfile();
    }
  }, [registerClientDetails, loginDetails]);

  useEffect(() => {
    if (masterprofile) redirect(history, location);
  }, [masterprofile]);

  const renderView = () => {
    switch (view) {
      case View.Login:
        return (
          <LoginForm
            userDetails={userDetails}
            setView={(aView: View) => setView(aView)}
            setCredentials={setCredentials}
          />
        );
      case View.VerifyOtp:
        return (
          <VerifyOtp
            credentials={credentials}
            userDetails={userDetails}
            setView={(aView: View) => setView(aView)}
          />
        );
      case View.Signup:
        return (
          <SignupForm
            userDetails={userDetails}
            setUserDetails={(details: RegisterUserPayload) => setUserDetails(details)}
            setView={(aView: View) => setView(aView)}
          />
        );
      case View.Verify:
        return <VerifyEmail userDetails={userDetails} setView={(aView: View) => setView(aView)} />;
      case View.ForgotPassword:
        return (
          <ForgotPasswordForm userDetails={userDetails} setView={(aView: View) => setView(aView)} />
        );
      default:
        return (
          <LoginForm
            userDetails={userDetails}
            setView={(aView: View) => setView(aView)}
            setCredentials={setCredentials}
          />
        );
    }
  };

  return (
    <div>
      <div className={classes.trianglesContainer}>
        {TRIANGLES.map((t, idx) => (
          <Triangle key={idx} size={t.size} rotation={t.rotation} parentStyles={t.styles} />
        ))}
      </div>
      <div className={classes.content}>
        <Welcome location={location} match={match} view={view} />

        <div className={classes.pageContainer}>{renderView()}</div>
      </div>
      <AboutSorted />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  masterprofile: state.masterProfile.masterProfileData,
  loginDetails: state.login.loginDetails,
  registerClientDetails: state.login.registerClientDetails,
  propertyDetails: state.dashboard.propertyDetails as PropertyDetailsResponse,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postRegisterClient: () => {
    dispatch(LoginActions.postRegisterClientStart());
  },
  refreshMasterProfile: () => {
    dispatch(LoginActions.refreshMasterProfile());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
