import { AddressComponents } from '../services/remoteSigning/equifax/Equifax';
import { GooglePlaceResult } from './GooglePlacesHelper';

export class AddressValue {
  public static format(value: AddressValue | undefined | null): string {
    if (value) {
      return value.place && value.place.formatted_address
        ? value.place.formatted_address
        : value.inputValue || '';
    } else {
      return '';
    }
  }

  public static equals(
    a: AddressValue | undefined | null,
    b: AddressValue | undefined | null,
  ): boolean {
    return AddressValue.format(a) === AddressValue.format(b);
  }

  public static isNullOrEmpty(value: AddressValue): boolean {
    return !AddressValue.format(value);
  }

  public readonly inputValue: string | undefined | null;
  public readonly place: GooglePlaceResult | undefined | null;
}

export const getAddressStringFromAddressComponents = <T extends AddressComponents>(
  addressComponents: T,
): string => {
  const { unitNumber, streetNumber, streetName, suburb, state, postcode, country, POBoxNumber } =
    addressComponents;

  // Construct the address string
  let address = '';

  // Handle unit number and street number
  if (unitNumber && streetNumber) {
    address += `${unitNumber}/${streetNumber}`;
  } else if (streetNumber) {
    address += streetNumber;
  }

  // Add street name if available
  if (streetName) {
    address += ` ${streetName}`;
  }

  // Add suburb if available
  if (suburb) {
    address += `, ${suburb}`;
  }

  // Add state if available
  if (state) {
    address += `, ${state}`;
  }

  // Add postcode if available
  if (postcode) {
    address += ` ${postcode}`;
  }

  // Add country if available
  if (country) {
    address += `, ${country}`;
  }

  // If POBox is present, construct a simpler POBox address string
  if (POBoxNumber) {
    address = `${POBoxNumber}, ${suburb || ''} ${state || ''} ${postcode || ''}`;
  }

  // Remove any leading/trailing spaces or commas
  return address.trim().replace(/\s*,\s*/g, ', ');
};
