export const LABELS = {
  newRequest: 'New maintenance request',
  property: 'Property',
  submit: 'Submit',
  userDetails: 'User details',
  track: 'Track',
};

export interface MRFormProperty {
  address: string;
  agencyId: number;
  branchId: number;
  country: string;
  id: number;
  postcode: string;
  refId: string;
  state: string;
  streetName: string;
  streetNumber: string;
  suburb: string;
  type: string;
  unitNumber: number | null;
}
