import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './NTDBannerStyles';
import { LABELS, VARIANT_1, VARIANT_2 } from './NTDBannerConstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import Text from '../text/Text';
import Button from '../button/Button';
import { classNameGenerator } from '../../theme/GlobalStyles';
import Sidebar from '../sidebar/Sidebar';
import { Dialog } from '@mui/material';
import AddCard from '../addCard/AddCard';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { PaymentActions, PaymentActionTypes } from '../../store/actions/PaymentActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { CreditCardRequest } from '../../services/remoteSigning/payment/Payment';
import { EquifaxActions, EquifaxActionTypes } from '../../store/actions/EquifaxActions';
import { EquifaxResponseDictionary, NTDRequest } from '../../models/equifax/equifax';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import {
  canCompleteNTDBanner,
  formNTDRequest,
  setNtdPurchasedToTrue,
} from '../../helper/NTDHelper';
import { ApplicationsResponse } from '../../services/groups/Groups';
import { theme } from '../../theme/Theme';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';
import { AdditionalPersonalDetailsScreenActions } from '../../store/actions/AdditionalPersonalDetailsAction';
import { routes } from '../../Routes';
import { PROFILE_FORMS as ProfileForm } from '../../constants/AppConstants';
import NTDSidebar from '../ntdSidebar/NTDSidebar';

interface NTDBannerProps extends RouteComponentProps {
  variant?: number;
  small?: boolean;
  applicantProfile: ApplicantProfile;
  index?: number;
  groupApplications: ApplicationsResponse | undefined;
}

const NTDBanner: FC<NTDBannerProps> = ({
  variant,
  small,
  applicantProfile,
  index,
  groupApplications,
}) => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const classes = useStyles();
  const selectedVariant = useMemo(() => {
    switch (variant) {
      case 1:
        return VARIANT_1;
      case 2:
        return VARIANT_2;
      default:
        return VARIANT_1;
    }
  }, [variant]);

  return (
    <>
      <div className={classNameGenerator([classes.ntdBanner, small && classes.smallNtdBanner])}>
        <div
          className={classNameGenerator([
            classes.ntdLeftContent,
            small && classes.smallNtdLeftContent,
          ])}
        >
          <Text
            textVariant="title"
            parentStyles={classNameGenerator([classes.ntdTitle, small && classes.smallNtdTitle])}
          >
            {selectedVariant.title}
          </Text>
          <Text
            textVariant="info"
            parentStyles={classNameGenerator([
              classes.ntdSubtitle,
              small && classes.smallNtdSubtitle,
            ])}
          >
            {selectedVariant.subtitle}
          </Text>
          <Button
            onPress={() => setOpenSidebar(true)}
            parentStyles={classNameGenerator([classes.ntdButton, small && classes.smallNtdButton])}
          >
            {canCompleteNTDBanner(applicantProfile, groupApplications)
              ? LABELS.GET_STARTED
              : LABELS.LEARN_MORE}
          </Button>
        </div>
        <div className={classes.ntdImageBackground}>
          <img
            src={selectedVariant.banner}
            className={classNameGenerator([classes.ntdLeftImage, small && classes.smallLeftImage])}
            alt="overlay"
          />
          <img src={selectedVariant.bannerImage} className={classes.ntdRightImage} alt="welcome" />
        </div>
      </div>
      <NTDSidebar
        openSidebar={openSidebar}
        setOpenSidebar={(value: boolean) => setOpenSidebar(value)}
        applicantProfile={applicantProfile}
        index={index}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NTDBanner));
