import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './SearchBarStyles';
import { ApplicationState } from '../../store/RootReducers';
import Input from '../input/Input';
import InputSelect from '../inputSelect/InputSelect';
import { AgencyLimited } from '../../models/agencies/Agencies';
import { GroupResponse } from '../../services/groups/Groups';
import Gravatars from '../gravatars/Gravatars';
import Button from '../button/Button';
import { ClickAwayListener } from '@mui/material';
import { classNameGenerator } from '../../theme/GlobalStyles';
import search from '../../assets/navigation/search.png';
import { routes } from '../../Routes';
import { LABELS } from './SearchBarConstants';
import { GroupsActions } from '../../store/actions/GroupsActions';
import { UpdatingType } from '../../services/dashboard/updating/Updating';
import { DashboardActions, DashboardActionTypes } from '../../store/actions/DashboardActions';
import { LandingScreenActions } from '../../store/actions/LandingScreenAction';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';
import { PropertyDetailsResponse } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { loadingSelector } from '../../store/selectors/LoadingSelector';

interface SearchBarProps {
  history: History;
  agencies: AgencyLimited[] | undefined;
  groups: GroupResponse[] | undefined;
  propertiesOption: PropertyDetailsResponse[];
  selectedProperties: PropertyDetailsResponse[] | undefined;
  loading: boolean;
  propertyDetails: PropertyDetailsResponse;
  setGroupRefId: (groupRefId: string | null) => void;
  clearProperties: (dashboardToo?: boolean) => void;
  setIsUpdating: (isUpdating: UpdatingType) => void;
  setIsAcceptingInvitation: (isAcceptingInvitation: boolean) => void;
  setMyAccountFlag: (value: boolean) => void;
  getProperties: (address: string, agency: string, branch?: string) => void;
  getPropertyDetails: (data: PropertyDetailsResponse) => void;
}

const SearchBar: FC<SearchBarProps> = ({
  history,
  agencies,
  groups,
  propertiesOption,
  selectedProperties,
  loading,
  propertyDetails,
  setGroupRefId,
  setIsUpdating,
  setIsAcceptingInvitation,
  clearProperties,
  setMyAccountFlag,
  getProperties,
  getPropertyDetails,
}) => {
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [transitionDelay, setTransitionDelay] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchAgency, setSearchAgency] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const classes = useStyles();

  const selectedAgency = useMemo<AgencyLimited | null>(() => {
    if (searchAgency) {
      return agencies!.find((a) => a.tradingName === searchAgency)!;
    } else {
      return null;
    }
  }, [searchAgency, agencies]);

  const searchAddress = () => {
    if (searchText && selectedAgency) {
      getProperties(searchText, selectedAgency.code, '');
    }
  };

  const handleSearch = () => {
    let url = `${routes.properties.view(selectedAgency!.code)}?searchText=${searchText}`;
    history.push(url);
    setTimeout(() => {
      setSearchActive(false);
    }, 200);
    clearProperties(true);
    setMyAccountFlag(false);
    setIsAcceptingInvitation(false);
    setIsUpdating(false);
  };

  useEffect(() => {
    if (searchText.length > 3 && selectedAgency) {
      setMenuOpen(true);
      searchAddress();
    }
  }, [searchText, selectedAgency]);

  useEffect(() => {
    if (searchActive) {
      setTimeout(() => {
        setTransitionDelay(true);
      }, 1000);
    } else {
      setTransitionDelay(false);
    }
  }, [searchActive]);

  const getPropertiesOptions = () => {
    if (propertiesOption && propertiesOption.length) {
      return propertiesOption
        .map((p: PropertyDetailsResponse) => ({
          value: p,
          label: p.address,
        }))
        .filter((d: { label: string }) =>
          selectedProperties === undefined
            ? true
            : !selectedProperties.filter((u: PropertyDetailsResponse) => u.address === d.label)
                .length,
        );
    }
    return [];
  };

  const onPropertySelect = (property: { value: PropertyDetailsResponse }) => {
    if (property) {
      property.value.inspectionDate = null;
      property.value.preferredMoveInDate = null;
      property.value.years = 1;
      property.value.months = 0;
      clearProperties();
      getPropertyDetails(property.value);
      history.push(routes.properties.view(selectedAgency!.code));
      setTimeout(() => {
        setSearchActive(false);
      }, 200);
      setMyAccountFlag(false);
      setIsAcceptingInvitation(false);
      setIsUpdating(false);
      setMenuOpen(false);
    } else {
      setSearchText('');
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setSearchActive(false)}>
      <div
        className={classNameGenerator([
          classes.searchContainer,
          searchActive && classes.searchActive,
        ])}
        onClick={() => setSearchActive(true)}
      >
        <img className={classes.searchIcon} src={search} alt="search" />
        <div
          className={classes.content}
          style={{
            width: `${searchActive ? 820 : 0}px`,
            opacity: searchActive ? 1 : 0,
            overflow: searchActive && transitionDelay ? 'unset' : 'hidden',
          }}
        >
          <Input
            placeholder="Search for a property..."
            value={searchText}
            setValue={setSearchText}
            parentStyles={classes.searchInputContainer}
            parentInputStyles={classes.searchInputInner}
            hideEndElement
          />
          <div className={classes.divider} />

          {selectedAgency && (
            <img src={selectedAgency.circularLogo!} className={classes.agency} alt="agency" />
          )}
          {agencies && (
            <InputSelect
              placeholder="Select a agency"
              value={selectedAgency ? selectedAgency.tradingName : ''}
              values={agencies
                .filter(
                  (a) => a.tradingName && a.allowedPortals && a.allowedPortals.includes('apply'),
                )
                .map((a) => ({ display: a.tradingName!, value: a.tradingName! }))}
              setValue={(value) => setSearchAgency(value as string)}
              parentStyles={classes.searchInputContainer}
              parentInputStyles={classes.searchInputInner}
              hideEndElement
              sort
            />
          )}
          {/* <div className={classes.divider} />
          {selectedGroup && (
            <div className={classes.groupsContainer}>
              <Gravatars group={selectedGroup} parentStyles={classes.gravatar} />
            </div>
          )}
          {groups && (
            <InputSelect
              placeholder="Select a group"
              value={selectedGroup ? selectedGroup.groupName : ''}
              values={groups.map((a) => ({ display: a.groupName, value: a.groupRefId }))}
              setValue={(value) => {
                setGroupRefId(value as string);
                setSearchGroup(value as string);
              }}
              parentStyles={classes.searchInputContainer}
              parentInputStyles={classes.searchInputInner}
              hideEndElement
            />
          )} */}
          <Button
            parentStyles={classes.searchButton}
            disabled={!(!!searchAgency && !!searchText)}
            onPress={() => handleSearch()}
          >
            {LABELS.SEARCH}
          </Button>
        </div>
        {loading ? (
          <div className={classes.menu}>
            <div className={classes.noOptionsMsg}>{LABELS.LOADING}</div>
          </div>
        ) : (
          <>
            {!!getPropertiesOptions().length && menuOpen && (
              <ClickAwayListener
                onClickAway={() => {
                  setSearchText('');
                  clearProperties();
                  setMenuOpen(false);
                }}
              >
                <div className={classes.menu}>
                  {getPropertiesOptions().map((o, idx) => (
                    <div key={idx} className={classes.menuItem} onClick={() => onPropertySelect(o)}>
                      {o.label}
                    </div>
                  ))}
                </div>
              </ClickAwayListener>
            )}
            {propertiesOption && !propertiesOption.length && menuOpen && (
              <ClickAwayListener
                onClickAway={() => {
                  setSearchText('');
                  setMenuOpen(false);
                }}
              >
                <div className={classes.menu}>
                  <div className={classes.noOptionsMsg}>{LABELS.NOT_FOUND}</div>
                </div>
              </ClickAwayListener>
            )}
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

const loading = loadingSelector([DashboardActionTypes.FETCH_PROPERTIES]);

const mapStateToProps = (state: ApplicationState) => ({
  selectedProperties: state.landingScreen.properties,
  propertiesOption: state.dashboard.properties!,
  propertyDetails: state.dashboard.propertyDetails,
  agencies: state.searchState.agencies,
  groups: state.groups.groups,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
  setGroupRefId: (groupRefId: string | null) => {
    dispatch(GroupsActions.setSelectedGroupRef(groupRefId));
  },
  setIsUpdating: (isUpdating: UpdatingType) => {
    dispatch(DashboardActions.setIsUpdating(isUpdating));
  },
  setIsAcceptingInvitation: (isAcceptingInvitation: boolean) => {
    dispatch(DashboardActions.setIsAcceptingInvitation(isAcceptingInvitation));
  },
  clearProperties: (dashboardToo?: boolean) => {
    dispatch(LandingScreenActions.clearProperties());
    if (dashboardToo) {
      dispatch(DashboardActions.clearPropertyDetails());
    }
  },
  getProperties: (address: string, agency: string, branch?: string) => {
    dispatch(
      DashboardActions.getProperties({
        agency,
        branch,
        address,
      }),
    );
  },
  getPropertyDetails: (data: PropertyDetailsResponse) => {
    dispatch(DashboardActions.onGetPropertySuccess(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
