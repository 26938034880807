/* eslint-disable import/prefer-default-export */
export const replaceVicTerms = (stringToChange: string): string => {
  let stringToReplace = stringToChange;
  if (stringToReplace !== undefined) {
    stringToReplace = stringToReplace.replace('/Landlord/g', 'Rental Provider');
    stringToReplace = stringToReplace.replace('/landlord/g', 'rental provider');
    stringToReplace = stringToReplace.replace('/Landlords/g', 'Rental Providers');
    stringToReplace = stringToReplace.replace('/landlords/g', 'rental providers');
    stringToReplace = stringToReplace.replace('/Tenant/g', 'Renter');
    stringToReplace = stringToReplace.replace('/tenant/g', 'renter');
    stringToReplace = stringToReplace.replace('/Tenants/g', 'Renters');
    stringToReplace = stringToReplace.replace('/tenants/g', 'renters');
  }

  return stringToReplace;
};
