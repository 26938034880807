/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { Formik } from 'formik';
import { Dialog, TextField, MenuItem } from '@mui/material';
import { StreetTypeHelper } from '../../helper/StreetTypeHelper';
import { useStyles } from './EditAddressModalStyles';
import { STATES } from '../../services/dashboard/australiaStates/australiaStates';
import { ManualAddressFormData, ManualAddressSchema } from './EditAddressModalConstants';
import { ManualAddressData } from '../../store/state/MyProfileFormState';
import InputSelect from '../inputSelect/InputSelect';
import Input from '../input/Input';
import Button from '../button/Button';
import Text from '../text/Text';

interface OnSubmitReturn {
  address: ManualAddressData;
  idx: number;
}

interface EditAddressModalProps {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (data: OnSubmitReturn) => void;
  initialData: OnSubmitReturn;
}

const { fields } = ManualAddressFormData.metadata;

const EditAddressModal: FC<EditAddressModalProps> = ({ open, onClose, onSubmit, initialData }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.modalBackgroundStyle}>
      <Formik
        initialValues={new ManualAddressFormData(initialData.address)}
        validationSchema={ManualAddressSchema}
        onSubmit={(values, actions) => {
          if (onSubmit) {
            const dataClone = { ...values };
            onSubmit({
              idx: initialData.idx,
              address: {
                ...dataClone,
              },
            });
            actions.resetForm();
          }
        }}
      >
        {({ handleSubmit, setFieldTouched, values, errors, touched, setFieldValue }) => (
          <div className={classes.modalContainerStyle}>
            <Text textVariant="title">Manual address</Text>
            <div className={classes.twoRowStyle}>
              <Input
                placeholder="Unit Number"
                value={values.unitNumber || ''}
                setValue={(value: string) => {
                  setFieldValue(`unitNumber`, value);
                }}
                onBlur={() => setFieldTouched('unitNumber')}
                touched={touched.unitNumber}
                error={errors.unitNumber}
                title="Unit Number"
                mandatory
              />

              <Input
                placeholder="Street Number"
                value={values.streetNumber || ''}
                setValue={(value: string) => {
                  setFieldValue(`streetNumber`, value);
                }}
                onBlur={() => setFieldTouched('streetNumber')}
                touched={touched.streetNumber}
                error={errors.streetNumber}
                title="Street Number"
                mandatory
                parentStyles={classes.secondInput}
              />
            </div>

            <div className={classes.twoRowStyle}>
              <Input
                placeholder="Street Name"
                value={values.streetName || ''}
                setValue={(value: string) => {
                  setFieldValue(`streetName`, value);
                }}
                onBlur={() => setFieldTouched('streetName')}
                touched={touched.streetName}
                error={errors.streetName}
                title="Street Name"
                mandatory
              />
              <Input
                placeholder="Street Type"
                value={values.streetType || ''}
                setValue={(value: string) => {
                  setFieldValue(`streetType`, value);
                }}
                onBlur={() => setFieldTouched('streetType')}
                touched={touched.streetType}
                error={errors.streetType}
                title="Street Type"
                mandatory
                parentStyles={classes.secondInput}
              />
            </div>
            <div className={classes.twoRowStyle}>
              <Input
                placeholder="Suburb"
                value={values.suburb || ''}
                setValue={(value: string) => {
                  setFieldValue(`suburb`, value);
                }}
                onBlur={() => setFieldTouched('suburb')}
                touched={touched.suburb}
                error={errors.suburb}
                title="Suburb"
                mandatory
              />

              {values.country.toLowerCase().trim() === 'australia' ? (
                <InputSelect
                  placeholder="State"
                  values={STATES.map((v) => ({
                    display: v,
                    value: v,
                  }))}
                  value={values.state || ''}
                  setValue={(value: string) => {
                    setFieldValue(`state`, value);
                  }}
                  onBlur={() => setFieldTouched('state')}
                  touched={touched.state}
                  error={errors.state}
                  title="State"
                  mandatory
                  parentStyles={classes.secondInput}
                />
              ) : (
                <Input
                  placeholder="State"
                  value={values.state || ''}
                  setValue={(value: string) => {
                    setFieldValue(`state`, value);
                  }}
                  onBlur={() => setFieldTouched('state')}
                  touched={touched.state}
                  error={errors.state}
                  title="State"
                  mandatory
                  parentStyles={classes.secondInput}
                />
              )}
            </div>

            <div className={classes.twoRowStyle}>
              <Input
                placeholder="Postcode"
                value={values.postcode || ''}
                setValue={(value: string) => {
                  setFieldValue(`postcode`, value);
                }}
                onBlur={() => setFieldTouched('postcode')}
                touched={touched.postcode}
                error={errors.postcode}
                title="Postcode"
                mandatory
              />

              <Input
                placeholder="Country"
                value={values.country || ''}
                setValue={(value: string) => {
                  setFieldValue(`country`, value);
                }}
                onBlur={() => setFieldTouched('country')}
                touched={touched.country}
                error={errors.country}
                title="Country"
                mandatory
                parentStyles={classes.secondInput}
              />
            </div>

            {/* Button Section */}
            <div className={classes.buttonContainerStyle}>
              <Button outline onPress={() => onClose!()} parentStyles={classes.cancelButtonStyle}>
                Cancel
              </Button>

              <div className={classes.spacerStyle} />

              <Button onPress={() => handleSubmit()} parentStyles={classes.okButtonStyle}>
                Done
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditAddressModal;
