import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './BackArrowStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../text/Text';
import backArrow from '../../assets/navigation/arrow-left.png';
import { LABELS } from './BackArrowConstants';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface BackArrowProps extends RouteComponentProps {
  label?: string;
  smallGap?: boolean;
  onBack?: () => void;
}

const BackArrow: FC<BackArrowProps> = ({ history, label, smallGap, onBack }) => {
  const classes = useStyles();
  return (
    <div
      className={classNameGenerator([classes.backRow, smallGap && classes.smallGap])}
      onClick={() => (onBack ? onBack() : history.goBack())}
    >
      <img className={classes.backArrow} src={backArrow} alt="back arrow" />
      <Text textVariant="link" size="l">
        {label || LABELS.BACK}
      </Text>
    </div>
  );
};

export default withRouter(BackArrow);
