export enum AccountTabs {
  APPLICATIONS = `My Applications`,
  ACCOUNT = `My Account`,
}

export const LABELS = {
  YOUR_APPLICATIONS: 'Your applications',
  SHOWING: 'Showing',
  DRAFTS: 'Drafts',
  ADD_DRAFT: '+ Add draft',
  SUBMITTED: 'Submitted',
  INVITES: 'Invites',
  UPDATE: 'Update',
  EDIT: 'Edit',
  DELETE: 'Delete',
  WITHDRAW: 'Withdraw',
  HOT: 'This property is hot',
  CONTACT_INFO: 'Contact info',
  DECLINE: 'Decline invitation',
  ACCEPT: 'Accept',
  ACCEPT_ALL: 'Accept all',
  INFO_REQUESTED: 'Info requested',
  SYNC_PROFILE: 'Sync profile',
  SYNC_ALL: 'Sync all',
  DID_YOU_INSPECT: 'Have you inspected this property?',
  INSPECTION_DATE: 'When did you inspect this property?',
  PREFERRED_MOVE_IN_DATE: 'Preferred move in date',
  PREFERRED_LEASE_DURATION: 'Preferred lease duration',
};

export const failedStatuses = ['Unsuccessful', 'Withdrawn', 'Cancelled', 'Declined'];
export const notInFlightStatus = ['Unsuccessful', 'Withdrawn', 'Accepted', 'Cancelled', 'Declined'];
export enum ResponseType {
  INVITE,
  DRAFT,
  SUBMITTED,
}

export enum ApplicationFilter {
  ALL = 'All',
  INVITED = 'Invited',
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
}
export const CONTACT_INFO = 'Contact info';

export const BOXES = [
  {
    styles: {
      width: 100,
      height: 100,
      right: 500,
      top: -40,
    },
  },
  {
    styles: {
      width: 250,
      height: 250,
      right: 240,
      top: -60,
    },
  },
  {
    styles: {
      width: 320,
      height: 320,
      right: 50,
      top: 140,
    },
  },
  {
    styles: {
      width: 200,
      height: 200,
      right: -60,
      top: 10,
    },
  },
];
