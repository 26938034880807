import { StoreAction } from '../StoreHelper';
import { store } from '../Store';

export enum SuccessToastActionTypes {
  ShowSuccessToast = 'SHOW_SUCCESS_TOAST',
  HideSuccessToast = 'HIDE_SUCCESS_TOAST',
  ShowErrorToast = 'SHOW_ERROR_TOAST',
}

export type SuccessToastAction = StoreAction<SuccessToastActionTypes, string | null>;

export class SuccessToastActions {
  public static show(message: null | string = 'Your details have been saved') {
    return { type: SuccessToastActionTypes.ShowSuccessToast, data: message };
  }

  public static hide() {
    store.dispatch({
      type: SuccessToastActionTypes.HideSuccessToast,
      data: null,
    });
  }

  public static showError(message = 'Something went wrong!') {
    store.dispatch({
      type: SuccessToastActionTypes.ShowErrorToast,
      data: message,
    });
  }
}
