import * as Yup from 'yup';
import { StateOfIssueDL, VehicleTypes } from '../../../../store/state/MyProfileFormState';
import { AustraliaState } from '../../../../services/dashboard/australiaStates/australiaStates';

export const LABELS = {
  VEHICLES: 'Vehicles',
  NUMBER_OF_VEHICLES: 'Number of Vehicles',
  BUTTON_TEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  ADD_ANOTHER_VEHICLE: '+ Add another vehicle',
  VEHICLE: 'Vehicle',
  REMOVE: 'Remove',
  MINIMUM_NUMBER: 'Cannot be less than 0',
  HAVE_VEHICLES: 'Do you have vehicles?',
  TYPE: 'Vehicle Type',
  YEAR: 'Year',
  MAKE: 'Make',
  MODEL: 'Model',
  STATE: 'State',
  REGISTRATION_NUMBER: 'Registration Number',
  VEHICLE_DOCUMENTATION: 'Attach additional documents (PDF, JPG, PNG) 10MB',
  VEHICLE_DOCUMENTATION_DESCRIPTION: "Please add vehicle's documentation/application",
};

export const ERRORS = {
  invalidNameFormat: 'Invalid name format',
  maxLengthForOtherPets: 'Can be at most 30 characters',
  maxStringLength: 'Can be at most 7 characters',
  minimumNoOfVehicles: 'Cannot be less than 0',
  registrationError: 'Invalid registration number',
  required: 'Required',
  stateError: 'Please select a state',
  typeError: 'Please select a type',
  yearError: 'Invalid year',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  vehicles: Yup.array().when('hasVehicles', {
    hasVehicles: Yup.boolean(),
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          make: Yup.string().nullable(),
          model: Yup.string().nullable(),
          registrationNumber: Yup.string().max(7, ERRORS.maxStringLength).nullable(),
          state: Yup.string()
            .oneOf([null, '', ...StateOfIssueDL.map((s) => s.value)], ERRORS.stateError)
            .nullable(),
          type: Yup.string()
            .oneOf(
              [null, '', ...Object.keys(VehicleTypes).map((i) => VehicleTypes[i])],
              ERRORS.typeError,
            )
            .nullable(),
          year: Yup.string()
            .max(4)
            .matches(/^[12][0-9]{3}$/, ERRORS.yearError)
            .nullable(),
        }),
      )
      .when('hasVehicles', {
        is: false,
        then: Yup.array().of(
          Yup.object().shape({
            make: Yup.string().nullable(),
            model: Yup.boolean().nullable(),
            registrationNumber: Yup.string().nullable(),
            state: Yup.string().nullable(),
            type: Yup.string().nullable(),
            year: Yup.string().nullable(),
          }),
        ),
      }),
  }),
});
