import * as Yup from 'yup';
import { ManualAddressData } from '../../store/state/MyProfileFormState';

export const ManualAddressSchema = Yup.object({
  streetNumber: Yup.string().required('Please enter a street number'),
  streetName: Yup.string().required('Please enter a street name'),
  suburb: Yup.string().required('Please enter a suburb'),
  state: Yup.string().required('Please enter a state'),
  postcode: Yup.string().min(4, 'At least 4 characters').required('Please enter a postcode'),
  country: Yup.string().required('Please enter a country'),
});

export class ManualAddressFormData {
  public static readonly metadata = {
    fields: {
      unitNumber: 'unitNumber',
      streetName: 'streetName',
      suburb: 'suburb',
      postcode: 'postcode',
      streetNumber: 'streetNumber',
      state: 'state',
      country: 'country',
      streetType: 'streetType',
    },
  };

  public unitNumber = '';
  public streetName = '';
  public suburb = '';
  public postcode = '';
  public streetNumber = '';
  public streetType = '';
  public state = '';
  public country = '';

  constructor(data: ManualAddressData = {} as ManualAddressData) {
    if (data.unitNumber) this.unitNumber = data.unitNumber;
    if (data.streetName) this.streetName = data.streetName;
    if (data.suburb) this.suburb = data.suburb;
    if (data.postcode) this.postcode = data.postcode;
    if (data.streetNumber) this.streetNumber = data.streetNumber;
    if (data.streetType) this.streetType = data.streetType;

    if (data.state) this.state = data.state;
    else this.state = 'ACT';

    if (data.country) this.country = data.country;
    else this.country = 'Australia';
  }
}
