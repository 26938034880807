import * as Yup from 'yup';
import { NAME_PATTERN, NUMBER_PATTERN, EMAIL_PATTERN } from '../../../../constants/AppConstants';
import { ManualAddressData } from '../../../../store/state/MyProfileFormState';
import { store } from '../../../../store/Store';

export const LABELS = {
  EMPLOYMENT: 'Employment',
  RECENT_EMPLOYMENT: 'Most recent employment',
  EMPLOYMENT_HISTORY: 'Employment History',
  PREVIOUS_EMPLOYMENT: 'Previous employment ',
  HAVE_EMPLOYMENT_HISTORY: 'Do you have any current or past employment references?',
  WHEN_DID_YOU_REQUEST: 'When did you start?',
  WHEN_DID_YOU_FINISH: 'When did you finish?',
  STILL_IN_THE_JOB: 'Still in the job?',
  COMPANY_NAME: 'Company name',
  AND: 'Company name & ACN/ABN number',
  POSITION: 'Position ',
  GROSS_ANNUAL_SALARY: 'Gross Annual Salary',
  MANAGER_NAME: 'Manager name',
  ACCOUNTANT_NAME: 'Accountant name',
  CONTACT_NUMBER: 'Contact number',
  COPY_OF_EMPLOYMENT_LETTER: 'Copy of employment letter (PDF, JPG, PNG) 10MB',
  COPY_OF_EMPLOYMENT_INCOME: 'Proof of self employment income (PDF, JPG, PNG) 10MB',
  ADD_EMPLOYMENT: '+ Add employment',
  FILE_SIZE_ERROR: 'Upload failed. File size cannot exceed 10MB.',
  RECOMMENDATION: 'Show at least the past two years of employment to boost your chances.',
  BUTTON_TEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  REMOVE: 'Remove',
  EMAIL_ADDRESS: 'Email',
  MANDATORY:
    'This section is mandatory to fill out if you have relevant income listed in the previous section',
  REFERENCE_ATTACHED: 'Reference attached',
  NO_RESPONSE_GIVEN: 'No response given',
  REFERENCE_ATTACHED_MESSAGE: 'Reference is attached to this employment record',
  NO_RESPONSE_GIVEN_MESSAGE: `Your reference did not respond. You might want to contact them 
    directly to ensure they are receiving the request.`,
  WARNING_TEXT: `Your employer will be contacted as a reference to confirm the details you have provided. 
    The reference will be linked to your application profile and used when submitting this and future 
    applications. You will receive confirmation when it is linked. Please note, only agencies you submit 
    applications to will have access to the responses provided by your reference.`,
  ADDRESS: 'Address',
};

export enum SubmissionStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
}

export const ERRORS = {
  required: 'Required',
  invalidMonthAndYearFormat: 'Invalid month and year',
  invalidEmailFormat: 'Invalid email format',
  invalidNameFormat: 'Invalid name format',
  invalidCountryNumber: 'Invalid phone number',
  minGrossSalary: 'Cannot be less than 1',
  maxStringLength: 'Can be at most 255 characters',
  requiredEmail: 'Please provide an email address',
  requiredPosition: 'Please provide your position',
  requiredSalary: 'Please provide your salary',
  requiredStartDate: 'Please provide a start date',
  requiredManagerName: 'Please provide manager name',
  requiredPhoneNumber: 'Please provide the phone number',
  selfReferenced: 'Cannot add yourself as a reference',
  otherReferenced: 'You cannot add someone as a reference who you are also applying with',
  requiredAddress: 'Please provide the address',
  manually: 'Missing required fields. Please fill in manually',
};

const { tenantDetails } = store.getState().landingScreen;

export const isValidAddressComponent = ({
  postcode,
  state,
  streetName,
  streetNumber,
  suburb,
}: ManualAddressData) => {
  if (!postcode || !state || !streetName || !streetNumber || !suburb) return false;
  return true;
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  employments: Yup.array().when('hasBeenEmployed', {
    hasBeenEmployed: Yup.boolean(),
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        inJob: Yup.boolean(),
        jobStartMonthAndYear: Yup.string().nullable(),
        grossAnnualSalary: Yup.string().matches(NUMBER_PATTERN, ERRORS.minGrossSalary).nullable(),
        // .required(ERRORS.requiredSalary),
        address: Yup.string().max(255, ERRORS.maxStringLength).nullable(),
        // .required(ERRORS.requiredAddress),
        companyName: Yup.string()
          .when('inJob', {
            is: true,
            then: Yup.string().max(255, ERRORS.maxStringLength).nullable(),
          })
          .when('inJob', {
            is: false,
            then: Yup.string().max(255, ERRORS.maxStringLength).nullable(),
          }),
        position: Yup.string()
          .when('inJob', {
            is: true,
            then: Yup.string()
              .max(255, ERRORS.maxStringLength)
              .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
              .nullable(),
          })
          .when('inJob', {
            is: false,
            then: Yup.string()
              .max(255, ERRORS.maxStringLength)
              .nullable()
              .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
          }),
        // .required(ERRORS.requiredPosition),
        managerName: Yup.string()
          .max(255, ERRORS.maxStringLength)
          .nullable()
          .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
        // .required(ERRORS.requiredManagerName),
        managerContactNumber: Yup.string()
          .when('inJob', {
            is: true,
            then: Yup.string().nullable(),
          })
          .when('inJob', {
            is: false,
            then: Yup.string().nullable(),
          }),
        email: Yup.string()
          .max(255, ERRORS.maxStringLength)
          .nullable()
          // .required(ERRORS.requiredEmail)
          .test('value', ERRORS.selfReferenced, (value) => {
            if (tenantDetails) {
              return value !== tenantDetails.email;
            }
            return true;
          })
          .test('value', ERRORS.otherReferenced, (value) => {
            let result = true;
            if (tenantDetails) {
              tenantDetails.otherApplicants.map((other) => {
                result = value !== other.email;
              });
            }
            return result;
          })
          .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
      }),
    ),
  }),
});
