import { ServiceType } from '../ServiceType.data';
import ApiService from '../TenancyApiService';
import ApiServiceBase from '../TenancyApiServiceBase';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
  ProcurementCallbackRequest,
} from './Marketplace';

export class MarketplaceService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.Services);

  public getAvailableServices(
    payload: AvailableServicesRequest,
  ): Promise<AvailableServicesResponse> | AvailableServicesResponse {
    return this.service.get<AvailableServicesResponse>({
      query: { postcode: payload.postcode, branchCode: payload.branchCode },
    });
  }

  public postProcurementCallback(payload: ProcurementCallbackRequest): Promise<void> | void {
    return this.service.post(
      {
        route: ['procurement-callback'],
      },
      payload,
    );
  }
}

const marketplaceService = new MarketplaceService();
export default marketplaceService;
