/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Formik } from 'formik';
import { History } from 'history';
import { useStyles } from './LoginFormStyles';
import { VALIDATION_SCHEMA, LABELS, WHY_SORTED } from './LoginFormConstants';
import visibilityOff from '../../../../assets/outline-visibility-off-24-px.svg';
import visibilityOn from '../../../../assets/outline-remove-red-eye-24-px.svg';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import {
  LoginPayload,
  RegisterUserPayload,
  UserCredential,
  VerificationLinkPayload,
} from '../../../../services/login/LoginData';
import UserConfirmationDialog from '../../../../component/userConfirmationDialog/UserConfirmationDialog';
import { LoginActions, LoginActionTypes } from '../../../../store/actions/LoginActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { View } from '../../Login';
import { theme } from '../../../../theme/Theme';
import Input from '../../../../component/input/Input';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import Button from '../../../../component/button/Button';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import waitingImage from '../../../../assets/waiting-image.jpg';
import WhileYouWait from '../../../dashboard/components/WhileYouWait/WhileYouWait';

interface LoginFormProps {
  loginError: any;
  userDetails: RegisterUserPayload | null;
  loading: boolean;
  setView: (view: View) => void;
  sendVerificationEmail: (data: VerificationLinkPayload) => void;
  postLogin: (data: LoginPayload) => void;
  clearErrors: () => void;
  setCredentials: (value: UserCredential | null) => void;
}

const LoginForm: FC<LoginFormProps> = ({
  loginError,
  userDetails,
  loading,
  sendVerificationEmail,
  postLogin,
  setView,
  clearErrors,
  setCredentials,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState<string>(
    'A new confirmation email has been sent!',
  );
  const [confirmDialogContent, setConfirmDialogContent] = useState<JSX.Element>(
    <div>Please verify your account by clicking the link in the email within 24 hours.</div>,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [verifyError, setVerifyError] = useState<string | null>(null);

  const resendEmail = (email: string) => {
    sendVerificationEmail({ email });
    setConfirmDialogTitle('A new confirmation email has been sent!');
    setConfirmDialogContent(
      <div>Please verify your account by clicking the link in the email within 24 hours.</div>,
    );
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (loginError && loginError.response) {
      if (loginError.response.status === 401) {
        setErrorMessage('Incorrect email or password');
      } else if (loginError.response.status === 400) {
        setVerifyError(
          'Your email hasn’t been verified. Check your email to verify or resend the link.',
        );
      }
    } else if (loginError?.name === 'PartialContent') {
      setView(View.VerifyOtp);
    } else {
      setErrorMessage(null);

      setVerifyError(null);
    }
  }, [loginError]);

  const showQuestionPopup = () => {
    setConfirmDialogTitle('Start your application');
    setConfirmDialogContent(
      <>
        <div>
          Our applications are powered by <strong>Sorted</strong> - a platform purpose built to make
          renters' lives simpler. With Sorted you can:
        </div>
        <ul>
          <li>Create a profile to apply with for any property with us</li>
          <li>Easily apply with co-applicants</li>
          <li>Set up your household - from paying rent to connecting services, all in one place</li>
        </ul>
      </>,
    );
    setOpenConfirmDialog(true);
  };

  const onSubmit = (value: UserCredential) => {
    setCredentials(value);
    postLogin(value);
  };

  return (
    <>
      <div className={classes.titleContainer}>
        <img src={sortedIcon} className={classes.icon} />
        <div className={classes.primaryTitle}>{LABELS.LOG_INTO_SORTED}</div>
      </div>
      <div className={classes.pageContent}>
        <div className={classes.primaryContainer}>
          <Formik
            initialValues={{
              username: userDetails && userDetails.email ? userDetails.email : '',
              password: '',
            }}
            validateOnChange
            onSubmit={onSubmit}
            validationSchema={VALIDATION_SCHEMA}
            render={({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Text textVariant="boxTitle">{LABELS.LOGIN_BELOW}</Text>
                <Box>
                  <div className={classes.subtitleRow}>
                    <Text textVariant="info">{LABELS.ENTER}</Text>
                    <HelpOutlineIcon
                      className={classes.questionMark}
                      onMouseDown={() => showQuestionPopup()}
                    />
                  </div>
                  <div className={classes.inputRow}>
                    {/* EMAIL ----------------------------------------------------------------- */}
                    <Input
                      placeholder={LABELS.EMAIL}
                      key={LABELS.EMAIL}
                      value={values.username}
                      setValue={(value: string) => setFieldValue('username', value)}
                      onBlur={() => setFieldTouched('username')}
                      touched={touched.username}
                      error={errors.username}
                      title={LABELS.EMAIL}
                      mandatory
                    />
                    {/* PASSWORD -------------------------------------------------------------- */}
                    <Input
                      placeholder={LABELS.PASSWORD}
                      key={LABELS.PASSWORD}
                      value={values.password}
                      setValue={(value: string) => setFieldValue('password', value)}
                      onBlur={() => setFieldTouched('password')}
                      touched={touched.password}
                      error={errors.password}
                      title={LABELS.PASSWORD}
                      parentStyles={classes.secondInput}
                      password={!showPassword}
                      mandatory
                      endElement={
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          <img
                            src={showPassword ? visibilityOff : visibilityOn}
                            alt=""
                            style={{ height: 20, width: 20 }}
                          />
                        </IconButton>
                      }
                    />
                  </div>

                  {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
                  {verifyError && (
                    <div className={classes.errorContainer}>
                      <div className={classes.errorText}>{verifyError}</div>
                      <div
                        onClick={() => resendEmail(values.username)}
                        className={classes.resendButton}
                      >
                        {LABELS.RESEND_LINK}
                      </div>
                    </div>
                  )}
                  <div className={classes.loginRow}>
                    <Text onPress={() => setView(View.ForgotPassword)} textVariant="link">
                      {LABELS.FORGOT}
                    </Text>
                    <Button loading={loading} type="submit" onPress={() => handleSubmit()}>
                      {LABELS.LOGIN}
                    </Button>
                  </div>

                  <div className={classes.signupRow}>
                    <Text textVariant="link">{LABELS.DONT}</Text>
                    <Button
                      outline
                      parentStyles={classes.signupButton}
                      onPress={() => setView(View.Signup)}
                    >
                      {LABELS.SIGNUP}
                    </Button>
                  </div>
                </Box>
              </form>
            )}
          />
        </div>
        <div className={classes.secondaryContainer}>
          <Text textVariant="boxTitle">{LABELS.WHY_SORTED}</Text>
          {WHY_SORTED.map((w, idx) => (
            <div className={classes.welcomeBanner} key={idx}>
              <div className={classes.welcomeLeftContent}>
                <Text textVariant="contentTitle">{w.title}</Text>
                <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
                  {w.body}
                </Text>
              </div>
              <div className={classes.welcomeImageBackground}>
                <img src={w.background} className={classes.welcomeLeftImage} alt="overlay" />
                <img src={w.image} className={classes.welcomeRightImage} alt="welcome" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <UserConfirmationDialog
        isOpen={openConfirmDialog}
        title={confirmDialogTitle}
        content={confirmDialogContent}
        primaryButtonTitle="Okay"
        onPrimaryClick={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
      />
    </>
  );
};

const loading = loadingSelector([LoginActionTypes.LOGIN]);

const mapStateToProps = (state: ApplicationState) => ({
  loginError: state.login.loginError,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearErrors: () => {
    dispatch(LoginActions.clearErrors());
  },
  postLogin: (data: LoginPayload) => {
    dispatch(LoginActions.postLoginStart(data));
  },
  sendVerificationEmail: (data: VerificationLinkPayload) => {
    dispatch(LoginActions.postVerificationLinkStart(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
