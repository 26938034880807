/* eslint-disable import/prefer-default-export */
export const LABELS = {
  WELCOME: 'Welcome onboard!',
  VERIFY_TITLE: 'Next steps',
  VERIFY_SUBTITLE: 'Verify your email',
  VERIFY_SUBTEXT:
    'Before you complete your profile and start applying for properties, we just need to verify your email address.',
  VERIFY_SUBTEXT2:
    "We've emailed you a verification link which you'll need to click within 24 hours.",
  VERIFY_SUBTEXT3:
    "If you can't see the email, check your junk and add 'noreply@sortedservices.com' to your safe senders list. ",
  EMAIL_SENT: 'Email sent to ',
  RESEND: 'Resend',
  SENT: 'Sent',
  INFO_TEXT: "Didn't receive an email? Hit 'resend' and we'll send another.",
  BACK: 'Back',
  VERFIED: 'Verified your email? ',
  LOGIN: 'Login',
};
