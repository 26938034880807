import {
  GetBuildingsResponse,
  GetQuestionsResponse,
  PartnerDetails,
} from '../../models/shortApplication/shortApplication';
import {
  ShortApplicationAction,
  ShortApplicationActionTypes,
} from '../actions/ShortApplicationActions';
import { ShortApplicationState } from '../state/ShortApplicationState';

export const initialState: ShortApplicationState = {
  buildings: undefined,
  sections: undefined,
  success: undefined,
  partner: undefined,
};

export const shortApplicationReducer: (
  state: ShortApplicationState,
  action: ShortApplicationAction,
) => ShortApplicationState = (state = initialState, action) => {
  switch (action.type) {
    case ShortApplicationActionTypes.GET_SHORT_APPLICATION_BUILDINGS_SUCCESS:
      return {
        ...state,
        buildings: action.data as GetBuildingsResponse[],
        success: undefined,
      };

    case ShortApplicationActionTypes.GET_SHORT_APPLICATION_QUESTIONS_SUCCESS:
      return {
        ...state,
        sections: action.data as GetQuestionsResponse[],
        success: undefined,
      };

    case ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_SUCCESS:
      return {
        ...state,
        success: true,
      };

    case ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_RESET:
      return {
        ...state,
        success: undefined,
      };
    case ShortApplicationActionTypes.GET_PARTNER_DETAILS:
      return {
        ...state,
        partnerSuccess: false,
      };
    case ShortApplicationActionTypes.GET_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        partnerLoading: false,
        partnerSuccess: true,
        partner: action.data as PartnerDetails,
      };

    default:
      return state;
  }
};
