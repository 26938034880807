import { makeStyles } from '@mui/styles';
import { theme } from '../../../theme/Theme';
import { SIZES } from '../../../constants/AppConstants';

export const useStyles = makeStyles({
  thankyouScreenRootStyle: {
    background: theme.colors.sortedPurple,
    minHeight: '100vh',
    overflow: 'hidden',
  },
  sortedLogoStyle: {
    display: 'flex',
    padding: '2.563rem 0 3.5rem 2.25rem',
    alignItems: 'center',
  },
  imageStyle: {
    height: '2rem',
  },
  referenceToolStyle: {
    paddingLeft: '1rem',
    fontFamily: 'GT Walsheim',
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.colors.light,
    alignItems: 'center',
    borderLeft: `0.125rem solid ${theme.colors.light}`,
    marginLeft: '1rem',
  },
  thankingRefereeStyle: {
    fontWeight: 500,
    fontSize: '2.8rem',
    color: theme.colors.light,
    fontFamily: 'GT Walsheim',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '2.297rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '1.969rem',
    },
    width: '100%',
    maxWidth: '500px',
  },
  demoBtnWrapperStyle: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.light,
    padding: '0 10.938rem',
    [`@media (max-width: 53.125rem)`]: {
      padding: '0 5.438rem',
      fontSize: '0.875rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      padding: '0 2.25rem',
      fontSize: '0.75rem',
    },
  },
  demoBtnStyle: {
    height: '2.75rem',
    border: `0.125rem solid ${theme.colors.light}`,
    color: theme.colors.light,
    textTransform: 'none',
    fontFamily: 'GT Walsheim',
    fontSize: '0.675rem',
    letterSpacing: '-0.2px',
    width: '100%',
  },
  sortedInfoStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
  },
  knowMoreStyle: {
    fontSize: '0.675rem',
    letterSpacing: '-0.0125rem',
  },
  learnMoreStyle: {
    color: theme.colors.light,
    fontSize: '0.675rem',
    letterSpacing: '-0.0125rem',
    textTransform: 'none',
    fontFamily: 'GT Walsheim',
  },
  dataLoadingStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: theme.colors.sortedPurple,
    color: 'white',
    textAlign: 'center',
  },
  topSectionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '500px',
  },
  textContainer: {
    width: '100%',
    marginRight: '40px',
    marginTop: '2rem',
    marginLeft: '6.25rem',

    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginLeft: '2.25rem',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '2.25rem',
    },
  },
  subtitleText: {
    color: theme.colors.light,
    fontSize: '24px',
    marginTop: '25px',
    marginBottom: '25px',
    maxWidth: '500px',
  },
  bodyText: {
    color: theme.colors.light,
    fontSize: '20px',
    fontWeight: 100,
    maxWidth: '600px',
  },
  unorderedList: {
    // marginTop: '45px',
  },
  listItem: {
    color: theme.colors.light,
    fontSize: '20px',
    fontWeight: 100,
    // marginBottom: 15,
  },
  imageContainer: {
    marginRight: '6.25rem',

    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginRight: '-10.25rem',
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginRight: '-26.25rem',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: '-32.25rem',
      transform: 'rotate(-5deg)',
    },
  },
  imageContainerAgent: {
    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'none',
    },
  },
  image: {
    height: '60vh',
  },
  imageAgent: {
    height: '70vh',
    transform: 'scale(1.3)',
    marginLeft: '0px',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      transform: 'scale(1.1)',
    },
  },
  learnMoreButton: {
    color: theme.colors.light,
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.success,
    borderRadius: '6px',
    width: '220px',
    height: '60px',
    cursor: 'pointer',
    marginTop: '45px',
  },
  middleSection: {
    marginTop: '60px',
    width: '100%',
    backgroundColor: theme.colors.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '70px',
    paddingBottom: '70px',
    flexDirection: 'column',
  },
  changingTheGame: {
    color: theme.colors.sortedPurple,
    fontSize: '24px',
    marginLeft: '15px',
    marginRight: '15px',
  },
  logosContainer: {
    width: '100%',
    maxWidth: '1000px',
    display: 'grid',
    marginTop: '60px',
    gridGap: '45px',
    gridTemplateColumns: 'auto auto auto',
    gridTemplateRows: 'auto auto',
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: 'auto auto',
      gridTemplateRows: 'auto auto auto',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
  },
  logo: {
    height: '70px',
    width: '160px',
    objectFit: 'contain',
  },
  bottomSection: {
    width: '100%',
    backgroundColor: theme.colors.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '60px',
    paddingBottom: '60px',
    flexDirection: 'column',
    borderTop: `${theme.colors.sortedPurple} 20px solid`,
  },
  starsContainer: {
    width: '70px',
    height: '70px',
    borderRadius: 5000,
    overflow: 'hidden',
  },
  stars: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  reviewQuote: {
    color: theme.colors.sortedPurple,
    fontSize: '26px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '600px',
    marginTop: '15px',
    marginBottom: '30px',
  },
  reviewName: {
    color: theme.colors.sortedPurple,
    fontSize: '18px',
    marginBottom: '5px',
  },
  reviewTitle: {
    color: theme.colors.sortedPurple,
    fontSize: '18px',
    fontWeight: 100,
    textAlign: 'center',
    marginBottom: '5px',
  },
  allLogoStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
  playStoreStyle: {
    width: '35%',
    height: '80px',
    objectFit: 'contain',
    maxWidth: '18.75rem',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '20px',
      width: '35%',
    },
    marginLeft: '30px',
  },
  learnMore: {
    color: theme.colors.light,
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 100,
    marginTop: '30px',
    marginBottom: '100px',
  },
  learnMoreUnderline: {
    textDecoration: 'underline',
    color: theme.colors.light,
    fontWeight: 'bold',
    fontSize: '18px',
    cursor: 'pointer',
  },
});
