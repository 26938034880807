import { ThemeOptions, createTheme } from '@mui/material/styles';

import * as defaultTheme from './TenancyTheme';

export const themeOptions: ThemeOptions = {
  ...defaultTheme.themeOptions,

  components: {
    ...defaultTheme.themeOptions.components,
    MuiButton: {
      styleOverrides: {
        ...defaultTheme.themeOptions.components!.MuiButton,
        textPrimary: {
          'backgroundColor': defaultTheme.color.secondary900,
          'color': defaultTheme.color.light,
          'textAlign': 'center',
          'textTransform': 'none',
          'borderRadius': '2px',
          '&:hover': {
            backgroundColor: defaultTheme.color.secondary900Hover,
          },
        },
        text: {
          textTransform: 'none',
        },
        textSecondary: {
          'backgroundColor': defaultTheme.color.dark800,
          'color': defaultTheme.color.light,
          'textAlign': 'center',
          'textTransform': 'none',
          'borderRadius': '2px',
          '&:hover': {
            backgroundColor: defaultTheme.color.dark800Hover,
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
