/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// TODO
// No applications
import React, { FC, useState, useRef, useEffect } from 'react';
import { MenuItem, Popover, Paper, Typography, Dialog } from '@mui/material';
import moment from 'moment';
import { History } from 'history';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import MoreVert from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { ApplicationState } from '../../store/RootReducers';
import { useStyles } from './ApplicationsStyles';
import { routes } from '../../Routes';
import {
  DeleteDraftRequest,
  SubmittedApplicationResponse,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import {
  LABELS,
  failedStatuses,
  notInFlightStatus,
  ResponseType,
  CONTACT_INFO,
  BOXES,
  ApplicationFilter,
} from './ApplicationsConstants';
import localStorage from '../../services/LocalStorage';
import propertyDefaultIcon from '../../assets/homePlaceholder.png';
import petsDefaultIcon from '../../assets/outline-pets-24-px-light.svg';
import updateIcon from '../../assets/refresh.svg';

import { prefillForms } from '../../helper/PrefillFormHelper';
import { DashboardActions, StoreAgencyPayload } from '../../store/actions/DashboardActions';
import { getStatus, isInformationRequested, isSyncRequired } from './ApplicationsUtils';
import SimilarProperties from '../../component/similarProperties/SimilarProperties';
import FooterComponent from '../../component/footer/Footer';
import { GroupsActions, GroupsActionTypes } from '../../store/actions/GroupsActions';
import {
  ApplicationsResponse,
  GroupRequest,
  GroupResponse,
  Application,
  ApplicationProperty,
  AgencyWiseApplication,
  InvitationRequest,
  AgentDetails,
} from '../../services/groups/Groups';
import { getOccupantsString } from '../../helper/GroupHelper';
import WithdrawPopup from './components/WithdrawPopup/WithdrawPopup';
import Gravatars from '../../component/gravatars/Gravatars';
import { LandingScreenActions } from '../../store/actions/LandingScreenAction';
import ContactInfoPopup from './components/ContactInfoPopup/ContactInfoPopup';
import SORTED_CIRCULAR_LOGO from '../../assets/logosCircle/sorted.png';
import { UpdatingType, UPDATING_TYPE } from '../../services/dashboard/updating/Updating';
import { DateFormats } from '../../constants/AppConstants';
import Text from '../../component/text/Text';
import InputSelect from '../../component/inputSelect/InputSelect';
import Box from '../../component/box/Box';
import Button from '../../component/button/Button';
import boxPackage from '../../assets/boxPackage.svg';
import edit from '../../assets/edit.png';
import bedrooms from '../../assets/bed.png';
import bathrooms from '../../assets/bath.png';
import carspaces from '../../assets/car.png';
import hot from '../../assets/hot.png';
import accordian from '../../assets/navigation/accordian.png';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';

interface ApplicationsProps {
  history: History;
  masterProfileData: SubmittedApplicationResponse;
  groups: GroupResponse[] | undefined;
  groupApplications: ApplicationsResponse | undefined;
  loading: boolean;
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any, // Provide type definition when type definition for applications is provided
    propertyRefId: string | null,
  ) => void; // Will replace all the any when the contract is final
  setPrefillHelperCalled: (isCalled: boolean) => void;
  setIsUpdating: (isUpdating: UpdatingType) => void;
  setIsAcceptingInvitation: (isAcceptingInvitation: boolean) => void;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
  getGroupMasterProfile: (data: GroupRequest) => void;
  setGroupRefId: (groupRefId: string | null) => void;
  getAllApplications: () => void;
  clearProperties: () => void;
  declineInvitation: (data: InvitationRequest) => void;
  resetSimilarProperties: () => void;
  deleteDraft: (data: DeleteDraftRequest) => void;
  setMyAccountFlag: (value: boolean) => void;
}

const Applications: FC<ApplicationsProps> = ({
  history,
  masterProfileData,
  groups,
  groupApplications,
  loading,
  prefillData,
  setPrefillHelperCalled,
  setIsUpdating,
  setIsAcceptingInvitation,
  storePropertyAgency,
  getGroupMasterProfile,
  setGroupRefId,
  getAllApplications,
  clearProperties,
  resetSimilarProperties,
  deleteDraft,
  setMyAccountFlag,
}) => {
  const classes = useStyles();
  const [applicationFilter, setApplicationFilter] = useState<ApplicationFilter>(
    ApplicationFilter.ALL,
  );
  const [groupFilter, setGroupFilter] = useState<string>('All');
  const [showWithdrawPopup, setShowWithdrawPopup] = useState<{
    application: Application;
    property: ApplicationProperty | null;
    responseType: ResponseType | string;
    agencyWiseApplication: AgencyWiseApplication;
    groupRefId: string | null;
  } | null>(null);
  const [contactInfoRefId, setContactInfoRefId] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getGroupMasterProfile({});
    getAllApplications();

    // Removes any preexisting properties in case you don't complete the flow
    clearProperties();
    setIsAcceptingInvitation(false);
    setIsUpdating(false);
    setMyAccountFlag(true);
  }, []);

  const handleSubmit = (
    application: Application | null,
    responseType: ResponseType | string,
    agencyWiseApplication: AgencyWiseApplication,
    groupRefId: string | null,
    updatingOne?: boolean,
  ) => {
    const { agency } = agencyWiseApplication;
    localStorage.setItem('landingURL', `/properties/${agency}`);
    storePropertyAgency({ agency });

    let propertyList: any[] = []; // TODO fix old interface
    if (updatingOne) {
      propertyList = application!.propertyList;
    } else if (responseType === ResponseType.DRAFT) {
      localStorage.setItem('draftId', application!.draftId!);
      propertyList = application!.propertyList;
    } else {
      agencyWiseApplication.application.map((app) => {
        app.propertyList.map((property) => {
          if (!notInFlightStatus.includes(getStatus(property.status!))) {
            propertyList.push(property);
          }
        });
      });
    }

    if (groupRefId) {
      setGroupRefId(groupRefId);
    }
    setIsUpdating(
      updatingOne
        ? UPDATING_TYPE.UPDATING
        : responseType === ResponseType.SUBMITTED
        ? UPDATING_TYPE.SYNCING
        : false,
    );
    setIsAcceptingInvitation(responseType === ResponseType.INVITE);
    prefillData(masterProfileData, false, propertyList, null);
    setPrefillHelperCalled(true);
    resetSimilarProperties();
    setMyAccountFlag(false);

    if (
      (responseType === ResponseType.SUBMITTED && updatingOne) ||
      responseType === ResponseType.DRAFT
    ) {
      history.push(routes.properties.view(agency));
    } else if (responseType === ResponseType.SUBMITTED && !updatingOne) {
      history.push(routes.submitProperties.new);
    } else {
      history.push(routes.applicationPreferences.new);
    }
  };

  const renderApplications = (
    agencyWiseApplications: AgencyWiseApplication[],
    responseType: ResponseType,
    groupRefId?: string,
  ) => {
    const foundGroup: GroupResponse | undefined = groups
      ? groups.find((group) => group.groupRefId === groupRefId)
      : undefined;

    const sortedCircularLogo = SORTED_CIRCULAR_LOGO;

    if (groupFilter !== 'All' && (foundGroup === undefined || groupFilter !== foundGroup.groupName))
      return null;

    return (
      <>
        {agencyWiseApplications.map((agency, index) => (
          <div
            className={classNameGenerator([
              responseType !== ResponseType.DRAFT && classes.applicationsSection,
            ])}
            key={index}
          >
            {((responseType === ResponseType.SUBMITTED &&
              (isSyncRequired(agency.application, groupApplications) ||
                isInformationRequested(agency.application, groupApplications))) ||
              responseType === ResponseType.INVITE) && (
              <div className={classes.applicationsRow}>
                <div className={classes.applicationsGroupNameRow}>
                  {/* <Gravatars group={foundGroup!} parentStyles={classes.applicationsGravatars} /> */}
                  <Text parentStyles={classes.applicationsGroupName}>Action required</Text>
                </div>
                <Button
                  onPress={() => handleSubmit(null, responseType, agency, groupRefId || null)}
                >
                  {responseType === ResponseType.SUBMITTED ? LABELS.SYNC_ALL : LABELS.ACCEPT_ALL}
                </Button>
              </div>
            )}
            {agency.application.map((application: Application, appIndex) => {
              const agentDetails = application.propertyList[0]!.agentDetails;
              const branchDetails = application.branch;
              const accordianActive = application.refId === contactInfoRefId;
              return (
                <Box parentStyles={classes.applicationContainer} key={appIndex}>
                  {application.propertyList.map(
                    (property: ApplicationProperty, propertyIndex: number) => (
                      <>
                        <div
                          className={classes.applicationRow}
                          key={propertyIndex}
                          style={
                            propertyIndex === 0
                              ? { border: 'none', paddingTop: 0, marginTop: 0 }
                              : {}
                          }
                        >
                          <div className={classes.propertyContainer}>
                            <img
                              className={classes.property}
                              src={property.photo ? property.photo : propertyDefaultIcon}
                              alt="property"
                            />
                          </div>
                          <div className={classes.applicationDetails}>
                            <div className={classes.applicationDetailsHeader}>
                              {responseType === ResponseType.DRAFT &&
                              property.propertyStatus === 'ARCHIVED' ? (
                                <div
                                  className={`${classes.statusContainer} ${classes.unsuccessfulLabel}`}
                                >
                                  Unavailable
                                </div>
                              ) : (
                                <>
                                  {responseType === ResponseType.DRAFT && (
                                    <Text textVariant="info" parentStyles={classes.lastEditedDate}>
                                      Last edited:{' '}
                                      {moment(application.lastModifiedDate).format(
                                        DateFormats.extendedUiFormat,
                                      )}
                                    </Text>
                                  )}
                                </>
                              )}
                              <div className={classes.submissionRow}>
                                {responseType !== ResponseType.DRAFT && (
                                  <div
                                    className={`${classes.statusContainer} 
                              ${
                                !notInFlightStatus.includes(getStatus(property.status!)) &&
                                (groupApplications!.updateRequired.includes(
                                  property.applicationRefId!,
                                ) ||
                                  groupApplications!.informationRequested.includes(
                                    property.applicationRefId!,
                                  )) &&
                                responseType === ResponseType.SUBMITTED
                                  ? classes.yellowLabel
                                  : failedStatuses.includes(getStatus(property.status!))
                                  ? classes.incompleteLabel
                                  : classes.completeLabel
                              }
                              ${
                                failedStatuses.includes(getStatus(property.status!))
                                  ? classes.unsuccessfulLabel
                                  : undefined
                              }
                            `}
                                  >
                                    {!notInFlightStatus.includes(getStatus(property.status!)) &&
                                    responseType === ResponseType.SUBMITTED &&
                                    (groupApplications!.updateRequired.includes(
                                      property.applicationRefId!,
                                    ) ||
                                      groupApplications!.informationRequested.includes(
                                        property.applicationRefId!,
                                      ))
                                      ? `${
                                          groupApplications!.informationRequested.includes(
                                            property.applicationRefId!,
                                          )
                                            ? 'Info Requested'
                                            : 'Sync Profile'
                                        }`
                                      : getStatus(property.status!)}
                                  </div>
                                )}
                                {responseType === ResponseType.SUBMITTED && (
                                  <Text textVariant="info" parentStyles={classes.submittedDate}>
                                    Submitted:{' '}
                                    {moment(application.createdDate).format(
                                      DateFormats.extendedUiFormat,
                                    )}
                                  </Text>
                                )}
                              </div>
                              {foundGroup && (
                                <div className={classes.gravatarsRow}>
                                  <Text textVariant="info" parentStyles={classes.groupName}>
                                    {foundGroup.groupName}
                                  </Text>
                                  <div className={classes.gravatars}>
                                    <Gravatars group={foundGroup} />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className={classes.applicationDetailsMiddle}>
                              <div className={classes.propertyAddressContainer}>
                                <Text textVariant="title" parentStyles={classes.address1}>
                                  {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                                    property.streetNumber || ''
                                  } ${property.streetName}`}
                                </Text>
                                <Text textVariant="info" parentStyles={classes.address2}>
                                  {`${property.suburb}, ${property.state} ${property.postcode}`}
                                </Text>
                              </div>
                              <div className={classes.rentAndAgencyContainer}>
                                <div className={classes.agencyContainer}>
                                  <img
                                    className={classes.agencyLogo}
                                    onError={(e) => {
                                      (e.target as HTMLImageElement).src = sortedCircularLogo;
                                    }}
                                    src={agency.agencyLogo}
                                    alt="agency logo"
                                  />
                                </div>
                                <div className={classes.rentContainer}>
                                  <Text textVariant="title" parentStyles={classes.rent}>
                                    {property.rentOffer ? `$${property.rentOffer}` : '-'}
                                  </Text>

                                  <Text textVariant="info" parentStyles={classes.rentInfo}>
                                    Rent pw
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <div className={classes.applicationDetailsFooter}>
                              <div className={classes.statsRow}>
                                {!!property.bedrooms && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={bedrooms}
                                      alt="people stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.bedrooms}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        bedrooms
                                      </Text>
                                    </div>
                                  </div>
                                )}
                                {!!property.bathrooms && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={bathrooms}
                                      alt="applications stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.bathrooms}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        bathrooms
                                      </Text>
                                    </div>
                                  </div>
                                )}
                                {!!property.carspaces && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={carspaces}
                                      alt="applications stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.carspaces}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        car spaces
                                      </Text>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* <div className={classes.hotContainer}>
                              <Text textVariant="link" parentStyles={classes.hotText}>
                                {LABELS.HOT}
                              </Text>
                              <img src={hot} className={classes.hotIcon} alt="hot" />
                            </div> */}
                            </div>
                          </div>
                        </div>
                        {responseType === ResponseType.SUBMITTED && (
                          <>
                            <div className={classes.lineRow} />
                            <div className={classes.questionGrid}>
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.DID_YOU_INSPECT}
                                </Text>
                                <Text textVariant="info">{property.inspectionMethod}</Text>
                              </div>
                              {!!property.inspectionDate && (
                                <div className={classes.questionContainer}>
                                  <Text textVariant="contentTitle" size="l">
                                    {LABELS.INSPECTION_DATE}
                                  </Text>
                                  <Text textVariant="info">
                                    {moment(property.inspectionDate, 'DD/MM/YYYY').format(
                                      'DD/MM/YYYY',
                                    )}
                                  </Text>
                                </div>
                              )}
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.PREFERRED_MOVE_IN_DATE}
                                </Text>
                                <Text textVariant="info">
                                  {moment(property.preferredMoveInDate, 'DD/MM/YYYY').format(
                                    'DD/MM/YYYY',
                                  )}
                                </Text>
                              </div>
                              <div className={classes.questionContainer}>
                                <Text textVariant="contentTitle" size="l">
                                  {LABELS.PREFERRED_LEASE_DURATION}
                                </Text>
                                <Text textVariant="info">
                                  {property.years ? `${property.years} year(s) ` : ' '}
                                  {property.months ? `${property.months} months(s) ` : ' '}
                                </Text>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ),
                  )}
                  {(agentDetails ||
                    (responseType === ResponseType.SUBMITTED &&
                      !notInFlightStatus.includes(
                        getStatus(application.propertyList[0].status!),
                      )) ||
                    responseType === ResponseType.INVITE ||
                    responseType === ResponseType.DRAFT) && (
                    <div className={classes.lineRow}>
                      {responseType === ResponseType.INVITE && (
                        <Button
                          parentStyles={classes.buttonMarginRight}
                          outline
                          onPress={() =>
                            setShowWithdrawPopup({
                              application,
                              property: application.propertyList[0],
                              responseType,
                              agencyWiseApplication: agency,
                              groupRefId: groupRefId || null,
                            })
                          }
                        >
                          {LABELS.DECLINE}
                        </Button>
                      )}
                      {responseType === ResponseType.DRAFT && (
                        <>
                          <Button
                            parentStyles={classes.buttonMarginRight}
                            outline
                            onPress={() => deleteDraft({ draftId: application.draftId! })}
                          >
                            {LABELS.DELETE}
                          </Button>
                          {application.propertyList.length === 1 &&
                          application.propertyList.some(
                            (p) => p.propertyStatus === 'ARCHIVED',
                          ) ? null : (
                            <Button
                              parentStyles={classes.buttonMarginRight}
                              onPress={() => handleSubmit(application, responseType, agency, null)}
                            >
                              {LABELS.EDIT}
                            </Button>
                          )}
                        </>
                      )}
                      {responseType === ResponseType.SUBMITTED &&
                        !notInFlightStatus.includes(
                          getStatus(application.propertyList[0].status!),
                        ) && (
                          <>
                            <Button
                              parentStyles={classes.buttonMarginRight}
                              outline
                              onPress={() =>
                                setShowWithdrawPopup({
                                  application,
                                  property: application.propertyList[0],
                                  responseType,
                                  agencyWiseApplication: agency,
                                  groupRefId: groupRefId || null,
                                })
                              }
                            >
                              {LABELS.WITHDRAW}
                            </Button>
                            <Button
                              parentStyles={classes.buttonMarginRight}
                              onPress={() =>
                                handleSubmit(
                                  application,
                                  responseType,
                                  agency,
                                  groupRefId || null,
                                  true,
                                )
                              }
                            >
                              {LABELS.UPDATE}
                            </Button>
                          </>
                        )}
                      {responseType === ResponseType.SUBMITTED && agentDetails && (
                        <div
                          className={classes.actionContainer}
                          onClick={() =>
                            setContactInfoRefId(accordianActive ? null : application.refId!)
                          }
                        >
                          <Text textVariant="link" parentStyles={classes.actionText}>
                            {LABELS.CONTACT_INFO}
                          </Text>
                          <img
                            className={classNameGenerator([
                              classes.actionIcon,
                              accordianActive && classes.actionIconActive,
                            ])}
                            src={accordian}
                            alt="accordian"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {agentDetails && (
                    <div
                      className={classNameGenerator([
                        classes.contactDetailsRow,
                        accordianActive && classes.contactDetailsRowActive,
                      ])}
                    >
                      {agentDetails.name && (
                        <div className={classes.contactDetail}>
                          <PersonOutlinedIcon className={classes.statIcon} />
                          <div className={classes.statTextRow}>
                            <Text textVariant="info" parentStyles={classes.statNumber}>
                              {agentDetails.name}
                            </Text>
                          </div>
                        </div>
                      )}
                      {branchDetails && (
                        <div className={classes.contactDetail}>
                          <PhoneAndroidOutlinedIcon className={classes.statIcon} />
                          <div className={classes.statTextRow}>
                            <Text textVariant="info" parentStyles={classes.statNumber}>
                              {branchDetails.phone}
                            </Text>
                          </div>
                        </div>
                      )}
                      {agentDetails.email && (
                        <div className={classes.contactDetail}>
                          <EmailOutlinedIcon className={classes.statIcon} />
                          <div className={classes.statTextRow}>
                            <Text textVariant="info" parentStyles={classes.statNumber}>
                              {agentDetails.email}
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
              );
            })}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={classes.boxPackageContainer}>
        {BOXES.map((t, idx) => (
          <img
            key={idx}
            src={boxPackage}
            style={t.styles}
            alt="background"
            className={classes.boxPackage}
          />
        ))}
      </div>
      <div className={classes.content}>
        <div className={classes.pageContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{LABELS.YOUR_APPLICATIONS}</div>
          </div>
          <div className={classes.showingFilter}>
            <Text>{LABELS.SHOWING}</Text>
            <div className={classes.filterRow}>
              <InputSelect
                value={
                  applicationFilter === 'All'
                    ? `Applications: ${applicationFilter}`
                    : applicationFilter
                }
                values={Object.values(ApplicationFilter).map((v) => ({ display: v, value: v }))}
                placeholder="All"
                parentStyles={classes.filterStyles}
                parentInputStyles={classes.filterInput}
                setValue={(value: string) => setApplicationFilter(value as ApplicationFilter)}
                hideEndElement
              />
              <div className={classes.filterBreak} />
              {groups !== undefined && (
                <InputSelect
                  value={groupFilter === 'All' ? `Group: ${groupFilter}` : groupFilter}
                  values={groups
                    .map((g) => g.groupName)
                    .concat('All')
                    .map((v) => ({ display: v, value: v }))}
                  placeholder="All"
                  parentStyles={classes.filterStyles}
                  parentInputStyles={classes.filterInput}
                  setValue={(value: string) => setGroupFilter(value)}
                  hideEndElement
                />
              )}
            </div>
          </div>
          {!groupApplications && loading ? (
            <>
              <div className={classes.section}>
                <Text textVariant="boxTitle">{LABELS.INVITES}</Text>
                {[...Array(2).fill(null)].map((_, i) => (
                  <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
                ))}
              </div>
              <div className={classes.section}>
                <Text textVariant="boxTitle">{LABELS.DRAFTS}</Text>
                {[...Array(3).fill(null)].map((_, i) => (
                  <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
                ))}
              </div>
              <div className={classes.section}>
                <Text textVariant="boxTitle">{LABELS.SUBMITTED}</Text>
                {[...Array(4).fill(null)].map((_, i) => (
                  <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
                ))}
              </div>
            </>
          ) : (
            <>
              {groupApplications &&
              (groupApplications.invitedApplicationGroups.groups.length ||
                groupApplications.draftApplications.agencyWiseApplications.length ||
                groupApplications.submittedApplicationGroups.groups.length) ? (
                <>
                  {[ApplicationFilter.ALL, ApplicationFilter.INVITED].includes(applicationFilter) &&
                  groupApplications.invitedApplicationGroups.groups.length ? (
                    <div className={classes.section}>
                      <>
                        {(groupFilter === 'All' ||
                          groupApplications.invitedApplicationGroups.groups
                            .map((g) => g.groupRefId)
                            .includes(groupFilter)) && (
                          <Text textVariant="boxTitle">{LABELS.INVITES}</Text>
                        )}
                        {groupApplications.invitedApplicationGroups.groups.map((group) => (
                          <>
                            {renderApplications(
                              group.agencyWiseApplications,
                              ResponseType.INVITE,
                              group.groupRefId,
                            )}
                          </>
                        ))}
                      </>
                    </div>
                  ) : null}

                  {[ApplicationFilter.ALL, ApplicationFilter.DRAFT].includes(applicationFilter) &&
                  groupApplications.draftApplications.agencyWiseApplications.length ? (
                    <div className={classes.section}>
                      <>
                        {groupFilter === 'All' && (
                          <div className={classes.row}>
                            <Text textVariant="boxTitle">{LABELS.DRAFTS}</Text>
                            <Button
                              parentStyles={classes.addGroup}
                              onPress={() => {
                                history.push(routes.properties.generic);
                              }}
                            >
                              {LABELS.ADD_DRAFT}
                            </Button>
                          </div>
                        )}
                        {renderApplications(
                          groupApplications.draftApplications.agencyWiseApplications,
                          ResponseType.DRAFT,
                        )}
                      </>
                    </div>
                  ) : null}

                  {[ApplicationFilter.ALL, ApplicationFilter.SUBMITTED].includes(
                    applicationFilter,
                  ) && groupApplications.submittedApplicationGroups.groups.length ? (
                    <div className={classes.section}>
                      <>
                        {(groupFilter === 'All' ||
                          groupApplications.submittedApplicationGroups.groups
                            .map((g) => g.groupRefId)
                            .includes(groupFilter)) && (
                          <Text textVariant="boxTitle">{LABELS.SUBMITTED}</Text>
                        )}
                        {groupApplications.submittedApplicationGroups.groups.map((group) => (
                          <>
                            {renderApplications(
                              group.agencyWiseApplications,
                              ResponseType.SUBMITTED,
                              group.groupRefId,
                            )}
                          </>
                        ))}
                      </>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {/* <Typography variant="subtitle1" className={classes.noApplication}>
                  {LABELS.NO_APPLICATIONS}
                </Typography>
                <div className={classes.backBtn}>
                  <p className={classes.nearByPartnerStyle}>{LABELS.NEAR_BY_PARTNERS_TEXT}</p>
                  <FooterComponent
                    buttonText={LABELS.BUTTON_TEXT}
                    onClick={() => window.open(LABELS.NEAR_BY_PARTNERS_URL, '_blank')}
                  />
                </div> */}
                </>
              )}
            </>
          )}
          <Dialog open={!!showWithdrawPopup} maxWidth="md">
            {showWithdrawPopup && showWithdrawPopup.property && (
              <WithdrawPopup
                responseType={showWithdrawPopup.responseType}
                applicationRefId={showWithdrawPopup.application.refId!}
                groupRefId={showWithdrawPopup.groupRefId!}
                propertyAddress={showWithdrawPopup.property.address}
                closeWithdrawPopup={() => setShowWithdrawPopup(null)}
              />
            )}
          </Dialog>
          <SimilarProperties history={history} />
        </div>
      </div>
    </>
  );
};

const loading = loadingSelector([
  GroupsActionTypes.GET_GROUP_MASTER_PROFILE,
  GroupsActionTypes.GET_ALL_APPLICATIONS,
  GroupsActionTypes.DECLINE_INVITATION,
  GroupsActionTypes.WITHDRAW_APPLICATION,
  GroupsActionTypes.WITHDRAW_MYSELF,
]);

const mapStateToProps = (state: ApplicationState) => ({
  masterProfileData: state.masterProfile.masterProfileData!,
  groups: state.groups.groups,
  groupApplications: state.groups.groupApplications,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null = null,
  ) => {
    prefillForms(data, dispatch, isUpdating, propertyList, propertyRefId);
  },
  setPrefillHelperCalled: (isCalled: boolean) => {
    dispatch(DashboardActions.setPrefillHelperCalled(isCalled));
  },
  setIsUpdating: (isUpdating: UpdatingType) => {
    dispatch(DashboardActions.setIsUpdating(isUpdating));
  },
  setIsAcceptingInvitation: (isAcceptingInvitation: boolean) => {
    dispatch(DashboardActions.setIsAcceptingInvitation(isAcceptingInvitation));
  },
  storePropertyAgency: (data: StoreAgencyPayload) => {
    dispatch(
      DashboardActions.storePropertyAgency({ agency: data.agency, branch: data.branch || '' }),
    );
  },
  setGroupRefId: (groupRefId: string | null) => {
    dispatch(GroupsActions.setSelectedGroupRef(groupRefId));
  },
  deleteDraft: (data: DeleteDraftRequest) => {
    dispatch(GroupsActions.deleteDraftRequest(data));
  },
  getGroupMasterProfile: (data: GroupRequest) => {
    dispatch(GroupsActions.getGroupMasterProfileRequest(data));
  },
  getAllApplications: () => {
    dispatch(GroupsActions.getAllApplicationsRequest());
  },
  clearProperties: () => {
    dispatch(LandingScreenActions.clearProperties());
    dispatch(DashboardActions.clearPropertyDetails());
  },
  resetSimilarProperties: () => {
    dispatch(DashboardActions.resetSimilarProperties());
  },
  declineInvitation: (data: InvitationRequest) => {
    dispatch(GroupsActions.declineInvitationRequest(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
