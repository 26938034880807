/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  inputCard: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: theme.units.spacing * 4,
    flexShrink: 0,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
    },
    backgroundColor: theme.colors.inputBackground,
    padding: theme.units.spacing * 2,
    paddingLeft: theme.units.spacing * 3,
    paddingRight: theme.units.spacing * 2,
    cursor: 'pointer',
  },
  inputStyle: {
    border: 'none',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
    },
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    marginRight: theme.units.spacing * 3,
    overflow: 'scroll',
    color: theme.colors.inputText,
  },
  value: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    marginRight: theme.units.spacing * 3,
    whiteSpace: 'nowrap',
    overflow: 'scroll',
  },
  placeholder: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Light'),
    // color: theme.colors.disabledText,
    marginRight: theme.units.spacing * 3,
  },
  accordianContainer: {
    marginLeft: 'auto',
    width: 'max-content',
    height: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    minHeight: 25,
  },
  accordian: {
    width: theme.units.iconSize / 1.8,
    height: theme.units.iconSize / 1.8,
    objectFit: 'contain',
    objectPosition: 'center',
    transition: 'all 0.5s ease 0s',
  },
  accordianActive: {
    transform: 'rotate(180deg)',
    transition: 'all 0.5s ease 0s',
  },
  errorInput: {
    border: `${theme.colors.error} 2px solid`,
  },
  errorTextStyle: {
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
    color: theme.colors.error,
    marginBottom: theme.units.spacing,
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontSize: theme.font.s,
    marginLeft: theme.units.spacing,
    fontWeight: getFonts('Medium'),
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 2,
    width: 'max-content',
    cursor: 'pointer',
  },
  checkBoxStyle: {
    padding: 0,
    marginRight: theme.units.spacing,
  },
});
