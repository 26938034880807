import * as redux from 'redux';
import createSagaMiddleware from 'redux-saga';
import moment from 'moment';
import reducers, { ApplicationState } from './RootReducers';
import localStorage from '../services/LocalStorage';
import LogRocket from 'logrocket';
import sagas from './RootSaga';

function saveToLocalStorage(state: ApplicationState) {
  // Save the redux state to local storage, use the email as the primary key if available
  try {
    const key: string | null = localStorage.getItem('email');
    const serialisedState = JSON.stringify(state);
    const currentDate = moment().format('DD/MM/YYYY');
    localStorage.setItem(key || 'state', serialisedState);
    localStorage.setItem('stateCreatedDate', currentDate);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const key: string | null = localStorage.getItem('email');
    const serialisedState = localStorage.getItem(key || 'state');
    const stateCreatedDate = localStorage.getItem('stateCreatedDate');
    if (serialisedState === null) {
      return undefined;
    }
    if (stateCreatedDate) {
      const date = moment(stateCreatedDate, 'DD/MM/YYYY');
      if (moment().diff(date, 'days') > 60) {
        localStorage.clearAll(); // clearing out the local storage to be fetched again
        window.location.href = `/#/login`; // clearing out the redux store and resetting the app state
      }
    }
    return JSON.parse(serialisedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })
  : redux.compose;
const sagaMiddleware = createSagaMiddleware();

// Creates store and sets it into singleton (Store.ts/store)
const createAppStore = (testing?: boolean | undefined | null) => {
  const persistedStore = loadFromLocalStorage();

  store = redux.createStore(
    reducers,
    persistedStore,
    composeEnhancers(
      redux.applyMiddleware(
        sagaMiddleware,
        /* LogRocket.reduxMiddleware() should be the very last 
      middleware applied, so it can properly log everything */
        LogRocket.reduxMiddleware(),
      ),
    ),
  );

  store.subscribe(() => saveToLocalStorage(store.getState()));

  sagaMiddleware.run(sagas);
  return store;
};

export type AppStore = redux.Store<ApplicationState>;
export let store: AppStore;

// initialize
createAppStore();
