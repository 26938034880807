/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/prefer-default-export */
import { ApplicationsResponse, GroupResponse } from '../services/groups/Groups';

export const getOccupantsString = (group: GroupResponse, name?: boolean): string => {
  let result = '';

  if (name) {
    const primaryFirstname =
      group.primary.profileData.firstName || localStorage.getItem('firstname')!;
    const primaryLastname = group.primary.profileData.lastName || localStorage.getItem('lastname')!;
    result = `${primaryFirstname} ${primaryLastname}`;

    const listOfPeople: string[] = [];

    {
      group.secondary.map((s) => {
        listOfPeople.push(`${s.profileData.firstName} ${s.profileData.lastName}`);
      });
    }
    {
      group.dependents.map((d) => {
        listOfPeople.push(`${d.profileData.firstName} ${d.profileData.lastName}`);
      });
    }
    {
      group.coApplicants.map((co) => {
        listOfPeople.push(`${co.primary.firstName} ${co.primary.lastName}`);

        {
          co.secondary.map((s) => {
            listOfPeople.push(`${s.firstName} ${s.lastName}`);
          });
        }
        {
          co.dependent.map((d) => {
            listOfPeople.push(`${d.firstName} ${d.lastName}`);
          });
        }
      });
    }
    listOfPeople.map((p, idx) => {
      result += `${idx === listOfPeople.length - 1 ? ' and ' : ', '}${p}`;
    });
  } else {
    // There will always be atleast 1 adult
    if (group.adults > 1) {
      result = `${group.adults} Adults`;
    } else {
      result = `${group.adults} Adult`;
    }

    if (group.children) {
      result += ', ';
      if (group.children > 1) {
        result += `${group.children} Children`;
      } else {
        result += `${group.children} Child`;
      }
    }

    if (group.pets) {
      result += ', ';
      if (group.pets > 1) {
        result += `${group.pets} Pets`;
      } else {
        result += `${group.pets} Pet`;
      }
    }
  }

  return result;
};

export const getApplicationsCount = (
  groupRefId: string,
  groupApplications?: ApplicationsResponse,
): number => {
  if (groupApplications && groupApplications.submittedApplicationGroups) {
    return groupApplications.submittedApplicationGroups.groups.filter(
      (g) => g.groupRefId === groupRefId,
    ).length;
  }

  return 0;
};
