import { makeStyles } from '@mui/styles';
import { theme } from '../../../../../theme/Theme';

export const useStyles = makeStyles({
  scrollBox: {
    height: '600px',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: 15,
    marginBottom: 15,
    overflow: 'scroll',
    '&:hover': {
      border: `${theme.colors.buttonPrimary} 2px solid`,
    },
  },
  numberStyle: { color: theme.colors.black60 },
  paragraphs: {
    padding: '10px',
  },
});
