import React, { FC, useState, useEffect } from 'react';
import {
  FormControl,
  FormHelperText,
  // Input,
  InputAdornment,
  InputLabel,
  Snackbar,
} from '@mui/material';
import { useStyles } from './AddBankStyles';
import {
  CommonPaymentSummary,
  MatchParams,
  PaymentSummary,
  InitialValues,
  accountNumberRegex,
  errorMessages,
  bsbValidatorPattern,
  LABELS,
  LINKS,
  lengths,
  accountNameRegex,
  bsbNumberRegex,
  MaskDetails,
} from './AddBankConstants';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../customInput/CustomInput';
import secureIcon from '../../assets/lock-24-px.svg';
import { BankAccountRequest } from '../../services/remoteSigning/payment/Payment';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { PaymentActionTypes } from '../../store/actions/PaymentActions';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import Input from '../input/Input';
import Button from '../button/Button';

interface AddBankProps {
  showSplit: boolean;
  loading: boolean;
  error: any;
  goBack: () => void;
  onSubmit: (bankDetails: BankAccountRequest) => void;
}

const AddBank: FC<AddBankProps> = ({ showSplit, loading, error, goBack, onSubmit }) => {
  const bsbNumberValidator = CustomInput(bsbValidatorPattern);
  const classes = useStyles();
  const [accountNumberError, setAccountNumberError] = useState<boolean>(false);

  const handleSubmit = (values: InitialValues) => {
    const paymentRequest: BankAccountRequest = {
      type: 'BANKACCOUNT',
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      bsbNumber: values.bsbNumber,
      email: '',
    };
    onSubmit(paymentRequest);
  };

  const handleAccountNumber = (accountNumber: string, setFieldValue: any) => {
    setFieldValue('accountNumber', accountNumber.replace(accountNumberRegex, ''));
    if (accountNumber.length > 10) {
      setAccountNumberError(true);
    } else if (accountNumberError) {
      setAccountNumberError(false);
    }
  };

  const initialValues: InitialValues = {
    accountName: '',
    accountNumber: '',
    bsbNumber: '',
    maskedBankAccountDetails: {
      paymentDetails: '',
      logo: '',
    },
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={Yup.object().shape({
          accountName: Yup.string()
            .required(errorMessages.required)
            .max(lengths.accNameMax, errorMessages.accNameLength)
            .matches(accountNameRegex, errorMessages.accName),
          bsbNumber: Yup.string()
            .required(errorMessages.required)
            .matches(bsbNumberRegex, errorMessages.invalidBsb),
          accountNumber: Yup.string()
            .required(errorMessages.required)
            .min(lengths.accNumMin, errorMessages.accNumMin)
            .max(lengths.accNumMax),
        })}
        render={({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
          isValid,
        }) => (
          <>
            <div className={classes.formContainer}>
              <div className={classes.form}>
                <Input
                  placeholder="Account name"
                  value={values.accountName || ''}
                  setValue={(value: string) => {
                    setFieldValue('accountName', value);
                  }}
                  onBlur={() => setFieldTouched('accountName')}
                  touched={touched.accountName}
                  error={errors.accountName}
                  title="Account name"
                  mandatory
                />
                <Input
                  placeholder="BSB Number"
                  value={values.bsbNumber || ''}
                  setValue={(value: string) => {
                    setFieldValue('bsbNumber', value);
                  }}
                  onBlur={() => setFieldTouched('bsbNumber')}
                  touched={touched.bsbNumber}
                  error={errors.bsbNumber}
                  title="BSB Number"
                  mandatory
                />
                <Input
                  placeholder="Account Number"
                  value={values.accountNumber || ''}
                  setValue={(value: string) => {
                    setFieldValue('accountNumber', value);
                  }}
                  onBlur={() => setFieldTouched('accountNumber')}
                  touched={touched.accountNumber}
                  error={errors.accountNumber}
                  title="Account Number"
                  mandatory
                />
              </div>
            </div>
            <div className={classes.infoBox}>
              {LABELS.DIRECT}{' '}
              <span className={classes.link} onClick={() => window.open(LINKS.direct, '_blank')}>
                direct debit service agreement
              </span>{' '}
              and our{' '}
              <span className={classes.link} onClick={() => window.open(LINKS.direct2, '_blank')}>
                terms and conditions
              </span>
            </div>
            <div className={classes.buttonContainer}>
              {showSplit && (
                <Button outline parentStyles={classes.secondaryButton} onPress={() => goBack()}>
                  Back
                </Button>
              )}
              <Button
                parentStyles={error ? classes.errorButton : classes.primaryButton}
                disabled={!isValid || loading}
                onPress={() => {
                  if (isValid) {
                    handleSubmit();
                  }
                }}
              >
                {error || (loading ? 'Processing' : 'Submit')}
              </Button>
            </div>
          </>
        )}
      />
    </>
  );
};

const loading = loadingSelector([
  PaymentActionTypes.GET_PAYMENT_AUTH_KEY,
  PaymentActionTypes.POST_PAYMENT_TOKEN,
]);

const error = errorMessageSelector([
  PaymentActionTypes.GET_PAYMENT_AUTH_KEY,
  PaymentActionTypes.POST_PAYMENT_TOKEN,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  error: error(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddBank);
