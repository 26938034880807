import React, { ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

interface Props {
  readonly handleClose: () => void;
  readonly message: string | null | undefined;
}

export default function SignatureErrorModal(props: Props): ReactElement {
  const { message, handleClose } = props;
  const open = !!message;
  return (
    <Dialog open={open} onBackdropClick={handleClose}>
      <DialogTitle>
        <Typography variant="h5" color="inherit">
          We weren’t able to save your signature.
        </Typography>
      </DialogTitle>
      <DialogContent className="custom-scrollbar">
        <Typography variant="body1" color="inherit">
          Sometimes this is because the internet connection was disrupted. Try again, and if the
          problem continues please wait a few minutes before trying again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" id="beforeSigningClose" onClick={handleClose}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
