export const LABELS = {
  TITLE: "Who's coming?",
  YOUR_GROUPS: 'Your groups',
  MANAGED_PROFILES: 'Your managed profiles',
  FROM_THE_BLOG: 'From the blog',
  NOTIFICATIONS: 'Notifications',
  ADD_GROUP: '+ Create group',
  ADD_MANAGED: '+ Add managed profile',
  BLOG_TITLE: "What's a managed profile?",
  BLOG_BODY:
    'This may be a child or a significant other’s profile, which you are completing on their behalf',
  BLOG_GROUP_TITLE: 'Whats a group?',
  BLOG_GROUP_BODY:
    'Groups are the people that are applying for a property with you; invite friends and family to join you in your rental application',
  BLOG_GROUP_TITLE2: 'Applying alone this time?',
  BLOG_GROUP_BODY2:
    'No worries! To create a group with just yourself, please click on + Create Group and hit save',
  FOR_YOU: 'For you',
  NO_MANAGED: 'You have no profiles that you are managing on behalf of',
  SEE_ALL: (length: number) => `See all (${length})`,
};
