import { StoreAction } from '../StoreHelper';
import {
  PropertyDetailsResponse,
  GetPropertyDetailsFailure,
  AgencyDetails,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import {
  SimilarPropertiesResponse,
  SimilarPropertiesRequest,
} from '../../services/dashboard/similarProperties/GetSimilarProperties';
import {
  ApplyFormPayload,
  BulkUpdateRequest,
  DeleteDraftRequest,
  GetApplicationsPayload,
  PatchTitlePayload,
  SubmittedApplicationResponse,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import {
  AppToApplyTransitionRequest,
  AppToApplyTransitionResponse,
} from '../../services/dashboard/applyTransition/applyTransition';
import { ApplicationState } from '../RootReducers';
import { UpdatingType } from '../../services/dashboard/updating/Updating';

export enum DashboardActionTypes {
  FETCH_PROPERTY_DETAILS = 'FETCH_PROPERTY_DETAILS',
  FETCH_PROPERTY_DETAILS_REQUEST = 'FETCH_PROPERTY_DETAILS_REQUEST',
  FETCH_PROPERTY_DETAILS_SUCCESS = 'FETCH_PROPERTY_DETAILS_SUCCESS',
  FETCH_PROPERTY_DETAILS_ERROR = 'FETCH_PROPERTY_DETAILS_ERROR',
  CLEAR_PROPERTY_DETAILS = 'CLEAR_PROPERTY_DETAILS',

  SET_IS_UPDATING_FLAG = 'SET_IS_UPDATING_FLAG',
  SET_IS_ACCEPTING_INVITATION_FLAG = 'SET_IS_ACCEPTING_INVITATION_FLAG',
  SET_APPLICATION_REF_ID = 'SET_APPLICATION_REF_ID',
  SET_PROPERTY_REF_ID = 'SET_PROPERTY_REF_ID',
  POST_APPLY_SUCCESS = 'POST_APPLY_SUCCESS',

  CLEAR_APPLY_ERROR = 'CLEAR_APPLY_ERROR',

  FETCH_PROPERTIES = 'FETCH_PROPERTIES',
  FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST',
  FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS',
  FETCH_PROPERTIES_ERROR = 'FETCH_PROPERTIES_ERROR',

  POST_PROPERTY_APPLY_REQUEST = 'POST_PROPERTY_APPLY_REQUEST',
  POST_PROPERTY_APPLY_SUCCESS = 'POST_PROPERTY_APPLY_SUCCESS',
  POST_PROPERTY_APPLY_ERROR = 'POST_PROPERTY_APPLY_ERROR',

  POST_APPLY_DRAFT = 'POST_APPLY_DRAFT_REQUEST',
  POST_APPLY_DRAFT_SUCCESS = 'POST_APPLY_DRAFT_SUCCESS',
  POST_APPLY_DRAFT_ERROR = 'POST_APPLY_DRAFT_ERROR',

  STORE_PROPERTY_AGENCY = 'STORE_PROPERTY_AGENCY',

  GET_SUBMITTED_APPLICATION_REQUEST = 'GET_SUBMITTED_APPLICATION_REQUEST',

  GET_APPLICATIONS_REQUSET = 'GET_APPLICATIONS_REQUSET',
  GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS',
  GET_APPLICATIONS_ERROR = 'GET_APPLICATIONS_ERROR',

  PATCH_DRAFT_TITLE_REQUEST = 'PATCH_DRAFT_TITLE_REQUEST',
  PATCH_DRAFT_TITLE_SUCCESS = 'PATCH_DRAFT_TITLE_SUCCESS',
  PATCH_DRAFT_TITLE_ERROR = 'PATCH_DRAFT_TITLE_ERROR',

  GET_PROPERTY_BY_ADDRESS = 'GET_PROPERTY_BY_ADDRESS',
  GET_PROPERTY_BY_ADDRESS_REQUEST = 'GET_PROPERTY_BY_ADDRESS_REQUEST',
  GET_PROPERTY_BY_ADDRESS_SUCCESS = 'GET_PROPERTY_BY_ADDRESS_SUCCESS',
  GET_PROPERTY_BY_ADDRESS_ERROR = 'GET_PROPERTY_BY_ADDRESS_ERROR',

  GET_AGENCY_BY_AGENT_REQUEST = 'GET_AGENCY_BY_AGENT_REQUEST',
  GET_AGENCY_BY_AGENT_SUCCESS = 'GET_AGENCY_BY_AGENT_SUCCESS',
  GET_AGENCY_BY_AGENT_ERROR = 'GET_AGENCY_BY_AGENT_ERROR',

  GET_PROPERTY_BY_REAXML = 'GET_PROPERTY_BY_REAXML',
  GET_PROPERTY_BY_REAXML_REQUEST = 'GET_PROPERTY_BY_REAXML_REQUEST',
  GET_PROPERTY_BY_REAXML_SUCCESS = 'GET_PROPERTY_BY_REAXML_SUCCESS',
  GET_PROPERTY_BY_REAXML_ERROR = 'GET_PROPERTY_BY_REAXML_ERROR',

  GET_PROPERTY_BY_REAXML_SEARCH = 'GET_PROPERTY_BY_REAXML_SEARCH',
  GET_PROPERTY_BY_REAXML_SEARCH_REQUEST = 'GET_PROPERTY_BY_REAXML_SEARCH_REQUEST',
  GET_PROPERTY_BY_REAXML_SEARCH_SUCCESS = 'GET_PROPERTY_BY_REAXML_SEARCH_SUCCESS',
  GET_PROPERTY_BY_REAXML_SEARCH_ERROR = 'GET_PROPERTY_BY_REAXML_SEARCH_ERROR',

  IS_PREFILL_HELPER_CALLED = 'IS_PREFILL_HELPER_CALLED',
  DASHBOARD_CLEAR_PROPERTIES = 'DASHBOARD_CLEAR_PROPERTIES',

  POST_UPDATE_PROPERTY_APPLY_REQUEST = 'POST_UPDATE_PROPERTY_APPLY_REQUEST',
  POST_UPDATE_PROPERTY_APPLY_SUCCESS = 'POST_UPDATE_PROPERTY_APPLY_SUCCESS',
  POST_UPDATE_PROPERTY_APPLY_ERROR = 'POST_UPDATE_PROPERTY_APPLY_ERROR',

  TOGGLE_POP_UP = 'TOGGLE_POP_UP',
  POST_UPDATED_PROPERTIES_TO_TENANCY_REQUEST = 'POST_UPDATED_PROPERTIES_TO_TENANCY_REQUEST',

  GET_SIMILAR_PROPERTIES_REQUEST = 'GET_SIMILAR_PROPERTIES_REQUEST',
  GET_SIMILAR_PROPERTIES_SUCCESS = 'GET_SIMILAR_PROPERTIES_SUCCESS',
  GET_SIMILAR_PROPERTIES_ERROR = 'GET_SIMILAR_PROPERTIES_ERROR',
  RESET_SIMILAR_PROPERTIES = 'RESET_SIMILAR_PROPERTIES',

  SET_SIMILAR_PROPERTY = 'SET_SIMILAR_PROPERTY',

  RESET_SUCCESS = 'RESET_SUCCESS',

  APPTOAPPLY_TRANSITION_REQUEST = 'APPTOAPPLY_TRANSITION_REQUEST',
  APPTOAPPLY_TRANSITION_SUCCESS = 'APPTOAPPLY_TRANSITION_SUCCESS',
  APPTOAPPLY_TRANSITION_ERROR = 'APPTOAPPLY_TRANSITION_ERROR',

  GET_DELETED_INFO_REQUEST = 'GET_DELETED_INFO_REQUEST',
  GET_DELETED_INFO_SUCCESS = 'GET_DELETED_INFO_SUCCESS',
  GET_DELETED_INFO_ERROR = 'GET_DELETED_INFO_ERROR',

  DISMISS_DELETED_INFO_REQUEST = 'DISMISS_DELETED_INFO_REQUEST',
  DISMISS_DELETED_INFO_SUCCESS = 'DISMISS_DELETED_INFO_SUCCESS',
  DISMISS_DELETED_INFO_ERROR = 'DISMISS_DELETED_INFO_ERROR',
}

export interface StoreAgencyPayload {
  agency: string | null | undefined;
  branch?: string | null | undefined;
}

export interface GetPropertiesPayload {
  agency: string | null | undefined;
  branch: string | null | undefined;
  address?: string | null | undefined;
  limit?: number;
}

export interface GetPropertyByAddressPayload {
  agentId: string | null | undefined;
  suburb: string | null | undefined;
  state: string | null | undefined;
  postcode: string | null | undefined;
  streetNumber: string | null | undefined;
  streetName: string | null | undefined;
  unitNumber: string | null | undefined;
}

export interface GetPropertyByReaxmlPayload {
  agentId: string | null | undefined;
  reaxmlId: string | null | undefined;
  type?: string;
}

export interface GetPropertyByReaxmlSearchPayload {
  agencyCode: string;
  reaxmlId: string;
}

export interface GetAgencyByAgentPayload {
  agentId: string | null | undefined;
  type?: string;
  branch?: string | null | undefined;
}

export type DashboardActionPayload =
  | string
  | SubmittedApplicationResponse
  | GetPropertyDetailsFailure
  | PropertyDetailsResponse
  | ApplyFormPayload
  | ApplicationState
  | PropertyDetailsResponse
  | GetPropertiesPayload
  | StoreAgencyPayload
  | PropertyDetailsResponse[]
  | GetApplicationsPayload
  | PatchTitlePayload
  | GetPropertyByAddressPayload
  | GetAgencyByAgentPayload
  | GetPropertyByReaxmlPayload
  | GetPropertyByReaxmlSearchPayload
  | AgencyDetails
  | Error
  | boolean
  | null
  | number
  | UpdatingType
  | BulkUpdateRequest
  | SimilarPropertiesRequest
  | SimilarPropertiesResponse[]
  | SimilarPropertiesResponse
  | AppToApplyTransitionRequest
  | AppToApplyTransitionResponse;

export type DashboardAction = StoreAction<DashboardActionTypes, DashboardActionPayload>;

export class DashboardActions {
  public static doGetPropertyDetails(data: string) {
    return { type: DashboardActionTypes.FETCH_PROPERTY_DETAILS_REQUEST, data };
  }
  public static clearPropertyDetails() {
    return { type: DashboardActionTypes.CLEAR_PROPERTY_DETAILS };
  }
  public static onGetPropertyFailure(data: GetPropertyDetailsFailure) {
    return { type: DashboardActionTypes.FETCH_PROPERTY_DETAILS_ERROR, data };
  }
  public static onGetPropertySuccess(data: PropertyDetailsResponse) {
    return { type: DashboardActionTypes.FETCH_PROPERTY_DETAILS_SUCCESS, data };
  }
  public static postApplySuccess() {
    return { type: DashboardActionTypes.POST_APPLY_SUCCESS };
  }

  public static clearApplyErrror() {
    return { type: DashboardActionTypes.CLEAR_APPLY_ERROR };
  }
  public static getProperties(data: GetPropertiesPayload) {
    return { type: DashboardActionTypes.FETCH_PROPERTIES_REQUEST, data };
  }
  public static getPropertiesFailure(data: GetPropertyDetailsFailure) {
    return { type: DashboardActionTypes.FETCH_PROPERTIES_ERROR, data };
  }
  public static getPropertiesSuccess(data: PropertyDetailsResponse[]) {
    return { type: DashboardActionTypes.FETCH_PROPERTIES_SUCCESS, data };
  }
  public static postPropertyApplyForm(data: ApplicationState) {
    return { type: DashboardActionTypes.POST_PROPERTY_APPLY_REQUEST, data };
  }
  public static postPropertyApplyFailure(data: Error) {
    return { type: DashboardActionTypes.POST_PROPERTY_APPLY_ERROR, data };
  }
  public static postPropertyApplySuccess() {
    return {
      type: DashboardActionTypes.POST_PROPERTY_APPLY_SUCCESS,
      data: null,
    };
  }
  public static postApplyDraft(data: ApplicationState) {
    return {
      type: DashboardActionTypes.POST_APPLY_DRAFT,
      data,
    };
  }
  public static postApplyDraftFailure(data: Error) {
    return { type: DashboardActionTypes.POST_APPLY_DRAFT_ERROR, data };
  }
  public static postApplyDraftSuccess() {
    return {
      type: DashboardActionTypes.POST_APPLY_DRAFT_SUCCESS,
      data: null,
    };
  }

  public static storePropertyAgency(data: StoreAgencyPayload) {
    return { type: DashboardActionTypes.STORE_PROPERTY_AGENCY, data };
  }
  public static getSubmittedApplication() {
    return {
      type: DashboardActionTypes.GET_SUBMITTED_APPLICATION_REQUEST,
    };
  }
  public static getApplicationsStart(data: GetApplicationsPayload) {
    return { type: DashboardActionTypes.GET_APPLICATIONS_REQUSET, data };
  }
  public static getApplicationsSuccess(data: any) {
    return { type: DashboardActionTypes.GET_APPLICATIONS_SUCCESS, data };
  }
  public static getApplicationsError(data: Error) {
    return { type: DashboardActionTypes.GET_APPLICATIONS_ERROR, data };
  }
  public static patchDraftTitle(data: PatchTitlePayload) {
    return { type: DashboardActionTypes.PATCH_DRAFT_TITLE_REQUEST, data };
  }
  public static patchDraftTitleSuccess(data: any) {
    return { type: DashboardActionTypes.PATCH_DRAFT_TITLE_SUCCESS, data };
  }
  public static patchDraftTitleError(data: Error) {
    return { type: DashboardActionTypes.PATCH_DRAFT_TITLE_ERROR, data };
  }
  public static getPropertyByAddress(data: GetPropertyByAddressPayload) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_ADDRESS_REQUEST, data };
  }
  public static getPropertyByAddressFailure(data: any) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_ADDRESS_ERROR, data };
  }
  public static getPropertyByAddressSuccess(data: PropertyDetailsResponse) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_ADDRESS_SUCCESS, data };
  }
  public static getAgencyByAgent(data: GetAgencyByAgentPayload) {
    return { type: DashboardActionTypes.GET_AGENCY_BY_AGENT_REQUEST, data };
  }
  public static getAgencyByAgentFailure(data: any) {
    return { type: DashboardActionTypes.GET_AGENCY_BY_AGENT_ERROR, data };
  }
  public static getAgencyByAgentSuccess(data: AgencyDetails) {
    return { type: DashboardActionTypes.GET_AGENCY_BY_AGENT_SUCCESS, data };
  }
  public static getPropertyByReaxml(data: GetPropertyByReaxmlPayload) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_REAXML_REQUEST, data };
  }
  public static getPropertyByReaxmlSearch(data: GetPropertyByReaxmlSearchPayload) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_REAXML_SEARCH_REQUEST, data };
  }
  public static getPropertyByReaxmlFailure(data: any) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_REAXML_ERROR, data };
  }
  public static getPropertyByReaxmlSuccess(data: PropertyDetailsResponse) {
    return { type: DashboardActionTypes.GET_PROPERTY_BY_REAXML_SUCCESS, data };
  }

  /** this action is made to track that prefiilHelper function will be called once only */
  public static setPrefillHelperCalled(isCalled: boolean) {
    return {
      type: DashboardActionTypes.IS_PREFILL_HELPER_CALLED,
      data: isCalled,
    };
  }
  public static clearProperties() {
    return { type: DashboardActionTypes.DASHBOARD_CLEAR_PROPERTIES };
  }

  public static setIsUpdating(isUpdating: UpdatingType) {
    return {
      type: DashboardActionTypes.SET_IS_UPDATING_FLAG,
      data: isUpdating,
    };
  }

  public static setIsAcceptingInvitation(isAcceptingInvitation: boolean) {
    return {
      type: DashboardActionTypes.SET_IS_ACCEPTING_INVITATION_FLAG,
      data: isAcceptingInvitation,
    };
  }

  public static setApplicationRefId(applicationRefId: string | null) {
    return {
      type: DashboardActionTypes.SET_APPLICATION_REF_ID,
      data: applicationRefId,
    };
  }

  public static setPropertyRefId(propertyRefId: string | null) {
    return {
      type: DashboardActionTypes.SET_PROPERTY_REF_ID,
      data: propertyRefId,
    };
  }

  // Depreciated
  public static postUpdatePropertyApplyForm(data: ApplicationState) {
    return { type: DashboardActionTypes.POST_UPDATE_PROPERTY_APPLY_REQUEST, data };
  }
  public static postUpdatePropertyApplyFailure(data: Error) {
    return { type: DashboardActionTypes.POST_UPDATE_PROPERTY_APPLY_ERROR, data };
  }
  public static postUpdatePropertyApplySuccess() {
    return {
      type: DashboardActionTypes.POST_UPDATE_PROPERTY_APPLY_SUCCESS,
      data: null,
    };
  }

  public static setFoundPropertiesToUpdate(data: boolean) {
    return {
      type: DashboardActionTypes.TOGGLE_POP_UP,
      data,
    };
  }

  public static postUpdatedPropertiesToTenancy(data: BulkUpdateRequest) {
    return {
      type: DashboardActionTypes.POST_UPDATED_PROPERTIES_TO_TENANCY_REQUEST,
      data,
    };
  }

  public static getSimilarPropertiesRequest(data: SimilarPropertiesRequest) {
    return { type: DashboardActionTypes.GET_SIMILAR_PROPERTIES_REQUEST, data };
  }

  public static getSimilarPropertiesSuccess(data: SimilarPropertiesResponse[]) {
    return { type: DashboardActionTypes.GET_SIMILAR_PROPERTIES_SUCCESS, data };
  }

  public static getSimilarPropertiesError(data: Error) {
    return { type: DashboardActionTypes.GET_SIMILAR_PROPERTIES_ERROR, data };
  }

  public static appToApplyTransitionRequest(data: AppToApplyTransitionRequest) {
    return { type: DashboardActionTypes.APPTOAPPLY_TRANSITION_REQUEST, data };
  }

  public static appToApplyTransitionSuccess(data: AppToApplyTransitionResponse) {
    return { type: DashboardActionTypes.APPTOAPPLY_TRANSITION_SUCCESS, data };
  }

  public static appToApplyTransitionError(data: Error) {
    return { type: DashboardActionTypes.APPTOAPPLY_TRANSITION_ERROR, data };
  }

  public static resetSimilarProperties() {
    return { type: DashboardActionTypes.RESET_SIMILAR_PROPERTIES };
  }

  public static setSimilarProperty(data: SimilarPropertiesResponse | null) {
    return { type: DashboardActionTypes.SET_SIMILAR_PROPERTY, data };
  }

  public static resetSuccess() {
    return { type: DashboardActionTypes.RESET_SUCCESS };
  }

  public static getDeletedInfoRequest() {
    return { type: DashboardActionTypes.GET_DELETED_INFO_REQUEST, data: null };
  }

  public static getDeletedInfoSuccess(data: boolean) {
    return { type: DashboardActionTypes.GET_DELETED_INFO_SUCCESS, data };
  }

  public static getDeletedInfoError(data: Error) {
    return { type: DashboardActionTypes.GET_DELETED_INFO_ERROR, data };
  }

  public static dismissDeletedInfoRequest() {
    return { type: DashboardActionTypes.DISMISS_DELETED_INFO_REQUEST, data: null };
  }

  public static dismissDeletedInfoSuccess(data: boolean) {
    return { type: DashboardActionTypes.DISMISS_DELETED_INFO_SUCCESS, data };
  }

  public static dismissDeletedInfoError(data: Error) {
    return { type: DashboardActionTypes.DISMISS_DELETED_INFO_ERROR, data };
  }
}
