import React, { FC, useRef, useState, useMemo } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './FAQQuestionStyles';
import Accordian from '../../assets/navigation/accordian.png';
import Text from '../text/Text';

interface FAQQuestionProps {
  question: string;
  description: string;
}

const FAQQuestion: FC<FAQQuestionProps> = ({ question, description }) => {
  const classes = useStyles();
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [descriptionHeight, setDescriptionHeight] = useState<number>(0);

  return (
    <div className={classes.faqContainer}>
      <div
        className={classes.faqRow}
        onClick={() =>
          setDescriptionHeight(descriptionHeight > 0 ? 0 : descriptionRef.current!.scrollHeight)
        }
      >
        <Text textVariant="subtitle2" parentStyles={classes.question}>
          {question}
        </Text>
        <img
          src={Accordian}
          className={classNameGenerator([
            classes.actionIcon,
            descriptionHeight > 0 && classes.actionIconActive,
          ])}
          alt="accordian"
        />
      </div>
      <div
        ref={descriptionRef}
        className={classes.content}
        style={{
          maxHeight: `${descriptionHeight}px`,
          opacity: descriptionHeight > 0 ? 1 : 0,
        }}
      >
        <Text parentStyles={classes.description}>{description}</Text>
      </div>
    </div>
  );
};

export default FAQQuestion;
