import React, { FC, useEffect } from 'react';
import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useStyles } from './SuccessStyles';
import { LABELS } from './SuccessConstants';

interface CreateMaintenanceRequestSuccessProps {
  createAnother: () => void;
}

const CreateMaintenanceRequestSuccess: FC<CreateMaintenanceRequestSuccessProps> = ({
  createAnother,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.iconWrapper}>
          <CheckCircleOutline style={{ width: '64px', height: '64px' }} color="inherit" />
        </div>

        <DialogTitle>
          <Typography className={classes.heading} color="textPrimary" variant="h5">
            {LABELS.heading}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <div className={classes.detailsWrapper}>
            <Typography
              className={`${classes.centered} ${classes.description}`}
              color="textSecondary"
              variant="caption"
            >
              {LABELS.details}
            </Typography>
            <Typography
              className={`${classes.centered} ${classes.description}`}
              color="textSecondary"
              variant="caption"
            >
              {LABELS.track}
            </Typography>
          </div>
        </DialogContent>
        <div className={classes.buttonWrapper}>
          {/* TODO: Uncomment and redirect to app links on click */}
          {/* <Button
            color="secondary"
            variant="contained"
            onClick={() => alert('clicked')}
            disableRipple
            className={classes.button}
          >
            <PhoneIphoneOutlined style={{ color: 'white', marginRight: '10px' }} />
            {LABELS.download}
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={() => alert('clicked')}
            disableRipple
            className={classes.button}
          >
            <Android style={{ color: 'white', marginRight: '10px' }} />
            {LABELS.download}
          </Button> */}

          <Button
            color="secondary"
            variant="contained"
            onClick={() => createAnother()}
            disableRipple
            className={classes.button}
          >
            {LABELS.addAnother}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateMaintenanceRequestSuccess;
