import { getFrequency } from './PaymentPopupUtils';

/* eslint-disable import/prefer-default-export */
export const LABELS = {
  ADD_TITLE: 'Add payment method',
  ADD_SUBTITLE:
    'Please add in your preferred payment method —  we’ll use these details for when your bills are due.',
  RENT_TITLE: 'Rent payment split',
  RENT_SUBTITLE: (frequency: string, state: string) =>
    `Please enter how much rent each ${
      state === 'VIC' ? 'renter' : 'tenant'
    } will be paying per ${getFrequency(
      frequency,
    )}. The individual amounts must equal to the total weekly amount. If the amount is already pre-filled, please confirm this with your housemates before making changes.`,
  CLOSE: 'Next',
  BPAY: 'Please note, for BPAY there is a surcharge of $0.88 for each transaction',
  DIRECT: 'By choosing direct debit, you are agreeing to our ',
  RENT_INFO: (state: string) =>
    `Please note, you can request to change the amounts paid by each ${
      state === 'VIC' ? 'renter' : 'tenant'
    } by contacting your property manager.`,
};

export enum PaymentType {
  BPAY,
  BANK,
}

export const LINKS = {
  direct: 'https://assemblypayments.com/company/policies/direct-debit-request-service-agreement',
  direct2: 'https://kolmeo.com/terms-conditions/',
  bpay: 'https://kolmeo.com/terms-conditions/',
};
