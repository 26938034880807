export const PRIMARY_PERSONAL_DETAILS_ERROR = 'Personal Details in my profile incomplete.';
export const PRIMARY_DECLARATION_ERROR = 'Declaration in my profile incomplete.';
export const PRIMARY_IDENTIFICATION_ERROR = 'Identification in my profile incomplete';
export const PRIMARY_ADDRESS_DETAILS_ERROR = 'Address details in my profile incomplete.';
export const ADDITIONAL_PERSONAL_DETAILS_ERROR =
  'Personal Details in Additional profile incomplete';
export const ADDITIONAL_DECLARATION_ERROR = 'Declaration in Additional profile incomplete.';
export const OVERVIEW_ERROR = 'Application Preferences form is incomplete.';
export const ADDITIONAL_IDENTIFICATION_ERROR = 'Identification in additional profile incomplete';
export const PROPERTIES_ERROR = 'Properties form incomplete';
export const PRIMARY_CURRENT_INCOME_ERROR = 'Current income in my profile incomplete';
export const ADDITIONAL_CURRENT_INCOME_ERROR = 'Current income in Additional profile incomplete';
export const ADDITIONAL_ADDRESS_DETAILS_ERROR = 'Address details in Additional profile incomplete.';
