import React, { FC, useEffect, useState, useMemo } from 'react';
import { isEmpty } from '../../helper/Validation';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputAreaStyles';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { ClickAwayListener } from '@mui/material';

interface InputAreaProps {
  ref?: any;
  placeholder: string;
  value: string | number;
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  parentStyles?: string;
  parentInputStyles?: string;
  endElement?: any;
  showRequiredError?: boolean;
  hideEndElement?: boolean;
  name?: string;
  onBlur?: () => void;
  setValue: (value: string) => void;
}

const InputArea: FC<InputAreaProps> = ({
  ref,
  title,
  placeholder,
  value,
  mandatory,
  touched,
  error,
  parentStyles,
  parentInputStyles,
  endElement,
  showRequiredError,
  hideEndElement,
  name,
  onBlur,
  setValue,
}) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div className={classNameGenerator([classes.inputCard, parentStyles])}>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div className={classes.titleContainer}>
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      <ClickAwayListener
        onClickAway={() => {
          if (isFocused && onBlur) {
            onBlur();
            setValue(value as string);
          }
        }}
      >
        <div
          className={classNameGenerator([
            classes.inputContainer,
            parentInputStyles,
            ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
          ])}
        >
          <textarea
            ref={ref}
            name={name}
            className={classNameGenerator([classes.inputStyle, parentInputStyles])}
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            value={value}
            onBlur={onBlur}
            onFocus={() => setIsFocused(true)}
          />

          <div className={classes.endElement}>{endElement}</div>
          {((touched && error) || (showRequiredError && isEmpty(value)) || value) &&
            !hideEndElement && (
              <img
                className={classes.endStatus}
                src={
                  (touched && error) || (showRequiredError && isEmpty(value))
                    ? IncompleteIcon
                    : value
                    ? CompletedIcon
                    : ''
                }
                alt=""
              />
            )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default InputArea;
