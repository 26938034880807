import { AgencyLimited } from '../../models/agencies/Agencies';
import { SearchAction, SearchActionTypes } from '../actions/SearchActions';
import { SearchState } from '../state/SearchState';

export const initialState: SearchState = {
  agencies: undefined,
};

export const searchReducer: (state: SearchState, action: SearchAction) => SearchState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SearchActionTypes.GET_AGENCIES_SUCCESS:
      return {
        ...state,
        agencies: action.data as AgencyLimited[],
      };

    default:
      return state;
  }
};
