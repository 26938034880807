/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './PaymentPopupStyles';
import { LABELS, PaymentType, LINKS } from './PaymentPopupConstants';
import AddBank from '../addBank/AddBank';
import AddCard from '../addCard/AddCard';
import { BankAccountRequest, RentSplit } from '../../services/remoteSigning/payment/Payment';
import { GetUserResponse } from '../../services/remoteSigning/user/User';
import PersonCard from '../../container/remoteSigning/components/RemoteSigning/components/PersonCard';
import { getUserTitle } from '../../container/remoteSigning/components/RemoteSigning/components/SigningBox';
import ProgressBar from '../progressBar/ProgressBar';
import { RentalFrequency } from '../../services/remoteSigning/lease/Lease';
import { theme } from '../../theme/Theme';
import {
  convertToAmount,
  convertToPercent,
  getInitalSplit,
  validateSplitValue,
} from './PaymentPopupUtils';
import Button from '../button/Button';
import { RemoteSigningGetResponse } from '../../services/remoteSigning/RemoteSigning';

interface PaymentPopupProps {
  rentalAmount: number | undefined | null;
  rentalFrequency: RentalFrequency | string | undefined | null;
  rentSplitPercentage: RentSplit[];
  propertyState: string | undefined | null;
  remoteSigningData: RemoteSigningGetResponse;
  closePopup: () => void;
  onSubmit: (split: RentSplit[], bankDetails?: BankAccountRequest, noPayment?: boolean) => void;
}

const PaymentPopup: FC<PaymentPopupProps> = ({
  rentalAmount,
  rentalFrequency,
  rentSplitPercentage,
  propertyState,
  remoteSigningData,
  closePopup,
  onSubmit,
}) => {
  const showSplit = rentSplitPercentage && rentSplitPercentage.length > 1;
  const classes = useStyles();
  const [step, setStep] = useState<number>(showSplit ? 0 : 1);
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.BANK);
  const [split, setSplit] = useState<RentSplit[]>(
    getInitalSplit(rentSplitPercentage, rentalAmount),
  );

  const splitComplete = useMemo<boolean>(() => {
    let subtotal = 0;
    split.map((s) => {
      subtotal += s.rentSplitAmount || 0;
    });
    return subtotal === rentalAmount;
  }, [split]);

  return (
    <div className={classes.formStyle}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{step === 0 ? LABELS.RENT_TITLE : LABELS.ADD_TITLE}</div>
        <CloseIcon className={classes.closeIcon} onClick={() => closePopup()} />
      </div>
      <div className={classes.subtitleText} style={step === 0 ? { marginBottom: '5px' } : {}}>
        {step === 0 ? LABELS.RENT_SUBTITLE(rentalFrequency!, propertyState!) : LABELS.ADD_SUBTITLE}
      </div>
      {step === 0 ? (
        <>
          <div className={classes.rentSplitHeader}>
            <div className={classes.rentContainer}>
              <div className={classes.rent}>${rentalAmount!.toFixed(2)}</div>{' '}
              <div className={classes.freq}>{rentalFrequency}</div>
            </div>
          </div>
          {rentSplitPercentage ? (
            <>
              {rentSplitPercentage.map((tenant, index) => (
                <>
                  <PersonCard
                    profileName={`${tenant.name} ${tenant.surname}`}
                    profileContext={getUserTitle(tenant.userType, propertyState)}
                    rentSplitAmount={split[index]!.rentSplitAmount}
                    rentalAmount={rentalAmount}
                    setSplitAmount={(value) =>
                      setSplit(validateSplitValue(split, rentalAmount!, index, value))
                    }
                  />
                </>
              ))}
            </>
          ) : null}
          <div className={classes.infoBox} style={{ marginTop: '15px' }}>
            {LABELS.RENT_INFO(propertyState!)}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              parentStyles={classes.primaryButton}
              disabled={!splitComplete}
              onPress={() => {
                if (splitComplete) {
                  // If amount is 0, don't proceed to next step
                  const tenantIndex = rentSplitPercentage.findIndex(
                    (t) => t.userId === remoteSigningData.user.id,
                  );
                  if (
                    split[tenantIndex]!.rentSplitAmount === 0 ||
                    split[tenantIndex]!.rentSplitAmount === null
                  ) {
                    onSubmit(split, undefined, true);
                  } else {
                    setStep(1);
                  }
                }
              }}
            >
              Next
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={classes.tabsContainer}>
            <div
              className={`${classes.tab} ${
                paymentType === PaymentType.BANK ? classes.tabActive : undefined
              }`}
              onClick={() => setPaymentType(PaymentType.BANK)}
            >
              Direct Debit
            </div>
            <div
              className={`${classes.tab} ${
                paymentType === PaymentType.BPAY ? classes.tabActive : undefined
              }`}
              onClick={() => setPaymentType(PaymentType.BPAY)}
            >
              BPAY
            </div>
          </div>
          {paymentType === PaymentType.BANK ? (
            <AddBank
              goBack={() => setStep(0)}
              showSplit={showSplit}
              onSubmit={(bankDetails: BankAccountRequest) => onSubmit(split, bankDetails)}
            />
          ) : (
            <>
              <div className={classes.buttonContainer}>
                {showSplit && (
                  <Button outline parentStyles={classes.secondaryButton} onPress={() => setStep(0)}>
                    Back
                  </Button>
                )}
                <Button
                  parentStyles={classes.primaryButton}
                  disabled={paymentType === null}
                  onPress={() => onSubmit(split)}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentPopup;
