import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './FirstStepsStyles';
import bannerRed from '../../../../assets/banner-blue.png';
import welcomeImage from '../../../../assets/firstSteps-image.png';
import { LABELS, STEPS } from './FirstStepsConstants';
import Text from '../../../../component/text/Text';

interface FirstStepsProps {}

const FirstSteps: FC<FirstStepsProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.welcomeBanner}>
      <div className={classes.welcomeLeftContent}>
        <Text textVariant="title" parentStyles={classes.welcomeTitle}>
          {LABELS.TITLE}
        </Text>
        <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
          {LABELS.SUBTITLE}
        </Text>
        <div className={classes.stepsContainer}>
          {STEPS.map((step, idx) => (
            <div className={classes.step} key={idx}>
              <div className={classes.stepIconContainer}>
                <img src={step.icon} className={classes.stepIcon} alt="step" />
              </div>
              <Text parentStyles={classes.stepText}>{step.title}</Text>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.welcomeImageBackground}>
        <img src={bannerRed} className={classes.welcomeLeftImage} alt="overlay" />
        <img src={welcomeImage} className={classes.welcomeRightImage} alt="welcome" />
      </div>
    </div>
  );
};

export default FirstSteps;
