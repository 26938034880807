/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import SearchService from '../../services/search/SearchService';
import { Logging } from '../../helper/Logging';
import { SearchAction, SearchActions, SearchActionTypes } from '../actions/SearchActions';
import { AgencyLimited } from '../../models/agencies/Agencies';

export function* getGroupMasterProfile() {
  try {
    const response: AgencyLimited[] = yield SearchService.getAgencies();
    yield put(SearchActions.getAgenciesSuccess(response));
  } catch (error) {
    yield put(SearchActions.getAgenciesError(error as Error));
  }
}

function* searchWatcher() {
  yield takeEvery(SearchActionTypes.GET_AGENCIES_REQUEST, () => getGroupMasterProfile());
}

export default searchWatcher;
