/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles, primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.light,
    alignItems: 'center',
    padding: '25px 30px',
  },
  title: {
    color: theme.colors.sortedPurple,
    fontSize: 28,
    marginBottom: '10px',
  },
  mailIcon: {
    width: '40px',
    height: '40px',
  },
  contentContainer: {
    padding: '30px',
    backgroundColor: theme.colors.light,
  },
  subtext: {
    color: theme.colors.black87,
    fontWeight: 100,
  },
  verifyEmailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    backgroundColor: theme.colors.fadedPurple,
    padding: '25px',
    borderRadius: '8px',
  },
  tick: {
    height: '30px',
    width: '30px',
  },
  emailText: {
    color: theme.colors.black87,
    fontWeight: 300,
    marginRight: 'auto',
    marginLeft: '20px',
  },
  resendButton: {
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '8px 25px',
    textAlign: 'center',
    width: '80px',
    cursor: 'pointer',
  },
  sentButton: {
    backgroundColor: theme.colors.success,
    border: `${theme.colors.borderColor} 2px solid`,
    color: theme.colors.light,
    borderRadius: '6px',
    padding: '8px 25px',
    textAlign: 'center',
    width: '80px',
    cursor: 'pointer',
  },
  infoText: {
    fontWeight: 100,
    opacity: 0.4,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
  },
  backButton: {
    position: 'relative',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.grey} 2px solid`,
    fontSize: 16,
    fontWeight: 300,
    borderRadius: '6px',
    padding: '15px',
    width: '180px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
    },
  },
  backarrow: {
    position: 'absolute',
    left: '15px',
  },
  verifiedContainer: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '50px',
  },
  verified: {
    color: theme.colors.black87,
    fontWeight: 100,
  },
  login: {
    color: theme.colors.success,
    marginLeft: '4px',
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    maxWidth: 800,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 8,
      maxWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 64,
    marginTop: 24,
  },
  icon: {
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  primaryTitle: {
    marginLeft: theme.units.spacing * 4,
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  marginBottom: {
    marginBottom: theme.units.spacing * 4,
  },
  subtitleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loginRow: {
    marginTop: theme.units.spacing * 6,
    display: 'flex',
    alignItems: 'center',
  },
  backRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  loginButton: {
    minWidth: 70,
    marginLeft: theme.units.spacing * 4,
    marginRight: 'auto',
  },
  marginTop: {
    marginTop: theme.units.spacing * 4,
  },
});
