import {
  GetDisclosureDetailsPayload,
  DisclosureDetailsResponse,
} from '../../services/disclosure/Disclosure';
import { DisclosureAction, DisclosureActionTypes } from '../actions/DisclosureActions';
import DisclosureState from '../state/DisclosureState';

export const initialState: DisclosureState = {
  token: '',
  user: undefined,
  questionnaire: undefined,
  agency: undefined,
  isPrimaryLessor: undefined,
  comments: undefined,
};

export const disclosureReducer = (state = initialState, action: DisclosureAction) => {
  switch (action.type) {
    case DisclosureActionTypes.GET_DISCLOSURE_DETAILS_REQUEST:
      return {
        ...state,
        token: (action.data as GetDisclosureDetailsPayload).token,
      };

    case DisclosureActionTypes.GET_DISCLOSURE_DETAILS_SUCCESS:
      return {
        ...state,
        questionnaire: (action.data as DisclosureDetailsResponse).questionnaire,
        user: (action.data as DisclosureDetailsResponse).user,
        agency: (action.data as DisclosureDetailsResponse).agency,
        isPrimaryLessor: (action.data as DisclosureDetailsResponse).primary,
        comments: (action.data as DisclosureDetailsResponse).comments,
      };
    default:
      return state;
  }
};
