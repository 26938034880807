import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  PropertyDetailsResponse,
  AgencyDetails,
} from './getPropertyDetails/GetPropertyDetails.data';
import {
  SimilarPropertiesResponse,
  SimilarPropertiesRequest,
} from './similarProperties/GetSimilarProperties';
import {
  GetPropertiesPayload,
  GetPropertyByAddressPayload,
  GetAgencyByAgentPayload,
  GetPropertyByReaxmlPayload,
  GetPropertyByReaxmlSearchPayload,
} from '../../store/actions/DashboardActions';
import {
  AppToApplyTransitionRequest,
  AppToApplyTransitionResponse,
} from './applyTransition/applyTransition';
import TenancyApiServiceBase from '../TenancyApiServiceBase';
import TenancyApiService from '../TenancyApiService';

const serviceType = ServiceType.Property;
const apiServiceType = ServiceType.ApiUserProperty;
const propertySearchServiceType = ServiceType.searchProperties;

export class PropertyService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly apiService: ApiServiceBase = new ApiService(apiServiceType);
  private readonly searchPropertyService: TenancyApiServiceBase = new TenancyApiService(
    propertySearchServiceType,
  );

  public getProperty(payload: string): Promise<PropertyDetailsResponse> | PropertyDetailsResponse {
    return this.service.get<PropertyDetailsResponse>({
      route: [payload.toString()],
    });
  }

  public getProperties(
    payload: GetPropertiesPayload,
  ): Promise<PropertyDetailsResponse> | PropertyDetailsResponse {
    const branchCode = payload.branch!.replace(`${payload.agency!}_`, '');
    return this.service.get<PropertyDetailsResponse>({
      route: ['search', payload.agency!, branchCode],
      query: { address: payload.address, limit: payload.limit || 20 },
    });
  }

  public getPropertyByAddress(
    payload: GetPropertyByAddressPayload,
  ): Promise<PropertyDetailsResponse> | PropertyDetailsResponse {
    const { agentId, suburb, streetName, streetNumber, unitNumber, postcode, state } = payload;
    const queryData = {
      agentId,
      suburb,
      streetName,
      streetNumber,
      postcode,
      state,
      unitNumber,
    };
    if (!unitNumber) {
      delete queryData.unitNumber;
    }
    return this.service.get<PropertyDetailsResponse>({
      route: ['address'],
      query: queryData,
    });
  }

  public getPropertyByReaxml(
    payload: GetPropertyByReaxmlPayload,
  ): Promise<PropertyDetailsResponse> | PropertyDetailsResponse {
    const { agentId, reaxmlId, type } = payload;
    const queryData = { agentId, reaxmlId, type };
    return this.service.get<PropertyDetailsResponse>({
      route: ['reaxml'],
      query: queryData,
    });
  }

  public getPropertyByReaxmlSearch(
    payload: GetPropertyByReaxmlSearchPayload,
  ): Promise<PropertyDetailsResponse> | PropertyDetailsResponse {
    const { agencyCode, reaxmlId } = payload;
    const queryData = { agencyCode, reaxmlId };
    return this.service.get<PropertyDetailsResponse>({
      route: ['search', 'reaxml'],
      query: queryData,
    });
  }

  public getAgencyByAgent(
    payload: GetAgencyByAgentPayload,
  ): Promise<AgencyDetails> | AgencyDetails {
    const { agentId, type, branch } = payload;
    return this.service.get<AgencyDetails>({
      route: ['agent', agentId!.toUpperCase()],
      query: { type, branch },
    });
  }

  public getSimiliarProperties(
    payload: SimilarPropertiesRequest,
  ): Promise<SimilarPropertiesResponse[]> | SimilarPropertiesResponse[] {
    return this.service.post<SimilarPropertiesResponse[]>(
      {
        route: ['advertised'],
      },
      payload.refIds,
    );
  }

  public appToApplyTransition(
    payload: AppToApplyTransitionRequest,
  ): Promise<AppToApplyTransitionResponse> | AppToApplyTransitionResponse {
    const { history, ...data } = payload; // excluding history
    return this.service.post<AppToApplyTransitionResponse>(
      {
        route: ['transition'],
      },
      data,
    );
  }

  public searchProperties({
    agency,
    searchString,
    branch,
  }: {
    agency?: string;
    branch?: string;
    searchString: string;
  }): Promise<undefined> | undefined {
    return this.searchPropertyService.get({
      query: {
        agencyCode: agency,
        branchCode: branch,
        query: searchString,
      },
    });
  }
}

const propertyService = new PropertyService();
export default propertyService;
