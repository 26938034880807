import greyBanner from '../../assets/banner-grey.png';
import hero1 from '../../assets/ntdImages/hero.png';
import hero2 from '../../assets/ntdImages/hero2.png';
import peachBanner from '../../assets/banner-peach.png';

export const LABELS = {
  GET_STARTED: 'Get started',
  LEARN_MORE: 'Learn more',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  BACK: 'Back',
  PURCHASE_NTD: 'Purchase background check',
  PAY_NOW: 'Pay now',
  COLLECTION_STATEMENT_TITLE: 'Personal Information Collection Statement',
  COLLECTION_STATEMENT_CONTENT: `
    <p>If you request a tenancy report we will disclose your personal information to our service provider, Equifax Australia Information Services and Solutions Pty Limited ABN 26 000 602 862 or its related bodies corporate (<strong>Equifax</strong>) for Equifax to generate the report. For this purpose Equifax may check the Equifax National Tenancy Database, Trading Reference Australia, the TICA tenant database, government records, court documents and other public databases. Please see <a href="https://static1.squarespace.com/static/5ad6c933ee1759a2c83f3ef2/t/5d40dee75545900001568399/1564532841824/Sorted-Services-Privacy-Policy.pdf">Sorted Services' Privacy Policy</a> for further information on how your personal information is collected, used and disclosed and how you can complain about a privacy breach. Please see <a href="https://www.equifax.com.au/privacy">Equifax's Privacy Policy</a> for details of how Equifax will handle your personal information.</p>`,
  ACKNOWLEDGE: 'Acknowledgement',
  ACKNOWLEDGE_INFO:
    "This service is provided by Equifax, by continuing below you are agreeing to our terms and conditions set out here: https://www.tenancydatabase.com.au/terms. You may also find Equifax's terms and conditions here: https://www.tenancydatabase.com.au/terms",
  VIEW_IN_PROFILE: 'View in profile',
  DEFAULT: 'Background check on by default',
};

export const VARIANT_1 = {
  title: 'Stand out from the crowd!',
  subtitle: 'Boost your profile with a background check',
  banner: greyBanner,
  bannerImage: hero1,
};

export const VARIANT_2 = {
  title: 'Boost your profile!',
  subtitle: 'Stand out from the crowd with a background check',
  banner: peachBanner,
  bannerImage: hero2,
};
