import { FileLinkOrContent } from './FileUploadState.data';

export enum FileUploadAction {
  Remove,
  Add,
  Replace, // when maxFiles=1, it's possible to replace by uploading new file
}

export class FileUploadResult {
  public readonly action: FileUploadAction;

  public readonly success: boolean;

  public readonly partialSuccess: boolean; // some of the files were not added (file size or max file count violation)

  public readonly removedFiles: FileLinkOrContent[];

  public readonly addedFiles: FileLinkOrContent[];

  public readonly currentFiles: FileLinkOrContent[];

  public readonly errors: string[];
}
