import house from '../../assets/propertyTypes/house.png';
import apartment from '../../assets/propertyTypes/apartment.png';
import unit from '../../assets/propertyTypes/unit.png';
import other from '../../assets/propertyTypes/other.png';
import { PropertyType } from '../../services/remoteSigning/lease/Lease';

export const LABELS = {
  SEARCH: 'Search',
  SEARCH_RESULTS: 'Search results',
  FILTERS: 'Filters',
  PRICE_RANGE: 'Price range',
  PROPERTY_TYPE: 'Property type',
  LOCATION: 'Location',
  SUBURB: 'Suburb',
  BEDROOMS: 'Bedrooms',
  BATHROOMS: 'Bathrooms',
  CARSPACES: 'Carspaces',
  HOUSE: 'House',
  APARTMENT: 'Apartment',
  UNIT: 'Unit',
  OTHER: 'Other',
  MIN: 'Min',
  MAX: 'Max',
  AGENCY: 'Agency',
};

export const propertyTypeArray = [
  {
    image: house,
    propertyType: PropertyType.House,
  },
  {
    image: apartment,
    propertyType: PropertyType.Apartment,
  },
  {
    image: unit,
    propertyType: PropertyType.Unit,
  },
  {
    image: other,
    propertyType: PropertyType.Other,
  },
];
