import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@mui/material';
import { SelectItem } from '../RenderSelect/RenderSelect';
import { theme } from '../../../../theme/Theme';

const RenderRadioGroup = (fieldData: any) => {
  const { field, label, disabled, options, onChange, form } = fieldData;
  /* istanbul ignore next */
  const hasError = !!field.error && !!field.touched;

  return (
    <div className="item-render">
      <FormControl required={field.required} error={hasError} fullWidth>
        {label && (
          <div style={{ marginBottom: '16px' }}>
            <FormLabel>{label}</FormLabel>
          </div>
        )}
        <RadioGroup
          id={field.name}
          name={field.name}
          value={String(field.value)}
          onChange={(e, value) => (onChange ? onChange(e, value) : field.onChange(e, value))}
        >
          {options &&
            options.map((item: string | SelectItem, index: number) =>
              renderItem(item, index, !!disabled),
            )}
        </RadioGroup>
        {hasError && <FormHelperText id={`${field.name}-error-text`}>{field.error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

function renderItem(item: string | SelectItem, index: number, disabled = false): JSX.Element {
  if (typeof item === 'string') {
    return (
      <FormControlLabel
        key={index}
        control={
          <Radio
            sx={{
              color: theme.colors.secondary,
              '&.Mui-checked': {
                color: theme.colors.secondary,
              },
            }}
          />
        }
        label={item}
        value={item}
        disabled={disabled}
      />
    );
  }
  return (
    <FormControlLabel
      key={index}
      value={
        /* istanbul ignore next */
        item.value !== null && item.value !== undefined ? item.value : undefined
      }
      disabled={disabled}
      control={
        <Radio
          sx={{
            color: theme.colors.secondary,
            '&.Mui-checked': {
              color: theme.colors.secondary,
            },
          }}
        />
      }
      label={item.label}
    />
  );
}
export default RenderRadioGroup;
