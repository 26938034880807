import React, { FC } from 'react';
import { theme } from '../../theme/Theme';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return <div style={{ color: theme.colors.error, fontSize: 14, fontWeight: 500 }}>{message}</div>;
};

export default ErrorMessage;
