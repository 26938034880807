import { bucketName } from '../AppSettings';
import { clearStorage } from '../container/login/LoginUtils';
import { SuccessToastActions } from '../store/actions/ SuccessToastAction';

export const versionSync = (
  initial?: boolean,
  onSignOut?: () => void,
  onPopUpShow?: () => void,
  onCancel?: () => void,
) => {
  fetch(`https://${bucketName}.s3.amazonaws.com/apply-ui-version/version.json`)
    .then((res) => res.json())
    .then((jsonResponse) => {
      const { version } = jsonResponse;
      // If initial i.e. page has been manually reloaded or visited fresh
      const currentVersion = localStorage.getItem('version');

      if (
        initial &&
        !(
          currentVersion && parseInt(version.split('.')[0]) > parseInt(currentVersion.split('.')[0])
        )
      ) {
        localStorage.setItem('version', `${version}`);
      } else {
        const currentVersion = localStorage.getItem('version');
        // if localstorage has value, compare
        // Otherwise if value isn't set, sets it
        if (currentVersion) {
          if (currentVersion !== `${version}`) {
            // Pop up open action
            if (onPopUpShow) {
              onPopUpShow();
            }

            const majorVersionChange =
              parseInt(version.split('.')[0]) > parseInt(currentVersion.split('.')[0]);

            if (majorVersionChange) {
              onSignOut && onSignOut();
              SuccessToastActions.showError('App has been updated, please log in again.');
              window.location.href = `/#/login`;
              // set new version in the local storage and set popUpOpen to false
              localStorage.setItem('version', `${version}`);
              (onCancel as () => void)();
            } else if (
              // eslint-disable-next-line no-alert
              window.confirm(
                "A newer version of web app is available, would you like to reload the page?\nPlease make sure you don't have any on-going task, otherwise cancel and reload manually later.",
              )
            ) {
              window.location.reload();
              localStorage.setItem('version', `${version}`);
            } else {
              // set popUpOpen to false
              (onCancel as () => void)();
            }
          }
        } else {
          localStorage.setItem('version', `${version}`);
        }
      }
    })
    .catch((e) => console.log(e));
};
