import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { globalStyles } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  paperBack: globalStyles.paperBack as any,
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    [`@media (min-width: ${SIZES.md}px)`]: {
      padding: '0 133px',
    },
  },
  titleStyle: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      textAlign: 'center',
      padding: '20px 0 54px 0',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      textAlign: 'center',
      paddingTop: '75px',
      paddingBottom: '40px',
    },
  },
  inputContainerStyle: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingBottom: '10px',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      paddingBottom: '8px',
      background: 'rgba(0, 0, 0, 0.05)',
      padding: 10,
      marginTop: 10,
    },
  },
  inputLabelStyle: {
    paddingLeft: '5px',
    width: '95%',
    color: '#1f0d33',
  },
  inputStyle: {
    paddingLeft: '5px',
    width: '95%',
  },
  labelStyle: {
    color: theme.colors.sortedPurple,
  },
  buttonContainer: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: '54px 0 24px',
      textAlign: 'center',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      margin: '54px 0 54px',
      textAlign: 'center',
    },
  },
  enterPassword: {
    paddingTop: '5px',
    color: theme.colors.buttonPrimary,
  },
  errorText: {
    color: theme.colors.error,
    marginTop: 5,
  },
});
