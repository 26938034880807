import { makeStyles } from '@mui/styles';
import { getFonts } from '../../../helper/GetFonts';
import { theme } from '../../../theme/Theme';

export const useStyles = makeStyles({
  backgroundImageStyle: {
    maxHeight: '18.75rem',
    width: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
  },
  formikRootStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    justifyContent: 'center',
    margin: '-5em 0rem',
    position: 'relative',
    background: theme.colors.light,
    width: '70.8%',
    left: '14.6%',
    [`@media (max-width: 53.125rem)`]: {
      width: '76.5%',
      left: '8.5%',
    },
    [`@media (max-width: 43.5rem)`]: {
      width: '88%',
      left: '0%',
      margin: '0',
    },
  },
  titleStyle: {
    fontSize: '2.625rem',
    fontWeight: 500,
    color: theme.colors.sortedPurple,
    fontFamily: 'GT Walsheim',
    paddingBottom: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '2.297rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '1.969rem',
    },
  },
  titleHeaderStyle: {
    fontSize: '1.375rem',
    paddingBottom: '3.688rem',
    color: theme.colors.grey,
    textAlign: 'justify',
    [`@media (max-width: 53.125rem)`]: {
      paddingBottom: '3.75rem',
      fontSize: '1.203rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      paddingBottom: '3.313rem',
      fontSize: '1.031rem',
    },
  },
  infoTitle: {
    marginBottom: theme.units.spacing * 12,
  },
  switchQuestionStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '2.5rem',
    [`@media (max-width: 53.125rem)`]: {
      paddingBottom: '1.75rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      paddingBottom: '1.125rem',
    },
  },
  questionLabelStyle: {
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.009375rem',
    maxWidth: 500,
  },
  buttonGroupStyle: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginLeft: theme.units.spacing * 4,
    marginRight: -theme.units.spacing * 4,
  },
  buttonStyle: {
    textTransform: 'none',
    borderRadius: '0.375rem',
    color: theme.colors.grey,
    background: theme.colors.grey,
    width: '50%',
    fontFamily: 'GT Walsheim',
  },
  buttonChecked: {
    color: theme.colors.light,
    background: theme.colors.success,
    [`@media (max-width: 43.5rem)`]: {
      '&:hover': {
        backgroundColor: theme.colors.success,
      },
    },
    [`@media (min-width: 43.5rem)`]: {
      '&:hover': {
        backgroundColor: theme.colors.success,
      },
    },
  },
  textQuestionStyle: {
    paddingBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
  },
  inputAnswerStyle: {
    margin: '0 0.5rem',
  },
  additionalNoteStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2.8125rem',
  },
  additionalNoteHeaderStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: 16,
    fontWeight: 500,
  },
  additionalNoteBodyStyle: {
    marginTop: theme.units.spacing * 2,
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.inputBackground,
  },
  buttonWrapperStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextButtonStyle: {},
  requiredErrorStyle: {
    margin: '0 0.5rem',
    color: theme.colors.error,
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit',
    fontFamily: 'GT Walsheim',
    fontSize: '0.875rem',
    letterSpacing: '0.008125rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '0.788rem',
      letterSpacing: '0.0075rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '0.675rem',
      letterSpacing: '0.00625rem',
    },
  },
  dataLoadingStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: theme.colors.sortedPurple,
    color: 'white',
    textAlign: 'center',
  },
});
