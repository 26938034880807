import { Question } from './GenericFormRenderer';

import * as Yup from 'yup';

export const getValidationSchema = (questions: Question[]) => {
  const shape: Record<string, any> = {};

  questions.forEach((question) => {
    if (question.mandatory) {
      shape[question.field] = Yup.mixed().required(`This field is required`);
    }
  });

  return Yup.object().shape(shape);
};
