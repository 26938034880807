import home from '../../assets/navigation/home.png';
import document from '../../assets/navigation/document.png';
import profile from '../../assets/navigation/profile.png';
import people from '../../assets/navigation/people.png';
import { routes } from '../../Routes';

export const LABELS = {
  YOU: 'You',
};

export interface LinkItem {
  name: string;
  route: string;
  routes: string[];
}

export const LINKS = [
  {
    name: 'For you',
    route: routes.home.view,
    routes: [routes.home.view],
    icon: home,
  },
  {
    name: 'Your profile',
    route: routes.myProfile.new,
    routes: [routes.myProfile.new],
    icon: profile,
  },
  {
    name: 'Your applications',
    route: routes.applications.view,
    routes: [
      routes.applications.view,
      routes.properties.generic,
      routes.advancedSearch.generic,
      routes.applicationPreferences.new,
      routes.submitProperties.new,
      routes.success.new,
    ],
    icon: document,
  },
  {
    name: 'Your groups',
    route: routes.groups.view,
    routes: [routes.groups.view],
    icon: people,
  },
];

export const showUrl = [
  routes.groups.view,
  routes.applications.view,
  routes.myProfile.new,
  routes.home.view,
  routes.properties.generic,
  routes.advancedSearch.generic,
  routes.applicationPreferences.new,
  routes.submitProperties.new,
  routes.success.new,
];

export const collapsedUrl = [
  routes.properties.generic,
  routes.advancedSearch.generic,
  routes.applicationPreferences.new,
  routes.submitProperties.new,
  routes.success.new,
];
