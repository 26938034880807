/* eslint-disable import/prefer-default-export */
import { Location } from 'history';
import {
  isAdditionalProfileDetailsFilled,
  isPropertiesDetailsFilled,
  verifyDeclarationDetails,
  verifyProfileDetails,
  verifySeconadaryDetails,
} from '../../helper/ProfileVerifyHelper';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { AppToApplyTransitionParams } from './ApplicationConstants';

export const getQueryParams = (location: Location, param: string): string => {
  const query = location.search.substring(1);
  const vars = query.split('&');
  let returnVal = '';
  vars.forEach((key: string) => {
    const pair = key.split('=');
    if (decodeURIComponent(pair[0]) === param) {
      returnVal = decodeURIComponent(pair[1]);
    }
  });
  return returnVal;
};

export const getAllQueryParams = (location: Location): AppToApplyTransitionParams => {
  const agency = getQueryParams(location, 'agency');
  const applicationRefIds = getQueryParams(location, 'applicationRefIds');
  const draftIds = getQueryParams(location, 'draftIds');
  const groupRefId = getQueryParams(location, 'groupRefId');
  const responseType = getQueryParams(location, 'responseType');
  const updatingOne = getQueryParams(location, 'updatingOne');

  return {
    agency,
    applicationRefIds,
    draftIds,
    groupRefId,
    responseType,
    updatingOne,
  };
};

export const canSubmitApplication = (
  myProfileForm: ApplicantProfile,
  secondaryApplicantProfiles: ApplicantProfile[],
  dependentProfiles: DependentProfile[],
): { valid: boolean; reason: string } => {
  let reason = '';
  let valid = true;

  if (!isPropertiesDetailsFilled()) {
    valid = false;
    reason = 'Your selected properties are missing information';
  }

  if (!verifyProfileDetails(myProfileForm)) {
    valid = false;
    reason = 'Your profile is incomplete';
  } else if (verifyDeclarationDetails(myProfileForm)) {
    valid = false;
    reason = "The declaration form hasn't been accepted";
  }

  if (secondaryApplicantProfiles && secondaryApplicantProfiles.length) {
    secondaryApplicantProfiles.map((tenant, key) => {
      if (tenant.personalDetails && tenant.personalDetails.isManaged) {
        if (!verifySeconadaryDetails(secondaryApplicantProfiles, key)) {
          valid = false;
          reason = 'Your managed profiles are incomplete';
        } else if (!tenant) {
          valid = false;
          reason = 'Your managed profiles are incomplete';
        }
      }
    });
  }

  if (dependentProfiles && dependentProfiles.length) {
    dependentProfiles.map((tenant) => {
      if (!isAdditionalProfileDetailsFilled(tenant)) {
        valid = false;
        reason = 'Your managed profiles are incomplete';
      }
    });
  }

  return { valid, reason };
};
