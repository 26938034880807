import {
  EquifaxRequest,
  EquifaxResponse,
  EquifaxRetryPaymentRequest,
} from '../../models/equifax/equifax';
import { StoreAction } from '../StoreHelper';

export enum EquifaxActionTypes {
  GET_NTD_CHECK = 'GET_NTD_CHECK',
  GET_NTD_CHECK_REQUEST = 'GET_NTD_CHECK_REQUEST',
  GET_NTD_CHECK_SUCCESS = 'GET_NTD_CHECK_SUCCESS',
  GET_NTD_CHECK_ERROR = 'GET_NTD_CHECK_ERROR',

  RETRY_PAYMENT_NTD_CHECK = 'RETRY_PAYMENT_NTD_CHECK',
  RETRY_PAYMENT_NTD_CHECK_REQUEST = 'RETRY_PAYMENT_NTD_CHECK_REQUEST',
  RETRY_PAYMENT_NTD_CHECK_SUCCESS = 'RETRY_PAYMENT_NTD_CHECK_SUCCESS',
  RETRY_PAYMENT_NTD_CHECK_ERROR = 'RETRY_PAYMENT_NTD_CHECK_ERROR',
}

export type EquifaxActionPayload =
  | Error
  | null
  | EquifaxRequest
  | EquifaxRetryPaymentRequest
  | EquifaxResponse;

export type EquifaxAction = StoreAction<EquifaxActionTypes, EquifaxActionPayload>;

export class EquifaxActions {
  public static getNTDCheckRequest(data: EquifaxRequest) {
    return { type: EquifaxActionTypes.GET_NTD_CHECK_REQUEST, data };
  }
  public static getNTDCheckSuccess(data: EquifaxResponse) {
    return { type: EquifaxActionTypes.GET_NTD_CHECK_SUCCESS, data };
  }
  public static getNTDCheckError(data: Error) {
    return { type: EquifaxActionTypes.GET_NTD_CHECK_ERROR, data };
  }

  public static retryPaymentCheckRequest(data: EquifaxRetryPaymentRequest) {
    return { type: EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK_REQUEST, data };
  }
  public static retryPaymentCheckSuccess(data: EquifaxResponse) {
    return { type: EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK_SUCCESS, data };
  }
  public static retryPaymentCheckError(data: Error) {
    return { type: EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK_ERROR, data };
  }
}
