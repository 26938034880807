import { FileLoadingSpinnerState } from '../state/FileLoadSpinnerState';
import {
  FileLoadingSpinnerAction,
  FileLoadingSpinnerActionTypes,
} from '../actions/FileLoadingSpinnerActions';

export const initialState: FileLoadingSpinnerState = {
  isUploading: false,
};

export const fileLoadingSpinnerReducer = (
  state = initialState,
  action: FileLoadingSpinnerAction,
) => {
  switch (action.type) {
    case FileLoadingSpinnerActionTypes.ShowSpinner:
      return {
        ...state,
        isUploading: true,
      };

    case FileLoadingSpinnerActionTypes.HideSpinner:
      return {
        ...state,
        isUploading: false,
      };
    default:
      return state;
  }
};
