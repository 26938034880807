import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './GroupCardStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationsResponse, GroupResponse } from '../../services/groups/Groups';
import Input from '../input/Input';
import Text from '../text/Text';
import Box from '../box/Box';
import Button from '../button/Button';
import Gravatars from '../gravatars/Gravatars';
import { getApplicationsCount, getOccupantsString } from '../../helper/GroupHelper';
import people from '../../assets/navigation/people.png';
import home from '../../assets/navigation/home.png';
import edit from '../../assets/edit.png';
import tick from '../../assets/check-filled.svg';
import { LABELS } from './GroupCardConstants';

interface GroupCardProps {
  group: GroupResponse;
  hideBox?: boolean;
  showBottomActions?: boolean;
  disableNameChange?: boolean;
  groupApplications?: ApplicationsResponse;
  selected?: boolean;
  onDelete?: () => void;
  onUpdate?: () => void;
  onNameChange: (name: string) => void;
  onPress?: () => void;
}

const GroupCard: FC<GroupCardProps> = ({
  group,
  hideBox,
  showBottomActions,
  groupApplications,
  disableNameChange,
  selected,
  onDelete,
  onUpdate,
  onNameChange,
  onPress,
}) => {
  const classes = useStyles();
  const applicationsCount = getApplicationsCount(group.groupRefId, groupApplications);
  const [editingName, setEditingName] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>(group.groupName || 'My group');

  const saveName = () => {
    setEditingName(false);
    onNameChange(groupName);
  };

  return (
    <Box
      parentStyles={hideBox ? classes.hideBox : classes.box}
      innerStyles={hideBox ? classes.hideBoxInner : ''}
      lightBorder
      selected={selected}
      onPress={onPress}
    >
      <div className={classes.header}>
        {editingName ? (
          <div className={classes.inputRow}>
            <Input
              placeholder={groupName}
              value={groupName}
              setValue={setGroupName}
              onBlur={saveName}
              parentStyles={classes.inputContainer}
              parentInputStyles={classes.input}
            />
            <div className={classes.saveButton} onClick={() => saveName()}>
              {LABELS.SAVE}
            </div>
          </div>
        ) : (
          <div
            className={classes.titleContainer}
            onClick={() => (disableNameChange ? null : setEditingName(true))}
          >
            <Text textVariant="title" parentStyles={classes.groupTitle}>
              {group.groupName}
            </Text>
            {!disableNameChange && <img className={classes.editIcon} src={edit} alt="edit name" />}
          </div>
        )}
        <div className={classes.gravatarRow}>
          <Gravatars group={group} parentStyles={classes.gravatarSize} />
        </div>
      </div>
      <Text textVariant="info">{getOccupantsString(group, true)}</Text>
      <div className={classes.bottomRow}>
        <div className={classes.statsRow}>
          <div className={classes.stat}>
            <img className={classes.statIcon} src={people} alt="people stat" />
            <Text textVariant="info" parentStyles={classes.statText}>
              {getOccupantsString(group)}
            </Text>
          </div>
          {applicationsCount !== 0 && (
            <div className={classes.stat}>
              <img className={classes.statIcon} src={home} alt="applications stat" />
              <Text textVariant="info" parentStyles={classes.statText}>
                {applicationsCount} application{applicationsCount === 1 ? '' : 's'}
              </Text>
            </div>
          )}
        </div>
        {selected && <img src={tick} className={classes.tick} alt="tick" />}
      </div>
      {showBottomActions && (
        <div className={classes.lineRow}>
          {onDelete !== undefined && (
            <Button
              disabled={!!applicationsCount}
              outline
              parentStyles={classes.buttonMarginRight}
              onPress={() => onDelete!()}
            >
              {LABELS.DELETE}
            </Button>
          )}
          {onUpdate !== undefined && (
            <Button muted parentStyles={classes.buttonMarginRight} onPress={() => onUpdate!()}>
              {LABELS.UPDATE}
            </Button>
          )}
        </div>
      )}
    </Box>
  );
};

export default GroupCard;
