export const LABELS = {
  EDIT: 'Edit',
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
  OPTIONAL: 'Optional',
};

export enum SectionStatus {
  COMPLETE,
  INCOMPLETE,
  OPTIONAL,
}
