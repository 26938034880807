import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { useStyles } from './ProfileItemStyles';
import { LABELS, SectionStatus } from './ProfileItemConstants';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import PercentageWheel from '../../../../component/percentageWheel/PercentageWheel';
import accordian from '../../../../assets/navigation/accordian.png';
import { stringToHslColor } from '../../../../helper/GravatarHelper';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import introductionIcon from '../../../../assets/profileIcons/introduction.png';
import currentIncomeIcon from '../../../../assets/profileIcons/currentIncome.png';
import addressIcon from '../../../../assets/profileIcons/address.png';
import employmentIcon from '../../../../assets/profileIcons/employment.png';
import idIcon from '../../../../assets/profileIcons/id.png';
import petsIcon from '../../../../assets/profileIcons/pets.png';
import referencesIcon from '../../../../assets/profileIcons/references.png';
import termsIcon from '../../../../assets/profileIcons/terms.png';
import vehiclesIcon from '../../../../assets/profileIcons/vehicles.png';
import { ApplicantProfile } from '../../../../store/state/MyProfileFormState';
import { OverviewFormData as OverviewDetails } from '../../../../services/landingScreen/OverviewForm.data';
import { DependentProfile } from '../../../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';

interface ProfileItemProps {
  id?: string;
  title: ProfileForm;
  subtitle: string;
  percentage: number;
  status: SectionStatus;
  active: ProfileForm | null;
  content: any;
  isDependent: boolean | null;
  formData?: ApplicantProfile | DependentProfile;
  setActive: (value: ProfileForm | null) => void;
}

const ProfileItem: FC<ProfileItemProps> = ({
  id,
  title,
  subtitle,
  percentage,
  status,
  active,
  content,
  formData,
  isDependent,
  setActive,
}) => {
  const classes = useStyles();
  const profileSectionRef = useRef<HTMLDivElement>(null);
  const [profileSectionHeight, setProfileSectionHeight] = useState<number>(0);

  useEffect(() => {
    const handleClickInside = (event: any) => {
      if (
        active === title &&
        profileSectionRef.current &&
        profileSectionRef.current.contains(event.target)
      ) {
        setProfileSectionHeight(profileSectionRef.current!.scrollHeight);
      }
    };

    document.addEventListener('click', handleClickInside);
    window.addEventListener('resize', handleClickInside);

    return () => {
      document.removeEventListener('click', handleClickInside);
      window.removeEventListener('resize', handleClickInside);
    };
  }, [active, title]);

  useEffect(() => {
    setProfileSectionHeight(active === title ? profileSectionRef.current!.scrollHeight : 0);
  }, [active, content]);

  const getStatusLabel = () => {
    switch (status) {
      case SectionStatus.INCOMPLETE:
        return LABELS.INCOMPLETE;
      case SectionStatus.OPTIONAL:
        return LABELS.OPTIONAL;
      default:
        return LABELS.COMPLETE;
    }
  };

  const getStatusClass = () => {
    switch (status) {
      case SectionStatus.INCOMPLETE:
        return classes.incompleteLabel;
      case SectionStatus.OPTIONAL:
        return classes.optionalLabel;
      default:
        return classes.completeLabel;
    }
  };

  const getProfileIcon = () => {
    switch (title) {
      case ProfileForm.INTRODUCTION:
        return (
          <div className={classes.profileImageContainer}>
            <img src={introductionIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.CURRENT_INCOME_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={currentIncomeIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.EMPLOYMENT_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={employmentIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.PETS_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={petsIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.VEHICLES_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={vehiclesIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.ADDRESS_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={addressIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.REFERENCE_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={referencesIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.IDENTIFICATION_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={idIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      case ProfileForm.DECLARATION_FORM:
        return (
          <div className={classes.profileImageContainer}>
            <img src={termsIcon} className={classes.profileImage} alt="profile icon" />
          </div>
        );
      default: {
        if (isDependent === null) return null;
        let profileName = '';

        const { firstName, lastName } = isDependent
          ? (formData as any)
          : (formData as ApplicantProfile).personalDetails;
        profileName = `${firstName} ${lastName}`;

        return (
          <div
            className={classes.gravatar}
            style={{ backgroundColor: stringToHslColor(`${profileName}`, 70, 70) }}
          >
            {profileName.split(' ')[0].charAt(0)}
            {profileName.split(' ')[1].charAt(0)}
          </div>
        );
      }
    }
  };

  return (
    <Box lightBorder parentStyles={classes.applicationProfileCard}>
      <div className={classes.profileRow} id={id}>
        {getProfileIcon()}
        <div className={classes.applicationProfileDetails}>
          <Text textVariant="title" size="xl" parentStyles={classes.title}>
            {title}
          </Text>
          <Text textVariant="info">{subtitle}</Text>
        </div>
        <PercentageWheel completed={percentage} parentStyles={classes.percentageWheel} />
      </div>
      <div className={classes.lineRow}>
        <div className={`${classes.statusContainer} ${getStatusClass()}`}>
          <div className={classes.statusText}>{getStatusLabel()}</div>
        </div>
        <div
          className={classes.actionContainer}
          onClick={() => setActive(active === title ? null : title)}
        >
          <Text textVariant="link" parentStyles={classes.actionText}>
            {LABELS.EDIT}
          </Text>
          <img
            className={classNameGenerator([
              classes.actionIcon,
              active === title && classes.actionIconActive,
            ])}
            src={accordian}
            alt="accordian"
          />
        </div>
      </div>
      <div
        ref={profileSectionRef}
        className={classNameGenerator([classes.content])}
        style={{
          maxHeight: `${profileSectionHeight}px`,
          opacity: profileSectionHeight > 0 ? 1 : 0,
          marginTop: profileSectionHeight > 0 ? 16 : 0,
        }}
      >
        {content}
      </div>
    </Box>
  );
};

export default ProfileItem;
