import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './UploadingScreenStyles';
import { AppIcon, AppIconType, AppIconSize } from '../../../../component/AppIcon/AppIcon';
import { theme } from '../../../../theme/Theme';

interface UploadingScreenProps {
  icon: string | AppIconType;
  iconType: string;
  title: string;
  primaryText?: string;
  secondaryText?: string;
  ternaryText?: string;
  dontInvert?: boolean;
}

const UploadingScreen: FC<UploadingScreenProps> = ({
  icon,
  title,
  primaryText,
  secondaryText,
  iconType,
  ternaryText,
  dontInvert,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.rootContainer}>
      <Grid container>
        <Grid item sm={4} xs={3} />
        <Grid item sm={4} xs={6}>
          <div className={classes.mainContainer}>
            {iconType === 'png' ? (
              <img
                src={icon}
                className={dontInvert ? classes.dontInvertIconStyle : classes.iconStyle}
                alt="icon"
              />
            ) : (
              <AppIcon
                type={icon as AppIconType}
                size={AppIconSize.ExtraLarge}
                color={theme.colors.light}
                className={dontInvert ? classes.dontInvertAppIconStyle : classes.appIconStyle}
              />
            )}
            <Typography className={classes.titleTextStyle}>{title}</Typography>
            {primaryText && <Typography className={classes.textStyle}>{primaryText}</Typography>}
            {secondaryText && (
              <Typography className={classes.textStyle}>{secondaryText}</Typography>
            )}
            {ternaryText && <Typography className={classes.textStyle}>{ternaryText}</Typography>}
          </div>
        </Grid>
        <Grid item sm={4} xs={3} />
      </Grid>
    </div>
  );
};

export default UploadingScreen;
