export const SWITCH_NO_BRANCH_CODE_REDIRECTION_LINK = 'https://www.switchliving.com.au/';
export const SWITCH_SUCCESS_REDIRECTION_LINK =
  'https://www.switchliving.com.au/thank-you-book-a-room/';

export const ERRORS = {
  formFetchError: 'Error loading form! Please try again later.',
  agency: 'Error loading agency details! Please try again later.',
};

export const getBranchName = (code: string) => {
  switch (code) {
    case 'AU_SWIT_PER':
      return 'Switch Perth';

    case 'AU_SWIT_SYD':
      return 'Switch Sydney';

    case 'AU_SWIT_ADL':
      return 'Switch Adelaide';

    case 'AU_SWIT_MEL':
      return 'Switch Melbourne';

    default:
      return '';
  }
};

export const getAddressFieldLabel = (code: string) => {
  switch (code) {
    case 'AU_SWIT_PER':
      return 'Enter your room address (Start with your room number/555 Wellington)';

    case 'AU_SWIT_ADL':
      return 'Enter your room address (Start with your room number/203 North)';

    case 'AU_SWIT_MEL':
      return "Enter your room address (Start with your room number/198 A'Beckett)";

    case 'AU_SWIT_SYD':
      return 'Enter your room address (Start with your room number/4-18 Doncaster)';

    default:
      return '';
  }
};

export const LABELS = {
  heading: (code: string) => `The ${getBranchName(code)} Extend My Stay Form`,
  addressFieldLabel: (code: string) => getAddressFieldLabel(code),
  searchYourRoom: 'Search you room',
  noOptions: 'No options',
};
