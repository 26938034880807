/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Button from '../../../../component/button/Button';
import Input from '../../../../component/input/Input';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { AustraliaState } from '../../../../services/dashboard/australiaStates/australiaStates';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import {
  PostFormData,
  PostVehiclesFormData,
  StateOfIssueDL,
  Vehicles,
  VehicleTypes,
} from '../../../../store/state/MyProfileFormState';
import { LABELS, VALIDATION_SCHEMA } from './VehiclesFormConstants';
import { useStyles } from './VehiclesFormStyles';
import Text from '../../../../component/text/Text';

interface VehiclesFormProps {
  vehiclesDetails: PostVehiclesFormData | undefined;
  index: number | undefined;
  updateProfileChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postVehiclesFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
}

const VehiclesForm: React.FC<VehiclesFormProps> = ({
  index,
  postVehiclesFormDetails,
  setChildFormRef,
  setSelected,
  updateProfileChangesFlag,
  vehiclesDetails,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostVehiclesFormData> | null>(null);
  const hasVisited = !!vehiclesDetails && vehiclesDetails.hasVehicles;
  const [profileChanged, setProfileChanged] = useState<boolean>(false);

  const newVehicle: Vehicles = {
    state: '',
    make: '',
    type: '',
    year: new Date().getFullYear(),
    model: '',
    registrationNumber: '',
    identificationLink: '',
  };

  const initialValues: PostVehiclesFormData = useMemo(() => {
    return vehiclesDetails && vehiclesDetails.vehicles.length
      ? vehiclesDetails
      : {
          hasVehicles: false,
          vehicles: [newVehicle],
        };
  }, [vehiclesDetails]);

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  const updateProfileState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateProfileChangesFlag(true);
      setProfileChanged(true);
    }
  };

  const toggleHaveVehicles = (
    values: PostVehiclesFormData,
    setFieldValue: (fieldName: string, value: any) => void,
  ) => {
    updateProfileState();
    setFieldValue('hasVehicles', !values.hasVehicles);
    if (values.hasVehicles) setFieldValue('vehicles', [{ ...newVehicle }]);
  };

  const clearLastVehicle = (setFieldValue: (fieldName: string, fieldValue: any) => void) => {
    setFieldValue('vehicles', [{ ...newVehicle }]);
  };

  return (
    <div className={classes.root}>
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(data) => {
          const changeOnSubmit = data;
          changeOnSubmit.hasVehicles =
            changeOnSubmit.hasVehicles === null ? false : changeOnSubmit.hasVehicles;
          changeOnSubmit.vehicles =
            changeOnSubmit.hasVehicles === true ? changeOnSubmit.vehicles : [];

          updateProfileState();
          postVehiclesFormDetails(
            changeOnSubmit,
            () => {
              setSelected(ProfileForm.ADDRESS_FORM);
            },
            index,
          );
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          handleBlur,
          setFieldTouched,
        }) => (
          <div className={classes.formStyle}>
            <div className={classes.questionContainer}>
              <Typography variant="subtitle1">{LABELS.HAVE_VEHICLES}</Typography>
              <ToggleButton
                toggleFunction={() => toggleHaveVehicles(values, setFieldValue)}
                buttonState={values.hasVehicles}
              />
            </div>

            {values.vehicles.map((_, idx) => (
              <div
                className={values.hasVehicles ? classes.extrabox : classes.hide}
                key={`vehicle-${idx}`}
              >
                <div className={classes.inputBoxStyle}>
                  <Text textVariant="boxTitle" parentStyles={classes.sectionTitle}>{`${
                    LABELS.VEHICLE
                  } ${idx + 1}`}</Text>
                  {idx >= 0 && (
                    <Button
                      outline
                      parentStyles={classes.removeButton}
                      onPress={() => {
                        if (values.vehicles.length === 1) {
                          clearLastVehicle(setFieldValue);
                        } else {
                          values.vehicles.splice(idx, 1);
                          setFieldValue('vehicles', values.vehicles);
                        }
                        updateProfileState();
                      }}
                    >
                      {LABELS.REMOVE}
                    </Button>
                  )}
                </div>
                {/* TYPE ----------------------------------------------------------------------- // */}
                <InputSelect
                  placeholder={LABELS.TYPE}
                  value={values.vehicles[idx].type || ''}
                  values={Object.values(VehicleTypes).map((v) => ({ display: v, value: v }))}
                  setValue={(value: string) => {
                    setFieldValue(`vehicles[${idx}].type`, value);
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`vehicles[${idx}].type`)}
                  touched={get(touched, `vehicles[${idx}]type`)}
                  error={get(errors, `vehicles[${idx}]type`)}
                  title={LABELS.TYPE}
                  mandatory
                  showRequiredError={get(touched, `vehicles[${idx}]type`) || hasVisited}
                />

                {/* YEAR ----------------------------------------------------------------------- // */}
                <Input
                  placeholder={LABELS.YEAR}
                  value={values.vehicles[idx].year || ''}
                  setValue={(value: string) => {
                    setFieldValue(`vehicles[${idx}].year`, value);
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`vehicles[${idx}].year`)}
                  touched={get(touched, `vehicles[${idx}]year`)}
                  error={get(errors, `vehicles[${idx}]year`)}
                  title={LABELS.YEAR}
                  mandatory
                  showRequiredError={get(touched, `vehicles[${idx}]year`) || hasVisited}
                />

                {/* MAKE ----------------------------------------------------------------------- // */}
                <Input
                  placeholder={LABELS.MAKE}
                  value={values.vehicles[idx].make || ''}
                  setValue={(value: string) => {
                    setFieldValue(`vehicles[${idx}].make`, value);
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`vehicles[${idx}].make`)}
                  touched={get(touched, `vehicles[${idx}]make`)}
                  error={get(errors, `vehicles[${idx}]make`)}
                  title={LABELS.MAKE}
                  mandatory
                  showRequiredError={get(touched, `vehicles[${idx}]make`) || hasVisited}
                />

                {/* MODEL ----------------------------------------------------------------------- // */}
                <Input
                  placeholder={LABELS.MODEL}
                  value={values.vehicles[idx].model || ''}
                  setValue={(value: string) => {
                    setFieldValue(`vehicles[${idx}].model`, value);
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`vehicles[${idx}].model`)}
                  touched={get(touched, `vehicles[${idx}]model`)}
                  error={get(errors, `vehicles[${idx}]model`)}
                  title={LABELS.MODEL}
                  mandatory
                  showRequiredError={get(touched, `vehicles[${idx}]model`) || hasVisited}
                />

                {/* TYPE ----------------------------------------------------------------------- // */}
                <InputSelect
                  placeholder={LABELS.STATE}
                  value={values.vehicles[idx].state || ''}
                  values={StateOfIssueDL.map((v) => ({ display: v.value, value: v.value }))}
                  setValue={(value: string) => {
                    setFieldValue(`vehicles[${idx}].state`, value);
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`vehicles[${idx}].state`)}
                  touched={get(touched, `vehicles[${idx}]state`)}
                  error={get(errors, `vehicles[${idx}]state`)}
                  title={LABELS.STATE}
                  mandatory
                  showRequiredError={get(touched, `vehicles[${idx}]state`) || hasVisited}
                />

                {/* REG NUMBER ----------------------------------------------------------------------- // */}
                <Input
                  placeholder={LABELS.REGISTRATION_NUMBER}
                  value={values.vehicles[idx].registrationNumber || ''}
                  setValue={(value: string) => {
                    setFieldValue(`vehicles[${idx}].registrationNumber`, value);
                    updateProfileState();
                  }}
                  onBlur={() => setFieldTouched(`vehicles[${idx}].registrationNumber`)}
                  touched={get(touched, `vehicles[${idx}]registrationNumber`)}
                  error={get(errors, `vehicles[${idx}]registrationNumber`)}
                  title={LABELS.REGISTRATION_NUMBER}
                  mandatory
                  showRequiredError={
                    get(touched, `vehicles[${idx}]registrationNumber`) || hasVisited
                  }
                />
              </div>
            ))}

            {values.hasVehicles && (
              <Button
                parentStyles={classes.addVehicleButton}
                onPress={() => {
                  const result = values.vehicles;
                  result.push(newVehicle);
                  setFieldValue('vehicles', result);
                }}
              >
                {LABELS.ADD_ANOTHER_VEHICLE}
              </Button>
            )}

            <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
              {LABELS.BUTTON_TEXT}
            </Button>
          </div>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postVehiclesFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postVehiclesFormDetails(data, id))
      : dispatch(MyProfileAction.postVehiclesFormDetails(data));
    onSubmit();
  },
});

export default connect(null, mapDispatchToProps)(VehiclesForm);
