import { ServiceType } from '../ServiceType.data';
import TenancyApiService from '../TenancyApiService';
import TenancyApiServiceBase from '../TenancyApiServiceBase';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { GetPaymentAuthKeyResponse } from '../remoteSigning/payment/Payment';

class PaymentService {
  private readonly servicePaymentNonAuth: TenancyApiServiceBase = new TenancyApiService(
    ServiceType.RemoteSigning,
  );
  private readonly servicePaymentAuth: ApiServiceBase = new ApiService(ServiceType.PaymentAuthKey);
  public getPaymentAuthKey(
    token?: string,
  ): Promise<GetPaymentAuthKeyResponse> | GetPaymentAuthKeyResponse {
    if (token) {
      return this.servicePaymentNonAuth.get({
        route: ['paymentMethods/authKey', token],
      });
    } else {
      return this.servicePaymentAuth.get({});
    }
  }
}

const paymentService = new PaymentService();
export default paymentService;
