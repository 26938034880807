/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  ntdBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: 340,
    borderRadius: theme.units.borderRadius,
    overflow: 'hidden',
    marginBottom: theme.units.spacing * 4,
  },
  smallNtdBanner: {
    height: 220,
  },
  ntdLeftContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: theme.units.spacing * 4,
    padding: theme.units.spacing * 4,
    zIndex: 3,
  },
  smallNtdLeftContent: {
    marginTop: 0,
  },
  ntdTitle: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 6,
    marginLeft: theme.units.spacing * 2,
  },
  smallNtdTitle: {
    fontSize: 24,
    marginBottom: theme.units.spacing * 3,
    maxWidth: 220,
  },
  ntdSubtitle: {
    marginBottom: 'auto',
    marginLeft: theme.units.spacing * 2,
    fontSize: theme.font.l,
    maxWidth: 350,
    lineHeight: 1.5,
  },
  smallNtdSubtitle: {
    maxWidth: 215,
    fontSize: 16,
  },
  ntdButton: {
    height: 30,
    minWidth: 120,
    marginBottom: theme.units.spacing * 4,
  },
  smallNtdButton: {
    height: 15,
    minWidth: 80,
    marginBottom: 0,
  },
  ntdImageBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  ntdLeftImage: {
    position: 'absolute',
    width: '65%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 2,
    [`@media (max-width: 1600px)`]: {
      width: '75%',
      objectPosition: 'right',
    },
    [`@media (max-width: 1500px)`]: {
      width: '80%',
      objectPosition: 'right',
    },
    [`@media (max-width: 1200px)`]: {
      width: '100%',
      objectPosition: 'right',
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      width: '70%',
      objectPosition: 'right',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '80%',
      objectPosition: 'right',
    },
    [`@media (max-width: 700px)`]: {
      width: '85%',
      objectPosition: 'right',
    },
    [`@media (max-width: 650px)`]: {
      width: '90%',
      objectPosition: 'right',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      width: '100%',
      objectPosition: 'left',
    },
  },
  smallLeftImage: {
    width: '80%',
    objectPosition: 'right',
  },
  ntdRightImage: {
    position: 'absolute',
    right: 0,
    width: '50%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1,
  },
});
