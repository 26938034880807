import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import {
  maxContentWidth,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    // },
    backgroundColor: theme.colors.light,
    height: '100%',
  },
  contentContainer: {
    paddingLeft: '80px',
    paddingRight: '80px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...smallPageContainer,
  },
  titleContainer: {
    ...pageTitleContainer,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gettingStarted: {
    marginTop: theme.units.spacing * 8,
  },
  profileSectionRow: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr',
    },
    marginBottom: theme.units.spacing * 4,
  },
  // actionSectionRow: {
  //   display: 'grid',
  //   gridAutoFlow: 'row',
  //   gridTemplateColumns: '1fr 1fr',
  //   gridGap: theme.units.spacing * 4,
  //   [`@media (max-width: ${SIZES.md}px)`]: {
  //     gridTemplateColumns: '1fr',
  //   },
  // },
  addGroup: {
    marginBottom: theme.units.spacing * 6,
  },
  pageContent: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 12,
      maxWidth: '100%',
    },
  },
  loadingPrimary: {
    height: 150,
    marginBottom: theme.units.spacing * 4,
  },
  loadingPrimary2: {
    height: 300,
    marginBottom: theme.units.spacing * 4,
  },
  loadingSecondary: {
    height: 250,
    width: 500,
    marginBottom: theme.units.spacing * 4,
  },
  loadingSecondary2: {
    height: 300,
    width: 500,
    marginBottom: theme.units.spacing * 4,
  },
  loadingBottom: {
    height: 400,
    marginBottom: theme.units.spacing * 4,
  },
});
