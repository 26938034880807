import { PostFormData } from '../state/MyProfileFormState';

export enum MyProfileActionTypes {
  MY_PROFILE_FORM_REQUEST = 'MY_PROFILE_FORM_REQUEST',
  INTRODUCTION_FORM_REQUEST = 'INTRODUCTION_FORM_REQUEST',
  CURRENT_INCOME_FORM_REQUEST = 'CURRENT_INCOME_FORM_REQUEST',
  EMPLOYMENT_FORM_REQUEST = 'EMPLOYMENT_FORM_REQUEST',
  PETS_FORM_REQUEST = 'PETS_FORM_REQUEST',
  VEHICLES_FORM_REQUEST = 'VEHICLES_FORM_REQUEST',
  ADDRESS_FORM_REQUEST = 'ADDRESS_FORM_REQUEST',
  REFERENCE_FORM_REQUEST = 'REFERENCE_FORM_REQUEST',
  IDENTIFICATION_FORM_REQUEST = 'IDENTIFICATION_FORM_REQUEST',
  DECLARATION_FORM_REQUEST = 'DECLARATION_FORM_REQUEST',
  REF_ID_REQUEST = 'REF_ID_REQUEST',
  MY_ACCOUNT_FLAG = 'MY_ACCOUNT_FLAG',
  POST_OVERVIEW_DATA = 'POST_OVERVIEW_DATA',
  SET_NTD_ACTIVE = 'SET_NTD_ACTIVE',
  SET_NTD_PURCHASED = 'SET_NTD_PURCHASED',
  SET_NTD_DATE = 'SET_NTD_DATE',
}

export interface MyProfileFormAction {
  type: MyProfileActionTypes;
  data?: PostFormData | Error | MyProfilePostOverview;
}

export interface MyProfilePostOverview {
  firstname: string;
  email: string;
  lastname: string;
  title: string;
}

export class MyProfileAction {
  // Action for updating personal details and keeping the other data as is
  public static postOverviewFormDetails(data: MyProfilePostOverview): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.POST_OVERVIEW_DATA,
      data,
    };
  }
  public static postFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.MY_PROFILE_FORM_REQUEST,
      data,
    };
  }
  public static postIntroductionDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.INTRODUCTION_FORM_REQUEST,
      data,
    };
  }
  public static postCurrentIncomeFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.CURRENT_INCOME_FORM_REQUEST,
      data,
    };
  }
  public static postEmploymentFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.EMPLOYMENT_FORM_REQUEST,
      data,
    };
  }
  public static postPetsFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.PETS_FORM_REQUEST,
      data,
    };
  }
  public static postVehiclesFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.VEHICLES_FORM_REQUEST,
      data,
    };
  }
  public static postAddressFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.ADDRESS_FORM_REQUEST,
      data,
    };
  }
  public static postReferencesFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.REFERENCE_FORM_REQUEST,
      data,
    };
  }
  public static postIdentificationFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.IDENTIFICATION_FORM_REQUEST,
      data,
    };
  }
  public static postDeclarationFormDetails(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.DECLARATION_FORM_REQUEST,
      data,
    };
  }
  public static saveRefIdForProfile(data: PostFormData): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.REF_ID_REQUEST,
      data,
    };
  }
  public static setMyAccountFlag(data: boolean): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.MY_ACCOUNT_FLAG,
      data,
    };
  }
  public static setNTDActive(data: boolean): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.SET_NTD_ACTIVE,
      data,
    };
  }
  public static setNTDPurchased(data: boolean): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.SET_NTD_PURCHASED,
      data,
    };
  }
  public static setNTDDate(data: string): MyProfileFormAction {
    return {
      type: MyProfileActionTypes.SET_NTD_DATE,
      data,
    };
  }
}
