import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  container: {
    marginTop: theme.units.spacing * 8,
    border: `${theme.colors.borderColorLight} 2px solid`,
    borderRadius: theme.units.borderRadius,
    width: '100%',
    height: 300,
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '100%',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  textContent: {
    padding: theme.units.spacing * 8,
    flexShrink: 0,
  },
  title: {
    maxWidth: 440,
    marginBottom: theme.units.spacing * 4,
  },
  subtitle: {
    maxWidth: 440,
    marginBottom: theme.units.spacing * 8,
  },
  button: {
    height: 30,
    minWidth: 160,
  },
  agencies: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: theme.units.spacing * 4,
    marginLeft: theme.units.spacing * 4,
    width: '100%',
    marginTop: 'auto',
    alignItems: 'center',
    justifyItems: 'center',
    marginBottom: theme.units.spacing * 8,
    maxWidth: 800,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  agency: {
    width: 90,
    height: 45,
    objectFit: 'contain',
    objectPosition: 'center',
  },
});
