import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import DisclosurePage from '../../component/disclosure/DisclosurePage';
import { QuestionnaireData } from '../../component/disclosure/QuestionnaireConstants';
import { Content } from '../../helper/Content/Content.data';
import { DisclosureSignaturePostRequest } from '../../services/disclosure/Disclosure';
import { DashboardActions, StoreAgencyPayload } from '../../store/actions/DashboardActions';
import { DisclosureActions, DisclosureActionTypes } from '../../store/actions/DisclosureActions';
import { ApplicationState } from '../../store/RootReducers';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import DisclosureState from '../../store/state/DisclosureState';

interface DisclosureProps extends RouteComponentProps<{ token: string }> {
  disclosureData: DisclosureState;
  error: Error | string;
  getDisclosureDetails: (token: string, onSuccess?: () => void, onError?: () => void) => void;
  submitDisclosureForm: (data: DisclosureSignaturePostRequest) => void;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
}

export const DISCLOSURE_STEPS = {
  QUESTIONNAIRE: 0,
  SIGNATURE: 1,
  SUCCESS: 2,
  LOADING: 3,
  UPLOADING: 4,
  ERROR: 5,
  ALREADY_SIGNED: 6,
  TOKEN_EXPIRED: 7,
};

const Disclosure: FC<DisclosureProps> = ({
  match,
  disclosureData,
  error,
  getDisclosureDetails,
  storePropertyAgency,
  submitDisclosureForm,
}) => {
  const { questionnaire, user, agency, isPrimaryLessor, comments } = disclosureData;

  const [currentStep, setCurrentStep] = useState(DISCLOSURE_STEPS.LOADING);

  const [filledQuestionnaire, setFilledQuestionnaire] = useState(questionnaire);

  useEffect(() => {
    const { token } = match.params;
    getDisclosureDetails(token, () => {
      setCurrentStep(DISCLOSURE_STEPS.QUESTIONNAIRE);
    });
  }, []);

  useEffect(() => {
    if (error) {
      if (
        (error as string).includes('Remote signing token is already used') ||
        (error as string).includes('Disclosure Already Signed')
      ) {
        setCurrentStep(DISCLOSURE_STEPS.ALREADY_SIGNED);
      } else if ((error as string).includes('Expired remote signing token')) {
        setCurrentStep(DISCLOSURE_STEPS.TOKEN_EXPIRED);
      } else {
        setCurrentStep(DISCLOSURE_STEPS.ERROR);
      }
    }
  }, [error]);

  useEffect(() => {
    if (disclosureData && disclosureData.agency) {
      storePropertyAgency({ agency: disclosureData.agency.code });
    }
  }, [disclosureData]);

  const onSubmitQuestions = (data: QuestionnaireData) => {
    setFilledQuestionnaire(data.questionnaire);
    setCurrentStep(DISCLOSURE_STEPS.SIGNATURE);
  };

  const onSubmitSignatures = (signatures: Content[]) => {
    setCurrentStep(DISCLOSURE_STEPS.UPLOADING);
    submitDisclosureForm({
      disclosureFormRequest: {
        token: match.params.token,
        questions: filledQuestionnaire || [],
      },
      signatures,
      onSuccess: () => {
        setCurrentStep(DISCLOSURE_STEPS.SUCCESS);
      },
      onError: () => {
        setCurrentStep(DISCLOSURE_STEPS.ERROR);
      },
    });
  };
  return (
    <DisclosurePage
      questionnaire={questionnaire}
      isPrimaryLessor={isPrimaryLessor}
      comments={comments}
      user={user}
      agency={agency}
      currentStep={currentStep}
      onSubmitQuestions={onSubmitQuestions}
      onSubmitSignatures={onSubmitSignatures}
    />
  );
};

const error = errorMessageSelector([
  DisclosureActionTypes.GET_DISCLOSURE_DETAILS,
  DisclosureActionTypes.POST_DISCLOSURE_SIGNATURE,
]);

const mapStateToProps = (state: ApplicationState) => ({
  disclosureData: state.disclosure,
  error: error(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDisclosureDetails: (token: string, onSuccess?: () => void, onError?: () => void) => {
    dispatch(DisclosureActions.getDisclosureDetailsStart({ token, onSuccess, onError }));
  },

  submitDisclosureForm: (data: DisclosureSignaturePostRequest) => {
    dispatch(DisclosureActions.postDisclosureSignStart(data));
  },

  storePropertyAgency: (data: StoreAgencyPayload) => {
    dispatch(
      DashboardActions.storePropertyAgency({
        agency: data.agency,
        branch: data.branch || '',
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Disclosure);
