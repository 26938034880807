import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import {
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Menu,
  ListItemSecondaryAction,
  IconButton,
  List,
} from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { useStyles } from './SelectPropertyStyles';
import { LABELS } from './SelectPropertyConstants';
import { DisplayComponent } from '../../MaintenanceForm';
import { MRFormProperty } from '../../MaintenanceFormConstants';
import propertyDefaultIcon from '../../../../assets/property-default.svg';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import Input from '../../../../component/input/Input';

interface SelectPropertyProps {
  properties: MRFormProperty[];
  selectedProperty: MRFormProperty | null;
  setSelectedProperty: (property: MRFormProperty | null) => void;
  setView: React.Dispatch<React.SetStateAction<DisplayComponent>>;
  searchProperties: (searchString: string) => void;
}

export const getAddressString = (data: MRFormProperty) => {
  const streetValue = data.unitNumber
    ? `${data.unitNumber}/${data.streetNumber || ''}`
    : data.streetNumber
    ? data.streetNumber
    : '';
  const addressString = `${streetValue} ${data.streetName || ''}`;
  return addressString.trim() || data.address;
};

export const titleCase = (str: string) => {
  return str.replace(/\w*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const SelectProperty: FC<SelectPropertyProps> = ({
  properties,
  selectedProperty,
  setSelectedProperty,
  setView,
  searchProperties,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<MRFormProperty[]>(properties);
  const classes = useStyles();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fieldRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (properties.length > 0) setAnchorEl(fieldRef.current);
  }, [properties]);

  useEffect(() => {
    handleClose();
  }, [selectedProperty]);

  const updateViewList = useCallback(
    debounce((searchString: string) => {
      searchProperties(searchString);
    }, 2000),
    [],
  );

  const ITEM_HEIGHT = 80;

  return (
    <div className={classes.wrapper}>
      <Box lightBorder parentStyles={classes.paper}>
        <div className={classes.content}>
          <Text parentStyles={classes.heading} textVariant="title">
            {LABELS.heading}
          </Text>
          <Text textVariant="info" parentStyles={classes.subHeading}>
            {LABELS.subHeading}
          </Text>

          {/* Properties dropdown */}
          <div ref={fieldRef} className={classes.selectWrapper}>
            <TextField
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.currentTarget.value);
                updateViewList(e.currentTarget.value);
              }}
              fullWidth
              placeholder="Type to search"
              variant="outlined"
              className={classes.searchInput}
            />

            {selectedProperty && (
              <List disablePadding className={classes.selectedPropertyWrapper}>
                <ListItem classes={{ root: classes.selectedProperty }}>
                  <img src={propertyDefaultIcon} className={classes.propertyImage} alt="property" />
                  <ListItemText
                    primary={getAddressString(selectedProperty)}
                    secondary={`${selectedProperty.suburb && titleCase(selectedProperty.suburb)}${
                      selectedProperty.suburb && ','
                    } ${selectedProperty.state}${selectedProperty.state && ','} ${
                      selectedProperty.postcode
                    }`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setSelectedProperty(null);
                      }}
                    >
                      <CancelOutlined />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            )}
            {anchorEl && (
              <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                  },
                }}
                MenuListProps={{
                  style: {
                    width: fieldRef.current!.clientWidth || 'auto',
                  },
                }}
                onBackdropClick={handleClose}
              >
                {properties.map((property: MRFormProperty) => {
                  const { id, state, suburb, postcode } = property;
                  return (
                    <MenuItem
                      onClick={() => {
                        setSelectedProperty(property);
                      }}
                      classes={{ root: classes.menuItem }}
                      value={id}
                    >
                      <ListItem>
                        <ListItemText
                          primaryTypographyProps={{ noWrap: true }}
                          primary={`${getAddressString(property)} ${suburb && titleCase(suburb)}${
                            suburb && ','
                          } ${state}${state && ','} ${postcode}`}
                        />
                      </ListItem>
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            onPress={() => setView(DisplayComponent.USER_DETAILS)}
            disabled={!selectedProperty}
          >
            Next
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default SelectProperty;
