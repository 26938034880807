/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import MailOutline from '@mui/icons-material/MailOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { theme } from '../../../../theme/Theme';
import { RegisterUserPayload, VerificationLinkPayload } from '../../../../services/login/LoginData';
import { LoginActions } from '../../../../store/actions/LoginActions';
import { View } from '../../Login';
import { LABELS } from './VerifyEmailConstants';
import { useStyles } from './VerifyEmailStyles';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import Button from '../../../../component/button/Button';

interface VerifyEmail {
  setView: (view: View) => void;
  sendVerificationEmail: (data: VerificationLinkPayload) => void;
  userDetails: RegisterUserPayload | null;
}

const VerifyEmail: FC<VerifyEmail> = ({ setView, sendVerificationEmail, userDetails }) => {
  const [verifySentAgain, setVerifySentAgain] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleContainer}>
        <img src={sortedIcon} className={classes.icon} />
        <div className={classes.primaryTitle}>{LABELS.VERIFY_TITLE}</div>
      </div>
      <div className={classes.pageContent}>
        <div className={classes.primaryContainer}>
          <Text textVariant="boxTitle">{LABELS.VERIFY_SUBTITLE}</Text>
          <Box>
            <div className={classes.subtitleRow}>
              <MailOutline fontSize="large" className={classes.mailIcon} />
            </div>
            <Text textVariant="info" parentStyles={classes.marginTop}>
              {LABELS.VERIFY_SUBTEXT}
            </Text>
            <Text textVariant="link" parentStyles={classes.marginTop}>
              {LABELS.VERIFY_SUBTEXT2}
            </Text>

            <div className={classes.verifyEmailContainer}>
              <Text textVariant="subtitle">
                {LABELS.EMAIL_SENT}&apos;{userDetails!.email}&apos;
              </Text>
              {verifySentAgain ? (
                <div className={classes.sentButton}>{LABELS.SENT}</div>
              ) : (
                <div
                  className={classes.resendButton}
                  onMouseDown={() => {
                    sendVerificationEmail({ email: userDetails!.email });
                    setVerifySentAgain(true);
                  }}
                >
                  {LABELS.RESEND}
                </div>
              )}
            </div>

            <Text textVariant="body">
              {LABELS.VERIFY_SUBTEXT3}
              {LABELS.INFO_TEXT}
            </Text>

            <div className={classes.loginRow}>
              <Text textVariant="link">{LABELS.VERFIED}</Text>
              <Button
                parentStyles={classes.loginButton}
                outline
                onPress={() => setView(View.Login)}
              >
                {LABELS.LOGIN}
              </Button>
            </div>

            <div className={classes.backRow}>
              <Button onPress={() => setView(View.Signup)}>{LABELS.BACK}</Button>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendVerificationEmail: (data: VerificationLinkPayload) => {
    dispatch(LoginActions.postVerificationLinkStart(data));
  },
});

export default connect(null, mapDispatchToProps)(VerifyEmail);
