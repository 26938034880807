import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './DraftActiveStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../store/RootReducers';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './DraftActiveConstants';
import {
  AgencyWiseApplication,
  Application,
  ApplicationProperty,
  ApplicationsResponse,
} from '../../../../services/groups/Groups';
import propertyDefaultIcon from '../../../../assets/homePlaceholder.png';
import moment from 'moment';
import { DateFormats } from '../../../../constants/AppConstants';
import SORTED_CIRCULAR_LOGO from '../../../../assets/logosCircle/sorted.png';
import bedrooms from '../../../../assets/bed.png';
import bathrooms from '../../../../assets/bath.png';
import carspaces from '../../../../assets/car.png';
import {
  DeleteDraftRequest,
  SubmittedApplicationResponse,
} from '../../../../services/dashboard/postApplyForm/PostApplyForm.data';
import { GroupsActions } from '../../../../store/actions/GroupsActions';
import { DashboardActions, StoreAgencyPayload } from '../../../../store/actions/DashboardActions';
import { routes } from '../../../../Routes';
import { prefillForms } from '../../../../helper/PrefillFormHelper';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';

interface DraftActiveProps extends RouteComponentProps {
  groupApplications: ApplicationsResponse | undefined;
  masterProfileData: SubmittedApplicationResponse;
  deleteDraft: (data: DeleteDraftRequest) => void;
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any, // Provide type definition when type definition for applications is provided
    propertyRefId: string | null,
  ) => void; // Will replace all the any when the contract is final
  setPrefillHelperCalled: (isCalled: boolean) => void;
  resetSimilarProperties: () => void;
  setMyAccountFlag: (value: boolean) => void;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
}

const DraftActive: FC<DraftActiveProps> = ({
  masterProfileData,
  groupApplications,
  history,
  deleteDraft,
  prefillData,
  setPrefillHelperCalled,
  resetSimilarProperties,
  setMyAccountFlag,
  storePropertyAgency,
}) => {
  const classes = useStyles();

  const handleSubmit = (
    application: Application | null,
    agencyWiseApplication: AgencyWiseApplication,
  ) => {
    const { agency } = agencyWiseApplication;
    localStorage.setItem('landingURL', `/properties/${agency}`);
    storePropertyAgency({ agency });

    let propertyList: any[] = []; // TODO fix old interface

    localStorage.setItem('draftId', application!.draftId!);
    propertyList = application!.propertyList;

    prefillData(masterProfileData, false, propertyList, null);
    setPrefillHelperCalled(true);
    resetSimilarProperties();
    setMyAccountFlag(false);
    history.push(routes.properties.view(agency));
  };

  return (
    <>
      <Text textVariant="boxTitle" parentStyles={classes.applicationsTitle}>
        {LABELS.TITLE}
      </Text>
      {groupApplications &&
        groupApplications.draftApplications.agencyWiseApplications.slice(0, 1).map((agency) =>
          agency.application.slice(0, 1).map((application: Application, appIndex) => (
            <Box lightBorder parentStyles={classes.applicationContainer} key={appIndex}>
              {application.propertyList.map(
                (property: ApplicationProperty, propertyIndex: number) => (
                  <div
                    className={classes.applicationRow}
                    key={propertyIndex}
                    style={
                      propertyIndex === 0 ? { border: 'none', paddingTop: 0, marginTop: 0 } : {}
                    }
                  >
                    <div className={classes.propertyContainer}>
                      <img
                        className={classes.property}
                        src={property.photo ? property.photo : propertyDefaultIcon}
                        alt="property"
                      />
                    </div>
                    <div className={classes.applicationDetails}>
                      <div className={classes.applicationDetailsHeader}>
                        <Text textVariant="info" parentStyles={classes.lastEditedDate}>
                          Last edited:{' '}
                          {moment(application.lastModifiedDate).format(
                            DateFormats.extendedUiFormat,
                          )}
                        </Text>
                      </div>
                      <div className={classes.applicationDetailsMiddle}>
                        <div className={classes.propertyAddressContainer}>
                          <Text textVariant="title" parentStyles={classes.address1}>
                            {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                              property.streetNumber || ''
                            } ${property.streetName}`}
                          </Text>
                          <Text textVariant="info" parentStyles={classes.address2}>
                            {`${property.suburb}, ${property.state} ${property.postcode}`}
                          </Text>
                        </div>
                        <div className={classes.rentAndAgencyContainer}>
                          <div className={classes.agencyContainer}>
                            <img
                              className={classes.agencyLogo}
                              onError={(e) => {
                                (e.target as HTMLImageElement).src = SORTED_CIRCULAR_LOGO;
                              }}
                              src={agency.agencyLogo}
                              alt="agency logo"
                            />
                          </div>
                          <div className={classes.rentContainer}>
                            <Text textVariant="title" parentStyles={classes.rent}>
                              {property.rentOffer ? `$${property.rentOffer}` : '-'}
                            </Text>

                            <Text textVariant="info" parentStyles={classes.rentInfo}>
                              Rent pw
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className={classes.applicationDetailsFooter}>
                        <div className={classes.statsRow}>
                          <div className={classes.stat}>
                            <img className={classes.statIcon} src={bedrooms} alt="people stat" />
                            <div className={classes.statTextRow}>
                              <Text textVariant="info" parentStyles={classes.statNumber}>
                                3
                              </Text>
                              <Text textVariant="info" parentStyles={classes.statText}>
                                bedrooms
                              </Text>
                            </div>
                          </div>
                          <div className={classes.stat}>
                            <img
                              className={classes.statIcon}
                              src={bathrooms}
                              alt="applications stat"
                            />
                            <div className={classes.statTextRow}>
                              <Text textVariant="info" parentStyles={classes.statNumber}>
                                2
                              </Text>
                              <Text textVariant="info" parentStyles={classes.statText}>
                                bathrooms
                              </Text>
                            </div>
                          </div>
                          <div className={classes.stat}>
                            <img
                              className={classes.statIcon}
                              src={carspaces}
                              alt="applications stat"
                            />
                            <div className={classes.statTextRow}>
                              <Text textVariant="info" parentStyles={classes.statNumber}>
                                2
                              </Text>
                              <Text textVariant="info" parentStyles={classes.statText}>
                                car spaces
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
              <div className={classes.lineRow}>
                <Button
                  parentStyles={classes.buttonMarginRight}
                  outline
                  onPress={() => deleteDraft({ draftId: application.draftId! })}
                >
                  {LABELS.DELETE}
                </Button>
                <Button
                  parentStyles={classes.buttonMarginRight}
                  onPress={() => handleSubmit(application, agency)}
                >
                  {LABELS.EDIT}
                </Button>
              </div>
            </Box>
          )),
        )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  masterProfileData: state.masterProfile.masterProfileData!,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null = null,
  ) => {
    prefillForms(data, dispatch, isUpdating, propertyList, propertyRefId);
  },
  setPrefillHelperCalled: (isCalled: boolean) => {
    dispatch(DashboardActions.setPrefillHelperCalled(isCalled));
  },
  storePropertyAgency: (data: StoreAgencyPayload) => {
    dispatch(
      DashboardActions.storePropertyAgency({ agency: data.agency, branch: data.branch || '' }),
    );
  },
  deleteDraft: (data: DeleteDraftRequest) => {
    dispatch(GroupsActions.deleteDraftRequest(data));
  },
  resetSimilarProperties: () => {
    dispatch(DashboardActions.resetSimilarProperties());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DraftActive));
