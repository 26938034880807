import { makeStyles } from '@mui/styles';
import { theme } from '../../../theme/Theme';

export const useStyles = makeStyles({
  btnWprStyle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem 1.5rem 1.25rem',
  },
  btnStyle: {
    color: theme.colors.light,
    textAlign: 'center',
    backgroundColor: theme.colors.sortedPurple,
    width: 'fit-content',
  },
});
