import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  employmentTitle: {
    marginBottom: 0,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginBottom: theme.units.spacing * 4,
    },
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 6,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  removeButton: {
    minWidth: 'min-content',
  },
  moveButton: {
    marginLeft: theme.units.spacing * 3,
    minWidth: 'min-content',
  },
  mostRecent: {
    marginTop: theme.units.spacing * 2,
  },
  removeLabelStyle: {
    color: '#F88379',
    fontSize: '12px',
    lineHeight: 0,
    padding: 3,
  },
  moveLabelStyle: {
    color: '#2ca7c0',
    fontSize: '12px',
    lineHeight: 0,
    padding: 3,
  },
  inputContainerStyle: {
    paddingBottom: '8px',
  },
  inputLabelStyle: {
    paddingLeft: '16px',
  },
  dateOfBirthLabelStyle: {
    padding: '23px 0 0 16px',
    fontSize: '12px',
  },

  preferredLeaseDurationLabelStyle: {
    padding: '23px 0 0 16px',
  },
  yearMonthStyle: {
    width: '45%',
  },
  yearMonthLabelStyle: {
    paddingLeft: '22px',
  },
  selectStyles: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  genericLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16px',
    fontFamily: 'GT Walsheim,Helvetica Neue,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  questionContainer: {
    marginRight: '-16px',
    height: '70px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileNumberView: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  containerStyle: {
    'display': 'flex',
    'flexDirection': 'row',
    'paddingBottom': '16.3px',
    'justifyContent': 'space-between',
    '@media (min-width:780px)': {
      width: '45%',
    },
  },
  hide: {
    display: 'none',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '16px',
    alignItems: 'center',
  },
  groupPetIconStyle: {
    paddingRight: '16px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  addEmploymentButtonContainer: {
    width: '100%',
  },
  addEmploymentButton: {
    width: 'auto',
  },
  addEmploymentButtonText: {},
  addEmploymentDescription: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    color: theme.colors.buttonPrimary,
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      textAlign: 'right',
    },
    marginTop: '8px',
    textAlign: 'center',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  overlay: {
    float: 'right',
    right: 0,
  },
  counter: {
    minWidth: '10px',
    textAlign: 'center',
  },
  numberChangeStyle: {
    cursor: 'pointer',
  },

  errorText: {
    color: `${theme.colors.error} !important`,
  },
  errorLine: {
    borderBottom: `2px solid ${theme.colors.error} !important`,
  },
  employmentLetterlabel: {
    paddingLeft: '0px',
  },
  datePicker: globalStyles.datePicker,
  pendingLebelStyle: {
    minWidth: 140,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.units.borderRadius / 2,
    fontSize: '14px',
    fontWeight: getFonts('Medium'),
    color: theme.colors.black87,
    backgroundColor: '#ffd3d3',
    border: '#ffd3d3 2px solid',
    fontFamily: 'GT Walsheim',
    marginRight: '0.5rem',
    cursor: 'default',
    textAlign: 'center',
    lineHeight: '1.14',
    letterSpacing: '0.13px',
  },
  submittedLebelStyle: {
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 140,
    fontWeight: getFonts('Medium'),
    borderRadius: theme.units.borderRadius / 2,
    fontSize: '14px',
    color: theme.colors.black87,
    backgroundColor: '#baebf4',
    border: '#baebf4 2px solid',
    fontFamily: 'GT Walsheim',
    marginRight: '0.5rem',
    cursor: 'default',
    textAlign: 'center',
    lineHeight: '1.14',
    letterSpacing: '0.13px',
  },
  submitStatusStyle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  resendEmailBtnStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: '0.875rem',
    letterSpacing: '0.13px',
    textAlign: 'center',
    color: theme.colors.light,
    backgroundColor: theme.colors.secondary,
    marginTop: '1rem',
    marginBottom: theme.units.spacing * 4,
    textTransform: 'none',
    height: '1.875rem',
  },
  warningTextStyle: {
    paddingTop: '15px',
    paddingBottom: '15px',
    fontSize: 14,
    color: '#9e9e9e',
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    letterSpacing: '-0.2px',
  },
  extrabox: {
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '20px 20px 0px 20px',
    marginBottom: '20px',
    marginTop: '5px',
  },
  firstBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '20px 20px 0px 20px',
    marginBottom: '20px',
  },
  incomeListed: {
    background: theme.colors.fadedPurple,
    padding: 15,
    color: theme.colors.black87,
    borderRadius: 6,
    marginBottom: 12,
  },
  manualButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: theme.units.spacing * 4,
  },
  addressManualButton: {
    backgroundColor: theme.colors.success,
    fontSize: '12px',
    fontWeight: 'normal',
    padding: '10px 20px 10px 20px',
    color: theme.colors.light,
    textTransform: 'none',
  },
  googleSearchInput: {
    'padding': '7px 0px',
    'backgroundColor': theme.colors.light,
    'border': 0,
    'fontSize': '1em',
    'borderBottom': '2px solid rgba(0, 0, 0, 0.15)',
    'fontWeight': 450,
    'fontFamily': 'GT Walsheim',
    'backgroundImage': 'none',
    'outline': '0',
    '&:hover': {
      outline: '0',
      'borderBottom': '2px solid 2px solid #1f0d33',
    },
  },
  addressIcon: {
    position: 'absolute',
    right: 0,
    top: 20,
  },
  phoneIcon: {
    position: 'absolute',
    right: 0,
    top: 40,
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
  fileInputTitle: {
    marginBottom: theme.units.spacing * 2,
    fontWeight: 500,
  },
  fileInputDescription: {
    marginBottom: theme.units.spacing * 2,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.inputBackground,
  },
  inputStyle: {
    border: 'none',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
    },
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    flex: 1,
    margin: theme.units.spacing * 2,
    marginLeft: theme.units.spacing * 3,
    position: 'relative',
  },
  errorInput: {
    border: `${theme.colors.error} 2px solid`,
  },
  errorTextStyle: {
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
    color: theme.colors.error,
    marginBottom: theme.units.spacing,
  },
  disabled: {
    opacity: 0.4,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontSize: theme.font.s,
    marginLeft: theme.units.spacing,
    fontWeight: getFonts('Medium'),
  },
  endElement: {
    marginLeft: theme.units.spacing * 2,
  },
  endStatus: {
    marginLeft: theme.units.spacing,
    width: 25,
    height: 25,
    marginRight: theme.units.spacing * 2,
  },
});
