/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  searchContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.units.borderRadius / 1.5,
    backgroundColor: theme.colors.inputBackground,
    border: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    cursor: 'pointer',
    transition: 'all 0.5s ease 0s',
    marginBottom: theme.units.spacing * 12,
    marginTop: -30,
    width: '100%',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      flexDirection: 'column',
    },
  },
  searchContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      justifyContent: 'space-between',
      marginTop: theme.units.spacing * 2,
      borderTop: `${theme.colors.borderColorLight} 2px solid`,
      paddingTop: theme.units.spacing * 2,
    },
    [`@media (max-width: 830px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  agencyPickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.units.spacing * 2,
    borderLeft: `${theme.colors.borderColorLight} 2px solid`,
    paddingLeft: theme.units.spacing * 2,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginLeft: 0,
      borderLeft: 0,
      paddingLeft: 0,
    },
  },
  groupPickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.units.spacing * 2,
    borderLeft: `${theme.colors.borderColorLight} 2px solid`,
    paddingLeft: theme.units.spacing * 2,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginRight: 'auto',
    },
    [`@media (max-width: 830px)`]: {
      width: '100%',
      marginTop: theme.units.spacing * 2,
      borderTop: `${theme.colors.borderColorLight} 2px solid`,
      paddingTop: theme.units.spacing * 2,
      marginLeft: 0,
      borderLeft: 0,
      paddingLeft: 0,
    },
  },
  searchIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
    objectPosition: 'center',
    marginLeft: theme.units.spacing * 4,
  },
  searchInputContainer: {
    marginBottom: '0px !important',
    marginRight: `${theme.units.spacing * 2}px !important`,
  },
  searchInputInner: {
    border: 'none !important',
  },
  agency: {
    height: 30,
    width: 30,
    objectFit: 'contain',
    objectPosition: 'center',
    flexShrink: 0,
    borderRadius: 500,
    border: `${theme.colors.borderColorLight} 1px solid`,
    marginLeft: theme.units.spacing * 3,
  },
  groupsContainer: {
    marginLeft: theme.units.spacing * 6,
    display: 'flex',
    alignItems: 'center',
  },
  gravatar: {
    height: 30,
    width: 30,
    minWidth: 30,
    fontSize: 12,
  },
  searchButtonTop: {
    display: 'none',
    minWidth: 60,
    marginLeft: theme.units.spacing * 3,
    marginRight: theme.units.spacing * 3,
    [`@media (max-width: 830px)`]: {
      display: 'block',
    },
  },
  searchButton: {
    minWidth: 60,
    marginLeft: theme.units.spacing * 3,
    marginRight: theme.units.spacing * 3,
    [`@media (max-width: 830px)`]: {
      display: 'none',
    },
  },

  menu: {
    position: 'absolute',
    top: 68,
    left: 8,
    right: 8,
    margin: 'auto',
    minWidth: 'max-content',
    height: 'max-content',
    maxHeight: 150,
    overflow: 'scroll',
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    backgroundColor: theme.colors.inputBackground,
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColorLight} 2px solid`,
    zIndex: 10,
  },
  menuItem: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    padding: theme.units.spacing * 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightGrey,
    },
  },
  noOptionsMsg: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.grey,
    padding: theme.units.spacing * 4,
    textAlign: 'center',
  },
});
