import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },
  selectStyles: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  errorText: {
    color: `${theme.colors.error} !important`,
  },
  errorPhoneInput: {
    borderBottom: `2px  solid ${theme.colors.error}`,
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
  },
  sectionTitle: {
    marginBottom: 0,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginBottom: theme.units.spacing * 4,
    },
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 6,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  genericLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16px',
    fontFamily: 'GT Walsheim,Helvetica Neue,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  questionContainer: {
    marginRight: '-16px',
    height: '70px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  referenceNumberLabelStyle: {
    fontSize: '16px',
    fontWeight: 500,
  },
  removeButton: {
    minWidth: 'min-content',
  },
  moveButton: {
    marginLeft: theme.units.spacing * 3,
    minWidth: 'min-content',
  },
  removeLabelStyle: {
    color: '#F88379',
    lineHeight: 0,
    fontSize: '12px',
    padding: 3,
  },
  inputContainerStyle: {
    paddingBottom: '8px',
  },
  inputLabelStyle: {
    paddingLeft: '16px',
  },
  dateOfBirthLabelStyle: {
    padding: '23px 0 0 16px',
    fontSize: '12px',
  },

  preferredLeaseDurationLabelStyle: {
    padding: '23px 0 0 16px',
  },
  yearMonthStyle: {
    width: '45%',
  },
  yearMonthLabelStyle: {
    paddingLeft: '22px',
  },
  doYouHaveGuarantorLabelStyle: {
    padding: '28px 0 22px 16px',
  },
  mobileNumberView: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  containerStyle: {
    'display': 'flex',
    'flexDirection': 'row',
    'paddingBottom': '16.3px',
    'justifyContent': 'space-between',
    '@media (min-width:780px)': {
      width: '45%',
    },
  },
  hide: {
    display: 'none',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '16px',
    alignItems: 'center',
  },
  groupPetIconStyle: {
    paddingRight: '16px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  addReferenceButtonContainer: {
    textAlign: 'center',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      textAlign: 'right',
    },
    marginTop: '8px',
    textAlign: 'center',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  overlay: {
    float: 'right',
    right: 0,
  },
  addReferenceButton: {
    width: 'auto',
  },
  addReferenceButtonText: {},
  addReferenceDescription: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    color: theme.colors.buttonPrimary,
  },
  counter: {
    minWidth: '10px',
    textAlign: 'center',
  },
  numberChangeStyle: {
    cursor: 'pointer',
  },
  pendingLebelStyle: {
    minWidth: 140,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.units.borderRadius / 2,
    fontSize: '14px',
    fontWeight: getFonts('Medium'),
    color: theme.colors.black87,
    backgroundColor: '#ffd3d3',
    border: '#ffd3d3 2px solid',
    fontFamily: 'GT Walsheim',
    marginRight: '0.5rem',
    cursor: 'default',
    textAlign: 'center',
    lineHeight: '1.14',
    letterSpacing: '0.13px',
  },
  submittedLebelStyle: {
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 140,
    fontWeight: getFonts('Medium'),
    borderRadius: theme.units.borderRadius / 2,
    fontSize: '14px',
    color: theme.colors.black87,
    backgroundColor: '#baebf4',
    border: '#baebf4 2px solid',
    fontFamily: 'GT Walsheim',
    marginRight: '0.5rem',
    cursor: 'default',
    textAlign: 'center',
    lineHeight: '1.14',
    letterSpacing: '0.13px',
  },
  submitStatusStyle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  resendEmailBtnStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: '0.875rem',
    letterSpacing: '0.13px',
    textAlign: 'center',
    color: theme.colors.light,
    backgroundColor: theme.colors.secondary,
    marginTop: '1rem',
    marginBottom: theme.units.spacing * 4,
    textTransform: 'none',
    height: '1.875rem',
  },
  warningTextStyle: {
    paddingBottom: 12,
    fontSize: 14,
    color: '#9e9e9e',
    fontFamily: 'GT Walsheim',
    fontWeight: 500,
    letterSpacing: '-0.2px',
  },
  extrabox: {
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '20px',
    marginBottom: '20px',
  },
  phoneIcon: {
    position: 'absolute',
    right: 0,
    top: 40,
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
  fileInputTitle: {
    marginBottom: theme.units.spacing * 2,
    fontWeight: 500,
  },
  fileInputDescription: {
    marginBottom: theme.units.spacing * 2,
  },
});
