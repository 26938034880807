import {
  LoginPayload,
  RegisterUserPayload,
  VerificationLinkPayload,
  VerifyUserPayload,
  RefreshTokenPayload,
  FindUserPayload,
  LoginResponse,
  RegisterUserResponse,
  RegisterClientResponse,
  RefreshTokenResponse,
  FindUserResponse,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  GuestTokenResponse,
} from '../../services/login/LoginData';

export enum LoginActionTypes {
  LOGIN = 'LOGIN',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',

  REGISTER_USER = 'REGISTER_USER',
  REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS',
  REGISTER_USER_ERROR = 'REGISTER_USER_ERROR',

  REGISTER_CLIENT_REQUEST = 'REGISTER_CLIENT_REQUEST',
  REGISTER_CLIENT_SUCCESS = 'REGISTER_CLIENT_SUCCESS',
  REGISTER_CLIENT_ERROR = 'REGISTER_CLIENT_ERROR',

  VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS',
  VERIFY_USER_ERROR = 'VERIFY_USER_ERROR',

  VERIFICATION_LINK_REQUEST = 'VERIFICATION_LINK_REQUEST',
  VERIFICATION_LINK_SUCCESS = 'VERIFICATION_LINK_SUCCESS',
  VERIFICATION_LINK_ERROR = 'VERIFICATION_LINK_ERROR',

  REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR',

  FIND_USER_REQUEST = 'FIND_USER_REQUEST',
  FIND_USER_SUCCESS = 'FIND_USER_SUCCESS',
  FIND_USER_ERROR = 'FIND_USER_ERROR',

  FORGOT_PASSWORD_LINK_REQUEST = 'FORGOT_PASSWORD_LINK_REQUEST',
  FORGOT_PASSWORD_LINK_SUCCESS = 'FORGOT_PASSWORD_LINK_SUCCESS',
  FORGOT_PASSWORD_LINK_ERROR = 'FORGOT_PASSWORD_LINK_ERROR',

  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',

  GUEST_TOKEN_REQUEST = 'GUEST_TOKEN_REQUEST',
  GUEST_TOKEN_SUCCESS = 'GUEST_TOKEN_SUCCESS',
  GUEST_TOKEN_ERROR = 'GUEST_TOKEN_ERROR',

  CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR',

  CLOSE_USER_CONFIRMATION_DIALOG = 'CLOSE_USER_CONFIRMATION_DIALOG',

  CLEAR_ERRORS = 'CLEAR_ERRORS',

  REFRESH_MASTER_PROFILE = 'REFRESH_MASTER_PROFILE',
}

export interface LoginAction {
  type: LoginActionTypes;
  data?:
    | LoginPayload
    | RegisterUserPayload
    | VerificationLinkPayload
    | ForgotPasswordPayload
    | VerifyUserPayload
    | ResetPasswordPayload
    | FindUserPayload
    | RefreshTokenPayload
    | RegisterClientResponse
    | RegisterUserResponse
    | LoginResponse
    | RefreshTokenResponse
    | FindUserResponse
    | Error;
}

export class LoginActions {
  public static postLoginStart(data: LoginPayload): LoginAction {
    return {
      type: LoginActionTypes.LOGIN_REQUEST,
      data,
    };
  }
  public static postLoginSuccess(data: LoginResponse): LoginAction {
    return {
      type: LoginActionTypes.LOGIN_SUCCESS,
      data,
    };
  }
  public static postLoginError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.LOGIN_ERROR,
      data: error,
    };
  }

  public static postRegisterUserStart(data: RegisterUserPayload): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_REQUEST,
      data,
    };
  }
  public static postRegisterUserSuccess(data: RegisterUserResponse): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_SUCCESS,
      data,
    };
  }
  public static postRegisterUserError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_ERROR,
      data: error,
    };
  }

  public static postRegisterClientStart(): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_CLIENT_REQUEST,
    };
  }
  public static postRegisterClientSuccess(data: RegisterClientResponse): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_CLIENT_SUCCESS,
      data,
    };
  }
  public static postRegisterClientError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.REGISTER_USER_ERROR,
      data: error,
    };
  }

  public static postVerificationLinkStart(data: VerificationLinkPayload): LoginAction {
    return {
      type: LoginActionTypes.VERIFICATION_LINK_REQUEST,
      data,
    };
  }
  public static postVerificationLinkSuccess(): LoginAction {
    return {
      type: LoginActionTypes.VERIFICATION_LINK_SUCCESS,
    };
  }
  public static postVerificationLinkError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.VERIFICATION_LINK_ERROR,
      data: error,
    };
  }

  public static postVerifyUserStart(data: VerifyUserPayload): LoginAction {
    return {
      type: LoginActionTypes.VERIFY_USER_REQUEST,
      data,
    };
  }
  public static postVerifyUserSuccess(): LoginAction {
    return {
      type: LoginActionTypes.VERIFY_USER_SUCCESS,
    };
  }
  public static postVerifyUserError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.VERIFY_USER_ERROR,
      data: error,
    };
  }

  public static postRefreshTokenStart(data: RefreshTokenPayload): LoginAction {
    return {
      type: LoginActionTypes.REFRESH_TOKEN_REQUEST,
      data,
    };
  }
  public static postRefreshTokenSuccess(data: RefreshTokenResponse): LoginAction {
    return {
      type: LoginActionTypes.REFRESH_TOKEN_SUCCESS,
      data,
    };
  }
  public static postRefreshTokenError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.REFRESH_TOKEN_ERROR,
      data: error,
    };
  }

  public static getUserDetails(data: FindUserPayload): LoginAction {
    return {
      type: LoginActionTypes.FIND_USER_REQUEST,
      data,
    };
  }
  public static getUserDetailsSuccess(data: FindUserResponse): LoginAction {
    return {
      type: LoginActionTypes.FIND_USER_SUCCESS,
      data,
    };
  }
  public static getUserDetailError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.FIND_USER_ERROR,
      data: error,
    };
  }

  public static postForgotPasswordLinkStart(data: ForgotPasswordPayload): LoginAction {
    return {
      type: LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST,
      data,
    };
  }
  public static postForgotPasswordSuccess(): LoginAction {
    return {
      type: LoginActionTypes.FORGOT_PASSWORD_LINK_SUCCESS,
    };
  }
  public static postForgotPasswordError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.FORGOT_PASSWORD_LINK_ERROR,
      data: error,
    };
  }

  public static resetPasswordStart(data: ResetPasswordPayload): LoginAction {
    return {
      type: LoginActionTypes.RESET_PASSWORD_REQUEST,
      data,
    };
  }
  public static resetPasswordSuccess(): LoginAction {
    return {
      type: LoginActionTypes.RESET_PASSWORD_SUCCESS,
    };
  }
  public static resetPasswordError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.RESET_PASSWORD_ERROR,
      data: error,
    };
  }

  public static clearLoginErrror() {
    return { type: LoginActionTypes.CLEAR_LOGIN_ERROR };
  }

  public static clearErrors() {
    return { type: LoginActionTypes.CLEAR_ERRORS };
  }

  public static closeUserConfirmationDialog() {
    return { type: LoginActionTypes.CLOSE_USER_CONFIRMATION_DIALOG };
  }

  public static getGuestToken() {
    return {
      type: LoginActionTypes.GUEST_TOKEN_REQUEST,
    };
  }
  public static getGuestTokenSuccess(data: GuestTokenResponse) {
    return {
      type: LoginActionTypes.GUEST_TOKEN_SUCCESS,
      data,
    };
  }
  public static getGuestTokenFailure(error: Error) {
    return {
      type: LoginActionTypes.GUEST_TOKEN_ERROR,
      data: error,
    };
  }

  public static refreshMasterProfile() {
    return {
      type: LoginActionTypes.REFRESH_MASTER_PROFILE,
    };
  }
}
