/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ApiServiceBase from '../ApiServiceBase';
import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import {
  CreateMaintenanceRequestSuccessResponse,
  CreateMaintenanceRequestStartRequest,
} from './CreateMaintenanceRequest/CreateMaintenanceRequestPayload';
import { MultipartFormData } from '../MultipartFormData.data';

const serviceType = ServiceType.maintenanceRequest;
const bffServiceTypeForId = ServiceType.bffMaintenanceRequestId;
export class MaintenanceRequestService {
  /**
   * We have two instance of service here:
   * bffService are intercepted by bff and starts with URI "/bff/api/trades".
   * service is not intercepted and directly rerouted to trades and starts with URI "trades/api".
   * Use either of them accordingly
   */
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  private readonly bffServiceForId: ApiServiceBase = new ApiService(bffServiceTypeForId);

  /* istanbul ignore next */
  public createMaintenanceRequest(
    request: CreateMaintenanceRequestStartRequest,
  ): Promise<CreateMaintenanceRequestSuccessResponse> | CreateMaintenanceRequestSuccessResponse {
    const data: MultipartFormData[] = [];
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        if (key === 'images') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          for (const photo of request[key]!) {
            data.push({
              name: key,
              content: photo,
            });
          }
        } else {
          data.push({
            name: key,
            content: request[key],
          });
        }
      }
    }
    return this.service.postMultipartData<CreateMaintenanceRequestSuccessResponse>({}, data, {
      tradesBffBaseUrl: true,
    });
  }
}

const maintenanceRequestService = new MaintenanceRequestService();
export default maintenanceRequestService;
