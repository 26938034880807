import { AddressComponents } from '../equifax/Equifax';

export enum UserSalutation {
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Ms = 'Ms.',
}

export enum UserType {
  Primary = 'Primary Tenant',
  Secondary = 'Secondary Tenant',
  Tenant = 'Tenant',
  Landlord = 'Landlord',
  Agent = 'Agent',
  Admin = 'Admin',
  User = 'User',
  Company = 'Company',
}

export enum UserField {
  LeasingAgent,
  ManagingAgent,
  SecondaryAgents,
  Lessor,
  PrimaryTenant,
  SecondaryTenants,
}

export enum UserIdentifierType {
  DriversLicense = 'Drivers license',
  Passport = 'Passport',
  Medicare = 'Medicare',
}

export const UserIdentificationPoints = {
  PASSPORT: 40,
  DRIVING_LICENSE: 40,
  BIRTH_CERTIFICATE: 30,
  MEDICARE: 20,
  OTHER: 10,
};

export interface CompanyResponse {
  readonly id: number | null;
  readonly name: string;
  readonly displayName: string;
  readonly pmsCode: string;
  readonly postCode: string;
  readonly state: string;
  readonly abn: string;
  readonly acn: string;
  readonly email: string;
  readonly phone: string;
  readonly address: string;
  readonly users: GetUserResponse[];
}

export interface GetUserResponse {
  id: number;
  userType: UserType;
  name: string;
  middleName?: string;
  surname: string;
  email: string;
  phone: string;
  salutation?: UserSalutation | null | undefined;
  dob?: string | null | undefined;
  idType?: UserIdentifierType;
  idNumber?: string;
  idExpiry?: string | null | undefined;
  address?: string | null | undefined;
  active?: boolean;
  branches?: number[] | null | undefined;
  isCompany?: boolean;
  companyResponse: null | CompanyResponse;
  preferredEmail: string | null | undefined;
  addressComponents: AddressComponents | null | undefined;
}
