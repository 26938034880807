import { CreateMaintenanceRequestSuccessResponse } from '../../services/MaintenanceRequest/CreateMaintenanceRequest/CreateMaintenanceRequestPayload';

export enum MaintenanceRequestStatus {
  RECEIVED = 'received',
  CLOSED = 'closed',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  LANDLORD_ATTENDING = 'landlord_attending',
}

export enum RequestCategory {
  BEDROOM = 'BEDROOM',
  LIVING_ROOM = 'LIVING_ROOM',
  DINING_ROOM = 'DINING_ROOM',
  RUMPUS_ROOM = 'RUMPUS_ROOM',
  STUDY = 'STUDY',
  KITCHEN = 'KITCHEN',
  PANTRY = 'PANTRY',
  LAUNDRY = 'LAUNDRY',
  BATHROOM = 'BATHROOM',
  HALLWAY = 'HALLWAY',
  ENTRANCE = 'ENTRANCE',
}

export enum WorkorderCategory {
  CARPENTER = 'CARPENTER',
  ELECTRICIAN = 'ELECTRICIAN',
  PAINTER = 'PAINTER',
  PLUMBER = 'PLUMBER',
  LOCKSMITH = 'LOCKSMITH',
  ARBORIST = 'ARBORIST',
  ASBESTOS_CLASS_A = 'ASBESTOS_CLASS_A',
  BUILDER = 'BUILDER',
  CABINET_MAKER = 'CABINET_MAKER',
  CLEANING = 'CLEANING',
  CONCRETER = 'CONCRETER',
  FENCER = 'FENCER',
  FLOOR_COVERINGS = 'FLOOR_COVERINGS',
  GARAGE_DOOR_INSTALLER = 'GARAGE_DOOR_INSTALLER',
  GLAZIER = 'GLAZIER',
  POOL = 'POOL',
  ROOFER = 'ROOFER',
  SHOWER_SCREENS = 'SHOWER_SCREENS',
  SOLAR_ELECTRICIAN = 'SOLAR_ELECTRICIAN',
  TILER = 'TILER',
  WATERPROOFER = 'WATERPROOFER',
  SECURITY = 'SECURITY',
  AIR_CONDITIONING_AND_HEATING = 'AIR_CONDITIONING_AND_HEATING',
  ANTENNAS = 'ANTENNAS',
  APPLIANCES = 'APPLIANCES',
  BATHROOMS = 'BATHROOMS',
  BLINDS_AND_CURTAINS = 'BLINDS_AND_CURTAINS',
  CARETAKING = 'CARETAKING',
  CARPET_CLEANING = 'CARPET_CLEANING',
  CHIMNEY_SWEEPS = 'CHIMNEY_SWEEPS',
  COMM_PLUMBERS = 'COMM_PLUMBERS',
  DAMP_CONTROL = 'DAMP_CONTROL',
  SAFETY = 'SAFETY',
  FIRE = 'FIRE',
  GARDENING = 'GARDENING',
  GUTTERING = 'GUTTERING',
  HANDYMEN = 'HANDYMEN',
  LETTERBOX = 'LETTERBOX',
  LIFTS = 'LIFTS',
  MOULD = 'MOULD',
  PEST_CONTROL = 'PEST_CONTROL',
  PHONE_LINE_REPAIRS = 'PHONE_LINE_REPAIRS',
  PUMP = 'PUMP',
  SMOKE_ALARM = 'SMOKE_ALARM',
  LEGAL = 'LEGAL',
  WATER_DAMAGE = 'WATER_DAMAGE',
  WINDOW_CLEANING = 'WINDOW_CLEANING',
}

export enum WorkorderStatus {
  INCOMPLETE = 'incomplete',
  REQUESTING_QUOTE = 'requesting_quote',
  APPROVED_OFF_PLATFORM = 'approved_off_platform',
  NEW_QUOTE = 'new_quote',
  NEW_VARIATION_QUOTE = 'new_variation_quote',
  NEW_ON_SITE_QUOTE = 'new_on_site_quote',
  IN_PROGRESS = 'in_progress',
  VARIATION_IN_PROGRESS = 'variation_in_progress',
  COMPLETE = 'complete',
  CANCEL = 'cancel',
}

// tslint:disable-next-line
export interface MaintenanceRequestData extends CreateMaintenanceRequestSuccessResponse {}
