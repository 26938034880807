import {
  CheckboxAnswersValues,
  DisclosureFormQuestion,
  DisclosureFormQuestionTypes,
} from '../../services/disclosure/Disclosure';

export interface QuestionnaireData {
  questionnaire: DisclosureFormQuestion[];
}

export const ERRORS = {
  required: 'Required',
};

export const isQuestionValid = (questions: DisclosureFormQuestion[], index: number) => {
  const question = questions[index];
  let isValid = true;

  if (question.dependencyQuestionId && question.dependencyAnswer) {
    // Question is conditionally Valid based on another questions answer
    isValid = false;
    const dependencyQuestion = questions.find((q) => q.id === question.dependencyQuestionId)!!;
    switch (dependencyQuestion.type) {
      case DisclosureFormQuestionTypes.Checkbox:
        // Check if Dependency Question is Answered is Dependency Answer
        if (
          dependencyQuestion.answers[
            dependencyQuestion.options.findIndex((o) => o === question.dependencyAnswer)
          ] === CheckboxAnswersValues.On
        ) {
          isValid = true;
        }
        break;

      case DisclosureFormQuestionTypes.Text:
        if (dependencyQuestion.answers[0]) {
          isValid = true;
        }
        break;
      default:
    }
  }
  return isValid;
};

const hasError = (questions: DisclosureFormQuestion[], index: number) => {
  const question = questions[index];

  const { required } = question;
  const isValid = isQuestionValid(questions, index);

  if (!required || !isValid) {
    return false;
  }

  switch (question.type) {
    case DisclosureFormQuestionTypes.Checkbox:
      if (!question.answers.filter((o) => o !== CheckboxAnswersValues.Off).length) {
        return true;
      }
      break;

    case DisclosureFormQuestionTypes.Text:
      if (!question.answers[0]) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

export const validation = (data: QuestionnaireData) => {
  let hasErrors = false;
  const errors = data.questionnaire.map((question, index) => {
    if (hasError(data.questionnaire, index)) {
      hasErrors = true;
      return { answers: ERRORS.required };
    }
    return {};
  });

  if (hasErrors) return { questionnaire: errors };
  else return {};
};

export const getInitialAnswer = (question: DisclosureFormQuestion) => {
  switch (question.type) {
    case DisclosureFormQuestionTypes.Checkbox:
      return question.options.map(() => CheckboxAnswersValues.Off); // Keep all Options Unchecked initially

    case DisclosureFormQuestionTypes.Text:
      return question.defaultAnswer || [''];
    case DisclosureFormQuestionTypes.Radio:
    default:
      return [''];
  }
};

export const INPUT_DEFAULT_MAX_LENGTH = 70;
