import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import { useStyles } from './MaintenanceFormStyles';
import CreateMaintenanceRequestForm from './components/MaintenanceRequest/CreateMaintenanceRequestForm';
import SelectProperty from './components/SelectProperty/SelectProperty';
import useFetch from '../../hooks/UseFetch';
import propertyService from '../../services/dashboard/PropertyService';
import { LABELS, MRFormProperty } from './MaintenanceFormConstants';
import { ApplicationState } from '../../store/RootReducers';
import PersonalDetails from './components/PersonalDetails/PersonalDetails';
import { PersonalDetailsFormValues } from './components/PersonalDetails/PersonalDetailsConstants';
import { MaintenanceRequestActions } from '../../store/actions/MaintenanceRequestActions';
import { useParams } from 'react-router';
import ProgressBar from '../../component/progressBar/ProgressBar';
import CreateMaintenanceRequestSuccess from './components/Success/Success';

export enum DisplayComponent {
  PROPERTY = 'PROPERTY',
  USER_DETAILS = 'USER_DETAILS',
  FORM = 'FORM',
  SUCCESS = 'SUCCESS',
}

interface TenantMRPathParams {
  agency: string;
}

interface MaintenanceFormProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const MaintenanceForm: FC<MaintenanceFormProps> = ({
  createMaintenanceRequestSuccess,
  resetCreateRequestState,
}) => {
  // state
  // this check is done to ensure that persisted success state is cleared on reload
  const classes = useStyles();
  const [initialStateCheckDone, setInitialStateCheckDone] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<MRFormProperty | null>(null);
  const [userDetails, setUserDetails] = useState<PersonalDetailsFormValues | null>(null);
  const [view, setView] = useState<DisplayComponent>(DisplayComponent.PROPERTY);

  const { state: propertyData, fetchData: getProperties } = useFetch(
    propertyService.searchProperties.bind(propertyService),
    undefined,
  );

  // effects
  useEffect(() => {
    if (createMaintenanceRequestSuccess) {
      setView(DisplayComponent.SUCCESS);
    }
  }, [createMaintenanceRequestSuccess]);

  useEffect(() => {
    if (createMaintenanceRequestSuccess) {
      resetCreateRequestState();
    }
    setInitialStateCheckDone(true);
  }, []);

  // functions and variables
  const onPropertyChange = (property: MRFormProperty) => {
    setSelectedProperty(property);
  };

  const searchProperties = useCallback(async (searchString: string) => {
    try {
      await getProperties({ agency, searchString });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getActiveStep = useCallback((dashboardView: DisplayComponent) => {
    switch (dashboardView) {
      case DisplayComponent.PROPERTY:
        return 0;
      case DisplayComponent.USER_DETAILS:
        return 1;
      case DisplayComponent.FORM:
        return 2;
      default:
        return undefined;
    }
  }, []);

  const setPersonalDetails = (values: PersonalDetailsFormValues) => {
    setUserDetails(values);
  };

  const onCreateAnother = () => {
    setUserDetails(null);
    setSelectedProperty(null);
    resetCreateRequestState();
    setView(DisplayComponent.PROPERTY);
  };
  const { agency } = useParams<TenantMRPathParams>();

  const activeStep = useMemo(() => getActiveStep(view), [view]);

  return initialStateCheckDone ? (
    <>
      <div className={classes.content}>
        <AgencyBackground agencyContainerHeight="240px" isMaintenance />
        <div className={classes.pageContainer}>
          {view === DisplayComponent.SUCCESS ? (
            <CreateMaintenanceRequestSuccess createAnother={onCreateAnother!} />
          ) : (
            <div className={classes.contentContainer}>
              <ProgressBar
                activeStep={activeStep || 0}
                steps={[LABELS.property, LABELS.userDetails, LABELS.submit]}
              />
            </div>
          )}
          <div className={classes.contentContainer}>
            {view === DisplayComponent.PROPERTY && (
              <SelectProperty
                selectedProperty={selectedProperty}
                setSelectedProperty={onPropertyChange}
                properties={propertyData.data || []}
                setView={setView}
                searchProperties={searchProperties}
              />
            )}

            {view === DisplayComponent.USER_DETAILS && (
              <PersonalDetails
                userDetails={userDetails}
                setView={setView}
                setPersonalDetails={setPersonalDetails}
              />
            )}

            {view === DisplayComponent.FORM && (
              <CreateMaintenanceRequestForm
                userDetails={userDetails!}
                property={selectedProperty!}
                setView={setView}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  createMaintenanceRequestSuccess: state.createMaintenanceRequestData.success,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  resetCreateRequestState: () =>
    dispatch(MaintenanceRequestActions.createMaintenanceRequestReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceForm);
