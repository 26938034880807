import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  title: {
    fontSize: '36px',
  },
  closeIcon: {
    width: 30,
    height: 30,
    cursor: 'pointer',
    marginLeft: '60px',
  },
  subtitleText: {
    color: theme.colors.darkGrey,
    fontSize: '16px',
    marginBottom: '20px',
  },
  buttonContainer: {
    margin: 'auto',
    width: '100%',
    maxWidth: '1000px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '15px',
  },
  primaryButton: {
    // marginLeft: '15px',
    // width: '50%',
    // height: '60px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: theme.colors.sortedPurple,
    // borderRadius: '6px',
    // color: theme.colors.light,
    // cursor: 'pointer',
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   marginLeft: '0px',
    //   width: '100%',
    // },
  },
  secondaryButton: {
    // width: '50%',
    // height: '60px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: theme.colors.light,
    // border: `${theme.colors.borderColor} 2px solid`,
    // borderRadius: '6px',
    // color: theme.colors.black87,
    // cursor: 'pointer',
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   width: '100%',
    //   marginRight: '15px',
    // },
    marginRight: theme.units.spacing * 4,
  },
  infoBox: {
    borderRadius: '6px',
    padding: '15px',
    backgroundColor: theme.colors.fadedBlue,
    color: theme.colors.success,
  },
  link: {
    color: theme.colors.success,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
    border: `#ededed 2px solid`,
  },
  tabsContainer: {
    display: 'flex',
    height: '50px',
    marginBottom: '15px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tab: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    color: theme.colors.grey,
    height: '100%',
    flex: 1,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '8px',
    cursor: 'pointer',
    '&:first-child': {
      marginRight: '12px',
    },
  },
  tabActive: {
    color: theme.colors.light,
    border: `${theme.colors.secondary} 2px solid`,
    backgroundColor: theme.colors.secondary,
  },
  errorButton: {
    backgroundColor: theme.colors.error,
    color: theme.colors.light,
  },
  rentSplitHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '5px',
  },
  rentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rent: {
    color: theme.colors.success,
    fontSize: '26px',
    fontWeight: 'bold',
    marginRight: '15px',
  },
  freq: {
    color: theme.colors.label,
    fontSize: '18px',
    textTransform: 'lowercase',
  },
  splitToggleContainer: {
    marginLeft: 'auto',
  },
  leftToggle: {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
  },
  rightToggle: {
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  },
});
