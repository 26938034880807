/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect } from 'react';
import { Typography, Paper } from '@mui/material';
import { useStyles } from './OwnershipDetailsScreenStyles';
import { OwnershipDetails } from '../../../../services/remoteSigning/ownership/Ownership';
import { GetUserResponse } from '../../../../services/remoteSigning/user/User';
import { LABELS } from './OwnershipDetailsScreenContants';
import PersonCard from '../RemoteSigning/components/PersonCard';
import OwnershipCard from '../RemoteSigning/components/OwnershipCard';

interface OwnershipDetailsScreenProps {
  ownershipDetails: OwnershipDetails;
  user: GetUserResponse;
  handleNext: () => void;
  handleBack: () => void;
  setActiveStep: (step: number) => void;
}

const OwnershipDetailsScreen: FC<OwnershipDetailsScreenProps> = ({
  ownershipDetails,
  user,
  handleNext,
  handleBack,
  setActiveStep,
}) => {
  const classes = useStyles();
  useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <>
      <OwnershipCard ownershipDetails={ownershipDetails} />
      <Paper className={classes.RDSContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h5">
            {ownershipDetails.ownershipType === 'Private'
              ? ownershipDetails.displayName
              : ownershipDetails.corporationName}
            {LABELS.TITLE}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {LABELS.SUBTITLE}
            {ownershipDetails.ownershipType === 'Private'
              ? ownershipDetails.displayName
              : ownershipDetails.corporationName}
          </Typography>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.ownershipDetails}>
            <div className={classes.ownershipDetailRow}>
              <div className={classes.ownershipDetailkey}>Corporation name:</div>
              <div className={classes.ownershipDetailValue}>
                {ownershipDetails.ownershipType === 'Private'
                  ? ownershipDetails.displayName
                  : ownershipDetails.corporationName}
              </div>
            </div>
            <div className={classes.ownershipDetailRow}>
              <div className={classes.ownershipDetailkey}>Phone:</div>
              <div className={classes.ownershipDetailValue}>{ownershipDetails.phoneNumber}</div>
            </div>
            <div className={classes.ownershipDetailRow}>
              <div className={classes.ownershipDetailkey}>Email:</div>
              <div className={classes.ownershipDetailValue}>{ownershipDetails.email}</div>
            </div>
            <div className={classes.ownershipDetailRow}>
              <div className={classes.ownershipDetailkey}>Owner:</div>
            </div>
            <PersonCard
              profileName={`${user.name} ${user.surname}`}
              profileContext={user.userType}
            />
            {ownershipDetails.additionalOwners && ownershipDetails.additionalOwners.length > 1 ? (
              <>
                <div className={classes.ownershipDetailRow} style={{ marginTop: '16px' }}>
                  <div className={classes.ownershipDetailkey}>Additional owners:</div>
                </div>
                {ownershipDetails.additionalOwners.map((owner, index) => (
                  <>
                    {index === 0 ? null : (
                      <PersonCard
                        profileName={`${owner.firstName} ${owner.lastName}`}
                        profileContext={owner.role}
                      />
                    )}
                  </>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </Paper>
      <div className={classes.buttonContainer}>
        <div className={classes.secondaryButton} onClick={() => handleBack()}>
          Back
        </div>
        <div className={classes.primaryButton} onClick={() => handleNext()}>
          Next
        </div>
      </div>
    </>
  );
};

export default OwnershipDetailsScreen;
