import * as Yup from 'yup';
import {
  CONTACT_NUMBER_PATTERN,
  EMAIL_PATTERN,
  NAME_PATTERN,
  invalidContactNumber,
} from '../../../../constants/AppConstants';

export const LABELS = {
  firstName: 'First name',
  lastName: 'Last name',
  mobileNumber: 'Mobile',
  email: 'E-mail (optional)',
  heading: 'Personal details',
  subHeading: 'Please provide your personal details',
};

export interface PersonalDetailsFormValues {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
}

export const personalDetailsFormInitialValues: PersonalDetailsFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
};

export const ERRORS = {
  required: 'Required',
  invalidNameFormat: 'Invalid name format',
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
};

export const personalDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(ERRORS.required)
    .max(255)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  lastName: Yup.string()
    .required(ERRORS.required)
    .max(255)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  mobileNumber: Yup.string()
    .required(ERRORS.required)
    .matches(CONTACT_NUMBER_PATTERN, invalidContactNumber),
});
