import { LoadingIndicatorState } from '../state/LoadingIndicatorState';
import {
  LoadingIndicatorAction,
  LoadingIndicatorActionTypes,
} from '../actions/LoadingIndicatorAction';
import { TypeHelper } from '../../helper/TypeHelper';

export const initialState: LoadingIndicatorState = {
  isLoading: false,
  loadingIndicatorCounter: 0,
};

export const loadingIndicatorReducer = (state = initialState, action: LoadingIndicatorAction) => {
  switch (action.type) {
    case LoadingIndicatorActionTypes.ShowIndicator:
      return {
        ...state,
        loadingIndicatorCounter: action.data
          ? state.loadingIndicatorCounter! + 1
          : state.loadingIndicatorCounter,
        isLoading: true,
      };
    case LoadingIndicatorActionTypes.HideIndicator:
      return {
        ...state,
        loadingIndicatorCounter: action.data
          ? state.loadingIndicatorCounter! - 1
          : state.loadingIndicatorCounter,
        isLoading: !TypeHelper.isNullOrUndefined(action.data)
          ? !(state.loadingIndicatorCounter! - 1 <= 0)
          : false,
        // if action.data is null/undefined or loadingIndicatorCounter <=0 then directly return false else return true
        // here checking (state.loadingIndicatorCounter! -1) <= 0, because action.data is true and state.loadingIndicatorCounter doesn't get updated yet.
      };
    default:
      return state;
  }
};
