/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import camelCaseKeys from 'camelcase-keys';
import { Logging } from '../../helper/Logging';
import {
  MarketplaceAction,
  MarketplaceActionTypes,
  MarketplaceActions,
} from '../actions/MarketplaceActions';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
  ProcurementCallbackRequest,
} from '../../services/marketplace/Marketplace';
import MarketplaceService from '../../services/marketplace/MarketplaceService';

function* getAvailableServices(action: MarketplaceAction) {
  try {
    const response: AvailableServicesResponse[] = yield MarketplaceService.getAvailableServices(
      action.data as AvailableServicesRequest,
    );
    const convertedToCamel: AvailableServicesResponse[] = camelCaseKeys(response, {
      deep: true,
    });
    yield put(MarketplaceActions.getAvailableServicesSuccess(convertedToCamel));
  } catch (error) {
    Logging.error('marketplaceWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(MarketplaceActions.getAvailableServicesError(error as Error));
  }
}

function* postProcurementCallback(action: MarketplaceAction) {
  const { onSuccess, ...data } = action.data as ProcurementCallbackRequest;
  try {
    yield MarketplaceService.postProcurementCallback(data as ProcurementCallbackRequest);
    yield put(MarketplaceActions.postProcurementCallbackSuccess());
    if (onSuccess) onSuccess();
  } catch (error) {
    Logging.error('marketplaceWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(MarketplaceActions.postProcurementCallbackError(error as Error));
  }
}

function* marketplaceWatcher() {
  yield takeEvery(
    MarketplaceActionTypes.GET_AVAILABLE_SERVICES_REQUEST,
    (action: MarketplaceAction) =>
      getAvailableServices({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    MarketplaceActionTypes.POST_PROCUREMENT_CALLBACK_REQUEST,
    (action: MarketplaceAction) =>
      postProcurementCallback({
        type: action.type,
        data: action.data,
      }),
  );
}

export default marketplaceWatcher;
