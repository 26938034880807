/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { Typography, Paper } from '@mui/material';
import { useStyles } from '../RemoteSigningStyles';
import { Property } from '../../../../../services/remoteSigning/lease/Lease';
import propertyDefaultIcon from '../../../../../assets/property-default.svg';

interface PropertyCardProps {
  property: Property;
}

const PropertyCard: FC<PropertyCardProps> = ({ property }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.propertyContainer}>
      <div className={classes.propertyImageContainer}>
        <img
          src={
            property.photos && property.photos.length
              ? (property.photos[0].link as any)
              : propertyDefaultIcon
          }
          className={classes.propertyImage}
          alt="property"
        />
      </div>
      <div className={classes.propertyDetails}>
        <Typography className={classes.propertyAddress}>
          {property.address.split(',')[0]}
        </Typography>
        <Typography className={classes.propertySuburb}>
          {`${property.suburb}, ${property.state} ${property.postcode}, ${property.country}`}
        </Typography>
      </div>
    </Paper>
  );
};

export default PropertyCard;
