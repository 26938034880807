import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import React, { FC, useMemo } from 'react';
import { Messages } from '../../../RemoteSigningConstants';
import { RemoteSigningGetResponse } from '../../../../../services/remoteSigning/RemoteSigning';
import { theme } from '../../../../../theme/Theme';
import { AppIcon, AppIconSize, AppIconType } from '../../../../../component/AppIcon/AppIcon';
// import { Contact } from '../../../../../component/ContactCard/Contact.data';
// import { ContactCard } from '../../../../../component/ContactCard/ContactCard';
import { SignaturePad } from '../../../../../component/signaturePad/SignaturePad';
import { SignatureType } from '../RemoteSigning';
import { SignatureType as LandlordSignatureType } from '../../LandlordSigningScreen/LandlordSigningScreenConstants';
import { useStyles } from '../RemoteSigningStyles';
import PersonCard from './PersonCard';
import errorIcon from '../../../../../assets/error-outline-24-px.svg';

export const getUserTitle = (title: string, state: string | undefined | null) => {
  let userTitle = title;
  if (state === 'VIC') {
    userTitle = userTitle.replace('Tenant', 'Renter');
    userTitle = userTitle.replace('Landlord', 'Rental Provider');
  }
  return userTitle;
};

interface SigningBoxProps {
  type: SignatureType | LandlordSignatureType;
  hidePersonCard?: boolean;
  hideWitnessInput?: boolean;
  title: string;
  witnessName: string;
  witnessNameRequired: boolean;
  remoteSigningData: RemoteSigningGetResponse;
  initialsBase64: string | null | undefined;
  signatureBase64: string | null | undefined;
  witnessSignatureBase64: string | null | undefined;
  notSigned: boolean;
  propertyState: string | undefined | null;
  setWitnessName: (name: string) => void;
  setWitnessNameRequired: (value: boolean) => void;
  setInitialsBase64: (value: string | null | undefined) => void;
  setSignatureBase64: (value: string | null | undefined) => void;
  setWitnessSignatureBase64: (value: string | null | undefined) => void;
  setNotSigned: (value: boolean) => void;
}

const SigningBox: FC<SigningBoxProps> = ({
  type,
  hidePersonCard,
  hideWitnessInput,
  title,
  witnessName,
  witnessNameRequired,
  remoteSigningData: { user },
  initialsBase64,
  signatureBase64,
  witnessSignatureBase64,
  notSigned,
  propertyState,
  setWitnessName,
  setWitnessNameRequired,
  setInitialsBase64,
  setSignatureBase64,
  setWitnessSignatureBase64,
  setNotSigned,
}) => {
  const classes = useStyles();
  const { titles } = Messages.pages.remoteSigningPage;
  const signAgain = useMemo<boolean>(() => {
    return (
      (!!signatureBase64 && type === SignatureType.TenantSignature) ||
      type === LandlordSignatureType.LandlordSignature ||
      (!!initialsBase64 && type === SignatureType.TenantInitials) ||
      type === LandlordSignatureType.LandlordInitials ||
      (!!witnessSignatureBase64 && type === SignatureType.WitnessSignature)
    );
  }, [type, signatureBase64, initialsBase64, witnessSignatureBase64]);

  const onSignAgain = (signPad: SignatureType | LandlordSignatureType) => {
    switch (signPad) {
      case SignatureType.TenantInitials:
      case LandlordSignatureType.LandlordInitials:
        setInitialsBase64(undefined);
        break;
      case SignatureType.TenantSignature:
      case LandlordSignatureType.LandlordSignature:
        setSignatureBase64(undefined);
        break;
      case SignatureType.WitnessSignature:
        setWitnessSignatureBase64(undefined);
        break;
      default:
    }
  };

  const handleSignatureChange = (
    signature: string,
    signPad: SignatureType | LandlordSignatureType,
  ) => {
    switch (signPad) {
      case SignatureType.TenantInitials:
      case LandlordSignatureType.LandlordInitials:
        setInitialsBase64(signature);
        setNotSigned(false);
        break;
      case SignatureType.TenantSignature:
      case LandlordSignatureType.LandlordSignature:
        setSignatureBase64(signature);
        setNotSigned(false);
        break;
      case SignatureType.WitnessSignature:
        setWitnessSignatureBase64(signature);
        setNotSigned(false);
        break;
      default:
    }
  };

  const getContentBase64 = () => {
    switch (type) {
      case SignatureType.TenantSignature:
      case LandlordSignatureType.LandlordSignature:
        return signatureBase64;
      case SignatureType.TenantInitials:
      case LandlordSignatureType.LandlordInitials:
        return initialsBase64;
      case SignatureType.WitnessSignature:
        return witnessSignatureBase64;
      default:
        return signatureBase64;
    }
  };

  return (
    <div className={classes.signContainer} style={{ flex: 1 }}>
      {type === SignatureType.WitnessSignature ? (
        <>
          {!hideWitnessInput && (
            <>
              <div className={classes.declarationText}>{titles.witnessHeader}</div>
              <FormControl className={classes.witnessFormStyle}>
                <Input
                  fullWidth
                  // disableUnderline
                  value={witnessName}
                  type="text"
                  onChange={(event) => {
                    setWitnessName(event.target.value);
                    setWitnessNameRequired(false);
                  }}
                  placeholder="Witness name"
                  classes={{ input: classes.witnessInputStyle }}
                  endAdornment={
                    <InputAdornment position="end">
                      <AppIcon
                        color={theme.colors.disabled}
                        size={AppIconSize.Size20}
                        type={AppIconType.Profile}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {witnessNameRequired && (
                <FormHelperText error className={classes.errorContainer}>
                  <img className={classes.errorIcon} src={errorIcon} alt="" />
                  You must provide a witness name
                </FormHelperText>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {!hidePersonCard && (
            <>
              <Typography variant="h1" className={classes.declarationText}>
                {titles.tenantHeader}
              </Typography>
              <PersonCard
                profileName={`${user.name} ${user.surname}`}
                profileContext={getUserTitle(user.userType, propertyState)}
              />
              <br />
            </>
          )}
        </>
      )}
      <>
        <div className={classes.signLabelContainer}>
          <div className={classes.signLabel}>{title}</div>
          {signAgain && (
            <div className={classes.signAgain} id="signAgain" onClick={() => onSignAgain(type)}>
              {type === SignatureType.TenantInitials ||
              type === LandlordSignatureType.LandlordInitials
                ? 'Initial again'
                : 'Sign again'}
            </div>
          )}
        </div>
        <div
          className={`${classes.signatureDivStyle} ${
            getContentBase64() ? classes.blueborder : undefined
          }
          ${notSigned ? classes.redborder : undefined}`}
        >
          <div
            className={`${classes.signpadLabelContainer} ${
              getContentBase64() ? classes.dontShow : undefined
            }`}
          >
            <div className={classes.signpadLabel}>Use your finger or mouse to sign this space</div>
          </div>
          <SignaturePad
            width="100%"
            height={260}
            contentBase64={getContentBase64()}
            onChange={(signature: string) => handleSignatureChange(signature, type)}
          />
        </div>
        {notSigned && (
          <>
            <br />
            <FormHelperText error className={classes.errorContainer}>
              <img className={classes.errorIcon} src={errorIcon} alt="" />
              {`Please provide ${
                type === SignatureType.TenantInitials ||
                type === LandlordSignatureType.LandlordInitials
                  ? 'initials'
                  : 'signature'
              }`}
            </FormHelperText>
            <br />
          </>
        )}
      </>
    </div>
  );
};

export default SigningBox;
