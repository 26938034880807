import React, { FC, useEffect, useState, useMemo, ReactNode, CSSProperties } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './BoxStyles';

interface BoxProps {
  children?: ReactNode;
  lightBorder?: boolean;
  parentStyles?: string;
  innerStyles?: string;
  loading?: boolean;
  selected?: boolean;
  onPress?: () => void;
}

const Box: FC<BoxProps> = ({
  children,
  parentStyles,
  lightBorder,
  innerStyles,
  loading,
  selected,
  onPress,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNameGenerator([
        parentStyles,
        classes.boxContainer,
        lightBorder && classes.lightBorder,
        selected && classes.selected,
        onPress !== undefined && classes.clickable,
        loading && classes.loading,
      ])}
      onClick={onPress}
    >
      <div className={classNameGenerator([classes.boxWrapper, innerStyles])}>{children}</div>
    </div>
  );
};

export default Box;
