import React, { FC } from 'react';
import { IconButton, Button } from '@mui/material';
import { useStyles } from './ToggleButtonStyles';

interface ToggleButtonProps {
  toggleFunction: () => void;
  style?: any;
  buttonState: boolean | null | undefined;
  hideNo?: boolean;
  isDisabled?: boolean;
  wide?: boolean;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  toggleFunction,
  buttonState,
  isDisabled,
  style,
  wide,
  hideNo,
}) => {
  const classes = useStyles();

  return (
    <IconButton style={style} disabled={isDisabled} disableRipple className="no-hover">
      <Button
        onClick={() => {
          if (!buttonState) {
            toggleFunction();
          }
        }}
        style={wide ? { minWidth: '150px', marginRight: '10px' } : { minWidth: '100px' }}
        className={buttonState ? classes.activeButton : classes.inactiveButton}
      >
        Yes
      </Button>
      {!hideNo && (
        <Button
          onClick={() => {
            if (buttonState) {
              toggleFunction();
            }
          }}
          style={wide ? { minWidth: '150px', marginRight: '10px' } : { minWidth: '100px' }}
          className={buttonState ? classes.inactiveButton : classes.activeButton}
        >
          No
        </Button>
      )}
    </IconButton>
  );
};

export default ToggleButton;
