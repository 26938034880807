import * as Yup from 'yup';
import { PetTypes } from '../../../../store/state/MyProfileFormState';
import { NAME_PATTERN } from '../../../../constants/AppConstants';

export const LABELS = {
  PETS: 'Pets',
  NUMBER_OF_PETS: 'Number of Pets',
  TYPE: 'Type',
  PLEASE_SPECIFY: 'Please specify type',
  BUTTON_TEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  ADD_ANOTHER_PET: '+ Add another pet',
  PET: 'Pet ',
  REMOVE: 'Remove',
  MINIMUM_NUMBER: 'Cannot be less than 0',
  HAVE_PETS: 'Do you have pets?',
  BREED: 'Breed',
  DESEXED: 'Desexed',
  SIZE: 'Size',
  PET_DOCUMENTATION: 'Attach additional documents (PDF, JPG, PNG) 10MB',
  PET_DOCUMENTATION_DESCRIPTION: "Please add pet's documentation/application",
  REGISTRATION_NUMBER: 'Pet Registration/Microchip Number',
};
export const ERRORS = {
  required: 'Required',
  otherPetTypeRequired: 'Enter the type of your pet',
  minimumNoOfPets: 'Cannot be less than 0',
  invalidNameFormat: 'Invalid name format',
  maxStringLength: 'Can be at most 255 characters',
  maxLengthForOtherPets: 'Can be at most 30 characters',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  pets: Yup.array().when('hasPets', {
    hasPets: Yup.boolean(),
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string(),
          specifyOtherType: Yup.string()
            .when('type', {
              is: (type) => type === PetTypes.Other,
              then: Yup.string()
                .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
                .max(30, ERRORS.maxLengthForOtherPets)
                .nullable(),
            })
            .nullable(),
          breed: Yup.string()
            .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
            .max(255, ERRORS.maxStringLength)
            .nullable(),
          desexed: Yup.boolean().nullable(),
          size: Yup.string().nullable(),
          registrationNumber: Yup.string().max(255, ERRORS.maxStringLength).nullable(),
        }),
      )
      .when('hasPets', {
        is: false,
        then: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().nullable(),
            specifyOtherType: Yup.string().nullable(),
            breed: Yup.string().nullable(),
            desexed: Yup.boolean().nullable(),
            size: Yup.string().nullable(),
          }),
        ),
      }),
  }),
});
