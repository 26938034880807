/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from 'react';
import { Typography, Paper } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useStyles } from '../RemoteSigningStyles';
import { OwnershipDetails } from '../../../../../services/remoteSigning/ownership/Ownership';
import { theme } from '../../../../../theme/Theme';

interface OwnershipCardProps {
  ownershipDetails: OwnershipDetails;
}

const OwnershipCard: FC<OwnershipCardProps> = ({ ownershipDetails }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.propertyContainer}>
      <div className={classes.propertyImageContainer}>
        <AccountBalanceIcon
          className={classes.propertyImage}
          style={{ width: 26, marginLeft: 2 }}
        />
      </div>
      <div className={classes.propertyDetails}>
        <Typography className={classes.propertyAddress}>
          {ownershipDetails.ownershipType === 'Private'
            ? ownershipDetails.displayName
            : ownershipDetails.corporationName}
        </Typography>
        <Typography className={classes.propertySuburb}>{ownershipDetails.ownershipType}</Typography>
      </div>
    </Paper>
  );
};

export default OwnershipCard;
