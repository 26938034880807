/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormControl, Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Button from '../../../../component/button/Button';
import FileUploads from '../../../../component/fileUpload/FileUploads';
import Input from '../../../../component/input/Input';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Text from '../../../../component/text/Text';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import { FileData } from '../../../../store/state/FileUploadState';
import {
  Desexed,
  Pets,
  PetSize,
  PetTypes,
  PostFormData,
  PostPetsFormData,
} from '../../../../store/state/MyProfileFormState';
import { LABELS, VALIDATION_SCHEMA } from './PetsFormConstants';
import { useStyles } from './PetsFormStyles';

interface PetsFormProps {
  petsDetails: PostPetsFormData | undefined;
  index: number | undefined;
  updateProfileChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postPetsFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
}

const PetsForm: FC<PetsFormProps> = ({
  petsDetails,
  index,
  updateProfileChangesFlag,
  setSelected,
  setChildFormRef,
  postPetsFormDetails,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostPetsFormData> | null>(null);
  const hasVisited = !!petsDetails && petsDetails.hasPets;
  const [profileChanged, setProfileChanged] = useState<boolean>(false);

  const updateProfileState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateProfileChangesFlag(true);
      setProfileChanged(true);
    }
  };

  const clearLastPet = (
    setFieldValue: (fieldName: string, fieldValue: any) => void,
    idx: number,
  ) => {
    setFieldValue(`pets[${idx}].type`, '');
    setFieldValue(`pets[${idx}].specifyOtherType`, '');
    setFieldValue(`pets[${idx}].breed`, '');
    setFieldValue(`pets[${idx}].desexed`, null);
    setFieldValue(`pets[${idx}].size`, '');
    setFieldValue(`pets[${idx}].additionalDocuments`, []);
    setFieldValue(`pets[${idx}].registrationNumber`, null);
  };

  const toggleHavePets = (
    values: PostPetsFormData,
    setFieldValue: (fieldName: string, fieldValue: string | boolean | FileData[] | null) => void,
  ) => {
    setFieldValue('hasPets', !values.hasPets);
    updateProfileState();
    if (values.hasPets) {
      values.pets.map((pet: Pets, idx: number) => {
        setFieldValue(`pets[${idx}].type`, '');
        setFieldValue(`pets[${idx}].specifyOtherType`, '');
        setFieldValue(`pets[${idx}].breed`, '');
        setFieldValue(`pets[${idx}].desexed`, null);
        setFieldValue(`pets[${idx}].size`, '');
        setFieldValue(`pets[${idx}].additionalDocuments`, []);
        setFieldValue(`pets[${idx}].registrationNumber`, null);
      });
    }
  };

  const newPet = {
    type: '',
    specifyOtherType: '',
    breed: '',
    desexed: null,
    size: '',
    additionalDocuments: [],
    registrationNumber: null,
  };

  const initialValues: PostPetsFormData =
    petsDetails && petsDetails.pets.length
      ? petsDetails
      : {
          hasPets: false,
          pets: [newPet],
        };

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  return (
    <div className={classes.root}>
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(onSubmitdata) => {
          const changeOnSubmit = onSubmitdata;
          changeOnSubmit.hasPets = changeOnSubmit.hasPets === null ? false : changeOnSubmit.hasPets;
          updateProfileState();
          postPetsFormDetails(
            changeOnSubmit,
            () => {
              setSelected(ProfileForm.VEHICLES_FORM);
            },
            index,
          );
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
          <div className={classes.formStyle}>
            <div className={classes.questionContainer}>
              <Typography variant="subtitle1">{LABELS.HAVE_PETS}</Typography>
              <ToggleButton
                toggleFunction={() => toggleHavePets(values, setFieldValue)}
                buttonState={values.hasPets}
              />
            </div>
            {values.pets.map((pet: Pets, idx: number) => {
              return (
                <div className={values.hasPets ? classes.extrabox : classes.hide} key={idx}>
                  <div className={`${classes.inputBoxStyle} ${values.hasPets ? '' : classes.hide}`}>
                    <Text textVariant="boxTitle" parentStyles={classes.sectionTitle}>
                      {LABELS.PET}
                      {idx + 1}
                    </Text>
                    {idx >= 0 && (
                      <Button
                        outline
                        parentStyles={classes.removeButton}
                        onPress={() => {
                          if (values.pets.length === 1) {
                            clearLastPet(setFieldValue, idx);
                          } else {
                            values.pets.splice(idx, 1);
                            setFieldValue('pets', values.pets);
                          }
                          updateProfileState();
                        }}
                      >
                        {LABELS.REMOVE}
                      </Button>
                    )}
                  </div>

                  {/* TYPE ----------------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.TYPE}
                    value={values.pets[idx].type || ''}
                    values={Object.values(PetTypes).map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`pets[${idx}].type`, value);
                      setFieldValue(`pets[${idx}].specifyOtherType`, '');
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`pets[${idx}].type`)}
                    touched={get(touched, `pets[${idx}]type`)}
                    error={get(errors, `pets[${idx}]type`)}
                    title={LABELS.TYPE}
                    mandatory
                    showRequiredError={get(touched, `pets[${idx}]type`) || hasVisited}
                  />

                  {values.pets[idx].type === PetTypes.Other && (
                    <Input
                      placeholder={LABELS.PLEASE_SPECIFY}
                      value={values.pets[idx].specifyOtherType || ''}
                      setValue={(value: string) => {
                        setFieldValue(`pets[${idx}].specifyOtherType`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`pets[${idx}].specifyOtherType`)}
                      touched={get(touched, `pets[${idx}]specifyOtherType`)}
                      error={get(errors, `pets[${idx}]specifyOtherType`)}
                      title={LABELS.PLEASE_SPECIFY}
                      mandatory
                      showRequiredError={get(touched, `pets[${idx}]specifyOtherType`) || hasVisited}
                    />
                  )}

                  {/* BREED ----------------------------------------------------------------------- // */}
                  <Input
                    placeholder={LABELS.BREED}
                    value={values.pets[idx].breed || ''}
                    setValue={(value: string) => {
                      setFieldValue(`pets[${idx}].breed`, value);
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`pets[${idx}].breed`)}
                    touched={get(touched, `pets[${idx}]breed`)}
                    error={get(errors, `pets[${idx}]breed`)}
                    title={LABELS.BREED}
                    mandatory
                    showRequiredError={get(touched, `pets[${idx}]breed`) || hasVisited}
                  />

                  {/* DESEXED ----------------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.DESEXED}
                    value={
                      values.pets[idx] &&
                      typeof values.pets[idx].desexed !== 'undefined' &&
                      values.pets[idx].desexed !== null
                        ? values.pets[idx].desexed
                          ? Desexed.isDesexed
                          : Desexed.isNotDesexed
                        : undefined
                    }
                    values={Object.values(Desexed).map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`pets[${idx}].desexed`, value === Desexed.isDesexed);
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`pets[${idx}].desexed`)}
                    touched={get(touched, `pets[${idx}]desexed`)}
                    error={get(errors, `pets[${idx}]desexed`)}
                    title={LABELS.DESEXED}
                    mandatory
                    showRequiredError={get(touched, `pets[${idx}]desexed`) || hasVisited}
                  />

                  {/* SIZE ----------------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.SIZE}
                    value={values.pets[idx].size}
                    values={Object.values(PetSize).map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`pets[${idx}].size`, value);
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`pets[${idx}].size`)}
                    touched={get(touched, `pets[${idx}]size`)}
                    error={get(errors, `pets[${idx}]size`)}
                    title={LABELS.SIZE}
                    mandatory
                    showRequiredError={get(touched, `pets[${idx}]size`) || hasVisited}
                  />

                  {/* REGISTRATION NUMBER ----------------------------------------------------------------------- // */}
                  <Input
                    placeholder={LABELS.REGISTRATION_NUMBER}
                    value={values.pets[idx].registrationNumber || ''}
                    setValue={(value: string) => {
                      setFieldValue(`pets[${idx}].registrationNumber`, value);
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`pets[${idx}].registrationNumber`)}
                    touched={get(touched, `pets[${idx}]registrationNumber`)}
                    error={get(errors, `pets[${idx}]registrationNumber`)}
                    title={LABELS.REGISTRATION_NUMBER}
                  />

                  <FormControl
                    className={`${classes.inputContainerStyle} ${
                      values.hasPets ? '' : classes.hide
                    }`}
                  >
                    <Text parentStyles={classes.fileInputTitle}>{LABELS.PET_DOCUMENTATION}</Text>
                    <Text textVariant="info" parentStyles={classes.fileInputDescription}>
                      {LABELS.PET_DOCUMENTATION_DESCRIPTION}
                    </Text>
                    <FileUploads
                      value={values.pets[idx].additionalDocuments}
                      id={`petAdditionalDocuments${idx}`}
                      onSuccess={(files: FileData[]) => {
                        const oldData = values.pets[idx].additionalDocuments;
                        const newData = oldData ? oldData.concat(files) : files;
                        setFieldValue(`pets[${idx}].additionalDocuments`, newData);
                        updateProfileState();
                      }}
                      multiple
                      onDelete={(file: FileData) => {
                        const oldData = values.pets[idx].additionalDocuments;
                        const newData = oldData.filter((oldFile: FileData) => oldFile !== file);
                        setFieldValue(`pets[${idx}].additionalDocuments`, newData);
                        updateProfileState();
                      }}
                    />
                  </FormControl>
                </div>
              );
            })}
            {/* Hide the "Add another pet" button if last noOfPets is less than or equal to zero. */}
            {values.hasPets && (
              <Button
                parentStyles={classes.addPetButton}
                onPress={() => {
                  const result = values.pets;
                  result.push(newPet);
                  setFieldValue('pets', result);
                }}
              >
                {LABELS.ADD_ANOTHER_PET}
              </Button>
            )}
            <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
              {LABELS.BUTTON_TEXT}
            </Button>
          </div>
        )}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postPetsFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postPetsFormDetails(data, id))
      : dispatch(MyProfileAction.postPetsFormDetails(data));
    onSubmit();
  },
});

export default connect(null, mapDispatchToProps)(PetsForm);
