import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  PhoneInputCountryFlagheight: {
    height: '100px',
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
  },
  inputContainerStyle: {
    paddingBottom: '8px',
    width: '100%',
  },
  mobileContainerStyle: {
    marginTop: '-26px',
    paddingBottom: '8px',
    width: '100%',
  },
  selectStyles: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  genericLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16px',
    fontFamily: 'GT Walsheim,Helvetica Neue,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  dateOfBirthLabelStyle: {
    padding: '23px 0 0 0px',
    fontSize: '12px',
  },
  preferredLeaseDurationLabelStyle: {
    padding: '23px 0 0 16px',
  },
  yearMonthStyle: {
    width: '45%',
  },
  yearMonthLabelStyle: {
    paddingLeft: '22px',
  },
  questionLabelStyle: {
    padding: '28px 0 22px 0px',
  },
  agencyQuestionLabelStyle: {
    paddingRight: '50px',
  },
  errorText: {
    color: `${theme.colors.error} !important`,
  },
  errorPhoneInput: {
    borderBottom: `2px  solid ${theme.colors.error}`,
  },
  mobileNumberView: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  containerStyle: {
    'display': 'flex',
    'flexDirection': 'row',
    'paddingBottom': '16.3px',
    'justifyContent': 'space-between',
    '@media (min-width:780px)': {
      width: '45%',
    },
  },
  hide: {
    display: 'none',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '16px',
    alignItems: 'center',
  },
  questionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  agencyQuestion: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '8px',
  },
  groupPetIconStyle: {
    paddingRight: '16px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      textAlign: 'right',
    },
    marginTop: '8px',
    textAlign: 'center',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  overlay: {
    float: 'right',
    right: 0,
  },
  counter: {
    minWidth: '10px',
    textAlign: 'center',
  },
  numberChangeStyle: {
    cursor: 'pointer',
  },
  dateInputLabelStyle: {
    '& input': {
      paddingLeft: '16px',
      color: 'transparent',
      textShadow: '0 0 0 black',
    },
  },
  datePicker: globalStyles.datePicker,
  emailErrorText: {
    marginTop: '8px',
    fontSize: '0.75rem',
    fontFamily: 'GT Walsheim',
    color: theme.colors.error,
  },
  leftButtonStyle: {
    width: 65,
    height: 32,
    borderColor: theme.colors.success,
    color: theme.colors.success,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rightButtonStyle: {
    width: 65,
    height: 32,
    borderColor: theme.colors.success,
    color: theme.colors.success,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  activeButtonStyle: {
    'backgroundColor': theme.colors.success,
    'color': theme.colors.light,
    '&:hover': {
      backgroundColor: theme.colors.success,
    },
  },
  customButtonContainer: {
    display: 'flex',
    marginTop: 24,
    marginBottom: 24,
  },
  textInputContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: 24,
  },
  textQuestionContainer: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  questionsSection: {
    marginTop: '15px',
  },
  emergencyContactTitle: {
    marginBottom: '30px',
  },
  emergencyContactCardStyle: {
    marginTop: '15px',
    padding: '20px 20px 0px 20px',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
  },
  activeButton: {
    color: theme.colors.light,
    backgroundColor: theme.colors.buttonPrimary,
    height: '35px',
    minWidth: '100px',
    textTransform: 'capitalize',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: theme.colors.buttonPrimary,
    },
  },
  inactiveButton: {
    color: theme.colors.label,
    height: '35px',
    minWidth: '100px',
    marginRight: '5px',
    textTransform: 'capitalize',
  },
  infoBox: {
    borderRadius: '6px',
    padding: '15px',
    backgroundColor: theme.colors.fadedBlue,
    color: theme.colors.success,
    marginBottom: '20px',
  },
  phoneIcon: {
    position: 'absolute',
    right: 0,
    top: 40,
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
});
