import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  profileSectionContainer: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
  },
  profileHeader: {
    // display: 'flex',
    // alignItems: 'center',
    marginBottom: theme.units.spacing * 4,
  },
  icon: {
    width: theme.units.iconSize * 1.2,
    height: theme.units.iconSize * 1.2,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: theme.units.spacing * 4,
  },
  title: {
    marginBottom: 0,
  },
  description: {},
  buttonContainer: {
    marginTop: theme.units.spacing * 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {},
});
