import { StoreAction } from '../StoreHelper';
import { store } from '../Store';

export enum SuccessToastActionTypes {
  ShowSuccessToast = 'SHOW_SUCCESS_TOAST',
  HideSuccessToast = 'HIDE_SUCCESS_TOAST',
}

export type SuccessToastAction = StoreAction<SuccessToastActionTypes, JSX.Element | string | null>;

export class SuccessToastActions {
  public static show() {
    return { type: SuccessToastActionTypes.ShowSuccessToast };
  }

  public static hide() {
    store.dispatch({
      type: SuccessToastActionTypes.HideSuccessToast,
    });
  }
}
