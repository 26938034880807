import { theme } from '../../../../theme/Theme';
import personalDetailsIcon from '../../../../assets/profileIcons/personalDetails.png';
import introductionIcon from '../../../../assets/profileIcons/introduction.png';
import currentIncomeIcon from '../../../../assets/profileIcons/currentIncome.png';
import addressIcon from '../../../../assets/profileIcons/address.png';
import employmentIcon from '../../../../assets/profileIcons/employment.png';
import idIcon from '../../../../assets/profileIcons/id.png';
import petsIcon from '../../../../assets/profileIcons/pets.png';
import referencesIcon from '../../../../assets/profileIcons/references.png';
import termsIcon from '../../../../assets/profileIcons/terms.png';
import vehiclesIcon from '../../../../assets/profileIcons/vehicles.png';

export const LABELS = {
  TITLE: 'Getting started',
};

export interface ProfileContent {
  icon: string;
  title: string;
  description: string;
  button: string;
  backgroundColor: string;
}

export const personalDetails: ProfileContent = {
  icon: personalDetailsIcon,
  title: 'Complete your profile',
  description:
    'Start your application journey by filling out your personal details, such as your date of birth and emergency contact details.',
  button: 'Complete profile',
  backgroundColor: theme.colors.lightPurple,
};

export const introDetails: ProfileContent = {
  icon: introductionIcon,
  title: 'Complete your introduction',
  description:
    'Tell the agent a bit about yourself. Who you are and what you like to do. It goes a long way to getting approved for a property.',
  button: 'Complete intro',
  backgroundColor: theme.colors.lightBlue,
};

export const incomeDetails: ProfileContent = {
  icon: currentIncomeIcon,
  title: 'Complete your details',
  description:
    'Let the person who is reviewing your application know your primary source of income.  Not working full time? There are other options to reflect your situation.',
  button: 'Complete income',
  backgroundColor: theme.colors.lightGreen,
};

export const employmentDetails: ProfileContent = {
  icon: employmentIcon,
  title: 'Complete your employment',
  description:
    'This section is where you confirm who you currently work for. This will also trigger a reference request to your employer.',
  button: 'Complete employment',
  backgroundColor: theme.colors.lightRed,
};

export const petsDetails: ProfileContent = {
  icon: petsIcon,
  title: 'Have a pet?',
  description:
    'This is where you tap “Yes” and fill out some information about your furry family member.',
  button: 'Complete details',
  backgroundColor: theme.colors.lightPurple,
};

export const vehiclesDetails: ProfileContent = {
  icon: vehiclesIcon,
  title: 'Complete your vehicle information',
  description:
    'This is where you confirm if you have a car or a motorbike, what kind of vehicle it is.',
  button: 'Complete vehicles',
  backgroundColor: theme.colors.lightOrange,
};

export const addressDetails: ProfileContent = {
  icon: addressIcon,
  title: 'Confirm your current address',
  description:
    'Please complete the section on where you currently live and what your living arrangements are and how long you have lived there.',
  button: 'Complete address',
  backgroundColor: theme.colors.lightRed,
};

export const referenceDetails: ProfileContent = {
  icon: referencesIcon,
  title: 'Confirm your referee',
  description:
    'This is your opportunity to add a personal reference to your application - they will receive a reference request.',
  button: 'Complete reference',
  backgroundColor: theme.colors.lightGreen,
};

export const identificationDetails: ProfileContent = {
  icon: idIcon,
  title: 'Complete your identification',
  description:
    'Please confirm your 80 points of ID and upload photos - a drivers licence, passport, or other forms of ID. ',
  button: 'Confirm ID',
  backgroundColor: theme.colors.lightBlue,
};

export const declarationDetails: ProfileContent = {
  icon: termsIcon,
  title: 'Final step',
  description: 'To finalise your profile, please read and accept our Ts and Cs',
  button: 'Complete terms',
  backgroundColor: theme.colors.lightPurple,
};
