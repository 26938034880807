/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { get } from 'lodash';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Button from '../../../../component/button/Button';
import FileUploads from '../../../../component/fileUpload/FileUploads';
import Input from '../../../../component/input/Input';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Text from '../../../../component/text/Text';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { TypeHelper } from '../../../../helper/TypeHelper';
import { AustraliaState } from '../../../../services/dashboard/australiaStates/australiaStates';
import { SubmittedApplicationResponse } from '../../../../services/dashboard/postApplyForm/PostApplyForm.data';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { FileData } from '../../../../store/state/FileUploadState';
import {
  CurrentIncome,
  PostCurrentIncomeFormData,
  PostFormData,
  SourceOfIncome,
} from '../../../../store/state/MyProfileFormState';
import { ERRORS, LABELS, VALIDATION_SCHEMA } from './CurrentIncomeFormConstants';
import { useStyles } from './CurrentIncomeFormStyles';

interface CurrentIncomeFormProps {
  currentIncomeDetails: PostCurrentIncomeFormData | undefined;
  index: number | undefined;
  selectedState: AustraliaState;
  updateProfileChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postCurrentIncomeFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
}

const CurrentIncomeForm: FC<CurrentIncomeFormProps> = ({
  currentIncomeDetails,
  index,
  selectedState,
  updateProfileChangesFlag,
  setSelected,
  setChildFormRef,
  postCurrentIncomeFormDetails,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostCurrentIncomeFormData> | null>(null);
  const hasVisited =
    currentIncomeDetails &&
    !!currentIncomeDetails.incomes.length &&
    currentIncomeDetails.incomes[0].source !== null;
  const [profileChanged, setProfileChanged] = useState<boolean>(false);
  const [isValidGuarantorNumber, setIsValidGuarantorNumber] = useState<boolean>(true);

  const updateProfileState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateProfileChangesFlag(true);
      setProfileChanged(true);
    }
  };

  const changeSourceOfIncome = (
    setFieldValue: (fieldName: string, fieldValue: string | boolean) => void,
    idx: number,
  ) => {
    setFieldValue(`incomes[${idx}.guarantorName]`, '');
    setFieldValue(`incomes[${idx}.guarantorContactNumber]`, '');
    setFieldValue(`incomes[${idx}.guarantorEmail]`, '');
    setFieldValue(`incomes[${idx}.makeGuarantor]`, false);
  };

  const toggleMakeGuarantor = (
    values: PostCurrentIncomeFormData,
    setFieldValue: (fieldName: string, fieldValue: string | boolean) => void,
    idx: number,
  ) => {
    setFieldValue(`incomes[${idx}].makeGuarantor`, !values.incomes[idx].makeGuarantor);
    if (!values.incomes[idx].makeGuarantor) {
      setFieldValue(`incomes[${idx}].guarantorName`, '');
      setFieldValue(`incomes[${idx}].guarantorContactNumber`, '');
      setFieldValue(`incomes[${idx}].guarantorEmail`, '');
    }
  };

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  const newIncome = {
    source: null,
    netWeeklyAmount: null,
    savingsAmount: null,
    makeGuarantor: false,
    guarantorName: null,
    guarantorContactNumber: null,
    guarantorEmail: null,
    payslipData: [],
    proofOfBenefitData: [],
    proofOfIncomeData: [],
    bankStatementData: [],
    centrePayReference: null,
  };

  const initialValues: PostCurrentIncomeFormData =
    currentIncomeDetails && currentIncomeDetails.incomes.length
      ? { ...currentIncomeDetails }
      : {
          incomes: [newIncome],
        };

  const acceptedFileTypes = '.jpg, .jpeg, .pdf, .png';

  return (
    <div className={classes.root}>
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(formData) => {
          updateProfileState();
          postCurrentIncomeFormDetails(
            formData,
            () => {
              setSelected(ProfileForm.EMPLOYMENT_FORM);
            },
            index,
          );
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => {
          const setIncomeFieldData = (files: FileData[], idx: number, id: string) => {
            const oldData = values.incomes[idx][id];
            const newData = oldData ? oldData.concat(files) : files;
            setFieldValue(`incomes[${idx}][${id}]`, newData);
            updateProfileState();
          };

          const onDelete = (file: FileData, idx: number, id: string) => {
            const oldData = values.incomes[idx][id];
            const newData = oldData.filter((oldFile: FileData) => oldFile !== file);
            setFieldValue(`incomes[${idx}][${id}]`, newData);
            updateProfileState();
          };

          return (
            <div className={classes.formStyle}>
              {values.incomes.map((income: CurrentIncome, idx: number) => (
                <div className={classes.extrabox} key={idx}>
                  <div className={classes.inputBoxStyle}>
                    <Text textVariant="boxTitle" parentStyles={classes.sectionTitle}>
                      {TypeHelper.formatIncomeNumber(idx + 1)}
                      {`${LABELS.INCOME}`}
                    </Text>
                    {idx > 0 && (
                      <Button
                        outline
                        onPress={() => {
                          values.incomes.splice(idx, 1);
                          setFieldValue('incomes', values.incomes);
                          updateProfileState();
                        }}
                      >
                        {LABELS.REMOVE}
                      </Button>
                    )}
                  </div>

                  {/* SOURCE OF INCOME ------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.SOURCE_OF_INCOME}
                    value={values.incomes[idx].source || ''}
                    values={Object.values(SourceOfIncome).map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      const payslipIncomes: string[] = [
                        SourceOfIncome.I_AM_WORKING_FULL_TIME,
                        SourceOfIncome.I_AM_WORKING_PART_TIME,
                        SourceOfIncome.I_HAVE_CASUAL_WORK,
                      ];
                      setFieldValue(`incomes[${idx}].source`, value);
                      updateProfileState();

                      if (
                        values.incomes[idx].source !== SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY
                      ) {
                        changeSourceOfIncome(setFieldValue, idx);
                      }

                      if (values.incomes[idx].source === SourceOfIncome.I_RECEIVE_A_BENEFIT) {
                        setFieldValue(`incomes[${idx}].payslipData`, []);
                      } else if (payslipIncomes.includes(values.incomes[idx].source!)) {
                        setFieldValue(`incomes[${idx}].proofOfBenefitData`, []);
                      }
                    }}
                    onBlur={() => setFieldTouched(`incomes[${idx}].proofOfBenefitData`)}
                    touched={get(touched, `incomes[${idx}]source`)}
                    error={get(errors, `incomes[${idx}]source`)}
                    title={LABELS.SOURCE_OF_INCOME}
                    mandatory
                    showRequiredError={get(touched, `incomes[${idx}]source`) || hasVisited}
                  />

                  {/* TOTAL AMOUNT ------------------------------------------------------------- // */}
                  {values.incomes[idx].source === SourceOfIncome.I_RECEIVE_SAVINGS ? (
                    <Input
                      placeholder={LABELS.TOTAL_AMOUNT}
                      value={values.incomes[idx].savingsAmount || ''}
                      setValue={(value: string) => {
                        setFieldValue(`incomes[${idx}].savingsAmount`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`incomes[${idx}].savingsAmount`)}
                      touched={get(touched, `incomes[${idx}]savingsAmount`)}
                      error={get(errors, `incomes[${idx}]savingsAmount`)}
                      title={LABELS.TOTAL_AMOUNT}
                      mandatory
                      showRequiredError={hasVisited && !values.incomes[idx].savingsAmount}
                      dollar
                    />
                  ) : (
                    <Input
                      placeholder={LABELS.NET_WEEKLY_AMOUNT}
                      value={values.incomes[idx].netWeeklyAmount || ''}
                      setValue={(value: string) => {
                        setFieldValue(`incomes[${idx}].netWeeklyAmount`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`incomes[${idx}].netWeeklyAmount`)}
                      touched={get(touched, `incomes[${idx}]netWeeklyAmount`)}
                      error={get(errors, `incomes[${idx}]netWeeklyAmount`)}
                      title={LABELS.NET_WEEKLY_AMOUNT}
                      mandatory
                      showRequiredError={hasVisited && !values.incomes[idx].netWeeklyAmount}
                      dollar
                    />
                  )}

                  {/* attach payslip */}
                  {(values.incomes[idx].source === SourceOfIncome.I_AM_WORKING_FULL_TIME ||
                    values.incomes[idx].source === SourceOfIncome.I_AM_WORKING_PART_TIME ||
                    values.incomes[idx].source === SourceOfIncome.I_HAVE_CASUAL_WORK) && (
                    <FormControl
                      className={classes.fileContainerStyle}
                      error={
                        (get(errors, `incomes[${idx}]payslipData`) &&
                          get(touched, `incomes[${idx}]payslipData`)) ||
                        (hasVisited && !values.incomes[idx].payslipData.length)
                      }
                    >
                      <Text parentStyles={classes.fileInputTitle}>{LABELS.ATTACH_PAYSLIP}</Text>
                      <Text textVariant="info" parentStyles={classes.fileInputDescription}>
                        {LABELS.ATTACH_PAYSLIP_DESCRIPTION}
                      </Text>
                      <FileUploads
                        value={values.incomes[idx].payslipData}
                        id={`payslipLink${idx}`}
                        onSuccess={(file: FileData[]) =>
                          setIncomeFieldData(file, idx, `payslipData`)
                        }
                        multiple
                        onDelete={(file: FileData) => onDelete(file, idx, `payslipData`)}
                        acceptedTypes={acceptedFileTypes}
                        error={
                          (get(errors, `incomes[${idx}]payslipData`) &&
                            get(touched, `incomes[${idx}]payslipData`)) ||
                          (hasVisited && !values.incomes[idx].payslipData.length)
                        }
                      />
                      {hasVisited && !values.incomes[idx].payslipData.length && (
                        <FormHelperText error={hasVisited}>{ERRORS.payslipRequired}</FormHelperText>
                      )}
                      <FormHelperText>
                        {get(errors, `incomes[${idx}]payslipData`) &&
                          get(touched, `incomes[${idx}]payslipData`) && (
                            <div>{get(errors, `incomes[${idx}]payslipData`)}</div>
                          )}
                      </FormHelperText>
                    </FormControl>
                  )}

                  {/* centrepay reference number */}
                  {values.incomes[idx].source === SourceOfIncome.I_RECEIVE_A_BENEFIT && (
                    <Input
                      placeholder={LABELS.CENTRE_PAY_REFERENCE}
                      value={values.incomes[idx].centrePayReference || ''}
                      setValue={(value: string) => {
                        setFieldValue(`incomes[${idx}].centrePayReference`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`incomes[${idx}].centrePayReference`)}
                      touched={get(touched, `incomes[${idx}]centrePayReference`)}
                      error={get(errors, `incomes[${idx}]centrePayReference`)}
                      showRequiredError={hasVisited && !values.incomes[idx].centrePayReference}
                      mandatory
                      title={LABELS.CENTRE_PAY_REFERENCE}
                    />
                  )}

                  {/* attach proof of benefit */}
                  {values.incomes[idx].source === SourceOfIncome.I_RECEIVE_A_BENEFIT && (
                    <FormControl
                      className={classes.fileContainerStyle}
                      error={
                        get(errors, `incomes[${idx}]proofOfBenefitData`) &&
                        get(touched, `incomes[${idx}]proofOfBenefitData`)
                      }
                    >
                      <Text parentStyles={classes.fileInputTitle}>
                        {LABELS.ATTACH_PROOF_OF_BENEFIT}
                      </Text>
                      <FileUploads
                        value={values.incomes[idx].proofOfBenefitData}
                        id={`proofOfBenefitLink${idx}`}
                        onSuccess={(file: FileData[]) =>
                          setIncomeFieldData(file, idx, `proofOfBenefitData`)
                        }
                        multiple
                        onDelete={(file: FileData) => onDelete(file, idx, `proofOfBenefitData`)}
                        acceptedTypes={acceptedFileTypes}
                        error={
                          (get(errors, `incomes[${idx}]proofOfBenefitData`) &&
                            get(touched, `incomes[${idx}]proofOfBenefitData`)) ||
                          (hasVisited && !values.incomes[idx].proofOfBenefitData.length)
                        }
                      />
                      {hasVisited && !values.incomes[idx].proofOfBenefitData.length && (
                        <FormHelperText error={hasVisited}>
                          {ERRORS.proofOfBenefitRequired}
                        </FormHelperText>
                      )}
                      <FormHelperText>
                        {get(errors, `incomes[${idx}]proofOfBenefitData`) &&
                          get(touched, `incomes[${idx}]proofOfBenefitData`) && (
                            <div>{get(errors, `incomes[${idx}]proofOfBenefitData`)}</div>
                          )}
                      </FormHelperText>
                    </FormControl>
                  )}
                  {/* attach proof of income */}
                  {values.incomes[idx].source === SourceOfIncome.OTHER && (
                    <FormControl
                      className={classes.fileContainerStyle}
                      error={
                        get(errors, `incomes[${idx}]proofOfIncomeData`) &&
                        get(touched, `incomes[${idx}]proofOfIncomeData`)
                      }
                    >
                      <Text parentStyles={classes.fileInputTitle}>
                        {LABELS.ATTACH_PROOF_OF_INCOME}
                      </Text>
                      <FileUploads
                        value={values.incomes[idx].proofOfIncomeData}
                        id={`proofOfIncomeLink${idx}`}
                        onSuccess={(file: FileData[]) =>
                          setIncomeFieldData(file, idx, `proofOfIncomeData`)
                        }
                        multiple
                        onDelete={(file: FileData) => onDelete(file, idx, `proofOfIncomeData`)}
                        acceptedTypes={acceptedFileTypes}
                      />
                      {hasVisited && !values.incomes[idx].proofOfIncomeData.length && (
                        <FormHelperText error={hasVisited}>
                          {ERRORS.proofOfIncomeRequired}
                        </FormHelperText>
                      )}
                      <FormHelperText>
                        {get(errors, `incomes[${idx}]proofOfIncomeData`) &&
                          get(touched, `incomes[${idx}]proofOfIncomeData`) && (
                            <div>{get(errors, `incomes[${idx}]proofOfIncomeData`)}</div>
                          )}
                      </FormHelperText>
                    </FormControl>
                  )}

                  {/* attach bank statement */}
                  {selectedState !== AustraliaState.VIC ||
                  values.incomes[idx].source === SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY ||
                  values.incomes[idx].source === SourceOfIncome.I_RECEIVE_SAVINGS ||
                  values.incomes[idx].source === SourceOfIncome.SELF_EMPLOYED ? (
                    <FormControl
                      className={classes.fileContainerStyle}
                      error={
                        get(errors, `incomes[${idx}]bankStatementData`) &&
                        get(touched, `incomes[${idx}]bankStatementData`)
                      }
                    >
                      <Text parentStyles={classes.fileInputTitle}>
                        {`${
                          values.incomes[idx].source === SourceOfIncome.SELF_EMPLOYED
                            ? LABELS.ATTACH_TAX_RETURN
                            : selectedState === AustraliaState.VIC &&
                              (values.incomes[idx].source ===
                                SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY ||
                                values.incomes[idx].source === SourceOfIncome.I_RECEIVE_SAVINGS)
                            ? LABELS.PROOF_OF_SUPPORT
                            : LABELS.ATTACH_BANK_STATEMENT
                        } 
                        ${
                          values.incomes[idx].source === SourceOfIncome.I_RECEIVE_SAVINGS ||
                          values.incomes[idx].source === SourceOfIncome.SELF_EMPLOYED
                            ? ' *'
                            : ''
                        }
                        `}
                      </Text>
                      <Text textVariant="info" parentStyles={classes.fileInputDescription}>
                        {values.incomes[idx].source === SourceOfIncome.SELF_EMPLOYED
                          ? LABELS.ATTACH_TAX_RETURN_DESCRIPTION
                          : selectedState === AustraliaState.VIC
                          ? ''
                          : LABELS.ATTACH_BANK_STATEMENT_DESCRIPTION}
                      </Text>
                      <FileUploads
                        value={values.incomes[idx].bankStatementData}
                        id={`bankStatementLink${idx}`}
                        onSuccess={(file: FileData[]) =>
                          setIncomeFieldData(file, idx, `bankStatementData`)
                        }
                        multiple
                        onDelete={(file: FileData) => onDelete(file, idx, `bankStatementData`)}
                        acceptedTypes={acceptedFileTypes}
                        error={
                          (get(errors, `incomes[${idx}]bankStatementData`) &&
                            get(touched, `incomes[${idx}]bankStatementData`)) ||
                          (hasVisited &&
                            !values.incomes[idx].bankStatementData.length &&
                            (values.incomes[idx].source === SourceOfIncome.I_RECEIVE_SAVINGS ||
                              values.incomes[idx].source === SourceOfIncome.SELF_EMPLOYED))
                        }
                      />
                      {hasVisited &&
                        !values.incomes[idx].bankStatementData.length &&
                        (values.incomes[idx].source === SourceOfIncome.I_RECEIVE_SAVINGS ||
                          values.incomes[idx].source === SourceOfIncome.SELF_EMPLOYED) && (
                          <FormHelperText error={hasVisited}>
                            {ERRORS.bankStatementRequired}
                          </FormHelperText>
                        )}
                      <FormHelperText>
                        {get(errors, `incomes[${idx}]bankStatementData`) &&
                          get(touched, `incomes[${idx}]bankStatementData`) && (
                            <div>{get(errors, `incomes[${idx}]bankStatementData`)}</div>
                          )}
                      </FormHelperText>
                    </FormControl>
                  ) : null}

                  <FormControl
                    className={`${classes.inputContainerStyle} ${classes.questionContainer} ${
                      values.incomes[idx].source === SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY
                        ? ''
                        : classes.hide
                    }`}
                  >
                    <Typography variant="subtitle1">{LABELS.GUARANTOR_QUESTION}</Typography>
                    <ToggleButton
                      style={{ marginRight: '-16px' }}
                      toggleFunction={() => toggleMakeGuarantor(values, setFieldValue, idx)}
                      buttonState={values.incomes[idx].makeGuarantor}
                    />
                  </FormControl>

                  {/* GUARANTOR INFORMATION ----------------------------------------------------------------------- // */}
                  {values.incomes[idx].makeGuarantor &&
                    values.incomes[idx].source ===
                      SourceOfIncome.I_RECEIVE_MONEY_FROM_MY_FAMILY && (
                      <>
                        <Input
                          placeholder={LABELS.GUARANTOR_NAME}
                          value={values.incomes[idx].guarantorName || ''}
                          setValue={(value: string) => {
                            setFieldValue(`incomes[${idx}].guarantorName`, value);
                            updateProfileState();
                          }}
                          onBlur={() => setFieldTouched(`incomes[${idx}].guarantorName`)}
                          touched={get(touched, `incomes[${idx}]guarantorName`)}
                          error={get(errors, `incomes[${idx}]guarantorName`)}
                          title={LABELS.GUARANTOR_NAME}
                        />
                        <InputMobile
                          placeholder={LABELS.CONTACT_NUMBER}
                          value={values.incomes[idx].guarantorContactNumber || ''}
                          setValue={(value: string) => {
                            const isValidGuarantorNum = !!(
                              value && TypeHelper.validatePhone(value || '')
                            );
                            setIsValidGuarantorNumber(isValidGuarantorNum);
                            setFieldValue(`incomes[${idx}].guarantorContactNumber`, value);
                            updateProfileState();
                          }}
                          onBlur={() => setFieldTouched(`incomes[${idx}].guarantorContactNumber`)}
                          touched={get(touched, `incomes[${idx}]guarantorContactNumber`)}
                          error={
                            !isValidGuarantorNumber && !!values.incomes[idx].guarantorContactNumber
                              ? ERRORS.invalidCountryNumber
                              : get(errors, `incomes[${idx}]guarantorContactNumber`)
                          }
                          title={LABELS.CONTACT_NUMBER}
                        />
                        <Input
                          placeholder={LABELS.EMAIL_ADDRESS}
                          value={values.incomes[idx].guarantorEmail || ''}
                          setValue={(value: string) => {
                            setFieldValue(`incomes[${idx}].guarantorEmail`, value);
                            updateProfileState();
                          }}
                          onBlur={() => setFieldTouched(`incomes[${idx}].guarantorEmail`)}
                          touched={get(touched, `incomes[${idx}]guarantorEmail`)}
                          error={get(errors, `incomes[${idx}]guarantorEmail`)}
                          title={LABELS.EMAIL_ADDRESS}
                        />
                      </>
                    )}
                </div>
              ))}
              <Button
                parentStyles={classes.addIncomeButton}
                onPress={() => {
                  const result = values.incomes;
                  result.push(newIncome);
                  setFieldValue('incomes', result);
                }}
              >
                {LABELS.ADD_ANOTHER_INCOME}
              </Button>
              <Typography className={classes.addCurrentIncomeDescription}>
                {LABELS.RECOMMENDATION}
              </Typography>
              <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
                {LABELS.BUTTON_TEXT}
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedState: (state.masterProfile.masterProfileData as SubmittedApplicationResponse)
    .applicants[0].selectedState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postCurrentIncomeFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postCurrentIncomeFormDetails(data, id))
      : dispatch(MyProfileAction.postCurrentIncomeFormDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentIncomeForm);
