/* eslint-disable max-lines */
import { ThemeOptions, createTheme } from '@mui/material/styles';
import { APP, AGENCIES } from '../../../AppNameHelper';

export const layerIndex = {
  labelAboveInput: 0x100,
  iconAboveForm: 0x200,
  dialogAboveForm: 14000,
  adhocTermPopUp: 16000,
  autoCompletePopUp: 0x400,
};

// https://sleevesup.atlassian.net/wiki/spaces/RP/pages/35389902/Theme+styles
export const color =
  APP === AGENCIES.SORTED
    ? {
        primary: '#2D1B49',
        primaryHover: '#1F0D33',
        primary900: '#3D2C57',

        secondary600: '#1BCEE6',
        secondary600Hover: '#2BA7C0',

        borderColor: '#EBEBEB',

        buttonDisabled: 'rgba(0, 0, 0, 0.26)',
        listSelected: 'rgba(0,0,0,0.08)',

        secondary: '#1ECEE6',
        secondary900: '#2BA7C0',
        secondary900Hover: '#127B8A',
        secondary800: '#C6E6EB',
        secondary700: '#E3F2F4',

        warning: '#FF525B',
        warningHover: '#D7444C',
        warningAlert: '#E6000D',
        warningTag: '#fae8ea',

        dark: '#202020',
        darkBlack: '#222222',
        dark1000: '#1e1e28',
        dark900: '#666666',
        dark800: '#9E9E9E',
        dark800Hover: '#4A4A4A',
        dark700: '#EEEFF1',
        dark600: '#F6F6F6',
        dark500: '#200e14',
        secondaryHover: '#2CA7C0',
        shadow100: '#c6cbd4',

        muted: '#9B9B9B',
        light: '#FFFFFF',
        lightPrimary: '#fbfbfb',
        white600: '#fafafa',
        white600Hover: '#dadada',
        irisBlue: '#1badc1',

        badgeBlue: '#C3E6EE',
        badgeBlueLight: '#DFF2F6',
        badgeRed: '#ffdbdd',
        badgeRedLight: '#ffe6e8',

        // progress bar
        iconPrimary: '#290036',
        iconError: '#FF104C',
        iconPending: '#9D9C9D',
        rdsBackground: '#2D1B49',
        rdsTextColor: '#FFFFFF',

        // equifax-progress-bar
        success: '#008000',
      }
    : APP === AGENCIES.NAX
    ? {
        primary: '#000',
        primaryHover: '#4A4A4A',
        primary900: '#000',
        primary500: '#EAF6F9',

        secondary600: '#ff525b',
        secondary600Hover: '#000',

        borderColor: '#EBEBEB',

        buttonDisabled: 'rgba(0, 0, 0, 0.26)',
        listSelected: 'rgba(0,0,0,0.08)',

        secondary: '#ff525b',
        secondary900: '#ff525b',
        secondary900Hover: '#ff525b',
        secondary800: '#ff525b',
        secondary700: '#ff525b',

        warning: '#FF525B',
        warningHover: '#D7444C',
        warningAlert: '#E6000D',
        warningTag: '#fae8ea',

        dark: '#202020',
        dark1000: '#1e1e28',
        dark900: '#666666',
        dark800: '#9E9E9E',
        dark800Hover: '#4A4A4A',
        dark700: '#EEEFF1',
        dark600: '#F6F6F6',
        dark500: '#200e14',

        shadow100: '#c6cbd4',

        muted: '#9B9B9B',
        light: '#FFFFFF',
        white600: '#fafafa',
        white600Hover: '#dadada',
        irisBlue: '#1badc1',

        badgeBlue: '#C3E6EE',
        badgeBlueLight: '#DFF2F6',
        badgeRed: '#ffdbdd',
        badgeRedLight: '#ffe6e8',

        // progress bar
        iconPrimary: '#290036',
        iconError: '#FF104C',
        iconPending: '#9D9C9D',
        rdsBackground: '#f4f3f3',
        rdsTextColor: '#000',
      }
    : {
        primary: '#323232',
        primaryHover: '#4A4A4A',
        primary900: '#323232',

        secondary600: '#ff525b',
        secondary600Hover: '#000',

        borderColor: '#EBEBEB',

        buttonDisabled: 'rgba(0, 0, 0, 0.26)',
        listSelected: 'rgba(0,0,0,0.08)',

        secondary: '#ff525b',
        secondary900: '#ff525b',
        secondary900Hover: '#ff525b',
        secondary800: '#ff525b',
        secondary700: '#ff525b',

        warning: '#FF525B',
        warningHover: '#D7444C',
        warningAlert: '#E6000D',

        dark: '#202020',
        darkBlack: '#222222',
        dark1000: '#1e1e28',
        dark900: '#666666',
        dark800: '#9E9E9E',
        dark800Hover: '#4A4A4A',
        dark700: '#EEEFF1',
        dark600: '#F6F6F6',
        dark500: '#200e14',
        shadow100: '#c6cbd4',

        muted: '#9B9B9B',
        // export const colorMidshaded : '#C0C0C0',
        // export const colorShaded : '#EEEEEE',
        light: '#FFFFFF',
        lightPrimary: '#fbfbfb',
        white600: '#fafafa',
        white600Hover: '#dadada',
        irisBlue: '#1badc1',

        badgeBlue: '#C3E6EE',
        badgeBlueLight: '#DFF2F6',
        badgeRed: '#ffdbdd',
        badgeRedLight: '#ffe6e8',

        // progress bar
        iconPrimary: '#290036',
        iconError: '#FF104C',
        iconPending: '#9D9C9D',
        rdsBackground: '#323232',
        rdsTextColor: '#FFFFFF',
      };

export const themeConstants = {
  inputBackgroundColor: color.dark600,
  fontSize: {
    lg: 16,
    md: 16,
    sm: 14,
    xs: 12,
    xs2: 10,
    xs3: 8,
  },
};

export const borderRadius = {
  small: '6px',
  medium: '10px',
  large: '18px',
};

export const shadows = {
  light:
    '0px 3px 1px -2px rgba(100,100,100,0.2), 0px 2px 2px 0px rgba(100,100,100,0.14), 0px 1px 5px 0px rgba(100,100,100,0.12)',
};

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: color.primary,
    },
    secondary: {
      main: color.secondary,
    },
    error: {
      main: color.warning,
    },
  },
  typography: {
    fontFamily: ['GT Walsheim', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),

    // ...fontSizeOverrides later
    fontWeightLight: 300,
    fontWeightRegular: 600,
    fontWeightMedium: 700,

    // heading3 in https://sleevesup.atlassian.net/wiki/spaces/RP/pages/35389902/Theme+styles
    h4: {
      fontSize: '4.236rem',
      fontWeight: 300,
      lineHeight: 1.15,
    },
    // heading4 - Form heading in https://sleevesup.atlassian.net/wiki/spaces/RP/pages/35389902/Theme+styles
    h3: {
      fontSize: '2.618rem',
      fontWeight: 300,
      lineHeight: 1.15,
    },
    // heading5
    h2: {
      fontSize: '1.618rem',
      fontWeight: 300,
      lineHeight: 1.2,
      color: color.dark800,
    },
    // heading6
    h1: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },

    button: {
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          'lineHeight': 1.75,
          'marginTop': 10,
          'padding': '10px',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: color.buttonDisabled,
            textAlign: 'center',
          },
        },
        textPrimary: {
          backgroundColor: color.primary,
          color: color.light,
          textAlign: 'center',

          '&:hover': {
            backgroundColor: color.primary,
            color: color.light,
          },
        },
        textSecondary: {
          backgroundColor: color.secondary,
          color: color.light,
          textAlign: 'center',
        },
        outlined: {
          minHeight: '36px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          transform: 'translate(10px, 34px)',
          zIndex: layerIndex.labelAboveInput,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&$disabled': {
            '&:after, &:before': {
              borderBottom: '0px none !important',
            },
          },
        },
        input: {
          // width: "100%",
          height: '30px',
          padding: '10px',
          color: color.dark900,
          backgroundColor: themeConstants.inputBackgroundColor,
          fontSize: '1rem',
          borderStyle: 'none',
        },
        inputMultiline: {
          // width: "100%",
          height: '30px',
          padding: '10px',
          color: color.dark900,
          backgroundColor: themeConstants.inputBackgroundColor,
          fontSize: '1rem',
        },
        inputTypeSearch: {
          // width: "100%",
          height: '30px',
          padding: '10px',
          color: color.dark900,
          backgroundColor: themeConstants.inputBackgroundColor,
          fontSize: '1rem',
        },
        underline: {
          '&:after, &:before': {
            borderBottom: `0px none`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '14px 10px 6px 10px',
          height: '30px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          'paddingTop': '0px',
          'borderBottomWidth': '0px',
          'borderBottomStyle': 'none',
          '&$error': {
            marginTop: 0,
            borderTop: '1px solid #FF525B',
            borderBottom: '0px',
            color: '#FF525B',
          },
          '&$required': {
            marginTop: 0,
            borderTop: '1px solid #2BA7C0',
            borderBottom: '0px',
            color: '#2BA7C0',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#FFFFFF',
          fontSize: '12px',
          padding: '10px',
          width: '150px',
          textAlign: 'center',
        },
        popper: {
          padding: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderLeft: 'solid 3px transparent',
          height: 48,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0px 5px',
        },
        head: {
          fontWeight: 700,
          fontSize: '1rem',
          lineHeight: '1.2',
        },
        body: {
          fontWeight: 300,
          fontSize: '1rem',
          lineHeight: '1.2',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: 'auto',
          padding: '9px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          minHeight: '55px',
        },
        selectLabel: {
          paddingTop: 0,
          width: 'auto',
        },
        selectRoot: {
          marginTop: '5px',
        },
        select: {
          height: 'auto',
          paddingTop: '5px',
          paddingRight: '20px',
          fontSize: '0.75rem',
          fontWeight: 600,
          lineHeight: '1.375em',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&$expanded': {
            margin: '10px 10px 10px 0px !important',
          },
          'padding': '0px 10px',
          'margin': '0px 10px',
        },
        content: {
          margin: '10px 0px 10px 0px !important',
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 20px 10px 20px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            fontWeight: 400,
          },
          'fontWeight': 400,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          '&$selected': {
            borderBottom: '2px solid #2D1B49',
          },
          'fontWeight': 500,
          'zIndex': 0,
          'textTransform': 'none',
          'minWidth': 72,
          'padding': '5px 20px',
          'minHeight': '35px',
        },
        labelIcon: {
          fontSize: `${themeConstants.fontSize.lg} !important`,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        secondary: {
          fontSize: themeConstants.fontSize.sm,
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '8px 16px 8px 16px',
          border: '2px',
        },
        gutters: {
          // padding: "8px 16px 8px 16px !important"
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // marginRight: "8px !important"
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          /* Small devices (portrait tablets and large phones, 600px and up) */
          '@media only screen and (min-width: 600px)': {
            fontSize: '0.8rem',
          },

          /* Medium devices (landscape tablets, 768px and up) */
          '@media only screen and (min-width: 768px)': {
            fontSize: '1rem',
          },

          /* Large devices (laptops/desktops, 992px and up) */
          '@media only screen and (min-width: 992px)': {
            fontSize: '1.2rem',
          },

          /* Extra large devices (large laptops and desktops, 1200px and up) */
          '@media only screen and (min-width: 1200px)': {
            fontSize: '1.5rem',
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export const fontSizeOverrides = {
  [theme.breakpoints.up('lg')]: { fontSize: themeConstants.fontSize.lg },
  [theme.breakpoints.only('md')]: { fontSize: themeConstants.fontSize.md },
  [theme.breakpoints.only('sm')]: { fontSize: themeConstants.fontSize.sm },
  [theme.breakpoints.down('xs')]: { fontSize: themeConstants.fontSize.xs },
};

export const fontSizeOverridesSmall = {
  [theme.breakpoints.up('lg')]: { fontSize: themeConstants.fontSize.sm },
  [theme.breakpoints.only('md')]: { fontSize: themeConstants.fontSize.sm },
  [theme.breakpoints.only('sm')]: { fontSize: themeConstants.fontSize.xs },
  [theme.breakpoints.down('xs')]: { fontSize: themeConstants.fontSize.xs2 },
};

export const fontSizeOverridesSmaller = {
  [theme.breakpoints.up('lg')]: { fontSize: themeConstants.fontSize.xs },
  [theme.breakpoints.only('md')]: { fontSize: themeConstants.fontSize.xs },
  [theme.breakpoints.only('sm')]: { fontSize: themeConstants.fontSize.xs2 },
  [theme.breakpoints.down('xs')]: { fontSize: themeConstants.fontSize.xs3 },
};

export default theme;
