import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  RDSContainer: {
    margin: 'auto',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    position: 'relative',
    marginTop: '-15px',
    padding: '16px',
  },
  signingContainers: {
    display: 'flex',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  signBelow: {
    flex: 3,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flex: 1,
    },
  },
  initialBelow: {
    flex: 2,
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flex: 1,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '-50px',
      marginLeft: 0,
    },
  },
  buttonWrapperStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextButtonStyle: {
    width: '14.688rem',
    height: '3rem',
    fontFamily: 'GT Walsheim',
    fontWeight: getFonts('Medium'),
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
    '&:hover': {
      backgroundColor: theme.colors.secondary,
    },
  },
  nextButtonDisabledStyle: {
    backgroundColor: theme.colors.disabled,
    color: theme.colors.darkGrey,
  },
});
