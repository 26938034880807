import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/AppConstants';
import { theme } from '../../../../theme/Theme';

export const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
  },
  heading: {},
  subHeading: {
    marginTop: '1em',
  },
  fullWidth: {
    width: '100%',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1em',
    marginTop: theme.units.spacing * 4,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  fieldWrapper: {},
  form: {
    flexGrow: 1,
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: theme.units.spacing * 4,

    '& .fieldWrapper:nth-child(odd)': {
      paddingRight: '1em',

      [`@media (max-width: 600px)`]: {
        paddingRight: 0,
      },
    },

    '& .fieldWrapper:nth-child(even)': {
      paddingLeft: '1em',

      [`@media (max-width: 600px)`]: {
        paddingLeft: 0,
      },
    },
  },
  textFieldsContainer: {
    flexGrow: 1,
  },
  errorMessage: {
    fontSize: '14px',
    color: 'red',
  },
});
