import React, { FC } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { connect } from 'react-redux';
import sortedLogo from '../../../assets/sorted_reference_logo.png';
import {
  referenceTool,
  knowMore,
  learnMore,
  learnMoreUrl,
  errorType,
  errorExpiredLink,
  errorExpiredMsg,
  errorUsedLink,
  errorUsedMesg,
  errorInvalidLink,
  errorInvalidMsg,
} from '../ReferenceToolConstants';
import { useStyles } from './ErrorScreenStyles';
import { ApplicationState } from '../../../store/RootReducers';
import { TypeHelper } from '../../../helper/TypeHelper';

interface ErrorScreenProps {
  getError: string | null;
  postError: string | null;
}

const ErrorScreen: FC<ErrorScreenProps> = ({ getError, postError }) => {
  const classes = useStyles();
  const errorMessage = getError || postError;
  if (TypeHelper.isNullOrUndefined(errorMessage)) {
    return <Typography className={classes.dataLoadingStyle} />;
  }
  return (
    <Grid className={classes.errorScreenRootStyle}>
      <div className={classes.sortedLogoStyle}>
        <img className={classes.imageStyle} src={sortedLogo} alt="" />
        <span className={classes.referenceToolStyle}>{referenceTool}</span>
      </div>
      <Typography className={classes.oopsTextStyle}>Oops!</Typography>
      {errorMessage!.includes(errorType.EXPIRED) && (
        <>
          <Typography className={classes.finishedReferenceStyle}>{errorExpiredLink}</Typography>
          <Typography className={classes.errorTextStyle}>{errorExpiredMsg}</Typography>
        </>
      )}
      {errorMessage!.includes(errorType.ALREADY_USED) && (
        <>
          <Typography className={classes.finishedReferenceStyle}>{errorUsedLink}</Typography>
          <Typography className={classes.errorTextStyle}>{errorUsedMesg}</Typography>
        </>
      )}
      {errorMessage!.includes(errorType.DOES_NOT_EXIST) && (
        <>
          <Typography className={classes.finishedReferenceStyle}>{errorInvalidLink}</Typography>
          <Typography className={classes.errorTextStyle}>{errorInvalidMsg}</Typography>
        </>
      )}

      <div className={classes.sortedInfoStyle}>
        <span className={classes.knowMoreStyle}>{knowMore}</span>
        <Button
          className={classes.learnMoreStyle}
          onClick={() => {
            window.open(learnMoreUrl, '_blank');
          }}
        >
          {learnMore}
        </Button>
      </div>
    </Grid>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  getError: state.referenceToolData.getError,
  postError: state.referenceToolData.postError,
});

export default connect(mapStateToProps, null)(ErrorScreen);
