import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  profileCompletionContainer: {
    marginBottom: theme.units.spacing * 8,
  },
  profileRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    width: theme.units.iconSize * 1.4,
    height: theme.units.iconSize * 1.4,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: theme.units.spacing * 4,
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
  },
  title: {
    marginBottom: theme.units.spacing * 2,
  },
  subtitle: {},
  buttonRow: {
    marginTop: theme.units.spacing * 4,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionContainer: {
    marginTop: theme.units.spacing * 4,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  actionText: {
    marginRight: theme.units.spacing * 4,
    marginLeft: 'auto',
  },
  actionIcon: {
    width: theme.units.iconSize / 1.6,
    height: theme.units.iconSize / 1.6,
    objectFit: 'contain',
    objectPosition: 'center',
    transition: 'all 0.5s ease 0s',
    ...actionGreen,
  },
  actionIconActive: {
    transform: 'rotate(180deg)',
    transition: 'all 0.5s ease 0s',
  },
  content: {
    transition: 'all 0.5s ease 0s',
    overflow: 'hidden',
    height: '100%',
  },
  quickStartItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.units.spacing * 4,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    cursor: 'pointer',
  },
  quickStartTick: {
    width: theme.units.iconSize * 1.2,
    height: theme.units.iconSize * 1.2,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: theme.units.spacing * 4,
  },
  quickStartTickIncomplete: {
    width: theme.units.iconSize * 1.2,
    height: theme.units.iconSize * 1.2,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: theme.units.spacing * 4,
    filter: 'grayscale(100%)',
    opacity: 0.3,
  },
  quickStartIconAction: {
    ...actionGreen,
    width: theme.units.iconSize / 1.5,
    height: theme.units.iconSize / 1.5,
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer',
  },
  quickStartText: {
    marginRight: 'auto',
    fontSize: theme.font.l,
  },
  verifiedBadge: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    border: `${theme.colors.darkGreen} solid 2px`,
    backgroundColor: theme.colors.lightGreen,
    width: 'auto',
    height: 30,
  },
  unverifiedBadge: {
    backgroundColor: theme.colors.lightRed,
    border: `${theme.colors.darkRed} solid 2px`,
  },
  verifiedIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginLeft: theme.units.spacing * 2,
    marginRight: theme.units.spacing * 2,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  verifiedText: {
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    color: theme.colors.secondary,
    marginRight: theme.units.spacing * 3,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
});
