import React, { FC } from 'react';
import { useStyles } from './SortedFooterStyles';
import sortedLogoHeader from '../../assets/sorted_logo.svg';
import linkedin from '../../assets/linkedin-white.png';
import instagram from '../../assets/instagram-white.png';
import facebook from '../../assets/facebook-white.png';

import { LABELS, links } from './SortedFooterConstants';

interface SortedFooterProps {}

const SortedFooter: FC<SortedFooterProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <div className={classes.paddingContainer}>
        <div className={classes.contentContainer}>
          <img
            src={sortedLogoHeader}
            className={classes.sortedLogo}
            onClick={() => window.open(links.aboutlink, '_blank')}
            alt="sorted logo"
          />
          <div className={classes.linkContainer}>
            <div className={classes.link} onClick={() => window.open(links.aboutlink, '_blank')}>
              {LABELS.ABOUT}
            </div>
            <div className={classes.link} onClick={() => window.open(links.privacylink, '_blank')}>
              {LABELS.PRIVACY}
            </div>
          </div>
          <div className={classes.socialContainer}>
            <img
              src={facebook}
              onClick={() => window.open(links.facebook, '_blank')}
              className={classes.socialIcon}
              alt="social icon"
            />
            <img
              src={instagram}
              onClick={() => window.open(links.instagram, '_blank')}
              className={classes.socialIcon}
              alt="social icon"
            />
            <img
              src={linkedin}
              onClick={() => window.open(links.linkedin, '_blank')}
              className={classes.socialIcon}
              alt="social icon"
            />
          </div>
          <div className={classes.urlContainer}>
            <div className={classes.url} onClick={() => window.open(links.aboutlink, '_blank')}>
              {LABELS.WEBSITE}
            </div>
            <div className={classes.url}>{LABELS.COPY}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortedFooter;
