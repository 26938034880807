/* eslint-disable default-case */
import { get } from 'lodash';
import {
  getProfilePercentage,
  isAddressDetailsFilled,
  isCurrentIncomeDetailsFilled,
  isDeclarationDetailsFilled,
  isEmploymentDetailsFilled,
  isIdentificationDetailsFilled,
  isIntroFilled,
  isPersonalDetailsFilled,
  isPetsDetailsFilled,
  isReferenceDetailsFilled,
  isVehiclesDetailsFilled,
} from '../../helper/ProfileVerifyHelper';
import {
  Application,
  ApplicationProperty,
  ApplicationsResponse,
} from '../../services/groups/Groups';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { isInformationRequested, isSyncRequired } from '../applications/ApplicationsUtils';
import { SectionStatus } from '../profile/components/ProfileItem/ProfileItemConstants';
import { DashboardItemType } from './DashboardConstants';

export const containsSigningLink = (
  groupApplications: ApplicationsResponse | undefined,
): Application | null => {
  if (!groupApplications) return null;

  let result = null;
  for (const group of groupApplications.submittedApplicationGroups.groups) {
    for (const agency of group.agencyWiseApplications) {
      for (const application of agency.application) {
        if (application.appointmentTokens && application.appointmentTokens.length) {
          result = application;
        }
      }
    }
  }
  return result;
};

export const getDashboardItems = (
  groupApplications: ApplicationsResponse | undefined,
  myProfileDetails: ApplicantProfile | undefined,
  debug?: boolean,
): DashboardItemType[] => {
  if (debug) {
    return [
      DashboardItemType.WELCOME,
      DashboardItemType.WHILE_YOU_WAIT,
      DashboardItemType.WHATS_NEXT,
      DashboardItemType.NO_APPLICATIONS,
      DashboardItemType.DRAFT_ACTIVE,
      DashboardItemType.PROFILE,
      // DashboardItemType.PROFILE_SECTION_PERSONAL_DETAILS,
      // DashboardItemType.PROFILE_SECTION_INTRO,
      // DashboardItemType.PROFILE_SECTION_CURRENT_INCOME,
      // DashboardItemType.PROFILE_SECTION_EMPLOYMENT,
      // DashboardItemType.PROFILE_SECTION_PETS,
      // DashboardItemType.PROFILE_SECTION_VEHICLES,
      // DashboardItemType.PROFILE_SECTION_ADDRESS,
      // DashboardItemType.PROFILE_SECTION_REFERENCE,
      // DashboardItemType.PROFILE_SECTION_IDENTIFICATION,
      // DashboardItemType.PROFILE_SECTION_DECLARATION,
      DashboardItemType.AGENCIES,
      DashboardItemType.BRANDS,
      DashboardItemType.INVITATION,
      // DashboardItemType.INFO_REQUESTED,
      DashboardItemType.DOWNLOAD_SORTED,
      DashboardItemType.FIND_A_PROPERTY,
      DashboardItemType.SORTED_APPLICATIONS,
    ];
  }
  const result: DashboardItemType[] = [];

  const containsLink = containsSigningLink(groupApplications);

  if (containsLink !== null) {
    result.push(DashboardItemType.SIGN_NOW);
    result.push(DashboardItemType.PROFILE);
    result.push(DashboardItemType.BRANDS);
    result.push(DashboardItemType.DOWNLOAD_SORTED);

    return result;
  }
  // WELCOME,
  // submitted applications are 0'
  if (
    !groupApplications ||
    (groupApplications && groupApplications.submittedApplicationGroups.groups.length === 0)
  ) {
    result.push(DashboardItemType.WELCOME);
  }

  // WHILE_YOU_WAIT
  //
  if (groupApplications && !!groupApplications.submittedApplicationGroups.groups.length) {
    result.push(DashboardItemType.WHILE_YOU_WAIT);
    result.push(DashboardItemType.WHATS_NEXT);
  }

  // NO_APPLICATIONS,
  // No drafts and no submitted applications
  if (
    !groupApplications ||
    (groupApplications &&
      groupApplications.submittedApplicationGroups.groups.length === 0 &&
      groupApplications.draftApplications.agencyWiseApplications.length === 0)
  ) {
    result.push(DashboardItemType.NO_APPLICATIONS);
  }

  // DRAFT_ACTIVE
  // Atleast 1 draft
  if (groupApplications && !!groupApplications.draftApplications.agencyWiseApplications.length) {
    result.push(DashboardItemType.DRAFT_ACTIVE);
  }

  // INVITATION,
  // If have invitiation
  if (groupApplications && !!groupApplications.invitedApplicationGroups.groups.length) {
    result.push(DashboardItemType.INVITATION);
  }

  // PROFILE,
  // Show profile if not 100%

  result.push(DashboardItemType.PROFILE);

  // Show individual missing areas, max 2
  let fails = 0;
  switch (true) {
    case true: {
      if (
        isPersonalDetailsFilled(get(myProfileDetails, 'personalDetails')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_PERSONAL_DETAILS);
        fails++;
        if (fails === 2) break;
      }

      if (isIntroFilled(get(myProfileDetails, 'introDetails')) === SectionStatus.INCOMPLETE) {
        result.push(DashboardItemType.PROFILE_SECTION_INTRO);
        fails++;
        if (fails === 2) break;
      }

      if (
        isCurrentIncomeDetailsFilled(get(myProfileDetails, 'currentIncomeDetails.incomes')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_CURRENT_INCOME);
        fails++;
        if (fails === 2) break;
      }

      if (
        isEmploymentDetailsFilled(
          get(myProfileDetails, 'employmentDetails'),
          get(myProfileDetails, 'currentIncomeDetails.incomes'),
        ) === SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_EMPLOYMENT);
        fails++;
        if (fails === 2) break;
      }

      if (isPetsDetailsFilled(get(myProfileDetails, 'petsDetails')) === SectionStatus.INCOMPLETE) {
        result.push(DashboardItemType.PROFILE_SECTION_PETS);
        fails++;
        if (fails === 2) break;
      }

      if (
        isVehiclesDetailsFilled(get(myProfileDetails, 'vehiclesDetails')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_VEHICLES);
        fails++;
        if (fails === 2) break;
      }

      if (
        isAddressDetailsFilled(get(myProfileDetails, 'addressDetails.addresses')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_ADDRESS);
        fails++;
        if (fails === 2) break;
      }

      if (
        isReferenceDetailsFilled(get(myProfileDetails, 'referencesDetails')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_REFERENCE);
        fails++;
        if (fails === 2) break;
      }

      if (
        isIdentificationDetailsFilled(get(myProfileDetails, 'identificationDetails')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_IDENTIFICATION);
        fails++;
        if (fails === 2) break;
      }

      if (
        isDeclarationDetailsFilled(get(myProfileDetails, 'declarationDetails')) ===
        SectionStatus.INCOMPLETE
      ) {
        result.push(DashboardItemType.PROFILE_SECTION_DECLARATION);
        fails++;
        if (fails === 2) break;
      }
    }
  }

  // AGENCIES,
  // Show always
  result.push(DashboardItemType.AGENCIES);

  // BRANDS,
  // Show if 1 submitted profile
  if (groupApplications && !!groupApplications.submittedApplicationGroups.groups.length) {
    result.push(DashboardItemType.BRANDS);
  }

  // INFO_REQUESTED,
  // If you have infomation requested
  if (groupApplications) {
    const infoRequested = groupApplications.submittedApplicationGroups.groups.some((group) =>
      group.agencyWiseApplications.some((agency) =>
        isInformationRequested(agency.application, groupApplications),
      ),
    );
    if (infoRequested) {
      result.push(DashboardItemType.INFO_REQUESTED);
    }
  }

  // SYNC_PROFILE,
  // If you have infomation requested
  if (groupApplications) {
    const syncRequired = groupApplications.submittedApplicationGroups.groups.some((group) =>
      group.agencyWiseApplications.some((agency) =>
        isSyncRequired(agency.application, groupApplications),
      ),
    );
    if (syncRequired) {
      result.push(DashboardItemType.SYNC_PROFILE);
    }
  }

  // DOWNLOAD_SORTED
  // Show always?
  result.push(DashboardItemType.DOWNLOAD_SORTED);

  // FIND_A_PROPERTY
  // Show always
  result.push(DashboardItemType.FIND_A_PROPERTY);

  // SORTED_APPLICATIONS
  // Show always
  result.push(DashboardItemType.SORTED_APPLICATIONS);

  return result;
};
