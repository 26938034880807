/* eslint-disable import/prefer-default-export */

import { links } from './AllDoneConstants';

export const getSortedCode = (value: number): string => {
  switch (value) {
    case 100:
      return 'SORTED100';
    case 50:
      return 'SORTED50';
    case 25:
      return 'WELCOME25';
    default:
      return 'WELCOME25';
  }
};

export const openAndroidLink = (agencyCode?: string) => {
  switch (agencyCode) {
    case 'AU_SWIT':
      window.open(links.playstoreSwitch, '_blank');
      break;
    case 'NAX':
      window.open(links.playstoreNAX, '_blank');
      break;
    default:
      window.open(links.playstore, '_blank');
      break;
  }
};

export const openAppleLink = (agencyCode?: string) => {
  switch (agencyCode) {
    case 'AU_SWIT':
      window.open(links.appleStoreSwitch, '_blank');
      break;
    case 'NAX':
      window.open(links.appleStoreNAX, '_blank');
      break;
    default:
      window.open(links.appleStore, '_blank');
      break;
  }
};
