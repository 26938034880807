import React, { FC } from 'react';
import { Paper } from '@mui/material';
import { useStyles } from './AboutSortedStyles';
import { LABELS } from './AboutSortedConstants';
import findhome from '../../../../assets/findhome.png';
import maintain from '../../../../assets/maintain.png';
import movein from '../../../../assets/movein.png';
import moveout from '../../../../assets/moveout.png';
import protect from '../../../../assets/protect.png';
import setup from '../../../../assets/setup.png';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';

interface AboutSortedProps {}

const AboutSorted: FC<AboutSortedProps> = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <div className={classes.pageContainer}>
          <Text textVariant="subtitle2">{LABELS.ABOUT_TITLE}</Text>
          <Text textVariant="subtitle2">{LABELS.ABOUT_TITLE2}</Text>
          <Text parentStyles={classes.bodyText} textVariant="body">
            {LABELS.ABOUT_SUBTEXT}
          </Text>

          <div className={classes.stepsContainer}>
            <Box lightBorder>
              <div className={classes.stepImgContainer}>
                <img src={findhome} className={classes.stepImg} alt="step img" />
              </div>
              <div className={classes.stepTitle}>{LABELS.FIND_NEXT_TITLE}</div>
              <div className={classes.stepText}>{LABELS.FIND_NEXT_TEXT}</div>
            </Box>
            <Box lightBorder>
              <div className={classes.stepImgContainer}>
                <img src={setup} className={classes.stepImg} alt="step img" />
              </div>
              <div className={classes.stepTitle}>{LABELS.SETUP_TITLE}</div>
              <div className={classes.stepText}>{LABELS.SETUP_TEXT}</div>
            </Box>
            <Box lightBorder>
              <div className={classes.stepImgContainer}>
                <img src={movein} className={classes.stepImg} alt="step img" />
              </div>
              <div className={classes.stepTitle}>{LABELS.MOVE_IN_TITLE}</div>
              <div className={classes.stepText}>{LABELS.MOVE_IN_TEXT}</div>
            </Box>
            <Box lightBorder>
              <div className={classes.stepImgContainer}>
                <img src={protect} className={classes.stepImg} alt="step img" />
              </div>
              <div className={classes.stepTitle}>{LABELS.PROTECT_TITLE}</div>
              <div className={classes.stepText}>{LABELS.PROTECT_TEXT}</div>
            </Box>
            <Box lightBorder>
              <div className={classes.stepImgContainer}>
                <img src={maintain} className={classes.stepImg} alt="step img" />
              </div>
              <div className={classes.stepTitle}>{LABELS.MAINTAIN_TITLE}</div>
              <div className={classes.stepText}>{LABELS.MAINTAIN_TEXT}</div>
            </Box>
            <Box lightBorder>
              <div className={classes.stepImgContainer}>
                <img src={moveout} className={classes.stepImg} alt="step img" />
              </div>
              <div className={classes.stepTitle}>{LABELS.MOVE_TITLE}</div>
              <div className={classes.stepText}>{LABELS.MOVE_TEXT}</div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSorted;
