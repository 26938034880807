import { identificationDetails } from '../../container/dashboard/components/ProfileSection/ProfileSectionConstants';
import {
  MyProfileFormAction,
  MyProfileActionTypes,
  MyProfilePostOverview,
} from '../actions/MyProfileFormActions';
import {
  PostPersonalDetailsFormData,
  ApplicantProfile,
  PostCurrentIncomeFormData,
  PostEmploymentFormData,
  PostAddressFormData,
  PostDeclarationFormData,
  PostReferencesFormData,
  PostIdentificationFormData,
  PostPetsFormData,
  IntroFormData,
  PostVehiclesFormData,
} from '../state/MyProfileFormState';

export const initialState: ApplicantProfile = {
  refId: null,
  id: undefined,
  personalDetails: undefined,
  referencesDetails: undefined,
  identificationDetails: undefined,
  currentIncomeDetails: undefined,
  employmentDetails: undefined,
  addressDetails: undefined,
  declarationDetails: undefined,
  petsDetails: undefined,
  vehiclesDetails: undefined,

  introDetails: undefined,
  myAccountFlag: false,
};

export function myProfileReducer(state = initialState, action: MyProfileFormAction) {
  state = postMyProfileFormReducer(state, action) as any;
  return state;
}

function postMyProfileFormReducer(state: ApplicantProfile, action: MyProfileFormAction) {
  switch (action.type) {
    // logic to only update the below data and keeping the other fields as it is.
    case MyProfileActionTypes.POST_OVERVIEW_DATA:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          ...{
            email: (action.data as MyProfilePostOverview).email,
            firstName: (action.data as MyProfilePostOverview).firstname,
            lastName: (action.data as MyProfilePostOverview).lastname,
            title: (action.data as MyProfilePostOverview).title,
          },
        },
      };
    case MyProfileActionTypes.MY_PROFILE_FORM_REQUEST:
      return {
        ...state,
        personalDetails: action.data as PostPersonalDetailsFormData,
      };
    case MyProfileActionTypes.INTRODUCTION_FORM_REQUEST:
      return {
        ...state,
        introDetails: action.data as IntroFormData,
      };
    case MyProfileActionTypes.CURRENT_INCOME_FORM_REQUEST:
      return {
        ...state,
        currentIncomeDetails: action.data as PostCurrentIncomeFormData,
      };
    case MyProfileActionTypes.EMPLOYMENT_FORM_REQUEST:
      return {
        ...state,
        employmentDetails: action.data as PostEmploymentFormData,
      };
    case MyProfileActionTypes.PETS_FORM_REQUEST:
      return {
        ...state,
        petsDetails: action.data as PostPetsFormData,
      };
    case MyProfileActionTypes.VEHICLES_FORM_REQUEST:
      return {
        ...state,
        vehiclesDetails: action.data as PostVehiclesFormData,
      };
    case MyProfileActionTypes.ADDRESS_FORM_REQUEST:
      return {
        ...state,
        addressDetails: action.data as PostAddressFormData,
      };
    case MyProfileActionTypes.DECLARATION_FORM_REQUEST:
      return {
        ...state,
        declarationDetails: action.data as PostDeclarationFormData,
      };
    case MyProfileActionTypes.REFERENCE_FORM_REQUEST:
      return {
        ...state,
        referencesDetails: action.data as PostReferencesFormData,
      };
    case MyProfileActionTypes.IDENTIFICATION_FORM_REQUEST:
      return {
        ...state,
        identificationDetails: action.data as PostIdentificationFormData,
      };

    case MyProfileActionTypes.SET_NTD_ACTIVE:
      return {
        ...state,
        identificationDetails: {
          ...state.identificationDetails,
          ntdActive: action.data as boolean,
        },
      };
    case MyProfileActionTypes.SET_NTD_PURCHASED:
      return {
        ...state,
        identificationDetails: {
          ...state.identificationDetails,
          ntdPurchased: action.data as boolean,
        },
      };

    case MyProfileActionTypes.SET_NTD_DATE:
      return {
        ...state,
        identificationDetails: {
          ...state.identificationDetails,
          ntdDate: action.data as string,
        },
      };

    case MyProfileActionTypes.REF_ID_REQUEST:
      return {
        ...state,
        refId: action.data as string | null,
      };
    case MyProfileActionTypes.MY_ACCOUNT_FLAG:
      return {
        ...state,
        myAccountFlag: action.data as boolean,
      };

    default:
      return state;
  }
}
