/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useStyles } from './QuestionnaireStyles';
import {
  CheckboxAnswersValues,
  DisclosureFormQuestion,
  DisclosureFormQuestionConfig,
  DisclosureFormQuestionTypes as QuestionTypes,
} from '../../services/disclosure/Disclosure';
import {
  validation,
  QuestionnaireData,
  getInitialAnswer,
  isQuestionValid,
  INPUT_DEFAULT_MAX_LENGTH,
} from './QuestionnaireConstants';
import Box from '../box/Box';

interface QuestionnaireProps {
  questionnaire: DisclosureFormQuestion[];
  isPrimaryLessor?: boolean;
  onSubmitQuestions: (data: QuestionnaireData) => void;
}

const Questionnaire: FC<QuestionnaireProps> = ({
  questionnaire,
  onSubmitQuestions,
  isPrimaryLessor,
}) => {
  const classes = useStyles();

  const getInitialValues = (): QuestionnaireData => {
    return {
      questionnaire: questionnaire.map((question) => {
        return {
          ...question,
          answers: isPrimaryLessor ? getInitialAnswer(question) : question.answers,
        };
      }),
    };
  };

  const [initialValues, setInitialValues] = useState<QuestionnaireData>(getInitialValues());

  useEffect(() => {
    if (questionnaire) {
      setInitialValues(getInitialValues());
    }
  }, [questionnaire]);

  const renderOptions = (
    question: DisclosureFormQuestion,
    values: QuestionnaireData,
    questionNo: number,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    const config = JSON.parse(question.config) as DisclosureFormQuestionConfig;
    const maxLength = config && config.maxLength ? config.maxLength : INPUT_DEFAULT_MAX_LENGTH;

    switch (question.type) {
      case QuestionTypes.Text:
        return (
          <Input
            fullWidth
            name={`Question${questionNo}`}
            inputProps={{
              maxLength,
            }}
            placeholder="Answer"
            disabled={!isPrimaryLessor}
            value={values.questionnaire[questionNo].answers[0]}
            onChange={(e) => {
              setFieldValue(`questionnaire[${questionNo}].answers[${0}]`, e.target.value);
            }}
          />
        );
      case QuestionTypes.Checkbox:
        return (
          <>
            {question.options.map((option, optionNo) => {
              const isChecked =
                values.questionnaire[questionNo].answers[optionNo] !== CheckboxAnswersValues.Off;
              return (
                <Button
                  name={option}
                  id={`q${questionNo}o${optionNo}`}
                  disabled={!isPrimaryLessor}
                  onClick={() => {
                    question.answers.forEach((ans, index) => {
                      setFieldValue(
                        `questionnaire[${questionNo}].answers[${index}]`,
                        index === optionNo ? CheckboxAnswersValues.On : CheckboxAnswersValues.Off,
                      );
                    });
                  }}
                  className={`${classes.buttonStyle} ${isChecked && classes.buttonChecked} `}
                >
                  {option}
                </Button>
              );
            })}
          </>
        );
      default:
        return <> </>;
    }
  };

  return (
    <div className={classes.wrapper}>
      <Box lightBorder parentStyles={classes.paper}>
        <div className={classes.content}>
          <Typography className={classes.heading}>
            {isPrimaryLessor
              ? 'Please answer following Questions'
              : 'Please Review following Questions'}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(data) => {
              onSubmitQuestions(data);
            }}
            validate={validation}
            render={({ values, setFieldValue, handleSubmit, errors, touched, submitForm }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    {values.questionnaire.map((question, questionNo) => {
                      if (!isQuestionValid(values.questionnaire, questionNo)) return null;
                      return (
                        <div>
                          {question.headerHtml && (
                            <div dangerouslySetInnerHTML={{ __html: question.headerHtml }} />
                          )}
                          {question.html ? (
                            <div dangerouslySetInnerHTML={{ __html: question.question }} className={classes.htmlQuestionLabel}/>
                          ) : (
                            <Typography className={classes.questionLabel}>
                              {`${question.question}`}
                            </Typography>
                          )}
                          {question.footerHtml && (
                            <div dangerouslySetInnerHTML={{ __html: question.footerHtml }} />
                          )}
                          {get(errors, `questionnaire[${questionNo}].answers`) &&
                            get(touched, `questionnaire[${questionNo}].answers`) && (
                              <div className={classes.requiredErrorStyle}>
                                {errors.questionnaire![questionNo]!.answers}
                              </div>
                            )}
                          <div className={classes.buttonContainer}>
                            {renderOptions(question, values, questionNo, setFieldValue)}
                          </div>
                        </div>
                      );
                    })}
                    <div className={classes.buttonWrapperStyle}>
                      <Button className={classes.nextButtonStyle} onClick={submitForm}>
                        Next
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default Questionnaire;
