import {
  GetBuildingsRequest,
  GetBuildingsResponse,
  GetPartnerDetailsRequest,
  GetQuestionsRequest,
  GetQuestionsResponse,
  PartnerDetails,
  PostQuestionsRequest,
} from '../../models/shortApplication/shortApplication';
import { StoreAction } from '../StoreHelper';

export enum ShortApplicationActionTypes {
  GET_SHORT_APPLICATION_BUILDINGS = 'GET_SHORT_APPLICATION_BUILDINGS',
  GET_SHORT_APPLICATION_BUILDINGS_REQUEST = 'GET_SHORT_APPLICATION_BUILDINGS_REQUEST',
  GET_SHORT_APPLICATION_BUILDINGS_SUCCESS = 'GET_SHORT_APPLICATION_BUILDINGS_SUCCESS',
  GET_SHORT_APPLICATION_BUILDINGS_ERROR = 'GET_SHORT_APPLICATION_BUILDINGS_ERROR',

  GET_SHORT_APPLICATION_QUESTIONS = 'GET_SHORT_APPLICATION_QUESTIONS',
  GET_SHORT_APPLICATION_QUESTIONS_REQUEST = 'GET_SHORT_APPLICATION_QUESTIONS_REQUEST',
  GET_SHORT_APPLICATION_QUESTIONS_SUCCESS = 'GET_SHORT_APPLICATION_QUESTIONS_SUCCESS',
  GET_SHORT_APPLICATION_QUESTIONS_ERROR = 'GET_SHORT_APPLICATION_QUESTIONS_ERROR',

  POST_SHORT_APPLICATION_QUESTIONS = 'POST_SHORT_APPLICATION_QUESTIONS',
  POST_SHORT_APPLICATION_QUESTIONS_REQUEST = 'POST_SHORT_APPLICATION_QUESTIONS_REQUEST',
  POST_SHORT_APPLICATION_QUESTIONS_SUCCESS = 'POST_SHORT_APPLICATION_QUESTIONS_SUCCESS',
  POST_SHORT_APPLICATION_QUESTIONS_ERROR = 'POST_SHORT_APPLICATION_QUESTIONS_ERROR',
  POST_SHORT_APPLICATION_QUESTIONS_RESET = 'POST_SHORT_APPLICATION_QUESTIONS_RESET',

  GET_PARTNER_DETAILS = 'GET_PARTNER_DETAILS',
  GET_PARTNER_DETAILS_REQUEST = 'GET_PARTNER_DETAILS_REQUEST',
  GET_PARTNER_DETAILS_SUCCESS = 'GET_PARTNER_DETAILS_SUCCESS',
  GET_PARTNER_DETAILS_ERROR = 'GET_PARTNER_DETAILS_ERROR',
  GET_PARTNER_DETAILS_RESET = 'GET_PARTNER_DETAILS_RESET',
}

export type ShortApplicationActionPayload =
  | GetBuildingsRequest
  | GetBuildingsResponse[]
  | GetQuestionsRequest
  | GetQuestionsResponse[]
  | PostQuestionsRequest
  | GetPartnerDetailsRequest
  | PartnerDetails;

export type ShortApplicationAction = StoreAction<
  ShortApplicationActionTypes,
  ShortApplicationActionPayload
>;

export class ShortApplicationActions {
  public static getShortApplicationBuildingsRequest(data: GetBuildingsRequest) {
    return { type: ShortApplicationActionTypes.GET_SHORT_APPLICATION_BUILDINGS_REQUEST, data };
  }
  public static getShortApplicationBuildingsSuccess(data: GetBuildingsResponse[]) {
    return { type: ShortApplicationActionTypes.GET_SHORT_APPLICATION_BUILDINGS_SUCCESS, data };
  }
  public static getShortApplicationBuildingsError(data: Error) {
    return { type: ShortApplicationActionTypes.GET_SHORT_APPLICATION_BUILDINGS_ERROR, data };
  }

  public static getShortApplicationQuestionsRequest(data: GetQuestionsRequest) {
    return { type: ShortApplicationActionTypes.GET_SHORT_APPLICATION_QUESTIONS_REQUEST, data };
  }
  public static getShortApplicationQuestionsSuccess(data: GetQuestionsResponse[]) {
    return { type: ShortApplicationActionTypes.GET_SHORT_APPLICATION_QUESTIONS_SUCCESS, data };
  }
  public static getShortApplicationQuestionsError(data: Error) {
    return { type: ShortApplicationActionTypes.GET_SHORT_APPLICATION_QUESTIONS_ERROR, data };
  }

  public static postShortApplicationQuestionsRequest(data: PostQuestionsRequest) {
    return { type: ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_REQUEST, data };
  }
  public static postShortApplicationQuestionsSuccess() {
    return {
      type: ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_SUCCESS,
      data: null,
    };
  }
  public static postShortApplicationQuestionsError(data: Error) {
    return { type: ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_ERROR, data };
  }

  public static postShortApplicationQuestionsReset() {
    return { type: ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_RESET, data: null };
  }

  public static getPartnerDetails(data: GetPartnerDetailsRequest) {
    return { type: ShortApplicationActionTypes.GET_PARTNER_DETAILS_REQUEST, data };
  }

  public static getPartnerDetailsSuccess(data: string) {
    return { type: ShortApplicationActionTypes.GET_PARTNER_DETAILS_SUCCESS, data };
  }

  public static getPartnerDetailsError(data: Error) {
    return { type: ShortApplicationActionTypes.GET_PARTNER_DETAILS_ERROR, data };
  }

  public static getPartnerDetailsReset() {
    return { type: ShortApplicationActionTypes.GET_PARTNER_DETAILS_RESET, data: null };
  }
}
