import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { actionGreen } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  backRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.units.spacing * 24,
  },
  smallGap: {
    marginBottom: theme.units.spacing * 8,
  },
  backArrow: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
    marginRight: theme.units.spacing * 6,
  },
});
