import { UserType } from '../user/User';

export enum PaymentProvider {
  Sorted = 'SORTED',
  DEFT = 'DEFT',
  BPAY = 'BPAY',
  Other = 'Other',
}

export interface PaymentInfo {
  payment_provider: PaymentProvider | string | undefined | null;
  biller_info?: string | undefined | null;
  accountName?: string;
  accountNumber?: string;
  bsbNumber?: string;
  recipientName?: string;
  recipientAddress?: string;
  bankName?: string;
  bankAddress?: string;
  swiftBic?: string;
}

export interface PaymentTransactions {
  internalPaymentId: string;
  amount: number;
  paymentProvider: PaymentProvider | string | undefined | null;
  paymentProviderType?: string | null;
  billerCode?: string | null;
  accountNumber?: string | null;
  accountName?: string | null;
  paymentDate?: string | null;
  paymentType: string;
  paymentReference?: string | null;
  transactionRunScheduleId?: string | null;
  sortedStatus?: string | null;
  payId?: string | null;
  reference?: string | null;
  payIdLabel?: string | null;
  sendSMS: boolean;
  bsbNumber?: string | null;
  recipientName?: string | null;
  recipientAddress?: string | null;
  bankName?: string | null;
  bankAddress?: string | null;
  swiftBic?: string | null;
  transactionRunId?: string | null;
  notes?: string | null;
}

export interface RentalIncrease {
  firstAmount: number | null;
  firstDate: string | null;
  secondAmount: number | null;
  secondDate: string | null;
  rentIncreaseLimit?: string | null;
  rentIncreaseLimitType?: string | null;
}

export class PaymentDetails {
  refId: string;
  paymentMethod: string;
  cardType: string;
  cardName: string;
  cardNumber: string;
  cardExpiryYear: string;
  cardExpiryMonth: string;
  accountName: string;
  accountNumber: string;
  bsb: string;
}

export interface RentSplit {
  userId: string | number;
  name: string;
  surname: string;
  userType: UserType | string;
  rentSplitAmount: number | null; // Percen
}

export interface KolmeoPaymentType {
  paymentMethod: string | null;
  resultKey?: string;
}

export interface CreditCardRequest {
  type: string;
  cardHolderName: string;
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  email: string;
}

export interface BankAccountRequest {
  type: string;
  accountName: string;
  accountNumber: string;
  bsbNumber: string;
  email: string;
}

export interface GetPaymentAuthKeyRequest {
  token?: string;
  data: CreditCardRequest | BankAccountRequest;
  onSuccess: (token: string) => void;
  onError: (error: Error) => void;
}

export interface GetPaymentAuthKeyResponse {
  authKey: string;
  status: number;
}

export interface PaymentToken {
  resultKey: string;
}

export interface PostPaymentTokenRequest {
  data: PaymentToken;
  onSuccess: (response: PaymentDetails) => void;
}
