import { isEqual } from 'lodash';
import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../../../constants/AppConstants';
import { store } from '../../../../store/Store';

export const LABELS = {
  POWERED_BY: 'Powered by',
  EMAIL_ADDRESS: 'Email address',
  SALUTATION: 'Title',
  FIRSTNAME: 'First name',
  SURNAME: 'Surname',
  WEEKLY_RENT: 'Weekly rent',
  PREFERRED_MOVE_IN_DATE: 'Preferred move in date *',
  PREFERRED_LEASE_DURATION: 'Preferred lease duration',
  YEAR: 'Year(s) *',
  MONTH: 'Month(s) *',
  WHO_WILL_BE_LIVING_THERE: 'Who would be living in the place?',
  PEOPLE: 'People',
  PET: 'Pet',
  BUTTON_TEXT: 'Save and next',
  BACK: 'BACK',
  YEAR_RANGE: 10,
  MONTH_RANGE: 11,
  PLACEHOLDER_WEEKLY_RENT: 100,
  SIGN_UP_CONTENT: 'Sign up to join Sorted and save your details for next time.',
  LOG_IN_CONTENT: 'Welcome back! Log in to prefill your details',
  NOT_VERIFIED_CONTENT:
    'You have already registered this email address, to save and prefill your details please click the link in your email to verify the account ',
  VERIFY_REQUIRED_LABEL:
    'This email address hasn�t been confirmed, please click the link in your email to verify your account. Or receive a new confirmation email by clicking ',
  LOG_IN: 'LOG IN',
  RESEND_LINK: 'RESEND LINK',
  SIGN_UP: ' SIGN UP',
  WILL_YOU: 'Will you be living with others who are submitting their information separately?',
  HOW_MANY_PEOPLE: 'How many others will be submitting their information separately?',
  HOW_MANY_PET: 'How many pets will be living at the property?',
  DATE_OF_BIRTH: 'Date of birth *',
  CHILD_OR_DEPENDENT: 'Child or dependent ?',
  UNDER_18_YEARS: 'Are they under 18 years of age ? *',
  COMPLETING_PROFILE: 'Are you completing their profile ? *',
  MOBILE: 'Mobile',
  AN_INVITATION:
    'A group invitation will be sent to this person once you have submitted your application so they can submit their profile separately',
  ACCEPTED: 'This person has joined the group and will be submitting their information separately',
  ADD_CO: 'Add another co-applicant',
  NAME_GROUP: 'Would you like to give a name to this group?',
  COAPPLICANTS_TITLE: 'Invited profiles',
  COAPPLICANTS_SUBTITLE:
    'Here are others you have invited who are submitting their information separately.',
  BASIC_DETAILS: 'Basic details',
};

export const ERRORS = {
  requiredEmail: 'Please provide an email address',
  requiredTitle: 'Title required',
  requiredFirstName: 'Enter first name',
  requiredSurname: 'Enter surname',
  requiredRent: 'Enter weekly rent',
  requiredDate: 'Enter preferred move in date',
  requiredSelectedDate: 'Enter date of birth',
  requiredYear: 'Enter preferred lease duration year',
  requiredMonth: 'Enter preferred lease duration month',
  invalidEmailFormat: 'Invalid email format',
  invalidNameFormat: 'Invalid name format',
  invalidNumber: 'Enter weekly rent',
  invalidDateFormat: 'Enter proper Date',
  futureDate: 'Date cannot be in the future',
  pastDate: 'Date cannot be in the past',
  minimumWeeklyRent: 'Weekly rent cannot be less than 1',
  minimumYear: 'Year must be greater than or equal to 0',
  minimumMonth: 'Month must be greater than or equal to 0',
  minimumMonthsWhenYearIsZero: 'When year is zero, month must be greater than or equal to 1',
  minimumYearsWhenMonthIsZero: 'When month is zero, year must be greater than or equal to 1',
  maxStringLength: 'Can be at most 255 characters',
  duplicateEmail: "Applicant's email cannot be same",
  above18ErrorMessage: 'Anyone over 18 must make a full application',
  invalidPhoneNumber: 'Invalid phone number',
  requiredMobileNumber: 'Enter mobile number',
  selfReferenced: 'Cannot add yourself as a co-applicant',
};

const { tenantDetails } = store.getState().landingScreen;

export const VALIDATION_SCHEMA = Yup.object().shape({
  coApplicants: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .required(ERRORS.requiredFirstName)
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
        .nullable(),
      lastName: Yup.string()
        .required(ERRORS.requiredSurname)
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
        .nullable(),
      title: Yup.string().required(ERRORS.requiredTitle).nullable(),
      email: Yup.string()
        .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat)
        .required(ERRORS.requiredEmail)
        .max(255, ERRORS.maxStringLength)
        .nullable()
        .test('value', ERRORS.selfReferenced, (value) => {
          if (tenantDetails) {
            return value !== tenantDetails.email;
          }
          return true;
        }),
      selectedDate: Yup.date()
        .when('isChild', {
          is: true,
          then: Yup.date().required(ERRORS.requiredSelectedDate).max(new Date(), ERRORS.futureDate),
        })
        .nullable(),
      mobile: Yup.string().required(ERRORS.requiredMobileNumber).nullable(),
    }),
  ),
});

export function changeInLandingScreen(prevTenantDetails: any, nextTenantDetails: any) {
  const tenantDetailsA = { ...prevTenantDetails };
  const tenantDetailsB = { ...nextTenantDetails };

  /** delete property specific keys from the tenantDetails object */
  const propertySpecificKeys: string[] = [
    'selectedDate',
    'leaseDurationMonth',
    'leaseDurationYear',
  ];
  propertySpecificKeys.forEach((e) => {
    delete tenantDetailsA[e];
    delete tenantDetailsB[e];
  });
  return !isEqual(tenantDetailsA, tenantDetailsB);
}
