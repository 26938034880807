import * as Yup from 'yup';
import { MIN_ID_POINTS } from '../../../../constants/AppConstants';

export const LABELS = {
  IDENTIFICATION: 'Identification',
  IDENTIFICATION_TYPE: 'Identification Type',
  ATTACHMENTS: 'Attachments (PDF, JPG,PNG) 10MB *',
  NAME: 'Name',
  FILE_SIZE_ERROR: 'Upload failed. File size cannot exceed 10MB.',
  ADD_ANOTHER_ID: '+ Add another ID type',
  ADD_ANOTHER_REFERENCE_DESCRIPTON: `You must supply at least ${MIN_ID_POINTS} ID Points for your application to be considered.`,
  BUTTON_TEXT: 'Save and next',
  ID_POINTS_PREFIX: 'You now have ',
  ID_POINTS: ' ID points. ',
  LATER: "I'LL DO IT LATER",
  POINTS: 'points',
  MIN_ID_POINTS,
  REMOVE: 'Remove',
  STATE_OF_ISSUE: 'State of issue',
  LICENSE_NUMBER: 'License number',
  COUNTRY_OF_ORIGIN: 'Country of origin',
  PASSPORT_NUMBER: 'Passport number',
  CARD_NUMBER: 'Card number',
  REFERENCE_NUMBER: 'Reference number',
  MEDICARE_COLOUR: 'Colour',
  EXPIRY: 'Expiry',
  DOCUMENT_REQUIRED: 'Please add atleast one document among passport, drivers licence or medicare',
  MONTH: 'Month',
  YEAR: 'Year',
  YEAR_RANGE: 10,
  NTD_CHECK_SUMMARY: 'Background check summary',
  DOWNLOAD_PDF: 'Download PDF',
  INCLUDE_NTD: 'Include background check',
  START_AGAIN: 'Start again',
  INFO: "At Sorted we value your security which is why after a period of inactivity any sensitive documents from your profile will be removed, don't worry you'll just need to upload them before your next application.",
};

export const ERRORS = {
  required: 'Required',
  stateOfIssueDLError: 'Please select any state',
  licenseNumber: 'Please enter license number',
  maxStringLength: 'Can be at most 12 characters',
  requiredCountryOfOrigin: 'Please select a country',
  passportNumberError: 'Please enter passport number',
  cardNumberError: 'Please enter card number',
  referenceNumberError: 'Please enter reference number',
  medicareColourError: 'Please select any colour',
  expiryDateError: 'Enter expiry date',
  pastDate: 'Date cannot be in the past',
  farDate: 'Date cannot be that far in the future',
  medicareNumberLength: 'Should be exactly 10 characters',
};

export const ID_POINTS = {
  DRIVING_LICENSE: 40,
  PASSPORT: 40,
  BIRTH_CERTIFICATE: 30,
  MEDICARE: 20,
  OTHER: 10,
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  identification: Yup.array().of(
    Yup.object().shape({
      identificationLink: Yup.string().nullable(),
      type: Yup.string().nullable(),
      stateOfIssueDL: Yup.string()
        .when('type', {
          is: 'DRIVING_LICENSE',
          then: Yup.string(),
        })
        .nullable(),
      licenseNumber: Yup.string()
        .when('type', {
          is: 'DRIVING_LICENSE',
          then: Yup.string().max(12, ERRORS.maxStringLength),
        })
        .nullable(),
      countryOfOrigin: Yup.string()
        .when('type', {
          is: 'PASSPORT',
          then: Yup.string(),
        })
        .nullable(),
      passportNumber: Yup.string()
        .when('type', {
          is: 'PASSPORT',
          then: Yup.string(),
        })
        .nullable(),
      cardNumber: Yup.string()
        .when('type', {
          is: 'MEDICARE',
          then: Yup.string().length(10, ERRORS.medicareNumberLength),
        })
        .nullable(),
      referenceNumber: Yup.string()
        .when('type', {
          is: 'MEDICARE',
          then: Yup.string(),
        })
        .nullable(),
      medicareColour: Yup.string()
        .when('type', {
          is: 'MEDICARE',
          then: Yup.string(),
        })
        .nullable(),
      expiryDate: Yup.date()
        .when('type', {
          is: 'MEDICARE',
          then: Yup.date()
            .min(new Date(), ERRORS.pastDate)
            .max(new Date('01/01/2999'), ERRORS.farDate),
        })
        .when('type', {
          is: 'DRIVING_LICENSE',
          then: Yup.date()
            .min(new Date(), ERRORS.pastDate)
            .max(new Date('01/01/2999'), ERRORS.farDate),
        })
        .when('type', {
          is: 'PASSPORT',
          then: Yup.date()
            .min(new Date(), ERRORS.pastDate)
            .max(new Date('01/01/2999'), ERRORS.farDate),
        })
        .nullable(),
    }),
  ),
});
