/* eslint-disable import/prefer-default-export */
import { OwnershipDetails } from '../../../../services/remoteSigning/ownership/Ownership';
import { SigningSteps, StepType, Terms } from '../../RemoteSigningUtils';

export class LandlordRemoteSigningHelper {
  public static setSigningsteps(
    ownershipDetails: OwnershipDetails | undefined,
    propertyIndex: number,
  ) {
    const steps: SigningSteps[] = [];
    let count = 1;
    if (!ownershipDetails) {
      return steps;
    }

    const { documents } = ownershipDetails.properties[propertyIndex].property;
    if (documents.agreement) {
      const content: Terms[] = [];
      let index = 1;
      documents.agreement.leaseAgreementTermResponse
        .sort((term1, term2) => {
          return term1.leaseTermsOrder - term2.leaseTermsOrder;
        })
        .map((leaseTerms) => {
          content.push({
            index,
            title: leaseTerms.terms.title,
            htmlContent: leaseTerms.terms.htmlContent,
            requireContentSection: leaseTerms.terms.requireContentSection,
          });
          index++;
        });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: 'Tenancy Agreement',
          stepType: StepType.PreviewDocument,
          content,
          footerHeight: 166,
          acceptText: 'Accept all',
        });
        count++;
      }
    }
    if (documents.configurableDocuments) {
      documents.configurableDocuments.map((leaseConfigurableDocument) => {
        const content: Terms[] = [];
        let index = 1;
        leaseConfigurableDocument.leaseConfigurableDocumentTerm
          .sort((term1, term2) => {
            return term1.leaseTermsOrder - term2.leaseTermsOrder;
          })
          .map((leaseTerms) => {
            content.push({
              index,
              title: leaseTerms.terms.title,
              htmlContent: leaseTerms.terms.htmlContent,
              requireContentSection: leaseTerms.terms.requireContentSection,
            });
            index++;
          });
        if (content.length !== 0) {
          steps.push({
            stepNo: count,
            stepTitle: leaseConfigurableDocument.templateInfo.title,
            stepType: StepType.PreviewDocument,
            content,
            footerHeight: 166,
            acceptText: 'Accept all',
          });
          count++;
        }
      });
    }
    if (documents.uploadTemplates) {
      const content: Terms[] = [];
      let index = 1;
      documents.uploadTemplates.map((uploadTemplate) => {
        content.push({
          index,
          title: uploadTemplate.title as string,
          link: uploadTemplate.link as string,
        });
        index++;
      });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: 'Additional documents',
          stepType: StepType.DownloadDocument,
          content,
          footerHeight: 126,
          acceptText: 'Accept all',
        });
        count++;
      }
    }
    if (documents.pdfFormTemplates) {
      const content: Terms[] = [];
      let index = 1;
      documents.pdfFormTemplates.map((pdfFormTemplate) => {
        content.push({
          index,
          title: pdfFormTemplate.title as string,
          link: pdfFormTemplate.link as string,
        });
        index++;
      });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: 'Additional documents',
          stepType: StepType.DownloadDocument,
          content,
          footerHeight: 126,
          acceptText: 'Accept all',
        });
        count++;
      }
    }

    return steps;
  }
}
