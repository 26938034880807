import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  snackBarContentStyles: {
    borderRadius: '5px',
    backgroundColor: '#fafafa',
    color: '#202020;',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.19',
    letterSpacing: '0px',
    fontFamily: 'Helvetica',
    borderLeft: '4px solid #1badc1',
    boxShadow:
      '0 2px 4px - 1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
  },
  snackBarTextStyles: {
    marginLeft: '15px',
  },
  errorStyle: {
    borderLeft: `0.25rem solid ${theme.colors.warning}`,
  },
});
