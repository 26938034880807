/* eslint-disable default-case */
import React, { FC, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { RemoteSigningGetResponse } from '../../../../services/remoteSigning/RemoteSigning';
import SigningBox from '../RemoteSigning/components/SigningBox';
import { useStyles } from './LandlordSigningScreenStyles';
import { SignatureType, LABELS } from './LandlordSigningScreenConstants';
import PersonCard from '../RemoteSigning/components/PersonCard';
import { Messages } from '../../RemoteSigningConstants';

interface LandLordSigningScreenProps {
  remoteSigningData: RemoteSigningGetResponse;
  handleSignatureSubmit: (
    signatureBase64: string,
    initialBase64: string,
    witnessSignatureBase64: string | null,
    witnessName: string,
  ) => void;
  handleBack: () => void;
  setActiveStep: (step: number) => void;
}

const LandLordSigningScreen: FC<LandLordSigningScreenProps> = ({
  remoteSigningData,
  handleSignatureSubmit,
  handleBack,
  setActiveStep,
}) => {
  const classes = useStyles();
  const [initialsBase64, setInitialsBase64] = useState<string | null | undefined>(null);
  const [signatureBase64, setSignatureBase64] = useState<string | null | undefined>(null);
  const [notSigned, setNotSigned] = useState<boolean>(false);
  const [notInitialed, setNotInitialed] = useState<boolean>(false);
  const { titles } = Messages.pages.remoteSigningPage;

  const onBack = () => {
    handleBack();
    setNotSigned(false);
    setNotInitialed(false);
  };

  const next = () => {
    handleSignatureSubmit(signatureBase64 as string, initialsBase64 as string, null, '');
  };

  const validateSignature = () => {
    if (signatureBase64 && initialsBase64) {
      next();
    } else {
      if (!signatureBase64) {
        setNotSigned(true);
      }
      if (!initialsBase64) {
        setNotInitialed(true);
      }
    }
  };

  const renderContent = (signatureType: SignatureType) => {
    switch (signatureType) {
      case SignatureType.LandlordSignature:
      case SignatureType.LandlordInitials: {
        setActiveStep(1);
        let type = SignatureType.LandlordSignature;
        let title = 'Sign below';
        if (signatureType === SignatureType.LandlordInitials) {
          type = SignatureType.LandlordInitials;
          title = 'Initial below';
        }
        return (
          <SigningBox
            type={type}
            hidePersonCard
            title={title}
            witnessName=""
            setWitnessName={(name) => null}
            witnessNameRequired={false}
            setWitnessNameRequired={(value) => null}
            remoteSigningData={remoteSigningData}
            initialsBase64={initialsBase64}
            setInitialsBase64={(value) => setInitialsBase64(value)}
            signatureBase64={signatureBase64}
            setSignatureBase64={(value) => setSignatureBase64(value)}
            witnessSignatureBase64={null}
            setWitnessSignatureBase64={(value) => null}
            notSigned={signatureType === SignatureType.LandlordSignature ? notSigned : notInitialed}
            setNotSigned={(value) => {
              if (signatureType === SignatureType.LandlordSignature) {
                setNotSigned(value);
              } else {
                setNotInitialed(value);
              }
            }}
            propertyState={null}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Paper className={classes.RDSContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h5">
            Sign and initial
          </Typography>
        </div>
        <div className={classes.signContainer}>
          <Typography variant="h1" className={classes.declarationText}>
            {titles.tenantHeader}
          </Typography>
          <PersonCard
            profileName={`${remoteSigningData.user.name} ${remoteSigningData.user.surname}`}
            profileContext={remoteSigningData.user.userType}
          />
        </div>
        <div className={classes.signingContainers}>
          <div className={classes.signBelow}>{renderContent(SignatureType.LandlordSignature)}</div>
          <div className={classes.initialBelow}>
            {renderContent(SignatureType.LandlordInitials)}
          </div>
        </div>
      </Paper>
      <div className={classes.buttonContainer}>
        <div className={classes.secondaryButton} onClick={() => onBack()}>
          Back
        </div>
        <div className={classes.primaryButton} onClick={() => validateSignature()}>
          Next
        </div>
      </div>
    </>
  );
};

export default LandLordSigningScreen;
