/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import * as Yup from 'yup';

export const LABELS = {
  ADD_PROPERTY: 'You can apply for up to 5 properties at once',
  SEARCH_AND_APPLY: 'Search and apply for up to five properties',
  PROPERTY: 'Property',
  PROPERTIES: 'Add Properties',
  ADVERTISED_WEEKLY_RENTAL_AMOUNT: 'Weekly rental amount *',
  DID_YOU_INSPECT: 'How have you inspected this property?',
  INSPECTION_DATE: 'When did you inspect this property?',
  PREFERRED_MOVE_IN_DATE: 'Preferred move in date',
  PREFERRED_LEASE_DURATION: 'Preferred lease duration (years)',
  YEAR_RANGE: 10,
  MONTH_RANGE: 11,
  EMBEDDED_NETWORKS: 'Note : This property has Embedded Network(s).',
  YEARS: 'Years',
  MONTHS: 'Months',
  HOT: 'This property is hot',
  DELETE: 'Delete',
  BEDROOMS: 'bedrooms',
  BATHROOMS: 'bathrooms',
  CARSPACES: 'car spaces',
  RENTAL_OFFER: 'Rent',
  RENT_PW: 'Rent pw',
  NEXT: 'Next',
  ARCHIVED:
    'The property you are applying for has been archived. \nPlease try searching for the property again to see if it has been re-listed.',
};

export const ERRORS = {
  requiredEmail: 'Please provide an email address',
  requiredTitle: 'Salutation required',
  requiredFirstName: 'Enter first name',
  requiredSurname: 'Enter surname',
  requiredRent: 'Enter weekly rent',
  requiredDate: 'Enter preferred move in date',
  requiredSelectedDate: 'Enter a inspection date',
  requiredInspectionMethod: 'Enter how you inspected this property',
  requiredPreferredMoveInDate: 'Enter a preferred move in date',
  requiredYear: 'Enter preferred lease duration year',
  requiredMonth: 'Enter preferred lease duration month',
  invalidEmailFormat: 'Invalid email format',
  invalidNameFormat: 'Invalid name format',
  invalidNumber: 'Enter weekly rent',
  invalidDateFormat: 'Enter proper Date',
  futureDate: 'Date cannot be in the future',
  pastDate: 'Date must be in the future',
  minimumWeeklyRent: 'Weekly rent cannot be less than 1',
  minimumYear: 'Year must be greater than or equal to 0',
  minimumMonth: 'Month must be greater than or equal to 0',
  minimumMonthsWhenYearIsZero: 'When year is zero, month must be greater than or equal to 1',
  minimumYearsWhenMonthIsZero: 'When month is zero, year must be greater than or equal to 1',
  minimumPeople: 'Number of people must be greater than or equal to 1',
  maxStringLength: 'Can be at most 255 characters',
  duplicateEmail: "Applicant's email cannot be same",
  above18ErrorMessage: 'Anyone over 18 must make a full application',
  invalidPhoneNumber: 'Invalid phone number',
  requiredMobileNumber: 'Enter mobile number',
};

export const INSPECTION_METHOD = [
  'I have inspected this property in person and accept it in its current state',
  'I have inspected this property virtually and accept it in its current state',
  'A nominated person inspected this property on my behalf and I accept it in its current state',
  'I have not inspected this property',
];

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const VALIDATION_SCHEMA = Yup.object().shape({
  properties: Yup.array().of(
    Yup.object().shape({
      rentalOffer: Yup.number()
        .min(1, ERRORS.minimumWeeklyRent)
        .required(ERRORS.requiredRent)
        .typeError(ERRORS.invalidNumber)
        .nullable(),
      inspectionMethod: Yup.string().required(ERRORS.requiredInspectionMethod),
      inspectionDate: Yup.date()
        .when('inspectionMethod', {
          is: (inspectionMethod) => inspectionMethod === 'I have not inspected this property.',
          then: Yup.date()
            .max(tomorrow, ERRORS.futureDate)
            .required(ERRORS.requiredSelectedDate)
            .nullable(),
          otherwise: () => null,
        })
        .nullable(),
      preferredMoveInDate: Yup.date()
        .required(ERRORS.requiredPreferredMoveInDate)
        .min(new Date(), ERRORS.pastDate)
        .nullable(),
      years: Yup.number().required(ERRORS.requiredYear).min(0, ERRORS.minimumYear),
      months: Yup.number()
        .when('years', {
          is: (years) => years === 0,
          then: Yup.number().min(1, ERRORS.minimumMonthsWhenYearIsZero),
        })
        .min(0, ERRORS.minimumMonth)
        .required(ERRORS.requiredMonth),
    }),
  ),
});
