/* eslint-disable import/prefer-default-export */
import { MAIL_MERGE_FIELDS } from '../constants/MailMergeFields';
import { Pair } from './Collections';

export class TermMappingHelper {
  public static substituteMailMergeFields(term: any, leaseData?: any) {
    let newTerm = term;

    MAIL_MERGE_FIELDS.map((field, index) => {
      if (index === 0) {
        return;
      }

      field.subFields!.map((subField) => {
        const mailMergeField = `\$\{${subField.value}\}`;
        /* istanbul ignore else */
        if (term.search(mailMergeField)) {
          newTerm = this.replaceAll(
            newTerm,
            mailMergeField,
            leaseData && leaseData[field.label] && leaseData[field.label][subField.label]
              ? leaseData[field.label][subField.label]
              : subField.placeHolder,
          );
        }
      });
    });
    return newTerm;
  }

  public static convertDynamicStringForDisclosure(
    str: string,
    disclosureQuestionnaire: Record<string, string>,
  ) {
    const disclosureMergeFieldRegex = new RegExp('\\$\\{Disclosure:\\w+}', 'g');
    const disclosureMergeField = (key: string) => disclosureQuestionnaire[key];

    const dynamicMergeFields: Pair<RegExp, (key: string) => string>[] = [
      Pair.of(disclosureMergeFieldRegex, disclosureMergeField),
    ];

    var finalString = str;
    dynamicMergeFields.forEach((field) => {
      const matches = finalString.matchAll(field.first);
      Array.from(matches).forEach((match) => {
        const value = match[0];
        const key = value && value.split(':')[1].slice(0, -1);
        finalString = finalString.replace(value, field.second(key));
      });
    });
    return finalString;
  }

  public static escapeRegExp(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  public static replaceAll(str: string, term: string, replacement: string) {
    return str.replace(new RegExp(this.escapeRegExp(term), 'gmi'), replacement);
  }
}
