/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HomeIcon from '@mui/icons-material/Home';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/RootReducers';
import { useStyles } from './ViewPropertyStyles';
import { LABELS } from './ViewPropertyConstants';
import backIcon from '../../assets/back-arrow.svg';
import locationIcon from '../../assets/location-blue.svg';
import bedIcon from '../../assets/bed.svg';
import bathIcon from '../../assets/bath.svg';
import carIcon from '../../assets/car.svg';
import phoneIcon from '../../assets/phone.svg';
import emailIcon from '../../assets/email.svg';
import arrowRightIcon from '../../assets/arrow-right.svg';
import assignmentWhite from '../../assets/assignment-white.svg';
import { SimilarPropertiesResponse } from '../../services/dashboard/similarProperties/GetSimilarProperties';
import { routes } from '../../Routes';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { Agent } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import SORTED_CIRCULAR_LOGO from '../../assets/logosCircle/sorted.png';

interface MatchParams {
  refId: string;
}

interface ViewPropertyProps extends RouteComponentProps<MatchParams> {
  selectedSimilarProperty: SimilarPropertiesResponse | undefined | null;
  getPropertyDetails: (data: SimilarPropertiesResponse) => void;
}

const ViewProperty: FC<ViewPropertyProps> = ({
  history,
  selectedSimilarProperty,
  getPropertyDetails,
}) => {
  const classes = useStyles();
  useEffect(() => {
    // Call viewProperty API with refId as param
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const addProperty = () => {
    getPropertyDetails(selectedSimilarProperty!);
    history.push(routes.properties.view(selectedSimilarProperty!.agencyCode));
  };

  const sortedCircularLogo = SORTED_CIRCULAR_LOGO;

  return (
    <div className={classes.root}>
      {selectedSimilarProperty ? (
        <div className={classes.contentContainer}>
          <div className={classes.viewPropertyHeader}>
            <img
              src={backIcon}
              className={classes.backArrowHeader}
              alt="back-icon"
              onClick={() => history.goBack()}
            />
            <div className={classes.viewPropertyHeaderText}>{LABELS.APPLICATIONS}</div>
          </div>
          <div className={classes.propertyImagesContainer}>
            {selectedSimilarProperty.photos.length ? (
              <img
                src={selectedSimilarProperty.photos[0]}
                className={classes.propertyImage}
                alt="property-pic"
              />
            ) : (
              <div className={classes.noPropertyImage}>
                <HomeIcon className={classes.homeLogo} />
              </div>
            )}
          </div>
          <div className={classes.viewPropertyCard}>
            <div className={classes.viewPropertyCardHeader}>
              <img src={locationIcon} className={classes.cardHeaderIcon} alt="location-icon" />
              <div className={classes.cardHeaderText}>{selectedSimilarProperty.address}</div>
              <div className={classes.cardHeaderRent}>${selectedSimilarProperty.rent} pw</div>
            </div>
            <div className={classes.propertyDetailsContainer}>
              {selectedSimilarProperty.bedrooms ? (
                <>
                  <img src={bedIcon} className={classes.propertyDetailsIcon} alt="bed-icon" />
                  <div className={classes.propertyDetailsIconText}>
                    {selectedSimilarProperty.bedrooms}
                  </div>
                </>
              ) : null}
              {selectedSimilarProperty.bathrooms ? (
                <>
                  <img
                    src={bathIcon}
                    style={{ height: '24px' }}
                    className={classes.propertyDetailsIcon}
                    alt="bath-icon"
                  />
                  <div className={classes.propertyDetailsIconText}>
                    {selectedSimilarProperty.bathrooms}
                  </div>
                </>
              ) : null}
              {selectedSimilarProperty.carspaces ? (
                <>
                  <img src={carIcon} className={classes.propertyDetailsIcon} alt="car-icon" />
                  <div className={classes.propertyDetailsIconText}>
                    {selectedSimilarProperty.carspaces}
                  </div>
                </>
              ) : null}
              {/* <div className={classes.propertyDetailsSize}>1,200 sq.mt</div> */}
            </div>
            {selectedSimilarProperty.description && (
              <div className={classes.propertyDescription}>
                {selectedSimilarProperty.description.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}
              </div>
            )}
            {(selectedSimilarProperty.agency as Agent).phone && (
              <div
                className={classes.contactAgentButton}
                onClick={() =>
                  window.open(`tel:${(selectedSimilarProperty.agency as Agent).phone}`)
                }
              >
                {LABELS.CONTACT}
              </div>
            )}
            {selectedSimilarProperty.agency ? (
              <>
                <div className={classes.sectionHeader}>{LABELS.AGENT_DETAILS}</div>
                <div className={classes.agentContainer}>
                  <div className={classes.agentRow}>
                    <img
                      src={selectedSimilarProperty.agencyLogo}
                      className={classes.agentLogo}
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = sortedCircularLogo;
                      }}
                      alt="Agency Logo"
                      width={98}
                    />
                    <div className={classes.agentNameContainer}>
                      <div className={classes.agentName}>
                        {(selectedSimilarProperty.agency as Agent).tradingName}
                      </div>
                      <div className={classes.agentContext}>{selectedSimilarProperty.state}</div>
                    </div>
                  </div>
                  {(selectedSimilarProperty.agency as Agent).phone && (
                    <div className={classes.agentRow}>
                      <img src={phoneIcon} className={classes.agentIcon} alt="agent icon" />
                      <div
                        className={classes.agentContactContainer}
                        onClick={() =>
                          window.open(`tel:${(selectedSimilarProperty.agency as Agent).phone}`)
                        }
                      >
                        <div className={classes.agentContactText}>
                          {(selectedSimilarProperty.agency as Agent).phone}
                        </div>
                        <img
                          src={arrowRightIcon}
                          className={classes.agentArrow}
                          alt="agent-arrow"
                        />
                      </div>
                    </div>
                  )}
                  {(selectedSimilarProperty.agency as Agent).email && (
                    <div className={classes.agentRow}>
                      <img src={emailIcon} className={classes.agentIcon} alt="agent icon" />
                      <div
                        className={classes.agentContactContainer}
                        onClick={() =>
                          (window.location.href = `mailto:${
                            (selectedSimilarProperty.agency as Agent).email
                          }`)
                        }
                      >
                        <div className={classes.agentContactText}>
                          {(selectedSimilarProperty.agency as Agent).email}
                        </div>
                        <img
                          src={arrowRightIcon}
                          className={classes.agentArrow}
                          alt="agent-arrow"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
          <div className={classes.viewPropertyActionsContainer}>
            <div className={classes.actionButtonContainer} onClick={() => history.goBack()}>
              <img src={backIcon} className={classes.actionButtonIcon} alt="action-icon" />
              Back
            </div>
            <div className={classes.actionButtonContainer} onClick={() => addProperty()}>
              <img src={assignmentWhite} className={classes.actionButtonIcon} alt="action-icon" />
              Add
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selectedSimilarProperty: state.dashboard.selectedSimilarProperty,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPropertyDetails: (data: SimilarPropertiesResponse) => {
    dispatch(DashboardActions.onGetPropertySuccess(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProperty);
