export const LABELS = {
  LOG_INTO_SORTED: 'Log into Sorted',
};

export const TRIANGLES = [
  {
    size: 50,
    rotation: 20,
    styles: {
      top: -45,
      marginLeft: 20,
    },
  },
  {
    size: 170,
    rotation: -15,
    styles: {
      top: -85,
    },
  },
  {
    size: 60,
    rotation: -10,
    styles: {
      top: 300,
    },
  },
  {
    size: 150,
    rotation: 30,
    styles: {
      top: -70,
    },
  },
  {
    size: 60,
    rotation: 100,
    styles: {
      top: 200,
      right: -50,
    },
  },
];
