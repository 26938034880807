import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useStyles } from './CustomButtonStyles';

interface CustomButtonProps {
  onConfirmation: () => void;
  onRejection: () => void;
  active: boolean | null;
}

const CustomButton: FC<CustomButtonProps> = ({ onConfirmation, active, onRejection }) => {
  const classes = useStyles();

  return (
    <div className={classes.customButtonContainer}>
      <Button
        className={active ? classes.activeButton : classes.inactiveButton}
        onClick={onConfirmation}
      >
        Yes
      </Button>
      <Button
        className={active ? classes.inactiveButton : classes.activeButton}
        onClick={onRejection}
      >
        No
      </Button>
    </div>
  );
};

export default CustomButton;
