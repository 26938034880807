import { QuestionnaireData, Questions } from '../../../store/state/ReferenceToolState';

export const ERRORS = {
  required: 'Required',
};

export const isQuestionValid = (questions: Questions[], index: number) => {
  const question = questions[index];
  let isValid = true;

  if (question.dependencyQuestionId) {
    // Question is conditionally Valid based on another questions answer
    isValid = false;
    const dependencyQuestion = questions.find((q) => q.id === question.dependencyQuestionId);
    if (dependencyQuestion) {
      switch (dependencyQuestion.type) {
        case 'switch':
        case 'textbox':
          // Check if Dependency Question is Answered is Dependency Answer
          if (
            dependencyQuestion.answer &&
            dependencyQuestion.answer.toString() === question.dependencyAnswer
          ) {
            isValid = true;
          }
          break;

        case 'file':
          if (dependencyQuestion.answer) {
            isValid = true;
          }
          break;
        default:
      }
    }
  }
  return isValid;
};

export const validation = (data: QuestionnaireData) => {
  let hasError = false;
  const errors = data.questionnaireData.map((question, index) => {
    const { required, answer } = question;

    const isRequired = required !== false;

    if (
      isQuestionValid(data.questionnaireData, index) &&
      isRequired &&
      (answer === null || answer === '' || answer === undefined)
    ) {
      hasError = true;
      return { answer: ERRORS.required };
    }
    return {};
  });

  if (hasError) return { questionnaireData: errors };
  else return {};
};

export const UPLOAD_FILE_LABEL =
  'Please upload your reference. (PDF, DOC/DOCX, CSV, XLS/XLSX, JPG/JPEG, or PNG) 10MB';

export const myQuestion = {
  question: 'I would like to provide my own questionnaire and answers for this reference.',
  type: 'switch',
  answer: false,
  required: true,
};

export const myQuestionInitialValues: QuestionnaireData = {
  questionnaireData: [
    {
      question: UPLOAD_FILE_LABEL,
      type: 'file',
      answer: null,
      required: true,
    },
  ],
};

export const UPLOADED_QUESTION_FILE_LABEL = 'Uploaded questionnaire and answers';
