/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { actionGreen, loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  faqContainer: {},
  faqRow: {
    marginBottom: theme.units.spacing * 4,
    paddingBottom: theme.units.spacing * 4,
    borderBottom: `${theme.colors.borderColor} 2px solid`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  actionIcon: {
    width: theme.units.iconSize / 1.6,
    height: theme.units.iconSize / 1.6,
    objectFit: 'contain',
    objectPosition: 'center',
    transition: 'all 0.5s ease 0s',
    ...actionGreen,
  },
  actionIconActive: {
    transform: 'rotate(180deg)',
    transition: 'all 0.5s ease 0s',
  },
  content: {
    transition: 'all 0.5s ease 0s',
    overflow: 'hidden',
    height: '100%',
  },
  question: {
    fontSize: '20px',
  },
  description: {
    paddingBottom: theme.units.spacing * 4,
  },
});
