import React, { FC, useEffect, useState, useMemo, CSSProperties } from 'react';
import { useStyles } from './TriangleStyles';

interface TriangleProps {
  size: number;
  border?: number;
  rotation?: number;
  parentStyles?: CSSProperties;
}

const Triangle: FC<TriangleProps> = ({ size, border = 24, rotation = 0, parentStyles }) => {
  const classes = useStyles();
  const EQ_ANGLE = 0.866;
  const triangleStyle = {
    ...parentStyles,
    borderLeftWidth: size,
    borderRightWidth: size,
    borderBottomWidth: (size + size) * EQ_ANGLE,
    transform: `rotate(${rotation}deg)`,
  };

  const innerTriangleStyle = {
    borderLeftWidth: size - border,
    borderRightWidth: size - border,
    borderBottomWidth: (size - border + size - border) * EQ_ANGLE,
    top: border / EQ_ANGLE,
  };
  return (
    <div className={classes.triangle} style={triangleStyle}>
      <div className={classes.innerTriangle} style={innerTriangleStyle} />
    </div>
  );
};

export default Triangle;
