/* eslint-disable import/prefer-default-export */
import { MarketplaceState } from '../state/MarketplaceState';
import { MarketplaceAction, MarketplaceActionTypes } from '../actions/MarketplaceActions';
import { AvailableServicesResponse } from '../../services/marketplace/Marketplace';

export const initialState: MarketplaceState = {
  availableServices: undefined,
};

export const marketplaceReducer = (state = initialState, action: MarketplaceAction) => {
  switch (action.type) {
    case MarketplaceActionTypes.GET_AVAILABLE_SERVICES_SUCCESS:
      return {
        ...state,
        availableServices: action.data as AvailableServicesResponse[],
      };
    default:
      return state;
  }
};
