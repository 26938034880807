import React, { ReactElement } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';
import { theme } from '../../theme/Theme';

const mandatoryErrorContainerStyle = {
  display: 'flex',
  paddingTop: 12,
  paddingBottom: 12,
  justifyContent: 'center',
};
const mandatoryErrorIcon = {
  color: theme.colors.warning,
};
const mandatoryErrorStyle = {
  paddingLeft: 8,
  color: theme.colors.warning,
  fontFamily: 'GT Walsheim',
};

interface Props {
  errorText?: string | undefined;
}
export default function MandatoryError({ errorText }: Props): ReactElement {
  return (
    <div style={mandatoryErrorContainerStyle}>
      <ErrorOutlineIcon style={mandatoryErrorIcon} />
      <Typography style={mandatoryErrorStyle}>
        {errorText || 'Mandatory information is missing'}
      </Typography>
    </div>
  );
}
