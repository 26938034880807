/* eslint-disable import/prefer-default-export */
export const LABELS = {
  ABOUT_TITLE: 'Way more than rental applications.',
  ABOUT_TITLE2: 'Set up & manage your household from move in to move out.',
  ABOUT_SUBTEXT: 'Get all the services you need, with Australia’s first home services marketplace.',
  FIND_NEXT_TITLE: 'Find your next home',
  FIND_NEXT_TEXT: 'Apply for properties in seconds using your Sorted profile',
  SETUP_TITLE: 'Set up',
  SETUP_TEXT: 'Sign your lease and set up rent payments in seconds in the app',
  MOVE_IN_TITLE: 'Move in',
  MOVE_IN_TEXT:
    'Book a mover, and get your electricity, internet and gas connected all in the one place',
  PROTECT_TITLE: 'Protect & insure',
  PROTECT_TEXT: 'Quickly find the right contents insurance to protect your stuff',
  MAINTAIN_TITLE: 'Maintain',
  MAINTAIN_TEXT: 'Easily organise maintenance requests directly in the app',
  MOVE_TITLE: 'Move out',
  MOVE_TEXT: 'Transfer or disconnect all your utilities with a single tap',
};
