import React, { FC, useEffect, useState } from 'react';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { Typography, TextField, Card, Grid } from '@mui/material';
import { History } from 'history';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { get } from 'lodash';
import backgroundImage from '../../../assets/placeholder2.jpg';
import { useStyles } from './QuestionnaireStyles';
import { routes } from '../../../Routes';
import { ApplicationState } from '../../../store/RootReducers';
import { ReferenceToolActions } from '../../../store/actions/ReferenceToolActions';
import { TypeHelper } from '../../../helper/TypeHelper';
import {
  QuestionnaireData,
  Questions,
  FileData,
  ReferenceToolDataState,
} from '../../../store/state/ReferenceToolState';
import {
  validation,
  myQuestion,
  myQuestionInitialValues,
  UPLOAD_FILE_LABEL,
  UPLOADED_QUESTION_FILE_LABEL,
  isQuestionValid,
} from './QuestionnaireConstants';
import FileUpload from '../../fileUpload/FileUpload';
import { FileUploadActions } from '../../../store/actions/FileUploadAction';
import ToggleButton from '../../toggleButton/ToggleButton';
import Text from '../../text/Text';
import Button from '../../button/Button';

import Input from '../../input/Input';
import Box from '../../box/Box';

interface QuestionnaireScreenProps extends RouteComponentProps {
  history: History;
  referencesData: ReferenceToolDataState | null;
  token: string;
  postSaveReferencesData: (
    questionnaireData: QuestionnaireData,
    onSuccess: () => void,
    history: any,
    sendAdditionalComment: boolean,
  ) => void;
  uploadFile: (token: string, data: any) => void;
}

const QuestionnaireScreen: FC<QuestionnaireScreenProps> = ({
  history,
  referencesData,
  postSaveReferencesData,
  uploadFile,
  token,
}) => {
  const classes = useStyles();
  const [ownQuestions, setOwnQuestions] = useState<boolean>(false);

  const initialValues: QuestionnaireData =
    referencesData && referencesData.questions
      ? {
          questionnaireData: referencesData.questions.map((element: Questions) => ({
            ...element,
            answer: element.type === 'switch' ? false : null,
          })),
        }
      : {
          questionnaireData: [],
        };

  const renderOwnQuestions = (
    element: Questions,
    index: number,
    setFieldValue: (key: string, value: boolean | string | null) => void,
    values: QuestionnaireData,
    errors: FormikErrors<QuestionnaireData>,
    touched: FormikTouched<QuestionnaireData>,
    setFieldTouched: (key: string) => void,
  ) => {
    const isValid = isQuestionValid(values.questionnaireData, index);

    if (!isValid) return null;

    switch (element.type) {
      case 'switch':
        return (
          <div key={index} className={classes.switchQuestionStyle}>
            <Text parentStyles={classes.questionLabelStyle}>{element.question}</Text>
            <div className={classes.buttonGroupStyle}>
              <ToggleButton
                buttonState={!!values.questionnaireData[index].answer}
                toggleFunction={() =>
                  setFieldValue(
                    `questionnaireData[${index}].answer`,
                    !values.questionnaireData[index].answer,
                  )
                }
              />
            </div>
            {get(errors, `questionnaireData[${index}].answer`) && (
              <div className={classes.requiredErrorStyle}>
                {errors.questionnaireData![index]!.answer}
              </div>
            )}
          </div>
        );
      case 'textbox':
        return (
          <Input
            placeholder="Answer"
            title={element.question}
            value={get(values, `questionnaireData[${index}].answer`)}
            setValue={(value: string) => {
              setFieldValue(`questionnaireData[${index}].answer`, value);
            }}
            onBlur={() => setFieldTouched(`questionnaireData[${index}].answer`)}
            error={get(errors, `questionnaireData[${index}].answer`)}
            touched={get(touched, `questionnaireData[${index}].answer`)}
          />
        );
      case 'file':
        return (
          <div className={classes.textQuestionStyle}>
            <Text parentStyles={classes.textQuestionStyle}>{element.question}</Text>
            <FileUpload
              linkStateSetter={(link: string, filename: string) => {
                setFieldValue(
                  `questionnaireData[${index}].answer`,
                  JSON.stringify({ filename, link } as FileData),
                );
              }}
              linkStateRemover={() => {
                setFieldValue(`questionnaireData[${index}].answer`, null);
              }}
              fileNameFromParent={
                (
                  JSON.parse(
                    (values.questionnaireData[index].answer as string) ||
                      '{"filename": "", "key": ""}',
                  ) as FileData
                ).filename
              }
              index={0}
              isMobile={false}
              uploadFile={(data: any) => uploadFile(token, data)}
            />
            {get(errors, `questionnaireData[${index}].answer`) && (
              <div className={classes.requiredErrorStyle}>
                {errors.questionnaireData![index]!.answer}
              </div>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {TypeHelper.isNullOrUndefined(referencesData) ? (
        <Typography className={classes.dataLoadingStyle} />
      ) : (
        <>
          <img
            className={classes.backgroundImageStyle}
            src={backgroundImage}
            alt="property image"
          />
          {!ownQuestions ? (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              key="questions_set"
              onSubmit={(data) => {
                postSaveReferencesData(
                  {
                    ...data,
                    questionnaireData: [...data.questionnaireData, myQuestion],
                  },
                  () => {
                    history.push(routes.thankyouScreen.view);
                  },
                  history,
                  true,
                );
              }}
              validate={validation}
              render={({
                values,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                errors,
                touched,
              }) => (
                <Box lightBorder parentStyles={classes.formikRootStyle}>
                  <Text textVariant="title">{`${referencesData!.type} reference`}</Text>
                  <Text textVariant="info" parentStyles={classes.infoTitle}>
                    {`You are completing a reference for ${referencesData!.applicantName} ${
                      referencesData!.type === 'Rental' &&
                      referencesData!.extendedData &&
                      referencesData!.extendedData.address
                        ? `at ${referencesData!.extendedData.address}`
                        : ''
                    }`}
                  </Text>
                  {values.questionnaireData.map((element: Questions, index: number) =>
                    renderOwnQuestions(
                      element,
                      index,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                      setFieldTouched,
                    ),
                  )}
                  <Typography className={classes.additionalNoteStyle}>
                    <Text parentStyles={classes.additionalNoteHeaderStyle}>
                      Please enter any additional comments you would like to offer
                    </Text>
                    <TextField
                      multiline
                      placeholder="Please give comments"
                      name="Additional comments"
                      onChange={handleChange}
                      className={classes.additionalNoteBodyStyle}
                    />
                  </Typography>
                  {/* agent's question and answer */}
                  <div key="agent question" className={classes.switchQuestionStyle}>
                    <div className={classes.questionLabelStyle}>{myQuestion.question}</div>
                    <div className={classes.buttonGroupStyle}>
                      <ToggleButton
                        buttonState={false}
                        toggleFunction={() => setOwnQuestions(true)}
                      />
                    </div>
                  </div>
                  <div className={classes.buttonWrapperStyle}>
                    <Button parentStyles={classes.nextButtonStyle} onPress={() => handleSubmit()}>
                      Submit
                    </Button>
                  </div>
                </Box>
              )}
            />
          ) : (
            <Formik
              initialValues={myQuestionInitialValues}
              key="my_own_questions"
              onSubmit={(data) => {
                postSaveReferencesData(
                  {
                    ...data,
                    questionnaireData: data.questionnaireData.map((dataObj) => ({
                      ...dataObj,
                      question:
                        dataObj.question === UPLOAD_FILE_LABEL
                          ? UPLOADED_QUESTION_FILE_LABEL
                          : dataObj.question,
                    })),
                  },
                  () => {
                    history.push(routes.thankyouScreen.view);
                  },
                  history,
                  false,
                );
              }}
              validate={validation}
              render={({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                errors,
                touched,
                setFieldTouched,
              }) => (
                <Box lightBorder parentStyles={classes.formikRootStyle}>
                  <Text textVariant="title">{`${referencesData!.type} reference`}</Text>
                  <Text textVariant="info" parentStyles={classes.infoTitle}>
                    {`You are completing a reference for ${referencesData!.applicantName} ${
                      referencesData!.type === 'Rental' &&
                      referencesData!.extendedData &&
                      referencesData!.extendedData.address
                        ? `at ${referencesData!.extendedData.address}`
                        : ''
                    }`}
                  </Text>
                  {values.questionnaireData.map((element: Questions, index: number) =>
                    renderOwnQuestions(
                      element,
                      index,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                      setFieldTouched,
                    ),
                  )}
                  <div key="agent question" className={classes.switchQuestionStyle}>
                    <div className={classes.questionLabelStyle}>{myQuestion.question}</div>
                    <div className={classes.buttonGroupStyle}>
                      <ToggleButton buttonState toggleFunction={() => setOwnQuestions(false)} />
                    </div>
                  </div>
                  <div className={classes.buttonWrapperStyle}>
                    <Button parentStyles={classes.nextButtonStyle} onPress={() => handleSubmit()}>
                      Submit
                    </Button>
                  </div>
                </Box>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  referencesData: state.referenceToolData.referencesData,
  token: state.referenceToolData.tokenId || '',
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  postSaveReferencesData: (
    questionnaireData: QuestionnaireData,
    onSuccess: () => void,
    history: any,
    sendAdditionalComment: boolean,
  ) => {
    dispatch(
      ReferenceToolActions.postSaveReferencesData(
        questionnaireData,
        onSuccess,
        history,
        sendAdditionalComment,
      ),
    );
  },
  uploadFile: (token: string, data: File) =>
    dispatch(FileUploadActions.postReferenceFileStart(token, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionnaireScreen));
