import React, { FC, useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import SigningBox from '../../container/remoteSigning/components/RemoteSigning/components/SigningBox';
import { SignatureType } from '../../container/remoteSigning/components/LandlordSigningScreen/LandlordSigningScreenConstants';
import { RemoteSigningGetResponse } from '../../services/remoteSigning/RemoteSigning';
import { GetUserResponse } from '../../services/remoteSigning/user/User';
import { useStyles } from './DisclosureSignatureBoxStyles';
import { Content } from '../../helper/Content/Content.data';
import { ContentType, ContentTypeHelper } from '../../helper/Content/ContentType.data';
import { AgencyDetails } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

interface DisclosureSignatureBoxProps {
  user: GetUserResponse;
  agency: AgencyDetails;
  comments?: string;
  onSubmit: (signatures: Content[]) => void;
}

const DisclosureSignatureBox: FC<DisclosureSignatureBoxProps> = ({
  user,
  agency,
  onSubmit,
  comments,
}) => {
  const [initialsBase64, setInitialsBase64] = useState<string | null | undefined>(null);
  const [signatureBase64, setSignatureBase64] = useState<string | null | undefined>(null);
  const [notSigned, setNotSigned] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (signatureBase64 && initialsBase64) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [signatureBase64, initialsBase64]);

  const remoteSigningData: RemoteSigningGetResponse = {
    user,
    agency,
    branch: agency,
  };

  const convertSignaturePostObject = (base64: string): Content => {
    const data = ContentTypeHelper.base64ToBlob(base64);
    return {
      contentRef: {
        contentType: ContentType.png,
        name: 'signature',
      },
      data,
      size: data.size,
      dataBase64: base64,
    };
  };

  const getSignatureBox = (type: SignatureType, title: string) => {
    return (
      <SigningBox
        type={type}
        hidePersonCard
        title={title}
        witnessName=""
        setWitnessName={() => null}
        witnessNameRequired={false}
        setWitnessNameRequired={() => null}
        remoteSigningData={remoteSigningData}
        initialsBase64={initialsBase64}
        setInitialsBase64={(value) => setInitialsBase64(value)}
        signatureBase64={signatureBase64}
        setSignatureBase64={(value) => setSignatureBase64(value)}
        witnessSignatureBase64={null}
        setWitnessSignatureBase64={() => null}
        notSigned={notSigned}
        setNotSigned={setNotSigned}
        propertyState={null}
      />
    );
  };

  return (
    <Paper className={classes.RDSContainer}>
      <div className={classes.signingContainers}>
        <div className={classes.signBelow}>
          {getSignatureBox(SignatureType.LandlordSignature, 'Sign Here')}
        </div>
        <div className={classes.initialBelow}>
          {getSignatureBox(SignatureType.LandlordInitials, 'Initials Here')}
        </div>
      </div>

      {comments && (
        <Typography variant="body2">
          <span dangerouslySetInnerHTML={{ __html: comments }} />
        </Typography>
      )}
      <br />

      <div className={classes.buttonWrapperStyle}>
        <Button
          className={`${classes.nextButtonStyle} ${!canSubmit && classes.nextButtonDisabledStyle}`}
          onClick={() => {
            const signatures = [
              convertSignaturePostObject(signatureBase64!!),
              convertSignaturePostObject(initialsBase64!!),
            ];
            onSubmit(signatures);
          }}
          disabled={!canSubmit}
        >
          Submit
        </Button>
      </div>
    </Paper>
  );
};

export default DisclosureSignatureBox;
