import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './FindAPropertyStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import Box from '../../../../component/box/Box';
import home from '../../../../assets/navigation/home.png';
import { routes } from '../../../../Routes';
import { LABELS } from './FindAPropertyConstants';

interface FindAPropertyProps extends RouteComponentProps {}

const FindAProperty: FC<FindAPropertyProps> = ({ history }) => {
  const classes = useStyles();

  return (
    <Box lightBorder innerStyles={classes.container}>
      <div className={classes.profileHeader}>
        <img src={home} className={classes.icon} alt="home icon" />
        <Text parentStyles={classes.title} textVariant="contentTitle">
          {LABELS.TITLE}
        </Text>
      </div>
      <Text textVariant="info">{LABELS.SUBTITLE}</Text>
      <div className={classes.buttonContainer}>
        <Button outline onPress={() => history.push(routes.properties.generic)}>
          {LABELS.BUTTON}
        </Button>
      </div>
    </Box>
  );
};

export default withRouter(FindAProperty);
