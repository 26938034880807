import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { actionGreen } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    // },
    backgroundColor: theme.colors.light,
    height: '100%',
    width: '100%,',
  },
  contentContainer: {
    margin: 'auto',
    paddingLeft: '80px',
    paddingRight: '80px',
    maxWidth: '1000px',
    marginBottom: '85px',

    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  viewPropertyHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  },
  backArrowHeader: {
    ...actionGreen,
    marginRight: '15px',
    marginLeft: '5px',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  viewPropertyHeaderText: {
    fontSize: '24px',
    color: theme.colors.secondary,
  },
  propertyImagesContainer: {
    overflow: 'hidden',
    borderRadius: 8,
  },
  propertyImage: {
    width: '100%',
    height: '400px',
    objectPosition: 'center',
    objectFit: 'cover',
    borderRadius: 8,
  },
  noPropertyImage: {
    width: 'calc(100% - 4px)',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `${theme.colors.borderColorLight} 2px solid`,
    borderRadius: 8,
    backgroundColor: theme.colors.disabled,
  },
  homeLogo: {
    width: '80px',
    height: '80px',
  },
  leftArrow: {},
  rightArrow: {},
  carouselButtonContainer: {},
  carouselButton: {},
  viewPropertyCard: {
    marginTop: '15px',
    border: `${theme.colors.borderColorLight} 2px solid`,
    borderRadius: 8,
    padding: '25px',
    background: theme.colors.light,
  },
  viewPropertyCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '25px',
  },
  cardHeaderIcon: {
    width: '30px',
    height: '30px',
  },
  cardHeaderText: {
    marginRight: 'auto',
    marginLeft: '15px',
    fontSize: '26px',
  },
  cardHeaderRent: {
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: 2,
    color: theme.colors.secondary,
  },
  propertyDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
  },
  propertyDetailsIcon: {
    height: '20px',
    marginRight: '12px',
  },
  propertyDetailsIconText: {
    marginRight: '30px',
  },
  propertyDetailsSize: {
    color: theme.colors.label,
  },
  propertyDescription: {
    fontWeight: 100,
    opacity: 0.8,
    fontSize: '17px',
    marginBottom: '25px',
  },
  contactAgentButton: {
    padding: '6px 18px',
    borderRadius: 6,
    minWidth: '75px',
    width: 'max-content',
    border: `${theme.colors.borderColor} 2px solid`,
    background: theme.colors.light,
    fontSize: '15px',
    display: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.colors.black87,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'flex',
    },
  },
  sectionHeader: {
    marginTop: '50px',
    width: '100%',
    fontSize: '20px',
    paddingBottom: '20px',
    borderBottom: `${theme.colors.borderColor} 2px solid`,
    marginBottom: '20px',
  },
  agentContainer: {
    width: '100%',
    flex: 1,
    flexShrink: 0,
  },
  agentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  agentLogo: {
    width: '50px',
    height: '50px',
    marginRight: '25px',
  },
  agentNameContainer: {
    marginRight: 'auto',
  },
  agentName: {
    fontSize: '18px',
    marginBottom: 2,
    color: theme.colors.black87,
  },
  agentContext: {
    color: theme.colors.label,
    fontSize: '15px',
  },
  agentIcon: {
    width: '35px',
    height: '35px',
    marginLeft: '10px',
  },
  agentContactContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginLeft: '30px',
    borderBottom: `${theme.colors.borderColor} 1px solid`,
    paddingBottom: '15px',
    cursor: 'pointer',
  },
  agentContactText: {
    fontSize: '16px',
    color: theme.colors.black87,
  },
  agentArrow: {
    width: '30px',
    height: '30px',
  },
  viewPropertyActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '35px',
  },
  actionButtonContainer: {
    padding: '10px 20px',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    backgroundColor: theme.colors.light,
    minWidth: '150px',
    fontSize: '16px',
    cursor: 'pointer',
    '&:last-child': {
      border: `${theme.colors.secondary} 2px solid`,
      backgroundColor: theme.colors.secondary,
      color: theme.colors.light,
    },
  },
  actionButtonIcon: {
    marginRight: '10px',
    width: '30px',
    height: '30px',
  },
});
