import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './WhileYouWaitStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import bannerPurple from '../../../../assets/banner-purple.png';
import welcomeImage from '../../../../assets/waiting-image.jpg';
import { LABELS } from './WhileYouWaitConstants';
import OptionalIcon from '../../../../assets/Circle.svg';
import Profile from '../../../../assets/navigation/services.png';
import ArrowRight from '../../../../assets/navigation/arrow-right.png';
import { routes } from '../../../../Routes';

interface WhileYouWaitProps extends RouteComponentProps {}

const WhileYouWait: FC<WhileYouWaitProps> = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.welcomeBanner}>
        <div className={classes.welcomeLeftContent}>
          <Text textVariant="title" parentStyles={classes.welcomeTitle}>
            {LABELS.TITLE}
          </Text>
          <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
            {LABELS.SUBTITLE}
          </Text>
        </div>
        <div className={classes.welcomeImageBackground}>
          <img src={bannerPurple} className={classes.welcomeLeftImage} alt="overlay" />
          <img src={welcomeImage} className={classes.welcomeRightImage} alt="welcome" />
        </div>
      </div>
      <div className={classes.quickStartContainer}>
        <div className={classes.quickStartRow}>
          <Text textVariant="contentTitle" parentStyles={classes.quickStartTitle}>
            {LABELS.QUICK_START}
          </Text>
        </div>
        <div
          className={classes.quickStartItem}
          style={{ borderTop: 'none' }}
          onClick={() => history.push(routes.applications.view)}
        >
          <img src={OptionalIcon} className={classes.quickStartTick} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.VIEW_OR_EDIT}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          className={classes.quickStartItem}
          onClick={() => history.push(routes.properties.generic)}
        >
          <img src={OptionalIcon} className={classes.quickStartTick} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.CHECK_OUT}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          className={classes.quickStartItem}
          onClick={() =>
            window.open(
              'https://www.sortedservices.com/blog-spot/application-stand-out-tips',
              '_blank',
            )
          }
        >
          <img src={OptionalIcon} className={classes.quickStartTick} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.CHECK_BLOGS}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(WhileYouWait);
