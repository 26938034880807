/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { Logging } from '../../helper/Logging';
import {
  RemoteSigningAction,
  RemoteSigningActionTypes,
  RemoteSigningActions,
} from '../actions/RemoteSigningActions';
import {
  RemoteSigningGetResponse,
  GetRemoteSigningPayload,
  PostRemoteSigningPayload,
  RemoteSigningPostResponse,
  MeritonServiceCallbackRequest,
  GuestAuthDetailsRequest,
  GuestAuthDetailsResponse,
} from '../../services/remoteSigning/RemoteSigning';
import RemoteSigningService from '../../services/remoteSigning/RemoteSigningService';

function* getRemoteSigningWorker(action: RemoteSigningAction) {
  try {
    const { onSuccess, token, type, source } = action.data as GetRemoteSigningPayload;
    const response: RemoteSigningGetResponse = yield RemoteSigningService.get(token, type, source);
    yield put(RemoteSigningActions.getRemoteSigningSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    Logging.error('remoteSigningWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(RemoteSigningActions.getRemoteSigningError(error as Error));
  }
}

function* postRemoteSigningWorker(action: RemoteSigningAction) {
  try {
    const { onSuccess, request, type } = action.data as PostRemoteSigningPayload;
    const response: RemoteSigningPostResponse = yield RemoteSigningService.post(request, type);
    yield put(RemoteSigningActions.postSignSuccess(response));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    Logging.error('remoteSigningWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(RemoteSigningActions.postSignError(error as Error));
  }
}

function* postMeritonCallbackWorker(action: RemoteSigningAction) {
  const { onSuccess, ...data } = action.data as MeritonServiceCallbackRequest;
  try {
    yield RemoteSigningService.postMeritonServiceCallback(data as MeritonServiceCallbackRequest);
    yield put(RemoteSigningActions.postMeritonCallbackSuccess());
    if (onSuccess) onSuccess();
  } catch (error) {
    Logging.error('marketplaceWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(RemoteSigningActions.postMeritonCallbackError(error as Error));
  }
}

function* getGuestToken(action: RemoteSigningAction) {
  try {
    const response: GuestAuthDetailsResponse = yield RemoteSigningService.getGuestToken(
      action.data as GuestAuthDetailsRequest,
    );
    const result = {
      accessToken: (response as any).access_token,
      refreshToken: (response as any).refresh_token,
    };
    yield put(RemoteSigningActions.getGuestTokenSuccess(result));
  } catch (error) {
    Logging.error('guestToken', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(RemoteSigningActions.getGuestTokenError(error as Error));
  }
}

function* remoteSigningWatcher() {
  yield takeEvery(
    RemoteSigningActionTypes.GET_REMOTE_SIGNING_REQUEST,
    (action: RemoteSigningAction) =>
      getRemoteSigningWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(RemoteSigningActionTypes.POST_SIGN_REQUEST, (action: RemoteSigningAction) =>
    postRemoteSigningWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(
    RemoteSigningActionTypes.POST_MERITON_CALLBACK_REQUEST,
    (action: RemoteSigningAction) =>
      postMeritonCallbackWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    RemoteSigningActionTypes.GET_GUEST_AUTH_DETAILS_REQUEST,
    (action: RemoteSigningAction) =>
      getGuestToken({
        type: action.type,
        data: action.data,
      }),
  );
}

export default remoteSigningWatcher;
