import { StoreAction } from '../StoreHelper';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
  ProcurementCallbackRequest,
} from '../../services/marketplace/Marketplace';

export enum MarketplaceActionTypes {
  GET_AVAILABLE_SERVICES_REQUEST = 'GET_AVAILABLE_SERVICES_REQUEST',
  GET_AVAILABLE_SERVICES_SUCCESS = 'GET_AVAILABLE_SERVICES_SUCCESS',
  GET_AVAILABLE_SERVICES_ERROR = 'GET_AVAILABLE_SERVICES_ERROR',
  POST_PROCUREMENT_CALLBACK_REQUEST = 'POST_PROCUREMENT_CALLBACK_REQUEST',
  POST_PROCUREMENT_CALLBACK_SUCCESS = 'POST_PROCUREMENT_CALLBACK_SUCCESS',
  POST_PROCUREMENT_CALLBACK_ERROR = 'POST_PROCUREMENT_CALLBACK_ERROR',
}

export type MarketplaceActionPayload =
  | ProcurementCallbackRequest
  | AvailableServicesRequest
  | AvailableServicesResponse[]
  | undefined
  | Error
  | null;

export type MarketplaceAction = StoreAction<MarketplaceActionTypes, MarketplaceActionPayload>;

export class MarketplaceActions {
  // get Remote singing data(lease,witness,tenant)
  public static getAvailableServicesStart(data?: AvailableServicesRequest): MarketplaceAction {
    return { type: MarketplaceActionTypes.GET_AVAILABLE_SERVICES_REQUEST, data };
  }

  public static getAvailableServicesSuccess(data: AvailableServicesResponse[]): MarketplaceAction {
    return {
      type: MarketplaceActionTypes.GET_AVAILABLE_SERVICES_SUCCESS,
      data,
    };
  }

  public static getAvailableServicesError(data: Error): MarketplaceAction {
    return { type: MarketplaceActionTypes.GET_AVAILABLE_SERVICES_ERROR, data };
  }

  // post procurement email
  public static postProcurementCallbackStart(data?: ProcurementCallbackRequest): MarketplaceAction {
    return { type: MarketplaceActionTypes.POST_PROCUREMENT_CALLBACK_REQUEST, data };
  }

  public static postProcurementCallbackSuccess(): MarketplaceAction {
    return {
      type: MarketplaceActionTypes.POST_PROCUREMENT_CALLBACK_SUCCESS,
      data: null,
    };
  }

  public static postProcurementCallbackError(data: Error): MarketplaceAction {
    return { type: MarketplaceActionTypes.POST_PROCUREMENT_CALLBACK_ERROR, data };
  }
}
