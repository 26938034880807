import {
  MaintenanceRequestAction,
  MaintenanceRequestActionsTypes,
} from '../actions/MaintenanceRequestActions';
import { CreateMaintenanceRequestState } from '../state/CreateMaintenanceRequestState';

export const initMaintenanceRequestState: CreateMaintenanceRequestState = {
  success: false,
};

export const createMaintenanceRequestReducer = (
  state: CreateMaintenanceRequestState = initMaintenanceRequestState,
  action: MaintenanceRequestAction,
): CreateMaintenanceRequestState => {
  switch (action.type) {
    case MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_REQUEST: {
      return {
        ...state,
        success: false,
      };
    }

    case MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }

    case MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_ERROR: {
      return {
        ...state,
        success: false,
      };
    }

    case MaintenanceRequestActionsTypes.UPDATE_MAINTENANCE_REQUEST_RESET: {
      return {
        ...initMaintenanceRequestState,
      };
    }

    default:
      return state;
  }
};
