import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';

export const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
  },
  heading: {},
  subHeading: {
    marginTop: '1em',
  },
  selectWrapper: {
    marginTop: '2em',
    width: '100%',
  },
  select: {
    height: 'auto !important',
  },
  fullWidth: {
    width: '100%',
  },
  menuItem: {
    height: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
  },
  searchInput: {
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.inputBackground,
    marginBottom: theme.units.spacing * 4,
    boxSizing: 'border-box',
  },
  selectedProperty: {
    background: '#eee',
    paddingTop: '1em',
    paddingBottom: '1em',
    border: '1px solid #B2BEB5',
    borderRadius: '4px',
    listStyleType: 'none',
  },
  selectedPropertyWrapper: {
    marginTop: '2em',
    listStyleType: `none !important`,
    padding: 0,
  },
  propertyImage: {
    height: 50,
    width: 50,
    flexShrink: 0,
    borderRadius: 5000,
    objectFit: 'cover',
    objectPosition: 'center',
  },
});
