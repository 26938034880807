import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PartneredAgenciesStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './PartneredAgenciesConstants';
import barryPlant from '../../../../assets/agenciesBanner/barryPlant.png';
import thePropertyCollective from '../../../../assets/agenciesBanner/thePropertyCollective.png';
import luton from '../../../../assets/agenciesBanner/luton.png';
import meriton from '../../../../assets/agenciesBanner/meriton.png';
import harcourts from '../../../../assets/agenciesBanner/harcourts.png';
import independent from '../../../../assets/agenciesBanner/independent.png';
import professionals from '../../../../assets/agenciesBanner/professionals.png';
import nax from '../../../../assets/agenciesBanner/nax.png';
import { routes } from '../../../../Routes';

interface PartneredAgenciesProps extends RouteComponentProps {}

const PartneredAgencies: FC<PartneredAgenciesProps> = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.textContent}>
          <Text parentStyles={classes.title} textVariant="title">
            {LABELS.TITLE}
          </Text>
          <Text parentStyles={classes.subtitle} size="l" textVariant="info">
            {LABELS.SUBTITLE}
          </Text>
          <Button
            onPress={() => history.push(routes.properties.generic)}
            parentStyles={classes.button}
          >
            {LABELS.BUTTON}
          </Button>
        </div>
        <div className={classes.agencies}>
          <img src={harcourts} className={classes.agency} alt="agency" />
          <img src={independent} className={classes.agency} alt="agency" />
          <img src={nax} className={classes.agency} alt="agency" />
          <img
            src={thePropertyCollective}
            className={classes.agency}
            style={{ height: 60 }}
            alt="agency"
          />
          <img src={meriton} className={classes.agency} alt="agency" />
          <img src={barryPlant} className={classes.agency} alt="agency" />
          <img src={luton} className={classes.agency} alt="agency" />
          <img src={professionals} className={classes.agency} alt="agency" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(PartneredAgencies);
