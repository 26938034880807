/* eslint-disable import/prefer-default-export */
import { AppIconType } from '../../component/AppIcon/AppIcon';
import sortedIcon from '../../assets/sorted-icon.png';
import personIcon from '../../assets/person-white.png';

export class Messages {
  public static pages = {
    remoteSigningPage: {
      titles: {
        signAgain: 'Sign again',
        witnessHeader: 'Enter the full name of your witness in the box below',
        tenantHeader: 'I hereby accept the terms presented to me',
        reviewDocs: 'Accept and review documents',
        makeUpdateHeader: 'Contact Agent ?',
        gotItButton: 'Got it',
        signHere: 'Sign here:',
        initialsHere: 'Initals here:',
        witnessSignature: 'Witness Signature:',
      },
    },
    uploadingScreen: {
      titles: {
        uploadTitle: 'Uploading your signature...',
        icon: sortedIcon,
        iconType: 'png',
      },
      helperText: {
        uploadText: 'Please wait while we verify and upload your signed documents',
      },
    },
    loadingScreen: {
      titles: {
        loadingTitle: 'Loading your details...',
        icon: sortedIcon,
        iconType: 'png',
      },
    },
    errorScreen: {
      titles: {
        errorTitle: 'Something went wrong...',
        icon: AppIconType.CrossInCircle,
        iconType: 'svg',
      },
      helperText: {
        errorText:
          "We're sorry, but something went wrong, it could be your internet connection. Please check connectivity and try again",
      },
    },
    allDone: {
      titles: {
        doneTitle: 'All done!',
        icon: AppIconType.TickInCirle,
        iconType: 'svg',
      },
      helperText: {
        thanksText: (agencyName?: string) => `Thank you for signing with ${agencyName}`,
        signText:
          "You can safely close your browser. Once everyone has signed, you'll receive a copy of your documents.",
        messageText: (agencyCode?: string) =>
          `Don't forget to check your text messages for a link to download ${
            agencyCode === 'NAX'
              ? 'Nelson Alexander'
              : agencyCode === 'AU_AGCY'
              ? 'The Agency app'
              : 'Sorted app'
          }!`,
      },
    },
    appointmentScreen: {
      titles: {
        appointmentTitle: 'Appointment expired',
        icon: AppIconType.Mobile,
        iconType: 'svg',
      },
      helperText: {
        contactText:
          "To complete the signing process you'll need to contact your property manager.",
      },
    },
    alreadySignedScreen: {
      titles: {
        signedTitle: "Looks like you've already signed",
        icon: personIcon,
        iconType: 'png',
        buttonText: 'Close',
      },
      helperText: {
        signText:
          "You're all good. Once everyone else has signed you'll receive a copy of your documents",
      },
    },
    successScreen: {
      titles: {
        successTitle: 'Signing successful!',
        icon: AppIconType.TickInCirle,
        iconType: 'icon',
      },
      helperText: {
        successText:
          "You can safely close your browser. Once everyone has signed, you'll receive a copy of your documents.",
      },
    },
  };
}

export enum RDSType {
  LEASE,
  LANDLORD,
}

export enum ScreenName {
  Welcome,
  OwnershipInfo,
  OwnershipDocs,
  Signing,
  LandlordSigning,
  Uploading,
  Success,
  Signatures,
  Promotion,
  AlreadySigned,
  Error,
  Expired,
  EndOfRDS,
}

export interface RemoteSigningParams {
  type?: string;
  source?: string;
}
