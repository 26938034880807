import { StoreAction } from '../StoreHelper';
import { ReferenceToolDataState, QuestionnaireData } from '../state/ReferenceToolState';

export enum ReferenceToolActionTypes {
  SET_REFERENCES_DATA = 'SET_REFERENCES_DATA',
  GET_REFERENCES_DATA = 'GET_REFERENCES_DATA',
  GET_REFERENCES_DATA_FAILURE = 'GET_REFERENCES_DATA_FAILURE',
  POST_SAVE_REFERENCES_DATA = 'POST_SAVE_REFERENCES_DATA',
  POST_SAVE_REFERENCES_DATA_FAILURE = 'POST_SAVE_REFERENCES_DATA_FAILURE',
  SAVE_TOKEN = 'SAVE_TOKEN',
  RESEND_EMAIL = 'RESEND_EMAIL',
  RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_ERROR = 'RESEND_EMAIL_ERROR',
}

export type ReferenceToolActionPayload = any | null;

export type ReferenceToolAction = StoreAction<ReferenceToolActionTypes, ReferenceToolActionPayload>;

export class ReferenceToolActions {
  public static setReferencesData(data: ReferenceToolDataState) {
    return { type: ReferenceToolActionTypes.SET_REFERENCES_DATA, data };
  }
  public static getReferencesData(token: string, history: any) {
    const data = {
      token,
      history,
    };
    return { type: ReferenceToolActionTypes.GET_REFERENCES_DATA, data };
  }
  public static getReferencesDataFailure(error: any) {
    return {
      type: ReferenceToolActionTypes.GET_REFERENCES_DATA_FAILURE,
      data: error,
    };
  }
  public static postSaveReferencesData(
    questionnaireData: QuestionnaireData,
    onSuccess: () => void,
    history: any,
    sendAdditionalComment: boolean,
  ) {
    const data = {
      questionnaireData,
      onSuccess,
      history,
      sendAdditionalComment,
    };
    return { type: ReferenceToolActionTypes.POST_SAVE_REFERENCES_DATA, data };
  }
  public static postSaveReferencesDataFailure(error: any) {
    return {
      type: ReferenceToolActionTypes.POST_SAVE_REFERENCES_DATA_FAILURE,
      data: error,
    };
  }
  public static saveToken(tokenId: string) {
    return { type: ReferenceToolActionTypes.SAVE_TOKEN, data: tokenId };
  }
  public static resendEmail(data: number) {
    return { type: ReferenceToolActionTypes.RESEND_EMAIL_REQUEST, data };
  }
  public static resendEmailError(data: Error | null) {
    return { type: ReferenceToolActionTypes.RESEND_EMAIL_ERROR, data };
  }
}
