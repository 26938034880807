import { SuccessToastAction } from '../actions/ SuccessToastAction';
import { SuccessToastState } from '../state/SuccessToastState';

export const initialState: SuccessToastState = {
  snackbarFlag: false,
  message: null,
  errorMessage: null,
};

export const successToastReducers = (state = initialState, action: SuccessToastAction) => {
  switch (action.type) {
    // Shows the toast
    case 'SHOW_SUCCESS_TOAST':
      return {
        ...state,
        snackbarFlag: true,
        message: action.data,
      };

    // Hides the toast
    case 'HIDE_SUCCESS_TOAST':
      return {
        ...state,
        snackbarFlag: false,
        message: null,
        errorMessage: null,
      };

    // shows the error toast
    case 'SHOW_ERROR_TOAST':
      return {
        ...state,
        snackbarFlag: true,
        errorMessage: action.data,
      };
    default:
      return state;
  }
};
