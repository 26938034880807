import React, { FC, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './ProfileCompletionStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../store/RootReducers';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Profile from '../../../../assets/navigation/profile.png';
import { LABELS } from './ProfileCompletionConstants';
import PercentageWheel from '../../../../component/percentageWheel/PercentageWheel';
import CompletedIcon from '../../../../assets/check.svg';
import ArrowRight from '../../../../assets/navigation/arrow-right.png';
import Accordian from '../../../../assets/navigation/accordian.png';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { ApplicantProfile } from '../../../../store/state/MyProfileFormState';
import {
  isPersonalDetailsFilled,
  isIntroFilled,
  isCurrentIncomeDetailsFilled,
  isEmploymentDetailsFilled,
  isPetsDetailsFilled,
  isVehiclesDetailsFilled,
  isAddressDetailsFilled,
  isReferenceDetailsFilled,
  isIdentificationDetailsFilled,
  isDeclarationDetailsFilled,
  getProfilePercentage,
} from '../../../../helper/ProfileVerifyHelper';
import { SectionStatus } from '../../../profile/components/ProfileItem/ProfileItemConstants';
import { routes } from '../../../../Routes';
import { isVerified } from '../../../../helper/NTDHelper';
import { Verified } from '../../../../models/equifax/equifax';
import verified from '../../../../assets/verified.png';

interface ProfileCompletionProps extends RouteComponentProps {
  profileFormDetails: ApplicantProfile;
}

const ProfileCompletion: FC<ProfileCompletionProps> = ({ profileFormDetails, history }) => {
  const classes = useStyles();
  const profileSectionRef = useRef<HTMLDivElement>(null);
  const [profileSectionHeight, setProfileSectionHeight] = useState<number>(0);
  const verifiedStatus = isVerified(profileFormDetails.identificationDetails);

  return (
    <Box lightBorder parentStyles={classes.profileCompletionContainer}>
      <div className={classes.profileRow}>
        <img src={Profile} className={classes.icon} alt="profile" />
        <div className={classes.textContent}>
          <Text parentStyles={classes.title} textVariant="title" size="xl">
            {LABELS.TITLE}
          </Text>
          <Text textVariant="info">{LABELS.SUBTITLE}</Text>
        </div>
        <PercentageWheel completed={getProfilePercentage(profileFormDetails)} />
      </div>
      <div
        className={classes.actionContainer}
        onClick={() =>
          setProfileSectionHeight(
            profileSectionHeight > 0 ? 0 : profileSectionRef.current!.scrollHeight,
          )
        }
      >
        {verifiedStatus !== undefined &&
          [Verified.VERIFIED, Verified.EXPIRED].includes(verifiedStatus) && (
            <div className={classes.verifiedBadge}>
              <img
                src={verified}
                className={classNameGenerator([
                  classes.verifiedIcon,
                  verifiedStatus === Verified.EXPIRED && classes.unverifiedBadge,
                ])}
                alt="badge"
              />
              <div className={classes.verifiedText}>
                {verifiedStatus === Verified.EXPIRED ? LABELS.EXPIRED : LABELS.VERIFIED}
              </div>
            </div>
          )}
        <Text textVariant="link" parentStyles={classes.actionText}>
          {LABELS.SHOW_PROGRESS}
        </Text>
        <img
          src={Accordian}
          className={classNameGenerator([
            classes.actionIcon,
            profileSectionHeight > 0 && classes.actionIconActive,
          ])}
          alt="accordian"
        />
      </div>
      <div
        ref={profileSectionRef}
        className={classes.content}
        style={{
          maxHeight: `${profileSectionHeight}px`,
          opacity: profileSectionHeight > 0 ? 1 : 0,
        }}
      >
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.PERSONAL_DETAILS_FORM))}
          className={classes.quickStartItem}
          style={{ borderTop: 'none' }}
        >
          <img
            src={CompletedIcon}
            className={
              isPersonalDetailsFilled(profileFormDetails.personalDetails) ===
              SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.PERSONAL_DETAILS_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.INTRODUCTION))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isIntroFilled(profileFormDetails.introDetails) === SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.INTRODUCTION}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.CURRENT_INCOME_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              (profileFormDetails.currentIncomeDetails
                ? isCurrentIncomeDetailsFilled(profileFormDetails.currentIncomeDetails!.incomes)
                : SectionStatus.INCOMPLETE) === SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.CURRENT_INCOME_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.EMPLOYMENT_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isEmploymentDetailsFilled(
                profileFormDetails.employmentDetails,
                profileFormDetails.currentIncomeDetails
                  ? profileFormDetails.currentIncomeDetails!.incomes
                  : undefined,
              ) === SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.EMPLOYMENT_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.PETS_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isPetsDetailsFilled(profileFormDetails.petsDetails) === SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.PETS_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.VEHICLES_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isVehiclesDetailsFilled(profileFormDetails.vehiclesDetails) ===
              SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.VEHICLES_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.ADDRESS_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              (profileFormDetails.addressDetails
                ? isAddressDetailsFilled(profileFormDetails.addressDetails!.addresses)
                : SectionStatus.INCOMPLETE) === SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.ADDRESS_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.REFERENCE_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isReferenceDetailsFilled(profileFormDetails.referencesDetails) ===
              SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.REFERENCE_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.IDENTIFICATION_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isIdentificationDetailsFilled(profileFormDetails.identificationDetails) ===
              SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.IDENTIFICATION_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          onClick={() => history.push(routes.myProfile.view(ProfileForm.DECLARATION_FORM))}
          className={classes.quickStartItem}
        >
          <img
            src={CompletedIcon}
            className={
              isDeclarationDetailsFilled(profileFormDetails.declarationDetails) ===
              SectionStatus.INCOMPLETE
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{ProfileForm.DECLARATION_FORM}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  profileFormDetails: state.myProfileForm,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileCompletion));
