import { LoginState } from '../state/LoginState';
import { LoginAction, LoginActionTypes } from '../actions/LoginActions';
import {
  LoginResponse,
  RegisterUserResponse,
  RefreshTokenResponse,
  RegisterClientResponse,
  FindUserResponse,
  GuestTokenResponse,
} from '../../services/login/LoginData';
import { IntercomChat } from '../../helper/IntercomChat';

export const initialState: LoginState = {
  loginError: undefined,
  registerError: undefined,
  forgotPasswordError: undefined,
  loginDetails: undefined,
  registerUserDetails: undefined,
  registerClientDetails: undefined,
  refreshTokenDetails: undefined,
  userDetails: undefined,
  guestToken: undefined,
};

export function loginReducer(state = initialState, action: LoginAction) {
  state = postLoginReducer(state, action);
  return state;
}

/* tslint:disable:cyclomatic-complexity */
function postLoginReducer(state: LoginState, action: LoginAction) {
  switch (action.type) {
    case LoginActionTypes.LOGIN_SUCCESS:
      // IntercomChat.registerUser({
      //   name: (action.data as LoginResponse).name,
      //   surname: (action.data as LoginResponse).surname,
      //   email: (action.data as LoginResponse).email,
      // });
      return {
        ...state,
        loginDetails: action.data as LoginResponse,
      };
    case LoginActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.data,
      };

    case LoginActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerUserDetails: action.data as RegisterUserResponse,
      };
    case LoginActionTypes.REGISTER_USER_ERROR:
      return {
        ...state,
        registerError: action.data,
      };

    case LoginActionTypes.REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        registerClientDetails: action.data as RegisterClientResponse,
      };

    case LoginActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshTokenDetails: action.data as RefreshTokenResponse,
      };

    case LoginActionTypes.FIND_USER_SUCCESS:
      return {
        ...state,
        userDetails: action.data as FindUserResponse,
      };
    case LoginActionTypes.FIND_USER_ERROR:
      return {
        ...state,
        userDetails: undefined,
      };
    case LoginActionTypes.CLEAR_LOGIN_ERROR: {
      return {
        ...state,
        userDetails: null,
      };
    }
    case LoginActionTypes.FORGOT_PASSWORD_LINK_ERROR:
      return {
        ...state,
        forgotPasswordError: action.data,
      };
    case LoginActionTypes.GUEST_TOKEN_SUCCESS: {
      return {
        ...state,
        guestToken: action.data as GuestTokenResponse,
      };
    }
    case LoginActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        loginError: null,
        registerError: null,
        forgotPasswordError: null,
      };
    }

    default:
      return state;
  }
}
