/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { leaseMailMergeFields } from '../../../../../helper/MailMergeFieldMapper';
import { propertyMailMergeFields } from '../../../../../helper/PropertyMailMergeFieldMapper';
import { SigningSteps, Terms } from '../../../RemoteSigningUtils';
import { TermMappingHelper } from '../../../../../helper/TermMappingHelper';
import { RemoteSigningGetResponse } from '../../../../../services/remoteSigning/RemoteSigning';
import { useStyles } from '../RemoteSigningStyles';
import { LandlordProperty } from '../../../../../services/remoteSigning/ownership/Ownership';
import './CategoryTermRendererStyle.scss';

interface PreviewDocsProps {
  stepData: SigningSteps;
  remoteSigningData: RemoteSigningGetResponse;
  landlordProperty?: LandlordProperty;
}

const PreviewDocs: FC<PreviewDocsProps> = ({
  stepData,
  remoteSigningData: { lease, ownershipDetails, agency, branch },
  landlordProperty,
}) => {
  const classes = useStyles();
  const getMergeFields = () => {
    if (landlordProperty) {
      return propertyMailMergeFields(ownershipDetails!, landlordProperty);
    } else {
      return leaseMailMergeFields(lease!, branch, agency);
    }
  };

  return (
    <Accordion className={classes.termContainerNoBorder} expanded>
      {stepData.content &&
        stepData.content.map((docData: Terms, index) => {
          return (
            <div key={index}>
              <AccordionSummary>
                <Grid container>
                  <Grid item xs={12}>
                    <div>
                      {docData.hideTitleContent ? (
                        <>
                          <span className={classes.termLabel}>
                            <span className={classes.termIndex}>
                              {docData.requireContentSection ? '' : `${docData.index}. `}
                            </span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: TermMappingHelper.substituteMailMergeFields(
                                  docData.htmlContent,
                                  getMergeFields(),
                                ),
                              }}
                              className="termTitle"
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>{docData.requireContentSection ? '' : `${docData.index}. `}</span>
                          <span>{docData.title}</span>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelStyles }}>
                {!docData.hideTitleContent && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: TermMappingHelper.substituteMailMergeFields(
                        docData.htmlContent,
                        getMergeFields(),
                      ),
                    }}
                    className={classes.termDescription}
                    id="termDescription"
                  />
                )}
              </AccordionDetails>
            </div>
          );
        })}
    </Accordion>
  );
};

export default PreviewDocs;
