/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect } from 'react';
import { Typography, Paper } from '@mui/material';
import { useStyles } from './CompletedSignaturesStyles';
import {
  RemoteSigningPostResponse,
  RemoteSigningGetResponse,
} from '../../../../services/remoteSigning/RemoteSigning';
import PersonCard from '../RemoteSigning/components/PersonCard';
import { getUserTitle } from '../RemoteSigning/components/SigningBox';
import Text from '../../../../component/text/Text';
import CompletedIcon from '../../../../assets/check.svg';
import Button from '../../../../component/button/Button';

interface CompletedSignaturesProps {
  postSignatureResponse: RemoteSigningPostResponse;
  remoteSigningData: RemoteSigningGetResponse;
  handleNext: () => void;
  setActiveStep: (step: number) => void;
}

const CompletedSignatures: FC<CompletedSignaturesProps> = ({
  postSignatureResponse: { unsignedUsers, signedUsers },
  remoteSigningData,
  handleNext,
  setActiveStep,
}) => {
  const classes = useStyles();
  useEffect(() => {
    setActiveStep(1);
  }, []);

  const propertyState =
    (remoteSigningData.lease && remoteSigningData.lease.property!.state) || null;

  return (
    <div className={classes.content}>
      <div className={classes.RDSContainer}>
        <div className={classes.signingTitlesContainer}>
          <Text parentStyles={classes.signingTitle} textVariant="title">
            Other signatures
          </Text>
          <Text textVariant="info">We'll send you a notification once everyone has signed.</Text>
        </div>
        <div className={classes.contentContainer}>
          {signedUsers.map((user) => (
            <div className={classes.personRow}>
              <div className={classes.textContent}>
                <Text
                  textVariant="title"
                  size="xl"
                  parentStyles={classes.personTitle}
                >{`${user.name} ${user.surname}`}</Text>
                <Text textVariant="info">{getUserTitle(user.userType, propertyState)}</Text>
              </div>
              <div className={classes.statusContainer}>
                <img src={CompletedIcon} className={classes.iconActive} alt="status" />
                <Text textVariant="title" size="l">
                  Signed
                </Text>
              </div>
            </div>
          ))}
          {unsignedUsers.map((user) => (
            <div className={classes.personRow}>
              <div className={classes.textContent}>
                <Text
                  textVariant="title"
                  size="xl"
                  parentStyles={classes.personTitle}
                >{`${user.name} ${user.surname}`}</Text>
                <Text textVariant="info">{getUserTitle(user.userType, propertyState)}</Text>
              </div>
              <div className={classes.statusContainer}>
                <img src={CompletedIcon} className={classes.icon} alt="status" />
                <Text textVariant="title" size="l">
                  Pending
                </Text>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.buttonContainer}>
          <Button parentStyles={classes.primaryButton} onPress={() => handleNext()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompletedSignatures;
