import React from 'react';
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import { MultiselectBox } from './MultiselectBox';

/**
 * input value must be an array for this component to work since it's a multiselect box
 */
const RenderMultiselectBox = (data: any) => {
  const { field, label, required, options, disabled, form } = data;

  const hasError = !!field.error && !!field.touched;
  return (
    <div className="item-render">
      <FormControl error={hasError} fullWidth>
        {label && <FormLabel required={required}>{label}</FormLabel>}
        <br />
        <MultiselectBox
          options={options}
          disabled={disabled}
          values={field.value || []}
          handleChange={
            /* istanbul ignore next */
            (item) => form.setFieldValue(field.name, handleChange(field.value, item))
          }
        />
        {hasError && <FormHelperText id={`${field.name}-error-text`}>{field.error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

/* istanbul ignore next */
const handleChange = (valueArray: any[] = [], item: any): any[] => {
  if (valueArray === null) {
    return [item];
  }

  if (valueArray.includes(item)) {
    return valueArray.filter((val) => val !== item);
  }
  return [...valueArray, item];
};

export default RenderMultiselectBox;
