/* eslint-disable import/prefer-default-export */
export const LABELS = {
  APPLICATION_TITLE: 'Your application - Step 2',
  APPLICATION_SUBTITLE:
    "This is every property, you're applying for and everyone your applying with. Please review your application information carefully and add anyone to your application who needs their own profile",
  START_NEW: 'Start a new application with different people',
  INVITE: 'Invite co-applicants to create a new group',
  MY_GROUPS: 'My Application Groups',
  ADD_PROFILE: "Add a profile you'll manage",
  LIVING_WITH_OTHERS:
    'Will you be living with others who are submitting their information separately?',
  HOW_MANY: 'How many others will you be living with?',
  AN_INVITATION:
    'An invitation will be sent to this person so they can submit their profile separately',
  ADD_ANOTHER_APPLICANT: 'Add another applicant',
  MANAGE_GROUP: 'Would you like to make changes to the members of your selected group?',
  CHANGE_NAME: "Would you like to change your group's name?",
  MANAGED_TITLE: 'My profiles',
  MANAGED_SUBTITLE: 'Here are your personal and managed profiles you will complete and manage',
  NAME_GROUP: 'Would you like to give a name to this group?',
  PROPERTIES: (single: boolean) => `${single ? 'Your property' : 'Your properties'}`,
  FROM_THE_BLOG: 'From the blog',
  FOR_YOU: 'For you',
  WHOS_COMING: "Who's coming?",
  BLOG_TITLE: "What's a managed profile?",
  BLOG_BODY:
    'This may be a child or a significant other’s profile, which you are completing on their behalf',
  BLOG_GROUP_TITLE: 'Whats a group?',
  BLOG_GROUP_BODY:
    'Groups are the people that are applying for a property with you; invite friends and family to join you in your rental application',
  NEXT: 'Next',
  SWITCH_GROUP: 'Switch group',
  GROUP_SIDEBAR_TITLE: 'Select group',
  GROUP_SIDE_SUBTITLE:
    'Select the group that you will be applying with. If you need to add a new group you can create one here.',
  YOUR_GROUPS: 'Your groups',
  ADD_A_NEW_GROUP: 'Add a new group',
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
  ADD_MANAGED: '+ Add managed profile',
  CREATE_NEW_GROUP: 'Create a group',
};
export interface AppToApplyTransitionParams {
  agency: string;
  applicationRefIds?: string; // comma separated
  draftIds?: string; // comma separated
  groupRefId: string;
  responseType: string;
  updatingOne?: string;
}

export interface CanSubmit {
  valid: boolean;
  reason: string;
}
