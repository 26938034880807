import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './ProfileSectionStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import {
  addressDetails,
  declarationDetails,
  employmentDetails,
  identificationDetails,
  incomeDetails,
  introDetails,
  personalDetails,
  petsDetails,
  ProfileContent,
  referenceDetails,
  vehiclesDetails,
} from './ProfileSectionConstants';
import { routes } from '../../../../Routes';

interface ProfileSectionProps extends RouteComponentProps {
  type: ProfileForm;
}

const ProfileSection: FC<ProfileSectionProps> = ({ type, history }) => {
  const classes = useStyles();

  const profileContent = useMemo<ProfileContent>(() => {
    switch (type) {
      case ProfileForm.PERSONAL_DETAILS_FORM:
        return personalDetails;
      case ProfileForm.INTRODUCTION:
        return introDetails;
      case ProfileForm.CURRENT_INCOME_FORM:
        return incomeDetails;
      case ProfileForm.EMPLOYMENT_FORM:
        return employmentDetails;
      case ProfileForm.PETS_FORM:
        return petsDetails;
      case ProfileForm.VEHICLES_FORM:
        return vehiclesDetails;
      case ProfileForm.ADDRESS_FORM:
        return addressDetails;
      case ProfileForm.REFERENCE_FORM:
        return referenceDetails;
      case ProfileForm.IDENTIFICATION_FORM:
        return identificationDetails;
      default:
        return declarationDetails;
    }
  }, [type]);

  return (
    <div
      className={classes.profileSectionContainer}
      style={{ backgroundColor: profileContent.backgroundColor }}
    >
      <div className={classes.profileHeader}>
        <img src={profileContent.icon} className={classes.icon} alt="profile icon" />
        <Text parentStyles={classes.title} textVariant="contentTitle">
          {profileContent.title}
        </Text>
      </div>
      <Text textVariant="info">{profileContent.description}</Text>
      <div className={classes.buttonContainer}>
        <Button onPress={() => history.push(routes.myProfile.view(type))}>
          {profileContent.button}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(ProfileSection);
