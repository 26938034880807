/* eslint-disable import/prefer-default-export */

import { LimitedApplicantResponse, GroupResponse } from '../../../../services/groups/Groups';
import localStorage from '../../../../services/LocalStorage';
import { CoApplicantFormData } from './CoApplicantsForm';
import { ERRORS } from './CoApplicantsFormConstants';
import { Selected } from '../../../editGroup/EditGroup';

export const isEmailSame = (values: CoApplicantFormData, index: number) => {
  const compareEmail = values.coApplicants[index].email;
  const found =
    localStorage.getItem('email') === compareEmail ||
    values.coApplicants.find(
      (co: LimitedApplicantResponse, idx: number) => co.email === compareEmail && idx !== index,
    );
  return found ? ERRORS.duplicateEmail : '';
};

export const isGroupDuplicated = (
  groups: GroupResponse[],
  values: CoApplicantFormData,
  selectedGroupRefId: string | null,
  selected: Selected,
): boolean => {
  // If no co-applicants
  if (!values.coApplicants.length) {
    const findGroup = groups.filter((group) => !group.coApplicants.length);

    if (findGroup.length === 1 && selected === Selected.newApplication) {
      return true;
    }
  }

  // else if co-applicants
  const formEmails: string[] = [];
  values.coApplicants.map((co) => {
    if (co.email) {
      formEmails.push(co.email);
    }
  });

  // This will go through every group of the same length,
  // merge the emails into the 1 array with no duplicates.
  // If there are no additional emails, means there is a duplicate.
  for (let i = 0; i < groups.length; i++) {
    if (
      groups[i].coApplicants.length === formEmails.length &&
      groups[i].groupRefId !== selectedGroupRefId
    ) {
      const groupEmails: string[] = [];
      groups[i].coApplicants.map((co) => {
        if (co.primary.email) {
          groupEmails.push(co.primary.email);
        }
      });

      // Check if theres any coApplicants to compare
      if (groupEmails.length !== 0) {
        const merged = Array.from(new Set(formEmails.concat(...groupEmails)));
        if (merged.length === formEmails.length) {
          return true;
        }
      }
    }
  }

  return false;
};
