/* eslint-disable import/prefer-default-export */

export const LABELS = {
  CONGRATZ: (name: string) => `Congrats ${name},`,
  CONGRATZ_SUBTITLE: 'only a few steps left to get move in ready.',
  CONGRATZ_RENEW: 'now is the perfect time to save some money and review your service providers',
  SERVICES_TITLE: 'Home setup',
  RENEW_TITLE: 'Lease renewal',
  RENEW_TEXT: 'Now is the perfect time to save some money and review your service providers',
  SERVICES_SUBTEXT: (agency: string) =>
    `${agency} has partnered with Sorted to help you with services in time for your move in date.`,
  ESSENTIAL: 'Essential services',
  ELECTRICITY: 'Electricity',
  INTERNET: 'Internet',
  GAS: 'Gas',
  WATER: 'Water',
  RENT: 'Rent',
  OPTIONAL: 'Optional services',
  INSURANCE: 'Insurance',
  REMOVALS: 'Removals',
  CLAIM: 'Finish',
  SETUP_RENT: 'Setup rent now',
  SKIP: 'Skip',
  OUR: 'Our service partners',
  MERITON_STATEMENT:
    'Meriton has reached a special arrangement with Occom, the most welcomed Internet Service Provider in Australia, for Internet and Phone services.',
  OCCOM_OFFER_TITLE: 'Exclusive Offer:',
  OCCOM_OFFER_1: 'Free Wifi Router*',
  OCCOM_OFFER_2: 'Free Activation**',
  OCCOM_OFFER_3: 'Free Onsite support',
  OCCOM_OFFER_4: 'Discounted monthly fee',
  OCCOM_SUB_OFFER_TITLE: 'Get Faster, Get Occom!',
  OCCOM_SUB_OFFER_1: 'Faster Response',
  OCCOM_SUB_OFFER_2: 'Faster Setup',
  OCCOM_SUB_OFFER_3: 'Faster Internet Speed',
  OCCOM_SUB_OFFER_4: 'Faster Troubleshooting',
  OCCOM_CONTACT_TITLE: 'Occom, Top-Rated Internet Service Provider in Australia',
  LIVECHAT: 'occom.com.au/meritonapt',
  WHATSAPP: 'WhatsApp: +61492931670',
  LINE: 'Line: @occom',
  WECHAT: 'WeChat: Occomwx',
  TEL: '1300 200 999',
  TEL_LANG: 'English/Mandarin/Cantonese/Hindi/Malay',
  EMAIL: 'sales@occom.com.au',
  OCCOM_1: '5000+ 5-Star Google Reviews',
  OCCOM_2: "Mozo Expert's Choice",
  OCCOM_3: 'AFR Fast 100 Company',
  OCCOM_4: '2nd Fastest Growing Telco in AU (Finanical Times)',
  YES_CONTACT: 'Yes, please contact me to arrange my Internet & Phone services. ',
  YES_CONTACT2:
    'I/we consent to Meriton providing my personal information details to Occom which will include my name, address, email, and phone number to be contacted in relation to my/our Internet and phone services.',
  STAR: '*For 6/12/24 months contract. **Opticomm: for 24 months contract. NBN: for no lock-in contract.',
  RENEW_DATE: 'Renewal date',
  MOVE_DATE: 'Move in date',
  YOUR_OFFER: 'Your offers (1)',
  OFFER_TEXT: "It's a good time to check your electricity plan",
  COMPARE: 'Compare',
  READY: 'Ready',
  DONE: 'Done',
  PENDING: 'Pending',
  REVIEW: 'You have signed your tenancy agreement',
  ORGANISE: 'Organise and connect your utilities',
  QUESTIONS: "Questions? We've got you sorted.",
  GET_MOVE_IN_READY: 'Get move in ready',
  GET_STARTED: 'Get started',
  QUESTIONS_SUBTITLE: "Not sure what's next? Don't worry, we'll give you a call soon.",
  FREQUENTLY: 'Frequently asked questions',
  YOUR_PROPERTY: 'Your property manager',
  CANT_FIND: "Can't find the answer?",
  CONTACT: 'Contact support',
  PROPERTY_MANAGER_PHONE: 'Phone',
  PROPERTY_MANAGER_EMAIL: 'Email',
  PROPERTY_MANAGER: 'Property manager',
  MANAGE: (agencyCode?: string) =>
    `Manage your tenancy with the ${
      agencyCode === 'NAX'
        ? 'Nelson Alexander app'
        : agencyCode === 'AU_SWIT'
        ? 'Switch app'
        : 'Sorted app'
    }`,
  SCAN: 'Scan for more information',
  MERI_ENERGY_TITLE: "Looking to connect Electricity and Gas? We've got you covered!",
  MERI_ENERGY_SUBTITLE: 'Join our smarter, simpler, friendlier energy community',
  MERI_ENERGY_LINE1:
    "We're here to help you take control of your energy needs, and we've got the big picture in mind",
  MERI_ENERGY_LINE2:
    'There are no lock-in contracts and you only pay for what you use, but as a community, you get better deals than you may have access to alone.',
  YES_CONTACT_ELEC: 'Yes, please contact me to arrange my Gas & Electricity services.',
  YES_CONTACT2_ELEC:
    'I/we consent to Meriton providing my personal information details to Meriton Energy which will include my name, address, email, and phone number to be contacted in relation to my/our Gas and Electricity services.',
};
