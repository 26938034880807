import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { pageContainer, smallPageContainer, primaryContent } from '../../theme/GlobalStyles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
  },
  pageContainer: {
    ...pageContainer,
    paddingTop: 0,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
      paddingTop: 0,
    },
  },
  contentContainer: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
});
