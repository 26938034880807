/* eslint-disable import/prefer-default-export */
export const onboardingUrls = {
  rent: (
    appName: string,
    propertyId: number,
    accessToken?: string,
    refreshToken?: string,
    isRenewal?: boolean,
  ) =>
    `onboarding/rent/?appName=${appName}&propertyId=${propertyId}&isRenewal=${isRenewal}&isExternal=true&accessToken=${accessToken}&refreshToken=${refreshToken}`,
  checkout: (
    appName: string,
    propertyId: number,
    accessToken?: string,
    refreshToken?: string,
    isRenewal?: boolean,
  ) =>
    `onboarding/checkout/select?requiredServiceTypes=Broadband,Electricity,Gas&appName=${appName}&propertyId=${propertyId}&isRenewal=${isRenewal}&isExternal=true&accessToken=${accessToken}&refreshToken=${refreshToken}`,
};
