import React, { FC, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  WithdrawRequest,
  WithdrawApplicationEnum,
  InvitationRequest,
} from '../../../../services/groups/Groups';
import { useStyles } from './WithdrawPopupStyles';
import { LABELS, CheckedOption } from './WithdrawPopupConstants';
import { GroupsActions } from '../../../../store/actions/GroupsActions';
import { theme } from '../../../../theme/Theme';
import { ResponseType } from '../../ApplicationsConstants';

interface WithdrawPopup {
  groupRefId: string;
  applicationRefId: string;
  propertyAddress: string;
  responseType: ResponseType | string;
  closeWithdrawPopup: () => void;
  withdrawMyself: (data: WithdrawRequest) => void;
  withdrawApplication: (data: WithdrawRequest) => void;
  declineInvitation: (data: InvitationRequest) => void;
}

const WithdrawPopup: FC<WithdrawPopup> = ({
  propertyAddress,
  groupRefId,
  applicationRefId,
  responseType,
  closeWithdrawPopup,
  withdrawMyself,
  withdrawApplication,
  declineInvitation,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState<CheckedOption | null>(null);

  const withdraw = () => {
    if (checked === CheckedOption.Myself && responseType === ResponseType.SUBMITTED) {
      withdrawMyself({ applicationRefId });
    } else if (checked === CheckedOption.Myself && responseType === ResponseType.INVITE) {
      declineInvitation({ groupRefId, applicationRefs: [applicationRefId] });
    } else if (responseType === ResponseType.INVITE) {
      withdrawApplication({ applicationRefId, type: WithdrawApplicationEnum.INVITE, groupRefId });
    } else {
      withdrawApplication({
        applicationRefId,
        type: WithdrawApplicationEnum.SUBMITTED,
        groupRefId,
      });
    }
    closeWithdrawPopup();
  };

  return (
    <div className={classes.formStyle}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{LABELS.WITHDRAW_TITLE}</div>
        <CloseIcon className={classes.closeIcon} onClick={() => closeWithdrawPopup()} />
      </div>
      <div className={classes.subtitleText}>{LABELS.PLEASE_SELECT}</div>
      <div className={classes.optionsContainer}>
        <div className={classes.optionRow} onClick={() => setChecked(CheckedOption.Application)}>
          <HomeIcon />
          <div className={classes.optionText}>{LABELS.WITHDRAW_APPLICATION}</div>
          {checked === CheckedOption.Application ? (
            <CheckCircleIcon className={classes.check} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.check} />
          )}
        </div>
        <div className={classes.optionRow} onClick={() => setChecked(CheckedOption.Myself)}>
          <PersonOutlineIcon />
          <div className={classes.optionText}>{LABELS.WITHDRAW_MYSELF}</div>
          {checked === CheckedOption.Myself ? (
            <CheckCircleIcon className={classes.check} />
          ) : (
            <RadioButtonUncheckedIcon className={classes.check} />
          )}
        </div>
      </div>
      <div className={classes.infoBox}>
        {checked
          ? checked === CheckedOption.Application
            ? `${LABELS.WITHDRAW_APPLICATION_INFO1}${propertyAddress}${LABELS.WITHDRAW_APPLICATION_INFO2}`
            : `${LABELS.WITHDRAW_MYSELF_INFO1}${propertyAddress}${LABELS.WITHDRAW_MYSELF_INFO2}`
          : LABELS.PLEASE_SELECT_INFO}
      </div>

      <div className={classes.buttonContainer}>
        <div className={classes.secondaryButton} onClick={() => closeWithdrawPopup()}>
          Cancel
        </div>
        <div
          className={`${classes.primaryButton} ${checked === null ? classes.disabled : undefined}`}
          onClick={() => withdraw()}
        >
          Continue
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  declineInvitation: (data: InvitationRequest) => {
    dispatch(GroupsActions.declineInvitationRequest(data));
  },
  withdrawMyself: (data: WithdrawRequest) => {
    dispatch(GroupsActions.withdrawMyselfRequest(data));
  },
  withdrawApplication: (data: WithdrawRequest) => {
    dispatch(GroupsActions.withdrawApplicationRequest(data));
  },
});

export default connect(null, mapDispatchToProps)(WithdrawPopup);
