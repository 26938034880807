/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import {
  actionGreen,
  maxContentWidth,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...smallPageContainer,
  },
  titleContainer: {
    ...pageTitleContainer,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addGroup: {
    marginBottom: theme.units.spacing * 6,
  },
  pageContent: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 12,
      maxWidth: '100%',
    },
  },
  locationContainer: {
    backgroundColor: theme.colors.fadedPurple,
    borderRadius: theme.units.borderRadius,
    padding: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 4,
  },
  stateQuestion: {
    marginBottom: '20px',
    fontSize: '18px',
    color: theme.colors.black87,
    fontWeight: '500',
  },
  stateButtonContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'repeat(8, 1fr)',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    gridGap: '15px',
  },
  stateButton: {
    width: '100%',
    border: `2px ${theme.colors.secondary} solid`,
    backgroundColor: theme.colors.light,
    height: '35px',
    borderRadius: '6px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.secondary,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  selectedButton: {
    width: '100%',
    border: `2px ${theme.colors.secondary} solid`,
    height: '35px',
    borderRadius: '6px',
    backgroundColor: theme.colors.secondary,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  backRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.units.spacing * 24,
  },
  backArrow: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
    marginRight: theme.units.spacing * 6,
  },
});
