import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Field, Formik } from 'formik';
import OtpInput from 'react-otp-input';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import {
  LoginPayload,
  RegisterUserPayload,
  UserCredential,
  VerificationLinkPayload,
} from '../../../../services/login/LoginData';
import { LoginActions, LoginActionTypes } from '../../../../store/actions/LoginActions';
import { SuccessToastActions } from '../../../../store/actions/ SuccessToastAction';
import { ApplicationState } from '../../../../store/RootReducers';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { View } from '../../Login';
import { useStyles } from './VerifyOtpStyles';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import Button from '../../../../component/button/Button';
import { LABELS, VALIDATION_SCHEMA } from './VerifyOtpConstants';
import RenderCheckbox from '../../../../component/RenderCheckbox/RenderCheckbox';
import moment from 'moment';
import { TypeHelper } from '../../../../helper/TypeHelper';

interface VerifyOtpProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  userDetails: RegisterUserPayload | null;
  credentials: UserCredential | null;
  loading: boolean;
  setView: (view: View) => void;
}

const VerifyOtp: FC<VerifyOtpProps> = ({
  credentials,
  loading,
  postLogin,
  loginError,
  otpSuccessAction,
}) => {
  const classes = useStyles();

  const errorMessage = useMemo(() => {
    if (loginError?.response?.status === 401) {
      return 'Verification failed.';
    } else if (loginError?.response?.status === 400) {
      return `Your email hasn't been verified. Check your email to verify or resend the link.`;
    } else {
      otpSuccessAction();
      return null;
    }
  }, [loginError]);

  const [otpCountdown, setOtpCountdown] = useState('01:59');
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [otpCount, setOtpCount] = useState(1);

  useEffect(() => {
    setCanResendOtp(false);
    if (otpCount < 3) {
      const reach = moment().add(2, 'm');
      const timer = setInterval(() => {
        const now = moment();
        const secondsRemain = reach.diff(now, 'seconds');

        const min = Math.floor(secondsRemain / 60);
        const sec = secondsRemain % 60;
        setOtpCountdown(`${TypeHelper.padZeroStart(min)}:${TypeHelper.padZeroStart(sec)}`);

        if (secondsRemain < 1) {
          clearInterval(timer);
          setCanResendOtp(true);
        }
      }, 1000);
    }
  }, [otpCount]);

  return (
    <>
      <div className={classes.titleContainer}>
        <img src={sortedIcon} className={classes.icon} />
        <div className={classes.primaryTitle}>{LABELS.login}</div>
      </div>
      <div className={classes.pageContent}>
        <div className={classes.primaryContainer}>
          <Formik
            initialValues={{
              username: '',
              password: '',
              otp: '',
              grant_type: 'otp',
              trust_device: false,
              ...credentials,
            }}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(d: LoginPayload) => postLogin(d)}
            render={({ handleSubmit, values, setFieldValue, errors }) => {
              return (
                <Box>
                  <form onSubmit={handleSubmit}>
                    <Text textVariant="boxTitle">{LABELS.title}</Text>

                    <div className={classes.subtitleRow}>
                      <Text textVariant="info">
                        {`${LABELS.label} `}
                        <span>
                          <b>{credentials?.username}</b>
                        </span>
                      </Text>
                    </div>
                    <div className={classes.inputRow}>
                      <OtpInput
                        onChange={(value: string) => setFieldValue('otp', value)}
                        numInputs={6}
                        value={values.otp}
                        containerStyle={classes.container}
                        isInputNum
                        shouldAutoFocus
                        inputStyle={classes.input}
                      />
                    </div>
                    {(errorMessage || errors) && (
                      <div className={classes.errorText}>{errorMessage || errors.otp}</div>
                    )}
                    <div className={classes.loginRow}>
                      <Field
                        name="trust_device"
                        component={RenderCheckbox}
                        label={LABELS.TRUST_DEVICE}
                      />
                      <Button loading={loading} type="submit" onPress={() => handleSubmit()}>
                        {LABELS.verifyButton}
                      </Button>
                    </div>
                  </form>
                  <div className={classes.signupRow}>
                    <Text textVariant="info">{LABELS.DIDNT_RECIEVE_OTP}</Text>

                    {otpCount < 3 ? (
                      canResendOtp ? (
                        <Button
                          outline
                          parentStyles={classes.signupButton}
                          onPress={() => {
                            setOtpCount(otpCount + 1);
                            postLogin(credentials!);
                          }}
                        >
                          {LABELS.RESEND_OTP}
                        </Button>
                      ) : (
                        <div style={{ marginLeft: '1rem' }}>
                          <Text textVariant="info">{`${LABELS.TRY_AGAIN_IN} ${otpCountdown}`}</Text>
                        </div>
                      )
                    ) : (
                      <div style={{ marginLeft: '1rem' }}>
                        <Text textVariant="info">{LABELS.MAXIMUM_RETRIES}</Text>
                      </div>
                    )}
                  </div>
                </Box>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

const loading = loadingSelector([LoginActionTypes.LOGIN]);

const mapStateToProps = (state: ApplicationState) => ({
  loginError: state.login.loginError,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearErrors: () => {
    dispatch(LoginActions.clearErrors());
  },
  postLogin: (data: LoginPayload) => {
    dispatch(LoginActions.postLoginStart(data));
  },
  sendVerificationEmail: (data: VerificationLinkPayload) => {
    dispatch(LoginActions.postVerificationLinkStart(data));
  },
  otpSuccessAction: () => dispatch(SuccessToastActions.show('OTP sent successfully!')),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
