import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PropertyCardStyles';
import Box from '../box/Box';
import Text from '../text/Text';
import bedrooms from '../../assets/bed.png';
import bathrooms from '../../assets/bath.png';
import carspaces from '../../assets/car.png';
import SORTED_CIRCULAR_LOGO from '../../assets/logosCircle/sorted.png';
import propertyDefaultIcon from '../../assets/homePlaceholder.png';
import {
  Assets,
  PropertyDetailsResponse,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { LABELS } from './PropertyConstants';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

interface PropertyCardProps {
  property: PropertyDetailsResponse;
  assets: Assets | null | undefined;
}

const PropertyCard: FC<PropertyCardProps> = ({ property, assets }) => {
  const classes = useStyles();
  return (
    <Box lightBorder parentStyles={classes.boxContainer}>
      <div className={classes.applicationRow}>
        <div className={classes.propertyContainer}>
          <img
            className={classes.property}
            src={
              property.photos && !!property.photos.length ? property.photos[0] : propertyDefaultIcon
            }
            alt="property"
          />
        </div>
        <div className={classes.applicationDetails}>
          <div className={classes.applicationDetailsMiddle}>
            <div className={classes.propertyAddressContainer}>
              <Text textVariant="title" parentStyles={classes.address1}>
                {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                  property.streetNumber || ''
                } ${property.streetName}`}
              </Text>
              <Text textVariant="info" parentStyles={classes.address2}>
                {`${property.suburb}, ${property.state} ${property.postcode}`}
              </Text>
            </div>
            <div className={classes.rentAndAgencyContainer}>
              <div className={classes.agencyContainer}>
                {assets && assets.circularLogo && (
                  <img
                    className={classes.agencyLogo}
                    onError={(e) => {
                      (e.target as HTMLImageElement).src = SORTED_CIRCULAR_LOGO;
                    }}
                    src={assets?.circularLogo}
                    alt="agency logo"
                  />
                )}
              </div>
              <div className={classes.rentContainer}>
                <div className={classes.rentRow}>
                  <Text textVariant="title" parentStyles={classes.rent}>
                    {property.rentalOffer ? `$${property.rentalOffer}` : '-'}
                  </Text>
                </div>

                <Text textVariant="info" parentStyles={classes.rentInfo}>
                  {LABELS.RENT_PW}
                </Text>
              </div>
            </div>
          </div>
          <div className={classes.applicationDetailsFooter}>
            <div className={classes.statsRow}>
              {!!property.bedrooms && (
                <div className={classes.stat}>
                  <img className={classes.statIcon} src={bedrooms} alt="people stat" />
                  <div className={classes.statTextRow}>
                    <Text textVariant="info" parentStyles={classes.statNumber}>
                      {property.bedrooms}
                    </Text>
                    <Text textVariant="info" parentStyles={classes.statText}>
                      {LABELS.BEDROOMS}
                    </Text>
                  </div>
                </div>
              )}
              {!!property.bathrooms && (
                <div className={classes.stat}>
                  <img className={classes.statIcon} src={bathrooms} alt="applications stat" />
                  <div className={classes.statTextRow}>
                    <Text textVariant="info" parentStyles={classes.statNumber}>
                      {property.bathrooms}
                    </Text>
                    <Text textVariant="info" parentStyles={classes.statText}>
                      {LABELS.BATHROOMS}
                    </Text>
                  </div>
                </div>
              )}
              {!!property.carspaces && (
                <div className={classes.stat}>
                  <img className={classes.statIcon} src={carspaces} alt="applications stat" />
                  <div className={classes.statTextRow}>
                    <Text textVariant="info" parentStyles={classes.statNumber}>
                      {property.carspaces}
                    </Text>
                    <Text textVariant="info" parentStyles={classes.statText}>
                      {LABELS.CARSPACES}
                    </Text>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  assets: state.dashboard.assets,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
