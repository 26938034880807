/* eslint-disable import/prefer-default-export */
export const LABELS = {
  THANK_YOU: 'Thanks for helping',
  FIND_NEXT: 'find their next home',
  SORTED_IS_HERE: 'Sorted is here to help you too',
  SORTED_IS_AUSTRALIA:
    'Sorted is Australia’s first whole of agency, full-lifecycle home engagement platform',
  WERE_AUSTRALIA:
    'We’re Australia’s first all-in-one home services platform, making it super fast and easy to get all the utilities and services you need for your home – from connecting electricity, gas and internet, to getting insured, to booking a tradie – all in just a few taps online.',
  BY_CONNECTING:
    "By connecting 'key household moments’, like applying for properties, connecting services, finding insurance, or booking maintenance, and bringing them all together within a single connected platform, Sorted is setting a new bar for the customer and agent experience.",
  LEARN_MORE: 'Learn more',
  DOT_ONE: 'The best customer experience in the market',
  DOT_TWO: 'Unparalleled efficiencies and time savings',
  DOT_THREE: 'Dramatically enhanced capability to scale',
  REVIEW:
    'We love using sorted as it creates a far better experience for our tenants - from the moment they apply for a property right through to lease signing and onboarding of utilities and rent set up. Sorted also saves significant time for our team, on average about 45 minutes for each new tenancy. This frees them up to focus on other important, client facing activities.',
  REVIEW_NAME: 'Hannah Gill',
  REIVEW_TITLE: 'Director of Property Management',
  REIVEW_TITLE2: 'The Property Collective',
  CHANGING_THE_GAME: "Changing the game for Australia's leading agencies",
};
