export const sortedWebUrls = {
  cimet: (propertyId: number | string, accessToken?: string, refreshToken?: string) =>
    `cimet?appName=SORTED&propertyId=${propertyId}&accessToken=${accessToken}&refreshToken=${refreshToken}`,
  rent: (
    appName: string,
    propertyId: number,
    accessToken?: string,
    refreshToken?: string,
    isRenewal?: boolean,
  ) =>
    `rentWelcome?appName=${appName}&propertyId=${propertyId}&isRenewal=${isRenewal}&isExternal=true&accessToken=${accessToken}&refreshToken=${refreshToken}`,
  marketplace: (
    appName: string,
    propertyId: number,
    accessToken?: string,
    refreshToken?: string,
    isRenewal?: boolean,
  ) =>
    `checkoutSelect?requiredServiceTypes=Broadband,Electricity,Gas&appName=${appName}&propertyId=${propertyId}&isRenewal=${isRenewal}&isExternal=true&accessToken=${accessToken}&refreshToken=${refreshToken}`,
};
