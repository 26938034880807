import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  CustomTermsHeaderResponse,
  CustomTermsHeaderPayload,
} from '../../store/actions/CustomTermsHeaderActions';

const serviceType = ServiceType.Terms;

export class CustomTermsHeaderService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public getCustomTermsHeader(
    payload: CustomTermsHeaderPayload,
  ): Promise<CustomTermsHeaderResponse> | CustomTermsHeaderResponse {
    const { agency, branch } = payload;
    return this.service.get<CustomTermsHeaderResponse>({
      route: branch ? [agency, branch] : [agency],
    });
  }
}

const customTermsHeaderService = new CustomTermsHeaderService();
export default customTermsHeaderService;
