/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { delay, put, takeEvery } from 'redux-saga/effects';
import PaymentService from '../../services/payment/PaymentService';
import {
  BankAccountRequest,
  CreditCardRequest,
  GetPaymentAuthKeyRequest,
  GetPaymentAuthKeyResponse,
} from '../../services/remoteSigning/payment/Payment';
import { PaymentActions, PaymentAction, PaymentActionTypes } from '../actions/PaymentActions';

const creditCardCallBackFunction = (
  response: any,
  onSuccess: (token: string) => void,
  onError: (error: Error) => void,
) => {
  if (response.AjaxResponseType === 0 && response.ApiResponseCode === 0) {
    const dvtoken = response.ResultKey;
    onSuccess(dvtoken);
  } else {
    onError(response.Errors[0].Message);
  }
};

export function* getPaymentAuthKeySaga(action: PaymentAction) {
  try {
    const actionData = action.data as GetPaymentAuthKeyRequest;
    const response: GetPaymentAuthKeyResponse = yield PaymentService.getPaymentAuthKey(
      actionData.token,
    );
    let requestObject = {};
    if (actionData.data.type === 'CREDITCARD') {
      const paymentDetails = actionData.data as CreditCardRequest;
      requestObject = {
        Type: paymentDetails.type,
        CardHolderName: paymentDetails.cardHolderName,
        CardNumber: paymentDetails.cardNumber.replace(/\s+/g, ''),
        ExpiryMonth: paymentDetails.expiryMonth,
        ExpiryYear: paymentDetails.expiryYear,
      };
    } else {
      const paymentDetails = actionData.data as BankAccountRequest;
      requestObject = {
        Type: paymentDetails.type,
        BankAccountName: paymentDetails.accountName,
        BankAccountNumber: paymentDetails.accountNumber,
        BSBNumber: paymentDetails.bsbNumber.replace('-', ''),
        AcceptBankAccountTerms: true,
      };
    }
    if (response.status === 0) {
      const paymentDetails = actionData.data;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      CBA.ProcessAddToken({
        AuthKey: response.authKey,
        Crn1: paymentDetails.email,
        EmailAddress: paymentDetails.email,
        ...requestObject,
        CallbackFunction: (result: any) =>
          creditCardCallBackFunction(result, actionData.onSuccess, actionData.onError),
      });
    } else {
      yield put(
        PaymentActions.getPaymentAuthKeyError(
          'Unable to communicate with payment provider, please contact support' as any,
        ),
      );
    }
    yield put(PaymentActions.getPaymentAuthKeySuccess(response));
  } catch (error) {
    yield put(PaymentActions.getPaymentAuthKeyError(error as Error));
    yield put(PaymentActions.resetPaymentError());
  }
}

function* paymentWatcher() {
  yield takeEvery(PaymentActionTypes.GET_PAYMENT_AUTH_KEY_REQUEST, (action: PaymentAction) =>
    getPaymentAuthKeySaga({
      type: action.type,
      data: action.data,
    }),
  );
}

export default paymentWatcher;
