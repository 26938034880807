import { StoreAction } from '../StoreHelper';
import { ApplicationState } from '../RootReducers';
import {
  SubmittedApplicationResponse,
  MasterProfilePayload,
  SignDataPayload,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { AustraliaState } from '../../services/dashboard/australiaStates/australiaStates';

export enum MasterProfileActionTypes {
  SET_MASTER_PROFILE = 'SET_MASTER_PROFILE',
  GET_MASTER_PROFILE_ERROR = 'GET_MASTER_PROFILE_ERROR',

  POST_SAVE_AS_MASTER_PROFILE = 'POST_SAVE_AS_MASTER_PROFILE',
  POST_SAVE_AS_MASTER_PROFILE_ERROR = 'POST_SAVE_AS_MASTER_PROFILE_ERROR',
  PROFILE_STATE_CHANGED = 'PROFILE_STATE_CHANGED',
  REFERENCES_STATE_CHANGED = 'REFERENCES_STATE_CHANGED',

  POST_SAVE_REFERENCES_PROFILE = 'POST_SAVE_REFERENCES_PROFILE',
  POST_SAVE_REFERENCES_PROFILE_ERROR = 'POST_SAVE_REFERENCES_PROFILE_ERROR',
  POST_SAVE_MASTER_PROFILE = 'POST_SAVE_MASTER_PROFILE',
  POST_SAVE_SIGNATURE = 'POST_SAVE_SIGNATURE',
  POST_SAVE_SIGNATURE_ERROR = 'POST_SAVE_SIGNATURE_ERROR',

  GET_CURRENT_MASTER_PROFILE_VERSION = 'GET_CURRENT_MASTER_PROFILE_VERSION',
  SET_CURRENT_MASTER_PROFILE_VERSION = 'SET_CURRENT_MASTER_PROFILE_VERSION',

  SET_AUSTRALIA_STATE = 'SET_AUSTRALIA_STATE',
}

export type MasterProfileActionPayload =
  | string
  | SubmittedApplicationResponse
  | ApplicationState
  | Error
  | null
  | SignDataPayload
  | AustraliaState
  | number;

export type MasterProfileAction = StoreAction<MasterProfileActionTypes, MasterProfileActionPayload>;

export class MasterProfileActions {
  public static setMasterProfile(data: MasterProfilePayload) {
    return { type: MasterProfileActionTypes.SET_MASTER_PROFILE, data };
  }
  public static getMasterProfileFailure(error: Error | string) {
    return {
      type: MasterProfileActionTypes.GET_MASTER_PROFILE_ERROR,
      data: error,
    };
  }
  public static updateProfileChangesFlag(isChanged: boolean) {
    return {
      type: MasterProfileActionTypes.PROFILE_STATE_CHANGED,
      data: isChanged,
    };
  }
  public static updateReferencesChangesFlag(isChanged: boolean) {
    return {
      type: MasterProfileActionTypes.REFERENCES_STATE_CHANGED,
      data: isChanged,
    };
  }

  public static postSaveReferencesProfile(data: ApplicationState) {
    return {
      type: MasterProfileActionTypes.POST_SAVE_REFERENCES_PROFILE,
      data,
    };
  }
  public static postSaveMasterProfile(data: ApplicationState) {
    return { type: MasterProfileActionTypes.POST_SAVE_MASTER_PROFILE, data };
  }
  public static postSaveSignature(data: SignDataPayload, success: () => void) {
    data.success = success;
    return { type: MasterProfileActionTypes.POST_SAVE_SIGNATURE, data };
  }
  public static saveSignatureError(error: Error | null) {
    return {
      type: MasterProfileActionTypes.POST_SAVE_SIGNATURE_ERROR,
      data: error,
    };
  }

  public static setAustraliaState(data: AustraliaState | null) {
    return { type: MasterProfileActionTypes.SET_AUSTRALIA_STATE, data };
  }
}
