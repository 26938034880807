import { takeEvery, put } from 'redux-saga/effects';
import {
  DeleteFilePayload,
  FileUploadResponse,
  FileUploadStartState,
  MultiFileUploadStartState,
  MultiFileUploadSuccessState,
  ReferenceFileUploadStart,
} from '../../services/fileUpload/FileData';
import FileUploadService from '../../services/fileUpload/FileService';
import {
  FileUploadAction,
  FileUploadActions,
  FileUploadActionTypes,
} from '../actions/FileUploadAction';
import { FileLoadingSpinnerActions } from '../actions/FileLoadingSpinnerActions';
import referenceToolService from '../../services/referenceTool/ReferenceToolService';

export function* fileUploadWorker(action: FileUploadAction) {
  try {
    yield put(FileLoadingSpinnerActions.show());
    const { file, id } = action.data as FileUploadStartState;
    const response: string = yield FileUploadService.postFile(file);
    yield put(FileUploadActions.postFileUploadSuccess({ link: response as string, id }));
  } catch (error) {
    yield put(FileUploadActions.postFileUploadError(error as Error));
  } finally {
    yield put(FileLoadingSpinnerActions.hide());
  }
}

export function* deleteFileWorker(action: FileUploadAction) {
  try {
    const data = action.data as DeleteFilePayload;
    yield FileUploadService.deleteFile(data);
    yield put(FileUploadActions.deleteFileSuccess());
  } catch (error) {
    yield put(FileUploadActions.deleteFileError(error as Error));
  }
}

export function* referenceFileUploadWorker(action: FileUploadAction) {
  try {
    yield put(FileLoadingSpinnerActions.show());
    const { data, token } = action.data as ReferenceFileUploadStart;
    const response: string = yield referenceToolService.postReferenceFile(token, data);
    yield put(
      FileUploadActions.postFileUploadSuccess({
        link: response as string,
        id: 'questionnaireFile',
      }),
    );
  } catch (error) {
    yield put(FileUploadActions.postFileUploadError(error as Error));
  } finally {
    yield put(FileLoadingSpinnerActions.hide());
  }
}

export function* multipleFileUploadWorker(action: FileUploadAction) {
  try {
    yield put(FileLoadingSpinnerActions.show());
    const { file, id } = action.data as MultiFileUploadStartState;
    const response: FileUploadResponse[] = yield FileUploadService.postMultipleFile(file);
    yield put(
      FileUploadActions.postMultiFileUploadSuccess({
        file: (response as FileUploadResponse[]).map((res) => ({
          link: res.fileLink,
          name: res.fileName,
        })),
        id,
      }),
    );
  } catch (error) {
    yield put(FileUploadActions.postFileUploadError(error as Error));
  } finally {
    yield put(FileLoadingSpinnerActions.hide());
  }
}

function* fileUplaodWatcher() {
  yield takeEvery(FileUploadActionTypes.POST_FILE_UPLOAD_REQUEST, fileUploadWorker);
  yield takeEvery(FileUploadActionTypes.DELETE_FILE, deleteFileWorker);
  yield takeEvery(FileUploadActionTypes.POST_REFERENCE_FILE_REQUEST, referenceFileUploadWorker);
  yield takeEvery(FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_REQUEST, multipleFileUploadWorker);
}

export default fileUplaodWatcher;
