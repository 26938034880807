import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  actionButton: {
    marginLeft: '8px',
    padding: '4px 16px 4px 16px',
    borderRadius: 6,
    minWidth: '80px',
    width: 'max-content',
    border: `${theme.colors.secondary} 2px solid`,
    background: theme.colors.secondary,
    color: theme.colors.light,
    fontWeight: 500,
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionIcon: {
    width: '24px',
    height: '24px',
    marginLeft: 'auto',
    filter: 'invert(1)',
  },
  similarPropertiesContainer: {
    margin: 'auto',
    marginTop: theme.units.spacing * 4,
    marginBottom: '30px',
    borderRadius: theme.units.borderRadius,
  },
  similarTitle: {
    marginBottom: theme.units.spacing * 4,
  },
  similarPropertyCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: 8,
    padding: '15px',
    marginBottom: theme.units.spacing * 4,
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  addressContainer: {
    marginRight: 'auto',
    marginLeft: 12,
  },
  addressBold: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: theme.units.spacing,
    color: theme.colors.black87,
  },
  address: {
    color: theme.colors.grey,
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  profileTitleContainer: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  profileTitle: {
    fontSize: '38px',
    fontWeight: 300,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      fontSize: '30px',
    },
  },
  profileSubtitle: {
    marginTop: '10px',
    fontSize: '20px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  propertyImage: {
    height: 80,
    width: 80,
    flexShrink: 0,
    borderRadius: theme.units.borderRadius / 1.5,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  applicationsCardsContainer: {
    marginTop: theme.units.spacing * 4,
  },
  rent: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: 2,
    color: theme.colors.success,
  },
  rentLarge: {
    color: theme.colors.secondary,
    marginRight: '15px',
  },
});
