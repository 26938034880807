import React, { FC } from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { Identification } from '../../store/state/MyProfileFormState';
import suggestions from './Countries';
import { useStyles } from './CountryStyle';
import CompletedIcon from '../../assets/check.svg';

interface CountryListProps {
  setFieldValue: (path: string, value: string) => void;
  setValues: Identification;
  path: string;
  updateProfileState: () => void;
  value: string | null | undefined;
}

interface InputDataProps {
  classes: any;
  InputProps: { placeholder: string };
  ref?: any;
}

interface SuggestionProps {
  suggestion: { code: string; label: string };
  index: number;
  itemProps: any;
  highlightedIndex: number | null;
  selectedItem: any;
}

const CountryList: FC<CountryListProps> = ({
  setFieldValue,
  setValues,
  path,
  updateProfileState,
  value,
}: CountryListProps) => {
  const selectedCountry = suggestions.filter((country) => country.code === value);
  const classes = useStyles();

  const renderInput = ({ InputProps, classes, ref }: InputDataProps) => {
    return (
      <TextField
        className={classes.root}
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
          autoComplete: 'new-password',
        }}
      />
    );
  };

  const renderSuggestion = ({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  }: SuggestionProps) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.code}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
    );
  };

  const getSuggestions = (value: string | null) => {
    const inputValue = deburr(value!.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const maxNoOfSuggestions = 10;

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => {
          const keep =
            count < maxNoOfSuggestions &&
            suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
          if (keep) {
            count += 1;
          }
          return keep;
        });
  };

  return (
    <Downshift
      id="downshift-simple"
      onSelect={(option) => {
        const countryCode = suggestions.filter((country) => country.label === option)[0].code;
        setFieldValue(path, countryCode);
        setValues.countryOfOrigin = countryCode;
        updateProfileState();
      }}
      initialInputValue={
        selectedCountry && selectedCountry.length > 0 ? selectedCountry[0].label : ''
      }
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
      }) => (
        <div className={classes.container}>
          {renderInput({
            classes,
            InputProps: getInputProps({
              placeholder: 'Start typing to search a country',
            }),
          })}
          {!!value && (
            <img
              style={{ position: 'absolute', right: 8, top: 8, width: 25, height: 25 }}
              src={CompletedIcon}
              alt=""
            />
          )}

          <div {...getMenuProps()}>
            {isOpen ? (
              <Paper square>
                {getSuggestions(inputValue).map((suggestion, index) => {
                  return renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({
                      item: suggestion.label,
                    }),
                    highlightedIndex,
                    selectedItem,
                  });
                })}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
};

export default CountryList;
