import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    maxWidth: 1200,
  },
  pageContainer: {
    marginTop: 0,
    paddingTop: 0,
    height: `100%`,
  },
  titleContainer: {
    marginTop: 64,
    flexWrap: 'wrap',
    marginBottom: 40,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: 500,
    flexBasis: '100%',
  },
  subtitle: {
    color: theme.colors.title,
    fontSize: theme.font.xxl,
    fontWeight: 500,
    marginTop: '1em',
  },
  section: {
    marginBottom: theme.units.spacing * 8,
  },
  questionInfoText: {
    color: theme.colors.grey,
    fontSize: theme.font.s,
    fontWeight: 500,
    marginTop: -theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 4,
  },
  checkboxRow: {
    display: 'flex',
    marginTop: theme.units.spacing * 2,
    width: 'max-content',
    alignItems: 'center',
  },
  checkBoxStyle: {
    padding: 0,
    marginRight: theme.units.spacing * 4,
    flexShrink: 0,
  },
  htmlContainer: {
    marginTop: theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 4,
  },
  htmlText: {
    color: theme.colors.black60,
    fontSize: theme.font.m,
    fontWeight: 500,
    '& > a': {
      color: theme.colors.secondary,
    },
  },
  dropdown: {
    padding: 6,
  },
  errorText: {
    color: theme.colors.error,
    fontWeight: 500,
    marginRight: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
  },
  inputContainerStyle: {
    paddingBottom: '16px',
    width: '100%',
  },
  fileInputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 2,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontSize: theme.font.s,
    marginLeft: theme.units.spacing,
    fontWeight: getFonts('Medium'),
  },
  fileInputTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
});

export default useStyles;
