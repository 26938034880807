import { makeStyles } from '@mui/styles';
import { actionGreen } from '../../../../theme/GlobalStyles';
import { theme } from '../../../../theme/Theme';

export const useStyles = makeStyles({
  contianer: {
    backgroundColor: theme.colors.sortedPurple,
    height: '100vh',
    display: 'flex',
    flex: 1,
  },
  textStyle: {
    color: theme.colors.black87,
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: 20,
  },
  titleTextStyle: {
    color: theme.colors.black87,
    marginBottom: 20,
    fontSize: 27,
    fontWeight: 700,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  iconStyle: {
    width: 58,
    height: 58,
    marginBottom: 40,
    filter: 'invert(100%)',
  },
  appIconStyle: {
    marginBottom: 40,
    filter: 'invert(100%)',
  },
  dontInvertIconStyle: {
    width: 58,
    height: 58,
    marginBottom: 40,
  },
  dontInvertAppIconStyle: {
    marginBottom: 40,
  },
  rootContainer: {
    backgroundColor: theme.colors.light,
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
});
