import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  button: {
    background: theme.colors.sortedPurple,
    [`@media (min-width: ${SIZES.md}px)`]: {
      width: '50%',
      height: 50,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
      height: 50,
    },
  },
  buttonFullWidth: {
    background: theme.colors.sortedPurple,
    width: '100%',
    height: 50,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    color: theme.colors.light,
  },
  disabledButtonText: {
    color: theme.colors.darkGrey,
  },
});
