import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  container: {
    marginTop: theme.units.spacing * 8,
    border: `${theme.colors.borderColorLight} 2px solid`,
    borderRadius: theme.units.borderRadius,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sortedLogo: {
    height: 30,
    marginTop: theme.units.spacing * 12,
    marginBottom: theme.units.spacing * 12,
  },
  title: {
    marginBottom: theme.units.spacing * 8,
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: theme.units.spacing * 4,
    textAlign: 'center',
  },
  assetsContainer: {
    display: 'flex',
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      flexDirection: 'column',
      width: '100%',
      marginBottom: theme.units.spacing * 8,
    },
    [`@media (max-width: 600px)`]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  brandsLeft: {
    marginRight: theme.units.spacing * 8,
    marginTop: theme.units.spacing * 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      marginRight: 0,
      marginTop: 0,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    [`@media (max-width: 600px)`]: {
      flexDirection: 'column',
    },
  },
  phone: {
    height: 400,
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      display: 'none',
    },
  },
  brandsRight: {
    marginLeft: theme.units.spacing * 8,
    marginTop: theme.units.spacing * 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      marginLeft: 0,
      marginTop: 0,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    [`@media (max-width: 600px)`]: {
      flexDirection: 'column',
    },
  },
  brand: {
    height: 40,
    width: 140,
    objectFit: 'contain',
    objectPosition: 'center',
    marginBottom: theme.units.spacing * 12,
    position: 'relative',
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      marginBottom: 0,
    },
    [`@media (max-width: 600px)`]: {
      marginBottom: theme.units.spacing * 8,
    },
  },
  rightBrand: {
    height: 40,
    width: 140,
    objectFit: 'contain',
    objectPosition: 'center',
    marginBottom: theme.units.spacing * 12,
    position: 'relative',
    right: -40,
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      right: 0,
      marginBottom: 0,
    },
    [`@media (max-width: 600px)`]: {
      marginBottom: theme.units.spacing * 8,
    },
  },
  leftBrand: {
    height: 40,
    width: 140,
    objectFit: 'contain',
    objectPosition: 'center',
    marginBottom: theme.units.spacing * 12,
    position: 'relative',
    left: -40,
    [`@media (max-width: ${SIZES.lgMax + 120}px)`]: {
      left: 0,
      marginBottom: 0,
    },
    [`@media (max-width: 600px)`]: {
      marginBottom: theme.units.spacing * 8,
    },
  },
});
