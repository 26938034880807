import { Content } from '../../helper/Content/Content.data';
import { AgencyDetails } from '../dashboard/getPropertyDetails/GetPropertyDetails.data';
import { GetUserResponse } from '../remoteSigning/user/User';

export interface GetDisclosureDetailsPayload {
  token: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export enum DisclosureFormQuestionTypes {
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  Text = 'Text',
  Attachment = 'Attachment',
}

export enum CheckboxAnswersValues {
  Off = 'Off',
  On = 'On',
}

export interface DisclosureFormQuestion {
  id: number;
  question: string;
  type: DisclosureFormQuestionTypes;
  options: string[];
  answers: string[];
  required: boolean;
  defaultAnswer?: string[] | null;
  dependencyQuestionId?: number | null;
  dependencyAnswer?: string | null;
  config: string;
  html?: boolean;
  headerHtml?: string;
  footerHtml?: string;
}
export interface DisclosureFormQuestionConfig {
  maxLength?: number | string;
}

export interface DisclosureDetailsResponse {
  questionnaire: DisclosureFormQuestion[];
  user: GetUserResponse;
  branch?: AgencyDetails;
  agency?: AgencyDetails;
  primary?: boolean;
  comments?: string;
}

interface DisclosureFormRequest {
  token: string;
  questions: DisclosureFormQuestion[];
}

export interface DisclosureSignaturePostRequest {
  signatures: Content[];
  disclosureFormRequest: DisclosureFormRequest;
  onSuccess?: () => void;
  onError?: () => void;
}
