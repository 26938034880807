import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './PercentageWheelStyles';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { theme } from '../../theme/Theme';

interface PercentageWheelProps {
  completed: number;
  parentStyles?: string;
  middleStyles?: string;
}

const PercentageWheel: FC<PercentageWheelProps> = ({ completed, parentStyles, middleStyles }) => {
  const classes = useStyles();
  const color =
    completed > 75
      ? theme.colors.secondary
      : completed > 50
      ? theme.colors.averageUsage
      : completed > 25
      ? theme.colors.yellowLabel
      : theme.colors.error;
  const slices = {
    background: `radial-gradient(circle closest-side, transparent 100%, white 0),
        conic-gradient(
        ${color} 0, ${color} ${completed}%,
        ${theme.colors.borderColor} 0, ${theme.colors.borderColor} ${100 - completed}%
      )`,
  };
  return (
    <div className={classNameGenerator([classes.wheelContainer, parentStyles])}>
      <div className={classes.pie} style={slices} />
      <div className={classNameGenerator([classes.middle, middleStyles])}>
        {completed.toFixed(0)}%
      </div>
    </div>
  );
};

export default PercentageWheel;
