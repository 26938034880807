import { TermMappingHelper } from '../../helper/TermMappingHelper';
import { Location } from 'history';
import {
  LeaseAttachment,
  LeaseUploadTemplate,
  LeaseConfigurableDocument,
  LeaseSigningDocument,
  LeaseTerms,
  GetLeaseResponse,
} from '../../services/remoteSigning/lease/Lease';
import { RemoteSigningParams } from './RemoteSigningConstants';

export enum StepType {
  PreviewDocument = 'PreviewDocument',
  DownloadDocument = 'DownloadDocument',
  Signture = 'Signature',
  Initials = 'Initials',
  Witness = 'Witness',
  PreviewFile = 'PreviewFile',
}

const acceptText = {
  withDocument: 'I accept the terms and conditions',
  withAttachments: 'Accept all documents',
};
export interface SigningSteps {
  stepNo: number;
  stepTitle: string;
  stepType: StepType;
  content: Terms[];
  footerHeight: number;
  acceptText: string;
  showCheckbox?: boolean;
  documentLink?: string | null;
}

export interface Terms {
  index: number;
  title: string;
  htmlContent?: string;
  requireContentSection?: boolean;
  link?: string;
  hideTitleContent?: boolean;
}
export class RemoteSigningHelper {
  public static setSingingsteps(leaseData: GetLeaseResponse | undefined, witnessRequired: boolean) {
    const steps: SigningSteps[] = [];
    let count = 1;
    if (!leaseData) {
      return steps;
    }

    if (leaseData.disclosureStatementResponse && leaseData.disclosureStatementResponse.preSigned) {
      steps.push({
        stepNo: count,
        stepTitle: 'Disclosure Statement',
        stepType: StepType.PreviewFile,
        content: [],
        footerHeight: 166,
        acceptText: '',
        documentLink: leaseData.disclosureStatementResponse.link,
      });
      count++;
    } else if (leaseData.leaseDisclosureStatement) {
      const content: Terms[] = leaseData.leaseDisclosureStatement.templateInfo.templatesTerms
        .sort((t1, t2) => t1.order - t2.order)
        .map((term, index) => ({
          index,
          title: term.term.title,
          htmlContent: TermMappingHelper.convertDynamicStringForDisclosure(
            term.term.htmlContent,
            leaseData.disclosureQuestionnaire,
          ),
          requireContentSection: term.term.requireContentSection,
        }));
      if (content.length) {
        steps.push({
          stepNo: count,
          stepTitle: 'Disclosure Statement',
          stepType: StepType.PreviewDocument,
          content,
          footerHeight: 166,
          acceptText: '',
          documentLink: leaseData.disclosureStatementResponse!.link,
        });
        count++;
      }
    }

    if (leaseData.leaseAgreement) {
      const content: Terms[] = [];
      let index = 1;
      leaseData.leaseAgreement.leaseAgreementTermResponse
        .sort((term1, term2) => {
          return term1.leaseTermsOrder - term2.leaseTermsOrder;
        })
        .map((leaseTerms: LeaseTerms) => {
          content.push({
            index,
            title: leaseTerms.terms.title,
            htmlContent: leaseTerms.terms.htmlContent,
            requireContentSection: leaseTerms.terms.requireContentSection,
            hideTitleContent: leaseTerms.terms.hideTitleContent,
          });
          index++;
        });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: leaseData.property!.state === 'VIC' ? 'Rental Agreement' : 'Tenancy Agreement',
          stepType: StepType.PreviewDocument,
          content,
          footerHeight: 166,
          acceptText: acceptText.withDocument,
          showCheckbox: true,
        });
        count++;
      }
    }
    if (leaseData.leaseSigningDocuments) {
      leaseData.leaseSigningDocuments.map((leaseSigningDocument: LeaseSigningDocument) => {
        const content: Terms[] = [];
        let index = 1;
        leaseSigningDocument.leaseSigningDocumentTerm
          .sort((term1, term2) => {
            return term1.leaseTermsOrder - term2.leaseTermsOrder;
          })
          .map((leaseTerms: LeaseTerms) => {
            content.push({
              index,
              title: leaseTerms.terms.title,
              htmlContent: leaseTerms.terms.htmlContent,
              requireContentSection: leaseTerms.terms.requireContentSection,
            });
            index++;
          });
        if (content.length !== 0) {
          steps.push({
            stepNo: count,
            stepTitle: leaseSigningDocument.templateInfo.title,
            stepType: StepType.PreviewDocument,
            content,
            footerHeight: 166,
            acceptText: acceptText.withDocument,
            showCheckbox: true,
          });
          count++;
        }
      });
    }
    if (leaseData.leaseConfigurableDocuments) {
      leaseData.leaseConfigurableDocuments.map(
        (leaseConfigurableDocument: LeaseConfigurableDocument) => {
          const content: Terms[] = [];
          let index = 1;
          leaseConfigurableDocument.leaseConfigurableDocumentTerm
            .sort((term1, term2) => {
              return term1.leaseTermsOrder - term2.leaseTermsOrder;
            })
            .map((leaseTerms: LeaseTerms) => {
              content.push({
                index,
                title: leaseTerms.terms.title,
                htmlContent: leaseTerms.terms.htmlContent,
                requireContentSection: leaseTerms.terms.requireContentSection,
              });
              index++;
            });
          if (content.length !== 0) {
            steps.push({
              stepNo: count,
              stepTitle: leaseConfigurableDocument.templateInfo.title,
              stepType: StepType.PreviewDocument,
              content,
              footerHeight: 166,
              acceptText: acceptText.withDocument,
              showCheckbox: true,
            });
            count++;
          }
        },
      );
    }
    if (leaseData.uploadTemplates) {
      const content: Terms[] = [];
      let index = 1;
      leaseData.uploadTemplates.map((uploadTemplate: LeaseUploadTemplate) => {
        content.push({
          index,
          title: uploadTemplate.title as string,
          link: uploadTemplate.link as string,
        });
        index++;
      });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: 'Additional documents',
          stepType: StepType.DownloadDocument,
          content,
          footerHeight: 126,
          acceptText: acceptText.withAttachments,
          showCheckbox: true,
        });
        count++;
      }
    }
    if (leaseData.pdfFormTemplates) {
      const content: Terms[] = [];
      let index = 1;
      leaseData.pdfFormTemplates.map((pdfFormTemplate: LeaseUploadTemplate) => {
        content.push({
          index,
          title: pdfFormTemplate.title as string,
          link: pdfFormTemplate.link as string,
        });
        index++;
      });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: 'Additional documents',
          stepType: StepType.DownloadDocument,
          content,
          footerHeight: 126,
          acceptText: acceptText.withAttachments,
          showCheckbox: true,
        });
        count++;
      }
    }
    if (leaseData.leaseAttachments) {
      const content: Terms[] = [];
      let index = 1;
      leaseData.leaseAttachments.map((leaseAttachment: LeaseAttachment) => {
        content.push({
          index,
          title: leaseAttachment.title as string,
          link: leaseAttachment.link as string,
        });
        index++;
      });
      if (content.length !== 0) {
        steps.push({
          stepNo: count,
          stepTitle: 'Additional documents',
          stepType: StepType.DownloadDocument,
          content,
          footerHeight: 126,
          acceptText: acceptText.withAttachments,
          showCheckbox: true,
        });
        count++;
      }
    }

    // Signature Step
    steps.push({
      stepNo: count,
      stepTitle: leaseData.property!.state === 'VIC' ? 'Rental Agreement' : 'Tenancy Agreement',
      stepType: StepType.Signture,
      content: [],
      footerHeight: 72,
      acceptText: '',
    });
    count++;
    // Intial Step
    steps.push({
      stepNo: count,
      stepTitle: leaseData.property!.state === 'VIC' ? 'Rental Agreement' : 'Tenancy Agreement',
      stepType: StepType.Initials,
      content: [],
      footerHeight: 72,
      acceptText: '',
    });
    count++;
    if (witnessRequired) {
      // witness signature
      steps.push({
        stepNo: count,
        stepTitle: leaseData.property!.state === 'VIC' ? 'Rental Agreement' : 'Tenancy Agreement',
        stepType: StepType.Witness,
        content: [],
        footerHeight: 72,
        acceptText: '',
      });
      count++;
    }

    return steps;
  }
}

export const getQueryParams = (location: Location, param: string): string => {
  const query = location.search.substring(1);
  const vars = query.split('&');
  let returnVal = '';
  vars.forEach((key: string) => {
    const pair = key.split('=');
    if (decodeURIComponent(pair[0]) === param) {
      returnVal = decodeURIComponent(pair[1]);
    }
  });
  return returnVal;
};

export const getAllQueryParams = (location: Location): RemoteSigningParams => {
  const type = getQueryParams(location, 'type');
  const source = getQueryParams(location, 'source');

  return {
    type,
    source,
  };
};
