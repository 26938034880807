import { put, takeEvery } from 'redux-saga/effects';
import {
  CreateMaintenanceRequestStartPayload,
  CreateMaintenanceRequestStartRequest,
  CreateMaintenanceRequestSuccessResponse,
} from '../../services/MaintenanceRequest/CreateMaintenanceRequest/CreateMaintenanceRequestPayload';
import maintenanceRequestService from '../../services/MaintenanceRequest/MaintenanceRequestService';
import { LoadingIndicatorActions } from '../actions/LoadingIndicatorAction';
import {
  MaintenanceRequestAction,
  MaintenanceRequestActions,
  MaintenanceRequestActionsTypes,
} from '../actions/MaintenanceRequestActions';

export function* createMaintenanceRequestWorker(
  action: MaintenanceRequestAction,
): IterableIterator<any> {
  const payloadData = action.data as CreateMaintenanceRequestStartPayload;
  try {
    yield put(LoadingIndicatorActions.show());
    const request: CreateMaintenanceRequestStartRequest = {
      ...payloadData,
      data: JSON.stringify({ ...payloadData.data, source: 'Web_Form' }),
    };
    const response = yield maintenanceRequestService.createMaintenanceRequest(request);
    yield put(
      MaintenanceRequestActions.createMaintenanceRequestSuccess(
        response as unknown as CreateMaintenanceRequestSuccessResponse,
      ),
    );
  } catch (error) {
    yield put(MaintenanceRequestActions.createMaintenanceRequestError(error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

function* maintenanceRequestWatcher() {
  yield takeEvery(
    MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_REQUEST,
    createMaintenanceRequestWorker,
  );
}

export default maintenanceRequestWatcher;
