import { Dialog, Slide, SlideProps } from '@mui/material';
import React, { FC, useEffect, useState, useMemo, forwardRef } from 'react';
import whiteSpash from '../../assets/white-splash.png';
import { useStyles } from './SidebarStyles';
import close from '../../assets/navigation/close.png';
import back from '../../assets/navigation/arrow-left.png';
import Text from '../text/Text';
import Button from '../button/Button';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface SidebarProps {
  children: any;
  headerImage?: string;
  title: string;
  subtitle: string;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  steps?: string;
  open?: boolean;
  hideClose?: boolean;
  onPrimary?: () => void;
  onSecondary?: () => void;
  onClose: () => void;
}

const Sidebar: FC<SidebarProps> = ({
  title,
  subtitle,
  open,
  children,
  headerImage,
  secondaryButtonText,
  primaryButtonText,
  steps,
  hideClose,
  onSecondary,
  onPrimary,
  onClose,
}) => {
  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Dialog
      open={!!open}
      onClose={() => (hideClose ? null : onClose())}
      fullScreen
      style={{
        zIndex: 2147483004, // Just above intercom
        marginLeft: screenWidth <= 520 + 15 ? 15 : screenWidth - 520,
      }}
      TransitionComponent={SlideLeft}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <div className={classes.headerContainer}>
        {headerImage && (
          <div className={classes.headerImageContainer}>
            <img src={headerImage} className={classes.headerImage} alt="header" />
            <img src={whiteSpash} className={classes.whiteSplash} alt="splash" />
          </div>
        )}
        {!hideClose && (
          <div className={classes.closeButtonContainer} onClick={() => onClose()}>
            <img src={close} className={classes.close} alt="close" />
          </div>
        )}
      </div>
      <div className={classes.dialogContainer}>
        {steps && (
          <div className={classes.stepperRow}>
            <div className={classes.backButtonContainer} onClick={() => onSecondary!()}>
              <img src={back} className={classes.back} alt="back" />
            </div>
            <Text textVariant="info">{steps}</Text>
          </div>
        )}
        <Text textVariant="title" size="xxl" parentStyles={classes.title}>
          {title}
        </Text>
        <Text textVariant="info" size="l" parentStyles={classes.subtitle}>
          {subtitle}
        </Text>
        <div
          className={classNameGenerator([
            classes.children,
            !(secondaryButtonText || primaryButtonText) && classes.noMaxHeight,
          ])}
        >
          {children}
        </div>
        {(secondaryButtonText || primaryButtonText) && (
          <div className={classes.buttonsContainer}>
            {secondaryButtonText && (
              <Button outline onPress={() => onSecondary!()} parentStyles={classes.button}>
                {secondaryButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <Button onPress={() => onPrimary!()} parentStyles={classes.button}>
                {primaryButtonText}
              </Button>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

// For the animation
const SlideLeft = forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default Sidebar;
