/* eslint-disable import/prefer-default-export */
import { RemoteSigningState } from '../state/RemoteSigningState';
import { RemoteSigningAction, RemoteSigningActionTypes } from '../actions/RemoteSigningActions';
import {
  RemoteSigningPostResponse,
  GetRemoteSigningPayload,
  RemoteSigningGetResponse,
  GuestAuthDetailsResponse,
} from '../../services/remoteSigning/RemoteSigning';
import { MOCK_LANDLORD_RDS } from './mockLandlordRDS';
import LogRocket from 'logrocket';

export const initialState: RemoteSigningState = {
  token: '',
  signingData: undefined,
  usersSignatureList: undefined,
  guestAuthDetails: undefined,
};

export const remoteSigningReducer = (state = initialState, action: RemoteSigningAction) => {
  switch (action.type) {
    case RemoteSigningActionTypes.GET_REMOTE_SIGNING_REQUEST:
      return {
        ...state,
        token: (action.data as GetRemoteSigningPayload).token,
      };
    case RemoteSigningActionTypes.GET_REMOTE_SIGNING_SUCCESS:
      LogRocket.identify((action.data as RemoteSigningGetResponse).user.email, {
        email: (action.data as RemoteSigningGetResponse).user.email,
      });

      return {
        ...state,
        signingData: action.data as RemoteSigningGetResponse,
      };

    case RemoteSigningActionTypes.POST_SIGN_SUCCESS:
      return {
        ...state,
        usersSignatureList: action.data as RemoteSigningPostResponse,
      };

    case RemoteSigningActionTypes.GET_GUEST_AUTH_DETAILS_SUCCESS:
      return {
        ...state,
        guestAuthDetails: action.data as GuestAuthDetailsResponse,
      };
    default:
      return state;
  }
};
