import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  headerContainer: {
    position: 'relative',
    height: 86,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.borderColorLight}`,
    paddingLeft: 96,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      paddingLeft: 48,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: 12,
    },
  },
  sortedLogoContainer: {
    flexShrink: 0,
    position: 'relative',
    marginRight: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: '16px',
    },
    transition: 'all 0.5s ease 0s',
  },
  sortedLogo: {
    position: 'relative',
    height: '30px',
    left: 0,
    transition: 'all 0.5s ease 0s',
  },
  poweredBySortedLogo: {
    height: '26px',
    left: 76,
    transition: 'all 0.5s ease 0s',
  },
  sortedLogoText: {
    position: 'absolute',
    opacity: 0,
    top: 6,
    bottom: 0,
    left: 0,
    margin: 'auto',
    height: '9px',
    transition: 'all 0.5s ease 0s',
  },
  poweredByLogoText: {
    transition: 'all 0.5s ease 0s',
    // left: -76,
    opacity: 1,
  },
  divider: {
    height: 27,
    width: 2,
    backgroundColor: theme.colors.borderColorLight,
    flexShrink: 0,
  },
  profileContainer: {
    paddingLeft: theme.units.spacing * 4,
    paddingRight: theme.units.spacing * 6,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 'calc(100% - 3px)',
    borderBottom: `${theme.colors.light} 3px solid`,
  },
  profileIcon: {
    height: theme.units.iconSize,
    width: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
  },
  profileText: {
    color: theme.colors.black87,
    fontSize: theme.font.l,
    fontWeight: getFonts('Medium'),
    marginLeft: theme.units.spacing * 2,
  },
  selected: {
    borderBottom: `${theme.colors.error} 3px solid`,
  },
  menu: {
    position: 'absolute',
    top: 104,
    right: 16,
    width: 200,
    minWidth: 'max-content',
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    backgroundColor: theme.colors.inputBackground,
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColorLight} 2px solid`,
    zIndex: 10,
  },
  menuDivider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.borderColorLight,
    marginTop: theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 2,
  },
  menuItem: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    padding: theme.units.spacing * 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightGrey,
    },
  },
  menuItemLight: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Light'),
    color: theme.colors.inputText,
    padding: theme.units.spacing * 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightGrey,
    },
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    height: '100%',
  },
});
