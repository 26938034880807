import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  resetPasswordConfirmationContainer: {
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '0 16px',
      backgroundColor: theme.colors.light,
      // height: 'calc(100vh - 48px)',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      padding: '0 133px',
      backgroundColor: theme.colors.light,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  successLogo: {
    width: '72px',
    height: '82px',
    objectFit: 'contain',
    paddingTop: '111px',
    paddingBottom: '60px',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      padding: '58px 0 50px',
      textAlign: 'center',
      width: '50%',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      textAlign: 'center',
      position: 'absolute',
      left: '16px',
      right: '16px',
      bottom: 0,
    },
  },
  loginButton: {
    height: '48px',
    margin: '20px 0px',
    backgroundColor: theme.colors.sortedPurple,
  },
  loginLabel: {
    color: theme.colors.light,
  },
});
