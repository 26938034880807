import assignmentIcon from '../../../../assets/assignment.png';
import gestureIcon from '../../../../assets/gesture.png';
import personIcon from '../../../../assets/person.png';
import sortedIcon from '../../../../assets/sorted-logomark.png';
import phoneIcon from '../../../../assets/smartphone.png';

export interface Step {
  stepTitle: string;
  stepSubtitle: string;
  stepIcon: string;
}

export const RenewStep = 'Check out available services and updated documents in the app';

export const SigningStepsWithWitness: Step[] = [
  {
    stepTitle: 'Step 1',
    stepSubtitle: 'Read and accept your agreement terms',
    stepIcon: assignmentIcon,
  },
  {
    stepTitle: 'Step 2',
    stepSubtitle: 'Sign and initial in the box provided (Witness required)',
    stepIcon: gestureIcon,
  },
  {
    stepTitle: 'Step 3',
    stepSubtitle: 'As part of state legislation your witness will also need to sign ',
    stepIcon: personIcon,
  },
  {
    stepTitle: 'Step 4',
    stepSubtitle: 'Set up your payment details and services',
    stepIcon: sortedIcon,
  },
];

export const SigningStepsWithoutWitness: Step[] = [
  {
    stepTitle: 'Step 1',
    stepSubtitle: 'Read and accept your agreement terms',
    stepIcon: assignmentIcon,
  },
  {
    stepTitle: 'Step 2',
    stepSubtitle: 'Sign and initial in the box provided',
    stepIcon: gestureIcon,
  },
  {
    stepTitle: 'Step 3',
    stepSubtitle: 'Set up your payment details and services',
    stepIcon: sortedIcon,
  },
];

export const NaxSigningStepsWithoutWitness: Step[] = [
  {
    stepTitle: 'Step 1',
    stepSubtitle: 'Read and accept your agreement terms',
    stepIcon: assignmentIcon,
  },
  {
    stepTitle: 'Step 2',
    stepSubtitle: 'Sign and initial in the box provided',
    stepIcon: gestureIcon,
  },
  {
    stepTitle: 'Step 3',
    stepSubtitle: 'Log in to the Nelson Alexander app and set up your payment details',
    stepIcon: phoneIcon,
  },
];

export const NaxSigningStepsWithWitness: Step[] = [
  {
    stepTitle: 'Step 1',
    stepSubtitle: 'Read and accept your agreement terms',
    stepIcon: assignmentIcon,
  },
  {
    stepTitle: 'Step 2',
    stepSubtitle: 'Sign and initial in the box provided (Witness required)',
    stepIcon: gestureIcon,
  },
  {
    stepTitle: 'Step 3',
    stepSubtitle: 'As part of state legislation your witness will also need to sign ',
    stepIcon: personIcon,
  },
  {
    stepTitle: 'Step 4',
    stepSubtitle: 'Log in to the Nelson Alexander app and set up your payment details',
    stepIcon: phoneIcon,
  },
];

export const LandlordSteps: Step[] = [
  {
    stepTitle: 'Step 1',
    stepSubtitle: 'Read and accept your agreement terms',
    stepIcon: assignmentIcon,
  },
  {
    stepTitle: 'Step 2',
    stepSubtitle: 'Sign and initial in the box provided',
    stepIcon: gestureIcon,
  },
  // {
  //   stepTitle: 'Step 3',
  //   stepSubtitle: 'Log in to the App and set up your property',
  //   stepIcon: phoneIcon,
  // },
];

export const LABELS = {
  CONGRATZ: (name: string) => `Congrats ${name},`,
  CONGRATZ_SUBTITLE: 'your application has been accepted.',
  CONGRATZ_RENEW: 'time to sign your renewal.',
  WELCOME_SUBTITLE: 'Invites you to sign your tenancy agreement',
  LANDLORD_SUBTITLE: 'Invites you to sign your property management agreement for',
  REVIEW: 'Review & sign your tenancy agreement',
  REVIEW_LL: 'Review & sign your agreement terms',
  READY: 'Ready',
  PENDING: 'Pending',
  SETUP_RENT: 'Setup rent payments',
  GET_MOVE_IN_READY: 'Get move in ready',
  GET_STARTED: 'Get started',
  ORGANISE: 'Organise and connect your utilities',
  QUESTIONS: "Questions? We've got you sorted.",
  QUESTIONS_SUBTITLE: "Not sure what's next? Don't worry, we'll give you a call soon.",
  FREQUENTLY: 'Frequently asked questions',
  YOUR_PROPERTY: 'Your property manager',
  CANT_FIND: "Can't find the answer?",
  CONTACT: 'Contact support',
  PHONE: 'Phone',
  EMAIL: 'Email',
  PROPERTY_MANAGER: 'Property manager',
};

export const FAQ_QUESTIONS = [
  {
    question: 'I need to change my rent payment start date or frequency, who do I contact?',
    description:
      'Your property manager is able to change this date. Contact them using the details on the right.',
  },
  {
    question: 'A group member can’t sign before the due date. What can I do?',
    description:
      'All group members need to sign before the due date. If you need the date extended, contact your property manager with the details on the right.',
  },
  {
    question: 'How do I pay my bond?',
    description:
      'Please reach out to your property manager with specific questions about bond payments.',
  },
  {
    question: 'What do I need to do to set up my home?',
    description:
      'Great question! You’ll need to connect your electricity, gas and broadband if you want it. We’ll let you know if one of these networks is embedded, if this is the case, speak to your property manager to find out who you need to contact.',
  },
  {
    question: 'I want to change my rent payment details.',
    description:
      'If you have set up your rent payments via. the app, you can easily edit these details there. Otherwise, please reach out to your property manager. ',
  },
  {
    question: 'When do I need to have my services set up?',
    description:
      'Electricity and gas connections need to be processed 2 business days before your move to be connected without an urgent connection charge. Your broadband may be able to be connected on the same day. ',
  },
];
