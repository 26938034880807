/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { get, isEqual } from 'lodash';

import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Button from '../../../../component/button/Button';
import Input from '../../../../component/input/Input';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import ResendEmailErrorModal from '../../../../component/referenceToolScreens/resendErrorModal/ResendEmailErrorModal';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { TypeHelper } from '../../../../helper/TypeHelper';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import {
  ReferenceToolActions,
  ReferenceToolActionTypes,
} from '../../../../store/actions/ReferenceToolActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import {
  PostFormData,
  PostReferencesFormData,
  Reference,
  Relationship,
} from '../../../../store/state/MyProfileFormState';
import { LABELS, SubmissionStatus, VALIDATION_SCHEMA } from './ReferencesFormConstants';
import { useStyles } from './ReferencesFormStyles';
import Text from '../../../../component/text/Text';

interface ReferencesFormProps {
  referencesDetails: PostReferencesFormData | undefined;
  index: number | undefined;
  resendEmailError: Error | string;
  updateReferencesChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postReferenceFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
  resendEmail: (data: number) => void;
}

const ReferencesForm: FC<ReferencesFormProps> = ({
  referencesDetails,
  index,
  resendEmailError,
  updateReferencesChangesFlag,
  setSelected,
  setChildFormRef,
  postReferenceFormDetails,
  resendEmail,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostReferencesFormData> | null>(null);
  const initialIsValidNumber = {};
  const initialGetPhoneDigit = {};
  if (referencesDetails && referencesDetails.references) {
    referencesDetails.references.map((obj, idx) => {
      initialIsValidNumber[idx] = TypeHelper.validatePhone(obj.mobile as string);
      initialGetPhoneDigit[idx] = obj.mobile;
    });
  }
  const [isValidNumber, setIsValidNumber] = useState<{ [key: string]: string }>(
    initialIsValidNumber,
  );
  const [getPhoneDigit, setGetPhoneDigit] = useState<{ [key: string]: string }>(
    initialGetPhoneDigit,
  );
  const hasVisited = !!referencesDetails && referencesDetails.addReference;
  const [profileChanged, setProfileChanged] = useState<boolean>(false);

  const updateReferencesState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateReferencesChangesFlag(true);
      setProfileChanged(true);
    }
  };

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  const clearLastReference = (
    setFieldValue: (fieldName: string, fieldValue: string | boolean) => void,
    idx: number,
  ) => {
    setFieldValue(`references[${idx}].name`, '');
    setFieldValue(`references[${idx}].relationship`, '');
    setFieldValue(`references[${idx}].mobile`, '');
    setFieldValue(`references[${idx}].email`, '');
    setFieldValue(`references[${idx}].applyReferenceId`, '');
    setFieldValue(`references[${idx}].isDisabled`, false);
    setFieldValue(`references[${idx}].submissionStatus`, '');
  };

  const toggleForm = (
    values: PostReferencesFormData,
    setFieldValue: (fieldName: string, fieldValue: string | boolean | null) => void,
  ) => {
    setFieldValue('addReference', !values.addReference);
    updateReferencesState();
    if (values.addReference) {
      values.references.map((reference: Reference, idx: number) => {
        setFieldValue(`references[${idx}].name`, null);
        setFieldValue(`references[${idx}].relationship`, null);
        setFieldValue(`references[${idx}].mobile`, null);
        setFieldValue(`references[${idx}].email`, null);
        setFieldValue(`references[${idx}].applyReferenceId`, null);
        setFieldValue(`references[${idx}].isDisabled`, false);
        setFieldValue(`references[${idx}].submissionStatus`, null);
        setFieldValue(`references[${idx}].mailSent`, false);
      });
    }
  };

  const newReference = {
    name: null,
    relationship: null,
    mobile: null,
    email: null,
    applyReferenceId: null,
    isDisabled: false,
    submissionStatus: null,
    mailSent: false,
    rank: null,
  };

  const initialValues: PostReferencesFormData =
    referencesDetails && referencesDetails.references.length
      ? referencesDetails
      : {
          references: [newReference],
          addReference: false,
        };

  return (
    <div className={classes.root}>
      {!!resendEmailError && <ResendEmailErrorModal error={resendEmailError} />}
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(onSubmitdata) => {
          onSubmitdata.references.forEach((element: any, i: number) => {
            if (
              !TypeHelper.isNullOrUndefined(onSubmitdata.references[i]) &&
              !TypeHelper.isNullOrUndefined(getPhoneDigit[i]) &&
              onSubmitdata.references[i].mobile !== null &&
              onSubmitdata.references[i].mobile !== ''
            ) {
              // eslint-disable-next-line no-param-reassign
              onSubmitdata.references[i].mobile = getPhoneDigit[i];
            }
          });
          onSubmitdata.references.forEach((reference, idx) => {
            if (!isEqual(initialValues.references[idx], reference)) {
              reference.applyReferenceId = null;
            }
            reference.rank = idx;
          });
          updateReferencesState();
          postReferenceFormDetails(
            onSubmitdata,
            () => {
              setSelected(ProfileForm.IDENTIFICATION_FORM);
            },
            index,
          );
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => (
          <div className={classes.formStyle}>
            <div className={classes.questionContainer}>
              <Typography variant="subtitle1">{LABELS.ADD_REFERENCE}</Typography>
              <ToggleButton
                toggleFunction={() => toggleForm(values, setFieldValue)}
                buttonState={values.addReference}
              />
            </div>
            {values.addReference &&
              values.references.map((value, idx) => (
                <div className={values.addReference ? classes.extrabox : classes.hide}>
                  <div className={classes.inputBoxStyle}>
                    <Text textVariant="boxTitle" parentStyles={classes.sectionTitle}>
                      {`${LABELS.REFERENCE} ${idx + 1}`}
                    </Text>
                    <div className={classes.submitStatusStyle}>
                      {value.mailSent &&
                        value.submissionStatus &&
                        value.submissionStatus.length !== 0 &&
                        (value.submissionStatus.toLowerCase() === SubmissionStatus.SUBMITTED ? (
                          <Typography className={classes.submittedLebelStyle}>
                            {LABELS.REFERENCE_ATTACHED}
                          </Typography>
                        ) : (
                          <Typography className={classes.pendingLebelStyle}>
                            {LABELS.NO_RESPONSE_GIVEN}
                          </Typography>
                        ))}
                      {idx >= 0 && (
                        <Button
                          outline
                          parentStyles={classes.removeButton}
                          onPress={() => {
                            if (values.references.length === 1) {
                              clearLastReference(setFieldValue, idx);
                            } else {
                              delete getPhoneDigit[idx];
                              delete isValidNumber[idx];
                              values.references.splice(idx, 1);
                              setFieldValue('references', values.references);
                            }
                            updateReferencesState();
                          }}
                        >
                          {LABELS.REMOVE}
                        </Button>
                      )}
                    </div>
                  </div>

                  {/* NAME ----------------------------------------------------------------------- // */}
                  <Input
                    placeholder={LABELS.NAME}
                    title={LABELS.NAME}
                    value={values.references[idx].name || ''}
                    setValue={(value: string) => {
                      setFieldValue(`references[${idx}].name`, value);
                      updateReferencesState();
                    }}
                    onBlur={() => setFieldTouched(`references[${idx}].name`)}
                    touched={get(touched, `references[${idx}]name`)}
                    error={get(errors, `references[${idx}]name`)}
                    showRequiredError={get(touched, `references[${idx}]name`) || hasVisited}
                    disabled={values.references[idx].isDisabled}
                  />

                  {/* RELATIONSHIP ----------------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.RELATIONSHIP}
                    title={LABELS.RELATIONSHIP}
                    value={values.references[idx].relationship || ''}
                    values={Object.values(Relationship).map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`references[${idx}].relationship`, value);
                      updateReferencesState();
                    }}
                    onBlur={() => setFieldTouched(`references[${idx}].relationship`)}
                    touched={get(touched, `references[${idx}]relationship`)}
                    error={get(errors, `references[${idx}]relationship`)}
                    showRequiredError={get(touched, `references[${idx}]relationship`) || hasVisited}
                    disabled={values.references[idx].isDisabled}
                  />

                  {/* MOBILE ----------------------------------------------------------------------- // */}
                  <InputMobile
                    placeholder={LABELS.MOBILE}
                    value={values.references[idx].mobile || ''}
                    setValue={(value: string) => {
                      const isValidPhone = !!(value && TypeHelper.validatePhone(value));
                      setFieldValue(`references[${idx}].mobile`, value);
                      setIsValidNumber({
                        ...isValidNumber,
                        ...{ [idx]: isValidPhone },
                      });
                      setGetPhoneDigit({
                        ...getPhoneDigit,
                        ...{ [idx]: value },
                      });

                      updateReferencesState();
                    }}
                    onBlur={() => setFieldTouched(`references[${idx}].mobile`)}
                    touched={get(touched, `references[${idx}]mobile`)}
                    error={
                      !TypeHelper.isNullOrUndefined(values.references[idx].mobile) &&
                      !TypeHelper.validatePhone(values.references[idx].mobile as string)
                        ? 'Invalid phone number'
                        : get(errors, `references[${idx}]mobile`)
                    }
                    title={LABELS.MOBILE}
                    disabled={values.references[idx].isDisabled}
                    showRequiredError={get(touched, `references[${idx}]mobile`) || hasVisited}
                  />

                  {/* EMAIL ----------------------------------------------------------------------- // */}
                  <Input
                    placeholder={LABELS.EMAIL}
                    title={LABELS.EMAIL}
                    value={values.references[idx].email || ''}
                    setValue={(value: string) => {
                      setFieldValue(`references[${idx}].email`, value);
                      updateReferencesState();
                    }}
                    onBlur={() => setFieldTouched(`references[${idx}].email`)}
                    touched={get(touched, `references[${idx}]email`)}
                    error={get(errors, `references[${idx}]email`)}
                    showRequiredError={get(touched, `references[${idx}]email`) || hasVisited}
                    disabled={values.references[idx].isDisabled}
                  />

                  {/* warning text will be shown till the time mail is not sent to the referee */}
                  {!value.mailSent && (
                    <Typography className={classes.warningTextStyle}>
                      {LABELS.WARNING_TEXT}
                    </Typography>
                  )}

                  {value.mailSent &&
                    value.submissionStatus &&
                    value.submissionStatus.length !== 0 &&
                    (value.submissionStatus.toLowerCase() === SubmissionStatus.SUBMITTED ? (
                      <Typography className={classes.submittedLebelStyle}>
                        {LABELS.REFERENCE_ATTACHED_MESSAGE}
                      </Typography>
                    ) : (
                      <>
                        <Typography className={classes.pendingLebelStyle}>
                          {LABELS.NO_RESPONSE_GIVEN_MESSAGE}
                        </Typography>
                        <Button
                          parentStyles={classes.resendEmailBtnStyle}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          onPress={() => resendEmail(Number(value.applyReferenceId!))}
                        >
                          Resend request
                        </Button>
                      </>
                    ))}
                </div>
              ))}
            {values.addReference && (
              <Button
                parentStyles={classes.addReferenceButton}
                onPress={() => {
                  const result = values.references;
                  result.push(newReference);
                  setFieldValue('references', result);
                }}
              >
                {LABELS.ADD_ANOTHER_REFERENCE}
              </Button>
            )}

            <Typography className={classes.addReferenceDescription}>
              {LABELS.ADD_ANOTHER_REFERENCE_DESCRIPTON}
            </Typography>
            <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
              {LABELS.BUTTON_TEXT}
            </Button>
          </div>
        )}
      />
    </div>
  );
};

const error = errorMessageSelector([ReferenceToolActionTypes.RESEND_EMAIL]);

const mapStateToProps = (state: ApplicationState) => ({
  resendEmailError: error(state),
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  updateReferencesChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateReferencesChangesFlag(isChanged));
  },
  resendEmail: (data: number) => dispatch(ReferenceToolActions.resendEmail(data)),
  postReferenceFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postReferencesFormDetails(data, id))
      : dispatch(MyProfileAction.postReferencesFormDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesForm);
