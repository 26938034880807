/* eslint-disable import/prefer-default-export */
import standout from '../../assets/articles/thankyou-image.jpg';

export const LABELS = {
  SELECT_STATE: 'Select the state/territory where you are looking for your next home',
  TITLE: "You're getting things sorted,",
  ABOUT: 'About you',
  FROM_THE_BLOG: 'From the blog',
  BACK: 'Back',
  ADDITIONAL_PROFILE: 'Managed Profile',
};

export const STATES = ['ACT', 'NSW', 'SA', 'TAS', 'QLD', 'VIC', 'WA', 'NT'];

export const BLOGS = [
  {
    title: 'How to stand out',
    body: '10 ways to make your rental application stand out ...',
    link: 'https://www.sortedservices.com/blog-spot/application-stand-out-tips',
    image: standout,
  },
  {
    title: 'Have my referees responded?',
    body: 'You can see if they have. Go to the references section of your profile and a status will show',
  },
];
