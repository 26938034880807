import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helper/GetFonts';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  formContainer: {
    // padding: 12,
    // marginBottom: 24,
    // paddingBottom: 24,
    // border: `${theme.colors.borderColor} 2px solid`,
    // borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  body: {
    padding: '12px',
  },
  titleStyle: {
    marginTop: 65,
    marginBottom: '4vh',
  },
  cardNumberInputStyle: {
    flexGrow: 1,
    marginTop: 20,
  },
  inputBoxStyle: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.units.spacing * 4,
  },
  expiryInputStyle: {
    flex: 1,
    marginRight: 10,
  },
  cvvInputStyle: {
    flex: 1,
    marginLeft: 10,
  },
  errorMessage: {
    color: 'red',
  },
  secureBoxStyle: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    marginVertical: 20,
  },
  secureLockStyle: {
    marginRight: 16,
  },
  buttonWrapper: {
    marginTop: 'auto',
    width: '100%',
    maxWidth: '1000px',
  },
  buttonContainer: {
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  primaryButton: {},
  secondaryButton: {
    marginRight: theme.units.spacing * 4,
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
    border: `#ededed 2px solid`,
  },
  errorButton: {
    border: `${theme.colors.error} 2px solid`,
    backgroundColor: theme.colors.error,
    color: theme.colors.light,
  },
  errorText: {
    marginBottom: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
    color: theme.colors.error,
    fontWeight: getFonts('Medium'),
    textAlign: 'center',
  },
});
