// 'name': unique name of field
// 'disableValidateOn': name of field on which validation will enable
// 'type': Html control type => ex. 'textarea',
// 'label': label
// 'options': for  select,multi select checkbox,radio select button pass value in value, label pair else undefined ,
export const configData = {
  maintenance: [
    {
      'name': 'areYouAwareOfAnyDangerousSubstance',
      'type': 'radioSelectbutton',
      'label': 'Are you aware of any dangerous present in the property now or in the past?',
      'options': [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    {
      'name': 'descriptionOfDangerousSubstances',
      'disableValidateOn': 'areYouAwareOfAnyDangerousSubstance',
      'type': 'textarea',
      'label': 'if yes,please describe the upgrades',
      'options': undefined,
    },
    {
      'name': 'onGoingMaintenanceAck',
      'type': 'multichoiceCheckbox',
      'label': 'Ongoing maintenance acknowledgements',
      'options': [
        {
          value: '1',
          label:
            'As per the Standard Residential Tenancy Terms, I am aware tenants can spend up to 5% of the annual rent for urgent repairs',
        },
        {
          value: '2',
          label:
            'For any urgent maintenance, I authorise you to spend up to $400 and to notify me of the action taken',
        },
        {
          value: '3',
          label:
            'As per the Standard Residential Tenancy Terms, I agree to pay the initial installment cost for NBN/ telephone connection',
        },
        {
          value: '4',
          label:
            'I will provide 3 sets of keys including remotes/swipes. If I cannot provide 3 full sets, I give you permission to order additional keys/remotes/swipes at my expense.',
        },
      ],
    },
    {
      'name': 'haveAnyPreferredTrades',
      'type': 'radioSelectbutton',
      'label': 'Do you have any preferred trades you would like us to deal with?',
      'options': [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  ],
  conditionDetails: [
    {
      'name': 'preSettlementSelect',
      'type': 'select',
      'label': 'Pre settlement inspection',
      'options': [
        {
          value: '1',
          label:
            'I understand it is my responsibility to conduct the pre settlement inspection with the builder',
        },
        {
          value: '2',
          label:
            'I would like you to do this on my behalf, and I understand there is a one off cost for this service',
        },
      ],
    },
    {
      'name': 'isPreSettlementInspection',
      'type': 'checkbox',
      'label':
        'I acknowledge that whilst you will take care in completing the inspection you accept no liability for acts or omissions on your part in overlooking items during the inspection, except to the extent that you are negligent in performing your duties.',
    },
    {
      'name': 'nintyDayMaintenanceSelect',
      'type': 'select',
      'label': '90 day maintenance inspection',
      'options': [
        {
          value: '1',
          label:
            'I understand it is my responsibility to conduct the 90 day maintenance inspection with the builder',
        },
        {
          value: '2',
          label:
            'I would like you to do this on my behalf, and I understand there is a one off cost for this service',
        },
      ],
    },
    {
      'name': 'is90DaysMaintenanceInspection',
      'type': 'checkbox',
      'label':
        'I acknowledge that whilst you will take care in completing the inspection you accept no liability for acts or omissions on your part in overlooking items during the inspection, except to the extent that you are negligent in performing your duties.',
    },
  ],
  dispursments: [
    {
      'name': 'dispursmentFrequency',
      'type': 'select',
      'label': 'Disbursement frequency',
      'options': [
        {
          value: '1',
          label: 'Monthly (payments will be disbursed on the last business day each month)',
        },
        {
          value: '2',
          label:
            'Mid and end of month (can only be arranged if we do not pay bills on your behalf)',
        },
        {
          value: '3',
          label:
            'Mid-month (payments will be disbursed as close as possible to the 15th of each month)',
        },
      ],
    },
    {
      'name': 'approvedAnnualDispursments',
      'type': 'multichoiceCheckbox',
      'label': 'Approved annual dispursments (check all that apply)',
      'options': [
        {
          value: '1',
          label: 'Smoke alarm compliance',
        },
        {
          value: '2',
          label: 'Landlord insurance',
        },
      ],
    },
    {
      'name': 'approvedQuarterlyDispursments',
      'type': 'multichoiceCheckbox',
      'label': 'Approved quaterly dispursments (check all that apply)',
      'options': [
        {
          value: '1',
          label: 'Strata levies',
        },
        {
          value: '2',
          label: 'General rates',
        },
        {
          value: '3',
          label: 'Land tax',
        },
        {
          value: '4',
          label: 'Water rates',
        },
      ],
    },
    {
      'name': 'waterConsumptionCharge',
      'type': 'inputnumber',
      'label': 'Water consumption chagre',
    },
  ],
  tenancyDetails: [
    {
      'name': 'preferredBreakLease',
      'type': 'select',
      'label': 'If my tenant breaches their agreement, my preferred break lease approach is:',
      'options': [
        {
          value: '1',
          label:
            'Request the tenant continue to make rent payments in full until either a new tenant is found or the fixed term tenancy ends (whichever comes first), plus charge the tenant an additional week of rent to cover the advertising and re-letting fees',
        },
        {
          value: '2',
          label:
            'Request the tenant to pay a maximum of six weeks’ rent if the breach occurs within the first half of the fixed term, or a maximum of four weeks’ rent if the breach occurs within the second half of the fixed term',
        },
      ],
    },
    {
      'name': 'isRequirePostingClause',
      'type': 'radioSelectbutton',
      'label': 'Do you require a posting clause?',
      'options': [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    {
      'name': 'isRequireExclusion',
      'type': 'radioSelectbutton',
      'label':
        'Do you require an exclusion clause as parts of the property/inclusions are not be included in a tenancy?',
      'options': [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    {
      'name': 'exclusionClause',
      'type': 'textarea',
      'label': 'Exclusion clause',
      'disableValidateOn': 'isRequireExclusion',
      'options': undefined,
    },
    {
      'name': 'acatRepresentation',
      'type': 'select',
      'label': 'ACAT representation',
      'options': [
        {
          value: '1',
          label: 'I am happy for you to represent me at ACAT',
        },
      ],
    },
    {
      'name': 'ackACAT',
      'type': 'checkbox',
      'label':
        'I acknowledge that if I choose not to sign the power of attorney for ACAT representation I understand that I cannot hold you liable if these legislated timeframes are missed.',
    },
    {
      'name': 'isRequireSpecialTerms',
      'type': 'radioSelectbutton',
      'label': 'Do you require special terms/endorsments from ACAT?',
      'options': [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    {
      'name': 'specialTerms',
      'type': 'textarea',
      'label': 'Special Terms',
      'disableValidateOn': 'isRequireSpecialTerms',
      'options': undefined,
    },
    {
      'name': 'doYouHaveACurrentTenantInPlace',
      'type': 'radioSelectbutton',
      'label': 'Is the property currently occupied',
      'options': [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  ],
};
