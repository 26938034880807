import { ServiceType } from '../ServiceType.data';
import ApiService from '../TenancyApiService';
import ApiServiceBase from '../TenancyApiServiceBase';
import {
  RemoteSigningPostResponse,
  RemoteSigningPostRequest,
  RemoteSigningGetResponse,
  MeritonServiceCallbackRequest,
  GuestAuthDetailsRequest,
  GuestAuthDetailsResponse,
} from './RemoteSigning';
import { MultipartFormData } from '../MultipartFormData.data';
import { Content } from '../../helper/Content/Content.data';

export class RemoteSigningService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.RemoteSigning);
  private readonly serviceCallback: ApiServiceBase = new ApiService(ServiceType.Callback);
  private readonly RDS: ApiServiceBase = new ApiService(ServiceType.RDS);

  public get(
    token: string,
    type?: string,
    source?: string,
  ): Promise<RemoteSigningGetResponse> | RemoteSigningGetResponse {
    const route: any = ['details', token];
    const query = {};

    return this.service.get<RemoteSigningGetResponse>({
      route,
      query: {
        ...(type ? { ...query, type } : { ...query }),
        ...(source ? { ...query, source } : { ...query }),
      },
    });
  }

  public post(
    request: RemoteSigningPostRequest,
    type: string | undefined,
  ): Promise<RemoteSigningPostResponse> | RemoteSigningPostResponse {
    const route: any = ['signatures'];
    const signatures: MultipartFormData[] = [
      {
        name: 'remoteSignRequest',
        content: JSON.stringify(request.remoteSignRequest),
      },
    ];

    if (request.paymentDetails) {
      signatures.push({
        name: 'paymentDetails',
        content: JSON.stringify(request.paymentDetails),
      });
    }

    if (request.rentSplitAmount) {
      signatures.push({
        name: 'rentSplitAmount',
        content: JSON.stringify(request.rentSplitAmount),
      });
    }

    request.signatures.map((signature: Content) =>
      signatures.push({
        name: 'signatures',
        content: signature,
      }),
    );
    if (type) {
      return this.service.postMultipartData<RemoteSigningPostResponse>(
        { route, query: { type } },
        signatures,
      );
    } else {
      return this.service.postMultipartData<RemoteSigningPostResponse>({ route }, signatures);
    }
  }

  public postMeritonServiceCallback(payload: MeritonServiceCallbackRequest): Promise<void> | void {
    return this.serviceCallback.post(
      {
        route: ['meriton', 'service-callback'],
      },
      payload,
    );
  }

  public getGuestToken(
    data: GuestAuthDetailsRequest,
  ): Promise<GuestAuthDetailsResponse> | GuestAuthDetailsResponse {
    return this.RDS.get<GuestAuthDetailsResponse>({
      query: { token: data.rdsToken },
    });
  }
}

const remoteSigningService = new RemoteSigningService();
export default remoteSigningService;
