export const LABELS = {
  ALL_DONE_TITLE: 'Nice work!',
  LEASE_SIGNED: (agencyName?: string) => `Thank you for signing with ${agencyName}`,
  ONCE: 'Once everyone else on the lease has signed, you’ll receive a copy of your documents (they’ll also always be available in the Sorted app)',
  SETTING: 'Setting up your new home with Sorted',
  STEP_1: 'Set up your rent payments',
  STEP_2: 'Book a mover',
  STEP_3: 'Sort your electricity, gas, internet, insurance and more',
  STEP_4: 'Easily organise maintenance & book tradies',
  STEP_5: 'Pay and manage all your bills',
  DOWNLOAD: 'Thanks for using Sorted!',
  DOWNLOAD_TEXT: (App: string) =>
    `Download the ${App} app to set up and manage services for your new home.`,
};

export const links = {
  appleStore:
    'https://apps.apple.com/app/apple-store/id1232960956?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreNAX:
    'https://apps.apple.com/app/apple-store/id1489135381?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreTA:
    'https://apps.apple.com/app/apple-store/id1520540329?pt=118629506&ct=post-digital-signing&mt=8',
  appleStoreSwitch: 'https://apps.apple.com/us/app/the-switch/id6447742526',
  playstore:
    'https://play.google.com/store/apps/details?id=com.sortedservices.sorted&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreNAX:
    'https://play.google.com/store/apps/details?id=au.com.nelsonalexander.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreTA:
    'https://play.google.com/store/apps/details?id=au.com.theagency.app&referrer=utm_source%3Dpost-digital-signing%26anid%3Dadmob',
  playstoreSwitch: 'https://play.google.com/store/apps/details?id=com.theswitch.app',
};
