import {
  GetDisclosureDetailsPayload,
  DisclosureDetailsResponse,
  DisclosureSignaturePostRequest,
} from '../../services/disclosure/Disclosure';
import { StoreAction } from '../StoreHelper';

export enum DisclosureActionTypes {
  GET_DISCLOSURE_DETAILS = 'GET_DISCLOSURE_DETAILS',
  GET_DISCLOSURE_DETAILS_REQUEST = 'GET_DISCLOSURE_DETAILS_REQUEST',
  GET_DISCLOSURE_DETAILS_SUCCESS = 'GET_DISCLOSURE_DETAILS_SUCCESS',
  GET_DISCLOSURE_DETAILS_ERROR = 'GET_DISCLOSURE_DETAILS_ERROR',
  POST_DISCLOSURE_SIGNATURE = 'POST_DISCLOSURE_SIGNATURE',
  POST_DISCLOSURE_SIGNATURE_REQUEST = 'POST_DISCLOSURE_SIGNATURE_REQUEST',
  POST_DISCLOSURE_SIGNATURE_SUCCESS = 'POST_DISCLOSURE_SIGNATURE_SUCCESS',
  POST_DISCLOSURE_SIGNATURE_ERROR = 'POST_DISCLOSURE_SIGNATURE_ERROR',
}

export type DisclosureActionPayload =
  | GetDisclosureDetailsPayload
  | DisclosureDetailsResponse
  | DisclosureSignaturePostRequest
  | undefined
  | Error
  | null;

export type DisclosureAction = StoreAction<DisclosureActionTypes, DisclosureActionPayload>;

export class DisclosureActions {
  public static getDisclosureDetailsStart(data: GetDisclosureDetailsPayload): DisclosureAction {
    return { type: DisclosureActionTypes.GET_DISCLOSURE_DETAILS_REQUEST, data };
  }

  public static getDisclosureDetailsSuccess(data: DisclosureDetailsResponse): DisclosureAction {
    return { type: DisclosureActionTypes.GET_DISCLOSURE_DETAILS_SUCCESS, data };
  }

  public static getDisclosureDetailsError(data: Error): DisclosureAction {
    return { type: DisclosureActionTypes.GET_DISCLOSURE_DETAILS_ERROR, data };
  }

  public static postDisclosureSignStart(data: DisclosureSignaturePostRequest): DisclosureAction {
    return { type: DisclosureActionTypes.POST_DISCLOSURE_SIGNATURE_REQUEST, data };
  }

  public static postDisclosureSignSuccess(): DisclosureAction {
    return { type: DisclosureActionTypes.POST_DISCLOSURE_SIGNATURE_SUCCESS, data: null };
  }

  public static postDisclosureSignError(data: Error): DisclosureAction {
    return { type: DisclosureActionTypes.POST_DISCLOSURE_SIGNATURE_ERROR, data };
  }
}
