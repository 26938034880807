import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Snackbar, SnackbarContent, Grid } from '@mui/material';
import { ApplicationState } from '../../store/RootReducers';
import { SuccessToastActions } from '../../store/actions/SuccessToastAction';
import { AppIcon, AppIconType, AppIconSize } from '../AppIcon/AppIcon';
import { useStyles } from './SuccessToastStyles';
import { TypeHelper } from '../../helper/TypeHelper';
import { theme } from '../../theme/Theme';

interface SuccessToastProps {
  snackbarFlag: boolean;
  message: string | null;
  errorMessage: string | null;
}

const SuccessToast: FC<SuccessToastProps> = ({ snackbarFlag, message, errorMessage }) => {
  const classes = useStyles();

  if (!TypeHelper.isNullOrUndefined(errorMessage)) {
    return (
      <Snackbar
        open={snackbarFlag}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        autoHideDuration={2000}
        onClose={SuccessToastActions.hide}
      >
        <SnackbarContent
          className={`${classes.snackBarContentStyles} ${classes.errorStyle}`}
          message={
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <AppIcon
                  type={AppIconType.FilledTick}
                  size={AppIconSize.Large}
                  color={theme.colors.warning}
                />
              </Grid>
              <Grid item className={classes.snackBarTextStyles}>
                {errorMessage}
              </Grid>
            </Grid>
          }
        />
      </Snackbar>
    );
  }
  return (
    <Snackbar
      open={snackbarFlag}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      autoHideDuration={2000}
      onClose={SuccessToastActions.hide}
    >
      <SnackbarContent
        className={classes.snackBarContentStyles}
        message={
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <AppIcon
                type={AppIconType.FilledTick}
                size={AppIconSize.Large}
                color={theme.colors.success}
              />
            </Grid>
            <Grid item className={classes.snackBarTextStyles}>
              {message}
            </Grid>
          </Grid>
        }
      />
    </Snackbar>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  snackbarFlag: state.successToast.snackbarFlag,
  message: state.successToast.message,
  errorMessage: state.successToast.errorMessage,
});

export default connect(mapStateToProps, null)(SuccessToast);
