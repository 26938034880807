import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  searchContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.units.borderRadius / 1.5,
    backgroundColor: theme.colors.inputBackground,
    border: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    marginRight: theme.units.spacing * 6,
    cursor: 'pointer',
    transition: 'all 0.5s ease 0s',
    top: 0,
    left: 0,
    minWidth: 300,
    [`@media (max-width: ${SIZES.md}px)`]: {
      minWidth: 200,
    },
    [`@media (max-width: ${SIZES.sm}px)`]: {
      display: 'none',
    },
  },
  searchActive: {
    // top: 86,
    // left: -68,
  },
  divider: {
    height: 27,
    width: 2,
    backgroundColor: theme.colors.borderColor,
    flexShrink: 0,
  },
  searchIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
    objectPosition: 'center',
    marginLeft: theme.units.spacing * 4,
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    height: '100%',
  },
  // searchItems: {

  // },
  searchInputContainer: {
    marginBottom: '0px !important',
    marginRight: `${theme.units.spacing * 2} !important`,
  },
  searchInputInner: {
    border: 'none !important',
  },
  agency: {
    height: 30,
    width: 30,
    objectFit: 'contain',
    objectPosition: 'center',
    flexShrink: 0,
    borderRadius: 500,
    border: `${theme.colors.borderColorLight} 1px solid`,
    marginLeft: theme.units.spacing * 3,
  },
  groupsContainer: {
    marginLeft: theme.units.spacing * 6,
    display: 'flex',
    alignItems: 'center',
  },
  gravatar: {
    height: 30,
    width: 30,
    minWidth: 30,
    fontSize: 12,
  },
  searchButton: {
    minWidth: `60px !important`,
    width: `60px !important`,
    marginLeft: theme.units.spacing * 3,
    marginRight: theme.units.spacing * 3,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.5s ease 0s',
    overflow: 'hidden',
    maxWidth: '100%',
    flex: 1,
  },
  menu: {
    position: 'absolute',
    top: 68,
    left: 8,
    right: 8,
    margin: 'auto',
    minWidth: 'max-content',
    height: 'max-content',
    maxHeight: 150,
    overflow: 'scroll',
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    backgroundColor: theme.colors.inputBackground,
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColorLight} 2px solid`,
    zIndex: 10,
  },
  menuItem: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    padding: theme.units.spacing * 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightGrey,
    },
  },
  noOptionsMsg: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.grey,
    padding: theme.units.spacing * 4,
    textAlign: 'center',
  },
});
