import React, { FC, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { useStyles } from '../../RemoteSigningStyles';
import { theme } from '../../../../theme/Theme';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';

interface PreviewDocumentDialogProps {
  link: String;
  title: String;
}

const PreviewDocumentDialog: FC<PreviewDocumentDialogProps> = ({ link, title }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <div onClick={() => setDialogOpen(true)}>
        <div className={classes.previewDocumentButtonContent}>
          <FileCopyRoundedIcon htmlColor={theme.colors.secondary} fontSize="small" />
          <Text textVariant="link">Preview Document</Text>
        </div>
      </div>
      <Dialog maxWidth="md" fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Text parentStyles={classes.previewPadding} textVariant="title">
          {title}
        </Text>
        <DialogContent>
          <embed src={`${link}#toolbar=0`} width="100%" height="100%" style={{ minHeight: 800 }} />
        </DialogContent>
        <DialogActions>
          <Button parentStyles={classes.previewPadding} onPress={() => setDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreviewDocumentDialog;
