import { all } from 'redux-saga/effects';
import propertyWatcher from './sagas/PropertySaga';
import applyWatcher from './sagas/ApplySaga';
import fileUploadWatcher from './sagas/FileUploadSaga';
import loginWatcher from './sagas/LoginSaga';
import customTermsHeaderWatcher from './sagas/CustomTermsHeaderSaga';
import referenceToolWatcher from './sagas/ReferenceToolSaga';
import groupsWatcher from './sagas/GroupsSaga';
import maintenanceRequestWatcher from './sagas/MaintenanceRequestSaga';
import remoteSigningWatcher from './sagas/RemoteSigningSaga';
import marketplaceWatcher from './sagas/MarketplaceSaga';
import paymentWatcher from './sagas/PaymentSaga';
import disclosureWatcher from './sagas/DisclosureSaga';
import searchWatcher from './sagas/SearchSaga';
import equifaxWatcher from './sagas/EquifaxSaga';
import shortApplicationWatcher from './sagas/ShortApplicationSaga';

export default function* sagas() {
  yield all([
    applyWatcher(),
    propertyWatcher(),
    fileUploadWatcher(),
    loginWatcher(),
    customTermsHeaderWatcher(),
    referenceToolWatcher(),
    groupsWatcher(),
    maintenanceRequestWatcher(),
    remoteSigningWatcher(),
    marketplaceWatcher(),
    paymentWatcher(),
    disclosureWatcher(),
    searchWatcher(),
    equifaxWatcher(),
    shortApplicationWatcher(),
  ]);
}
