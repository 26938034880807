import { Grid, Paper, Typography } from '@mui/material';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { FC } from 'react';
import { DisplayComponent } from '../../MaintenanceForm';
import RenderInput from '../RenderInput/RenderInput';
import {
  LABELS,
  personalDetailsFormInitialValues,
  PersonalDetailsFormValues,
  personalDetailsValidationSchema,
} from './PersonalDetailsConstants';
import { useStyles } from './PersonalDetailsStyles';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import Input from '../../../../component/input/Input';
import InputMobile from '../../../../component/inputMobile/InputMobile';

interface PersonalDetailsProps {
  setView: React.Dispatch<React.SetStateAction<DisplayComponent>>;
  setPersonalDetails: (values: PersonalDetailsFormValues) => void;
  userDetails: PersonalDetailsFormValues | null;
}

const PersonalDetails: FC<PersonalDetailsProps> = ({
  setView,
  setPersonalDetails,
  userDetails,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Box lightBorder parentStyles={classes.paper}>
        <div className={classes.content}>
          <Text parentStyles={classes.heading} textVariant="title">
            {LABELS.heading}
          </Text>
          <Text textVariant="info" parentStyles={classes.subHeading}>
            {LABELS.subHeading}
          </Text>

          <Formik
            validationSchema={personalDetailsValidationSchema}
            validateOnBlur
            onSubmit={(values) => {
              setPersonalDetails(values);
              setView(DisplayComponent.FORM);
            }}
            isInitialValid={!!userDetails}
            initialValues={userDetails || personalDetailsFormInitialValues}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              dirty,
              isValid,
            }) => (
              <div className={classes.formWrapper}>
                <div className={classes.textFieldsContainer}>
                  <Grid className={classes.form} container>
                    <Grid xs={12} sm={6} className="fieldWrapper" item>
                      <Input
                        placeholder={LABELS.firstName}
                        title={LABELS.firstName}
                        value={values.firstName}
                        setValue={(value: string) => setFieldValue('firstName', value)}
                        onBlur={() => setFieldTouched('firstName')}
                        error={errors.firstName}
                        touched={touched.firstName}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} className="fieldWrapper" item>
                      <Input
                        placeholder={LABELS.lastName}
                        title={LABELS.lastName}
                        value={values.lastName}
                        setValue={(value: string) => setFieldValue('lastName', value)}
                        onBlur={() => setFieldTouched('lastName')}
                        error={errors.lastName}
                        touched={touched.lastName}
                      />
                    </Grid>

                    <Grid xs={12} sm={6} className="fieldWrapper" item>
                      <Input
                        placeholder={LABELS.email}
                        title={LABELS.email}
                        value={values.email}
                        setValue={(value: string) => setFieldValue('email', value)}
                        onBlur={() => setFieldTouched('email')}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} className="fieldWrapper" item>
                      <InputMobile
                        placeholder={LABELS.mobileNumber}
                        title={LABELS.mobileNumber}
                        value={values.mobileNumber}
                        setValue={(value: string) => setFieldValue('mobileNumber', value)}
                        onBlur={() => setFieldTouched('mobileNumber')}
                        error={errors.mobileNumber}
                        touched={touched.mobileNumber}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.buttonContainer}>
                  <Button outline onPress={() => setView(DisplayComponent.PROPERTY)}>
                    Back
                  </Button>
                  <Button
                    onPress={() => handleSubmit()}
                    disabled={(!userDetails && !dirty) || !isValid}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Box>
    </div>
  );
};

export default PersonalDetails;
