export enum SuccessActionTypes {
  FLUSH_DATA = 'FLUSH_DATA',
  DRAFT_FLUSH_DATA = 'DRAFT_FLUSH_DATA',
}

export interface SuccessAction {
  type: SuccessActionTypes;
  data?: any | Error;
}

export class SuccessActions {
  public static flushData(): SuccessAction {
    return {
      type: SuccessActionTypes.FLUSH_DATA,
      data: undefined,
    };
  }
  public static draftFlushData(): SuccessAction {
    return {
      type: SuccessActionTypes.DRAFT_FLUSH_DATA,
      data: undefined,
    };
  }
}
