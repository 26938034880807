import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  contentBox: {
    maxWidth: 'min(1200px, 100%)',
  },
  borderedBox: {
    border: `2px solid ${theme.colors.borderColorLight}`,
    borderRadius: 12,
  },
  containerBox: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 600px)',
  },
  inputStyle: {
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.inputBackground,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.colors.inputText,
      fontSize: theme.font.m,
      fontWeight: getFonts('Light'),
    },
    padding: '8px 10px',
    marginLeft: '-9px',
    '&.error': {
      border: `${theme.colors.error} 2px solid`,
    },
    fontSize: 16,
    fontWeight: 500,
  },
  outlinedInputRoot: {
    padding: 0,
    paddingBottom: 9,
  },
  titleStyle: {
    color: theme.colors.inputText,
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontSize: theme.font.s,
    marginLeft: theme.units.spacing,
    fontWeight: getFonts('Medium'),
  },
});
