/* eslint-disable no-case-declarations */
import { EquifaxResponse } from '../../models/equifax/equifax';
import { EquifaxAction, EquifaxActionTypes } from '../actions/EquifaxActions';
import { EquifaxState } from '../state/EquifaxState';

export const initialState: EquifaxState = {
  ntdChecks: undefined,
};

export const equifaxReducer: (state: EquifaxState, action: EquifaxAction) => EquifaxState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case EquifaxActionTypes.GET_NTD_CHECK_SUCCESS:
    case EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK_SUCCESS:
      const { userRefId } = action.data as EquifaxResponse;

      return {
        ...state,
        ntdChecks: { ...state.ntdChecks, [userRefId!]: action.data as EquifaxResponse },
      };

    default:
      return state;
  }
};
