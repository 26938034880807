/* eslint-disable max-lines */
import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './AdvancedSearchStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/RootReducers';
import BackArrow from '../../component/backArrow/BackArrow';
import { LABELS, propertyTypeArray } from './AdvancedSearchConstants';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import Slider from '@mui/material/Slider';
import { theme } from '../../theme/Theme';
import bedroomsIcon from '../../assets/bed.png';
import bathroomsIcon from '../../assets/bath.png';
import carspacesIcon from '../../assets/car.png';
import bookmarkIcon from '../../assets/bookmark.png';
import bookmarkFilledIcon from '../../assets/bookmark-filled.png';
import propertyDefaultIcon from '../../assets/homePlaceholder.png';
import { PropertyType } from '../../services/remoteSigning/lease/Lease';
import { classNameGenerator } from '../../theme/GlobalStyles';
import Input from '../../component/input/Input';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import {
  DashboardActions,
  DashboardActionTypes,
  StoreAgencyPayload,
} from '../../store/actions/DashboardActions';
import {
  AgencyDetails,
  PropertyDetailsResponse,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { getQueryParams } from '../../helper/URLHelper';
import { AgencyLimited } from '../../models/agencies/Agencies';
import InputSelect from '../../component/inputSelect/InputSelect';
import GoogleMap from 'google-map-react';

interface AdvancedSearchProps extends RouteComponentProps {
  propertiesOption: PropertyDetailsResponse[];
  selectedProperties: PropertyDetailsResponse[] | undefined;
  loading: boolean;
  agency: string | null | undefined;
  branch: string | null | undefined;
  agencies: AgencyLimited[] | undefined;
  agencyDetails: AgencyDetails | undefined;
  getProperties: (address: string, agency: string, branch?: string, limit?: number) => void;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
}

const AdvancedSearch: FC<AdvancedSearchProps> = ({
  propertiesOption,
  selectedProperties,
  loading,
  agency,
  branch,
  location,
  agencies,
  agencyDetails,
  getProperties,
  storePropertyAgency,
}) => {
  const classes = useStyles();
  const [minRent, setMinRent] = useState<number>(200);
  const [maxRent, setMaxRent] = useState<number>(500);
  const [bedrooms, setBedrooms] = useState<number>(3);
  const [bathrooms, setBathrooms] = useState<number>(2);
  const [propertyTypes, setPropertyTypes] = useState<PropertyType[]>([PropertyType.House]);
  const [suburb, setSuburb] = useState<string>('');
  const [searchAgency, setSearchAgency] = useState<string>(
    agencyDetails ? agencyDetails.tradingName : '',
  );
  const [disableMapSearch, setDisableMapSearch] = useState<boolean>(false);
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: -37.8136,
    lng: 144.9631,
  });

  const selectedAgency = useMemo<AgencyLimited | null>(() => {
    if (searchAgency && agencies && !!agencies.length) {
      const result = agencies.find((a) => a.tradingName === searchAgency)!;
      storePropertyAgency({ agency: result.code });
      return result;
    } else {
      return null;
    }
  }, [searchAgency, agencies]);

  const setPropertyType = (type: PropertyType) => {
    const included = propertyTypes.includes(type);
    if (propertyTypes.length === 1 && included) return;
    if (included) {
      setPropertyTypes(propertyTypes.filter((p) => p !== type));
    } else {
      setPropertyTypes([...propertyTypes, type]);
    }
  };

  const getPropertiesOptions = () => {
    if (propertiesOption && propertiesOption.length) {
      if (selectedProperties === undefined) return propertiesOption;
      return propertiesOption.filter(
        (p: PropertyDetailsResponse) =>
          !selectedProperties.filter((u: PropertyDetailsResponse) => u.address === p.address)
            .length,
      );
    }
    return [];
  };

  function searchMapBySuburb() {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        'address': suburb,
        componentRestrictions: {
          country: 'AU',
        },
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng();
          setCenter({ lat, lng });
        } else {
          console.log("didn't work", status);
        }
      },
    );
  }

  const getSuburbFromMap = (lat: number, lng: number) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const findSuburb = results[0].address_components.find((a) => a.types.includes('locality'));
        if (findSuburb) {
          setDisableMapSearch(true);
          setSuburb(findSuburb.short_name);
        }
        console.log('findSuburb', findSuburb);
      } else {
        console.log('no');
      }
    });
  };

  useEffect(() => {
    if (!disableMapSearch) {
      searchMapBySuburb();
    }
  }, [disableMapSearch, suburb]);

  useEffect(() => {
    if (agencyDetails) {
      setSearchAgency(agencyDetails.tradingName);
    }
  }, [agencyDetails]);

  useEffect(() => {
    if (suburb.length > 3) {
      const agencyToSearch = selectedAgency
        ? selectedAgency.code
        : agency || getQueryParams(location, 'agencyCode');
      const branchToSearch = branch || getQueryParams(location, 'branchCode');
      getProperties(suburb, agencyToSearch, branchToSearch);
    }
  }, [suburb, agency, selectedAgency]);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.pageContent}>
          <div className={classes.filterContainer}>
            <BackArrow smallGap />
            <div className={classes.titleContainer}>
              <div className={classes.title}>{LABELS.FILTERS}</div>
            </div>
            <div className={classes.filterSection}>
              <div className={classes.filterTitle}>{LABELS.SUBURB}</div>
              <Input
                placeholder={LABELS.SUBURB}
                value={suburb || ''}
                setValue={(value: string) => {
                  setDisableMapSearch(false);
                  setSuburb(value);
                }}
                hideEndElement
                parentStyles={classes.postcode}
              />
            </div>
            <div className={classes.filterSection}>
              <div className={classes.filterTitle}>{LABELS.AGENCY}</div>
              <div className={classes.agencyPickerContainer}>
                {selectedAgency && (
                  <img src={selectedAgency.circularLogo!} className={classes.agency} alt="agency" />
                )}
                {agencies && (
                  <div className={classes.agencySearch}>
                    <InputSelect
                      placeholder="Select a agency"
                      value={selectedAgency ? selectedAgency.tradingName : ''}
                      values={agencies
                        .filter((a) => a.tradingName)
                        .map((a) => ({ display: a.tradingName!, value: a.tradingName! }))}
                      setValue={(value) => {
                        setSearchAgency(value as string);
                      }}
                      hideEndElement
                      parentStyles={classes.agencyPickerWrapper}
                      disabled={!!selectedProperties && !!selectedProperties.length}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.filterSection}>
              <div className={classes.filterTitle}>{LABELS.PROPERTY_TYPE}</div>
              <div className={classes.propertyTypes}>
                {propertyTypeArray.map((p, idx) => (
                  <div
                    key={idx}
                    className={classNameGenerator([
                      classes.typeContainer,
                      propertyTypes.includes(p.propertyType) && classes.activeTypeContainer,
                    ])}
                    onClick={() => setPropertyType(p.propertyType)}
                  >
                    <img
                      src={p.image}
                      className={classNameGenerator([
                        classes.typeIcon,
                        propertyTypes.includes(p.propertyType) && classes.activeTypeIcon,
                      ])}
                      alt="type"
                    />
                    <div
                      className={classNameGenerator([
                        classes.typeText,
                        propertyTypes.includes(p.propertyType) && classes.activeTypeText,
                      ])}
                    >
                      {p.propertyType}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.filterSection}>
              <div className={classes.filterTitle}>{LABELS.PRICE_RANGE}</div>
              <Slider
                min={0}
                max={2000}
                value={[minRent, maxRent]}
                onChange={(_, values: number[]) => {
                  setMinRent(values[0]);
                  setMaxRent(values[1]);
                }}
                valueLabelDisplay="auto"
                disableSwap
                sx={{
                  color: theme.colors.secondary,
                  '& .MuiSlider-thumb': {
                    width: 15,
                    height: 15,
                  },
                  '& .MuiSlider-valueLabel': {
                    width: 'max-content',
                    maxWidth: 'max-content',
                  },
                }}
              />
              <div className={classes.priceRangeInputRow}>
                <Input
                  placeholder={LABELS.MIN}
                  value={minRent || ''}
                  setValue={(value: string) => {
                    setMinRent(parseInt(value));
                  }}
                  title={LABELS.MIN}
                  hideEndElement
                  dollar
                />
                <Input
                  placeholder={LABELS.MAX}
                  value={maxRent || ''}
                  setValue={(value: string) => {
                    setMaxRent(parseInt(value));
                  }}
                  title={LABELS.MAX}
                  hideEndElement
                  dollar
                  parentStyles={classes.secondInput}
                />
              </div>
            </div>
            <div className={classes.filterSection}>
              <div className={classes.bedroomsBathroomsRow}>
                <div className={classes.bedrooms}>
                  <div className={classes.filterTitle}>{LABELS.BEDROOMS}</div>
                  <div className={classes.counterContainer}>
                    <div
                      className={classNameGenerator([
                        classes.leftCounter,
                        bedrooms === 0 && classes.disabledCounter,
                      ])}
                      onClick={() => setBedrooms(bedrooms === 0 ? 0 : bedrooms - 1)}
                    >
                      -
                    </div>
                    <div className={classes.middleCounter}>{bedrooms}</div>
                    <div className={classes.rightCounter} onClick={() => setBedrooms(bedrooms + 1)}>
                      +
                    </div>
                  </div>
                </div>
                <div className={classes.bathrooms}>
                  <div className={classes.filterTitle}>{LABELS.BATHROOMS}</div>
                  <div className={classes.counterContainer}>
                    <div
                      className={classNameGenerator([
                        classes.leftCounter,
                        bathrooms === 0 && classes.disabledCounter,
                      ])}
                      onClick={() => setBathrooms(bathrooms === 0 ? 0 : bathrooms - 1)}
                    >
                      -
                    </div>
                    <div className={classes.middleCounter}>{bathrooms}</div>
                    <div
                      className={classes.rightCounter}
                      onClick={() => setBathrooms(bathrooms + 1)}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.resultsContainer}>
            <div className={classes.titleContainer}>
              <div className={classes.title}>
                {LABELS.SEARCH_RESULTS} {getPropertiesOptions().length}
              </div>
            </div>
            <div className={classes.resultsWrapper}>
              <div className={classes.searchResults}>
                {loading ? (
                  <>
                    {[...Array(8).fill(null)].map((_, i) => (
                      <Box key={i} parentStyles={classes.loadingApplicationContainer} loading />
                    ))}
                  </>
                ) : (
                  <>
                    {getPropertiesOptions().map((property, idx) => (
                      <Box parentStyles={classes.boxContainer} key={idx}>
                        <div className={classes.applicationRow}>
                          <div className={classes.propertyContainer}>
                            <img
                              className={classes.property}
                              src={
                                property.photos && !!property.photos.length
                                  ? property.photos[0]
                                  : propertyDefaultIcon
                              }
                              alt="property"
                            />
                          </div>
                          <div className={classes.applicationDetails}>
                            <div className={classes.applicationDetailsMiddle}>
                              <div className={classes.propertyAddressContainer}>
                                <Text textVariant="title" parentStyles={classes.address1}>
                                  {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                                    property.streetNumber || ''
                                  } ${property.streetName}`}
                                </Text>
                                <Text textVariant="info" parentStyles={classes.address2}>
                                  {`${property.suburb}, ${property.state} ${property.postcode}`}
                                </Text>
                              </div>
                              <img src={bookmarkIcon} className={classes.bookMark} alt="bookmark" />
                            </div>
                            <div className={classes.applicationDetailsFooter}>
                              <div className={classes.statsRow}>
                                {!!property.bedrooms && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={bedroomsIcon}
                                      alt="people stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.bedrooms}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        {LABELS.BEDROOMS}
                                      </Text>
                                    </div>
                                  </div>
                                )}
                                {!!property.bathrooms && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={bathroomsIcon}
                                      alt="applications stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.bathrooms}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        {LABELS.BATHROOMS}
                                      </Text>
                                    </div>
                                  </div>
                                )}
                                {!!property.carspaces && (
                                  <div className={classes.stat}>
                                    <img
                                      className={classes.statIcon}
                                      src={carspacesIcon}
                                      alt="applications stat"
                                    />
                                    <div className={classes.statTextRow}>
                                      <Text textVariant="info" parentStyles={classes.statNumber}>
                                        {property.carspaces}
                                      </Text>
                                      <Text textVariant="info" parentStyles={classes.statText}>
                                        {LABELS.CARSPACES}
                                      </Text>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={classes.rentRow}>
                              <Text textVariant="title" parentStyles={classes.rent}>
                                {property.rentalOffer ? `$${property.rentalOffer}` : '$ -'}
                              </Text>
                              <div className={classes.pw}> /pw</div>
                            </div>
                          </div>
                        </div>
                      </Box>
                    ))}
                  </>
                )}
              </div>
              <div id="map" className={classes.map}>
                <GoogleMap
                  key="AIzaSyANoNn7A2QXAzN2gA8XVCGwv5G03UWp_ys" // set if you need stats etc ...
                  center={center}
                  zoom={16}
                  onChange={(map) => getSuburbFromMap(map.center.lat, map.center.lng)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const loading = loadingSelector([DashboardActionTypes.FETCH_PROPERTIES]);

const mapStateToProps = (state: ApplicationState) => ({
  selectedProperties: state.landingScreen.properties,
  propertiesOption: state.dashboard.properties!,
  loading: loading(state),
  agency: state.dashboard.agency,
  branch: state.dashboard.branch,
  agencyDetails: state.dashboard.agencyDetails,
  agencies: state.searchState.agencies,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProperties: (address: string, agency: string, branch?: string, limit?: number) => {
    dispatch(
      DashboardActions.getProperties({
        agency,
        branch,
        address,
        limit,
      }),
    );
  },
  storePropertyAgency: (data: StoreAgencyPayload) =>
    dispatch(
      DashboardActions.storePropertyAgency({ agency: data.agency, branch: data.branch || '' }),
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdvancedSearch));
