import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 72px)',
  },
  errorMessage: {
    color: theme.colors.success,
  },
});
