/* eslint-disable no-lonely-if */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dispatch } from 'redux';
import { Typography, Paper, Checkbox, FormControlLabel } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import House from '../../../../assets/setup-house.png';
import { useStyles } from './AvailableServicesStyles';
import {
  GuestAuthDetailsResponse,
  MeritonServiceCallbackRequest,
  RemoteSigningGetResponse,
} from '../../../../services/remoteSigning/RemoteSigning';
import { ApplicationState } from '../../../../store/RootReducers';
import { LABELS } from './AvailableServicesConstants';
import { MarketplaceActions } from '../../../../store/actions/MarketplaceActions';
import {
  AvailableServicesRequest,
  AvailableServicesResponse,
  ProcurementCallbackRequest,
  ServiceType,
} from '../../../../services/marketplace/Marketplace';
import {
  getEssentialServices,
  getOptionalServices,
  getServiceName,
  getWalletValue,
  Service,
} from './AvailableServicesUtils';
import { theme } from '../../../../theme/Theme';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import inactiveHouse from '../../../../assets/houseAssets/inactive.png';
import electricity from '../../../../assets/houseAssets/electricity.png';
import gas from '../../../../assets/houseAssets/gas.png';
import wifi from '../../../../assets/houseAssets/wifi.png';
import removals from '../../../../assets/houseAssets/removals.png';
import insurance from '../../../../assets/houseAssets/insurance.png';
import { AppSettings } from '../../../../AppSettings';
import { onboardingUrls } from '../../../../constants/OnboardingURLs';
import { RemoteSigningActions } from '../../../../store/actions/RemoteSigningActions';
import { UserType } from '../../../../services/remoteSigning/user/User';
import { PaymentProvider } from '../../../../services/remoteSigning/payment/Payment';
import renewBG from '../../../../assets/renewOffer.jpg';
import sortedLogo from '../../../../assets/logosCircle/sorted.png';
import meriEnergy from '../../../../assets/energy-locals-meri.png';
import occom from '../../../../assets/occom.png';
import qrCode from '../../../../assets/meri-qr.png';
import Text from '../../../../component/text/Text';
import DownloadSorted from '../../../dashboard/components/DownloadSorted/DownloadSorted';
import Button from '../../../../component/button/Button';
import { sortedWebUrls } from '../../../../constants/SortedWebUrls';
import AppReview from '../../../applicationSent/components/AppReview/AppReview';

interface AvailableServicesProps {
  agencyCode: string;
  agencyName: string;
  remoteSigningData: RemoteSigningGetResponse;
  availableServices: AvailableServicesResponse[] | undefined;
  walletAmount: number;
  guestAuthDetails: GuestAuthDetailsResponse;
  setWalletAmount: (amount: number) => void;
  handleNext: () => void;
  setActiveStep: (step: number) => void;
  setContactMe: (value: boolean) => void;
  getAvailableServices: (data: AvailableServicesRequest) => void;
  postMeritonServiceCallback: (data: MeritonServiceCallbackRequest) => void;
}

const AvailableServices: FC<AvailableServicesProps> = ({
  agencyCode,
  agencyName,
  walletAmount,
  remoteSigningData,
  availableServices,
  guestAuthDetails,
  setWalletAmount,
  handleNext,
  setActiveStep,
  setContactMe,
  getAvailableServices,
  postMeritonServiceCallback,
}) => {
  const classes = useStyles();
  const [electricityActive, setElectricityActive] = useState<boolean>();
  const [gasActive, setGasActive] = useState<boolean>();
  const [insuranceActive, setInsuranceActive] = useState<boolean>();
  const [removalsActive, setRemovalsActive] = useState<boolean>();
  const [wifiActive, setWifiActive] = useState<boolean>();
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [essentialServices, setEssentialServices] = useState<Service[]>(
    getEssentialServices(availableServices, agencyCode),
  );
  const [optionalServices, setOptionalServices] = useState<Service[]>(
    getOptionalServices(availableServices),
  );
  const isRenew = remoteSigningData.lease!.leaseType === 'Renew';
  const hasRent = remoteSigningData!.lease!.paymentInfo.payment_provider === PaymentProvider.Sorted;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveStep(2);
    getAvailableServices({
      branchCode: remoteSigningData.branch.internalCode!,
      postcode: remoteSigningData.lease!!.property!.postcode!,
    });
  }, []);

  useEffect(() => {
    let amount = 0;
    essentialServices.map((service) => {
      if (service.active) {
        amount += getWalletValue(service.serviceType);
      }
    });
    setWalletAmount(amount);
  }, [essentialServices]);

  useEffect(() => {
    setEssentialServices(getEssentialServices(availableServices, agencyCode));
    setOptionalServices(getOptionalServices(availableServices));
  }, [availableServices]);

  useEffect(() => {
    essentialServices.map((service) => {
      switch (service.serviceName) {
        case 'Electricity':
          setElectricityActive(service.active);
          break;
        case 'Broadband':
          setWifiActive(service.active);
          break;
        case 'Gas':
          setGasActive(service.active);
          break;
        default:
          break;
      }
    });
    optionalServices.map((service) => {
      switch (service.serviceName) {
        case 'Insurance':
          setInsuranceActive(service.active);
          break;
        case 'Removals':
          setRemovalsActive(service.active);
          break;
        default:
          break;
      }
    });
  }, [essentialServices, optionalServices]);

  const redirectToOnboarding = () => {
    // If on sorted payments and have not gone through rent already then go to rent
    if (
      remoteSigningData.lease!.paymentInfo.payment_provider === PaymentProvider.Sorted &&
      !remoteSigningData.lease!.servicesComplete.includes('Rent')
    ) {
      if (agencyCode === 'SORTED') {
        window.location.href = `${AppSettings.server.sortedWebUrl}${sortedWebUrls.rent(
          // agencyCode === 'NAX' ? 'NAX' : agencyCode === 'AU_SWIT' ? 'SWITCH' : 'SORTED', // uncomment
          'SORTED',
          remoteSigningData.lease!.property!.id,
          guestAuthDetails.accessToken,
          guestAuthDetails.refreshToken,
          isRenew,
        )}`;
      } else {
        // remove when activating sorted web for all
        window.location.href = `${AppSettings.onboardingUI.url}/#/${onboardingUrls.rent(
          agencyCode === 'NAX' ? 'NAX' : agencyCode === 'AU_SWIT' ? 'SWITCH' : 'SORTED',
          remoteSigningData.lease!.property!.id,
          guestAuthDetails.accessToken,
          guestAuthDetails.refreshToken,
          isRenew,
        )}`;
      }
      // otherwise go to checkout page
    } else {
      if (remoteSigningData.cimetEnabled) {
        window.location.href = `${AppSettings.server.sortedWebUrl}${sortedWebUrls.cimet(
          remoteSigningData.lease!.property!.id,
          guestAuthDetails.accessToken,
          guestAuthDetails.refreshToken,
        )}`;
      } else {
        if (agencyCode === 'SORTED') {
          window.location.href = `${AppSettings.server.sortedWebUrl}${sortedWebUrls.marketplace(
            // agencyCode === 'NAX' ? 'NAX' : agencyCode === 'AU_SWIT' ? 'SWITCH' : 'SORTED', // uncomment
            'SORTED',
            remoteSigningData.lease!.property!.id,
            guestAuthDetails.accessToken,
            guestAuthDetails.refreshToken,
            isRenew,
          )}`;
        } else {
          // remove when activating sorted web for all
          window.location.href = `${AppSettings.onboardingUI.url}/#/${onboardingUrls.checkout(
            agencyCode === 'NAX' ? 'NAX' : agencyCode === 'AU_SWIT' ? 'SWITCH' : 'SORTED',
            remoteSigningData.lease!.property!.id,
            guestAuthDetails.accessToken,
            guestAuthDetails.refreshToken,
            isRenew,
          )}`;
        }
      }
    }
  };

  const onNext = (contactMe: boolean) => {
    if (remoteSigningData.user.userType === UserType.Primary) {
      if (agencyCode === 'AU_MERI' && (wifiActive || electricityActive)) {
        const serviceTypes: ServiceType[] = [];
        if (wifiActive) serviceTypes.push(ServiceType.Broadband);
        if (electricityActive) serviceTypes.push(ServiceType.Electricity);

        setDisableNext(true);
        postMeritonServiceCallback({
          userId: remoteSigningData.user.id,
          leaseId: remoteSigningData.lease!.id,
          serviceType: serviceTypes,
          onSuccess: () => {
            redirectToOnboarding();
          },
        });
      } else {
        setContactMe(contactMe);
        redirectToOnboarding();
      }
    } else {
      setWalletAmount(25);
      handleNext();
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <Text textVariant="title" size="xxl">
          {LABELS.CONGRATZ(remoteSigningData.user.name)}
        </Text>
        <Text textVariant="title" size="xxl" parentStyles={classes.pageSubtitle}>
          {isRenew ? LABELS.CONGRATZ_RENEW : LABELS.CONGRATZ_SUBTITLE}
        </Text>
        <Text parentStyles={classes.moveInText}>
          {`Move in is ${moment(
            (isRenew
              ? remoteSigningData.lease!.startDate
              : remoteSigningData.lease!.moveInDate) as string,
            'DD/MM/YYYY',
          ).diff(moment(), 'days')} days away on ${moment(
            (isRenew
              ? remoteSigningData.lease!.startDate
              : remoteSigningData.lease!.moveInDate) as string,
            'DD/MM/YYYY',
          ).format('DD/MM/YYYY')}`}
        </Text>
        <AppReview />

        <div className={classes.stepsContainer}>
          <div className={classes.bigStepContainer}>
            <div className={classes.statusRow}>
              <div className={classes.statusDone}>{LABELS.DONE}</div>
            </div>
            <Text parentStyles={classes.stepTitle} textVariant="title">
              {LABELS.REVIEW}
            </Text>
          </div>
          <div className={classes.minorSteps}>
            {hasRent && (
              <div
                className={classes.purpleStepContainer}
                style={{ marginBottom: theme.units.spacing * 4 }}
                onClick={() => onNext(true)}
              >
                <div className={classes.statusRow}>
                  <div className={classes.statusWhite}>{LABELS.READY}</div>
                  <div className={classes.dot} />
                </div>
                <Text parentStyles={classes.stepTitle} size="xl" textVariant="title">
                  {LABELS.SETUP_RENT}
                </Text>

                <Text textVariant="info">
                  First rent is due in{' '}
                  {moment(
                    (isRenew
                      ? remoteSigningData.lease!.startDate
                      : remoteSigningData.lease!.moveInDate) as string,
                    'DD/MM/YYYY',
                  ).diff(moment(), 'days')}{' '}
                  days on{' '}
                  {isRenew
                    ? remoteSigningData.lease!.startDate
                    : remoteSigningData.lease!.moveInDate}
                </Text>
                <div className={classes.actionContainer}>
                  <Button parentStyles={classes.action} onPress={() => onNext(true)}>
                    {LABELS.GET_STARTED}
                  </Button>
                </div>
              </div>
            )}
            <div
              className={hasRent ? classes.stepContainer : classes.purpleStepContainer}
              onClick={() => (hasRent ? null : onNext(true))}
            >
              <div className={classes.statusRow}>
                <div className={hasRent ? classes.statusGrey : classes.statusWhite}>
                  {hasRent ? LABELS.PENDING : LABELS.READY}
                </div>
                <div className={classes.dot} />
              </div>
              <Text parentStyles={classes.stepTitle} size="xl" textVariant="title">
                {LABELS.GET_MOVE_IN_READY}
              </Text>

              <Text textVariant="info">{LABELS.ORGANISE}</Text>
              <div className={classes.actionContainer}>
                <Button parentStyles={classes.action} onPress={() => onNext(true)}>
                  {LABELS.GET_STARTED}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {agencyCode === 'AU_MERI' && (
          <>
            {optionalServices.length || essentialServices.length ? (
              <>
                <div className={classes.contentContainer}>
                  {essentialServices.length ? (
                    <>
                      <div className={classes.listTitle}>{LABELS.ESSENTIAL}</div>
                      <div className={classes.listContainer}>
                        {essentialServices
                          .filter(
                            (service) =>
                              service.serviceName === 'Broadband' ||
                              service.serviceName === 'Electricity',
                          )
                          .map((service, index) => (
                            <>
                              <div className={classes.listItem} key={index}>
                                <div className={classes.listIconContainer}>
                                  <img
                                    src={service.logo.active}
                                    className={classes.listIcon}
                                    alt="icon"
                                  />
                                </div>
                                <div className={classes.listText}>
                                  {getServiceName(agencyCode, service.serviceName)}
                                </div>
                                {service.serviceName === 'Broadband' ||
                                service.serviceName === 'Electricity' ? null : (
                                  <div
                                    className={classes.iconContainer}
                                    onClick={() =>
                                      setEssentialServices(
                                        essentialServices.map((item, idx) =>
                                          index === idx ? { ...item, active: !item.active } : item,
                                        ),
                                      )
                                    }
                                  >
                                    {service.active ? (
                                      <CheckOutlinedIcon className={classes.iconSize} />
                                    ) : (
                                      <AddOutlinedIcon className={classes.iconSize} />
                                    )}
                                  </div>
                                )}
                              </div>
                              {service.serviceName === ServiceType.Broadband && (
                                <>
                                  <div className={classes.meritonContainer}>
                                    <div className={classes.meritonTitle}>
                                      {LABELS.MERITON_STATEMENT}
                                    </div>
                                    <div className={classes.occomLogoContainer}>
                                      <img className={classes.occomLogo} src={occom} alt="occom" />
                                    </div>
                                    <div className={classes.occumOffersContainer}>
                                      <div>
                                        <div className={classes.occomTitle}>
                                          {LABELS.OCCOM_OFFER_TITLE}
                                        </div>
                                        <ul>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_OFFER_1}
                                          </li>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_OFFER_2}
                                          </li>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_OFFER_3}
                                          </li>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_OFFER_4}
                                          </li>
                                        </ul>
                                      </div>
                                      <div>
                                        <div className={classes.occomTitle}>
                                          {LABELS.OCCOM_SUB_OFFER_TITLE}
                                        </div>
                                        <ul>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_SUB_OFFER_1}
                                          </li>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_SUB_OFFER_2}
                                          </li>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_SUB_OFFER_3}
                                          </li>
                                          <li className={classes.occomLi}>
                                            {LABELS.OCCOM_SUB_OFFER_4}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className={classes.star}>{LABELS.STAR}</div>
                                    <div className={classes.occomCenter}>
                                      <div className={classes.occomTitle}>
                                        {LABELS.OCCOM_CONTACT_TITLE}
                                      </div>
                                    </div>
                                    <div className={classes.occumOffersContainer}>
                                      <ul>
                                        <li className={classes.occomLi}>{LABELS.OCCOM_1}</li>
                                        <li className={classes.occomLi}>{LABELS.OCCOM_2}</li>
                                        <li className={classes.occomLi}>{LABELS.OCCOM_3}</li>
                                        <li className={classes.occomLi}>{LABELS.OCCOM_4}</li>
                                      </ul>
                                      <ul>
                                        <li className={classes.occomLi}>{LABELS.LIVECHAT}</li>
                                        <li className={classes.occomLi}>{LABELS.WHATSAPP}</li>
                                        <li className={classes.occomLi}>{LABELS.LINE}</li>
                                        <li className={classes.occomLi}>{LABELS.WECHAT}</li>
                                        <li className={classes.occomLi}>{LABELS.EMAIL}</li>
                                        <li className={classes.occomLi}>{LABELS.TEL}</li>
                                        <li className={classes.occomLi}>{LABELS.TEL_LANG}</li>
                                      </ul>
                                    </div>
                                    <div className={classes.yesContactContainer}>
                                      <FormControlLabel
                                        value={LABELS.YES_CONTACT}
                                        control={
                                          <Checkbox
                                            style={{
                                              color: theme.colors.success,
                                              flexShrink: 0,
                                              marginRight: 10,
                                            }}
                                            checked={wifiActive}
                                            onChange={() =>
                                              setEssentialServices([
                                                ...essentialServices.map((item) =>
                                                  item.serviceName === 'Broadband'
                                                    ? { ...item, active: !item.active }
                                                    : item,
                                                ),
                                              ])
                                            }
                                          />
                                        }
                                        label={LABELS.YES_CONTACT}
                                        labelPlacement="end"
                                      />
                                      <div className={classes.yesDisclamer}>
                                        {LABELS.YES_CONTACT2}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {service.serviceName === ServiceType.Electricity && (
                                <>
                                  <div className={classes.meritonContainer}>
                                    <div
                                      className={classes.meritonTitle}
                                      style={{ textAlign: 'center' }}
                                    >
                                      {LABELS.MERI_ENERGY_TITLE}
                                    </div>
                                    <div
                                      className={classes.meritonSubtitle}
                                      style={{ textAlign: 'center' }}
                                    >
                                      {LABELS.MERI_ENERGY_SUBTITLE}
                                    </div>
                                    <div className={classes.meriEnergyFlex}>
                                      <img className={classes.meriEnergyLogo} src={meriEnergy} />
                                      <div className={classes.scanContainer}>
                                        <img className={classes.qrCode} src={qrCode} />
                                        <div className={classes.scanText}>{LABELS.SCAN}</div>
                                      </div>
                                    </div>
                                    <div className={classes.meriSubline}>
                                      {LABELS.MERI_ENERGY_LINE1}
                                    </div>
                                    <div className={classes.meriSubline}>
                                      {LABELS.MERI_ENERGY_LINE2}
                                    </div>
                                    <div className={classes.yesContactContainer}>
                                      <FormControlLabel
                                        value={LABELS.YES_CONTACT_ELEC}
                                        control={
                                          <Checkbox
                                            style={{
                                              color: theme.colors.success,
                                              flexShrink: 0,
                                              marginRight: 10,
                                            }}
                                            checked={electricityActive}
                                            onChange={() =>
                                              setEssentialServices([
                                                ...essentialServices.map((item) =>
                                                  item.serviceName === 'Electricity'
                                                    ? { ...item, active: !item.active }
                                                    : item,
                                                ),
                                              ])
                                            }
                                          />
                                        }
                                        label={LABELS.YES_CONTACT_ELEC}
                                        labelPlacement="end"
                                      />
                                      <div className={classes.yesDisclamer}>
                                        {LABELS.YES_CONTACT2_ELEC}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <div className={classes.loading}>Loading services...</div>
            )}
            <div className={classes.occumButtonContainer}>
              <Button onPress={() => (hasRent ? null : onNext(true))}>Next</Button>
            </div>
          </>
        )}

        <DownloadSorted title={LABELS.MANAGE(agencyCode)} agencyCode={agencyCode} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  availableServices: state.marketplace.availableServices,
  guestAuthDetails: state.remoteSigning.guestAuthDetails,
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAvailableServices: (data: AvailableServicesRequest) => {
    dispatch(MarketplaceActions.getAvailableServicesStart(data));
  },
  postMeritonServiceCallback: (data: MeritonServiceCallbackRequest) => {
    dispatch(RemoteSigningActions.postMeritonCallbackStart(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableServices);
