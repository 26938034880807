import { CustomTermsHeaderState } from '../state/CustomTermsHeaderState';
import {
  CustomTermsHeaderAction,
  CustomTermsHeaderActionTypes,
  CustomTermsHeaderResponse,
} from '../actions/CustomTermsHeaderActions';

export const initialState: CustomTermsHeaderState = {
  termsHeaderDetails: undefined,
};

export function termsHeaderReducer(state = initialState, action: CustomTermsHeaderAction) {
  state = postCustomTermsHeaderReducer(state, action);
  return state;
}

/* tslint:disable:cyclomatic-complexity */
function postCustomTermsHeaderReducer(
  state: CustomTermsHeaderState,
  action: CustomTermsHeaderAction,
) {
  switch (action.type) {
    case CustomTermsHeaderActionTypes.GET_TERMS_SUCCESS:
      return {
        ...state,
        termsHeaderDetails: action.data as CustomTermsHeaderResponse,
      };
    default:
      return state;
  }
}
