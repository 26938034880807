import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
  },
  sectionTitle: {
    marginBottom: 0,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 6,
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },
  selectStyles: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  incomeNumberLabelStyle: {
    fontSize: '16px',
    fontWeight: 500,
  },
  removeButton: {
    'marginRight': '8px',
    'background': 'transparent',
    'boxShadow': 'none',
    'minWidth': 'unset',
    'border': '1px solid #F88379',
    opacity: 0.8,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  removeLabelStyle: {
    color: '#F88379',
    fontSize: '12px',
    lineHeight: 0,
    padding: 3,
  },
  inputContainerStyle: {
    paddingBottom: '16px',
  },
  inputLabelStyle: {
    paddingLeft: '16px',
  },
  fileContainerStyle: {
    paddingBottom: '16px',
  },
  attachPayslipLabel: {
    paddingLeft: '0px',
  },
  dateOfBirthLabelStyle: {
    padding: '23px 0 0 16px',
    fontSize: '12px',
  },
  preferredLeaseDurationLabelStyle: {
    padding: '23px 0 0 16px',
  },
  yearMonthStyle: {
    width: '45%',
  },
  yearMonthLabelStyle: {
    paddingLeft: '22px',
  },
  subHeadingLabelStyle: {
    padding: '28px 0 22px 16px',
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toggleButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  addIncomeButtonContainer: {
    textAlign: 'center',
    border: `1px  solid ${theme.colors.borderColor}`,
  },
  addIncomeButton: {
    width: 'auto',
  },
  addCurrentIncomeDescription: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    color: theme.colors.buttonPrimary,
    fontWeight: 400,
  },
  mobileNumberView: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  containerStyle: {
    'display': 'flex',
    'flexDirection': 'row',
    'paddingBottom': '16.3px',
    'justifyContent': 'space-between',
    '@media (min-width:780px)': {
      width: '45%',
    },
  },
  hide: {
    display: 'none',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '16px',
    alignItems: 'center',
  },
  groupPetIconStyle: {
    paddingRight: '16px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      textAlign: 'right',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      margin: '8px 0 0',
      textAlign: 'center',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  overlay: {
    float: 'right',
    right: 0,
  },
  counter: {
    minWidth: '10px',
    textAlign: 'center',
  },
  errorText: {
    color: theme.colors.error,
  },
  attachDescription: {
    fontSize: '14px',
  },
  extrabox: {
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '20px 20px 0px 20px',
    marginBottom: '20px',
  },
  cancelButton: {
    background: theme.colors.sortedPurple,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '150px',
    height: 50,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    color: theme.colors.light,
    marginRight: '15px',
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
  fileInputTitle: {
    marginBottom: theme.units.spacing * 2,
    fontWeight: 500,
  },
  fileInputDescription: {
    marginBottom: theme.units.spacing * 2,
  },
});
