import React, { FC, useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { showUrl, LABELS, LINKS, collapsedUrl } from './MenuConstants';
import { useStyles } from './MenuStyles';
import sortedLogo from '../../assets/sorted.png';
import profile from '../../assets/navigation/profile.png';
import { SIZES } from '../../constants/AppConstants';
import { classNameGenerator } from '../../theme/GlobalStyles';

interface MenuProps {}

const Menu: FC<MenuProps> = ({}) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const showMenu = showUrl.some((s) => location.pathname.includes(s));
  const collapsed = collapsedUrl.some((s) => location.pathname.includes(s));
  const isUserLoggedIn = !!(
    localStorage.getItem('userId') && localStorage.getItem('userId') !== 'undefined'
  );
  // This is here to stop the overlap of intercom
  useEffect(() => {
    const resize = () => {
      const isMobileSize = window.innerWidth <= SIZES.sm;

      // This handles onMount
      window.intercomSettings = {
        ...window.intercomSettings,
        vertical_padding: isMobileSize && showMenu ? 130 : 20,
      };

      // This handles resizing (doesn't work onMount, hence above)
      document
        .querySelectorAll<HTMLElement>('.intercom-lightweight-app-launcher')
        .forEach((element) => {
          element.style.bottom = `${isMobileSize && showMenu ? '130px' : '20px'}`;
        });
      document.querySelectorAll<HTMLElement>('.intercom-launcher-frame').forEach((element) => {
        element.style.bottom = `${isMobileSize && showMenu ? '130px' : '20px'}`;
      });
      document.querySelectorAll<HTMLElement>('.intercom-messenger-frame').forEach((element) => {
        element.style.bottom = `${isMobileSize && showMenu ? '210px' : '100px'}`;
      });
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [showMenu]);

  return (
    <div
      className={classNameGenerator([
        classes.menuContainer,
        collapsed && classes.collapsed,
        (!showMenu || !isUserLoggedIn) && classes.hide,
      ])}
    >
      <div className={classes.linksWrapper}>
        <div className={classes.links}>
          {LINKS.map((link, idx) => (
            <div
              className={
                link.routes.some((s) => location.pathname.includes(s))
                  ? classes.linkItemActive
                  : classes.linkItem
              }
              key={idx}
              onClick={() => history.push(link.route)}
            >
              <img
                src={link.icon}
                className={classNameGenerator([
                  classes.icon,
                  collapsed && classes.collapsedShowIcon,
                ])}
                alt="icon"
              />
              <div
                className={classNameGenerator([
                  classes.link,
                  collapsed && classes.collapsedHideLink,
                ])}
              >
                {link.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;
