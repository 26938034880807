import { get } from 'lodash';
import { DashboardState } from '../state/DashboardState';
import {
  DashboardAction,
  DashboardActionTypes,
  StoreAgencyPayload,
} from '../actions/DashboardActions';
import {
  PropertyDetailsResponse,
  GetPropertyDetailsFailure,
  AgencyDetails,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { SimilarPropertiesResponse } from '../../services/dashboard/similarProperties/GetSimilarProperties';
import {
  AppToApplyTransitionRequest,
  AppToApplyTransitionResponse,
} from '../../services/dashboard/applyTransition/applyTransition';
import { UpdatingType } from '../../services/dashboard/updating/Updating';

export const initialState: DashboardState = {
  propertyDetails: null,
  properties: null,
  success: false,
  agency: '',
  branch: '',
  applications: undefined,
  agencyDetails: undefined,
  isPrefillHelperCalled: false,
  isUpdating: false,
  isAcceptingInvitation: false,
  applicationRefId: null,
  propertyRefId: null,
  foundPropertiesToUpdate: false,
  similarProperties: undefined,
  selectedSimilarProperty: undefined,
  assets: undefined,
  loadingAssets: false,
  showDataDeletedBanner: false,
};

/* tslint:disable:cyclomatic-complexity */
export const dashboardReducer: (
  state: DashboardState | undefined,
  action: DashboardAction,
) => DashboardState = (state = initialState, action) => {
  switch (action.type) {
    case DashboardActionTypes.FETCH_PROPERTY_DETAILS_SUCCESS: {
      const { branch } = action.data as PropertyDetailsResponse;
      return {
        ...state,
        propertyDetails: action.data as PropertyDetailsResponse,
        branch: branch || state.branch,
      };
    }
    case DashboardActionTypes.CLEAR_PROPERTY_DETAILS: {
      return {
        ...state,
        propertyDetails: null,
      };
    }
    case DashboardActionTypes.POST_APPLY_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case DashboardActionTypes.POST_PROPERTY_APPLY_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case DashboardActionTypes.POST_APPLY_DRAFT_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case DashboardActionTypes.FETCH_PROPERTIES_SUCCESS: {
      return {
        ...state,
        properties: action.data as PropertyDetailsResponse[],
      };
    }
    case DashboardActionTypes.STORE_PROPERTY_AGENCY: {
      const data = action.data as StoreAgencyPayload;
      return {
        ...state,
        agency: data.agency ? get(action, 'data.agency', '').toUpperCase() : state.agency,
        branch: get(action, 'data.branch', ''),
      };
    }
    case DashboardActionTypes.GET_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        applications: action.data,
      };
    }
    case DashboardActionTypes.GET_PROPERTY_BY_ADDRESS_SUCCESS: {
      const { branch } = action.data as PropertyDetailsResponse;
      return {
        ...state,
        propertyDetails: action.data as PropertyDetailsResponse,
        branch: branch || state.branch,
      };
    }

    case DashboardActionTypes.GET_AGENCY_BY_AGENT_REQUEST: {
      return {
        ...state,
        loadingAssets: true,
      };
    }

    case DashboardActionTypes.GET_AGENCY_BY_AGENT_SUCCESS: {
      const { code, branchCode, logoUrl, backgroundUrl, logoHeight, circularLogo } =
        action.data as AgencyDetails;

      return {
        ...state,
        loadingAssets: false,
        agencyDetails: action.data as AgencyDetails,
        branch: branchCode || state.branch,
        agency: code || state.agency,
        assets: {
          logoUrl,
          backgroundUrl,
          logoHeight,
          circularLogo,
          agencyLoaded: code || state.agency,
        },
      };
    }
    case DashboardActionTypes.GET_AGENCY_BY_AGENT_ERROR: {
      return {
        ...state,
        loadingAssets: false,
      };
    }
    case DashboardActionTypes.GET_PROPERTY_BY_REAXML_SUCCESS: {
      const { branch } = action.data as PropertyDetailsResponse;
      return {
        ...state,
        propertyDetails: action.data as PropertyDetailsResponse,
        branch: branch || state.branch,
      };
    }

    /** Set isPrefillHelperCalled to true/false in redux to ensure that prefill-helper will be called only-once */
    case DashboardActionTypes.IS_PREFILL_HELPER_CALLED: {
      return {
        ...state,
        isPrefillHelperCalled: !!action.data,
      };
    }
    case DashboardActionTypes.DASHBOARD_CLEAR_PROPERTIES: {
      return {
        ...state,
        properties: null,
      };
    }

    case DashboardActionTypes.SET_IS_UPDATING_FLAG: {
      return {
        ...state,
        isUpdating: action.data as UpdatingType,
      };
    }

    case DashboardActionTypes.SET_IS_ACCEPTING_INVITATION_FLAG: {
      return {
        ...state,
        isAcceptingInvitation: !!action.data,
      };
    }

    case DashboardActionTypes.SET_APPLICATION_REF_ID: {
      return {
        ...state,
        applicationRefId: action.data as string,
      };
    }

    case DashboardActionTypes.SET_PROPERTY_REF_ID: {
      return {
        ...state,
        propertyRefId: action.data as string,
      };
    }

    case DashboardActionTypes.POST_UPDATE_PROPERTY_APPLY_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }

    case DashboardActionTypes.TOGGLE_POP_UP: {
      const { data } = action;
      return {
        ...state,
        foundPropertiesToUpdate: data as boolean,
      };
    }

    case DashboardActionTypes.GET_SIMILAR_PROPERTIES_SUCCESS: {
      return {
        ...state,
        similarProperties: action.data as SimilarPropertiesResponse[],
      };
    }

    case DashboardActionTypes.SET_SIMILAR_PROPERTY: {
      return {
        ...state,
        selectedSimilarProperty: action.data as SimilarPropertiesResponse | null,
      };
    }

    case DashboardActionTypes.RESET_SIMILAR_PROPERTIES: {
      return {
        ...state,
        similarProperties: undefined,
      };
    }

    case DashboardActionTypes.RESET_SUCCESS: {
      return {
        ...state,
        success: false,
      };
    }

    case DashboardActionTypes.APPTOAPPLY_TRANSITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    case DashboardActionTypes.GET_DELETED_INFO_SUCCESS: {
      return {
        ...state,
        showDataDeletedBanner: true,
      };
    }

    case DashboardActionTypes.DISMISS_DELETED_INFO_SUCCESS: {
      return {
        ...state,
        showDataDeletedBanner: false,
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
