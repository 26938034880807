/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { useStyles } from './ApplicationSentStyles';
import {
  Assets,
  PropertyDetailsResponse,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import propertyDefaultIcon from '../../assets/homePlaceholder.png';
import { BLOGS_BASIC, LABELS, WHAT_HAPPENS_NEXT } from './ApplicationSentConstants';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { GroupsActions } from '../../store/actions/GroupsActions';
import { LandingScreenActions } from '../../store/actions/LandingScreenAction';
import { UpdatingType, UPDATING_TYPE } from '../../services/dashboard/updating/Updating';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import bedrooms from '../../assets/bed.png';
import bathrooms from '../../assets/bath.png';
import carspaces from '../../assets/car.png';
import SORTED_CIRCULAR_LOGO from '../../assets/logosCircle/sorted.png';
import BlogCard from '../../component/blogCard/BlogCard';
import DownloadSorted from '../dashboard/components/DownloadSorted/DownloadSorted';
import WhileYouWait from '../dashboard/components/WhileYouWait/WhileYouWait';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { canShowNTDBanner } from '../../helper/NTDHelper';
import { ApplicationsResponse } from '../../services/groups/Groups';
import NTDBanner from '../../component/ntdBanner/NTDBanner';
import AppReview from './components/AppReview/AppReview';

interface ApplicationSentProps extends RouteComponentProps<{}, {}, any> {
  isUpdating: UpdatingType;
  assets: Assets | null | undefined;
  myProfileDetails: ApplicantProfile | undefined;
  groupApplications: ApplicationsResponse | undefined;
  resetStore: () => void;
}

const ApplicationSent: FC<ApplicationSentProps> = ({
  location,
  isUpdating,
  assets,
  myProfileDetails,
  groupApplications,
  resetStore,
}) => {
  const classes = useStyles();
  const [wasUpdating, setWasUpdating] = useState<UpdatingType>(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setWasUpdating(isUpdating);
    resetStore();
  }, []);

  return (
    <>
      {location.state && location.state.state ? (
        <div className={classes.content}>
          <div className={classes.pageContainer}>
            <div className={classes.titleContainer}>
              <div className={classes.title}>{LABELS.SUCCESS_TITLE}</div>
              <Text textVariant="info" size="l" parentStyles={classes.submitSubtitle}>
                {wasUpdating !== false
                  ? wasUpdating === UPDATING_TYPE.UPDATING
                    ? LABELS.SUCCESS_UPDATE
                    : LABELS.SUCCESS_SYNC(location.state.state.length === 1)
                  : LABELS.SUCCESS_SUBITLE}
              </Text>
            </div>
            <div className={classes.pageContent}>
              <div className={classes.primaryContainer}>
                <AppReview />
                <div className={classes.contentTitleContainer}>
                  <Text textVariant="contentTitle">
                    {LABELS.PROPERTIES(location.state.state.length === 1)}
                  </Text>
                </div>
                {location.state.state.map((property: PropertyDetailsResponse, index: number) => (
                  <Box lightBorder parentStyles={classes.boxContainer} key={index}>
                    <div className={classes.applicationRow}>
                      <div className={classes.propertyContainer}>
                        <img
                          className={classes.property}
                          src={
                            property.photos && !!property.photos.length
                              ? property.photos[0]
                              : propertyDefaultIcon
                          }
                          alt="property"
                        />
                      </div>
                      <div className={classes.applicationDetails}>
                        <div className={classes.applicationDetailsMiddle}>
                          <div className={classes.propertyAddressContainer}>
                            <Text textVariant="title" parentStyles={classes.address1}>
                              {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                                property.streetNumber || ''
                              } ${property.streetName}`}
                            </Text>
                            <Text textVariant="info" parentStyles={classes.address2}>
                              {`${property.suburb}, ${property.state} ${property.postcode}`}
                            </Text>
                          </div>
                          <div className={classes.rentAndAgencyContainer}>
                            <div className={classes.agencyContainer}>
                              {assets && assets.circularLogo && (
                                <img
                                  className={classes.agencyLogo}
                                  onError={(e) => {
                                    (e.target as HTMLImageElement).src = SORTED_CIRCULAR_LOGO;
                                  }}
                                  src={assets?.circularLogo}
                                  alt="agency logo"
                                />
                              )}
                            </div>
                            <div className={classes.rentContainer}>
                              <div className={classes.rentRow}>
                                <Text textVariant="title" parentStyles={classes.rent}>
                                  {property.rentalOffer ? `$${property.rentalOffer}` : '-'}
                                </Text>
                              </div>

                              <Text textVariant="info" parentStyles={classes.rentInfo}>
                                {LABELS.RENT_PW}
                              </Text>
                            </div>
                          </div>
                        </div>
                        <div className={classes.applicationDetailsFooter}>
                          <div className={classes.statsRow}>
                            {!!property.bedrooms && (
                              <div className={classes.stat}>
                                <img
                                  className={classes.statIcon}
                                  src={bedrooms}
                                  alt="people stat"
                                />
                                <div className={classes.statTextRow}>
                                  <Text textVariant="info" parentStyles={classes.statNumber}>
                                    {property.bedrooms}
                                  </Text>
                                  <Text textVariant="info" parentStyles={classes.statText}>
                                    {LABELS.BEDROOMS}
                                  </Text>
                                </div>
                              </div>
                            )}
                            {!!property.bathrooms && (
                              <div className={classes.stat}>
                                <img
                                  className={classes.statIcon}
                                  src={bathrooms}
                                  alt="applications stat"
                                />
                                <div className={classes.statTextRow}>
                                  <Text textVariant="info" parentStyles={classes.statNumber}>
                                    {property.bathrooms}
                                  </Text>
                                  <Text textVariant="info" parentStyles={classes.statText}>
                                    {LABELS.BATHROOMS}
                                  </Text>
                                </div>
                              </div>
                            )}
                            {!!property.carspaces && (
                              <div className={classes.stat}>
                                <img
                                  className={classes.statIcon}
                                  src={carspaces}
                                  alt="applications stat"
                                />
                                <div className={classes.statTextRow}>
                                  <Text textVariant="info" parentStyles={classes.statNumber}>
                                    {property.carspaces}
                                  </Text>
                                  <Text textVariant="info" parentStyles={classes.statText}>
                                    {LABELS.CARSPACES}
                                  </Text>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
                <div className={classes.contentTitleContainer} style={{ marginTop: 48 }}>
                  <Text textVariant="contentTitle">{LABELS.WHATS_NEXT}</Text>
                </div>
                <div className={classes.whatsNextContainer}>
                  {WHAT_HAPPENS_NEXT.map((next, index) => (
                    <Box lightBorder parentStyles={classes.nextContainer}>
                      <div className={classes.nextTop}>
                        <img src={next.icon} className={classes.nextIcon} alt="next icon" />
                        <div className={classes.nextTime}>{next.time}</div>
                      </div>
                      <Text textVariant="title" size="xl" parentStyles={classes.nextTitle}>
                        {next.title}
                      </Text>
                      <Text textVariant="info" size="l">
                        {next.description}
                      </Text>
                    </Box>
                  ))}
                </div>
                <WhileYouWait />
              </div>
              <div className={classes.secondaryContainer}>
                <div className={classes.contentTitleContainer}>
                  <Text textVariant="contentTitle">{LABELS.FOR_YOU}</Text>
                </div>
                {BLOGS_BASIC.map((blog, idx) => (
                  <BlogCard
                    key={idx}
                    title={blog.title}
                    body={blog.body}
                    link={blog.link}
                    image={blog.image}
                  />
                ))}
                {myProfileDetails && canShowNTDBanner(myProfileDetails) && (
                  <NTDBanner small variant={2} applicantProfile={myProfileDetails} />
                )}
              </div>
            </div>
            <div className={classes.bottomContainer}>
              <DownloadSorted />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  myProfileDetails: state.myProfileForm,
  selectedProperties: state.landingScreen.properties,
  isUpdating: state.dashboard.isUpdating,
  assets: state.dashboard.assets,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetStore: () => {
    dispatch(DashboardActions.resetSuccess());
    dispatch(GroupsActions.resetSuccess());
    dispatch(LandingScreenActions.clearProperties());
    dispatch(DashboardActions.clearPropertyDetails());
    dispatch(DashboardActions.setIsAcceptingInvitation(false));
    dispatch(DashboardActions.setIsUpdating(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationSent));
