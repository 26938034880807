export enum AGENCIES {
  SORTED = 'SORTED',
  NAX = 'NAX',
  TA = 'TA',
}

export const NAX_LONG = 'nelsonalexander';

export const APP =
  window.location.hostname.toLowerCase().includes('nelsonalexander') &&
  window.location.hash.toLowerCase().includes('signing')
    ? AGENCIES.NAX
    : window.location.hostname.toLowerCase().includes('theagency') &&
      window.location.hash.toLowerCase().includes('signing')
    ? AGENCIES.TA
    : AGENCIES.SORTED;
