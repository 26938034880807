import * as Yup from 'yup';

export const LABELS = {
  title: 'Verify One Time Password (OTP)',
  label:
    'We take the security of your data very seriously. To help keep your account safe, please enter the 6 digit OTP sent to',
  login: 'Log into Sorted',
  otpPlaceholder: 'Enter OTP',
  otpTitle: 'OTP',
  verifyButton: 'Verify',
  DIDNT_RECIEVE_OTP: "Didn't receive the OTP?",
  RESEND_OTP: 'Resend OTP',
  MAXIMUM_RETRIES: 'Maximum retry attempts reached. Please go back to the Login page and try again',
  TRUST_DEVICE: "Don't ask again on this device",
  TRY_AGAIN_IN: 'Please try again in',
};

const ERRORS = {
  requiredOtp: 'OTP is required to login',
  otpLength: 'OTP should be 6 digit long',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  otp: Yup.string().required(ERRORS.requiredOtp).length(6, ERRORS.otpLength),
});
