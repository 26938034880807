import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './DeletedInfoStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './DeletedInfoConstants';
import { routes } from '../../../../Routes';
import { ApplicationState } from '../../../../store/RootReducers';
import { Dispatch } from 'redux';
import { DashboardActions } from '../../../../store/actions/DashboardActions';
import { connect } from 'react-redux';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';

interface InfoRequestedProps extends RouteComponentProps {
  dismissDeletedData: () => void;
}

const InfoRequested: FC<InfoRequestedProps> = ({ history, dismissDeletedData }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text textVariant="contentTitle">{LABELS.TITLE}</Text>
      <Text textVariant="info">{LABELS.SUBTITLE}</Text>
      <Button
        parentStyles={classes.button}
        onPress={() => {
          dismissDeletedData();
          // history.push(routes.myProfile.view(ProfileForm.IDENTIFICATION_FORM));
        }}
      >
        {LABELS.BUTTON}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismissDeletedData: () => {
    dispatch(DashboardActions.dismissDeletedInfoRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoRequested));
