import * as Yup from 'yup';
import { FileLinkOrContent } from '../FileUpload/FileUploadState.data';
import { WeekdayList } from '../../../../helper/WeekdaysList';
import { SelectItem } from '../RenderSelect/RenderSelect';
import { Messages } from '../Messages';
import { isRequired } from '../../../../helper/Validation';
import { GetUserResponse } from '../../../../services/remoteSigning/user/User';

const { validationMessages } = Messages.forms.maintenanceRequestForm;

export interface Tenant extends GetUserResponse {
  tenantType: 'primary' | 'secondary';
}

export enum PropertyAccessType {
  COLLECT_KEY = 'COLLECT_KEY',
  TENANT_AVAILABLE = 'TENANT_AVAILABLE',
  OTHER = 'OTHER',
}

/**
 * will be fetching these details from API later on for now keeping it static
 */
export const MaintenanceRequestLocation: SelectItem[] = [
  {
    label: 'Bedroom',
    value: 'BEDROOM',
  },
  {
    label: 'Living room',
    value: 'LIVING_ROOM',
  },
  {
    label: 'Dining room',
    value: 'DINING_ROOM',
  },
  {
    label: 'Rumpus/Recreation room',
    value: 'RUMPUS_ROOM',
  },
  {
    label: 'Study',
    value: 'STUDY',
  },
  {
    label: 'Kitchen',
    value: 'KITCHEN',
  },
  {
    label: 'Pantry',
    value: 'PANTRY',
  },
  {
    label: 'Laundry',
    value: 'LAUNDRY',
  },
  {
    label: 'Bathroom',
    value: 'BATHROOM',
  },
  {
    label: 'Hallway',
    value: 'HALLWAY',
  },
  {
    label: 'Entrance',
    value: 'ENTRANCE',
  },
  {
    label: 'Outdoor/External',
    value: 'OUTDOOR_EXTERNAL',
  },
  {
    label: 'Pool house',
    value: 'POOLHOUSE',
  },
  {
    label: 'Equipment room',
    value: 'EQUIPMENT_ROOM',
  },
  {
    label: 'Garage',
    value: 'GARAGE',
  },
  {
    label: 'Carport',
    value: 'CARPORT',
  },
  {
    label: 'Shed',
    value: 'SHED',
  },
  {
    label: 'Loft',
    value: 'LOFT',
  },
  {
    label: 'Basement',
    value: 'BASEMENT',
  },
  {
    label: 'Gym',
    value: 'GYM',
  },
  {
    label: 'Sustainability',
    value: 'SUSTAINABILITY',
  },
  {
    label: 'Technology',
    value: 'TECHNOLOGY',
  },
];

export const BooleanLabel: SelectItem[] = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const clearAccessLabel: SelectItem[] = [
  {
    label: 'Yes',
    value: 'false',
  },
  {
    label: 'No',
    value: 'true',
  },
];

export const parkingAvailabilityBooleanLabel: SelectItem[] = [
  {
    label: 'Yes, parking spaces are available',
    value: 'true',
  },
  {
    label: 'No, only street parking in the area',
    value: 'false',
  },
];

export const propertyAccessData: SelectItem[] = [
  {
    label: 'Collect keys from agent',
    value: PropertyAccessType.COLLECT_KEY,
  },
  {
    label: 'Contact the tenant',
    value: PropertyAccessType.TENANT_AVAILABLE,
  },
];

export const TimeSlot: SelectItem[] = [
  {
    label: 'No preference',
    value: 'No preference',
  },
  {
    label: 'Morning (8am-12pm)',
    value: 'Morning',
  },
  {
    label: 'Afternoon (12pm-5pm)',
    value: 'Afternoon',
  },
  {
    label: 'Other (Enter a 4 hour window between 7am and 6pm)',
    value: 'Other',
  },
];

export const Weekdaysdata = WeekdayList.map((val) => ({
  key: val.toUpperCase(),
  title: val,
}));

export interface CreateMaintenanceRequestFormData {
  id?: string;
  tenant?: Tenant;
  location?: string;
  title: string;
  description: string;
  photos: FileLinkOrContent[];
  appliance: {
    present?: boolean;
    make?: string;
    model?: string;
    warranty?: boolean;
  };
  preferredAvailability: {
    timeSlot?: string;
    otherTime?: string;
    days: string[];
  };
  propertyAccess: {
    propertyAccessType?: PropertyAccessType;
    description?: string;
  };
  pet: {
    present?: boolean;
    description?: string;
  };
  clearAccess: boolean;
  stairs: {
    present?: boolean;
    description?: string;
  };
  parkingAvailability: boolean;
  unSupervisedMinors: boolean;
  reCaptchaResponseToken: string;
}

export const placeholders = {
  title: "Give the job a title - eg 'Leaking tap' or 'Broken window'",
  description:
    'Briefly describe the problem, where it is on the premises and any other relevant information',
  timeSolt_otherTime: 'Eg: 2pm onwards only',
  key_loaction: 'Eg: Under the doormat',
  stairs_description: 'Eg: Two flights of stairs, very narrow, inside or outside, etc.',
  appliance: {
    make: 'e.g. Panasonic or Samsung',
    model: 'e.g. EPF12CRN2A or CS/CU-S18PKR',
  },
};

export const labels = {
  formTitle: 'Create maintenance request',
  editFormTitle: 'Edit maintenance request',
  location: 'Where is the issue located?',
  tenant: 'Please select the tenant reporting the issue',
  title: 'Title',
  description: 'Description',
  photos: 'Photos',
  appliance: {
    present: 'Is the request related to an appliance? (Eg: dishwasher, oven, etc.)',
    make: 'Please enter the make',
    model: 'Please enter the model',
    warranty: 'Is the warranty information available?',
  },
  preferredAvailability: {
    label: 'Access to the property',
    timeSlot: 'Preferred time',
    days: 'Preferred day of the week (select multiple)',
  },
  propertyAccess: 'How can the trade access the property?',
  pet: {
    present: 'Is there a dog or any animal onsite?',
    description: 'Pet(s) name and description (all animals must be restrained)',
  },
  clearAccess:
    'Will the trades person need to contact body corporate to access the property or maintenance location (are meter boxes or manholes obstructed?)',
  stairs: {
    present:
      'Are there stairs to access the property which may be an obstacle to completing the required work?',
    description: 'How many stairs are there?',
  },
  parkingAvailability: 'Is there guest parking available on site?',
  unSupervisedMinors:
    'Please confirm no minor under 18 yo will be left unsupervised for the duration of the maintenance booking',
};

export const fields = {
  tenant: 'tenant',
  location: 'location',
  title: 'title',
  description: 'description',
  photos: 'photos',
  appliance: {
    present: 'appliance.present',
    make: 'appliance.make',
    model: 'appliance.model',
    warranty: 'appliance.warranty',
  },
  preferredAvailability: {
    timeSlot: 'preferredAvailability.timeSlot',
    otherTime: 'preferredAvailability.otherTime',
    days: 'preferredAvailability.days',
  },
  propertyAccess: {
    propertyAccessType: 'propertyAccess.propertyAccessType',
    description: 'propertyAccess.description',
  },
  pet: {
    present: 'pet.present',
    description: 'pet.description',
    images: 'pet.images',
  },
  clearAccess: 'clearAccess',
  stairs: {
    present: 'stairs.present',
    description: 'stairs.description',
  },
  parkingAvailability: 'parkingAvailability',
  unSupervisedMinors: 'unSupervisedMinors',
};

export const validation = {
  isRequired: isRequired(validationMessages.required),
};

export const createMaintenanceRequestFormValidations = Yup.object().shape({
  location: Yup.string().required('Location is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  photos: Yup.array().min(1, 'At lease one image is required'),
});
