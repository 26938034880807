import { isNil } from 'lodash';
import {
  AdditionalContact,
  LandlordProperty,
  Owner,
  OwnershipDetails,
} from '../services/remoteSigning/ownership/Ownership';
import { configData } from './dynamicFields';

export enum PROPERTY_SECTIONS {
  details = 'Property Details',
  features = 'Property Features',
  appliances = 'Appliances',
  maintenance = 'Maintenance',
  warranties = 'Property warranties',
  trades = 'Preferred trades',
  conditionDetails = 'Condition details',
  dispursments = 'Disbursements',
  tenancyDetails = 'Tenancy details',
  documents = 'Property documents',
}

export const DETAILS_LABELS = {
  ADDRESS: 'Address',
  DEVELOPMENT: 'Development name(if applicable)',
  BLOCK: 'Block',
  SECTION: 'Section',
  STRATA_MANAGER: 'Strata Manager(if applicable)',
  UPN: 'Unit plan number',
  CONTACT_PHONE: 'Contact phone',
  STARTA_EMAIL: 'Strata contact email',
  HowLongOwnedProperty: 'How long have you owned the property for?',
  AnyInvestmentInUpgradeProperty:
    'In the past 12 months, have you invested in any upgrades on the property?',
  Investmentdetails: 'If yes, please describe the upgrades',
};

export const FEATURES_LABELS = {
  BEDROOM: 'Bedrooms',
  BATHROOM: 'Bathrooms',
  STUDY: 'Study',
  LIVING_SPACE: 'Living spaces',
  LAUNDRY: 'Laundry',
  CAR_SPACE: 'Car spaces',
  CAR_SPACE_TYPE: 'Car space type',
  EER: 'EER',
  ASPECT: 'Aspect',
  STORAGE: 'Storage',
  HOME_SIZE: 'Home size m2',
  BLOCKE_SIZE: 'Block size m2',
  YEAR_BUILT: 'Year built',
  SECURITY_SYSTEM: 'Security system',
  SECURITY_SYSTEM_LOCATION: 'Security system location',
  SECURITY_SYSTEM_CODE: 'Security system code',
};

export const APPLIANCES_LABELS = {
  APPLIANCE: 'Appliance',
  BRAND: 'Brand',
  MODEL: 'Model',
  IsApplianceUnderWarranty: 'Is the appliance under warranty?',
  UPLOAD_DOC: 'Upload document',
};
export const WARRANTY_LABELS = {
  isPropertyCoveredUnderBuilderWarranty: 'Is the property covered by any builders warranty?',
  builderName: 'Who is the builder/construction company',
  docUploadSettlementLetter: 'Please upload a copy of your settlement letter',
  additionalCurrentWarrantyDocUpload: 'Additional warranties',
  warranty: 'Warranty',
  UPLOAD_DOC: 'Upload document',
};

export const TRADES_LABELS = {
  tradeHeader:
    'Please provide contact details for preferred trades such as your accountant or lawyer so we can assist at tax time and provide additional support when requested',
  tradeType: 'Trade type',
  businessName: 'Business name',
  contactName: 'Contact name',
  phoneNumber: 'Contact phone',
  email: 'Contact email',
};
export const TENANCY_DETAILS_LABELS = {
  detailsText:
    'Please provide details for the current tenancy so we can inform the tenants of changes to their rent payments and acquire relevant rental history from your current property manager.',
  primaryTenant: 'Primary Tenant',
  email: 'Contact email',
  leaseStartDate: 'Lease start date',
  leaseEndDate: 'Lease end date',
  phoneNumber: 'Contact phone',
  currentRentAmount: 'Current rent amount',
  paymentFrequency: 'Payment frequency',
  managingAgency: 'Managing agency',
  contactName: 'Contact Name',
};
export const DOCUMENTS_LABEL = {
  docUplodInfo:
    'Documents added to a property will only be reviewed for this property. If this property is duplicated the documents will be copied to all duplicated properties.',
};

const DETAILS_MERGEFIELDS_LABEL = {
  address: DETAILS_LABELS.ADDRESS,
  developmentName: DETAILS_LABELS.DEVELOPMENT,
  block: DETAILS_LABELS.BLOCK,
  section: DETAILS_LABELS.SECTION,
  strataManager: DETAILS_LABELS.STRATA_MANAGER,
  unitPlanNumber: DETAILS_LABELS.UPN,
  phoneNumber: DETAILS_LABELS.CONTACT_PHONE,
  strataEmail: DETAILS_LABELS.STARTA_EMAIL,
  howLongOwnedProperty: DETAILS_LABELS.HowLongOwnedProperty,
  anyInvestmentInUpgradeProperty: DETAILS_LABELS.AnyInvestmentInUpgradeProperty,
  investmentdetails: DETAILS_LABELS.Investmentdetails,
};

const FEATURES_MERGEFIELDS = {
  bedrooms: FEATURES_LABELS.BEDROOM,
  bathrooms: FEATURES_LABELS.BATHROOM,
  study: FEATURES_LABELS.STUDY,
  livingSpace: FEATURES_LABELS.LIVING_SPACE,
  laundry: FEATURES_LABELS.LAUNDRY,
  carSpaces: FEATURES_LABELS.CAR_SPACE,
  carSpaceType: FEATURES_LABELS.CAR_SPACE_TYPE,
  eer: FEATURES_LABELS.EER,
  aspect: FEATURES_LABELS.ASPECT,
  storage: FEATURES_LABELS.STORAGE,
  homeSize: FEATURES_LABELS.HOME_SIZE,
  blockSize: FEATURES_LABELS.BLOCKE_SIZE,
  yearBuilt: FEATURES_LABELS.YEAR_BUILT,
  securitySystem: FEATURES_LABELS.SECURITY_SYSTEM,
  securitySystemLocation: FEATURES_LABELS.SECURITY_SYSTEM_LOCATION,
  securitySystemCode: FEATURES_LABELS.SECURITY_SYSTEM_CODE,
};
const APPLIANCE_MERGEFIELDS = {
  appliance: 'All Appliance',
};
const TRADES_MERGEFIELDS = {
  trades: 'All Trades',
};
const TENANT_MERGEFIELDS = {
  primaryTenant: 'Primary Tenant',
};
const WARRANTY_MERGEFIELDS = {
  isPropertyCoveredUnderBuilderWarranty: WARRANTY_LABELS.isPropertyCoveredUnderBuilderWarranty,
  builderName: WARRANTY_LABELS.builderName,
  additionalCurrentWarrantyDocUpload: 'All additional warranty(s)',
};

const getDynamicFields = (data: any) => {
  let dataList = {};

  data.forEach((element: any) => {
    dataList[element.name] = element.label;
  });

  return dataList;
};
const MAINTAINANCE_MERGEFIELDS = {
  ...getDynamicFields(configData.maintenance),
  ...TRADES_MERGEFIELDS,
};

const CONDITION_DETAILS_MERGE = { ...getDynamicFields(configData.conditionDetails) };
const DISPURSMENTS_MERGE = { ...getDynamicFields(configData.dispursments) };
const TENANCY_DETAILS_MERGE = {
  ...getDynamicFields(configData.tenancyDetails),
  ...TENANT_MERGEFIELDS,
};

const MAIL_MERGEFIELDS_LABEL = {
  ...DETAILS_MERGEFIELDS_LABEL,
  ...FEATURES_MERGEFIELDS,
  ...APPLIANCE_MERGEFIELDS,
  ...WARRANTY_MERGEFIELDS,
  ...MAINTAINANCE_MERGEFIELDS,
  ...CONDITION_DETAILS_MERGE,
  ...DISPURSMENTS_MERGE,
  ...TENANCY_DETAILS_MERGE,
};

export const OPPORTUNITY_PROPERTY_MAIL_MERGEFIELDS = Object.entries(MAIL_MERGEFIELDS_LABEL).map(
  ([key, value]) => {
    return {
      label: value,
      value: `Opportunity_Property:${key}`,
      placeHolder: value,
    };
  },
);
const booleanYesNoConvert = (value: boolean | string | undefined | null): string => {
  if (value) return value.toString().toUpperCase() === 'TRUE' ? 'Yes' : 'No';
  return 'No';
};
const checkUndefined = (value: string | undefined | null) => {
  return isNil(value) ? 'NA' : value;
};
const getDynamicWidgetData = (section: PROPERTY_SECTIONS, sectionData: any) => {
  let data = {};
  switch (section) {
    case PROPERTY_SECTIONS.maintenance:
      Object.entries(sectionData).map(([key, value]) => {
        Object.entries(MAINTAINANCE_MERGEFIELDS).map(([fieldkey, fieldvalue]) => {
          if (key === fieldkey && value) data[fieldvalue] = value;
        });
      });
      break;
    case PROPERTY_SECTIONS.conditionDetails:
      Object.entries(sectionData).map(([key, value]) => {
        Object.entries(CONDITION_DETAILS_MERGE).map(([fieldkey, fieldvalue]) => {
          if (key === fieldkey && value) data[fieldvalue as string] = value;
        });
      });
      break;
    case PROPERTY_SECTIONS.dispursments:
      Object.entries(sectionData).map(([key, value]) => {
        Object.entries(DISPURSMENTS_MERGE).map(([fieldkey, fieldvalue]) => {
          if (key === fieldkey && value) data[fieldvalue as string] = value;
        });
      });
      break;
    case PROPERTY_SECTIONS.tenancyDetails:
      Object.entries(sectionData).map(([key, value]) => {
        Object.entries(TENANCY_DETAILS_MERGE).map(([fieldkey, fieldvalue]) => {
          if (key === fieldkey && value) data[fieldvalue] = value;
        });
      });
      break;
    default:
      return data;
  }

  return data;
};

const OWNERSHIP_MAIL_MERGEFIELDS_LABEL = {
  displayName: 'Display Name',
  ownershipType: 'Ownership Type',
  corporationName: 'Corporation Name',
  acn: 'ACN',
  abn: 'ABN',
  phoneNumber: 'Contact number',
  email: 'Contact Email',
  bankName: 'Bank name',
  bsbNumber: 'Bsb Number',
  accountNumber: 'Account Number',
  accountName: 'Account name',
  isSpecificOwnerResponsible: 'Specified Owner Responsible',
  isOwnerSignatureRequired: 'Owner signature required',
  additionalOwners: 'All owner(s) details',
  additionalContacts: 'All contact(s) details',
};

export const OWNERSHIP_MAIL_MERGEFIELDS = [
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.displayName,
    value: 'Ownership:displayName',
    placeHolder: 'Ownership Display Name',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.ownershipType,
    value: 'Ownership:ownershipType',
    placeHolder: 'Ownership Type',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.corporationName,
    value: 'Ownership:corporationName',
    placeHolder: 'Ownership Corporation Name',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.acn,
    value: 'Ownership:acn',
    placeHolder: 'Ownership ACN',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.abn,
    value: 'Ownership:abn',
    placeHolder: 'Ownership ABN',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.phoneNumber,
    value: 'Ownership:phoneNumber',
    placeHolder: 'Ownership Contact number',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.email,
    value: 'Ownership:email',
    placeHolder: 'Ownership Contact Email',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.bankName,
    value: 'Ownership:bankName',
    placeHolder: 'Ownership Bank name',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.bsbNumber,
    value: 'Ownership:bsbNumber',
    placeHolder: 'Ownership Bsb number',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.accountNumber,
    value: 'Ownership:accountNumber',
    placeHolder: 'Ownership Account number',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.accountName,
    value: 'Ownership:accountName',
    placeHolder: 'Ownership Account name',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.isSpecificOwnerResponsible,
    value: 'Ownership:isSpecificOwnerResponsible',
    placeHolder: 'Ownership Specified owner responsible',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.isOwnerSignatureRequired,
    value: 'Ownership:isOwnerSignatureRequired',
    placeHolder: 'Ownership Owner signature required',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.additionalOwners,
    value: 'Ownership:additionalOwners',
    placeHolder: 'Additional Owner(s)',
  },
  {
    label: OWNERSHIP_MAIL_MERGEFIELDS_LABEL.additionalContacts,
    value: 'Ownership:additionalContacts',
    placeHolder: 'Additional Contact(s)',
  },
];

export const OWNERS_INSTRUCTIONS_MERGE_FIELDS = [
  {
    label: 'Property Management Services',
    value: 'Owners_Instructions:propertyManagementServices',
    placeholder: 'Property Management Services',
  },
  {
    label: 'Electrical Safety Check',
    value: 'Owners_Instructions:electricalSafetyCheck',
    placeholder: 'Electrical Safety Check',
  },
  {
    label: 'Gas Safety Check',
    value: 'Owners_Instructions:gasSafetyCheck',
    placeholder: 'Gas Safety Check',
  },
  {
    label: 'Standard Safety Check',
    value: 'Owners_Instructions:standardSafetyCheck',
    placeholder: 'Standard Safety Check',
  },
  {
    label: 'Smoke Safety Check',
    value: 'Owners_Instructions:smokeSafetyCheck',
    placeholder: 'Smoke Safety Check',
  },
  {
    label: 'Accounting Services - Owners Name',
    value: 'Owners_Instructions:accountingServicesOwnersName',
    placeholder: 'Accounting Services - Owners Name',
  },
  {
    label: 'Accounting Services Phone',
    value: 'Owners_Instructions:accountingServicesPhone',
    placeholder: 'Accounting Services Phone',
  },
  {
    label: 'Accounting Services Email',
    value: 'Owners_Instructions:accountingServicesEmail',
    placeholder: 'Accounting Services Email',
  },
  {
    label: 'Accounting Services Address',
    value: 'Owners_Instructions:accountingServicesAddress',
    placeholder: 'Accounting Services Address',
  },
];

export const PRIMARY_OWNER_MERGE_FIELDS = [
  {
    label: 'Emergency Contact Name',
    value: 'Ownership_Primary_Owner:emergencyContactName',
    placeholder: 'Emergency Contact Name',
  },
  {
    label: 'Emergency Contact Relationship',
    value: 'Ownership_Primary_Owner:emergencyContactRelationship',
    placeholder: 'Emergency Contact Relationship',
  },
  {
    label: 'Emergency Contact Phone',
    value: 'Ownership_Primary_Owner:emergencyContactPhone',
    placeholder: 'Emergency Contact Phone',
  },
  {
    label: 'Emergency Contact Email',
    value: 'Ownership_Primary_Owner:emergencyContactEmail',
    placeholder: 'Emergency Contact Email',
  },
  {
    label: 'Emergency Contact Address',
    value: 'Ownership_Primary_Owner:emergencyContactAddress',
    placeholder: 'Emergency Contact Address',
  },
  {
    label: 'Representative Name',
    value: 'Ownership_Primary_Owner:representativeName',
    placeholder: 'Representative Name',
  },
  {
    label: 'Representative Contact',
    value: 'Ownership_Primary_Owner:representativeContact',
    placeholder: 'Representative Contact',
  },
  {
    label: 'Representative Email',
    value: 'Ownership_Primary_Owner:representativeEmail',
    placeholder: 'Representative Email',
  },
  {
    label: 'Representative Address',
    value: 'Ownership_Primary_Owner:representativeAddress',
    placeholder: 'Representative Address',
  },
];

export const AUTHORITY_FEE_MERGE_FIELDS = [
  {
    label: 'Leasing Fee Percent',
    value: 'Authority_Fee:leasingFeePercent',
    placeholder: 'Leasing Fee Percent',
  },
  {
    label: 'Leasing Fee Custom Calculation',
    value: 'Authority_Fee:leasingFeeCustomCalculation',
    placeholder: 'Leasing Fee Custom Calculation',
  },
  {
    label: 'Leasing Fee Dollar Amount',
    value: 'Authority_Fee:leasingFeeDollarAmount',
    placeholder: 'Leasing Fee Dollar Amount',
  },
  {
    label: 'Leasing Fee Gst',
    value: 'Authority_Fee:leasingFeeGst',
    placeholder: 'Leasing Fee Gst',
  },
  {
    label: 'Leasing Fee Rent',
    value: 'Authority_Fee:leasingFeeRent',
    placeholder: 'Leasing Fee Rent',
  },
  {
    label: 'Leasing Fee Frequency',
    value: 'Authority_Fee:leasingFeeFrequency',
    placeholder: 'Leasing Fee Frequency',
  },
  {
    label: 'Re-Leasing Fee Percent',
    value: 'Authority_Fee:reLeasingFeePercent',
    placeholder: 'Re-Leasing Fee Percent',
  },
  {
    label: 'Re-Leasing Fee Custom Calculation',
    value: 'Authority_Fee:reLeasingFeeCustomCalculation',
    placeholder: 'Re-Leasing Fee Custom Calculation',
  },
  {
    label: 'Re-Leasing Fee Dollar Amount',
    value: 'Authority_Fee:reLeasingFeeDollarAmount',
    placeholder: 'Re-Leasing Fee Dollar Amount',
  },
  {
    label: 'Re-Leasing Fee Gst',
    value: 'Authority_Fee:reLeasingFeeGst',
    placeholder: 'Re-Leasing Fee Gst',
  },
  {
    label: 'Re-Leasing Fee Rent',
    value: 'Authority_Fee:reLeasingFeeRent',
    placeholder: 'Re-Leasing Fee Rent',
  },
  {
    label: 'Re-Leasing Fee Frequency',
    value: 'Authority_Fee:reLeasingFeeFrequency',
    placeholder: 'Re-Leasing Fee Frequency',
  },
  {
    label: 'Management Fee Percent',
    value: 'Authority_Fee:managementFeePercent',
    placeholder: 'Management Fee Percent',
  },
  {
    label: 'Management Fee Custom Calculation',
    value: 'Authority_Fee:managementFeeCustomCalculation',
    placeholder: 'Management Fee Custom Calculation',
  },
  {
    label: 'Management Fee Dollar Amount',
    value: 'Authority_Fee:managementFeeDollarAmount',
    placeholder: 'Management Fee Dollar Amount',
  },
  {
    label: 'Management Fee Gst',
    value: 'Authority_Fee:managementFeeGst',
    placeholder: 'Management Fee Gst',
  },
  {
    label: 'Management Fee Rent',
    value: 'Authority_Fee:managementFeeRent',
    placeholder: 'Management Fee Rent',
  },
  {
    label: 'Management Fee Frequency',
    value: 'Authority_Fee:managementFeeFrequency',
    placeholder: 'Management Fee Frequency',
  },
];

export const AUTHORITY_MERGE_FIELDS = [
  { label: 'Rent Amount', value: 'Authority:rentAmount', placeholder: 'Rent Amount' },
  { label: 'Rent Frequency', value: 'Authority:rentFrequency', placeholder: 'Rent Frequency' },
  {
    label: 'Exclusive Authority Period',
    value: 'Authority:exclusiveAuthorityPeriod',
    placeholder: 'Exclusive Authority Period',
  },
  {
    label: 'Continuing Authority Period',
    value: 'Authority:continuingAuthorityPeriod',
    placeholder: 'Continuing Authority Period',
  },
  { label: 'Bond Amount', value: 'Authority:bondAmount', placeholder: 'Bond Amount' },
  { label: 'Bond Type', value: 'Authority:bondType', placeholder: 'Bond Type' },
  { label: 'Urgent Repairs', value: 'Authority:urgentRepairs', placeholder: 'Urgent Repairs' },
  { label: 'Agents Services', value: 'Authority:agentsServices', placeholder: 'Agents Services' },
  {
    label: 'Advertising Fees',
    value: 'Authority:advertisingFees',
    placeholder: 'Advertising Fees',
  },
  { label: 'Safety Checks', value: 'Authority:safetyChecks', placeholder: 'Safety Checks' },
  {
    label: 'Other Marketing Fees',
    value: 'Authority:otherMarketingFees',
    placeholder: 'Other Marketing Fees',
  },
  {
    label: 'Commission Sharers',
    value: 'Authority:commissionSharers',
    placeholder: 'Commission Sharers',
  },
];

export const propertyMailMergeFields = (
  ownership: OwnershipDetails,
  { property }: LandlordProperty,
) => {
  if (!ownership) return ownership;
  if (!property.details.address) return property;
  const {
    details,
    features,
    appliances,
    warranties,
    maintenance,
    conditionDetails,
    dispursments,
    tenancyDetails,
    trades,
  } = property;
  const applianceValues: string[] = [];
  const additionalWarrantyDoc: string[] = [];
  const tradesData: string[] = [];
  let tenantDetails = '';
  const ownerList: string[] = [];
  const contactList: string[] = [];
  const { additionalOwners, additionalContacts } = ownership;
  if (additionalOwners) {
    additionalOwners.forEach((o: Owner) => {
      ownerList.push(
        `Name: ${o.firstName} ${o.lastName} Email: ${o.email} PhoneNumber:  ${o.postalAddress}`,
      );
    });
  }
  if (additionalContacts) {
    additionalContacts.forEach((u: AdditionalContact) => {
      ownerList.push(`${u.contactName}`);
    });
  }

  const detailsData = {
    [MAIL_MERGEFIELDS_LABEL.address]: details.address,
    [MAIL_MERGEFIELDS_LABEL.developmentName]: checkUndefined(details.developmentName),
    [MAIL_MERGEFIELDS_LABEL.block]: checkUndefined(details.block),
    [MAIL_MERGEFIELDS_LABEL.section]: checkUndefined(details.section),
    [MAIL_MERGEFIELDS_LABEL.strataManager]: checkUndefined(details.strataManager),
    [MAIL_MERGEFIELDS_LABEL.unitPlanNumber]: checkUndefined(details.unitPlanNumber),
    [MAIL_MERGEFIELDS_LABEL.phoneNumber]: checkUndefined(details.phoneNumber),
    [MAIL_MERGEFIELDS_LABEL.strataEmail]: checkUndefined(details.strataEmail),
    [MAIL_MERGEFIELDS_LABEL.howLongOwnedProperty]: checkUndefined(details.howLongOwnedProperty),
    [MAIL_MERGEFIELDS_LABEL.anyInvestmentInUpgradeProperty]: booleanYesNoConvert(
      details.anyInvestmentInUpgradeProperty,
    ),
    [MAIL_MERGEFIELDS_LABEL.investmentdetails]: checkUndefined(details.investmentdetails),
  };
  const featureData = {
    [FEATURES_MERGEFIELDS.bedrooms]: features.bedrooms,
    [FEATURES_MERGEFIELDS.bathrooms]: features.bathrooms,
    [FEATURES_MERGEFIELDS.study]: features.study,
    [FEATURES_MERGEFIELDS.livingSpace]: features.livingSpace,
    [FEATURES_MERGEFIELDS.laundry]: features.laundry,
    [FEATURES_MERGEFIELDS.carSpaces]: features.carSpaces,
    [FEATURES_MERGEFIELDS.carSpaceType]: checkUndefined(features.carSpaceType),
    [FEATURES_MERGEFIELDS.eer]: checkUndefined(features.eer),
    [FEATURES_MERGEFIELDS.aspect]: features.aspect,
    [FEATURES_MERGEFIELDS.storage]: features.storage,
    [FEATURES_MERGEFIELDS.homeSize]: features.homeSize,

    [FEATURES_MERGEFIELDS.blockSize]: features.blockSize,
    [FEATURES_MERGEFIELDS.yearBuilt]: features.yearBuilt,

    [FEATURES_MERGEFIELDS.securitySystem]: features.securitySystem,
    [FEATURES_MERGEFIELDS.securitySystemLocation]: features.securitySystemLocation,
    [FEATURES_MERGEFIELDS.securitySystemCode]: features.securitySystemCode,
  };
  if (appliances.length > 0) {
    appliances.forEach((appliance, index) => {
      applianceValues.push(
        `${APPLIANCES_LABELS.APPLIANCE} ${index + 1}: ${checkUndefined(appliance.applianceName)}, ${
          APPLIANCES_LABELS.BRAND
        }: ${checkUndefined(appliance.brand)}, ${APPLIANCES_LABELS.MODEL}: ${checkUndefined(
          appliance.model,
        )}, ${APPLIANCES_LABELS.IsApplianceUnderWarranty}:${booleanYesNoConvert(
          appliance.isApplianceUnderWarranty,
        )}<br/>`,
      );
    });
  }
  const applianceData = { [APPLIANCE_MERGEFIELDS.appliance]: applianceValues };

  /* warranties */
  if (warranties.additionalCurrentWarrantyDocUpload) {
    warranties.additionalCurrentWarrantyDocUpload.forEach((doc, index) => {
      additionalWarrantyDoc.push(
        `${WARRANTY_LABELS.warranty} ${index + 1}: ${checkUndefined(doc.name)}, Document:  ${
          doc.document.length > 0 ? checkUndefined(doc.document[0].name) : 'NA'
        }`,
      );
    });
  }

  const warrntyData = {
    [WARRANTY_MERGEFIELDS.isPropertyCoveredUnderBuilderWarranty]: booleanYesNoConvert(
      warranties.isPropertyCoveredUnderBuilderWarranty,
    ),
    [WARRANTY_MERGEFIELDS.builderName]: warranties.builderName,
    [WARRANTY_MERGEFIELDS.additionalCurrentWarrantyDocUpload]: additionalWarrantyDoc,
  };

  if (trades.length > 0) {
    trades.forEach((trader, index) => {
      const values = [
        `Trade${index + 1}, Business name : ${checkUndefined(
          trader.businessName,
        )} Contact name: ${checkUndefined(trader.contactName)} Type: ${checkUndefined(
          trader.tradeType,
        )}
    Phone number: ${checkUndefined(trader.phoneNumber)}
    Email: ${checkUndefined(trader.email)} `,
      ];

      tradesData.push(values.join('</br>').concat('</br>'));
    });
    tradesData.join('</br>');
  }
  if (tenancyDetails.primaryTenant) {
    tenantDetails = `Primary Tenant<br/>&nbsp;&nbsp;${
      TENANCY_DETAILS_LABELS.primaryTenant
    }:${checkUndefined(tenancyDetails.primaryTenant.primaryTenant)}<br/>&nbsp;&nbsp;${
      TENANCY_DETAILS_LABELS.leaseStartDate
    }:${checkUndefined(tenancyDetails.primaryTenant.leaseStartDate)}&nbsp;${
      TENANCY_DETAILS_LABELS.leaseEndDate
    }:${checkUndefined(tenancyDetails.primaryTenant.leaseEndDate)}<br/>
    &nbsp;&nbsp;${TENANCY_DETAILS_LABELS.currentRentAmount}:${
      tenancyDetails.primaryTenant.currentRentAmount
    }&nbsp;${TENANCY_DETAILS_LABELS.paymentFrequency}:${checkUndefined(
      tenancyDetails.primaryTenant.paymentFrequency,
    )}<br/>&nbsp;&nbsp;${TENANCY_DETAILS_LABELS.email}:${tenancyDetails.primaryTenant.email}&nbsp;${
      TENANCY_DETAILS_LABELS.phoneNumber
    }:${checkUndefined(tenancyDetails.primaryTenant.phoneNumber)}<br/>
    
    Managing Agency<br/>&nbsp;&nbsp;${TENANCY_DETAILS_LABELS.managingAgency}:${checkUndefined(
      tenancyDetails.managingAgency.agencyName,
    )}&nbsp;&nbsp;${TENANCY_DETAILS_LABELS.contactName}:${checkUndefined(
      tenancyDetails.managingAgency.contactName,
    )}<br/>&nbsp;&nbsp;${TENANCY_DETAILS_LABELS.email}:${
      tenancyDetails.managingAgency.contactEmail
    }&nbsp;${TENANCY_DETAILS_LABELS.phoneNumber}:${checkUndefined(
      tenancyDetails.managingAgency.contactPhone,
    )}<br/>`;
  }

  const maintenanceData = getDynamicWidgetData(PROPERTY_SECTIONS.maintenance, maintenance);
  const conditionData = getDynamicWidgetData(PROPERTY_SECTIONS.conditionDetails, conditionDetails);
  const dispursementData = getDynamicWidgetData(PROPERTY_SECTIONS.dispursments, dispursments);
  const tenancyDetailsData = getDynamicWidgetData(PROPERTY_SECTIONS.tenancyDetails, tenancyDetails);
  const tradeDetailsData = { [TRADES_MERGEFIELDS.trades]: tradesData };

  return {
    Ownership: {
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.displayName]: ownership.displayName,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.ownershipType]: ownership.ownershipType,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.corporationName]: ownership.corporationName,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.acn]: ownership.acn,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.abn]: ownership.abn,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.phoneNumber]: ownership.phoneNumber,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.email]: ownership.email,

      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.bankName]: ownership.bankName,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.bsbNumber]: ownership.bsbNumber,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.accountNumber]: ownership.accountNumber,

      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.accountName]: ownership.accountName,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.isSpecificOwnerResponsible]:
        ownership.isSpecificOwnerResponsible,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.isOwnerSignatureRequired]:
        ownership.isOwnerSignatureRequired,

      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.additionalOwners]: ownerList,
      [OWNERSHIP_MAIL_MERGEFIELDS_LABEL.additionalContacts]: contactList,
    },
    Opportunity_Property: {
      ...detailsData,
      ...featureData,
      ...applianceData,
      ...warrntyData,
      ...maintenanceData,
      ...conditionData,
      ...dispursementData,
      ...tenancyDetailsData,
      ...tradeDetailsData,
      [TENANT_MERGEFIELDS.primaryTenant]: tenantDetails,
    },
  };
};
