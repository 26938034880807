import React, { FC } from 'react';
import { useStyles } from './DisclosurePageStyles';
import Questionnaire from './Questionnaire';
import { GetUserResponse } from '../../services/remoteSigning/user/User';
import { DisclosureFormQuestion } from '../../services/disclosure/Disclosure';
import { QuestionnaireData } from './QuestionnaireConstants';
import { DISCLOSURE_STEPS } from '../../container/disclosure/Disclosure';
import DisclosureSignatureBox from './DisclosureSignatureBox';
import { Content } from '../../helper/Content/Content.data';
import AgencyBackground from '../agencyBackground/AgencyBackground';
import ProgressBar from '../progressBar/ProgressBar';
import UploadingScreen from '../../container/remoteSigning/components/UploadingScreen/UploadingScreen';
import { Messages } from '../../container/remoteSigning/RemoteSigningConstants';
import { AgencyDetails } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

interface DisclosurePageProps {
  currentStep: number;
  questionnaire?: DisclosureFormQuestion[];
  user?: GetUserResponse;
  agency?: AgencyDetails;
  isPrimaryLessor?: boolean;
  comments?: string;
  onSubmitQuestions: (data: QuestionnaireData) => void;
  onSubmitSignatures: (signatures: Content[]) => void;
}

const DisclosurePage: FC<DisclosurePageProps> = ({
  user,
  agency,
  questionnaire,
  currentStep,
  isPrimaryLessor,
  comments,
  onSubmitQuestions,
  onSubmitSignatures,
}) => {
  const classes = useStyles();

  const {
    uploadingScreen,
    errorScreen,
    appointmentScreen,
    loadingScreen,
    alreadySignedScreen,
    successScreen,
  } = Messages.pages;

  const renderInContainer = (children: JSX.Element) => {
    return (
      <div className={classes.root}>
        <AgencyBackground />
        <div className={classes.pageContainer}>
          <div className={classes.contentContainer}>
            <ProgressBar activeStep={currentStep} steps={['Disclosure', 'Sign', 'Done']} />

            {children}
          </div>
        </div>
      </div>
    );
  };

  const renderScreen = () => {
    switch (currentStep) {
      case DISCLOSURE_STEPS.QUESTIONNAIRE:
        if (questionnaire) {
          return renderInContainer(
            <Questionnaire
              questionnaire={questionnaire}
              onSubmitQuestions={onSubmitQuestions}
              isPrimaryLessor={isPrimaryLessor}
            />,
          );
        }
        break;

      case DISCLOSURE_STEPS.SIGNATURE:
        return renderInContainer(
          <DisclosureSignatureBox
            user={user!!}
            agency={agency!!}
            onSubmit={onSubmitSignatures}
            comments={comments}
          />,
        );

      case DISCLOSURE_STEPS.SUCCESS:
        return (
          <UploadingScreen
            icon={successScreen.titles.icon}
            iconType={successScreen.titles.iconType}
            title={successScreen.titles.successTitle}
            primaryText={successScreen.helperText.successText}
            dontInvert
          />
        );

      case DISCLOSURE_STEPS.UPLOADING:
        return (
          <UploadingScreen
            icon={uploadingScreen.titles.icon}
            iconType={uploadingScreen.titles.iconType}
            title={uploadingScreen.titles.uploadTitle}
            primaryText={uploadingScreen.helperText.uploadText}
          />
        );

      case DISCLOSURE_STEPS.LOADING:
        return (
          <UploadingScreen
            icon={loadingScreen.titles.icon}
            iconType={loadingScreen.titles.iconType}
            title={loadingScreen.titles.loadingTitle}
            dontInvert
          />
        );

      case DISCLOSURE_STEPS.TOKEN_EXPIRED:
        return (
          <UploadingScreen
            icon={appointmentScreen.titles.icon}
            iconType={appointmentScreen.titles.iconType}
            title={appointmentScreen.titles.appointmentTitle}
            primaryText={appointmentScreen.helperText.contactText}
          />
        );

      case DISCLOSURE_STEPS.ALREADY_SIGNED:
        return (
          <UploadingScreen
            icon={alreadySignedScreen.titles.icon}
            iconType={alreadySignedScreen.titles.iconType}
            title={alreadySignedScreen.titles.signedTitle}
            primaryText={alreadySignedScreen.helperText.signText}
          />
        );

      case DISCLOSURE_STEPS.ERROR:
      default:
        break;
    }
    return (
      <UploadingScreen
        icon={errorScreen.titles.icon}
        iconType={errorScreen.titles.iconType}
        title={errorScreen.titles.errorTitle}
        primaryText={errorScreen.helperText.errorText}
      />
    );
  };

  return renderScreen();
};

export default DisclosurePage;
