/* eslint-disable max-lines */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  card: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.colors.light,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  cardNoShadow: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  contentContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingBottom: '35px',
    },
  },
  inputContainerStyle: {
    paddingBottom: '8px',
    width: '100%',
  },
  inputLabel: {
    'top': '-8px',
    'fontSize': '15px',
    'color': theme.colors.sortedPurple,

    '& > h6': {
      fontSize: '18px',
      color: theme.colors.sortedPurple,
    },
  },
  dateInputLabel: {
    top: '-6px',
    position: 'relative',
    fontSize: '15px',
    color: theme.colors.sortedPurple,
  },
  inputLabelStyle: {
    paddingBottom: '15px',
    color: theme.colors.black87,
  },
  nameContainer: {
    paddingBottom: '10px',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
    },
  },
  otherNameContainer: {
    paddingTop: '25px',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
    },
  },
  nameStyles: {
    [`@media (max-width: ${SIZES.sm}px)`]: {
      maxWidth: '100%',
      width: '100%',
      flexBasis: 'unset',
      flexGrow: 'unset',
      paddingBottom: '5px !important',
    },
  },
  dateInputLabelStyle: {
    '& input': {
      paddingLeft: '16px',
      color: 'transparent',
      textShadow: '0 0 0 black',
    },
  },
  errorText: {
    color: theme.colors.error,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whoWillBeLivingThereLabelStyle: {
    padding: '40px 0px 22px 16px',
  },
  dependentLabel: {
    paddingRight: '45px',
    color: theme.colors.black87,
  },
  toggleContainer: {
    marginTop: '10px',
    marginLeft: '-12px',
    marginBottom: '-12px',
  },

  groupPetIconStyle: {
    paddingRight: '16px',
  },
  buttonWrapper: {
    width: '100%',
    maxWidth: '540px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backButtonWrapper: {
    width: '100%',
    maxWidth: '540px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  dateIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '-12px',
  },
  button: {
    height: 48,
    background: theme.colors.sortedPurple,
    margin: '56.3px 16px 35px',
  },
  buttonText: {
    color: theme.colors.light,
  },
  overlay: {
    float: 'right',
    right: 0,
  },
  counter: {
    minWidth: '40px',
    textAlign: 'center',
    fontWeight: 600,
  },
  gravatar: {
    height: '80px',
    width: '80px',
    zIndex: 2,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '24px',
    border: `${theme.colors.light} 1px solid`,
    flexShrink: 0,
  },
  coApplicantTitleContainer: {
    marginLeft: theme.units.spacing * 4,
  },
  basicDetails: {
    marginBottom: theme.units.spacing * 2,
  },
  applicationCardsTitle: {
    marginBottom: theme.units.spacing * 4,
  },
  applicationCardsSubtitle: {
    fontSize: '16px',
    fontWeight: 100,
    color: theme.colors.black87,
  },
  howManyOthersContainer: {
    marginTop: theme.units.spacing * 6,
    borderTop: `2px ${theme.colors.borderColor} solid`,
    paddingTop: theme.units.spacing * 8,
    paddingBottom: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  noTopSpace: {
    marginTop: 'unset',
    borderTop: 'unset',
    paddingTop: '10px',
  },
  noTopSpaceAtAll: {
    marginTop: 'unset',
    borderTop: 'unset',
    paddingTop: 'unset',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  howManyOthers: {
    fontWeight: 500,
  },
  counterWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '15px',
    },
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  numberChangeStyle: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  hide: {
    display: 'none',
  },
  datePicker: {
    width: '100%',
    paddingBottom: '10px',
  },
  signUpContentStyle: {
    paddingBottom: '20px',
    color: theme.colors.buttonPrimary,
  },
  confirmationContentStyle: {
    paddingBottom: '20px',
    color: theme.colors.error,
  },
  signUpButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  continueAsGuestButton: {
    borderRadius: '4px',
    border: '1px solid #2ca7c0',
    marginRight: '20px',
    height: 32,
  },
  signUpButton: {
    borderRadius: '4px',
    backgroundColor: theme.colors.buttonPrimary,
    height: 32,
  },
  continueAsGuestLabel: {
    color: theme.colors.buttonPrimary,
  },
  signUpLabel: {
    color: theme.colors.light,
  },
  errorMsgStyle: {
    paddingBottom: '10px',
  },
  resendLinkStyle: {
    cursor: 'pointer',
    color: theme.colors.buttonPrimary,
    textDecoration: 'underline',
  },
  dependentToggle: {
    width: '100%',
    position: 'relative',
  },
  otherApplicantPaper: {
    padding: '20px',
    position: 'relative',
    marginBottom: 20,
    borderRadius: '6px',
  },
  marginRight: {
    marginRight: 30,
  },
  additionalPersonInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  additionalPeopleTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.units.spacing * 4,
    borderBottom: `2px ${theme.colors.borderColor} solid`,
    marginBottom: theme.units.spacing * 4,
  },
  additionalPeopleTitle: {
    fontSize: '20px',
    color: theme.colors.black87,
  },
  isCompletingProfile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  underAge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  crossLogo: {
    width: 40,
    height: 40,
    cursor: 'pointer',
    marginLeft: 'auto',
    opacity: 0.6,
  },
  invitationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '70px',
    borderRadius: '8px',
    backgroundColor: theme.colors.fadedPurple,
    marginTop: '15px',
  },
  invitationText: {
    fontSize: theme.font.m,
    color: theme.colors.sortedPurple,
    fontWeight: 500,
  },
  mailIcon: {
    width: '25px',
    height: '25px',
    marginRight: '20px',
    marginLeft: '30px',
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: theme.units.borderRadius / 1.5,
    color: theme.colors.black87,
    fontWeight: 500,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
    },
  },
  primaryButton: {
    minWidth: 200,
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
  },
  disabledBottomBorder: {
    borderBottom: '2px dotted rgba(0, 0, 0, 0.15) !important',
  },
  applicationProfileCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addProfileIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '9px',
    marginRight: '10px',
  },
  addProfileText: {
    marginRight: 'auto',
    marginLeft: '20px',
    fontSize: '18px',
    color: theme.colors.black87,
  },
  plusIcon: {
    width: '25px',
    height: '25px',
    marginLeft: '15px',
    marginRight: '5px',
  },
  inputGroupNameLabelStyle: {
    paddingBottom: '5px',
    marginTop: '5px',
    marginBottom: '20px',
    color: theme.colors.black87,
    width: '50%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
    },
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dropdownIcon: {
    width: '25px',
    height: '25px',
    marginLeft: '15px',
    marginRight: '5px',
    transition: 'all 0.5s ease 0s',
  },
  selectedDropdownIcon: {
    transition: 'all 0.5s ease 0s',
    transform: 'rotate(180deg)',
  },
  hideText: {
    opacity: 0,
    transition: 'all 0.5s ease 0s',
  },
  hideContainer: {
    maxHeight: '25px',
    overflow: 'hidden',
    transition: 'max-height 0.5s ease 0s',
  },
  dontHide: {
    overflow: 'hidden',
    transition: 'max-height 0.5s ease 0s',
  },
  // NEW STYLES
  boxStyles: {
    marginTop: theme.units.spacing * 4,
  },
  basicDetailsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
  },
  lastNameInput: {
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginLeft: 0,
    },
  },
  titleAndFirstName: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});
