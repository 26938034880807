import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  registrationConfirmationContainer: {
    backgroundColor: theme.colors.light,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  registrationConfirmationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.colors.light,
    maxWidth: '450px',
    width: '100%',
    height: 'max-content',
    marginTop: 100,
    padding: 15,
  },
  successLogo: {
    width: '30px',
    objectFit: 'contain',
    margin: 20,
  },
  titleText: {
    fontWeight: 500,
    fontSize: '34px',
    marginBottom: 20,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      fontSize: '24px',
    },
  },
  bodyText: {
    fontSize: '12px',
    textAlign: 'center',
    width: '80%',
    marginBottom: 30,
  },
  buttonContainer: {
    marginBottom: 30,
  },
  loginButton: {
    padding: '7px 28px',
    backgroundColor: theme.colors.sortedPurple,
    '&:hover': {
      backgroundColor: theme.colors.sortedPurple,
      opacity: 0.6,
    },
  },
  loginLabel: {
    color: theme.colors.light,
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  sortedLogo: {
    height: '40px',
    objectFit: 'contain',
    margin: 20,
  },
});
