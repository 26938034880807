import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  container: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.lightRed,
    marginBottom: theme.units.spacing * 8,
  },
  title: {},
  subtitle: {},
  button: {
    backgroundColor: theme.colors.darkRed,
    borderColor: theme.colors.darkRed,
    marginTop: theme.units.spacing * 4,
  },
});
