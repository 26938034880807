/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { FormControl, InputLabel, FormHelperText, TextField } from '@mui/material';

/// <summary>
/// Value is string
/// </summary>
const RenderMultiLineInput = (fieldData: any) => {
  const {
    field,
    label,
    required,
    inputProps,
    customProps,
    helperText,
    style,
    rows,
    disabled,
    type,
    placeholder,
    maxLength,
    inputStyle,
  } = fieldData;
  const hasError = !!field.error && !!field.touched;
  // const hasSecondaryError = !!field.secondaryValidationMessage;
  return (
    <div className="item-render" style={field.itemRenderStyle}>
      {label && (
        <InputLabel required={required} htmlFor={field.name}>
          {label}
        </InputLabel>
      )}
      <FormControl error={hasError} fullWidth>
        <TextField
          type={type}
          id={field.name}
          value={field.value || ''} // string
          onChange={field.onChange}
          onBlur={field.onBlur}
          disabled={disabled}
          // inputRef={(elem: HTMLInputElement) => { if (field.inputRef) { field.inputRef(elem); } }}
          style={style}
          multiline
          rows={rows}
          inputProps={{
            style: { ...inputStyle, height: 'auto' },
            placeholder,
            maxLength,
            ...inputProps,
          }}
          {...customProps}
        />
        {hasError && <FormHelperText id={`${field.name}-error-text`}>{field.error}</FormHelperText>}
        {/* {hasSecondaryError && <FormHelperText id={`${field.name}-error2-text`} error={false} required={true}>{field.secondaryValidationMessage}</FormHelperText>} */}
        {!hasError && !!helperText && (
          <FormHelperText id={`${field.name}-helper-text`}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default RenderMultiLineInput;
