import { combineReducers, AnyAction } from 'redux';
import { IntroductionState } from './state/IntroductionState';
import { groupsReducer, initialState as InitialGroupsState } from './reducers/GroupsReducer';
import { GroupsState } from './state/GroupsState';
import {
  landingScreenReducer,
  initialState as InitialLandingScreenState,
} from './reducers/LandingScreenReducer';
import { LandingScreenState } from './state/LandingScreenState';
import {
  additionalPersonalDetailScreenReducer,
  initialState as InitialAdditionalPersonalDetailsState,
} from './reducers/AdditionalPersonalDetailsReducer';
import { AdditionalPersonalDetailsScreenState } from './state/AdditionalPersonalDetailsScreenState';
import {
  myProfileReducer,
  initialState as InitialMyProfileFormState,
} from './reducers/MyProfileFormReducer';
import { ApplicantProfile } from './state/MyProfileFormState';
import { DashboardState } from './state/DashboardState';
import {
  dashboardReducer,
  initialState as InitialDashboardState,
} from './reducers/DashboardReducer';
import { introReducer, initialState as InitialIntroState } from './reducers/IntroReducer';
import {
  fileUploadReducer,
  initialState as InitialFileUploadState,
} from './reducers/FileUploadReducer';
import { LoadingIndicatorState } from './state/LoadingIndicatorState';
import {
  loadingIndicatorReducer,
  initialState as InitialLoadingIndicatorState,
} from './reducers/LoadingIndicatorReducer';
import { FileLoadingSpinnerState } from './state/FileLoadSpinnerState';
import { LoginState } from './state/LoginState';
import { loginReducer, initialState as IntitialLoginState } from './reducers/LoginReducer';
import {
  fileLoadingSpinnerReducer,
  initialState as InitialileLoadingSpinnerState,
} from './reducers/FileLoadingSpinnerReducer';
import { CustomTermsHeaderState } from './state/CustomTermsHeaderState';
import {
  termsHeaderReducer,
  initialState as InitialTermsHeaderState,
} from './reducers/CustomTermsHeaderReducer';
import { SuccessToastState } from './state/SuccessToastState';
import {
  successToastReducers,
  initialState as initialSuccessToastState,
} from './reducers/SuccessToastReducer';
import MasterProfileState from './state/MasterProfileState';
import {
  masterProfileReducer,
  initialState as InitialMasterProfileState,
} from './reducers/MasterProfileReducer';
import { SuccessActionTypes } from './actions/SuccessActions';
import {
  referenceToolReducer,
  initialState as InitialReferenceToolState,
} from './reducers/ReferenceToolReducer';
import { ReferenceToolState } from './state/ReferenceToolState';
import { FileUploadState } from './state/FileUploadState';
import {
  createMaintenanceRequestReducer,
  initMaintenanceRequestState,
} from './reducers/MaintenanceRequestReducer';
import { RemoteSigningState } from './state/RemoteSigningState';
import {
  remoteSigningReducer,
  initialState as InitialRemoteSigningState,
} from './reducers/RemoteSigningReducer';
import { MarketplaceState } from './state/MarketplaceState';
import {
  marketplaceReducer,
  initialState as InitialMarketplaceState,
} from './reducers/MarketplaceReducer';
import { SearchState } from './state/SearchState';
import { searchReducer, initialState as InitialSearchState } from './reducers/SearchReducer';
import { paymentReducer, initialState as InitialPaymentState } from './reducers/PaymentReducer';
import { CreateMaintenanceRequestState } from './state/CreateMaintenanceRequestState';
import { PaymentState } from './state/PaymentState';
import {
  disclosureReducer,
  initialState as InitialDisclosureState,
} from './reducers/DisclosureReducer';
import DisclosureState from './state/DisclosureState';
import { initialLoadingState, LoadingState } from './state/LoadingState';
import { ErrorState, initialErrorState } from './state/ErrorState';
import { LoadingReducer } from './reducers/LoadingReducer';
import { ErrorReducer } from './reducers/ErrorReducer';
import { EquifaxState } from './state/EquifaxState';
import { equifaxReducer, initialState as InitialEquifaxState } from './reducers/EquifaxReducer';
import { ShortApplicationState } from './state/ShortApplicationState';
import {
  shortApplicationReducer,
  initialState as InitialShortApplicationState,
} from './reducers/ShortApplicationReducer';

export interface ApplicationState {
  landingScreen: LandingScreenState;
  groups: GroupsState;
  introductionDetails: IntroductionState;
  additionalPersonalDetailScreen: AdditionalPersonalDetailsScreenState;
  myProfileForm: ApplicantProfile;
  dashboard: DashboardState;
  fileUpload: FileUploadState;
  loadingIndicator: LoadingIndicatorState;
  fileUploadingSpinner: FileLoadingSpinnerState;
  login: LoginState;
  termsHeader: CustomTermsHeaderState;
  onSuccess?: (applicationState?: ApplicationState) => void;
  successToast: SuccessToastState;
  isSaveAsDraftBtnClicked?: boolean;
  masterProfile: MasterProfileState;
  referenceToolData: ReferenceToolState;
  createMaintenanceRequestData: CreateMaintenanceRequestState;
  remoteSigning: RemoteSigningState;
  marketplace: MarketplaceState;
  payment: PaymentState;
  disclosure: DisclosureState;
  searchState: SearchState;
  equifaxState: EquifaxState;
  shortApplicationState: ShortApplicationState;
  loadingState: LoadingState;
  errorState: ErrorState;
}

const initialState: ApplicationState = {
  landingScreen: InitialLandingScreenState,
  groups: InitialGroupsState,
  introductionDetails: InitialIntroState,
  additionalPersonalDetailScreen: InitialAdditionalPersonalDetailsState,
  myProfileForm: InitialMyProfileFormState,
  dashboard: InitialDashboardState,
  fileUpload: InitialFileUploadState,
  loadingIndicator: InitialLoadingIndicatorState,
  fileUploadingSpinner: InitialileLoadingSpinnerState,
  login: IntitialLoginState,
  termsHeader: InitialTermsHeaderState,
  successToast: initialSuccessToastState,
  masterProfile: InitialMasterProfileState,
  referenceToolData: InitialReferenceToolState,
  createMaintenanceRequestData: initMaintenanceRequestState,
  remoteSigning: InitialRemoteSigningState,
  marketplace: InitialMarketplaceState,
  payment: InitialPaymentState,
  disclosure: InitialDisclosureState,
  searchState: InitialSearchState,
  equifaxState: InitialEquifaxState,
  shortApplicationState: InitialShortApplicationState,
  loadingState: initialLoadingState,
  errorState: initialErrorState,
};

const rootReducer = combineReducers<ApplicationState>({
  landingScreen: landingScreenReducer,
  groups: groupsReducer,
  additionalPersonalDetailScreen: additionalPersonalDetailScreenReducer,
  myProfileForm: myProfileReducer,
  introductionDetails: introReducer,
  dashboard: dashboardReducer,
  fileUpload: fileUploadReducer,
  loadingIndicator: loadingIndicatorReducer,
  fileUploadingSpinner: fileLoadingSpinnerReducer,
  login: loginReducer,
  termsHeader: termsHeaderReducer,
  successToast: successToastReducers,
  masterProfile: masterProfileReducer,
  referenceToolData: referenceToolReducer,
  createMaintenanceRequestData: createMaintenanceRequestReducer,
  remoteSigning: remoteSigningReducer,
  marketplace: marketplaceReducer,
  payment: paymentReducer,
  disclosure: disclosureReducer,
  searchState: searchReducer,
  equifaxState: equifaxReducer,
  shortApplicationState: shortApplicationReducer,
  loadingState: LoadingReducer,
  errorState: ErrorReducer,
});

export default (state: ApplicationState | undefined, action: AnyAction) => {
  if (action.type === SuccessActionTypes.DRAFT_FLUSH_DATA) {
    const masterProfileState = state ? state.masterProfile : initialState.masterProfile;
    return rootReducer({ ...initialState, masterProfile: masterProfileState }, action);
  }
  if (action.type === SuccessActionTypes.FLUSH_DATA) {
    return rootReducer(initialState, action);
  }
  return rootReducer(state, action);
};
