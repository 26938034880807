/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState, useMemo } from 'react';
import { Checkbox, Paper, Typography } from '@mui/material';
import AttachedDocuments from '../RemoteSigning/components/AttachedDocuments';
import PreviewDocs from '../RemoteSigning/components/PreviewDocs';
import { useStyles } from './OwnershipDocumentsStyles';
import PropertyCard from '../RemoteSigning/components/PropertyCard';
import { MOCK_PROPERTY, MOCK_STEPDATA, MOCK_REMOTE } from './OwnershipDocumentsConstants';
import { theme } from '../../../../theme/Theme';
import { RemoteSigningGetResponse } from '../../../../services/remoteSigning/RemoteSigning';
import { SigningSteps, StepType } from '../../RemoteSigningUtils';
import { LandlordRemoteSigningHelper } from './OwnershipDocumentsUtils';

interface OwnershipDocumentsProps {
  remoteSigningData: RemoteSigningGetResponse;
  handleBack: () => void;
  handleNext: () => void;
  setActiveStep: (step: number) => void;
}

const OwnershipDocuments: FC<OwnershipDocumentsProps> = ({
  remoteSigningData,
  handleBack,
  handleNext,
  setActiveStep,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState<number>(0);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [checkedArray, setIsCheckedArray] = useState<boolean[]>([]);
  const { ownershipDetails } = remoteSigningData;
  const steps = useMemo<SigningSteps[]>(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const result = LandlordRemoteSigningHelper.setSigningsteps(ownershipDetails, step);
    setIsCheckedArray(
      new Array(
        result.filter((stepData) => stepData.stepType === StepType.DownloadDocument).length,
      ).fill(false),
    );
    return result;
  }, [step]);

  const onBack = () => {
    if (step === 0) {
      handleBack();
    } else {
      setStep(step - 1);
    }
  };

  const next = () => {
    if (isChecked && !checkedArray.filter((c) => !c).length) {
      if (step + 1 === ownershipDetails!.properties.length) {
        handleNext();
      } else {
        setStep(step + 1);
        setIsChecked(false);
      }
    }
  };

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const toggleChecked = (index: number, value: boolean) => {
    setIsCheckedArray(
      checkedArray.map((item, idx) => {
        return idx === index ? value : item;
      }),
    );
  };

  return (
    <>
      <Paper className={classes.RDSContainer}>
        <div className={classes.signingTitleContainer}>
          <Typography className={classes.signingTitle} variant="h5">
            {step + 1} of {ownershipDetails!.properties.length} properties for{' '}
            {ownershipDetails!.ownershipType === 'Private'
              ? ownershipDetails!.displayName
              : ownershipDetails!.corporationName}
          </Typography>
        </div>
        <div className={classes.propertyCardContainer}>
          <PropertyCard
            property={
              {
                ...ownershipDetails!.properties[step].property.address,
                ...ownershipDetails!.properties[step].property.details,
              } as any
            }
          />
        </div>
        <div className={classes.termsConditionsContainer}>
          <div className={classes.termsConditionsWrapper}>
            {steps
              .filter((stepData) => stepData.stepType === StepType.PreviewDocument)
              .map((stepData) => {
                return (
                  <>
                    <div className={classes.stepTitle}>{stepData.stepTitle}</div>
                    <PreviewDocs
                      stepData={stepData}
                      remoteSigningData={remoteSigningData}
                      landlordProperty={ownershipDetails!.properties[step]}
                    />
                  </>
                );
              })}
          </div>
        </div>
        {!!steps.filter((stepData) => stepData.stepType === StepType.DownloadDocument).length && (
          <div className={classes.additionalDocumentsContainer}>
            <div className={classes.additionalTitle}>Additional Documents</div>
            <div className={classes.additionalInfoBox}>
              Please review the following additional documents below
            </div>
          </div>
        )}
        {steps
          .filter((stepData) => stepData.stepType === StepType.DownloadDocument)
          .map((stepData, index) => {
            return (
              <AttachedDocuments
                stepData={stepData}
                acceptable
                setIsAllChecked={(value: boolean) => toggleChecked(index, value)}
              />
            );
          })}
      </Paper>
      <Paper className={classes.checkboxContainer}>
        <Checkbox
          style={{ color: theme.colors.success }}
          className={classes.checkboxStyle}
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <Typography className={classes.checkboxText} onClick={() => setIsChecked(!isChecked)}>
          I accept
        </Typography>
      </Paper>
      <div className={classes.buttonContainer}>
        <div className={classes.secondaryButton} onClick={() => onBack()}>
          Back
        </div>
        <div
          className={`${classes.primaryButton} ${
            !isChecked || checkedArray.filter((c) => !c).length ? classes.disabled : undefined
          }`}
          onClick={() => next()}
        >
          Next
        </div>
      </div>
    </>
  );
};

export default OwnershipDocuments;
