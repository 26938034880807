import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './EditGroupStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/RootReducers';
import backArrow from '../../assets/navigation/arrow-left.png';
import Text from '../../component/text/Text';
import { LABELS } from './EditGroupConstants';
import { TRIANGLES } from '../login/LoginConstants';
import Triangle from '../login/components/triangle/Triangle';
import { ApplicationsResponse, GroupResponse } from '../../services/groups/Groups';
import GroupCard from '../../component/groupCard/GroupCard';
import CoApplicantsForm from '../application/components/coApplicantsForm/CoApplicantsForm';
import Input from '../../component/input/Input';
import BackArrow from '../../component/backArrow/BackArrow';

interface EditGroupProps extends RouteComponentProps {
  groups: GroupResponse[] | undefined;
  selectedGroupRefId: string | null;
  groupApplications: ApplicationsResponse | undefined;
}

export enum Selected {
  group,
  newApplication,
}

const EditGroup: FC<EditGroupProps> = ({
  history,
  groups,
  selectedGroupRefId,
  groupApplications,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<Selected>(
    selectedGroupRefId ? Selected.group : Selected.newApplication,
  );
  const [groupRefSelected, setGroupRefSelected] = useState<string | null>(selectedGroupRefId);
  const [groupName, setGroupName] = useState<string>('My Group');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (selected === Selected.newApplication && groupRefSelected !== selectedGroupRefId) {
      setGroupRefSelected(selectedGroupRefId);
      setSelected(Selected.group);
    }
  }, [selectedGroupRefId]);

  useEffect(() => {
    if (groupRefSelected && groups && groups.length) {
      setGroupName(groups.find((g) => g.groupRefId === groupRefSelected)!.groupName);
    }
  }, [groupRefSelected]);

  return (
    <>
      <div className={classes.trianglesContainer}>
        {TRIANGLES.map((t, idx) => (
          <Triangle key={idx} size={t.size} rotation={t.rotation} parentStyles={t.styles} />
        ))}
      </div>
      <div className={classes.content}>
        <div className={classes.pageContainer}>
          <BackArrow />
          {groups && groupRefSelected ? (
            <GroupCard
              hideBox
              group={groups.find((group) => group.groupRefId === groupRefSelected)!}
              groupApplications={groupApplications}
              onNameChange={(name: string) => setGroupName(name)}
            />
          ) : (
            <div className={classes.newGroupContainer}>
              <Text textVariant="title" parentStyles={classes.newGroupTitle}>
                {LABELS.NEW_GROUP}
              </Text>
              <Text textVariant="info" size="l">
                {LABELS.NEW_GROUP_INFO}
              </Text>
              <Input
                title={LABELS.GROUP_NAME}
                placeholder={LABELS.GROUP_NAME}
                value={groupName}
                setValue={setGroupName}
                parentStyles={classes.newGroupInput}
              />
            </div>
          )}
          <div className={classes.spacing} />
          <CoApplicantsForm
            parentGroupName={groupName}
            groupRefSelected={groupRefSelected}
            selected={selected}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  groups: state.groups.groups,
  selectedGroupRefId: state.groups.selectedGroupRefId,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditGroup));
