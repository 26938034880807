/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { maxContentWidth, pageContainer, pageTitleContainer } from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
    maxWidth: 1200,
  },
  pageContainer: {
    ...pageContainer,
    marginTop: 0,
    paddingTop: 0,
    // height excluding agency bg
    height: `100%`,
  },
  circleLogoRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
  },
  circleLogo: {
    width: 100,
    height: 100,
    border: `${theme.colors.light} 2px solid`,
    borderRadius: 5000,
    position: 'absolute',
    top: '-50px',
    objectPosition: 'center',
    objectFit: 'cover',
  },
  titleContainer: {
    ...pageTitleContainer,
    marginTop: 64,
    flexWrap: 'wrap',
    marginBottom: 40,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
    flexBasis: '100%',
  },
  subtitle: {
    color: theme.colors.title,
    fontSize: theme.font.xxl,
    fontWeight: getFonts('Medium'),
    marginTop: '1em',
  },
  buildingsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 8,
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  building: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColorLight} 2px solid`,
    cursor: 'pointer',
    minHeight: 120,
    '&:hover': {
      border: `${theme.colors.secondary} 2px solid`,
      backgroundColor: theme.colors.secondary,
      transition: 'all 0.3s ease 0s',
      '& > *': {
        color: '#FFF',
        transition: 'all 0.3s ease 0s',
      },
    },
  },
  disabledBuilding: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColorLight} 2px solid`,
    minHeight: 120,
    opacity: 0.4,
    cursor: 'default',
  },
  buildingName: {
    color: theme.colors.black87,
    fontSize: theme.font.xxl,
    fontWeight: getFonts('Medium'),
    textAlign: 'center',
  },
  buildingAddress: {
    color: theme.colors.black60,
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    maxWidth: 200,
    textAlign: 'center',
  },
  comingSoon: {
    color: theme.colors.black,
    marginTop: '1em',
  },
  section: {
    marginBottom: theme.units.spacing * 8,
  },
  question: {},
  questionInfoText: {
    color: theme.colors.grey,
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
    marginTop: -theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 4,
  },
  checkboxRow: {
    display: 'flex',
    marginTop: theme.units.spacing * 2,
    width: 'max-content',
  },
  checkBoxStyle: {
    padding: 0,
    marginRight: theme.units.spacing * 4,
    flexShrink: 0,
  },
  htmlContainer: {
    marginTop: theme.units.spacing * 8,
  },
  htmlText: {
    color: theme.colors.black60,
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    '& > a': {
      color: theme.colors.secondary,
    },
    '& > p > a': {
      color: theme.colors.secondary,
    },
  },
  inputContainerStyle: {
    paddingBottom: '16px',
    width: '100%',
  },
  fileInputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 2,
  },
  mandatory: {
    color: theme.colors.buttonPrimary,
    fontSize: theme.font.s,
    marginLeft: theme.units.spacing,
    fontWeight: getFonts('Medium'),
  },
  fileInputTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  additionalApplicantsHeading: {
    color: theme.colors.title,
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    marginTop: theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 2,
  },
  additionalApplicantsSubheading: {
    color: theme.colors.title,
    fontSize: theme.font.s,
    fontWeight: getFonts('Medium'),
    marginTop: theme.units.spacing * 2,
    marginBottom: theme.units.spacing,
  },
  toggleContainer: {
    marginLeft: -theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 4,
  },
  additionalApplicantBox: {
    marginBottom: theme.units.spacing * 4,
  },
  boxBorder: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  questionExtraSpacing: {
    width: '100%',
    marginTop: theme.units.spacing * 2,
  },
  applicantTitle: {
    width: '25%',
    [`@media (max-width: 635px)`]: {
      width: '50%',
    },
  },
  firstName: {
    width: 'calc(75% - 16px)',
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: 635px)`]: {
      width: 'calc(50% - 16px)',
    },
  },
  middleName: {
    width: '50%',
  },
  lastName: {
    width: 'calc(50% - 16px)',
    marginLeft: theme.units.spacing * 4,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropdown: {
    padding: 6,
  },
  loadingForm: {
    height: 1400,
    marginBottom: theme.units.spacing * 4,
  },
  loadingTitle: {
    height: 30,
    width: '60%',
    marginBottom: theme.units.spacing * 4,
  },
  loadingBox: {
    height: 160,
  },
  subtitleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 8,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
    },
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginLeft: 0,
    },
  },
  errorText: {
    color: theme.colors.error,
    fontWeight: 500,
    marginRight: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
  },
  signupRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  timer: {
    marginLeft: '1rem',
    marginRight: 'auto',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      marginBottom: theme.units.spacing * 4,
      marginTop: theme.units.spacing * 4,
    },
  },
  signupButton: {
    minWidth: 70,
    marginLeft: theme.units.spacing * 4,
    marginRight: 'auto',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      marginBottom: theme.units.spacing * 4,
      marginTop: theme.units.spacing * 4,
    },
  },
  input: {
    padding: '0.5rem',
    fontSize: theme.font.xl,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    borderRadius: theme.units.borderRadius / 2,
    border: `${theme.colors.borderColor} 2px solid`,
  },
  container: {
    gridGap: '0.5rem',
  },
});
