/* eslint-disable import/prefer-default-export */
export const getPageTitle = (location: string) => {
  const suffix = ' | Sorted';
  return (
    (() => {
      switch (true) {
        case location === '/':
          return 'Login';
        case location.includes('applications'):
          return 'Applications';
        case location.includes('my-profile'):
          return 'My profile';
        case location.includes('additional-profile'):
          return 'Additional profile';
        case location.includes('applicationPreferences'):
          return 'Application';
        case location.includes('error-screen'):
          return 'Error';
        default:
          return 'Apply';
      }
    })() + suffix
  );
};
