/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles, pageContainer, smallPageContainer } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  paperBack: globalStyles.paperBack as any,
  contentContainer: {
    padding: '30px',
  },
  welcomeTitle: {
    color: theme.colors.sortedPurple,
    fontSize: 40,
    marginBottom: '10px',
  },
  welcomeSubtitle: {
    fontSize: 18,
  },
  boxStyling: {
    marginTop: theme.units.spacing * 8,
  },
  propertyContainer: {
    // marginTop: '30px',
    // border: `${theme.colors.borderColor} 2px solid`,
    // borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  propertyImageContainer: {
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.lightGrey,
    // border: `${theme.colors.borderColor} 2px solid`,
    overflow: 'hidden',
    width: '80px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  propertyImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  propertyImageBlank: {
    position: 'relative',
    top: '-1px',
  },
  propertyDetails: {
    marginRight: 'auto',
    marginLeft: theme.units.spacing * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  propertyAddress: {
    marginBottom: theme.units.spacing * 3,
  },
  propertySuburb: {
    opacity: 0.38,
  },
  weeklyAmount: {
    fontSize: 26,
    color: theme.colors.success,
    marginRight: '15px',
  },
  poweredBySorted: {
    marginTop: '30px',
  },
  pageContainer: {
    ...pageContainer,
    paddingBottom: 0,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  naxLogoContainer: {
    height: '70px',
  },
  naxLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  hide: {
    display: 'none',
  },
});
