/* eslint-disable max-lines */
import React, { FC, useState, useEffect } from 'react';
import { Typography, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field, Formik, ErrorMessage } from 'formik';
import {
  CreateMaintenanceRequestFormData,
  placeholders,
  labels,
  fields,
  validation,
  BooleanLabel,
  Weekdaysdata,
  TimeSlot,
  MaintenanceRequestLocation,
  propertyAccessData,
  parkingAvailabilityBooleanLabel,
  clearAccessLabel,
  createMaintenanceRequestFormValidations,
  PropertyAccessType,
} from './CreateMaintenanceRequestForm.data';
import RenderSelect from '../RenderSelect/RenderSelect';
import RenderMultiLineInput from '../RenderMultiLineInput/RenderMultiLineInput';
import RenderFileUpload from '../FileUpload/RenderFileUpload';
import { ContentTypeHelper } from '../../../../helper/Content/ContentType.data';
import { ContentCategory } from '../../../../helper/Content/ContentCategory.data';
import { FileUploadDesign, FileUploadDisplayVariant } from '../FileUpload/FileUpload';
import { Messages } from '../Messages';
import RenderRadioGroup from '../RenderRadioButtons/RenderRadioButton';
import RenderMultiselectBox from '../SelectBox/RenderMultiselectBox';
import { CreateMaintenanceRequestStartPayload } from '../../../../services/MaintenanceRequest/CreateMaintenanceRequest/CreateMaintenanceRequestPayload';
import {
  MaintenanceRequestActions,
  MaintenanceRequestActionsTypes,
} from '../../../../store/actions/MaintenanceRequestActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { MaintenanceRequestConverter } from './MaintenanceRequestConverter';
import { useStyles } from './MaintenanceRequest.styles';
import RenderCheckbox from '../../../../component/RenderCheckbox/RenderCheckbox';
import { MaintenanceRequestStatus } from '../../../../models/MaintenanceRequest/MaintenanceRequest.model';
import RenderInput from '../RenderInput/RenderInput';
import { MRFormProperty } from '../../MaintenanceFormConstants';
import { PersonalDetailsFormValues } from '../PersonalDetails/PersonalDetailsConstants';
import { DisplayComponent } from '../../MaintenanceForm';
import { theme } from '../../../../theme/Theme';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import Input from '../../../../component/input/Input';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import InputMobile from '../../../../component/inputMobile/InputMobile';

const { photoUploadMessages } = Messages.forms.maintenanceRequestForm;

interface CreateMaintenanceRequestFormProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  status?: MaintenanceRequestStatus;
  property: MRFormProperty;
  initialValues?: CreateMaintenanceRequestFormData;
  userDetails: PersonalDetailsFormValues;
  setView: React.Dispatch<React.SetStateAction<DisplayComponent>>;
}

const defaultFormValues: CreateMaintenanceRequestFormData = {
  title: '',
  description: '',
  photos: [],
  appliance: { present: false },
  preferredAvailability: {
    timeSlot: 'No preference',
    otherTime: '',
    days: Weekdaysdata.map((val) => val.key),
  },
  propertyAccess: { propertyAccessType: PropertyAccessType.TENANT_AVAILABLE },
  pet: { present: false },
  clearAccess: true,
  stairs: { present: false },
  parkingAvailability: false,
  unSupervisedMinors: false,
  reCaptchaResponseToken: '',
};

const CreateMaintenanceRequestForm: FC<CreateMaintenanceRequestFormProps> = ({
  status,
  property,
  submitting,
  initialValues,
  userDetails,
  setView,
  createMaintenanceRequest,
}) => {
  const [readonly, setReadonly] = useState<boolean>(
    status === MaintenanceRequestStatus.COMPLETE || status === MaintenanceRequestStatus.CLOSED,
  );
  const classes = useStyles();
  /* istanbul ignore next */
  const onSubmit = (data: CreateMaintenanceRequestFormData) => {
    const { id } = property;
    const payload = MaintenanceRequestConverter.convertFormDataToStartPayload(
      data,
      id,
      userDetails,
    );
    createMaintenanceRequest(payload);
  };

  return (
    <Box lightBorder>
      <Text parentStyles={classes.title} textVariant="title">
        Maintenance Request
      </Text>

      <Formik
        validationSchema={createMaintenanceRequestFormValidations}
        onSubmit={onSubmit}
        initialValues={initialValues || defaultFormValues}
      >
        {({
          handleSubmit,
          setFieldValue,
          values: {
            appliance: applianceValue,
            preferredAvailability: preferredAvailabilityValue,
            pet: petValue,
            stairs: stairsValue,
            reCaptchaResponseToken: reCaptchaResponseTokenValue,
          },
          values,
          isValid,
          dirty,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <>
            {/* Job description */}
            <div className={classes.boxStyle}>
              <Text textVariant="subtitle" size="xl">
                Job description
              </Text>
              <div className={classes.dividerContainer}>
                <Divider />
              </div>
              <InputSelect
                placeholder={labels.location}
                title={labels.location}
                value={values.location}
                values={MaintenanceRequestLocation.map((v) => ({
                  display: v.label as string,
                  value: v.value as string,
                }))}
                setValue={(value: string) => setFieldValue('location', value)}
                onBlur={() => setFieldTouched('location')}
                error={errors.location}
                touched={touched.location}
                mandatory
              />
              <Input
                placeholder={labels.title}
                title={labels.title}
                value={values.title}
                setValue={(value: string) => setFieldValue('title', value)}
                onBlur={() => setFieldTouched('title')}
                error={errors.title}
                touched={touched.title}
                mandatory
              />
              <Input
                placeholder={labels.description}
                title={labels.description}
                value={values.description}
                setValue={(value: string) => setFieldValue('description', value)}
                onBlur={() => setFieldTouched('description')}
                error={errors.description}
                touched={touched.description}
                disabled={readonly}
                mandatory
              />
              <Field
                name={fields.unSupervisedMinors}
                component={RenderCheckbox}
                disabled={readonly}
                label={labels.unSupervisedMinors}
              />
            </div>

            {/* Photos */}
            <div className={classes.boxStyle}>
              <Text textVariant="subtitle" size="xl">
                {labels.photos} *
              </Text>
              <div className={classes.dividerContainer}>
                <Divider />
              </div>
              <Text textVariant="info">
                Attach at least one photo, showing the site and affected areas, appliances, brand
                model and size (if applicable)
              </Text>

              <Field
                name={fields.photos}
                component={RenderFileUpload}
                disabled={readonly}
                variant={FileUploadDisplayVariant.List}
                maxFileCount={5}
                maxFileSize={0xa00000}
                allowedFormats={[
                  ...ContentTypeHelper.getAllByCategory(ContentCategory.Image),
                  ...ContentTypeHelper.getAllByCategory(ContentCategory.Video),
                ]}
                design={FileUploadDesign.Photos}
                onChange={(value: any) => setFieldValue(fields.photos, value)}
                showErrors
                messages={photoUploadMessages}
              />
            </div>

            {/* Appliance */}
            <div className={classes.boxStyle}>
              <Field
                label={labels.appliance.present}
                component={RenderRadioGroup}
                disabled={readonly}
                name={fields.appliance.present}
                options={BooleanLabel}
                onChange={(_event: any, value: string) => {
                  // resetting the  dependant value if the appliance is chosen false
                  /* istanbul ignore next */

                  if (value === 'false') {
                    setFieldValue('appliance', {
                      present: false,
                      make: '',
                      warranty: undefined,
                    });
                  } else {
                    setFieldValue('appliance', {
                      present: true,
                    });
                  }
                }}
              />
              {
                /* istanbul ignore next */
                applianceValue && applianceValue.present === true && (
                  <>
                    <Field
                      name={fields.appliance.make}
                      label={labels.appliance.make}
                      component={RenderMultiLineInput}
                      placeholder={placeholders.appliance.make}
                    />
                    <Field
                      name={fields.appliance.model}
                      label={labels.appliance.model}
                      component={RenderMultiLineInput}
                      placeholder={placeholders.appliance.model}
                    />
                    <Field
                      label={labels.appliance.warranty}
                      component={RenderRadioGroup}
                      name={fields.appliance.warranty}
                      onChange={(_event: any, value: string) => {
                        /* istanbul ignore next */

                        setFieldValue(fields.appliance.warranty, value === 'true');
                      }}
                      options={BooleanLabel}
                    />
                  </>
                )
              }
            </div>

            {/* Preferred availability */}
            <div className={classes.boxStyle}>
              <Text textVariant="subtitle" size="xl">
                {labels.preferredAvailability.label}
              </Text>
              <div className={classes.dividerContainer}>
                <Divider />
              </div>
              <Field
                label={labels.propertyAccess}
                component={RenderRadioGroup}
                disabled={readonly}
                name={fields.propertyAccess.propertyAccessType}
                options={propertyAccessData}
              />
              <Field
                label={labels.preferredAvailability.timeSlot}
                component={RenderRadioGroup}
                disabled={readonly}
                name={fields.preferredAvailability.timeSlot}
                options={TimeSlot}
                onChange={(_event: any, value: string) => {
                  /* istanbul ignore next */
                  if (value !== 'Other') {
                    setFieldValue('preferredAvailability', {
                      ...preferredAvailabilityValue,
                      timeSlot: value,
                      otherTime: '',
                      days: [],
                    });
                  } else {
                    setFieldValue('preferredAvailability', {
                      ...preferredAvailabilityValue,
                      timeSlot: value,
                      otherTime: '',
                      days: [],
                    });
                  }
                  if (value === 'No preference') {
                    setFieldValue('preferredAvailability', {
                      ...preferredAvailabilityValue,
                      timeSlot: value,
                      otherTime: '',
                      days: Weekdaysdata.map((val) => val.key),
                    });
                  }
                }}
              />
              <Field
                name={fields.preferredAvailability.otherTime}
                component={RenderInput}
                placeholder={placeholders.timeSolt_otherTime}
                validate={
                  /* istanbul ignore next */
                  !preferredAvailabilityValue ||
                  (preferredAvailabilityValue && preferredAvailabilityValue.timeSlot !== 'Other')
                    ? undefined
                    : validation.isRequired
                }
                disabled={
                  (preferredAvailabilityValue && preferredAvailabilityValue.timeSlot !== 'Other') ||
                  readonly
                }
              />
              <br />
              <Field
                name={fields.preferredAvailability.days}
                disabled={
                  (preferredAvailabilityValue &&
                    preferredAvailabilityValue.timeSlot === 'No preference') ||
                  readonly
                }
                component={RenderMultiselectBox}
                options={Weekdaysdata}
                label={labels.preferredAvailability.days}
              />
            </div>

            {/* Property details */}
            <div className={classes.boxStyle}>
              <Text textVariant="subtitle" size="xl">
                Property details
              </Text>
              <div className={classes.dividerContainer}>
                <Divider />
              </div>
              <Field
                name={fields.pet.present}
                label={labels.pet.present}
                disabled={readonly}
                component={RenderRadioGroup}
                options={BooleanLabel}
                onChange={(_event: any, value: string) => {
                  // resetting the  dependant value if the key is not present
                  /* istanbul ignore next */
                  let val = value === 'true';
                  if (val === false) {
                    setFieldValue('pet', {
                      present: val,
                      description: '',
                    });
                  } else {
                    setFieldValue('pet', {
                      ...petValue,
                      present: val,
                    });
                  }
                }}
              />
              {
                /* istanbul ignore next */
                petValue && petValue.present === true && (
                  <>
                    <Field
                      name={fields.pet.description}
                      label={labels.pet.description}
                      component={RenderMultiLineInput}
                      rows={4}
                    />
                  </>
                )
              }
            </div>
            <div className={classes.boxStyle}>
              <Field
                name={fields.clearAccess}
                disabled={readonly}
                label={labels.clearAccess}
                component={RenderRadioGroup}
                onChange={(_event: any, value: string) => {
                  setFieldValue(fields.clearAccess, value === 'true');
                }}
                options={clearAccessLabel}
              />
            </div>
            <div className={classes.boxStyle}>
              <Field
                name={fields.stairs.present}
                label={labels.stairs.present}
                component={RenderRadioGroup}
                disabled={readonly}
                options={BooleanLabel}
                onChange={(_event: any, value: string) => {
                  // resetting the  dependant value if the key is not present
                  /* istanbul ignore next */
                  let val = value === 'true';
                  setFieldValue(fields.stairs.present, val);
                  if (val === false) {
                    setFieldValue('stairs', {
                      present: val,
                      description: '',
                    });
                  }
                }}
              />
              {
                /* istanbul ignore next */
                stairsValue && stairsValue.present === true && (
                  <Field
                    name={fields.stairs.description}
                    label={labels.stairs.description}
                    component={RenderMultiLineInput}
                    rows={4}
                    placeholder={placeholders.stairs_description}
                  />
                )
              }
            </div>
            <div className={classes.boxStyle}>
              <Field
                name={fields.parkingAvailability}
                label={labels.parkingAvailability}
                disabled={readonly}
                component={RenderRadioGroup}
                onChange={(_event: any, value: string) => {
                  setFieldValue(fields.parkingAvailability, value === 'true');
                }}
                options={parkingAvailabilityBooleanLabel}
              />
            </div>

            <div className={classes.captchaContainer}>
              <ReCAPTCHA
                sitekey="6LcfV08bAAAAAHm6COQrKMpCODLzZcLi3Z1Fr8pf"
                onChange={(value) => {
                  setFieldValue('reCaptchaResponseToken', value);
                }}
              />
            </div>

            <div className={classes.submitButtonContainer}>
              <Button onPress={() => setView(DisplayComponent.USER_DETAILS)}>Back</Button>
              {!initialValues && !readonly && (
                <Button
                  onPress={() => handleSubmit()}
                  parentStyles={classes.buttonStyle}
                  disabled={!reCaptchaResponseTokenValue || !dirty || !isValid || submitting}
                >
                  {submitting ? 'Submitting...' : 'Submit request'}
                </Button>
              )}
            </div>
          </>
        )}
      </Formik>
    </Box>
  );
};

const loading = loadingSelector([MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST]);

export const mapStateToProps = (state: ApplicationState) => ({
  submitting: loading(state),
});

/* istanbul ignore next */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  createMaintenanceRequest: (data: CreateMaintenanceRequestStartPayload) =>
    dispatch(MaintenanceRequestActions.createMaintenanceRequestStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMaintenanceRequestForm);
