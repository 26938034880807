import { ServiceType } from '../ServiceType.data';
import { GetFormParams, PostFormParams } from './residentRequestService.data';
import TenancyApiService from '../TenancyApiService';
import { Question } from '../../component/genericFormRenderer/GenericFormRenderer';
import { MultipartFormData } from '../MultipartFormData.data';

const service = new TenancyApiService(ServiceType.ResidentRequest);

const residentRequestService = () => {
  const getForm = (params: GetFormParams): Promise<Question[]> => {
    return service.get({ route: ['form'], query: { ...params } });
  };

  const postForm = (params: PostFormParams): Promise<any> => {
    const { files, request } = params;
    const body: MultipartFormData[] = [{ name: 'request', content: JSON.stringify(request) }];
    (files || []).map((f) => {
      body.push({ name: 'files', content: f });
    });
    return service.postMultipartData({ route: ['form'] }, body);
  };

  return { getForm, postForm };
};

export default residentRequestService();
