import React, { FC } from 'react';
import { Typography, Button } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router';
import { History } from 'history';
import Like from '../../assets/like.svg';
import { useStyles } from './ResetPasswordConfirmationStyles';
import { routes } from '../../Routes';

interface ResetPasswordConfirmationComponentProps extends RouteComponentProps {
  history: History;
}

const ResetPasswordConfirmationComponent: FC<ResetPasswordConfirmationComponentProps> = ({
  history,
}) => {
  const classes = useStyles();
  const redirectTo = (path: string) => {
    history.push(path);
  };
  return (
    <div className={classes.resetPasswordConfirmationContainer}>
      <img className={classes.successLogo} src={Like} alt="password reset success" />
      <Typography variant="h6">Your password has been reset!</Typography>
      <div className={classes.buttonContainer}>
        <Button
          fullWidth
          className={classes.loginButton}
          onClick={() => redirectTo(routes.login.view)}
        >
          <Typography className={classes.loginLabel}>LOGIN</Typography>
        </Button>
      </div>
    </div>
  );
};

export default withRouter(ResetPasswordConfirmationComponent);
