import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { pageContainer, primaryContent, smallPageContainer } from '../../theme/GlobalStyles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  root: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
  },
  pageContainer: {
    ...pageContainer,
    paddingTop: 0,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
      paddingTop: 0,
    },
  },
  contentContainer: {
    maxWidth: '1000px',
    margin: 'auto',
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  heading: {
    fontSize: '2.625rem',
    fontWeight: 500,
    color: theme.colors.sortedPurple,
    fontFamily: 'GT Walsheim',
    paddingBottom: '0.5rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '2.297rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '1.969rem',
    },
  },
  subHeading: {
    fontSize: '1.375rem',
    color: '#9e9e9e',
    textAlign: 'justify',
    paddingBottom: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '1.203rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '1.031rem',
    },
  },
  messageHeading: {
    fontSize: '2.125rem',
    fontWeight: 500,
    color: theme.colors.sortedPurple,
    fontFamily: 'GT Walsheim',
    textAlign: 'center',
    paddingTop: '2rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '1.797rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '1.469rem',
    },
  },
});
