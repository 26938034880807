import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  RDSContainer: {
    margin: 'auto',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    position: 'relative',
    marginTop: '-15px',
  },
  titleContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: '36px',
    fontWeight: 300,
    color: theme.colors.sortedPurple,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      fontSize: '28px',
    },
  },
  subtitle: {
    marginTop: '15px',
    fontSize: '20px',
    fontWeight: 100,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    color: theme.colors.black87,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
      marginRight: '15px',
    },
  },
  primaryButton: {
    marginLeft: '30px',
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sortedPurple,
    borderRadius: '6px',
    color: theme.colors.light,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
  },
  checkboxContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: 'calc(1000px - 30px)',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  checkboxStyle: {
    marginLeft: '4px',
  },
  checkboxText: {
    marginTop: 2,
    cursor: 'pointer',
    userSelect: 'none',
  },
  declarationText: {
    fontSize: '16px',
    color: theme.colors.black87,
    fontWeight: 100,
  },
  signContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
  },
  signingContainers: {
    display: 'flex',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  signBelow: {
    flex: 3,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flex: 1,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '-25px',
    },
  },
  initialBelow: {
    flex: 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flex: 1,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '-50px',
    },
  },
});
