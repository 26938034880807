/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Location } from 'history';
import { ApplicationState } from '../../../../store/RootReducers';
import { getQueryParams } from './SearchPropertiesUtils';
import { useStyles } from './SearchPropertiesStyles';
import { LABELS } from './SearchPropertiesConstants';
import {
  AgencyDetails,
  PropertyDetailsResponse,
} from '../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import {
  DashboardActions,
  DashboardActionTypes,
  StoreAgencyPayload,
} from '../../../../store/actions/DashboardActions';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import search from '../../../../assets/navigation/search.png';
import Input from '../../../../component/input/Input';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Gravatars from '../../../../component/gravatars/Gravatars';
import Button from '../../../../component/button/Button';
import { AgencyLimited } from '../../../../models/agencies/Agencies';
import { GroupResponse } from '../../../../services/groups/Groups';
import { loadingSelector } from '../../../../store/selectors/LoadingSelector';
import { ClickAwayListener } from '@mui/material';
import { GroupsActions } from '../../../../store/actions/GroupsActions';

interface SearchPropertiesProps {
  location: Location;
  failedSearchString: string | undefined;
  agency: string | null | undefined;
  branch: string | null | undefined;
  propertiesOption: PropertyDetailsResponse[];
  properties: PropertyDetailsResponse[] | undefined;
  selectedProperties: PropertyDetailsResponse[];
  disabled?: boolean;
  agencyDisabled?: boolean;
  agencies: AgencyLimited[] | undefined;
  groups: GroupResponse[] | undefined;
  loading: boolean;
  agencyDetails: AgencyDetails | undefined;
  selectedGroupRefId: string | null;
  propertyDetails: PropertyDetailsResponse;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
  setGroupRefId: (groupRefId: string | null) => void;
  setSelectedProperties: React.Dispatch<React.SetStateAction<PropertyDetailsResponse[]>>;
  getProperties: (address: string, agency: string, branch?: string) => void;
  clearProperties: () => void;
  setSnackBarState: (value: boolean) => void;
  setSnackBarText: (value: string) => void;
}

const SearchProperties: FC<SearchPropertiesProps> = ({
  location,
  failedSearchString,
  agency,
  branch,
  propertiesOption,
  selectedProperties,
  disabled,
  agencyDisabled,
  agencies,
  groups,
  loading,
  agencyDetails,
  properties,
  selectedGroupRefId,
  propertyDetails,
  setGroupRefId,
  setSelectedProperties,
  getProperties,
  clearProperties,
  setSnackBarState,
  setSnackBarText,
  storePropertyAgency,
}) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState<string>(failedSearchString || '');
  const [searchAgency, setSearchAgency] = useState<string>(
    agencyDetails ? agencyDetails.tradingName : '',
  );

  const [searchGroup, setSearchGroup] = useState<string>(
    selectedGroupRefId || (groups && groups.length ? groups[0].groupRefId : ''),
  );
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const searchTextURL = getQueryParams(location, 'searchText');

  const selectedAgency = useMemo<AgencyLimited | null>(() => {
    if (searchAgency && agencies && !!agencies.length) {
      const result = agencies.find((a) => a.tradingName === searchAgency);
      if (result) {
        if (agency !== result.code) {
          storePropertyAgency({ agency: result.code });
        }
        return result;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [searchAgency, agencies]);

  const searchAddress = () => {
    const agencyToSearch = selectedAgency
      ? selectedAgency.code
      : agency || getQueryParams(location, 'agencyCode');
    const branchToSearch = branch || getQueryParams(location, 'branchCode');
    if (searchKey) {
      getProperties(searchKey, agencyToSearch, branchToSearch);
    }
  };

  useEffect(() => {
    if (searchTextURL) {
      setSearchKey(searchTextURL);
    }
  }, [searchTextURL]);

  useEffect(() => {
    if (searchKey.length > 3 && searchAgency) {
      setMenuOpen(true);
      searchAddress();
    }
  }, [searchKey, searchAgency]);

  useEffect(() => {
    if (agencyDetails) {
      setSearchAgency(agencyDetails.tradingName);
    }
  }, [agencyDetails]);

  useEffect(() => {
    if (selectedGroupRefId) {
      setSearchGroup(selectedGroupRefId);
    }
  }, [selectedGroupRefId]);

  const selectedGroup = useMemo<GroupResponse | null>(() => {
    if (searchGroup) {
      return groups!.find((g) => g.groupRefId === searchGroup)!;
    } else {
      return null;
    }
  }, [searchGroup, groups]);

  useEffect(() => {
    if (failedSearchString) {
      searchAddress();
    }
  }, [failedSearchString]);

  const findRentFromPropertyAddress = (address: string): number | undefined => {
    const streetNumber = getQueryParams(location, 'streetNumber');
    const streetName = getQueryParams(location, 'streetName');
    const unitNumber = getQueryParams(location, 'unitNumber');

    if (
      address.includes(streetNumber) &&
      address.includes(streetName) &&
      address.includes(unitNumber)
    ) {
      return parseInt(getQueryParams(location, 'rent'), 10);
    }
    return 0;
  };

  const getPropertiesOptions = () => {
    if (propertiesOption && propertiesOption.length) {
      return propertiesOption
        .map((p: PropertyDetailsResponse) => ({
          value: p,
          label: p.address,
        }))
        .filter(
          (d: { label: string }) =>
            !selectedProperties.filter((u: PropertyDetailsResponse) => u.address === d.label)
              .length,
        );
    }
    return [];
  };

  const onPropertySelect = (property: { value: PropertyDetailsResponse }) => {
    if (property) {
      setSnackBarState(true);
      setSnackBarText('Property added');

      const data = selectedProperties.length > 0 ? selectedProperties : [];
      property.value.rentalOffer = findRentFromPropertyAddress(
        property.value.address.toLowerCase(),
      );
      property.value.inspectionDate = null;
      property.value.preferredMoveInDate = null;
      property.value.years = 1;
      property.value.months = 0;
      const newProps = [property.value, ...data];
      setSelectedProperties(newProps);
      // setSelectedProperties is only called when the clicks on a property in the search field.
      // clear the property-list after we select one property from the suggestion-list
      clearProperties();
    } else {
      setSearchKey('');
    }
  };

  return (
    <div className={classes.searchContainer}>
      <div className={classes.searchContent}>
        <img className={classes.searchIcon} src={search} alt="search" />
        <Input
          placeholder="Search for a property..."
          value={searchKey}
          setValue={(value: string) => {
            setSearchKey(value);
          }}
          parentStyles={classes.searchInputContainer}
          parentInputStyles={classes.searchInputInner}
          hideEndElement
          disabled={disabled}
        />
        <Button
          parentStyles={classes.searchButtonTop}
          disabled={!(!!searchAgency && !!searchKey && !disabled)}
          onPress={() => {
            setMenuOpen(true);
            searchAddress();
          }}
        >
          {LABELS.SEARCH}
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.agencyPickerContainer}>
          {selectedAgency && (
            <img src={selectedAgency.circularLogo!} className={classes.agency} alt="agency" />
          )}
          {agencies && (
            <InputSelect
              placeholder="Select a agency"
              value={selectedAgency ? selectedAgency.tradingName : ''}
              values={agencies
                .filter(
                  (a) => a.tradingName && a.allowedPortals && a.allowedPortals.includes('apply'),
                )
                .map((a) => ({ display: a.tradingName!, value: a.tradingName! }))}
              setValue={(value) => {
                setSearchAgency(value as string);
              }}
              parentStyles={classes.searchInputContainer}
              parentInputStyles={classes.searchInputInner}
              hideEndElement
              disabled={
                disabled ||
                agencyDisabled ||
                (!!properties && !!properties.length) ||
                !!propertyDetails
              }
              sort
            />
          )}
        </div>
        {/* <div className={classes.groupPickerContainer}>
          {selectedGroup && (
            <div className={classes.groupsContainer}>
              <Gravatars group={selectedGroup} parentStyles={classes.gravatar} />
            </div>
          )}
          {groups && (
            <InputSelect
              placeholder="Select a group"
              value={selectedGroup ? selectedGroup.groupName : ''}
              values={groups.map((a) => ({ display: a.groupName, value: a.groupRefId }))}
              setValue={(value) => {
                setGroupRefId(value as string);
                setSearchGroup(value as string);
              }}
              parentStyles={classes.searchInputContainer}
              parentInputStyles={classes.searchInputInner}
              hideEndElement
              disabled={disabled}
            />
          )}
        </div> */}

        <Button
          parentStyles={classes.searchButton}
          disabled={!(!!searchAgency && !!searchKey && !disabled)}
          onPress={() => {
            setMenuOpen(true);
            searchAddress();
          }}
        >
          {LABELS.SEARCH}
        </Button>
      </div>

      {loading ? (
        <div className={classes.menu}>
          <div className={classes.noOptionsMsg}>{LABELS.LOADING}</div>
        </div>
      ) : (
        <>
          {!!getPropertiesOptions().length && !disabled && menuOpen && (
            <ClickAwayListener
              onClickAway={() => {
                setSearchKey('');
                clearProperties();
                setMenuOpen(false);
              }}
            >
              <div className={classes.menu}>
                {getPropertiesOptions().map((o, idx) => (
                  <div key={idx} className={classes.menuItem} onClick={() => onPropertySelect(o)}>
                    {o.label}
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          )}
          {propertiesOption && !propertiesOption.length && menuOpen && (
            <ClickAwayListener
              onClickAway={() => {
                setSearchKey('');
                clearProperties();
                setMenuOpen(false);
              }}
            >
              <div className={classes.menu}>
                <div className={classes.noOptionsMsg}>{LABELS.NOT_FOUND}</div>
              </div>
            </ClickAwayListener>
          )}
        </>
      )}
    </div>
  );
};

const loading = loadingSelector([DashboardActionTypes.FETCH_PROPERTIES]);

const mapStateToProps = (state: ApplicationState) => ({
  properties: state.landingScreen.properties,
  propertiesOption: state.dashboard.properties!,
  propertyDetails: state.dashboard.propertyDetails,
  agency: state.dashboard.agency,
  agencyDetails: state.dashboard.agencyDetails,
  branch: state.dashboard.branch,
  agencies: state.searchState.agencies,
  groups: state.groups.groups,
  selectedGroupRefId: state.groups.selectedGroupRefId,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProperties: (address: string, agency: string, branch?: string) => {
    dispatch(
      DashboardActions.getProperties({
        agency,
        branch,
        address,
      }),
    );
  },
  setGroupRefId: (groupRefId: string | null) => {
    dispatch(GroupsActions.setSelectedGroupRef(groupRefId));
  },
  clearProperties: () => {
    dispatch(DashboardActions.clearProperties());
  },
  storePropertyAgency: (data: StoreAgencyPayload) =>
    dispatch(
      DashboardActions.storePropertyAgency({ agency: data.agency, branch: data.branch || '' }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchProperties);
