import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { useStyles } from './SortedApplicationsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import Box from '../../../../component/box/Box';
import clipboard from '../../../../assets/clipboard.png';
import { routes } from '../../../../Routes';
import { LABELS } from './SortedApplicationsConstants';
import { ApplicationsResponse } from '../../../../services/groups/Groups';

interface FindAPropertyProps extends RouteComponentProps {
  groupApplications: ApplicationsResponse | undefined;
}

const FindAProperty: FC<FindAPropertyProps> = ({ history, groupApplications }) => {
  const classes = useStyles();

  return (
    <Box lightBorder innerStyles={classes.container}>
      <div className={classes.profileHeader}>
        <img src={clipboard} className={classes.icon} alt="clipboard icon" />
        <Text parentStyles={classes.title} textVariant="contentTitle">
          {LABELS.TITLE}
        </Text>
      </div>
      <Text textVariant="info">
        {LABELS.SUBTITLE(
          groupApplications ? groupApplications.submittedApplicationGroups.groups.length : 0,
        )}
      </Text>
      <div className={classes.buttonContainer}>
        <Button onPress={() => history.push(routes.applications.view)}>{LABELS.BUTTON}</Button>
      </div>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FindAProperty));
