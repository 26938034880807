import React, { FC, useEffect, useState, useMemo } from 'react';

import { useStyles } from './PartneredBrandsStyles';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import phone from '../../../../assets/brands/phone.png';
import Kanopi from '../../../../assets/brands/Kanopi.png';
import ActewAGL from '../../../../assets/brands/ActewAGL.png';
import OVO from '../../../../assets/brands/OVO.png';
import Muval from '../../../../assets/brands/Muval.png';
import Tangerine from '../../../../assets/brands/Tangerine.png';
import EnergyAustralia from '../../../../assets/brands/EnergyAustralia.png';
import Goodtel from '../../../../assets/brands/Goodtel.png';
import sortedLogoHeader from '../../../../assets/sortedLogoHeader.png';
import { LABELS } from './PartneredBrandsConstants';

interface PartneredBrandsProps {}

const PartneredBrands: FC<PartneredBrandsProps> = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={sortedLogoHeader} className={classes.sortedLogo} alt="sorted" />
      <Text textVariant="title" parentStyles={classes.title}>
        {LABELS.TITLE}
      </Text>
      <Text textVariant="info" size="l" parentStyles={classes.subtitle}>
        {LABELS.SUBTITLE}
      </Text>
      <div className={classes.assetsContainer}>
        <div className={classes.brandsLeft}>
          <img src={OVO} className={classes.brand} style={{ height: 60 }} alt="brand" />
          <img
            src={EnergyAustralia}
            className={classes.leftBrand}
            style={{ width: 150 }}
            alt="brand"
          />
          <img src={Tangerine} className={classes.brand} alt="brand" />
        </div>
        <img src={phone} className={classes.phone} alt="phone" />

        <div className={classes.brandsRight}>
          <img src={Kanopi} className={classes.brand} style={{ height: 30 }} alt="brand" />
          <img src={Muval} className={classes.rightBrand} alt="brand" />
          <img src={ActewAGL} className={classes.brand} alt="brand" />
        </div>
      </div>
    </div>
  );
};

export default PartneredBrands;
