import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
  },
  paperBack: globalStyles.paperBack as any,
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
  },
  inputContainerStyle: {
    paddingBottom: '8px',
  },
  inputLabelStyle: {
    padding: '16px',
    font: 'inherit',
    background: theme.colors.textarea,
    height: 150,
    border: 'none',
    borderRadius: '6px',
    resize: 'none',
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  remainingCharacters: {
    textAlign: 'right',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '36px',
      textAlign: 'right',
    },
    marginTop: '33px',
    textAlign: 'center',
  },
  button: {
    height: 48,
    background: theme.colors.sortedPurple,
    margin: '56.3px 16px 35px',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  questionLabelStyle: {
    padding: '0px 0 22px 0px',
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
  question: {
    marginBottom: theme.units.spacing * 4,
  },
});
