import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { theme } from '../../theme/Theme';
import {
  actionGreen,
  maxContentWidth,
  pageContainer,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...smallPageContainer,
  },
  titleContainer: {
    ...pageTitleContainer,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitSubtitle: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 12,
  },
  contentTitleContainer: {
    marginBottom: theme.units.spacing * 4,
  },
  pageContent: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 12,
      maxWidth: '100%',
    },
  },
  boxContainer: {
    marginBottom: theme.units.spacing * 4,
  },
  applicationRow: {
    display: 'flex',
    alignItems: 'stretch',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  propertyContainer: {
    marginRight: theme.units.spacing * 4,
    width: 140,
    height: 140,
    borderRadius: theme.units.borderRadius / 1.5,
    backgroundColor: theme.colors.lightGrey,
    flexShrink: 0,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginRight: 0,
      width: '100%',
      height: 200,
      marginBottom: theme.units.spacing * 2,
    },
  },
  property: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  applicationDetails: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'row',
  },
  applicationDetailsMiddle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 4,
    },
  },
  applicationDetailsFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  propertyAddressContainer: {
    marginRight: 'auto',
  },
  address1: {
    marginBottom: theme.units.spacing * 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  address2: {},
  rentAndAgencyContainer: {
    marginLeft: 'auto',
    display: 'flex',
  },
  agencyContainer: {
    height: '70px',
    flexShrink: 0,
    borderRadius: 5000,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      height: '50px',
    },
  },
  agencyLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  rentContainer: {
    marginLeft: theme.units.spacing * 4,
    borderLeft: `${theme.colors.borderColorLight} 2px solid`,
    paddingLeft: theme.units.spacing * 4,
  },
  rentRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 2,
    cursor: 'pointer',
  },
  rent: {
    color: theme.colors.secondary,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  rentError: {
    color: theme.colors.error,
    fontSize: 24,
  },
  editIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginLeft: theme.units.spacing * 3,
    ...actionGreen,
    alignSelf: 'flex-start',
    position: 'relative',
    top: 2,
  },
  inputContainer: {
    width: 140,
    marginRight: theme.units.spacing * 2,
    marginBottom: theme.units.spacing * 2,
  },
  parentInputStyles: {
    width: '100%',
    maxWidth: 'max-content',
  },
  rentInfo: {},
  deleteButtonContainer: {
    borderLeft: `${theme.colors.borderColorLight} 2px solid`,
    paddingLeft: theme.units.spacing * 4,
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  deleteButton: {
    minWidth: 60,
    height: 'min-content',
  },
  deleteButtonContainerLarge: {
    display: 'none',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'flex',
    },
    width: '100%',
  },
  deleteButtonLarge: {
    flex: 1,
  },
  statsRow: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
  },
  statTextRow: {
    display: 'flex',
    alignItems: 'center',
  },
  statNumber: {
    marginLeft: theme.units.spacing * 3,
    position: 'relative',
    top: 2,
  },
  statText: {
    position: 'relative',
    top: 2,
    marginLeft: theme.units.spacing * 1,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'none',
    },
  },
  hotContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  hotText: {
    marginRight: theme.units.spacing * 2,
  },
  hotIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
  },
  whatsNextContainer: {
    width: '100%',
    overflow: 'scroll',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 4,
  },
  nextContainer: {
    minHeight: 240,
  },
  nextTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 6,
  },
  nextIcon: {
    width: theme.units.iconSize * 1.2,
    height: theme.units.iconSize * 1.2,
    objectFit: 'contain',
    objectPosition: 'center',
  },
  nextTime: {
    padding: '4px 8px',
    backgroundColor: theme.colors.lightPurple,
    borderRadius: 4,
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
  },
  nextTitle: {
    marginBottom: theme.units.spacing * 3,
  },
  bottomContainer: {
    marginTop: theme.units.spacing * 16,
  },
});
