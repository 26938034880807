import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   height: 'calc(100vh - 48px)',
    //   overflowY: 'scroll',
    //   backgroundColor: theme.colors.light,
    // },
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
  },
  sectionTitle: {
    marginBottom: 0,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 6,
  },
  identificationNumberLabelStyle: {
    fontSize: '16px',
    fontWeight: 500,
  },
  removeButton: {},
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },
  selectStyles: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  genericLabel: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    fontFamily: 'GT Walsheim,Helvetica Neue,Helvetica,Arial,sans-serif',
    fontWeight: 500,
    lineHeight: '1.5',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  removeLabelStyle: {
    color: '#F88379',
    fontSize: '12px',
    lineHeight: 0,
    padding: 3,
  },
  inputContainerStyle: {
    paddingBottom: '8px',
  },
  inputLabelStyle: {
    paddingLeft: '0px',
  },
  dateOfBirthLabelStyle: {
    padding: '23px 0 0 16px',
    fontSize: '12px',
  },
  preferredLeaseDurationLabelStyle: {
    padding: '23px 0 0 16px',
  },
  yearMonthStyle: {
    width: '45%',
  },
  yearMonthLabelStyle: {
    paddingLeft: '22px',
  },
  doYouHaveGuarantorLabelStyle: {
    padding: '28px 0 22px 16px',
  },
  mobileNumberView: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  containerStyle: {
    'display': 'flex',
    'flexDirection': 'row',
    'paddingBottom': '16.3px',
    'justifyContent': 'space-between',
    '@media (min-width:780px)': {
      width: '45%',
    },
  },
  hide: {
    display: 'none',
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '16px',
    alignItems: 'center',
  },
  groupPetIconStyle: {
    paddingRight: '16px',
  },
  numberStyle: {
    padding: '0 9px 0',
  },
  addIdentificationButtonContainer: {
    width: '100%',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      textAlign: 'right',
    },
    marginTop: '8px',
    textAlign: 'center',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  overlay: {
    float: 'right',
    right: 0,
  },
  addIdentificationButton: {
    width: 'auto',
  },
  addIdentificationDescription: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    color: theme.colors.buttonPrimary,
  },
  counter: {
    minWidth: '10px',
    textAlign: 'center',
  },
  numberChangeStyle: {
    cursor: 'pointer',
  },
  inputFileStyle: {
    padding: 2,
  },
  errorText: {
    color: `${theme.colors.error} !important`,
  },
  identificationCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 0px 20px',
    borderRadius: '6px',
    border: `${theme.colors.borderColorLight} 2px solid`,
    marginBottom: '20px',
  },
  datePicker: globalStyles.datePicker,
  dateInputLabelStyle: {
    '& input': {
      paddingLeft: '16px',
      color: 'transparent',
      textShadow: '0 0 0 black',
    },
  },
  inputLabel: {
    'top': '-8px',
    'fontSize': '15px',
    'color': theme.colors.sortedPurple,

    '& > h6': {
      fontSize: '18px',
      color: theme.colors.sortedPurple,
    },
  },
  monthYearStyle: {
    resize: 'inline',
    height: 10,
    width: 10,
    cursor: 'pointer',
  },
  addressIcon: {
    position: 'absolute',
    right: 0,
    top: 20,
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
  fileInputTitle: {
    marginBottom: theme.units.spacing * 2,
    fontWeight: 500,
  },
  fileInputDescription: {
    marginBottom: theme.units.spacing * 2,
  },
  ntdContainer: {
    borderRadius: theme.units.borderRadius,
    border: `${theme.colors.borderColorLight} 2px solid`,
    backgroundColor: theme.colors.lightGrey,
    padding: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 4,
  },
  ntdHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 8,
  },
  ntdTitle: {},
  ntdSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  ntdSwitchLabel: {
    fontSize: theme.font.l,
    fontWeight: getFonts('Medium'),
    color: theme.colors.subtitle,
    marginRight: theme.units.spacing,
  },
  reportGrid: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 800,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      maxWidth: 700,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  dataPoint: {
    display: 'flex',
  },
  dataPointKey: {
    marginRight: theme.units.spacing * 2,
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.black87,
  },
  dataPointValue: {
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.subtitle,
  },
  transactionRow: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
  },
  downloadPDFContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.units.spacing * 12,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      gridTemplateColumns: '1fr',
      gridGap: theme.units.spacing * 4,
    },
  },
  cloudDownload: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    marginLeft: theme.units.spacing * 2,
  },
  refresh: {
    width: theme.units.iconSize / 1.4,
    height: theme.units.iconSize / 1.4,
    objectFit: 'contain',
    objectPosition: 'center',
    marginLeft: theme.units.spacing * 2,
  },
  downloadPDFButton: {
    width: 'max-content',
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors.light,
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.spacing * 4,
    paddingRight: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    backgroundColor: theme.colors.buttonPrimary,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    boxSizing: 'content-box',
    position: 'relative',
    height: 40,
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: 'auto',
    },
  },
  startAgainButton: {
    width: 'max-content',
    height: 40,
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors.black87,
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.spacing * 4,
    paddingRight: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.light,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    boxSizing: 'content-box',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginRight: 0,
      width: 'auto',
      marginLeft: 0,
    },
  },
  infoContainer: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.lightBlue,
    marginBottom: theme.units.spacing * 4,
  },
});
