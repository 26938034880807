/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-shadow */
import React, { FC, useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { Location } from 'history';
import { match } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';
import { LABELS } from './WelcomeConstants';
import { useStyles } from './WelcomeStyles';
import {
  getAllQueryParams,
  getMatchParams,
  getQueryParams,
  isValidPropertyURL,
  MatchParams,
} from '../../../../helper/URLHelper';
import propertyDefaultIcon from '../../../../assets/homePlaceholder.png';
import naxLogo from '../../../../assets/nelsonAlexanderCircle.png';
import { View } from '../../Login';
import localStorage from '../../../../services/LocalStorage';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import {
  Assets,
  PropertyDetailsResponse,
} from '../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

interface WelcomeProps {
  location: Location;
  match: match<MatchParams>;
  view: View;
  propertyDetails: PropertyDetailsResponse | undefined;
  agencyCode: string | null | undefined;
  myAccountFlag: boolean;
  assets: Assets | null | undefined;
}

const Welcome: FC<WelcomeProps> = ({
  location,
  match,
  view,
  propertyDetails,
  agencyCode,
  myAccountFlag,
  assets,
}) => {
  const [URLError, setURLError] = useState<boolean>(false);
  const [URLRent, setURLRent] = useState<number | null>(null);
  const matchParams = getMatchParams(match.params[0]);
  const classes = useStyles();

  useEffect(() => {
    const { refId, type, agentId, address, suburb, state, postcode, streetNumber, streetName } =
      getAllQueryParams(location, matchParams);
    localStorage.setItem('landingURL', `${location.pathname}${location.search}`);

    if (getQueryParams(location, 'rent')) {
      setURLRent(parseInt(getQueryParams(location, 'rent'), 10));
    }

    if (
      !isValidPropertyURL(
        refId,
        type,
        agentId,
        address,
        suburb,
        state,
        postcode,
        streetNumber,
        streetName,
      )
    ) {
      setURLError(true);
    }
  }, []);

  const secondaryWelcomeText = () => {
    if (
      location.pathname.includes('applications') ||
      location.pathname.includes('login') ||
      myAccountFlag
    ) {
      return LABELS.DEFAULT_TEXT;
    } else if (agencyCode === '' || agencyCode === 'SORTED') {
      return LABELS.DEFAULT_TEXT;
    }

    switch (view) {
      case View.ForgotPassword:
        return LABELS.FORGOT_VIEW;
      case View.Verify:
        return LABELS.VERIFY_VIEW;
      default:
        return LABELS.CANNOT_FIND_PROPERTY;
    }
  };

  return (
    <div
      className={classNameGenerator([
        classes.pageContainer,
        !(propertyDetails && !URLError) && classes.hide,
      ])}
    >
      <Text textVariant="title" size="xxl">
        {LABELS.WELCOME}
      </Text>
      <Text textVariant="title" size="xxl">
        {LABELS.WELCOME_SUBTITLE}
      </Text>
      {propertyDetails && (
        <Box parentStyles={classes.boxStyling}>
          <div className={classes.propertyContainer}>
            <div className={classes.propertyImageContainer}>
              <img
                src={
                  propertyDetails.photos && !!propertyDetails.photos.length
                    ? propertyDetails.photos[0]
                    : propertyDefaultIcon
                }
                className={classes.propertyImage}
                alt="property"
              />
            </div>
            <div className={classes.propertyDetails}>
              <Text size="xl" parentStyles={classes.propertyAddress}>
                {propertyDetails.address}
              </Text>
              {(propertyDetails.rentalOffer || URLRent) && (
                <Text size="m" textVariant="link">
                  ${propertyDetails.rentalOffer || URLRent}/week
                </Text>
              )}
            </div>
            {assets && assets.circularLogo && (
              <div className={classes.naxLogoContainer}>
                <img src={assets.circularLogo} className={classes.naxLogo} alt="agency" />
              </div>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  propertyDetails: state.dashboard.propertyDetails,
  agencyCode: state.dashboard.agency,
  myAccountFlag: state.myProfileForm.myAccountFlag,
  assets: state.dashboard.assets,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
