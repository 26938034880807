/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/prefer-default-export */

import { Application, ApplicationsResponse } from '../../services/groups/Groups';
import { notInFlightStatus } from './ApplicationsConstants';

export const getStatus = (status: string) => {
  const appStatus = status.toUpperCase();
  if (appStatus === 'NEW') {
    return 'Submitted';
  }
  if (
    appStatus === 'PROCESSING' ||
    appStatus === 'LANDLORD_APPROVED' ||
    appStatus === 'PENDING_APPROVAL'
  ) {
    return 'Processing';
  }
  if (['UNSUCCESSFUL', 'REJECTED', 'BLACKFLAG'].includes(appStatus)) {
    return 'Unsuccessful';
  }
  if (
    appStatus === 'WITHDRAWN' ||
    appStatus === 'CANCELLED' ||
    appStatus === 'APPLICANT WITHDRAWN'
  ) {
    return 'Withdrawn';
  }
  if (appStatus === 'ACCEPTED') {
    return 'Accepted';
  }
  if (appStatus === 'DECLINE') {
    return 'Declined';
  }
  if (appStatus === 'SHORTLISTED') {
    return 'Shortlisted';
  }
  return '';
};

export const isSyncRequired = (
  applications: Application[],
  groupApplications: ApplicationsResponse | undefined,
): boolean => {
  let containsValidStatus = false;
  for (let i = 0; i < applications.length; i++) {
    if (
      groupApplications!.updateRequired.includes(applications[i].refId!) &&
      !notInFlightStatus.includes(getStatus(applications[i].status![0].status))
    ) {
      containsValidStatus = true;
    }
  }
  return containsValidStatus;
};

export const isInformationRequested = (
  applications: Application[],
  groupApplications: ApplicationsResponse | undefined,
): boolean => {
  let containsValidStatus = false;
  for (let i = 0; i < applications.length; i++) {
    if (
      groupApplications!.informationRequested.includes(applications[i].refId!) &&
      !notInFlightStatus.includes(getStatus(applications[i].status![0].status))
    ) {
      containsValidStatus = true;
    }
  }
  return containsValidStatus;
};
