import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { maxContentWidth, pageContainer, smallPageContainer } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  root: {},
  termExpanded: {
    marginTop: '0px !important',
  },
  termLabel: {
    paddingTop: 7,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  termIndex: {
    alignSelf: 'normal',
    marginTop: 15,
  },
  termTitle: {
    marginLeft: 0,
    wordBreak: 'break-word',
  },
  termDescription: {
    marginLeft: 32,
    // width: '100%',
    wordBreak: 'break-word',
  },
  termContainerNoBorder: {
    boxShadow: 'none',
    paddingBottom: '120px',
  },
  link: {
    textDecoration: 'underline',
    fontSize: 12,
    cursor: 'pointer',
    color: theme.colors.success,
  },
  containerSpaceBetween: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
  },
  dialogStyle: {
    borderRadius: 0,
    margin: '16px',
  },
  gridStyle: {
    padding: '0 40',
    background: theme.colors.light,
    overflow: 'auto',
  },
  signatureTitle: {
    color: theme.colors.black,
    paddingBottom: 2,
  },
  stepHeaderText: {
    marginTop: 10,
    color: theme.colors.sortedPurple,
    borderWidth: 0,
  },
  footerContainerStyle: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
  },
  footerGridStyle: {
    padding: 16,
    paddingTop: 0,
    display: 'flex',
  },
  footerDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: 16,
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
  },

  makeupdateButton: {
    padding: '8px 16px',
    marginTop: 16,
    textTransform: 'none',
  },
  headerSubheading: {
    color: theme.colors.light,
    fontSize: 14,
    marginBottom: 8,
  },
  headerTitle: {
    color: theme.colors.light,
    fontSize: 24,
    marginBottom: 8,
  },
  headerDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
  headerGrid: {
    height: '180px',
    backgroundColor: theme.colors.sortedPurple,
    padding: 16,
    display: 'flex',
  },
  headerContainer: {
    width: '100%',
    backgroundColor: theme.colors.sortedPurple,
  },
  panelStyles: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
  pageContainer: {
    ...pageContainer,
    paddingBottom: 0,
    paddingTop: 0,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  signingTitlesContainer: {
    marginBottom: theme.units.spacing * 12,
  },
  signingTitle: {
    marginBottom: theme.units.spacing * 4,
  },
  signingSubtitle: {
    marginTop: '15px',
    fontSize: '20px',
    fontWeight: 100,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  // ---------------- Start of Property Card ----------------
  propertyContainer: {
    // border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    margin: 'auto',
    marginTop: '-15px',
    maxWidth: 'calc(1000px - 30px)',
    marginBottom: '35px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
  },
  propertyImageContainer: {
    borderRadius: 1000,
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 1px solid`,
    overflow: 'hidden',
    width: '42px',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  propertyImage: {
    width: '100%',
    height: '100%',
    borderRadius: 5000,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  propertyImageBlank: {
    position: 'relative',
    top: '-1px',
  },
  propertyDetails: {
    marginRight: 'auto',
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  propertyAddress: {
    fontSize: 18,
    opacity: 0.87,
  },
  propertySuburb: {
    opacity: 0.38,
  },
  // ---------------- End of Property Card ----------------
  termsConditionsContainer: {
    padding: '30px',
    paddingTop: '0px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
  },
  termsConditionsWrapper: {
    height: '100%',
    maxHeight: '700px',
    overflow: 'scroll',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    '&:hover': {
      border: `${theme.colors.secondary} 2px solid`,
    },
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    fontWeight: getFonts('Medium'),
    borderRadius: '6px',
    color: theme.colors.black87,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
      marginRight: '15px',
    },
  },
  primaryButton: {
    minWidth: 200,
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    border: `${theme.colors.textarea} 2px solid`,
    color: theme.colors.label,
    cursor: 'default',
  },
  checkboxAck: {
    marginTop: theme.units.spacing * 8,
    marginBottom: theme.units.spacing * 8,
  },
  checkboxContainer: {
    marginTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  checkboxStyle: {
    marginLeft: '4px',
  },
  checkboxText: {
    marginTop: 2,
    fontWeight: getFonts('Medium'),
    color: theme.colors.black87,
    fontSize: 16,
    cursor: 'pointer',
    userSelect: 'none',
  },
  previewContainer: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 8,
    cursor: 'pointer',
  },
  // ---------------- Start of Download Docs ----------------
  downloadDocsContainer: {
    padding: '30px',
    paddingTop: '0px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
  },
  downloadCard: {
    padding: '18px 0px',
    marginTop: 9,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `${theme.colors.borderColor} 1px solid`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  downloadContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  docIconStyle: {
    paddingRight: '20px',
    width: '30px',
    height: '30px',
  },
  downloadButton: {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  acceptContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  // ---------------- End of Download Docs ----------------
  // ---------------- Start of Signing Area ----------------
  signingWrapper: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridTemplateColumns: '1fr 1fr',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  witnessColumn: {
    [`@media (max-width: 1000px)`]: {
      flexDirection: 'column',
    },
  },
  declarationText: {
    fontSize: '16px',
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
  },
  signContainer: {
    paddingBottom: '30px',
    paddingTop: '0px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '0',
      paddingBottom: '15px',
    },
  },
  signLabelContainer: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  signLabel: {
    fontSize: '15px',
    color: theme.colors.black87,
    marginLeft: '10px',
  },
  signAgain: {
    fontSize: '15px',
    color: theme.colors.success,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: '10px',
  },
  signatureDivStyle: {
    width: '100%',
    height: 260,
    backgroundColor: theme.colors.disabled,
    overflow: 'hidden',
    borderRadius: '6px',
    border: `${theme.colors.borderColor} 2px solid`,
    position: 'relative',
  },
  signpadLabelContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  signpadLabel: {
    width: '220px',
    textAlign: 'center',
    opacity: 0.2,
    fontSize: '17px',
  },
  dontShow: {
    display: 'none',
  },
  blueborder: {
    border: `${theme.colors.success} 2px solid`,
  },
  redborder: {
    border: `${theme.colors.warning} 2px solid`,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
  },
  errorIcon: {
    marginRight: '5px',
    filter: 'saturate(2878%) contrast(97%)',
  },
  witnessInputStyle: {
    backgroundColor: theme.colors.light,
    padding: '10px 0px 16px 12px',
    marginTop: '15px',
    fontSize: '17px',
  },
  witnessFormStyle: {
    display: 'flex',
    flex: 1,
    padding: '0 10',
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.1)',
  },
  // ---------------- End of Signing Area ----------------
  // ---------------- Start of Person Card ----------------
  personCard: {
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: 8,
    padding: '15px',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    minHeight: 50,
    maxHeight: 50,
  },
  personImage: {
    height: '50px',
    width: '50px',
    borderRadius: 500,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '40px',
      width: '40px',
    },
  },
  gravatar: {
    height: '50px',
    width: '50px',
    marginLeft: -8,
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontSize: '16px',
    border: `${theme.colors.light} 1px solid`,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '40px',
      width: '40px',
    },
  },
  personDetails: {
    marginLeft: '20px',
    marginRight: 'auto',
  },
  personName: {
    color: theme.colors.black87,
    marginBottom: 4,
  },
  personContext: {
    color: theme.colors.label,
    fontSize: '15px',
  },
  rentContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  total: {
    color: theme.colors.label,
    fontSize: '16px',
    marginLeft: '8px',
    width: '65px',
  },
  rentInputContainer: {
    minWidth: '50px',
    minHeight: '15px',
    padding: '12px',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
  },
  rentInput: {
    border: 'none',
    background: 'none',
    maxWidth: '60px',
    width: '100%',
    fontSize: '16px',
    outline: 'none',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  rentSymbol: {
    color: theme.colors.success,
    fontWeight: 'bold',
    fontSize: '18px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  // ---------------- End of Person Card ----------------
});
