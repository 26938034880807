import { IntroFormData } from '../state/MyProfileFormState';

export enum IntroductionScreenActionTypes {
  POST_FORM_REQUEST = 'POST_INTRO_REQUEST',
}

export interface IntroductionScreenAction {
  type: IntroductionScreenActionTypes;
  data?: IntroFormData | Error;
}

export class IntroductionScreenActions {
  public static postFormDetails(data: IntroFormData): IntroductionScreenAction {
    return {
      type: IntroductionScreenActionTypes.POST_FORM_REQUEST,
      data,
    };
  }
}
