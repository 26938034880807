/* eslint-disable import/prefer-default-export */

export const LABELS = {
  APPLICATIONS: 'Applications',
  CONTACT: 'Contact agent',
  LOCATION: 'Location',
  AGENT_DETAILS: 'Contact Details',
  SEARCHBAR_PLACEHOLDER_TEXT: 'Search ...',
  NOT_FOUND: 'No matching properties found',
};
