/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles, primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.light,
    alignItems: 'center',
    padding: '25px 30px',
  },
  title: {
    color: theme.colors.sortedPurple,
    fontSize: 28,
    marginBottom: '10px',
  },
  subtitle: {
    fontWeight: 'lighter',
    opacity: 0.87,
  },
  contentContainer: {
    padding: '30px',
    backgroundColor: theme.colors.light,
  },
  inputContainerStyle: {
    width: '100%',
    paddingBottom: '8px',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },

  enterEmail: {
    paddingTop: '5px',
    color: theme.colors.buttonPrimary,
  },
  errorText: {
    color: theme.colors.warning,
    marginTop: '15px',
    fontWeight: 300,
  },

  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
  },
  backButton: {
    position: 'relative',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.grey} 2px solid`,
    fontSize: 16,
    fontWeight: 300,
    borderRadius: '6px',
    padding: '15px',
    width: '180px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
    },
  },
  backarrow: {
    position: 'absolute',
    left: '15px',
  },
  loginButton: {
    backgroundColor: theme.colors.buttonPrimary,
    fontSize: 16,
    fontWeight: 300,
    border: 'none',
    borderRadius: '6px',
    padding: '15px',
    width: '180px',
    color: theme.colors.light,
    cursor: 'pointer',
    textAlign: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
      marginLeft: '30px',
    },
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    maxWidth: 800,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 8,
      maxWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 64,
    marginTop: 24,
  },
  icon: {
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  primaryTitle: {
    marginLeft: theme.units.spacing * 4,
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  marginBottom: {
    marginBottom: theme.units.spacing * 4,
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginLeft: 0,
    },
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 6,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
    },
  },
  forgotRow: {
    marginTop: theme.units.spacing * 2,
    // borderTop: `${theme.colors.borderColor} 2px solid`,
    // paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  forgotButton: {
    marginLeft: theme.units.spacing * 4,
  },
});
