import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { MultipartFileData } from '../MultipartFormData.data';

const serviceType = ServiceType.referenceTool;
const resendEmailServiceType = ServiceType.resendEmail;

export class ReferenceToolService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);
  private readonly resendEmailService: ApiServiceBase = new ApiService(resendEmailServiceType);

  public getReferencesData(tokenId: string): Promise<void> | void {
    return this.service.get({
      route: [],
      query: { token: tokenId },
    });
  }
  public postReferencesData(payload: any, tokenId: string): Promise<void> | void {
    return this.service.post(
      {
        route: [],
        query: { token: tokenId },
      },
      payload,
    );
  }
  public postResendEmail(id: number) {
    const query = { id };
    return this.resendEmailService.post(
      {
        query,
      },
      id,
    );
  }

  public postReferenceFile(token: string, payload: File): Promise<void> | void {
    const data: MultipartFileData = { name: 'file', file: payload };
    return this.service.postMultipart({ route: ['upload'], query: { token } }, data);
  }
}

const referenceToolService = new ReferenceToolService();
export default referenceToolService;
