import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
  },
  formContainer: {
    padding: 12,
    // marginBottom: 24,
    // border: `${theme.colors.borderColor} 2px solid`,
    // borderRadius: 6,
  },
  body: {
    padding: '12px',
  },
  title: {
    paddingTop: 70,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  inputs: {
    width: '100%',
    marginTop: 36,
    '&:first-child': {
      marginTop: '0px',
    },
    '&:last-child': {
      marginTop: '24px',
    },
  },
  secureIcon: {
    padding: '0 12px 16px 0',
  },
  errorMessage: {
    color: 'red',
  },
  infoBox: {
    borderRadius: '6px',
    padding: '15px',
    backgroundColor: theme.colors.fadedBlue,
    color: theme.colors.success,
    marginBottom: '20px',
  },
  link: {
    color: theme.colors.success,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  buttonContainer: {
    margin: 'auto',
    width: '100%',
    maxWidth: '1000px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '15px',
  },
  primaryButton: {
    // marginLeft: '15px',
    // width: '50%',
    // height: '60px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: theme.colors.sortedPurple,
    // borderRadius: '6px',
    // color: theme.colors.light,
    // cursor: 'pointer',
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   marginLeft: '0px',
    //   width: '100%',
    // },
  },
  secondaryButton: {
    // width: '50%',
    // height: '60px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: theme.colors.light,
    // border: `${theme.colors.borderColor} 2px solid`,
    // borderRadius: '6px',
    // color: theme.colors.black87,
    // cursor: 'pointer',
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   width: '100%',
    //   marginRight: '15px',
    // },
    marginRight: theme.units.spacing * 4,
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
    border: `#ededed 2px solid`,
  },
  errorButton: {
    border: `${theme.colors.error} 2px solid`,
    backgroundColor: theme.colors.error,
    color: theme.colors.light,
  },
});
