import tutorial1 from '../../assets/ntdImages/tutorial1.jpg';
import tutorial2 from '../../assets/ntdImages/tutorial2.jpg';
import tutorial3 from '../../assets/ntdImages/tutorial3.jpg';

export const LABELS = {
  GET_STARTED: 'Get started',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  BACK: 'Back',
  PURCHASE_NTD: 'Purchase background check',
  PAY_NOW: 'Pay now',
  COLLECTION_STATEMENT_TITLE: 'Personal Information Collection Statement',
  COLLECTION_STATEMENT_CONTENT: `
    <p>If you request a tenancy report we will disclose your personal information to our service provider, Equifax Australia Information Services and Solutions Pty Limited ABN 26 000 602 862 or its related bodies corporate (<strong>Equifax</strong>) for Equifax to generate the report. For this purpose Equifax may check the Equifax National Tenancy Database, Trading Reference Australia, the TICA tenant database, government records, court documents and other public databases. Please see <a href="https://static1.squarespace.com/static/5ad6c933ee1759a2c83f3ef2/t/5d40dee75545900001568399/1564532841824/Sorted-Services-Privacy-Policy.pdf" target="_blank">Sorted Services' Privacy Policy</a> for further information on how your personal information is collected, used and disclosed and how you can complain about a privacy breach. Please see <a href="https://www.equifax.com.au/privacy" target="_blank">Equifax's Privacy Policy</a> for details of how Equifax will handle your personal information.</p>`,
  ACKNOWLEDGE: 'Acknowledgement',
  ACKNOWLEDGE_INFO: `<p>By continuing below you are agreeing to our terms and conditions set out <a href="https://sortedservices.com/s/Sorted-Services-Agency-Declaration_Equifax_updated-August-2021.pdf" target="_blank">here</a>. This service is provided by Equifax, you can also find Equifax's terms and conditions <a href="https://www.tenancydatabase.com.au/terms" target="_blank">here</a></p>`,
  VIEW_IN_PROFILE: 'View in My Profile',
  DEFAULT: 'Background check on by default',
};

export const STEPS = [
  {
    headerImage: tutorial1,
    title: 'What is a Background check?',
    subtitle:
      "Strengthen your application with an Equifax National Tenancy Database Check, Australia's leading tenancy database. Your background check will:",
    secondary: LABELS.CANCEL,
    primary: LABELS.NEXT,
  },
  {
    headerImage: tutorial3,
    title: 'Ready to boost your application?',
    subtitle: 'Some things to note:',
  },
  {
    title: 'Payment',
    subtitle:
      'Enter your payment details below. Once payment processing has completed, you will be able to view your background check report in your profile',
    secondary: LABELS.CANCEL,
    primary: LABELS.PAY_NOW,
  },
  {
    title: 'Report being generated',
    subtitle:
      'When your Background Check is complete, it will be stored against your profile in the Identification section.',
  },
];
