import {
  IntroductionScreenAction,
  IntroductionScreenActionTypes,
} from '../actions/IntroductionActions';
import { IntroductionState } from '../state/IntroductionState';
import { IntroFormData } from '../state/MyProfileFormState';

export const initialState: IntroductionState = {
  introDetails: undefined,
};

export function introReducer(state = initialState, action: IntroductionScreenAction) {
  state = postIntroDetailsReducer(state, action);
  return state;
}

function postIntroDetailsReducer(state: any, action: IntroductionScreenAction) {
  switch (action.type) {
    case IntroductionScreenActionTypes.POST_FORM_REQUEST:
      return {
        ...state,
        introDetails: action.data as IntroFormData,
      };
    default:
      return state;
  }
}
