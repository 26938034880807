/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import {
  ShortApplicationAction,
  ShortApplicationActions,
  ShortApplicationActionTypes,
} from '../actions/ShortApplicationActions';
import ShortApplicationService from '../../services/shortApplication/ShortApplicationService';
import {
  GetBuildingsRequest,
  GetBuildingsResponse,
  GetPartnerDetailsRequest,
  GetQuestionsRequest,
  GetQuestionsResponse,
  PostQuestionsRequest,
} from '../../models/shortApplication/shortApplication';

export function* getShortApplicationBuildingsRequest(action: ShortApplicationAction) {
  try {
    const response: GetBuildingsResponse[] =
      yield ShortApplicationService.getShortApplicationBuildings(
        action.data as GetBuildingsRequest,
      );
    yield put(ShortApplicationActions.getShortApplicationBuildingsSuccess(response));
  } catch (error) {
    yield put(ShortApplicationActions.getShortApplicationBuildingsError(error as Error));
  }
}

export function* getShortApplicationQuestionsRequest(action: ShortApplicationAction) {
  try {
    const response: GetQuestionsResponse[] =
      yield ShortApplicationService.getShortApplicationQuestions(
        action.data as GetQuestionsRequest,
      );
    yield put(ShortApplicationActions.getShortApplicationQuestionsSuccess(response));
  } catch (error) {
    yield put(ShortApplicationActions.getShortApplicationBuildingsError(error as Error));
  }
}

export function* postShortApplicationQuestionsRequest(action: ShortApplicationAction) {
  try {
    yield ShortApplicationService.postShortApplicationQuestions(
      action.data as PostQuestionsRequest,
    );
    yield put(ShortApplicationActions.postShortApplicationQuestionsSuccess());
  } catch (error) {
    yield put(ShortApplicationActions.postShortApplicationQuestionsError(error as Error));
  }
}

export function* getPartnerDetails(action: ShortApplicationAction) {
  try {
    const response: string = yield ShortApplicationService.getPartnerDetails(
      (action.data as GetPartnerDetailsRequest).partnerCode,
    );
    yield put(ShortApplicationActions.getPartnerDetailsSuccess(response));
  } catch (error) {
    yield put(ShortApplicationActions.getPartnerDetailsError(error as Error));
  }
}

function* shortApplicationWatcher() {
  yield takeEvery(
    ShortApplicationActionTypes.GET_SHORT_APPLICATION_BUILDINGS_REQUEST,
    (action: ShortApplicationAction) =>
      getShortApplicationBuildingsRequest({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    ShortApplicationActionTypes.GET_SHORT_APPLICATION_QUESTIONS_REQUEST,
    (action: ShortApplicationAction) =>
      getShortApplicationQuestionsRequest({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    ShortApplicationActionTypes.POST_SHORT_APPLICATION_QUESTIONS_REQUEST,
    (action: ShortApplicationAction) =>
      postShortApplicationQuestionsRequest({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    ShortApplicationActionTypes.GET_PARTNER_DETAILS_REQUEST,
    (action: ShortApplicationAction) => getPartnerDetails({ type: action.type, data: action.data }),
  );
}

export default shortApplicationWatcher;
