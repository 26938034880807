import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { actionGreen } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 2,
  },
  box: {
    height: 'min-content',
  },
  gravatarRow: {
    display: 'flex',
    // marginBottom: theme.units.spacing * 4,
    marginLeft: theme.units.spacing,
  },
  gravatarSize: {
    width: 40,
    height: 40,
  },
  titleContainer: {
    display: 'flex',
    cursor: 'pointer',
  },
  groupTitle: {
    fontSize: 28,
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 3,
    marginTop: -theme.units.spacing * 1,
  },
  inputContainer: {
    width: 200,
    marginRight: theme.units.spacing * 2,
    marginBottom: 0,
  },
  input: {
    // fontSize: 28,
  },
  hideBox: {
    border: 'none',
    background: 'none',
  },
  hideBoxInner: {
    padding: 0,
  },
  saveButton: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.s,
    paddingLeft: theme.units.spacing * 6,
    paddingRight: theme.units.spacing * 6,
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.buttonPrimary} 3px solid`,
    backgroundColor: theme.colors.buttonPrimary,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    boxSizing: 'content-box',
  },
  editIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginLeft: theme.units.spacing * 3,
    ...actionGreen,
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.units.spacing * 4,
  },
  statsRow: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 600,
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
  },
  statText: {
    marginLeft: theme.units.spacing * 3,
    position: 'relative',
    top: 2,
  },
  tick: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    transform: 'scale(1.2)',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  lineRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  buttonMarginRight: {
    marginRight: theme.units.spacing * 4,
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionText: {
    marginRight: theme.units.spacing * 4,
  },
  actionIcon: {
    width: theme.units.iconSize / 1.5,
    height: theme.units.iconSize / 1.5,
  },
});
