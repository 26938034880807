/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { getFonts } from '../../../../helper/GetFonts';
import { SIZES } from '../../../../constants/AppConstants';
import { primaryContent } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  input: {
    padding: '0.5rem',
    fontSize: theme.font.xl,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    borderRadius: theme.units.borderRadius / 2,
    border: `${theme.colors.borderColor} 2px solid`,
  },
  container: {
    gridGap: '0.5rem',
  },
  title: {
    color: theme.colors.sortedPurple,
    fontSize: 28,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 64,
    marginTop: 24,
  },
  icon: {
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  primaryTitle: {
    marginLeft: theme.units.spacing * 4,
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    maxWidth: 800,
    flexShrink: 0.7,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  subtitleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 8,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
    },
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginLeft: 0,
    },
  },
  loginRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  errorText: {
    color: theme.colors.error,
    fontWeight: 500,
    marginRight: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
  },
  signupRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  signupButton: {
    minWidth: 70,
    marginLeft: theme.units.spacing * 4,
  },
});
