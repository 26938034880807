/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import {
  pageContainer,
  globalStyles,
  smallPageContainer,
  maxContentWidth,
} from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  contentContainer: {
    padding: '30px',
    backgroundColor: theme.colors.light,
  },
  sortedLogo: {
    height: '40px',
    marginBottom: '30px',
    marginTop: '10px',
  },
  title: {
    color: theme.colors.sortedPurple,
    fontSize: 28,
    marginBottom: '30px',
  },
  subtext: {
    fontWeight: 'lighter',
    opacity: 0.87,
    marginBottom: '20px',
    fontSize: '16px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  stepsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '50px',
    [`@media (min-width: 2400px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    marginBottom: '60px',
    // maxWidth: 1200,
  },
  step: {
    // height: '50px',
  },
  stepImgContainer: {
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5px',
  },
  stepImg: {
    width: '90%',
    height: '100%',
    objectFit: 'contain',
  },
  stepTitle: {
    fontSize: '22px',
    fontWeight: 500,
    margin: '10px',
    marginBottom: '18px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  stepText: {
    fontWeight: 'lighter',
    fontSize: '16px',
    margin: '10px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  page: {
    backgroundColor: theme.colors.lightGrey,
  },
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...pageContainer,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  bodyText: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 8,
  },
});
