/* eslint-disable max-lines */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { maxContentWidth, pageContainer, smallPageContainer } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...pageContainer,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  titleContainer: {
    padding: '30px',
    paddingBottom: '0px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: '36px',
    fontWeight: 300,
    color: theme.colors.sortedPurple,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      fontSize: '28px',
    },
  },
  subtitle: {
    marginTop: '15px',
    fontSize: '20px',
    fontWeight: 100,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  contentContainer: {
    padding: '30px',
    paddingTop: '15px',
    paddingBottom: '0px',
    paddingLeft: 0,
    paddingRight: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      paddingLeft: 0,
      paddingRight: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  listTitle: {
    marginTop: '15px',
    fontSize: '18px',
    color: theme.colors.black87,
    paddingBottom: '15px',
    borderBottom: `${theme.colors.borderColor} 1px solid`,
  },
  listContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridGap: '30px',
    marginTop: '30px',
    marginBottom: '30px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listIconContainer: {
    width: '35px',
    height: '35px',
    marginRight: '20px',
  },
  listIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  listText: {
    fontSize: '22px',
    color: theme.colors.black87,
  },
  iconContainer: {
    marginLeft: 'auto',
    width: '100px',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconSize: {
    width: '30px',
    height: '30px',
  },
  contactMeButton: {
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sortedPurple,
    borderRadius: '6px',
    color: theme.colors.light,
    cursor: 'pointer',
    marginTop: '30px',
    marginBottom: '30px',
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
  },
  skipButton: {
    marginTop: '30px',
    marginBottom: '30px',
    color: theme.colors.black87,
    cursor: 'pointer',
    textAlign: 'center',
  },
  servicePartnersContainer: {
    margin: 'auto',
    maxWidth: '1000px',
    position: 'relative',
    marginTop: '20px',
    paddingTop: '30px',
    marginBottom: '80px',
  },
  servicePartners: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridGap: '30px',
    marginTop: '40px',
    justifyItems: 'center',
  },
  serviceImgContainer: {
    width: '120px',
    height: '50px',
  },
  serviceImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  loading: {
    paddingTop: '30px',
    paddingBottom: '30px',
    margin: 'auto',
    textAlign: 'center',
    width: '100%',
  },
  buttonContainer: {
    margin: 'auto',
    maxWidth: '1000px',
    borderRadius: '6px',
    overflow: 'hidden',
    position: 'relative',
    marginBottom: '80px',
    // marginTop: '20px',
  },
  todo: {
    fontFamily: 'GT Walsheim',
    fontSize: '20px',
    color: theme.colors.sortedPurple,
    marginBottom: '15px',
    marginTop: '25px',
  },
  todoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  todoText: {
    marginRight: 'auto',
    fontSize: '18px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  pendingButton: {
    backgroundColor: theme.colors.yellowLabel,
    borderRadius: 6,
    padding: '5px 15px',
    fontSize: '16px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  incompleteButton: {
    backgroundColor: theme.colors.incompleteLabel,
    borderRadius: 6,
    padding: '5px 15px',
    fontSize: '16px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  purpleBackground: {
    backgroundColor: theme.colors.sortedPurple,
    width: '100%',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: theme.units.spacing * 8,
    borderTopLeftRadius: theme.units.borderRadius,
    borderTopRightRadius: theme.units.borderRadius,
  },
  houseContainer: {
    width: '400px',
    height: '400px',
    position: 'absolute',
    bottom: '-111px',
  },
  house: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  truck: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    animation: `truckMoving 1.5s cubic-bezier(0.645, 0.045, 0.355, 1)`,
  },
  '@keyframes truckMoving': {
    '0%': {
      opacity: 0,
      left: -100,
    },
    '100%': {
      opacity: 1,
      left: 0,
    },
  },
  lights: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    animation: `glow 1s infinite alternate`,
  },
  '@keyframes glow': {
    '0%': {
      filter: 'brightness(1)',
    },
    '100%': {
      filter: 'brightness(1.3)',
    },
  },
  insurance: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    animation: `dome 2s infinite alternate`,
  },
  '@keyframes dome': {
    '0%': {
      filter: 'hue-rotate(0) brightness(1) saturate(1)',
    },
    '100%': {
      filter: 'hue-rotate(-40deg) brightness(1.3) saturate(7)',
    },
  },
  wifi: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    animation: `scale 1s infinite alternate`,
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1.1)',
    },
  },
  walletCreditsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  walletCreditsTitle: {
    fontFamily: 'GT Walsheim',
    fontSize: '24px',
    color: theme.colors.black87,
  },
  walletCreditsAmount: {
    fontFamily: 'GT Walsheim',
    fontSize: '36px',
    color: theme.colors.success,
  },
  creditButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90px',
    height: '30px',
    fontFamily: 'GT Walsheim',
    color: theme.colors.light,
    fontSize: '14px',
    borderRadius: '6px',
    fontWeight: 500,
    backgroundColor: theme.colors.success,
    cursor: 'pointer',
  },
  meritonContainer: {
    padding: '30px',
    paddingBottom: '5px',
    borderRadius: '6px',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColorLight} 2px solid`,
  },
  occumOffersContainer: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  meritonTitle: {
    fontSize: '24px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
    fontWeight: getFonts('Medium'),
    marginBottom: theme.units.spacing * 12,
  },
  meritonSubtitle: {
    fontSize: '20px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
    fontWeight: getFonts('Medium'),
  },
  meriEnergyFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
    },
  },
  meriEnergyLogo: {
    height: '250px',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  qrCode: {
    height: '150px',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  meriSubline: {
    fontSize: '20px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
    fontWeight: getFonts('Medium'),
    textAlign: 'center',
    marginBottom: theme.units.spacing * 4,
  },
  occomTitle: {
    fontSize: '18px',
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    marginBottom: '-10px',
    fontFamily: 'GT Walsheim',
  },
  occomLi: {
    fontSize: '14px',
    color: theme.colors.black60,
    fontFamily: 'GT Walsheim',
  },
  occomCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.units.spacing * 12,
    marginBottom: theme.units.spacing * 12,
  },
  yesContactContainer: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
  },
  yesDisclamer: {
    fontSize: 14,
    color: theme.colors.black38,
  },
  star: {
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',
    marginBottom: '15px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  offerContainer: {
    marginTop: '20px',
    margin: 'auto',
    maxWidth: '1000px',
    marginBottom: '80px',
  },
  offerTitle: {
    marginLeft: '15px',
    fontSize: '24px',
    color: theme.colors.black87,
    marginBottom: '15px',
  },
  offerCard: {},
  offerImage: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    width: '100%',
    height: 200,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  offerDetailsContainer: {
    position: 'relative',
    backgroundColor: theme.colors.light,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: `${theme.colors.borderColorLight} 2px solid`,
    borderTopWidth: 0,
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
  },
  offerSortedLogo: {
    position: 'absolute',
    right: '15px',
    borderRadius: 5000,
    width: 70,
    height: 70,
    top: -35,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  offerText: {
    fontSize: '24px',
    color: theme.colors.black87,
    marginBottom: '20px',
    maxWidth: '80%',
  },
  offerButton: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    height: '45px',
    fontFamily: 'GT Walsheim',
    color: theme.colors.light,
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 500,
    backgroundColor: theme.colors.sortedPurple,
    cursor: 'pointer',
  },
  pageSubtitle: {
    maxWidth: 570,
  },
  moveInText: {
    marginTop: theme.units.spacing * 6,
    fontWeight: getFonts('Light'),
    fontSize: 18,
    marginBottom: theme.units.spacing * 6,
  },
  stepsContainer: {
    marginTop: theme.units.spacing * 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  bigStepContainer: {
    padding: theme.units.spacing * 6,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColorLight} 2px solid`,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  minorSteps: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.units.spacing * 4,
    width: '100%',
    flex: 1,
  },
  stepContainer: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColorLight} 2px solid`,
    flex: 1,
  },
  purpleStepContainer: {
    backgroundColor: theme.colors.lightPurple,
    flex: 1,
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    cursor: 'pointer',
  },
  statusRow: {
    marginBottom: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusWhite: {
    backgroundColor: theme.colors.light,
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    padding: `${theme.units.spacing}px ${theme.units.spacing * 3}px`,
    borderRadius: theme.units.borderRadius / 3,
  },
  statusGrey: {
    backgroundColor: theme.colors.borderColorLight,
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    padding: `${theme.units.spacing}px ${theme.units.spacing * 3}px`,
    borderRadius: theme.units.borderRadius / 3,
  },
  statusDone: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    padding: `${theme.units.spacing}px ${theme.units.spacing * 3}px`,
    borderRadius: theme.units.borderRadius / 3,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 500,
    backgroundColor: theme.colors.error,
  },
  stepTitle: {
    marginBottom: theme.units.spacing * 2,
  },
  stepSubtitle: {},
  actionContainer: {
    // marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.units.spacing * 4,
  },
  action: {
    height: 25,
  },
  questionsContainer: {
    ...pageContainer,
    backgroundColor: theme.colors.lightGrey,
    marginTop: theme.units.spacing * 16,
    paddingTop: theme.units.spacing * 12,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  bodyText: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 8,
  },
  questionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  questionsFAQ: {
    maxWidth: 800,
  },
  questionsFAQContainer: {
    paddingTop: theme.units.spacing * 4,
  },
  FAQFooter: {
    marginTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  findAnswer: {
    fontWeight: getFonts('Medium'),
  },
  contact: {
    marginLeft: theme.units.spacing * 2,
  },
  propertyManager: {
    maxWidth: 360,
    flexShrink: 0,
    width: '100%',
    marginLeft: theme.units.spacing * 8,
  },
  propertyManagerContainer: {},
  propertyManagerTopRow: {
    display: 'flex',
    alignItems: 'center',
  },
  gravatar: {
    height: '50px',
    width: '50px',
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '24px',
    border: `${theme.colors.light} 1px solid`,
    flexShrink: 0,
  },
  propertyManagerNameContainer: {
    marginLeft: theme.units.spacing * 4,
  },
  propertyManagerBottomRow: {
    marginTop: theme.units.spacing * 4,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    display: 'grid',
    gridGap: theme.units.spacing * 2,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
  },
  detail: {
    textAlign: 'right',
    fontWeight: getFonts('Medium'),
  },
  occumButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  occumButton: {},
  occomLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.units.spacing * 6,
  },
  occomLogo: {
    height: 200,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  scanContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  scanText: {
    textAlign: 'center',
    fontWeight: getFonts('Medium'),
  },
});
