import { useState } from 'react';

interface FetchState<T> {
  loading: boolean;
  success: boolean;
  error?: Error | any;
  data?: T;
}

type unBoxPromise<T extends Promise<any> | any> = T extends Promise<infer U> ? U : never;

/**
 * The purpose of this hook is to avoid fetching api through redux saga when we don't need to
 * store the response in redux store. Redux store should be used only when we need a shared state
 * for components which are deeply nested. Otherwise we're using it in a wrong way. This also takes away
 * the trouble of cleaning up the state on component unmount
 * @param asyncCallback pass the async callback for fetching data asynchronously
 * @param initialValue pass the initial value of the data.
 * @param disableLoader for disabling the blanket loader
 * @param disableErrorPopup for disabling pop-up on error
 * @param onSuccess callback after the async operation is successful
 * @typeParam T describes the type of data it is trying fetch also is the type of initial value
 */
const useFetch = <R extends (...args: any[]) => Promise<any> | any>(
  asyncCallback: R,
  initialValue?: unBoxPromise<ReturnType<R>>,
  onSuccess?: (data: any) => void,
): {
  state: FetchState<unBoxPromise<ReturnType<R>>>;
  fetchData: (...funcArgs: Parameters<R>) => Promise<FetchState<unBoxPromise<ReturnType<R>>>>;
} => {
  const initState: FetchState<unBoxPromise<ReturnType<R>>> = {
    loading: false,
    success: false,
    error: undefined,
    data: initialValue,
  };
  const [state, setState] = useState(initState);

  /**
   * @param disableLoader optional param to disable the loader
   */

  const fetchData = async (...args: Parameters<R>) => {
    try {
      setState({
        ...state,
        loading: true,
        success: false,
      });

      const response = await asyncCallback(...args);
      setState({
        data: response,
        loading: false,
        success: true,
        error: undefined,
      });
      if (onSuccess) onSuccess(response);
      return Promise.resolve({
        data: response,
        loading: false,
        success: true,
        error: undefined,
      });
    } catch (error) {
      setState({
        ...initState,
        error,
      });

      return Promise.reject(error);
    }
  };

  return { state, fetchData };
};

export default useFetch;
