import React, { FC, useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router';
import { History } from 'history';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { last } from 'lodash';
import sortedLogo from '../../../assets/sorted-logotype-rgb-navy-melon.png';
import {
  referenceTool,
  requestedReferee,
  purposeDiscription,
  referenceBtnLebel,
  learnMoreUrl,
  requestDemoUrl,
  RENTING_THROUGH_AGENT,
} from '../ReferenceToolConstants';
import { useStyles } from './WelcomeRefereeStyles';
import { routes } from '../../../Routes';
import { ReferenceToolActions } from '../../../store/actions/ReferenceToolActions';
import { ApplicationState } from '../../../store/RootReducers';
import { TypeHelper } from '../../../helper/TypeHelper';
import { ReferenceToolDataState } from '../../../store/state/ReferenceToolState';

interface WelcomeRefereeScreenProps extends RouteComponentProps {
  history: History;
  referencesData: ReferenceToolDataState | null;
  getError: string | null;
  getReferencesData: (token: string, history: any) => void;
  saveToken: (token: string) => void;
}

const WelcomeRefereeScreen: FC<WelcomeRefereeScreenProps> = ({
  history,
  referencesData,
  getError,
  saveToken,
  getReferencesData,
}) => {
  const [referencesDataState, setReferencesDataState] = useState<ReferenceToolDataState | null>(
    null,
  );
  const classes = useStyles();

  useEffect(() => {
    const url = window.location.href.split('/');
    const tokenId = last(url);
    saveToken(tokenId!);
    getReferencesData(tokenId!, history);
  }, []);

  useEffect(() => {
    setReferencesDataState(referencesData);
  }, [referencesData]);

  if (
    TypeHelper.isNullOrUndefined(referencesData) ||
    TypeHelper.isNullOrUndefined(referencesDataState)
  ) {
    return <Typography className={classes.dataLoadingStyle} />;
  }

  const { refereeName, applicantName, type, extendedData } = referencesDataState!;

  const isAgent =
    extendedData &&
    extendedData.currentLivingArrangement &&
    extendedData.currentLivingArrangement === RENTING_THROUGH_AGENT;

  const requestedRefereeText = requestedReferee[type].replace(`{applicantName}`, applicantName);
  return (
    <Grid className={classes.screenRootStyle} container>
      <Grid className={classes.screenBodyStyle} item xs={12} sm={8} md={7} lg={6}>
        <div className={classes.sortedLogoStyle}>
          <img className={classes.imageStyle} src={sortedLogo} alt="" />
          <span className={classes.referenceToolStyle}>{referenceTool}</span>
        </div>
        <div className={classes.referenceNameStyle}>{`Hi ${refereeName}`}</div>
        <div className={classes.requestedRefereeStyle}>{requestedRefereeText}</div>
        <p className={classes.purposeDiscriptionStyle}>{purposeDiscription(applicantName)}</p>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.referenceBtnStyle}
            onClick={() => {
              history.push(routes.questionnaire.view);
            }}
          >
            {referenceBtnLebel}
          </Button>
        </div>
        <div className={classes.sortedInfoStyle}>
          <span
            className={classes.learnMoreStyle}
            onClick={() => window.open(isAgent ? requestDemoUrl : learnMoreUrl, '_blank')}
          >
            Click here
          </span>
          <span className={classes.knowMoreStyle}>
            &nbsp;
            {isAgent
              ? `to find out more about Sorted – Australia’s first whole-of-agency home engagement solution.`
              : `to find out more about Sorted – Australia’s first all-in-one home services
              platform.`}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={5} lg={6} className={classes.referenceImageStyle} />
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  referencesData: state.referenceToolData.referencesData,
  getError: state.referenceToolData.getError,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getReferencesData: (token: string, history: any) => {
    dispatch(ReferenceToolActions.getReferencesData(token, history));
  },
  saveToken: (token: string) => {
    dispatch(ReferenceToolActions.saveToken(token));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WelcomeRefereeScreen));
