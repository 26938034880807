import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
    display: 'none',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'block',
    },
  },
  declarationPoints: {
    padding: '0 10px 0 10px',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '36px',
      textAlign: 'right',
    },
    marginTop: '33px',
    textAlign: 'center',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  signHereStyle: {
    color: theme.colors.sortedPurple,
    fontSize: '0.875rem',
  },
  signaturePadStyle: {
    // fontFamily: 'LaBelleAurore',
  },
  headerWrraperStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem',
  },
  signAgainStyle: {
    color: '#2BA7C0',
    cursor: 'pointer',
    fontSize: '0.75rem',
    textDecoration: 'underline',
  },
  agreeOnSignStyle: {
    color: '#272727',
    fontSize: '16px',
    fontFamily: 'GT Walsheim',
    letterSpacing: '0.13px',
    marginBottom: '6px',
    marginTop: '15px',
  },
  confirmMessageStyle: {
    fontSize: '13px',
    fontFamily: 'GT Walsheim',
    letterSpacing: '-0.2px',
    color: theme.colors.black87,
    paddingBottom: '30px',
  },
  optionIconWrpStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    height: '3rem',
  },
  signBtnStyle: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-evenly',
    height: '40%',
    alignItems: 'baseline',
  },
  signContainerStyle: {
    display: 'flex',
  },
  signPadStyle: {
    width: '80%',
    background: '#eeeeee',
  },
  uploadStyle: {
    width: '80%',
    background: '#eeeeee',
    display: 'flex',
  },
  docNameStyle: {
    color: theme.colors.sortedPurple,
    fontSize: '0.85rem',
  },
  updateSignatureBtnWrapperStyle: {
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#eeeeee',
    alignItems: 'center',
  },
  updateSignTextStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: '0.788rem',
    color: '#a8a8a8',
    paddingBottom: '0.813rem',
    textAlign: 'center',
    width: '89.2%',
  },
  updateSignatureStyle: {
    background: '#2BA7C0',
    color: '#ffff',
  },
  signPanelStyle: {
    margin: 0,
  },
  signSummaryStyle: {
    height: '0.875rem',
    minHeight: '1.5rem',
    color: '#2ca7c0',
    fontFamily: 'GT Walsheim',
    fontSize: '0.788rem',
    fontWeight: 500,
    padding: '0 0.3125rem',
  },
  signGuideStyle: {
    width: '100%',
  },
  opacityStyle: {
    opacity: 0.5,
  },
  ButtonTextStyle: {
    fontSize: '1rem',
    padding: '0.25rem',
    color: '#808080',
  },
  expendIconStyle: {
    color: '#2ca7c0',
    padding: 0,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
  scrollBox: {
    height: '830px',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: 15,
    marginBottom: 15,
    overflow: 'scroll',
    '&:hover': {
      border: `${theme.colors.buttonPrimary} 2px solid`,
    },
  },
  inputContainerStyle: {
    width: '100%',
  },
  signingContainer: {
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: 15,
  },
  signingLabel: {
    fontSize: '16px',
    color: theme.colors.black87,
  },
  inputStyle: {
    color: theme.colors.black87,
    background: theme.colors.light,
    padding: '10px 15px',
    borderRadius: 4,
    marginTop: '15px',
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
});
