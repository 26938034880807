import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  container: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  infoIcon: {
    marginBottom: 20,
    fontSize: 80,
  },
  helpSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: 20,
    },
  },
});
