import { ReactNode } from 'react';

export const LABELS = {
  RETRY_PAYMENT: 'Retry payment',
  RETRY_AGAIN: 'Retry',
  START_AGAIN: 'Start again',
  BACKGROUND_CHECK_PENDING: 'Background check pending',
  BACKGROUND_CHECK_PENDING_TEXT:
    'Your Background Check is currently being generated, sometimes this can take a few minutes. If it takes any longer, please contact us.',
  BACKGROUND_CHECK_FAILED: 'Background check failed',
  BACKGROUND_CHECK_FAILED_TEXT:
    'You have not been charged, there was an issue generated your background check. This can occur for a number of reasons, please ensure before trying again: that your profile name must match exactly one of your identification documents, the identification documents expiry dates are correct and you are using an australian mobile number. If you are still having difficulties, please contact our support team to resolve the issue',
  PAYMENT_FAILED: 'Payment failed for background check',
  PAYMENT_FAILED_TEXT: 'There was an issue in taking a payment for your background check.',
  BACKGROUND_CHECK_SUCCESS: 'Background check completed',
  BACKGROUND_CHECK_SUCCESS_TEXT:
    "Your background check was completed, we've emailed you a copy. You may now include it on your profile for any property applications.",
  BACKGROUND_CHECK_INCLUDED: (plural?: boolean) => `Background check${plural ? 's' : ''} included`,
  BACKGROUND_CHECK_INCLUDED_TEXT:
    'You are including a copy of a background check with this application. If you want to exclude it, go back and turn it off for each profile you do not want sent through with your application',
  BACKGROUND_CHECK_EXCLUDED: (plural?: boolean) =>
    `Background check not included${plural ? ' on all profiles' : ''}`,
  BACKGROUND_CHECK_EXCLUDED_TEXT:
    'One or more of your managed profiles has conducted a background check that might boost your application. If you want to include it, go back and turn it on for each eligible profile. Otherwise you may continue without.',
  NO_BACKGROUND_CHECK: 'No background check conducted',
  NO_BACKGROUND_CHECK_TEXT:
    'No background check conducted on any profiles. If you want to include one, you may purchase one within a profile. Otherwise you may continue without.',
  BACKGROUND_CHECK_EXPIRED: 'Background check expired',
  BACKGROUND_CHECK_EXPIRED_TEXT:
    'Your background check has expired, you may purchase another one to attach to your profile.',
};

export interface BackgroundCheckContent {
  icon: string;
  title: string;
  description: string;
  type: 'PENDING' | 'ERROR' | 'SUCCESS' | 'NORMAL';
  button?: ReactNode;
}

export enum RetryMethod {
  RETRY_PAYMENT,
  RETRY_CHECK,
}
