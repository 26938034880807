/* eslint-disable default-case */
import React, { FC, ReactNode, useState } from 'react';
import { FormControl, FormHelperText, InputAdornment, InputLabel } from '@mui/material';
import value from 'card-validator';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStyles } from './AddCardStyles';
import {
  cardHolderNameMaxLength,
  cardHolderNameRegex,
  cardNumberLength,
  cardNumberPattern,
  CardType,
  cardTypeNotSupportedError,
  CARD_HOLDER_NAME,
  CARD_NUMBER,
  CVV,
  EXPIRY,
  expiryDateError,
  expiryDateLength,
  expiryDatePattern,
  InitialValue,
  invalidCardError,
  nameFormatError,
  nameMaxLengthError,
  requiredFieldError,
} from './AddCardConstants';
import cardLogo from '../../assets/credit-card-24-px.svg';
import visaIcon from '../../assets/group-82.svg';
import masterCardIcon from '../../assets/mc-symbol.svg';
import lockIcon from '../../assets/lock-24-px.svg';
import calendarIcon from '../../assets/outlined.svg';
import errorIcon from '../../assets/error-outline-24-px.svg';
import CustomInput from '../customInput/CustomInput';
import { dateValidator } from '../../helper/DateHelper';
import { CardHelper } from '../../helper/CardHelper';
import Input from '../input/Input';
import Button from '../button/Button';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducers';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { PaymentActionTypes } from '../../store/actions/PaymentActions';
import { CreditCardRequest } from '../../services/remoteSigning/payment/Payment';
import { EquifaxActionTypes } from '../../store/actions/EquifaxActions';
import { MMYY_FORMAT, MONTH_AND_YEAR_FORMAT } from '../../constants/AppConstants';

interface AddCardProps {
  loading: boolean;
  error: any;
  terms?: ReactNode;
  submitted?: boolean;
  goBack: () => void;
  onSubmit: (creditCardDetails: CreditCardRequest) => void;
}

const AddCard: FC<AddCardProps> = ({ loading, error, terms, submitted, goBack, onSubmit }) => {
  // const cardExpiryValidator = CustomInput(expiryDatePattern);
  // const cardNumberValidator = CustomInput(cardNumberPattern);
  const [cardType, setCardType] = useState<string>('');
  const [cardError, setCardError] = useState<boolean>(false);
  const [cardErrorValue, setCardErrorValue] = useState<string>('');
  const [cvvLength, setCvvLength] = useState<number>(3);
  const [expiryError, setExpiryError] = useState<boolean>(false);
  const [cardTypeValidation, setCardTypeValidation] = useState<boolean>(true);
  const classes = useStyles();

  const initialValues: InitialValue = {
    cardHolderName: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  };

  const handleSubmit = (paymentDetails: InitialValue) => {
    const expiryDate = paymentDetails.expiryDate.split('/');
    const paymentRequest: CreditCardRequest = {
      type: 'CREDITCARD',
      cardHolderName: paymentDetails.cardHolderName,
      cardNumber: paymentDetails.cardNumber,
      email: '',
      expiryMonth: expiryDate[0],
      expiryYear: expiryDate[1],
    };
    onSubmit(paymentRequest);
  };

  const handleCardNumber = (cardValue: string) => {
    if (cardValue.length < 4) {
      setCardType('');
      setCardTypeValidation(true);
    } else if (cardType === '' && cardValue.length >= 4) {
      const { card } = value.number(cardValue);
      const type = card && (card as any).type;
      const cvvLength = card && card.code.size;
      const cardTypeValidation = type === CardType.Visa || type === CardType.MasterCard;
      setCardType(type);
      setCvvLength(cvvLength as number);
      setCardTypeValidation(cardTypeValidation);
    }
    const valid = value.number(cardValue.replace(/\s+/g, '')).isValid;
    switch (true) {
      case cardValue.length === cardNumberLength && !valid:
        setCardError(true);
        setCardErrorValue(invalidCardError);
        break;
      case !cardTypeValidation:
        setCardError(true);
        setCardErrorValue(`${cardType || ''} ${cardTypeNotSupportedError}`);
        break;
      case cardError && cardTypeValidation:
        setCardError(false);
        setCardErrorValue('');
        break;
    }
  };

  const renderCardIcon = () => {
    switch (cardType) {
      case 'visa':
        return visaIcon;
      case 'mastercard':
        return masterCardIcon;
      default:
        return cardLogo;
    }
  };

  const expiryDateValidation = (date: string) => {
    if (date.length === expiryDateLength) {
      setExpiryError(dateValidator(date, 'paymentCard'));
    } else if (expiryError) {
      setExpiryError(false);
    }
  };

  const handleCardDisplay = (cardNumber: string) => {
    const inputVal = cardNumber.replace(/ /g, ''); // remove all the empty spaces in the input
    let inputNumbersOnly = inputVal.replace(/\D/g, ''); // Get only digits

    if (inputNumbersOnly.length > 16) {
      // If entered value has a length greater than 16 then take only the first 16 digits
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }

    // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = '';
    if (splits) {
      spacedNumber = splits.join(' '); // Join all the splits with an empty space
    }

    return spacedNumber; // Set the new CC number
  };

  return (
    <div className={classes.formContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={Yup.object().shape({
          cardHolderName: Yup.string()
            .max(cardHolderNameMaxLength, nameMaxLengthError)
            .matches(cardHolderNameRegex, nameFormatError)
            .required(requiredFieldError),
          cardNumber: Yup.string().min(16).required(requiredFieldError),
          expiryDate: Yup.string()
            .min(5, expiryDateError)
            .matches(MMYY_FORMAT, expiryDateError)
            .required(requiredFieldError),
          cvv: Yup.string().min(cvvLength).max(cvvLength).required(requiredFieldError),
        })}
        render={({
          values,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isValid,
          touched,
          errors,
        }) => (
          <>
            <Input
              placeholder={CARD_HOLDER_NAME}
              value={values.cardHolderName || ''}
              setValue={(value: string) => {
                setFieldValue('cardHolderName', value);
              }}
              onBlur={() => setFieldTouched('cardHolderName')}
              touched={touched.cardHolderName}
              error={errors.cardHolderName}
              title={CARD_HOLDER_NAME}
              mandatory
              name="cc-name"
              autoComplete
            />
            <Input
              placeholder={CARD_NUMBER}
              value={values.cardNumber ? handleCardDisplay(values.cardNumber) : ''}
              setValue={(value: string) => {
                setFieldValue('cardNumber', value);
                handleCardNumber(value);
              }}
              onBlur={() => setFieldTouched('cardNumber')}
              touched={touched.cardNumber}
              error={errors.cardNumber || cardErrorValue}
              title={CARD_NUMBER}
              mandatory
              name="cc-number"
              autoComplete
            />
            <div className={classes.inputBoxStyle}>
              <Input
                placeholder={EXPIRY}
                value={values.expiryDate || ''}
                setValue={(value: string) => {
                  expiryDateValidation(value);
                  setFieldValue('expiryDate', value);
                }}
                onBlur={() => setFieldTouched('expiryDate')}
                touched={touched.expiryDate}
                error={expiryError ? expiryDateError : errors.expiryDate}
                title={EXPIRY}
                mandatory
                name="cc-exp"
                autoComplete
              />
              <Input
                placeholder={CVV}
                value={values.cvv || ''}
                setValue={(value: string) => {
                  setFieldValue('cvv', CardHelper.formatCvvNumber(value));
                }}
                onBlur={() => setFieldTouched('cvv')}
                touched={touched.cvv}
                error={errors.cvv}
                title={CVV}
                mandatory
                name="cc-csc"
                autoComplete
              />
            </div>
            {terms}
            <div className={classes.buttonWrapper}>
              {/* {error && <div className={classes.errorText}>{error}</div>} */}
              <div className={classes.buttonContainer}>
                <Button outline parentStyles={classes.secondaryButton} onPress={() => goBack()}>
                  Back
                </Button>
                <Button
                  parentStyles={error ? classes.errorButton : classes.primaryButton}
                  disabled={error ? false : !isValid || loading || submitted}
                  onPress={() => {
                    if (isValid) {
                      handleSubmit();
                    }
                  }}
                >
                  {submitted || loading ? 'Processing' : error ? 'Failed' : 'Submit'}
                </Button>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

const loading = loadingSelector([
  PaymentActionTypes.GET_PAYMENT_AUTH_KEY,
  PaymentActionTypes.POST_PAYMENT_TOKEN,
  EquifaxActionTypes.GET_NTD_CHECK,
]);

const error = errorMessageSelector([
  PaymentActionTypes.GET_PAYMENT_AUTH_KEY,
  PaymentActionTypes.POST_PAYMENT_TOKEN,
  EquifaxActionTypes.GET_NTD_CHECK,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  error: error(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
