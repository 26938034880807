/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useEffect, useRef, useMemo, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { get } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Switch from '@mui/material/Switch';
import Button from '../../../../component/button/Button';
import CountryList from '../../../../component/country/CountryList';
import MandatoryError from '../../../../component/errorPage/MandatoryError';
import FileUpload from '../../../../component/fileUpload/FileUpload';
import Input from '../../../../component/input/Input';
import InputDate from '../../../../component/inputDate/InputDate';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Text from '../../../../component/text/Text';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { checkRequiredDocument } from '../../../../helper/ProfileVerifyHelper';
import { isEmpty } from '../../../../helper/Validation';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import { ApplicationState } from '../../../../store/RootReducers';
import {
  ApplicantProfile,
  IdentificationTypes,
  MedicareColours,
  PostFormData,
  PostIdentificationFormData,
  StateOfIssueDL,
} from '../../../../store/state/MyProfileFormState';
import { ID_POINTS, LABELS, VALIDATION_SCHEMA } from './IdentificationFormConstants';
import { useStyles } from './IdentificationFormStyles';
import cloudDownload from '../../../../assets/cloud-download.png';
import refreshIcon from '../../../../assets/refresh.png';
import { theme } from '../../../../theme/Theme';
import NTDBanner from '../../../../component/ntdBanner/NTDBanner';
import { canShowNTDBanner, formNTDRequest, ntdRecords } from '../../../../helper/NTDHelper';
import {
  EquifaxPaymentStatus,
  EquifaxResponse,
  EquifaxResponseDictionary,
  EquifaxStatus,
  NTDRequest,
} from '../../../../models/equifax/equifax';
import { EquifaxActions } from '../../../../store/actions/EquifaxActions';
import { DownloadHelper } from '../../../../helper/DownloadHelper';
import { ApplicationsResponse } from '../../../../services/groups/Groups';
import BackgroundCheckBanner from '../../../../component/backgroundCheckBanner/BackgroundCheckBanner';
import moment from 'moment';
import NTDSidebar from '../../../../component/ntdSidebar/NTDSidebar';

interface IdentificationFormProps {
  isMobile: boolean;
  identificationDetails: PostIdentificationFormData | undefined;
  index: number | undefined;
  formData: ApplicantProfile;
  ntdChecks: EquifaxResponseDictionary | undefined;
  groupApplications: ApplicationsResponse | undefined;
  startEquifaxRequest: (ntdRequest: NTDRequest) => void;
  updateProfileChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postIdentificationFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
}

const IdentificationForm: FC<IdentificationFormProps> = ({
  isMobile,
  identificationDetails,
  index,
  formData,
  ntdChecks,
  groupApplications,
  startEquifaxRequest,
  updateProfileChangesFlag,
  setSelected,
  setChildFormRef,
  postIdentificationFormDetails,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostIdentificationFormData> | null>(null);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [equifaxRequestMade, setEquifaxRequestMade] = useState<boolean>(false);

  const hasVisited =
    identificationDetails &&
    !!identificationDetails.identification.length &&
    identificationDetails.identification[0].type !== null;
  const calculatePoints = (identificationData: PostIdentificationFormData) => {
    const points = identificationData.identification
      .map((identification): number => {
        if (identification && identification.type && identification.fileName) {
          return ID_POINTS[identification.type] as number;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0); // adding all elements of array.
    return points;
  };

  const [IDPoints, setIDPoints] = useState<number>(
    identificationDetails ? calculatePoints(identificationDetails) : 0,
  );
  const [documentRequired, setDocumentRequired] = useState<boolean>(false);
  const [profileChanged, setProfileChanged] = useState<boolean>(false);

  const ntdData = useMemo<EquifaxResponse | undefined>(() => {
    if (ntdChecks && formData.refId) return ntdChecks[formData.refId];
    return undefined;
  }, [ntdChecks, formData]);

  const updateProfileState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateProfileChangesFlag(true);
      setProfileChanged(true);
    }
  };

  useEffect(() => {
    setChildFormRef(formRef.current);

    if (
      identificationDetails &&
      identificationDetails.identification.length !== 0 &&
      !checkRequiredDocument(identificationDetails.identification)
    ) {
      setDocumentRequired(true);
    }

    const addPoints = () => {
      if (formRef.current) {
        setIDPoints(calculatePoints(formRef.current.state.values));
      }
    };

    document.addEventListener('click', addPoints);

    return () => document.removeEventListener('click', addPoints);
  }, []);

  useEffect(() => {
    if (
      identificationDetails &&
      identificationDetails.identification.length !== 0 &&
      identificationDetails.ntdPurchased &&
      groupApplications &&
      !equifaxRequestMade
    ) {
      setEquifaxRequestMade(true);
      startEquifaxRequest(formNTDRequest(formData, groupApplications));
    }
  }, [groupApplications, identificationDetails, formData, equifaxRequestMade]);

  const newId = {
    type: null,
    identificationLink: null,
    fileName: null,
    stateOfIssueDL: null,
    licenseNumber: null,
    countryOfOrigin: null,
    passportNumber: null,
    cardNumber: null,
    referenceNumber: null,
    medicareColour: null,
    expiryDate: null,
  };

  const initialValues: PostIdentificationFormData =
    identificationDetails && identificationDetails.identification.length
      ? identificationDetails
      : {
          identification: [newId, newId],
          IDPoints,
          ntdPurchased: null,
          ntdActive: null,
          ntdDate: null,
        };

  return (
    <div className={classes.root}>
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(postData) => {
          // eslint-disable-next-line no-param-reassign
          postData.IDPoints = calculatePoints(postData);

          if (ntdData) {
            postData = {
              ...postData,
              ntdPurchased: identificationDetails!.ntdPurchased,
              ntdDate: identificationDetails!.ntdDate,
            };
          }

          updateProfileState();

          postIdentificationFormDetails(
            postData,
            () => {
              setSelected(ProfileForm.DECLARATION_FORM);
            },
            index,
          );
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => {
          const linkStateSetter = (link: string, fileName: string, idx?: number) => {
            if (idx || idx === 0) {
              setFieldValue(`identification[${idx}].fileName`, fileName);
              setFieldValue(`identification[${idx}].identificationLink`, link);
            }
          };

          const linkStateRemover = (idx?: number) => {
            if (idx || idx === 0) {
              setFieldValue(`identification[${idx}].fileName`, null);
              setFieldValue(`identification[${idx}].identificationLink`, null);
            }
          };

          setIDPoints(calculatePoints(values));

          return (
            <div className={classes.formStyle}>
              {canShowNTDBanner(formData) && (
                <NTDBanner applicantProfile={formData} index={index} />
              )}
              {ntdData && !!ntdData.status && (
                <BackgroundCheckBanner applicantProfile={formData} index={index} />
              )}
              {ntdData &&
                ntdData.status === EquifaxStatus.COMPLETED &&
                ntdData.payment &&
                ntdData.payment.status === EquifaxPaymentStatus.APPROVED && (
                  <div className={classes.ntdContainer}>
                    <div className={classes.ntdHeader}>
                      <Text textVariant="title" size="xl" parentStyles={classes.ntdTitle}>
                        {LABELS.NTD_CHECK_SUMMARY}
                      </Text>
                      <div className={classes.ntdSwitchContainer}>
                        <FormControlLabel
                          value="start"
                          control={
                            <Switch
                              checked={!!values.ntdActive}
                              onChange={(_, checked) => {
                                setFieldValue('ntdActive', checked);
                                updateProfileState();
                              }}
                              value="active"
                              inputProps={{ 'aria-label': 'controlled' }}
                              sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                  color: theme.colors.secondary,
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                  backgroundColor: theme.colors.secondary,
                                },
                              }}
                            />
                          }
                          label={LABELS.INCLUDE_NTD}
                          labelPlacement="start"
                        />
                      </div>
                    </div>
                    <div className={classes.reportGrid}>
                      {ntdRecords(ntdData).map((d, idx) => (
                        <div className={classes.dataPoint} key={idx}>
                          <div className={classes.dataPointKey}>{d.label}</div>
                          <div className={classes.dataPointValue}>{d.value}</div>
                        </div>
                      ))}
                    </div>

                    {!!ntdData.pdfLink && (
                      <div className={classes.downloadPDFContainer}>
                        <div className={classes.transactionRow}>
                          <div className={classes.dataPoint}>
                            <div className={classes.dataPointKey}>Bank transaction ID: </div>
                            <div className={classes.dataPointValue}>
                              {ntdData.payment!.bankTransactionId}
                            </div>
                          </div>
                          <div className={classes.dataPoint}>
                            <div className={classes.dataPointKey}>Transaction date: </div>
                            <div className={classes.dataPointValue}>
                              {moment(ntdData.payment!.transactionDate).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                        <div
                          className={classes.downloadPDFButton}
                          onClick={() =>
                            DownloadHelper.downloadLink(ntdData.pdfLink!, 'Background Check Report')
                          }
                        >
                          {LABELS.DOWNLOAD_PDF}
                          <img
                            src={cloudDownload}
                            className={classes.cloudDownload}
                            alt="download"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {values.identification.map((value, idx) => (
                <div className={classes.identificationCardContainer} key={idx}>
                  <div className={classes.inputBoxStyle}>
                    <Text textVariant="boxTitle" parentStyles={classes.sectionTitle}>
                      {`${LABELS.IDENTIFICATION} ${idx + 1}`}
                    </Text>
                    {idx > 0 && (
                      <Button
                        outline
                        parentStyles={classes.removeButton}
                        onPress={() => {
                          values.identification.splice(idx, 1);
                          setFieldValue('identification', values.identification);
                          // setIDPoints(calculatePoints(values));
                          updateProfileState();
                        }}
                      >
                        {LABELS.REMOVE}
                      </Button>
                    )}
                  </div>

                  {/* TYPE ----------------------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.IDENTIFICATION_TYPE}
                    value={
                      values.identification[idx].type
                        ? IdentificationTypes[values.identification[idx].type!]
                        : ''
                    }
                    values={Object.keys(IdentificationTypes)
                      .filter((option) => {
                        for (const element of values.identification) {
                          if (
                            element.type &&
                            element.type === option &&
                            element.type !== values.identification[idx].type &&
                            IdentificationTypes[option] !== IdentificationTypes.OTHER
                          ) {
                            return false;
                          }
                        }
                        return true;
                      })
                      .map((v) => ({
                        display: `${IdentificationTypes[v]} (${ID_POINTS[v]} ${LABELS.POINTS})`,
                        value: v,
                      }))}
                    setValue={(value: string) => {
                      setFieldValue(`identification[${idx}].type`, value as IdentificationTypes);
                      // clear all set values if document-type is changed
                      setFieldValue(`identification[${idx}].stateOfIssueDL`, null);
                      setFieldValue(`identification[${idx}].licenseNumber`, null);
                      setFieldValue(`identification[${idx}].countryOfOrigin`, null);
                      setFieldValue(`identification[${idx}].passportNumber`, null);
                      setFieldValue(`identification[${idx}].cardNumber`, null);
                      setFieldValue(`identification[${idx}].referenceNumber`, null);
                      setFieldValue(`identification[${idx}].medicareColour`, null);
                      setFieldValue(`identification[${idx}].expiryDate`, null);
                      updateProfileState();
                    }}
                    onBlur={() => setFieldTouched(`identification[${idx}].type`)}
                    touched={get(touched, `identification[${idx}]type`)}
                    error={get(errors, `identification[${idx}]type`)}
                    title={LABELS.IDENTIFICATION_TYPE}
                    mandatory
                    showRequiredError={get(touched, `identification[${idx}]type`) || hasVisited}
                  />

                  {/* ATTACHMENT ----------------------------------------------------------------------- // */}
                  <FormControl
                    className={classes.inputContainerStyle}
                    error={
                      (get(errors, `identification[${idx}]identificationLink`) &&
                        get(touched, `identification[${idx}]identificationLink`)) ||
                      ((get(touched, `identification[${idx}]identificationLink`) || hasVisited) &&
                        isEmpty(values.identification[idx].identificationLink))
                    }
                  >
                    <Typography
                      variant="subtitle1"
                      className={`${classes.genericLabel} ${
                        (get(errors, `identification[${idx}]identificationLink`) &&
                          get(touched, `identification[${idx}]identificationLink`)) ||
                        ((get(touched, `identification[${idx}]identificationLink`) || hasVisited) &&
                          isEmpty(values.identification[idx].identificationLink))
                          ? classes.errorText
                          : ''
                      }`}
                    >
                      {LABELS.ATTACHMENTS}
                    </Typography>
                    <FileUpload
                      linkStateSetter={linkStateSetter}
                      linkStateRemover={linkStateRemover}
                      index={idx}
                      isMobile={isMobile}
                      fileNameFromParent={values.identification[idx].fileName || ''}
                      error={
                        (get(errors, `identification[${idx}]identificationLink`) &&
                          get(touched, `identification[${idx}]identificationLink`)) ||
                        ((get(touched, `identification[${idx}]identificationLink`) || hasVisited) &&
                          isEmpty(values.identification[idx].identificationLink))
                      }
                    />
                    {values.identification[idx].identificationLink === 'Error' ? (
                      <FormHelperText className={classes.errorText}>
                        {LABELS.FILE_SIZE_ERROR}
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                    <FormHelperText>
                      {get(errors, `identification[${idx}]identificationLink`) &&
                      get(touched, `identification[${idx}]identificationLink`) ? (
                        <div>{errors.identification![idx]!.identificationLink}</div>
                      ) : (
                        <>
                          {(get(touched, `identification[${idx}]identificationLink`) ||
                            hasVisited) &&
                            isEmpty(values.identification[idx].identificationLink) && (
                              <div>Required</div>
                            )}
                        </>
                      )}
                    </FormHelperText>
                  </FormControl>

                  {/* STATE OF ISSUE ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'DRIVING_LICENSE' && (
                    <InputSelect
                      placeholder={LABELS.STATE_OF_ISSUE}
                      value={
                        values.identification[idx].stateOfIssueDL
                          ? StateOfIssueDL.find(
                              (v) =>
                                v.code === values.identification[idx].stateOfIssueDL ||
                                v.value === values.identification[idx].stateOfIssueDL,
                            )!.value
                          : ''
                      }
                      values={StateOfIssueDL.map((v) => ({ display: v.value, value: v.code }))}
                      setValue={(value: string) => {
                        setFieldValue(`identification[${idx}].stateOfIssueDL`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`identification[${idx}].stateOfIssueDL`)}
                      touched={get(touched, `identification[${idx}]stateOfIssueDL`)}
                      error={get(errors, `identification[${idx}]stateOfIssueDL`)}
                      title={LABELS.STATE_OF_ISSUE}
                      mandatory
                      showRequiredError={
                        get(touched, `identification[${idx}]stateOfIssueDL`) || hasVisited
                      }
                    />
                  )}

                  {/* LICENSE NUMBER ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'DRIVING_LICENSE' && (
                    <Input
                      placeholder={LABELS.LICENSE_NUMBER}
                      value={values.identification[idx].licenseNumber || ''}
                      setValue={(value: string) => {
                        setFieldValue(`identification[${idx}].licenseNumber`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`identification[${idx}].licenseNumber`)}
                      touched={get(touched, `identification[${idx}]licenseNumber`)}
                      error={get(errors, `identification[${idx}]licenseNumber`)}
                      title={LABELS.LICENSE_NUMBER}
                      mandatory
                      showRequiredError={
                        get(touched, `identification[${idx}]licenseNumber`) || hasVisited
                      }
                    />
                  )}

                  {/* COUNTRY OF ORIGIN ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'PASSPORT' && (
                    <>
                      <InputLabel>
                        <Typography
                          variant="subtitle1"
                          className={`${classes.genericLabel} ${
                            (get(errors, `identification[${idx}]countryOfOrigin`) &&
                              get(touched, `identification[${idx}]countryOfOrigin`)) ||
                            ((get(touched, `identification[${idx}]countryOfOrigin`) ||
                              hasVisited) &&
                              isEmpty(values.identification[idx].countryOfOrigin))
                              ? classes.errorText
                              : ''
                          }`}
                        >
                          {LABELS.COUNTRY_OF_ORIGIN}
                        </Typography>
                      </InputLabel>
                      <FormControl
                        className={classes.inputContainerStyle}
                        error={
                          (get(errors, `identification[${idx}]countryOfOrigin`) &&
                            get(touched, `identification[${idx}]countryOfOrigin`)) ||
                          ((get(touched, `identification[${idx}]countryOfOrigin`) || hasVisited) &&
                            isEmpty(values.identification[idx].countryOfOrigin))
                        }
                      >
                        <CountryList
                          setFieldValue={setFieldValue}
                          path={`identification[${idx}].countryOfOrigin`}
                          setValues={values.identification[idx]}
                          updateProfileState={updateProfileState}
                          value={values.identification[idx].countryOfOrigin}
                        />
                        <FormHelperText>
                          {get(errors, `identification[${idx}]countryOfOrigin`) &&
                          get(touched, `identification[${idx}]countryOfOrigin`) ? (
                            <div>{errors.identification![idx]!.countryOfOrigin}</div>
                          ) : (
                            <>
                              {(get(touched, `identification[${idx}]countryOfOrigin`) ||
                                hasVisited) &&
                                isEmpty(values.identification[idx].countryOfOrigin) && (
                                  <div>Required</div>
                                )}
                            </>
                          )}
                        </FormHelperText>
                      </FormControl>
                    </>
                  )}

                  {/* PASSPORT NUMBER ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'PASSPORT' && (
                    <Input
                      placeholder={LABELS.PASSPORT_NUMBER}
                      value={values.identification[idx].passportNumber || ''}
                      setValue={(value: string) => {
                        setFieldValue(`identification[${idx}].passportNumber`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`identification[${idx}].passportNumber`)}
                      touched={get(touched, `identification[${idx}]passportNumber`)}
                      error={get(errors, `identification[${idx}]passportNumber`)}
                      title={LABELS.PASSPORT_NUMBER}
                      mandatory
                      showRequiredError={
                        get(touched, `identification[${idx}]passportNumber`) || hasVisited
                      }
                    />
                  )}

                  {/* MEDICARE NUMBER ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'MEDICARE' && (
                    <Input
                      placeholder={LABELS.CARD_NUMBER}
                      value={values.identification[idx].cardNumber || ''}
                      setValue={(value: string) => {
                        setFieldValue(`identification[${idx}].cardNumber`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`identification[${idx}].cardNumber`)}
                      touched={get(touched, `identification[${idx}]cardNumber`)}
                      error={get(errors, `identification[${idx}]cardNumber`)}
                      title={LABELS.CARD_NUMBER}
                      mandatory
                      showRequiredError={
                        get(touched, `identification[${idx}]cardNumber`) || hasVisited
                      }
                    />
                  )}

                  {/* REFERENCE NUMBER ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'MEDICARE' && (
                    <Input
                      placeholder={LABELS.REFERENCE_NUMBER}
                      value={values.identification[idx].referenceNumber || ''}
                      setValue={(value: string) => {
                        setFieldValue(`identification[${idx}].referenceNumber`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`identification[${idx}].referenceNumber`)}
                      touched={get(touched, `identification[${idx}]referenceNumber`)}
                      error={get(errors, `identification[${idx}]referenceNumber`)}
                      title={LABELS.REFERENCE_NUMBER}
                      mandatory
                      showRequiredError={
                        get(touched, `identification[${idx}]referenceNumber`) || hasVisited
                      }
                    />
                  )}

                  {/* MEDICARE COLOUR ----------------------------------------------------------------------- // */}
                  {values.identification[idx].type === 'MEDICARE' && (
                    <InputSelect
                      placeholder={LABELS.MEDICARE_COLOUR}
                      value={values.identification[idx].medicareColour || ''}
                      values={Object.values(MedicareColours).map((v) => ({ display: v, value: v }))}
                      setValue={(value: string) => {
                        setFieldValue(`identification[${idx}].medicareColour`, value);
                        updateProfileState();
                      }}
                      onBlur={() => setFieldTouched(`identification[${idx}].medicareColour`)}
                      touched={get(touched, `identification[${idx}]medicareColour`)}
                      error={get(errors, `identification[${idx}]medicareColour`)}
                      title={LABELS.MEDICARE_COLOUR}
                      mandatory
                      showRequiredError={
                        get(touched, `identification[${idx}]medicareColour`) || hasVisited
                      }
                    />
                  )}

                  {values.identification[idx].type === 'BIRTH_CERTIFICATE' ? null : (
                    <InputDate
                      placeholder={LABELS.EXPIRY}
                      title={LABELS.EXPIRY}
                      touched={get(touched, `identification[${idx}]expiryDate`)}
                      error={get(errors, `identification[${idx}]expiryDate`)}
                      value={values.identification[idx].expiryDate}
                      showRequiredError={
                        (get(touched, `identification[${idx}]expiryDate`) || hasVisited) &&
                        isEmpty(values.identification[idx].expiryDate)
                      }
                      setValue={(newValue: string | Date) => {
                        setFieldValue(`identification[${idx}].expiryDate`, newValue);
                        updateProfileState();
                      }}
                      mandatory
                      onBlur={() => setFieldTouched(`identification[${idx}].expiryDate`)}
                      minDate={new Date()}
                      yearMonthOnly={
                        values.identification[idx].type === 'MEDICARE' &&
                        get(values, `identification[${idx}].medicareColour`) ===
                          MedicareColours.GREEN
                      }
                    />
                  )}
                </div>
              ))}
              <Button
                parentStyles={classes.addIdentificationButton}
                onPress={() => {
                  const result = values.identification;
                  result.push({ ...newId });
                  setDocumentRequired(false);
                  setFieldValue('identification', result);
                }}
              >
                {LABELS.ADD_ANOTHER_ID}
              </Button>
              <Typography
                className={`
                  ${hasVisited && IDPoints < LABELS.MIN_ID_POINTS ? classes.errorText : ''} ${
                  classes.addIdentificationDescription
                }
                `}
              >
                {IDPoints > 0 && LABELS.ID_POINTS_PREFIX + IDPoints + LABELS.ID_POINTS}
                {IDPoints < LABELS.MIN_ID_POINTS && LABELS.ADD_ANOTHER_REFERENCE_DESCRIPTON}
              </Typography>

              {/* if user didn't added DRIVING_LICENSE/PASSPORT/MEDICARE, then show error */}
              {documentRequired && <MandatoryError errorText={LABELS.DOCUMENT_REQUIRED} />}

              <div className={classes.infoContainer}>
                <Text textVariant="info">{LABELS.INFO}</Text>
              </div>
              <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
                {LABELS.BUTTON_TEXT}
              </Button>
            </div>
          );
        }}
      />
      <NTDSidebar
        openSidebar={openSidebar}
        setOpenSidebar={(value: boolean) => setOpenSidebar(value)}
        applicantProfile={formData}
        index={index}
        retryCheck
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ntdChecks: state.equifaxState.ntdChecks,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  startEquifaxRequest: (ntdRequest: NTDRequest) =>
    dispatch(EquifaxActions.getNTDCheckRequest({ resultKey: null, ntdRequest })),

  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postIdentificationFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postIdentificationFormDetails(data, id))
      : dispatch(MyProfileAction.postIdentificationFormDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IdentificationForm);
