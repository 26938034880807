/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Popover } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import { History } from 'history';
import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddIcon from '@mui/icons-material/Add';
import { routes } from '../../Routes';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { PropertyDetailsResponse } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { ApplicationState } from '../../store/RootReducers';
import {
  ApplicantProfile,
  PostFormData,
  PostPersonalDetailsFormData,
} from '../../store/state/MyProfileFormState';
import additionalProfileIcon from '../../assets/person-add.svg';
import arrowRightIcon from '../../assets/arrow-right.svg';
import { LABELS } from './ManagedProfilesConstants';
import { useStyles } from './ManagedProfilesStyles';
import { MasterProfileActions } from '../../store/actions/MasterProfileActions';
import { AdditionalPersonalDetailsScreenActions } from '../../store/actions/AdditionalPersonalDetailsAction';
import localStorage from '../../services/LocalStorage';
import {
  myProfileStatus,
  secondaryProfileStatus,
  dependentProfileStatus,
} from './ManagedProfilesUtils';
import { stringToHslColor } from '../../helper/GravatarHelper';
import { LandingScreenState } from '../../store/state/LandingScreenState';
import Text from '../text/Text';
import Box from '../box/Box';
import Button from '../button/Button';
import PercentageWheel from '../percentageWheel/PercentageWheel';
import accordian from '../../assets/navigation/accordian.png';
import verified from '../../assets/verified.png';
import { isVerified } from '../../helper/NTDHelper';
import { Verified } from '../../models/equifax/equifax';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { GroupRequest } from '../../services/groups/Groups';
import { GroupsActions } from '../../store/actions/GroupsActions';

interface ManagedProfilesProps {
  history: History;
  tenantDetails: LandingScreenState;
  myProfileDetails: ApplicantProfile;
  secondaryApplicantProfiles: ApplicantProfile[];
  dependentProfiles: DependentProfile[];
  refId: string;
  propertyDetails: PropertyDetailsResponse;
  applicationState: ApplicationState;
  isProfileStateChanged: boolean;
  hidePrimary?: boolean;
  deleteSecondaryCardDetails: (id: number, key?: number) => void;
  deleteCardDetails: (id: number, key?: number) => void;
  postSaveMasterProfile: (data: ApplicationState) => void;
  updateProfileChangesFlag: (shouldReset: boolean) => void;
  addNewProfile: (data: PostPersonalDetailsFormData) => void;
  getGroupMasterProfile: (data: GroupRequest) => void;
}

const ManagedProfiles: FC<ManagedProfilesProps> = ({
  history,
  tenantDetails,
  myProfileDetails,
  secondaryApplicantProfiles,
  dependentProfiles,
  applicationState,
  isProfileStateChanged,
  hidePrimary,
  deleteSecondaryCardDetails,
  deleteCardDetails,
  postSaveMasterProfile,
  updateProfileChangesFlag,
  addNewProfile,
  getGroupMasterProfile,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (isProfileStateChanged) {
      postSaveMasterProfile(applicationState);
      updateProfileChangesFlag(false);
    }
  }, [isProfileStateChanged]);

  useEffect(() => {
    getGroupMasterProfile({});
  }, [secondaryApplicantProfiles.length, dependentProfiles.length]);

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const renderProfileCard = (
    onClick: () => void | null,
    profileName: string,
    profileContext: string,
    statusText: string,
    statusClass: string,
    percentage: number,
    actions?: any,
    verifiedBadge?: Verified,
  ) => {
    return (
      <Box lightBorder parentStyles={classes.applicationProfileCard}>
        <div className={classes.profileRow}>
          <div
            className={classes.gravatar}
            style={{ backgroundColor: stringToHslColor(`${profileName}`, 70, 70) }}
          >
            {profileName.split(' ')[0].charAt(0)}
            {profileName.split(' ')[1].charAt(0)}
          </div>
          <div className={classes.applicationProfileDetails}>
            <div className={classes.titleRow}>
              <Text textVariant="title" size="xl">
                {profileContext}
              </Text>
              {verifiedBadge !== undefined &&
                [Verified.VERIFIED, Verified.EXPIRED].includes(verifiedBadge) && (
                  <div className={classes.verifiedBadge}>
                    <img
                      src={verified}
                      className={classNameGenerator([
                        classes.verifiedIcon,
                        verifiedBadge === Verified.EXPIRED && classes.unverifiedBadge,
                      ])}
                      alt="badge"
                    />
                    <div className={classes.verifiedText}>
                      {verifiedBadge === Verified.EXPIRED ? LABELS.EXPIRED : LABELS.VERIFIED}
                    </div>
                  </div>
                )}
              <div
                className={`${classes.statusContainer} ${
                  statusClass === 'incompleteLabel'
                    ? classes.incompleteLabel
                    : classes.completeLabel
                }`}
              >
                <div className={classes.statusText}>{statusText}</div>
              </div>
            </div>
            <Text textVariant="info">{profileName}</Text>
          </div>
          <PercentageWheel completed={percentage} parentStyles={classes.percentageWheel} />
        </div>
        <div className={classes.lineRow}>
          {actions}
          <Button muted parentStyles={classes.buttonMarginRight} onPress={() => onClick()}>
            {LABELS.UPDATE}
          </Button>
        </div>
      </Box>
    );
  };

  // MY PROFILE ------------------------------------------------------------------- //
  const renderMyProfile = () => {
    const { statusText, statusClass, percentage } = myProfileStatus(myProfileDetails);
    const firstNameTemp = localStorage.getItem('firstname');
    const lastNameTemp = localStorage.getItem('lastname');
    const verifiedStatus = isVerified(myProfileDetails.identificationDetails);

    if (myProfileDetails.personalDetails) {
      const { firstName, middleName, lastName } = myProfileDetails.personalDetails;
      return (
        <>
          {renderProfileCard(
            () => redirectTo(routes.applicationMyProfile.new),
            `${firstName} ${middleName ? `${middleName} ` : ''}${lastName}`,
            LABELS.MY_PROFILE,
            statusText,
            statusClass,
            percentage,
            undefined,
            verifiedStatus,
          )}
        </>
      );
    } else if (firstNameTemp && lastNameTemp) {
      return (
        <>
          {renderProfileCard(
            () => redirectTo(routes.myProfile.new),
            `${firstNameTemp} ${lastNameTemp}`,
            LABELS.MY_PROFILE,
            statusText,
            statusClass,
            percentage,
            undefined,
            verifiedStatus,
          )}
        </>
      );
    } else {
      return null;
    }
  };

  // SECONDARY MANAGED PROFILES --------------------------------------------------- //
  const renderSecondaryApplicantProfiles = () => {
    return secondaryApplicantProfiles && secondaryApplicantProfiles.length
      ? secondaryApplicantProfiles.map((tenant, key) => {
          if (!(tenant.personalDetails && tenant.personalDetails.isManaged)) return null;

          const { statusText, statusClass, percentage } = secondaryProfileStatus(
            secondaryApplicantProfiles,
            tenant,
            key,
          );
          const verifiedStatus = isVerified(tenant.identificationDetails);

          if (
            tenant.personalDetails.firstName === null &&
            tenant.personalDetails.lastName === null
          ) {
            deleteSecondaryCardDetails(key, tenant.personalDetails.key);
            updateProfileChangesFlag(true);
          }

          return (
            <>
              {renderProfileCard(
                () => redirectTo(routes.additionalApplicant.new(tenant.personalDetails!.key!)),
                `${tenant.personalDetails.firstName} ${
                  tenant.personalDetails.middleName ? `${tenant.personalDetails.middleName} ` : ''
                }${tenant.personalDetails.lastName}`,
                LABELS.MANAGED_PROFILE,
                statusText,
                statusClass,
                percentage,
                <Button
                  outline
                  parentStyles={classes.buttonMarginRight}
                  onPress={() => {
                    deleteSecondaryCardDetails(key, tenant.personalDetails!.key);
                    updateProfileChangesFlag(true);
                  }}
                >
                  {LABELS.DELETE}
                </Button>,
                verifiedStatus,
              )}
            </>
          );
        })
      : null;
  };

  // DEPENDENT MANAGED PROFILES --------------------------------------------------- //
  const renderDependentProfiles = () => {
    return dependentProfiles && dependentProfiles.length
      ? dependentProfiles.map((tenant, key) => {
          const { statusText, statusClass, percentage } = dependentProfileStatus(tenant);

          if (tenant.firstName === null && tenant.lastName === null) {
            deleteCardDetails(key, tenant.key);
            updateProfileChangesFlag(true);
          }

          return (
            <>
              {renderProfileCard(
                () => redirectTo(routes.additionalApplicant.new(tenant.key!)),
                `${tenant.firstName} ${tenant.middleName ? `${tenant.middleName} ` : ''}${
                  tenant.lastName
                }${
                  tenant.selectedDateOfBirth
                    ? `, Born ${moment(tenant.selectedDateOfBirth).format('DD/MM/YYYY')}`
                    : ''
                }`,
                `${LABELS.MANAGED_DEPENDENT}`,
                statusText,
                statusClass,
                percentage,
                <Button
                  outline
                  parentStyles={classes.buttonMarginRight}
                  onPress={() => {
                    deleteCardDetails(key, tenant.key);
                    updateProfileChangesFlag(true);
                  }}
                >
                  {LABELS.DELETE}
                </Button>,
              )}
            </>
          );
        })
      : null;
  };

  return (
    <>
      {!hidePrimary && renderMyProfile()}
      {renderSecondaryApplicantProfiles()}
      {renderDependentProfiles()}
      {/* Once ready to enable */}
      {/* <div
        className={classes.applicationProfileCard}
        style={{ cursor: 'pointer' }}
        onMouseDown={() => {
          const key =
            tenantDetails &&
            tenantDetails.tenantDetails &&
            tenantDetails.tenantDetails.otherApplicants.length
              ? tenantDetails.tenantDetails.otherApplicants[
                  tenantDetails.tenantDetails.otherApplicants.length - 1
                ].key + 1
              : 0;
          const result = {
            key,
            email: null,
            emergencyContactEmail: null,
            emergencyContactMobile: null,
            emergencyContactName: null,
            emergencyContactRelationship: null,
            firstName: null,
            isChild: false,
            isManaged: true,
            lastName: null,
            mobile: null,
            selectedDate: null,
            selectedDateOfBirth: null,
            title: null,
          } as any;
          addNewProfile(result);
          history.push(routes.additionalApplicant.new(key));
        }}
      >
        <img
          src={additionalProfileIcon}
          className={classes.addProfileIcon}
          alt="additional profile pic"
        />
        <div className={classes.addProfileText}>{LABELS.ADD_PROFILE}</div>
        <AddIcon className={classes.plusIcon} nativeColor="#757575" />
      </div> */}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  tenantDetails: state.landingScreen,
  myProfileDetails: state.myProfileForm,
  secondaryApplicantProfiles: state.additionalPersonalDetailScreen.secondaryApplicantProfiles,
  dependentProfiles: state.additionalPersonalDetailScreen.dependentProfiles,
  refId: state.landingScreen.refId!,
  propertyDetails: state.dashboard.propertyDetails as PropertyDetailsResponse,
  applicationState: state,
  isProfileStateChanged: state.masterProfile.isProfileStateChanged,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getGroupMasterProfile: (data: GroupRequest) => {
    dispatch(GroupsActions.getGroupMasterProfileRequest(data));
  },
  deleteCardDetails: (id: number, key?: number) => {
    dispatch(AdditionalPersonalDetailsScreenActions.deleteAdditionalProfile({ id }));
  },
  deleteSecondaryCardDetails: (id: number, key?: number) => {
    dispatch(AdditionalPersonalDetailsScreenActions.deleteSeconadaryProfile({ id }));
  },
  postSaveMasterProfile: (data: ApplicationState) => {
    dispatch(MasterProfileActions.postSaveMasterProfile(data));
  },
  updateProfileChangesFlag: (shouldReset: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(shouldReset));
  },
  addNewProfile: (data: PostFormData) =>
    dispatch(AdditionalPersonalDetailsScreenActions.postMyProfileFormDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagedProfiles);
