/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { SIZES } from './constants/AppConstants';

export const useStyles = makeStyles({
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column-reverse',
      height: 'calc(100vh - 90px)',
    },
  },
  content: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 87px)',
    [`@media (max-width: ${SIZES.md}px)`]: {
      minHeight: 0,
      height: '100%',
      width: '100vw',
      overflow: 'scroll',
    },
  },
});
