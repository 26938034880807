/* eslint-disable default-case */
import React, { FC, useMemo, useState, useEffect } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import { AppIcon, AppIconSize, AppIconType } from '../../../../component/AppIcon/AppIcon';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import { theme } from '../../../../theme/Theme';
import { Messages } from '../../RemoteSigningConstants';
import { SigningSteps, StepType } from '../../RemoteSigningUtils';
import { RemoteSigningGetResponse } from '../../../../services/remoteSigning/RemoteSigning';
import AttachedDocuments from './components/AttachedDocuments';
import PreviewDocs from './components/PreviewDocs';
import SigningBox, { getUserTitle } from './components/SigningBox';
import { useStyles } from './RemoteSigningStyles';
import PropertyCard from './components/PropertyCard';
import PaymentPopup from '../../../../component/paymentPopup/PaymentPopup';
import { AGENCIES, APP } from '../../../../AppNameHelper';
import { BankAccountRequest, RentSplit } from '../../../../services/remoteSigning/payment/Payment';
import { sortPrimary } from './RemoteSigningUtils';
import PersonCard from './components/PersonCard';
import PreviewDocumentDialog from '../PreviewDocumentDialog/PreviewDocumentDialog';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import BackArrow from '../../../../component/backArrow/BackArrow';

export enum SignatureType {
  TenantSignature = 'TenantSignature',
  TenantInitials = 'TenantInitials',
  WitnessSignature = 'WitnessSignature',
}

interface RemoteSigningProps {
  token: string;
  remoteSigningData: RemoteSigningGetResponse;
  steps: SigningSteps[];
  handleSignatureSubmit: (
    signatureBase64: string,
    initialBase64: string,
    witnessSignatureBase64: string | null,
    witnessName: string,
    kolmeoFlow?: boolean,
    split?: RentSplit[],
    bankDetails?: BankAccountRequest,
    noPayment?: boolean,
  ) => void;
  handleBack: () => void;
  setActiveStep: (step: number) => void;
}

const RemoteSigning: FC<RemoteSigningProps> = ({
  remoteSigningData,
  steps,
  handleSignatureSubmit,
  handleBack,
  setActiveStep,
}) => {
  const classes = useStyles();
  const { titles } = Messages.pages.remoteSigningPage;
  const [step, setStep] = useState<number>(1);
  const [initialsBase64, setInitialsBase64] = useState<string | null | undefined>(null);
  const [signatureBase64, setSignatureBase64] = useState<string | null | undefined>(null);
  const [witnessSignatureBase64, setWitnessSignatureBase64] = useState<string | null | undefined>(
    null,
  );
  const [witnessName, setWitnessName] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [witnessNameRequired, setWitnessNameRequired] = useState<boolean>(false);
  const [notSignedSignature, setNotSignedSignature] = useState<boolean>(false);
  const [notSignedInitials, setNotSignedInitials] = useState<boolean>(false);
  const [notSignedWitness, setNotSignedWitness] = useState<boolean>(false);

  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const [isSharedSigningScreen, setIsSharedSigningScreen] = useState<boolean>(false);
  const [hasWitnessStep, setHasWitnessStep] = useState<boolean>(false);

  const propertyState = remoteSigningData.lease!.property!.state;

  const stepData = steps[step - 1];

  useEffect(() => {
    setIsSharedSigningScreen(
      [StepType.Signture, StepType.Initials, StepType.Witness].includes(stepData.stepType),
    );
    setHasWitnessStep(steps.some((s) => s.stepType === StepType.Witness));
  }, [stepData]);

  const showCheckbox = useMemo(() => {
    return stepData.showCheckbox!!;
  }, [stepData]);

  const checkBoxText = useMemo<string>(() => {
    return stepData.acceptText;
  }, [stepData]);

  const onBack = () => {
    if (step === 1) {
      handleBack();
    } else {
      setStep(step - 1);
      setNotSignedSignature(false);
      setNotSignedInitials(false);
      setNotSignedWitness(false);
      setWitnessNameRequired(false);
    }
  };

  const next = () => {
    // If at the end
    if (step === steps.length || isSharedSigningScreen) {
      if (
        remoteSigningData.lease!.agency!.code === 'NAX' &&
        remoteSigningData.lease!.paymentInfo.payment_provider === 'KOLMEO' &&
        remoteSigningData.lease!.leaseType !== 'Renew' &&
        remoteSigningData.user.userType !== 'Landlord'
      ) {
        setShowPaymentPopup(true);
      } else {
        handleSignatureSubmit(
          signatureBase64 as string,
          initialsBase64 as string,
          witnessSignatureBase64 as string | null,
          witnessName,
        );
      }
    } else {
      setStep(step + 1);
      setIsChecked(false);
    }
  };

  const validateSignature = (type: SignatureType) => {
    let signature;
    switch (type) {
      case SignatureType.TenantSignature:
        signature = signatureBase64;
        if (!signature) {
          setNotSignedSignature(true);
        }
        break;
      case SignatureType.TenantInitials:
        signature = initialsBase64;
        if (!signature) {
          setNotSignedInitials(true);
        }
        break;
    }
    return !!signature;
  };

  const validateWitnessForm = () => {
    let isWitnessNameRequired = false;
    let isNotSigned = false;
    if (!witnessSignatureBase64) {
      isNotSigned = true;
    }
    if (!witnessName) {
      isWitnessNameRequired = true;
    }
    setNotSignedWitness(isNotSigned);
    setWitnessNameRequired(isWitnessNameRequired);

    return !(isWitnessNameRequired || isNotSigned);
  };

  const onNextStep = (stepType: StepType) => {
    if (
      stepType === StepType.PreviewDocument ||
      stepType === StepType.DownloadDocument ||
      stepType === StepType.PreviewFile
    ) {
      next();
    } else if (isSharedSigningScreen) {
      const witnessValidated = hasWitnessStep ? validateWitnessForm() : true;
      if (
        validateSignature(SignatureType.TenantSignature) &&
        validateSignature(SignatureType.TenantInitials) &&
        witnessValidated
      ) {
        next();
      }
    }
  };

  const renderTitle = (data: SigningSteps) => {
    if (isSharedSigningScreen) {
      return 'Sign';
    } else {
      switch (data.stepType) {
        case StepType.PreviewDocument:
        case StepType.PreviewFile:
          return data.stepTitle;
        case StepType.DownloadDocument:
          return 'Documents';
      }
    }
    return '';
  };

  const renderContent = (data: SigningSteps) => {
    switch (data.stepType) {
      case StepType.PreviewDocument:
        setActiveStep(0);
        return (
          <div className={classes.termsConditionsWrapper}>
            <PreviewDocs stepData={data} remoteSigningData={remoteSigningData} />
          </div>
        );
      case StepType.DownloadDocument:
        setActiveStep(0);
        return <AttachedDocuments stepData={data} />;

      case StepType.PreviewFile:
        setActiveStep(0);
        return (
          <div className={classes.termsConditionsWrapper}>
            <embed
              src={`${data.documentLink!}#toolbar=0`}
              width="100%"
              height="100%"
              style={{ minHeight: 500 }}
            />
          </div>
        );
      case StepType.Signture:
      case StepType.Initials:
      case StepType.Witness: {
        setActiveStep(1);
        let type = SignatureType.TenantSignature;
        let title = 'Sign below';
        let notSigned = notSignedSignature;
        if (data.stepType === StepType.Initials) {
          type = SignatureType.TenantInitials;
          title = 'Initial below';
          notSigned = notSignedInitials;
        } else if (data.stepType === StepType.Witness) {
          type = SignatureType.WitnessSignature;
          title = 'Witness sign below';
          notSigned = notSignedWitness;
        }
        return (
          <SigningBox
            type={type}
            title={title}
            witnessName={witnessName}
            setWitnessName={(name) => setWitnessName(name)}
            witnessNameRequired={witnessNameRequired}
            setWitnessNameRequired={(value) => setWitnessNameRequired(value)}
            remoteSigningData={remoteSigningData}
            initialsBase64={initialsBase64}
            setInitialsBase64={(value) => setInitialsBase64(value)}
            signatureBase64={signatureBase64}
            setSignatureBase64={(value) => setSignatureBase64(value)}
            witnessSignatureBase64={witnessSignatureBase64}
            setWitnessSignatureBase64={(value) => setWitnessSignatureBase64(value)}
            notSigned={notSigned}
            setNotSigned={(value) => {
              switch (data.stepType) {
                case StepType.Signture: {
                  setNotSignedSignature(value);
                  break;
                }
                case StepType.Initials: {
                  setNotSignedInitials(value);
                  break;
                }
                case StepType.Witness: {
                  setNotSignedWitness(value);
                  break;
                }
              }
            }}
            propertyState={propertyState}
            hidePersonCard
            hideWitnessInput
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <BackArrow smallGap onBack={() => onBack()} />
        <div className={classes.signingTitlesContainer}>
          <Text parentStyles={classes.signingTitle} textVariant="title">
            {renderTitle(stepData)}
          </Text>
          <Text textVariant="info">
            {stepData.stepType === StepType.PreviewDocument ||
            stepData.stepType === StepType.DownloadDocument ||
            stepData.stepType === StepType.PreviewFile
              ? 'This is a legal contract, please ensure you read it carefully and ask for clarification if anything is unclear.'
              : 'By signing this agreement you agree to the terms outlined in the agreement. Everyone entering this agreement will have to sign individually'}
          </Text>
        </div>

        {isSharedSigningScreen && (
          <>
            <Typography variant="h1" className={classes.declarationText}>
              {titles.tenantHeader}
            </Typography>
            <PersonCard
              profileName={`${remoteSigningData.user.name} ${remoteSigningData.user.surname}`}
              profileContext={getUserTitle(remoteSigningData.user.userType, propertyState)}
            />

            {hasWitnessStep && (
              <>
                <div className={classes.declarationText} style={{ marginTop: 45 }}>
                  {titles.witnessHeader}
                </div>
                <FormControl className={classes.witnessFormStyle}>
                  <Input
                    fullWidth
                    // disableUnderline
                    value={witnessName}
                    type="text"
                    onChange={(event) => {
                      setWitnessName(event.target.value);
                      setWitnessNameRequired(false);
                    }}
                    placeholder="Witness name"
                    classes={{ input: classes.witnessInputStyle }}
                    endAdornment={
                      <InputAdornment position="end">
                        <AppIcon
                          color={theme.colors.label}
                          size={AppIconSize.Size20}
                          type={AppIconType.Profile}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {witnessNameRequired && (
                  <FormHelperText error className={classes.errorContainer}>
                    <img className={classes.errorIcon} src={errorIcon} alt="" />
                    You must provide a witness name
                  </FormHelperText>
                )}
              </>
            )}
          </>
        )}
        {stepData.stepType === StepType.PreviewDocument ||
        stepData.stepType === StepType.DownloadDocument ||
        stepData.stepType === StepType.PreviewFile ? (
          <>{renderContent(stepData)}</>
        ) : (
          <div className={`${classes.signingWrapper} ${hasWitnessStep && classes.witnessColumn}`}>
            {steps
              .filter(
                (step) =>
                  step.stepType !== StepType.PreviewDocument &&
                  step.stepType !== StepType.DownloadDocument &&
                  step.stepType !== StepType.PreviewFile,
              )
              .map((s) => (
                <>{renderContent(s)}</>
              ))}
          </div>
        )}
        {showCheckbox && (
          <div className={classes.checkboxAck}>
            <Text parentStyles={classes.signingTitle} textVariant="title">
              Acknowledgement
            </Text>
            <Text textVariant="info">I have read and understood the above</Text>
            <div className={classes.checkboxContainer}>
              <Checkbox
                style={{ color: theme.colors.secondary }}
                className={classes.checkboxStyle}
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <Typography className={classes.checkboxText} onClick={() => setIsChecked(!isChecked)}>
                {checkBoxText}
              </Typography>
            </div>
          </div>
        )}
        {stepData.documentLink && (
          <div className={classes.previewContainer}>
            <PreviewDocumentDialog link={stepData.documentLink} title={stepData.stepTitle} />
          </div>
        )}
        <div className={classes.buttonContainer}>
          {/* <div className={classes.secondaryButton} onClick={() => onBack()}>
            Back
          </div> */}
          <Button
            parentStyles={classes.primaryButton}
            disabled={!isChecked && showCheckbox}
            onPress={() => (!isChecked && showCheckbox ? null : onNextStep(stepData.stepType))}
          >
            Next
          </Button>
        </div>
      </div>
      <Dialog open={showPaymentPopup} maxWidth="sm" fullWidth>
        {showPaymentPopup && (
          <PaymentPopup
            rentalAmount={remoteSigningData.lease!.oneCycleRentalAmount}
            rentalFrequency={remoteSigningData.lease!.rentalFrequency}
            rentSplitPercentage={sortPrimary(remoteSigningData.lease!.rentSplitPercentage!)}
            propertyState={propertyState}
            remoteSigningData={remoteSigningData}
            closePopup={() => setShowPaymentPopup(false)}
            onSubmit={(split, bankDetails?: BankAccountRequest, noPayment?: boolean) => {
              handleSignatureSubmit(
                signatureBase64 as string,
                initialsBase64 as string,
                witnessSignatureBase64 as string | null,
                witnessName,
                true,
                split,
                bankDetails,
                noPayment,
              );
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

export default RemoteSigning;
