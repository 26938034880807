/* eslint-disable max-lines */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import RemoteSigningComponent from './components/RemoteSigning/RemoteSigning';
import { ApplicationState } from '../../store/RootReducers';
import { RemoteSigningState } from '../../store/state/RemoteSigningState';
import { RemoteSigningHelper, SigningSteps, getAllQueryParams } from './RemoteSigningUtils';
import {
  RemoteSigningActions,
  RemoteSigningActionTypes,
} from '../../store/actions/RemoteSigningActions';
import WelcomeScreen from './components/WelcomeScreen/WelcomeScreen';
import UploadingScreen from './components/UploadingScreen/UploadingScreen';
import {
  GuestAuthDetailsRequest,
  RemoteSigningGetResponse,
  RemoteSigningPostRequest,
  RemoteSigningPostResponse,
} from '../../services/remoteSigning/RemoteSigning';
import { ContentType, ContentTypeHelper } from '../../helper/Content/ContentType.data';
import { Content } from '../../helper/Content/Content.data';
import { Messages, RDSType, ScreenName } from './RemoteSigningConstants';
import CompletedSignatures from './components/CompletedSignatures/CompletedSignatures';
import { GetLeaseResponse } from '../../services/remoteSigning/lease/Lease';
import { OwnershipDetails } from '../../services/remoteSigning/ownership/Ownership';
import { useStyles } from './RemoteSigningStyles';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import ProgressBar from '../../component/progressBar/ProgressBar';
import { DashboardActions, StoreAgencyPayload } from '../../store/actions/DashboardActions';
import SortedFooter from '../../component/sortedFooter/SortedFooter';
import AllDone from './components/AllDone/AllDone';
import AvailableServices from './components/AvailableServices/AvailableServices';
import OwnershipDetailsScreen from './components/OwnershipDetailsScreen/OwnershipDetailsScreen';
import LandlordSigningScreen from './components/LandlordSigningScreen/LandlordSigningScreen';
import OwnershipDocuments from './components/OwnershipDocuments/OwnershipDocuments';
import { PaymentActions } from '../../store/actions/PaymentActions';
import {
  BankAccountRequest,
  KolmeoPaymentType,
  RentSplit,
} from '../../services/remoteSigning/payment/Payment';
import { UserType } from '../../services/remoteSigning/user/User';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { Assets } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

interface RemoteSigningProps extends RouteComponentProps<{ token: string }> {
  screenName: ScreenName;
  remoteSigningData: RemoteSigningState;
  error: string;
  loading: boolean;
  assets: Assets | null | undefined;
  getGuestToken: (data: GuestAuthDetailsRequest) => void;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
  getRemoteSigningData: (token: string, type?: string, source?: string) => void;
  postSignature: (
    type: string | undefined,
    request: RemoteSigningPostRequest,
    onSuccess: () => void,
    onError: () => void,
  ) => void;
  postKolmeoSignature: (
    token: string,
    request: RemoteSigningPostRequest,
    onAuthKeySuccess: (
      request: RemoteSigningPostRequest,
      resultKey: string,
      split: RentSplit[],
    ) => void,
    onSuccess: () => void,
    onError: () => void,
    split?: RentSplit[],
    bankDetails?: BankAccountRequest,
    noPayment?: boolean,
  ) => void;
}

const RemoteSigning: FC<RemoteSigningProps> = ({
  remoteSigningData: { signingData, usersSignatureList },
  loading,
  match,
  location,
  error,
  assets,
  getGuestToken,
  storePropertyAgency,
  getRemoteSigningData,
  postSignature,
  postKolmeoSignature,
}) => {
  const classes = useStyles();
  const [walletAmount, setWalletAmount] = useState<number>(0);
  const [screen, setScreen] = useState<ScreenName>(ScreenName.Welcome);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [contactMe, setContactMe] = useState<boolean>(false);
  const [rdsType, setRdsType] = useState<RDSType>(RDSType.LEASE);
  const ownershipDetails = useMemo<OwnershipDetails | undefined>(() => {
    if (signingData && signingData.ownershipDetails) {
      setRdsType(RDSType.LANDLORD);
      return signingData.ownershipDetails;
    } else {
      return undefined;
    }
  }, [signingData]);

  const lease = useMemo<GetLeaseResponse | undefined>(() => {
    if (signingData && signingData.lease) {
      setRdsType(RDSType.LEASE);
      return signingData.lease;
    } else {
      return undefined;
    }
  }, [signingData]);

  const witnessRequired = useMemo<boolean>(() => {
    if (signingData) {
      return signingData.witnessRequired || false;
    } else {
      return false;
    }
  }, [signingData]);

  const steps = useMemo<SigningSteps[]>(() => {
    return RemoteSigningHelper.setSingingsteps(lease, witnessRequired);
  }, [lease, witnessRequired]);

  const agencyName = useMemo<string>(() => {
    if (signingData) {
      return signingData.agency ? signingData.agency.tradingName : '';
    } else {
      return '';
    }
  }, [signingData]);

  const agencyCode = useMemo<string>(() => {
    if (signingData && signingData.agency) {
      storePropertyAgency({ agency: signingData.agency.code });
      return signingData.agency.code;
    } else {
      return '';
    }
  }, [signingData]);

  const {
    uploadingScreen,
    errorScreen,
    appointmentScreen,
    successScreen,
    alreadySignedScreen,
    allDone,
  } = Messages.pages;

  useEffect(() => {
    if (error) {
      if (error.includes('Remote signing token is already used')) {
        setScreen(ScreenName.AlreadySigned);
      } else if (error.includes('Expired remote signing token')) {
        setScreen(ScreenName.Expired);
      } else {
        setScreen(ScreenName.Error);
      }
    }
  }, [error]);

  useEffect(() => {
    const { token } = match.params;
    const { type, source } = getAllQueryParams(location);
    getRemoteSigningData(token, type, source);
  }, []);

  useEffect(() => {
    const { token } = match.params;
    const { type } = getAllQueryParams(location);
    if (
      !(type && type === 'landlord') &&
      signingData &&
      signingData.user &&
      signingData.user.userType === UserType.Primary
    ) {
      getGuestToken({ rdsToken: token });
    }
  }, [signingData]);

  const convertSignaturePostObject = (signatureBase64: string): Content => {
    const data = ContentTypeHelper.base64ToBlob(signatureBase64);
    return {
      contentRef: {
        contentType: ContentType.png,
        name: 'signature',
      },
      data,
      size: data.size,
      dataBase64: signatureBase64,
    };
  };

  const handlePostSignatureSuccess = () => {
    setScreen(ScreenName.Success);
    // Show success screen for 2 seconds
    setTimeout(() => {
      setScreen(ScreenName.Signatures);
    }, 2000);
  };

  const handlePostSignatureError = () => setScreen(ScreenName.Error);

  const skipSetup = () => {
    return (
      // agencyCode === 'NAX' ||
      agencyCode === 'AU_BUXT' ||
      rdsType === RDSType.LANDLORD ||
      signingData!.user.userType === 'Landlord' ||
      (signingData!.lease!.leaseType === 'Renew' &&
        signingData!.lease!.property!.state !== 'VIC') ||
      signingData!.user.userType === UserType.Secondary
    );
  };

  const handleAuthKeySuccess = (
    request: RemoteSigningPostRequest,
    resultKey: string,
    split: RentSplit[],
  ) => {
    const paymentDetails = {
      paymentMethod: 'BANK',
      resultKey,
    };
    const rentSplitAmount = split;
    const result = { ...request, paymentDetails, rentSplitAmount };
    setScreen(ScreenName.Uploading);
    postSignature(undefined, result, handlePostSignatureSuccess, handlePostSignatureError);
  };

  const handleSignatureSubmit = (
    signatureBase64: string,
    initialBase64: string,
    witnessSignatureBase64: string | null,
    witnessName: string,
    kolmeoFlow?: boolean,
    split?: RentSplit[],
    bankDetails?: BankAccountRequest,
    noPayment?: boolean,
  ) => {
    const { token } = match.params;
    const signatures: Content[] = [];
    signatures.push(convertSignaturePostObject(signatureBase64));
    signatures.push(convertSignaturePostObject(initialBase64));
    if (witnessSignatureBase64) {
      signatures.push(convertSignaturePostObject(witnessSignatureBase64));
    }
    const request: RemoteSigningPostRequest = {
      remoteSignRequest: {
        token,
        witnessName: witnessSignatureBase64 ? witnessName : null,
      },
      signatures,
    };
    if (kolmeoFlow) {
      postKolmeoSignature(
        token,
        request,
        handleAuthKeySuccess,
        handlePostSignatureSuccess,
        handlePostSignatureError,
        split,
        bankDetails,
        noPayment,
      );
    } else {
      setScreen(ScreenName.Uploading);
      postSignature(
        rdsType === RDSType.LANDLORD ? 'landlord' : undefined,
        request,
        handlePostSignatureSuccess,
        handlePostSignatureError,
      );
    }
  };

  const renderInContainer = (children: JSX.Element) => {
    return (
      <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <div className={classes.root}>
          <AgencyBackground />
          <div className={classes.content}>
            <div className={classes.contentContainer}>
              {screen !== ScreenName.Welcome && (
                <ProgressBar
                  activeStep={activeStep}
                  steps={['Review', 'Sign', skipSetup() ? 'Done' : 'Setup']}
                />
              )}
            </div>
          </div>

          {children}
        </div>
        <SortedFooter />
      </div>
    );
  };

  const renderScreens = () => {
    switch (screen) {
      case ScreenName.Welcome: {
        return renderInContainer(
          <WelcomeScreen
            ownershipDetails={ownershipDetails}
            rdsType={rdsType}
            handleNext={(screen: ScreenName) => setScreen(screen)}
            witnessRequired={witnessRequired}
            agencyName={agencyName}
            agencyCode={agencyCode}
            assets={assets}
            signingData={signingData}
            setActiveStep={(step: number) => setActiveStep(step)}
            loading={loading}
          />,
        );
      }
      case ScreenName.OwnershipInfo:
        return renderInContainer(
          <OwnershipDetailsScreen
            ownershipDetails={ownershipDetails!}
            user={signingData!.user}
            handleNext={() => setScreen(ScreenName.OwnershipDocs)}
            handleBack={() => setScreen(ScreenName.Welcome)}
            setActiveStep={(step: number) => setActiveStep(step)}
          />,
        );
      case ScreenName.OwnershipDocs:
        return renderInContainer(
          <OwnershipDocuments
            remoteSigningData={signingData as RemoteSigningGetResponse}
            handleNext={() => setScreen(ScreenName.LandlordSigning)}
            handleBack={() => setScreen(ScreenName.OwnershipInfo)}
            setActiveStep={(step: number) => setActiveStep(step)}
          />,
        );
      case ScreenName.LandlordSigning:
        return renderInContainer(
          <LandlordSigningScreen
            remoteSigningData={signingData as RemoteSigningGetResponse}
            handleBack={() => setScreen(ScreenName.OwnershipDocs)}
            handleSignatureSubmit={handleSignatureSubmit}
            setActiveStep={(step: number) => setActiveStep(step)}
          />,
        );
      case ScreenName.Signing: // contains different types of signing
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return renderInContainer(
          <RemoteSigningComponent
            token={match.params.token}
            steps={steps}
            remoteSigningData={signingData as RemoteSigningGetResponse}
            handleBack={() => setScreen(ScreenName.Welcome)}
            handleSignatureSubmit={handleSignatureSubmit}
            setActiveStep={(step: number) => setActiveStep(step)}
          />,
        );
      case ScreenName.Uploading: {
        // uploading signature
        return (
          <UploadingScreen
            icon={uploadingScreen.titles.icon}
            iconType={uploadingScreen.titles.iconType}
            title={uploadingScreen.titles.uploadTitle}
            primaryText={uploadingScreen.helperText.uploadText}
          />
        );
      }
      case ScreenName.Success: {
        // uploading signature success
        return (
          <UploadingScreen
            icon={successScreen.titles.icon}
            iconType={successScreen.titles.iconType}
            title={successScreen.titles.successTitle}
          />
        );
      }
      case ScreenName.Signatures: {
        // semi-final screen
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return renderInContainer(
          <CompletedSignatures
            postSignatureResponse={usersSignatureList as RemoteSigningPostResponse}
            remoteSigningData={signingData as RemoteSigningGetResponse}
            handleNext={() => setScreen(skipSetup() ? ScreenName.EndOfRDS : ScreenName.Promotion)}
            setActiveStep={(step: number) => setActiveStep(step)}
          />,
        );
      }
      case ScreenName.Promotion: {
        return renderInContainer(
          <AvailableServices
            remoteSigningData={signingData as RemoteSigningGetResponse}
            agencyCode={agencyCode}
            agencyName={agencyName}
            walletAmount={walletAmount}
            setWalletAmount={setWalletAmount}
            handleNext={() => setScreen(ScreenName.EndOfRDS)}
            setActiveStep={(step: number) => setActiveStep(step)}
            setContactMe={(value: boolean) => setContactMe(value)}
          />,
        );
      }
      case ScreenName.EndOfRDS: {
        if (skipSetup()) {
          return (
            <UploadingScreen
              icon={allDone.titles.icon}
              iconType={allDone.titles.iconType}
              title={allDone.titles.doneTitle}
              primaryText={allDone.helperText.thanksText(agencyName)}
              secondaryText={allDone.helperText.signText}
              ternaryText={
                rdsType === RDSType.LANDLORD || signingData!.user.userType === 'Landlord'
                  ? ''
                  : allDone.helperText.messageText(agencyCode)
              }
            />
          );
        } else {
          return <AllDone walletAmount={walletAmount} agencyCode={agencyCode} />;
        }
      }
      case ScreenName.Expired:
        return (
          <UploadingScreen
            icon={appointmentScreen.titles.icon}
            iconType={appointmentScreen.titles.iconType}
            title={appointmentScreen.titles.appointmentTitle}
            primaryText={appointmentScreen.helperText.contactText}
          />
        );
      case ScreenName.AlreadySigned:
        return (
          <UploadingScreen
            icon={alreadySignedScreen.titles.icon}
            iconType={alreadySignedScreen.titles.iconType}
            title={alreadySignedScreen.titles.signedTitle}
            primaryText={alreadySignedScreen.helperText.signText}
          />
        );
      case ScreenName.Error:
        return (
          <UploadingScreen
            icon={errorScreen.titles.icon}
            iconType={errorScreen.titles.iconType}
            title={errorScreen.titles.errorTitle}
            primaryText={errorScreen.helperText.errorText}
          />
        );
      default:
        return (
          <UploadingScreen
            icon={errorScreen.titles.icon}
            iconType={errorScreen.titles.iconType}
            title={errorScreen.titles.errorTitle}
            primaryText={errorScreen.helperText.errorText}
          />
        );
    }
  };

  return renderScreens();
};

const error = errorMessageSelector([
  RemoteSigningActionTypes.GET_REMOTE_SIGNING,
  RemoteSigningActionTypes.POST_SIGN,
]);
const loading = loadingSelector([
  RemoteSigningActionTypes.GET_REMOTE_SIGNING,
  RemoteSigningActionTypes.POST_SIGN,
]);

const mapStateToProps = (state: ApplicationState) => ({
  assets: state.dashboard.assets,
  remoteSigningData: state.remoteSigning,
  error: error(state),
  loading: loading(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  getGuestToken: (data: GuestAuthDetailsRequest) =>
    dispatch(RemoteSigningActions.getGuestTokenStart(data)),
  storePropertyAgency: (data: StoreAgencyPayload) => {
    dispatch(DashboardActions.storePropertyAgency({ agency: data.agency }));
  },
  getRemoteSigningData: (token: string, type?: string, source?: string) =>
    dispatch(RemoteSigningActions.getRemoteSigningStart({ token, type, source })),
  postSignature: (
    type: string | undefined,
    request: RemoteSigningPostRequest,
    onSuccess: () => void,
    onError: () => void,
  ) => dispatch(RemoteSigningActions.postSignStart({ type, request, onSuccess, onError })),
  postKolmeoSignature: (
    token: string,
    request: RemoteSigningPostRequest,
    onAuthKeySuccess: (
      request: RemoteSigningPostRequest,
      resultKey: string,
      split: RentSplit[],
    ) => void,
    onSuccess: () => void,
    onError: () => void,
    split?: RentSplit[],
    bankDetails?: BankAccountRequest,
    noPayment?: boolean,
  ) => {
    if (bankDetails) {
      dispatch(
        PaymentActions.getPaymentAuthKeyRequest({
          token,
          data: bankDetails,
          onSuccess: (resultKey: string) => {
            onAuthKeySuccess(request, resultKey, split!);
          },
          onError: (error: Error) => {
            dispatch(PaymentActions.getPaymentAuthKeyError(error));
            setTimeout(() => {
              dispatch(PaymentActions.resetPaymentError());
            }, 5000);
          },
        }),
      );
    } else {
      const rentSplitAmount = split;
      let result = { ...request, rentSplitAmount };
      if (!noPayment) {
        const paymentDetails = {
          paymentMethod: 'BPAY',
        };
        result = { ...result, paymentDetails };
      }
      dispatch(
        RemoteSigningActions.postSignStart({
          type: undefined,
          request: result,
          onSuccess,
          onError,
        }),
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSigning);
