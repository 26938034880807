import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import sortedLogo from '../../../assets/sorted_reference_logo.png';
import {
  referenceTool,
  learnMoreUrl,
  playStoreUrl,
  appStoreUrl,
  RENTING_THROUGH_AGENT,
  requestDemoUrl,
} from '../ReferenceToolConstants';
import { useStyles } from './ThankyouStyles';
import { ApplicationState } from '../../../store/RootReducers';
import { ReferenceToolDataState } from '../../../store/state/ReferenceToolState';
import playStore from '../../../assets/PlayStore.png';
import appStore from '../../../assets/AppStore.png';
import agentMock from '../../../assets/agentMock.png';
import appMock from '../../../assets/appMock.png';
import nax from '../../../assets/referenceToolLogos/nax.png';
import ipg from '../../../assets/referenceToolLogos/ipg.png';
import luton from '../../../assets/referenceToolLogos/luton.png';
import buxton from '../../../assets/referenceToolLogos/bux-black.png';
import agency from '../../../assets/referenceToolLogos/agency.png';
import harcourts from '../../../assets/referenceToolLogos/harcourts.png';
import hannahgill from '../../../assets/hannah-gill.png';
import { TypeHelper } from '../../../helper/TypeHelper';
import { LABELS } from './ThankyouScreenConstants';

interface ThankyouScreenProps {
  referencesData: ReferenceToolDataState | null;
}

const ThankyouScreen: FC<ThankyouScreenProps> = ({ referencesData }) => {
  const classes = useStyles();
  if (TypeHelper.isNullOrUndefined(referencesData)) {
    return <Typography className={classes.dataLoadingStyle} />;
  }
  const { applicantName, extendedData } = referencesData!;

  const isAgent =
    extendedData &&
    extendedData.currentLivingArrangement &&
    extendedData.currentLivingArrangement === RENTING_THROUGH_AGENT;

  return (
    <div className={classes.thankyouScreenRootStyle}>
      <div className={classes.sortedLogoStyle}>
        <img className={classes.imageStyle} src={sortedLogo} alt="" />
        <span className={classes.referenceToolStyle}>{referenceTool}</span>
      </div>

      <div
        className={classes.topSectionContainer}
        style={{ marginBottom: isAgent ? '0' : '-120px' }}
      >
        <div className={classes.textContainer}>
          <Typography className={classes.thankingRefereeStyle}>
            {`${LABELS.THANK_YOU} ${applicantName} ${LABELS.FIND_NEXT}`}
          </Typography>
          {isAgent ? (
            <>
              <Typography className={classes.subtitleText}>{LABELS.SORTED_IS_AUSTRALIA}</Typography>
              <Typography className={classes.bodyText}>{LABELS.BY_CONNECTING}</Typography>
              <ul className={classes.unorderedList}>
                <li className={classes.listItem}>{LABELS.DOT_ONE}</li>
                <li className={classes.listItem}>{LABELS.DOT_TWO}</li>
                <li className={classes.listItem}>{LABELS.DOT_THREE}</li>
              </ul>
              <div
                className={classes.learnMoreButton}
                onClick={() => window.open(requestDemoUrl, '_blank')}
              >
                {LABELS.LEARN_MORE}
              </div>
            </>
          ) : (
            <>
              <Typography className={classes.subtitleText}>{LABELS.SORTED_IS_HERE}</Typography>
              <Typography className={classes.bodyText}>{LABELS.WERE_AUSTRALIA}</Typography>
            </>
          )}
        </div>
        {isAgent ? (
          <div className={classes.imageContainerAgent}>
            <img className={classes.imageAgent} src={agentMock} alt="app mock" />
          </div>
        ) : (
          <div className={classes.imageContainer}>
            <img className={classes.image} src={appMock} alt="app mock" />
          </div>
        )}
      </div>
      <div className={classes.middleSection}>
        {isAgent ? (
          <>
            <Typography className={classes.changingTheGame}>{LABELS.CHANGING_THE_GAME}</Typography>
            <div className={classes.logosContainer}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={buxton} alt="logo" />
              </div>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={nax} alt="logo" />
              </div>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={ipg} alt="logo" />
              </div>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={luton} alt="logo" />
              </div>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={agency} alt="logo" />
              </div>
              <div className={classes.logoContainer}>
                <img className={classes.logo} src={harcourts} alt="logo" />
              </div>
            </div>
          </>
        ) : (
          <div className={classes.allLogoStyle}>
            <img
              onClick={() => window.open(playStoreUrl, '_blank')}
              className={classes.playStoreStyle}
              src={playStore}
              alt=""
            />
            <img
              onClick={() => window.open(appStoreUrl, '_blank')}
              className={classes.playStoreStyle}
              src={appStore}
              alt=""
            />
          </div>
        )}
      </div>
      {isAgent ? (
        <div className={classes.bottomSection}>
          <div className={classes.starsContainer}>
            <img className={classes.stars} src={hannahgill} alt="stars" />
          </div>
          <Typography className={classes.reviewQuote}>"{LABELS.REVIEW}"</Typography>
          <Typography className={classes.reviewName}>{LABELS.REVIEW_NAME}</Typography>
          <Typography className={classes.reviewTitle}>{LABELS.REIVEW_TITLE}</Typography>
          <Typography className={classes.reviewTitle}>{LABELS.REIVEW_TITLE2}</Typography>
        </div>
      ) : (
        <Typography className={classes.learnMore}>
          Click{' '}
          <span
            onClick={() => window.open(learnMoreUrl, '_blank')}
            className={classes.learnMoreUnderline}
          >
            here
          </span>{' '}
          to learn more about Sorted
        </Typography>
      )}
    </div>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  referencesData: state.referenceToolData.referencesData,
});

export default connect(mapStateToProps, null)(ThankyouScreen);
