import { AgencyLimited } from '../../models/agencies/Agencies';
import { StoreAction } from '../StoreHelper';

export enum SearchActionTypes {
  // TODO move all search properties actions hereby

  GET_AGENCIES = 'GET_AGENCIES',
  GET_AGENCIES_REQUEST = 'GET_AGENCIES_REQUEST',
  GET_AGENCIES_SUCCESS = 'GET_AGENCIES_SUCCESS',
  GET_AGENCIES_ERROR = 'GET_AGENCIES_ERROR',
}

export type SearchActionPayload = Error | null | AgencyLimited[];

export type SearchAction = StoreAction<SearchActionTypes, SearchActionPayload>;

export class SearchActions {
  public static getAgenciesRequest() {
    return { type: SearchActionTypes.GET_AGENCIES_REQUEST, data: null };
  }
  public static getAgenciesSuccess(data: AgencyLimited[]) {
    return { type: SearchActionTypes.GET_AGENCIES_SUCCESS, data };
  }
  public static getAgenciesError(data: Error) {
    return { type: SearchActionTypes.GET_AGENCIES_ERROR, data };
  }
}
// https://apply-service.uat.sortedservices.com/api/agencies
