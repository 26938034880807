/* eslint-disable no-case-declarations */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import Input from '../input/Input';
import InputArea from '../inputArea/InputArea';
import InputSelect from '../inputSelect/InputSelect';
import InputMobile from '../inputMobile/InputMobile';
import { Checkbox, FormControl, Grid } from '@mui/material';
import { GoogleAddressData } from '../../helper/GooglePlacesHelper';
import { FileData } from '../../store/state/FileUploadState';
import FileUploads from '../fileUpload/FileUploads';
import InputDate from '../inputDate/InputDate';
import InputGoogle from '../inputGoogle/InputGoogle';
import InputMultiSelect from '../inputMultiSelect/InputMultiSelect';
import useStyles from './GenericFormRendererStyles';
import Text from '../text/Text';
import {
  AllowedDates,
  DateRange,
  DependantDateRange,
} from '../../models/shortApplication/shortApplication';
import moment from 'moment';
import { DateFormats } from '../../constants/AppConstants';
import {
  minMonth,
  isDateAllowed,
} from '../../container/shortApplication/ShortApplicationConstants';
import { theme } from '../../theme/Theme';

export interface Question {
  field: string;
  // condition?: string;
  display: string;
  mandatory?: boolean;
  type: QuestionType;
  values?: string[];
  specialAllowedValues?: string[];
  infoText?: string | null;
  maxSelectionAllowed?: number;
  questions?: Question[];
  allowDualRoomTypes?: string[];
  showPastDates?: boolean;
  allowedDates?: AllowedDates;
  excludedWeekDays?: string[];
  dependentFields?: string[];
  // subQuestions?: Question[];
  fieldSize?: number;
  hideTenancy?: boolean;
}

type QuestionType =
  | 'dropDown'
  | 'text'
  | 'textArea'
  | 'mobile'
  | 'multi-select'
  | 'date'
  | 'googleAddress'
  | 'calculated'
  | 'fileUpload'
  | 'nestedQuestions'
  | 'hiddenDropDown'
  | 'HTMLdisclamier'
  | 'HTMLConfirmationCheckBox';

interface GenericFormRendererProps {
  questions: Question[];
  values: any;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}

const GenericFormRenderer: React.FC<GenericFormRendererProps> = ({
  questions,
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
}) => {
  const classes = useStyles();

  const renderQuestion = (
    question: Question,
    parentQuestion?: Question,
    index?: number,
  ): React.ReactNode | null => {
    const questionField = parentQuestion
      ? `${parentQuestion.field}[${index}].${question.field}`
      : question.field;

    const value = parentQuestion
      ? values[parentQuestion.field] && values[parentQuestion.field][index!]
        ? values[parentQuestion.field][index!][question.field]
        : ''
      : values[question.field];

    const touchedField = parentQuestion
      ? (touched[parentQuestion.field] &&
          touched[parentQuestion.field]?.[index!][question.field]) ||
        undefined
      : touched[question.field];

    const errorField = parentQuestion
      ? errors[parentQuestion.field] && errors[parentQuestion.field]?.[index!]
        ? errors[parentQuestion.field]?.[index!][question.field]
        : undefined
      : errors[question.field];

    const switchQuestion = () => {
      switch (question.type) {
        case 'text':
          return (
            <Input
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: string) => {
                setFieldValue(questionField, newValue);
              }}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        case 'textArea':
          return (
            <InputArea
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        case 'dropDown': {
          return (
            <InputSelect
              placeholder="Please select"
              key={`${questionField}-${question.display}`}
              value={value}
              values={question.values!.map((v) => ({ display: v, value: v }))}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
              parentInputStyles={classes.dropdown}
            />
          );
        }
        case 'mobile':
          return (
            <InputMobile
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: string) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        case 'googleAddress': {
          return (
            <InputGoogle
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value}
              setValue={(newValue: GoogleAddressData) => setFieldValue(questionField, newValue)}
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
            />
          );
        }
        case 'date':
          const { allowedDates } = question;
          const minOpenDate = allowedDates
            ? 'field' in allowedDates
              ? minMonth(allowedDates.values[values[allowedDates.field]] || [])
              : minMonth(allowedDates || [])
            : undefined;

          return (
            <InputDate
              placeholder={question.display}
              key={`${questionField}-${question.display}`}
              value={value ? moment(value, 'DD/MM/YYYY').toDate() : ''}
              setValue={(newValue: string) =>
                setFieldValue(questionField, moment(newValue, 'DD/MM/YYYY').format('DD/MM/YYYY'))
              }
              onBlur={() => setFieldTouched(questionField)}
              touched={touchedField}
              error={errorField}
              title={question.display}
              mandatory={question.mandatory}
              minDate={allowedDates ? undefined : question.showPastDates ? undefined : new Date()}
              maxDate={allowedDates ? undefined : question.showPastDates ? new Date() : undefined}
              disabled={
                (allowedDates as DependantDateRange)?.field &&
                !values[(allowedDates as DependantDateRange)?.field]
              }
              defaultCalendarMonth={minOpenDate && moment(minOpenDate, DateFormats.apiFormat)}
              shouldDisableDate={
                question.allowedDates
                  ? (date) => {
                      if (allowedDates && 'field' in allowedDates) {
                        return !isDateAllowed(
                          date,
                          allowedDates.values[values[allowedDates.field]],
                          question.excludedWeekDays,
                          question.showPastDates,
                        );
                      } else {
                        return !isDateAllowed(
                          date,
                          allowedDates as DateRange[],
                          question.excludedWeekDays,
                          question.showPastDates,
                        );
                      }
                    }
                  : undefined
              }
            />
          );
        case 'multi-select':
          return (
            <InputMultiSelect
              key={`${questionField}-${question.display}`}
              value={value}
              values={question.values!.map((v) => ({ display: v, value: v }))}
              setValue={(newValue: string[]) => setFieldValue(question.field, newValue)}
              title={question.display}
              mandatory={question.mandatory}
              maxSelection={question.maxSelectionAllowed}
            />
          );
        case 'fileUpload': {
          return (
            <FormControl className={classes.inputContainerStyle}>
              <div className={classes.fileInputTitleContainer}>
                <Text parentStyles={classes.fileInputTitle}>{question.display}</Text>
                <div className={classes.mandatory}>*</div>
              </div>
              <FileUploads
                multiple
                uploadFile={(data: File[]) => {
                  const oldData = value as File[];
                  const newData = oldData ? oldData.concat(data) : data;
                  setFieldValue(questionField, newData);
                }}
                uploadOnly
                value={value || []}
                id={questionField}
                onDelete={(file: FileData) => {
                  const oldData = value as File[];
                  const newData = oldData.filter((oldFile: File) => oldFile.name !== file.name);
                  setFieldValue(questionField, newData);
                }}
                error={!!errorField && !!touchedField}
                onSuccess={() => null}
              />
            </FormControl>
          );
        }
        case 'HTMLdisclamier':
          return (
            <div className={classes.htmlContainer}>
              <div
                key={`${questionField}-${question.display}`}
                className={classes.htmlText}
                dangerouslySetInnerHTML={{ __html: question.display }}
              />
            </div>
          );
        case 'HTMLConfirmationCheckBox':
          return (
            <div className={classes.htmlContainer}>
              <div className={classes.checkboxRow}>
                <Checkbox
                  style={{ color: theme.colors.secondary }}
                  className={classes.checkBoxStyle}
                  checked={!!values[question.field]}
                  onChange={(_, checked) => setFieldValue(question.field, checked)}
                />
                <div
                  className={classes.htmlText}
                  dangerouslySetInnerHTML={{ __html: question.display }}
                />
              </div>
              {!!errorField && !!touchedField && (
                <div className={classes.errorText}>{errorField}</div>
              )}
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <>
        {switchQuestion()}
        {!!question.infoText && <div className={classes.questionInfoText}>{question.infoText}</div>}
      </>
    );
  };

  return (
    <Grid container columnSpacing={2}>
      {questions.map((question, index) => (
        <Grid xs={12} md={question.fieldSize || 12} item key={index}>
          {renderQuestion(question)}
        </Grid>
      ))}
    </Grid>
  );
};

export default GenericFormRenderer;
