import { takeEvery, put } from 'redux-saga/effects';
import { LoadingIndicatorActions } from '../actions/LoadingIndicatorAction';
import { LoginAction, LoginActions, LoginActionTypes } from '../actions/LoginActions';
import {
  LoginResponse,
  LoginPayload,
  RegisterUserPayload,
  RegisterUserResponse,
  RegisterClientResponse,
  VerificationLinkPayload,
  RefreshTokenPayload,
  RefreshTokenResponse,
  VerifyUserPayload,
  FindUserPayload,
  FindUserResponse,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  GuestTokenResponse,
} from '../../services/login/LoginData';
import LoginService from '../../services/login/LoginService';
import ApplyService from '../../services/dashboard/ApplyService';
import { MasterProfileActions } from '../actions/MasterProfileActions';
import {
  SubmittedApplicationResponse,
  MasterProfilePayload,
  Applicants,
  ApplicantResponsePayload,
  MasterProfileResponsePayload,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import LogRocket from 'logrocket';
import { GroupsActions } from '../actions/GroupsActions';
import { routes } from '../../Routes';
import { SuccessActions } from '../actions/SuccessActions';
import { SuccessToastActions } from '../actions/ SuccessToastAction';

export function* refreshMasterProfileWorker() {
  try {
    yield put(GroupsActions.getGroupMasterProfileRequest({}));
    const masterProfileResponse: MasterProfileResponsePayload =
      yield ApplyService.getMasterProfileData();
    const resData: MasterProfilePayload = {
      applicants: [masterProfileResponse.primary.profileData],
    };
    let key = 0;
    if (masterProfileResponse.secondary.length > 0) {
      masterProfileResponse.secondary.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    if (masterProfileResponse.dependents.length > 0) {
      masterProfileResponse.dependents.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    yield put(MasterProfileActions.setMasterProfile(resData));
  } catch (error) {
    SuccessToastActions.showError("Couldn't load your profile, try again");
    yield put(MasterProfileActions.getMasterProfileFailure(error as Error));
  }
}

export function* postLoginWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as LoginPayload;
    const response: LoginResponse = yield LoginService.postLogin(data);
    yield put(LoginActions.postLoginSuccess(response));
    LogRocket.identify(data.username, {
      email: data.username,
    });
  } catch (error: any) {
    yield put(LoginActions.postLoginError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRegisterUserWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as RegisterUserPayload;
    const response: RegisterUserResponse = yield LoginService.postRegisterUser(data);
    yield put(LoginActions.postRegisterUserSuccess(response));
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(LoginActions.postRegisterUserError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRegisterClientWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const response: RegisterClientResponse = yield LoginService.postRegisterClient();
    yield put(LoginActions.postRegisterClientSuccess(response));
  } catch (error) {
    yield put(LoginActions.postRegisterClientError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postVerificationLinkWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as VerificationLinkPayload;
    yield LoginService.postVerificationLink(data);
    yield put(LoginActions.postVerificationLinkSuccess());
  } catch (error) {
    yield put(LoginActions.postRegisterClientError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postRefreshTokenWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as RefreshTokenPayload;
    const response: RefreshTokenResponse = yield LoginService.postRefreshToken(data);
    yield put(LoginActions.postRefreshTokenSuccess(response));
  } catch (error) {
    yield put(LoginActions.postRegisterClientError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postVerifyUserWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as VerifyUserPayload;
    yield LoginService.postVerifyUser(data);
    yield put(LoginActions.postVerifyUserSuccess());
  } catch (error) {
    yield put(LoginActions.postVerifyUserError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getUserDetailsWorker(action: LoginAction) {
  try {
    const data = action.data as FindUserPayload;
    const response: FindUserResponse = yield LoginService.getUserDetails(data);
    yield put(LoginActions.getUserDetailsSuccess(response));
  } catch (error) {
    yield put(LoginActions.getUserDetailError(error as Error));
  }
}

export function* postForgotPasswordLinkWorker(action: LoginAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as ForgotPasswordPayload;
    yield LoginService.postForgotPasswordLink(data);
    yield put(LoginActions.postForgotPasswordSuccess());
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(LoginActions.postForgotPasswordError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* postResetPasswordWorker(action: LoginAction) {
  try {
    const data = action.data as ResetPasswordPayload;
    yield LoginService.postResetPassword(data);
    yield put(LoginActions.resetPasswordSuccess());
    if (data.onSuccess) {
      data.onSuccess();
    }
  } catch (error) {
    yield put(LoginActions.resetPasswordError(error as Error));
  }
}

export function* getGusetTokenWorker(action: LoginAction) {
  try {
    const response: GuestTokenResponse = yield LoginService.getGuestToken();
    yield put(LoginActions.getGuestTokenSuccess(response));
  } catch (error) {
    yield put(LoginActions.getGuestTokenFailure(error as Error));
  }
}

function* loginWatcher() {
  yield takeEvery(LoginActionTypes.LOGIN_REQUEST, (action: LoginAction) =>
    postLoginWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.REFRESH_TOKEN_REQUEST, (action: LoginAction) =>
    postRefreshTokenWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.REGISTER_USER_REQUEST, (action: LoginAction) =>
    postRegisterUserWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.REGISTER_CLIENT_REQUEST, (action: LoginAction) =>
    postRegisterClientWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.VERIFY_USER_REQUEST, (action: LoginAction) =>
    postVerifyUserWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.VERIFICATION_LINK_REQUEST, (action: LoginAction) =>
    postVerificationLinkWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.FIND_USER_REQUEST, (action: LoginAction) =>
    getUserDetailsWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.FORGOT_PASSWORD_LINK_REQUEST, (action: LoginAction) =>
    postForgotPasswordLinkWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.RESET_PASSWORD_REQUEST, (action: LoginAction) =>
    postResetPasswordWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.GUEST_TOKEN_REQUEST, (action: LoginAction) =>
    getGusetTokenWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(LoginActionTypes.REFRESH_MASTER_PROFILE, () => refreshMasterProfileWorker());
}

export default loginWatcher;
