import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './CreateGroupStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './CreateGroupConstants';
import { routes } from '../../../../Routes';
import { GroupsActions } from '../../../../store/actions/GroupsActions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../store/RootReducers';

interface CreateGroupProps extends RouteComponentProps {
  setGroupRefId: (groupRefId: string | null) => void;
}

const CreateGroup: FC<CreateGroupProps> = ({ history, setGroupRefId }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text textVariant="contentTitle">{LABELS.TITLE}</Text>
      <Text textVariant="info">{LABELS.SUBTITLE}</Text>
      <Button
        parentStyles={classes.button}
        onPress={() => {
          setGroupRefId(null);
          history.push(routes.groups.application);
        }}
      >
        {LABELS.BUTTON}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setGroupRefId: (groupRefId: string | null) => {
    dispatch(GroupsActions.setSelectedGroupRef(groupRefId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateGroup));
