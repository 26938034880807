import { RentalFrequency } from '../../services/remoteSigning/lease/Lease';
import { RentSplit } from '../../services/remoteSigning/payment/Payment';

export const convertToPercent = (value: number | null, maxValue: number) => {
  return value !== null ? (value / maxValue) * 100 : 0;
};

export const convertToAmount = (value: number | null, maxValue: number) => {
  return value !== null ? (value / 100) * maxValue : 0;
};

export const getFrequency = (frequency: string) => {
  switch (frequency) {
    case RentalFrequency.Weekly:
      return 'week';
    case RentalFrequency.Fortnightly:
      return 'fortnight';
    case RentalFrequency.Monthly:
      return 'month';
    default:
      return 'week';
  }
};

export const validateSplitValue = (
  split: RentSplit[],
  rentalAmount: number,
  index: number,
  value: string,
) => {
  let remaining = rentalAmount;
  split.map((s, idx) => {
    if (index !== idx) {
      remaining -= s.rentSplitAmount !== null ? s.rentSplitAmount : 0;
    }
  });
  return split.map((s, idx) => {
    if (index === idx) {
      let amount = Number(value);

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(amount)) {
        amount = amount > remaining ? remaining : amount;
        s.rentSplitAmount = Math.round((amount + Number.EPSILON) * 100) / 100;
      }
    }
    return s;
  });
};

export const getInitalSplit = (
  split: RentSplit[],
  rentalAmount: number | null | undefined,
): RentSplit[] => {
  if (split.length === 1) {
    return [{ ...split[0], rentSplitAmount: rentalAmount! }];
  } else {
    return split;
  }
};
