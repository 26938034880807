/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Checkbox, FormHelperText, FormControlLabel } from '@mui/material';
import { theme } from '../../theme/Theme';

/// <summary>
/// Value is string
/// </summary>
const RenderCheckbox = (data: any) => {
  const {
    field,
    icon,
    color,
    label,
    style,
    checkedIcon,
    disabled,
    labelPlacement,
    itemRenderStyle,
  } = data;

  const hasError = !!field.error && !!field.touched;
  // const hasSecondaryError = !!field.secondaryValidationMessage;
  return (
    <div className="item-render" style={itemRenderStyle}>
      {/*
          //@ts-ignore */}
      <FormControlLabel
        labelPlacement={labelPlacement || 'end'}
        control={
          <Checkbox
            onChange={(e) => {
              if (field.onChange) {
                field.onChange(e);
              }
            }}
            checked={!!field.value} // string
            id={field.name}
            disabled={disabled}
            icon={icon}
            checkedIcon={checkedIcon}
            color={color}
            style={style}
            sx={{
              color: theme.colors.secondary,
              '&.Mui-checked': {
                color: theme.colors.secondary,
              },
            }}
          />
        }
        label={label}
      >
        {hasError && <FormHelperText id={`${field.name}-error-text`}>{field.error}</FormHelperText>}
        {/* {hasSecondaryError && <FormHelperText id={`${field.name}-error2-text`} error={false} required={true}>{field.secondaryValidationMessage}</FormHelperText>} */}
        {/* {!!field.helperText && <FormHelperText id={`${field.name}-helper-text`}>{field.helperText}</FormHelperText>} */}
      </FormControlLabel>
    </div>
  );
};

export default RenderCheckbox;
