import ThankyouImage2 from '../../assets/articles/thankyou-image5.png';
import ThankyouImage7 from '../../assets/articles/thankyou-image7.png';

export const LABELS = {
  TITLE: (name: string) => `Hi ${name}, Here's what you need to know`,
  INSIGHTS: 'Insights',
  BLOG_TITLE: "What's a managed profile?",
  BLOG_BODY:
    'Everything you need to know about managed profiles. This is a sentence that needs to be filled in.',
  FROM_THE_BLOG: 'From the blog',
  YOUR_PROFILE: 'Your profile',
  GETTING_STARTED: 'Getting started',
};

export enum DashboardItemType {
  WELCOME,
  WHILE_YOU_WAIT,
  WHATS_NEXT,
  SIGN_NOW,
  NO_APPLICATIONS,
  DRAFT_ACTIVE,
  PROFILE,
  PROFILE_SECTION_PERSONAL_DETAILS,
  PROFILE_SECTION_INTRO,
  PROFILE_SECTION_CURRENT_INCOME,
  PROFILE_SECTION_EMPLOYMENT,
  PROFILE_SECTION_PETS,
  PROFILE_SECTION_VEHICLES,
  PROFILE_SECTION_ADDRESS,
  PROFILE_SECTION_REFERENCE,
  PROFILE_SECTION_IDENTIFICATION,
  PROFILE_SECTION_DECLARATION,
  AGENCIES,
  BRANDS,
  INVITATION,
  INFO_REQUESTED,
  SYNC_PROFILE,
  DOWNLOAD_SORTED,
  FIND_A_PROPERTY,
  SORTED_APPLICATIONS,
}

export const BLOGS_BASIC = [
  {
    title: 'Top 5 tips from property managers',
    body: 'Inside advice from Property Managers to consider for your next application ...',
    link: 'https://www.sortedservices.com/blog-spot/inside-advice-from-property-managers',
    image: ThankyouImage2,
  },
  {
    title: 'Protecting your data',
    body: 'Your data is important to us, and is securely encrypted on our platform.',
  },
  {
    title: 'Can I delete my data?',
    body: 'Yes, your data can be deleted, simply start a chat with our friendly team and they will organise it for you.',
  },
];

export const BLOGS_COMPLETE = [
  {
    title: 'How to make your next move, a stress free one with Sorted',
    body: 'Moving into a new place is exciting, however also quite stressful. So to help take the stress out, we’ve put this action plan list together so it’s one less thing you need to do ...',
    link: 'http://www.sortedservices.com/blog-spot/how-to-make-your-next-move-a-stress-free-one-with-sorted',
    image: ThankyouImage7,
  },
];
