/* eslint-disable import/prefer-default-export */

import { routes } from '../../Routes';

export const LABELS = {
  LOGIN: 'Login',
  YOU: 'You',
};

export const POWERED_BY_ROUTES = [routes.residentRequest.view];
