import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useStyles } from './FooterStyles';

interface FooterComponentProps {
  redirectLink?: string;
  buttonText: string;
  history?: any;
  onClick?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button';
}

const FooterComponent: FC<FooterComponentProps> = ({
  history,
  redirectLink,
  buttonText,
  onClick,
  disabled,
  fullWidth,
  type,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    history.push(redirectLink);
  };
  const classDisabled = disabled ? classes.disabledButtonText : classes.buttonText;
  return (
    <Button
      className={fullWidth ? classes.buttonFullWidth : classes.button}
      onClick={onClick || handleClick}
      disabled={disabled}
      color={disabled ? 'secondary' : 'inherit'}
      variant="contained"
      type={type || 'button'}
    >
      <Typography className={classDisabled}>{buttonText}</Typography>
    </Button>
  );
};

export default FooterComponent;
