/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
import { ApplicationState } from '../store/RootReducers';
import { get, uniqBy } from 'lodash';
import {
  PostAddressFormData,
  Address,
  PostIdentificationFormData,
  PostEmploymentFormData,
  PostCurrentIncomeFormData,
  PostReferencesFormData,
  PostPetsFormData,
  PetTypes,
  ApplicantProfile,
  Pets,
  Vehicles,
  PostPersonalDetailsFormData,
  SourceOfIncome,
  PostVehiclesFormData,
} from '../store/state/MyProfileFormState';
import {
  ApplyFormPayload,
  ApplyForm,
  ApplyPropertyForm,
  AddressHistory,
  Applicants,
  Identification,
  EmploymentHistory,
  Income,
  Reference,
  Pet,
  Vehicle,
  PropertyData,
  ApplyPropertyFormPayload,
  ReferencesPayload,
  SubmittedApplicationResponse,
} from '../services/dashboard/postApplyForm/PostApplyForm.data';
import {
  isIdentificationDetailsFilled,
  isPersonalDetailsFilled,
  isDeclarationDetailsFilled,
  isCurrentIncomeDetailsFilled,
  isAddressDetailsFilled,
} from './ProfileVerifyHelper';
import * as ERROR_MESSAGES from './ErrorMessages';
import { TypeHelper } from './TypeHelper';
import { PropertyDetailsResponse } from '../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { store } from '../store/Store';

import { employmentFormData, addressFormData, refrenceFormData } from './PrefillFormHelper';
import { AustraliaState } from '../services/dashboard/australiaStates/australiaStates';
import { PropertyUpdateDetails } from '../services/groups/Groups';
import { SectionStatus } from '../container/profile/components/ProfileItem/ProfileItemConstants';

/* tslint:disable:cyclomatic-complexity */
export const checkIfObjectIsEmpty = (data: Record<string, any>): boolean => {
  if (Object.values(data).every((value) => !value)) {
    return true;
  }
  return false;
};

const getApplicantAddressHistory = (data: PostAddressFormData): AddressHistory[] | null => {
  if (!data || data.addresses.length === 0) {
    return [];
  }
  const applicantAddressHistory: AddressHistory[] = data.addresses
    .filter((address) => !checkIfObjectIsEmpty(address))
    // .filter((address) => {
    //   if (
    //     address.livingArrangement === 'Renting through an agent' ||
    //     address.livingArrangement === 'Renting through a private landlord'
    //   ) {
    //     return (
    //       address.propertyManagerName &&
    //       address.email &&
    //       address.livingArrangement &&
    //       address.address
    //     );
    //   }
    //   return address.livingArrangement && address.address;
    // })
    .map((curAddress: Address) => {
      const {
        address,
        addressComponents,
        livingArrangement,
        durationOfStayYear,
        durationOfStayMonth,
        propertyManagerName,
        contactNumber,
        reasonForLeaving,
        postalAddress,
        agencyName,
        isCurrent,
        email,
        applyReferenceId,
        additionalAddressDocuments,
        rank,
      } = curAddress;
      return {
        address,
        addressComponents,
        currentLivingArrangement: livingArrangement,
        years: durationOfStayYear!,
        months: durationOfStayMonth!,
        propertyManagerName,
        mobile: contactNumber ? contactNumber.toString() : null,
        monthlyRent: curAddress.monthlyRent ? parseInt(curAddress.monthlyRent, 10) : null,
        reasonForLeaving,
        postalAddress,
        landlord: null,
        agencyName,
        current: isCurrent,
        propertyManagerEmail: email,
        applyReferenceId,
        additionalAddressDocuments,
        rank: TypeHelper.isNullOrUndefined(rank) ? 0 : rank,
      };
    });

  return applicantAddressHistory;
};

const getapplicantIdentification = (
  data: PostIdentificationFormData | undefined,
): Identification[] => {
  // if (
  //   !data ||
  //   data.identification.length === 0 ||
  //   isIdentificationDetailsFilled(data) === SectionStatus.INCOMPLETE
  // ) {
  //   return [];
  // }
  if (!data) return [];
  const identificationDetails = data.identification
    .filter((id) => !checkIfObjectIsEmpty(id))
    // .filter((value) => {
    //   const { identificationLink, type } = value;
    //   if (type && identificationLink) {
    //     return true;
    //   }
    //   return false;
    // })
    .map((identity) => {
      const {
        identificationLink,
        type,
        stateOfIssueDL,
        licenseNumber,
        countryOfOrigin,
        passportNumber,
        cardNumber,
        referenceNumber,
        medicareColour,
        expiryDate,
      } = identity;
      let identification;
      if (type === 'DRIVING_LICENSE') {
        identification = {
          type: type ? type.toString() : null,
          identificationLink,
          idNumber: licenseNumber,
          extendedData: {
            stateOfIssueDL,
            expiryDate: TypeHelper.formatDate(expiryDate),
          },
        };
      } else if (type === 'PASSPORT') {
        return {
          type: type ? type.toString() : null,
          identificationLink,
          idNumber: passportNumber,
          extendedData: {
            countryOfOrigin,
            expiryDate: TypeHelper.formatDate(expiryDate),
          },
        };
      } else if (type === 'MEDICARE') {
        return {
          type: type ? type.toString() : null,
          identificationLink,
          idNumber: referenceNumber,
          extendedData: {
            cardNumber,
            medicareColour,
            expiryDate: TypeHelper.formatDate(expiryDate),
          },
        };
      } else {
        identification = {
          type: type ? type.toString() : null,
          identificationLink,
          extendedData: {
            expiryDate: TypeHelper.formatDate(expiryDate),
          },
        };
      }
      return identification;
    });

  return identificationDetails && identificationDetails.length > 0 ? identificationDetails : [];
};

const getApplicantEmploymentHistory = (data: PostEmploymentFormData): EmploymentHistory[] => {
  if (!data || data.employments.length === 0) {
    return [];
  }
  if (data && !data.hasBeenEmployed) {
    return [];
  }
  return data.employments
    .filter((employment) => !checkIfObjectIsEmpty(employment))
    .map((employment) => {
      const {
        companyName,
        copyOfEmploymentLetter,
        grossAnnualSalary,
        inJob,
        jobEndMonthAndYear,
        jobStartMonthAndYear,
        managerContactNumber,
        managerName,
        position,
        email,
        applyReferenceId,
        address,
        addressComponents,
        rank,
      } = employment;
      return {
        startDate: jobStartMonthAndYear ? TypeHelper.formatDate(jobStartMonthAndYear) : null,
        endDate: jobEndMonthAndYear ? TypeHelper.formatDate(jobEndMonthAndYear) : null,
        stillInJob: inJob,
        companyName,
        position,
        annualSalary: grossAnnualSalary ? parseInt(grossAnnualSalary, 10) : null,
        managerName,
        managerPhone: managerContactNumber ? managerContactNumber.toString() : null,
        coverLetterLink: copyOfEmploymentLetter,
        employerEmail: email,
        applyReferenceId,
        address,
        addressComponents,
        rank: TypeHelper.isNullOrUndefined(rank) ? 0 : rank,
      };
    });
};

const getApplicantIncome = (data: PostCurrentIncomeFormData): Income[] => {
  if (!data || data.incomes.length === 0) {
    return [];
  }
  return data.incomes
    .filter((income) => !checkIfObjectIsEmpty(income))
    .map((income) => {
      const {
        guarantorContactNumber,
        guarantorEmail,
        guarantorName,
        netWeeklyAmount,
        savingsAmount,
        payslipData,
        proofOfBenefitData,
        proofOfIncomeData,
        bankStatementData,
        centrePayReference,
        source,
      } = income;
      const selectedState = (
        store.getState().masterProfile.masterProfileData as SubmittedApplicationResponse
      ).applicants[0].selectedState;

      return {
        source,
        other: null,
        weeklyAmount: netWeeklyAmount ? parseInt(netWeeklyAmount, 10) : null,
        savingsAmount: savingsAmount ? parseInt(savingsAmount, 10) : null,
        guarantorName,
        guarantorEmail,
        guarantorMobile: guarantorContactNumber ? guarantorContactNumber.toString() : null,
        payslipLink: payslipData,
        benefitProofLink: proofOfBenefitData,
        incomeProofLink: proofOfIncomeData,
        bankStatementLink:
          selectedState === AustraliaState.VIC &&
          (source === SourceOfIncome.I_AM_WORKING_FULL_TIME ||
            source === SourceOfIncome.I_AM_WORKING_PART_TIME ||
            source === SourceOfIncome.I_HAVE_CASUAL_WORK ||
            source === SourceOfIncome.I_RECEIVE_A_BENEFIT ||
            source === SourceOfIncome.OTHER)
            ? []
            : bankStatementData,
        crn: centrePayReference,
      };
    });
};

const getApplicantReference = (data: PostReferencesFormData): Reference[] | null => {
  if (!data || data.references.length === 0) {
    return [];
  }
  return data.references
    .filter((reference) => !checkIfObjectIsEmpty(reference))
    .map((reference) => {
      const { email, mobile, name, relationship, applyReferenceId, rank } = reference;
      return {
        name,
        relationship,
        email,
        mobile: mobile ? mobile.toString() : null,
        applyReferenceId,
        rank: TypeHelper.isNullOrUndefined(rank) ? 0 : rank,
      };
    });
};

const getApplicantVehicles = (data: PostVehiclesFormData): Vehicle[] | null => {
  if (!data || data.vehicles.length === 0) {
    return [];
  }

  return data.vehicles
    .filter((vehicle) => !checkIfObjectIsEmpty(vehicle))
    .map((vehicle) => ({
      count: 1,
      ...vehicle,
    }));
};

const getApplicantPets = (data: PostPetsFormData): Pet[] | null => {
  if (!data || data.pets.length === 0) {
    return [];
  }
  if (data && !data.hasPets) {
    return [];
  }
  // Filter function is added to remove those objects which have noOfPets as zero.
  return data.pets
    .filter((pet) => !checkIfObjectIsEmpty(pet))
    .map((pet) => {
      const {
        type,
        specifyOtherType,
        breed,
        desexed,
        size,
        additionalDocuments,
        registrationNumber,
      } = pet;
      return {
        count: 1,
        breed,
        size,
        desexedStatus: desexed,
        type: type === PetTypes.Other ? specifyOtherType : (type as string),
        additionalPetDocuments: additionalDocuments,
        registrationNumber,
      };
    });
};

// tslint:disable-next-line: cyclomatic-complexity
const getApplicants = (data: ApplicationState, isDraft?: boolean): Applicants[] => {
  const { tenantDetails } = data.landingScreen;
  const {
    addressDetails,
    personalDetails,
    identificationDetails,
    employmentDetails,
    currentIncomeDetails,
    referencesDetails,
    petsDetails,
    vehiclesDetails,
    declarationDetails,
    refId,
  } = data.myProfileForm;

  const { secondaryApplicantProfiles, dependentProfiles } = data.additionalPersonalDetailScreen;
  const noOfApplicants =
    1 +
    (secondaryApplicantProfiles ? secondaryApplicantProfiles.length : 0) +
    (dependentProfiles ? dependentProfiles.length : 0);

  let applicants: Applicants[] = [];

  // Check if mandatory data for primary applicant has been entered
  if (!isDraft) {
    if (isPersonalDetailsFilled(personalDetails) === SectionStatus.INCOMPLETE) {
      throw Error(ERROR_MESSAGES.PRIMARY_PERSONAL_DETAILS_ERROR);
    }
    if (
      !currentIncomeDetails ||
      isCurrentIncomeDetailsFilled(currentIncomeDetails.incomes) === SectionStatus.INCOMPLETE
    ) {
      throw Error(ERROR_MESSAGES.PRIMARY_CURRENT_INCOME_ERROR);
    }
    if (isAddressDetailsFilled(addressDetails!.addresses) === SectionStatus.INCOMPLETE) {
      throw Error(ERROR_MESSAGES.PRIMARY_ADDRESS_DETAILS_ERROR);
    }
    if (isIdentificationDetailsFilled(identificationDetails) === SectionStatus.INCOMPLETE) {
      throw Error(ERROR_MESSAGES.PRIMARY_IDENTIFICATION_ERROR);
    }
    if (isDeclarationDetailsFilled(declarationDetails) === SectionStatus.INCOMPLETE) {
      throw Error(ERROR_MESSAGES.PRIMARY_DECLARATION_ERROR);
    }
  }

  let primaryEmail: string | null | undefined;
  if (tenantDetails! && tenantDetails!.email) {
    primaryEmail = tenantDetails!.email;
  } else {
    primaryEmail = personalDetails! && personalDetails!.email;
  }
  const primaryApplicant: Applicants = {
    refId: refId || null,
    digitalSignature: declarationDetails && declarationDetails.digitalSignature,
    isSortedTermsAccepted: declarationDetails! && declarationDetails!.isSortedTermsAccepted,
    title: personalDetails! && personalDetails!.title,
    firstName: personalDetails! && personalDetails!.firstName,
    middleName: personalDetails! && personalDetails!.middleName,
    lastName: personalDetails! && personalDetails!.lastName,
    email: primaryEmail,
    dob:
      personalDetails! && personalDetails!.selectedDateOfBirth
        ? TypeHelper.formatDate(personalDetails!.selectedDateOfBirth)
        : null,
    mobile: personalDetails && personalDetails.mobile ? personalDetails.mobile.toString() : null,
    applicantType: 'PRIMARY',
    hasBeenEmployed:
      employmentDetails &&
      typeof employmentDetails.hasBeenEmployed !== 'undefined' &&
      employmentDetails.hasBeenEmployed !== null
        ? employmentDetails.hasBeenEmployed
        : null,
    hasPets:
      petsDetails && typeof petsDetails.hasPets !== 'undefined' && petsDetails.hasPets !== null
        ? petsDetails.hasPets
        : null,
    hasVehicles:
      vehiclesDetails &&
      typeof vehiclesDetails.hasVehicles !== 'undefined' &&
      vehiclesDetails.hasVehicles !== null
        ? vehiclesDetails.hasVehicles
        : null,
    addReference:
      referencesDetails &&
      typeof referencesDetails.addReference !== 'undefined' &&
      referencesDetails.addReference !== null
        ? referencesDetails.addReference
        : null,
    guarantorName: personalDetails! ? personalDetails!.guarantorName : null,
    guarantorMobile:
      personalDetails && personalDetails.guarantorContactNumber
        ? personalDetails.guarantorContactNumber.toString()
        : null,
    guarantorEmail:
      personalDetails! && personalDetails!.guarantorEmail ? personalDetails!.guarantorEmail : null,
    applicantAddressHistory: getApplicantAddressHistory(addressDetails as PostAddressFormData),
    applicantIdentification: getapplicantIdentification(
      identificationDetails as PostIdentificationFormData,
    ),
    applicantEmergencyContact: personalDetails
      ? [
          {
            name: personalDetails.emergencyContactName ? personalDetails.emergencyContactName : '',
            email: personalDetails.emergencyContactEmail
              ? personalDetails.emergencyContactEmail
              : '',
            mobile: personalDetails.emergencyContactMobile
              ? personalDetails.emergencyContactMobile
              : '',
            relationship: personalDetails.emergencyContactRelationship
              ? personalDetails.emergencyContactRelationship
              : '',
          },
        ]
      : [],
    applicantEmploymentHistory: getApplicantEmploymentHistory(
      employmentDetails as PostEmploymentFormData,
    ),
    applicantIncome: getApplicantIncome(currentIncomeDetails as PostCurrentIncomeFormData),
    applicantPet: getApplicantPets(petsDetails as PostPetsFormData),
    applicantVehicles: getApplicantVehicles(vehiclesDetails as PostVehiclesFormData),
    applicantReference: getApplicantReference(referencesDetails as PostReferencesFormData),
    isManaged: true,
    introduction: get(data, `myProfileForm.introDetails.introText`, null),
    questions: personalDetails && personalDetails.questions ? personalDetails.questions : null,
    selectedState: (data.masterProfile.masterProfileData as SubmittedApplicationResponse)
      .applicants[0].selectedState,
    ntdPurchased: identificationDetails ? identificationDetails.ntdPurchased : null,
    ntdActive: identificationDetails ? identificationDetails.ntdActive : null,
    ntdDate: identificationDetails ? identificationDetails.ntdDate : null,
  };

  applicants.push(primaryApplicant);
  if (noOfApplicants === 1) {
    return uniqBy(applicants, 'email');
  }

  const secondaryApplicants: Applicants[] = secondaryApplicantProfiles.map((profileObj, index) => {
    // Check if mandatory data for seconadry applicant has been entered
    if (!isDraft && profileObj.personalDetails && profileObj.personalDetails.isManaged) {
      if (isPersonalDetailsFilled(profileObj.personalDetails) === SectionStatus.INCOMPLETE) {
        throw Error(ERROR_MESSAGES.ADDITIONAL_PERSONAL_DETAILS_ERROR);
      }
      if (
        !profileObj.currentIncomeDetails ||
        isCurrentIncomeDetailsFilled(profileObj.currentIncomeDetails.incomes) ===
          SectionStatus.INCOMPLETE
      ) {
        throw Error(ERROR_MESSAGES.ADDITIONAL_CURRENT_INCOME_ERROR);
      }
      if (
        !profileObj.addressDetails ||
        isAddressDetailsFilled(profileObj.addressDetails.addresses) === SectionStatus.INCOMPLETE
      ) {
        throw Error(ERROR_MESSAGES.ADDITIONAL_ADDRESS_DETAILS_ERROR);
      }
      if (
        isIdentificationDetailsFilled(profileObj.identificationDetails) === SectionStatus.INCOMPLETE
      ) {
        throw Error(ERROR_MESSAGES.ADDITIONAL_IDENTIFICATION_ERROR);
      }
      if (isDeclarationDetailsFilled(profileObj.declarationDetails) === SectionStatus.INCOMPLETE) {
        throw Error(ERROR_MESSAGES.ADDITIONAL_DECLARATION_ERROR);
      }
    }
    const applicant = (
      data.masterProfile.masterProfileData as SubmittedApplicationResponse
    ).applicants.find(
      (a) => profileObj.personalDetails && a.email === profileObj.personalDetails.email,
    )!;
    let applicantRefId = null;
    if (applicant && applicant.refId) {
      applicantRefId = applicant.refId;
    }
    const isManaged = get(profileObj, `personalDetails.isManaged`);
    if (!isManaged) {
      return {
        refId: profileObj.refId ? profileObj.refId : applicantRefId || null,
        title: get(profileObj, `personalDetails.title`),
        firstName: get(profileObj, `personalDetails.firstName`),
        middleName: get(profileObj, `personalDetails.middleName`),
        lastName: get(profileObj, `personalDetails.lastName`),
        email: get(profileObj, `personalDetails.email`),
        dob: null,
        mobile: get(profileObj, `personalDetails.mobile`),
        applicantType: 'ADDITIONAL',
        hasBeenEmployed: null,
        hasPets: null,
        hasVehicles: null,
        addReference: null,
        guarantorName: null,
        guarantorMobile: null,
        guarantorEmail: null,
        declarationDate: null,
        applicantAddressHistory: [],
        applicantIdentification: [],
        applicantEmergencyContact: [],
        applicantEmploymentHistory: [],
        applicantIncome: [],
        applicantPet: [],
        applicantVehicles: [],
        applicantReference: [],
        isManaged,
        introduction: null,
        questions: null,
        ntdPurchased: null,
        ntdActive: null,
        ntdDate: null,
      };
    }
    return {
      refId: profileObj.refId ? profileObj.refId : applicantRefId || null,
      title: get(profileObj, `personalDetails.title`),
      firstName: get(profileObj, `personalDetails.firstName`),
      middleName: get(profileObj, `personalDetails.middleName`),
      lastName: get(profileObj, `personalDetails.lastName`),
      email: get(profileObj, `personalDetails.email`),
      dob: get(profileObj, `personalDetails.selectedDateOfBirth`)
        ? TypeHelper.formatDate(get(profileObj, `personalDetails.selectedDateOfBirth`))
        : null,
      mobile: get(profileObj, `personalDetails.mobile`)
        ? get(profileObj, `personalDetails.mobile`).toString()
        : null,
      applicantType: 'ADDITIONAL',
      hasBeenEmployed:
        typeof get(profileObj, `employmentDetails.hasBeenEmployed`) !== 'undefined' &&
        get(profileObj, `employmentDetails.hasBeenEmployed`) !== null
          ? get(profileObj, `employmentDetails.hasBeenEmployed`)
          : null,
      hasPets:
        typeof get(profileObj, `petsDetails.hasPets`) !== 'undefined' &&
        get(profileObj, `petsDetails.hasPets`) !== null
          ? get(profileObj, `petsDetails.hasPets`)
          : null,
      hasVehicles:
        typeof get(profileObj, `vehiclesDetails.hasVehicles`) !== 'undefined' &&
        get(profileObj, `vehiclesDetails.hasVehicles`) !== null
          ? get(profileObj, `vehiclesDetails.hasVehicles`)
          : null,
      addReference:
        typeof get(profileObj, `referencesDetails.addReference`) !== 'undefined' &&
        get(profileObj, `referencesDetails.addReference`) !== null
          ? get(profileObj, `referencesDetails.addReference`)
          : null,
      guarantorName: get(profileObj, `personalDetails.guarantorName`),
      guarantorMobile: get(profileObj, `personalDetails.guarantorContactNumber`),
      guarantorEmail: get(profileObj, `personalDetails.guarantorEmail`),
      declarationDate: null,
      digitalSignature: get(profileObj, `declarationDetails.digitalSignature`, undefined),
      isSortedTermsAccepted: get(profileObj, `declarationDetails.isSortedTermsAccepted`, null),
      applicantAddressHistory: isManaged
        ? getApplicantAddressHistory(profileObj.addressDetails as PostAddressFormData)
        : [],
      applicantIdentification: getapplicantIdentification(
        profileObj.identificationDetails as PostIdentificationFormData,
      ),
      applicantEmergencyContact: get(profileObj, `personalDetails`)
        ? [
            {
              name: get(profileObj, `personalDetails.emergencyContactName`, null)
                ? get(profileObj, `personalDetails.emergencyContactName`, null)
                : '',
              email: get(profileObj, `personalDetails.emergencyContactEmail`, null)
                ? get(profileObj, `personalDetails.emergencyContactEmail`, null)
                : '',
              mobile: get(profileObj, `personalDetails.emergencyContactMobile`, null)
                ? get(profileObj, `personalDetails.emergencyContactMobile`, null)
                : '',
              relationship: get(profileObj, `personalDetails.emergencyContactRelationship`, null)
                ? get(profileObj, `personalDetails.emergencyContactRelationship`, null)
                : '',
            },
          ]
        : [],
      applicantEmploymentHistory: isManaged
        ? getApplicantEmploymentHistory(profileObj.employmentDetails as PostEmploymentFormData)
        : [],
      applicantIncome: getApplicantIncome(
        profileObj.currentIncomeDetails as PostCurrentIncomeFormData,
      ),
      applicantPet: getApplicantPets(profileObj.petsDetails as PostPetsFormData),
      applicantVehicles: getApplicantVehicles(profileObj.vehiclesDetails as PostVehiclesFormData),
      applicantReference: isManaged
        ? getApplicantReference(profileObj.referencesDetails as PostReferencesFormData)
        : [],
      isManaged,
      introduction: get(profileObj, `introDetails.introText`, null),
      questions: get(profileObj, `personalDetails.questions`, null),
      ntdPurchased: get(profileObj, `identificationDetails.ntdPurchased`, null),
      ntdActive: get(profileObj, `identificationDetails.ntdActive`, null),
      ntdDate: get(profileObj, `identificationDetails.ntdDate`, null),
    };
  });

  if (secondaryApplicants && secondaryApplicants.length) {
    applicants.push(...secondaryApplicants);
  }
  applicants = uniqBy(applicants, 'email');
  const dependentApplicants: Applicants[] = dependentProfiles.map((profileObj) => {
    const applicant = (
      data.masterProfile.masterProfileData as SubmittedApplicationResponse
    ).applicants.find(
      (a) => a.firstName === profileObj.firstName && a.lastName === profileObj.lastName,
    )!;
    let applicantRefId = null;
    if (applicant && applicant.refId) {
      applicantRefId = applicant.refId;
    }

    const { isChild, selectedDateOfBirth } = profileObj;
    return {
      refId: isChild && profileObj.refId ? profileObj.refId : applicantRefId || null,
      title: isChild && profileObj.title ? profileObj.title : null,
      firstName: isChild && profileObj ? profileObj.firstName : null,
      middleName: isChild && profileObj.middleName ? profileObj.middleName : null,
      lastName: isChild && profileObj ? profileObj.lastName : null,
      email: null,
      dob: isChild ? TypeHelper.formatDate(selectedDateOfBirth) : null,
      mobile: null,
      applicantType: 'DEPENDENT',
      guarantorName: null,
      guarantorMobile: null,
      guarantorEmail: null,
      hasBeenEmployed: null,
      hasPets: null,
      hasVehicles: null,
      addReference: null,
      applicantAddressHistory: [],
      applicantIdentification: [],
      applicantEmergencyContact: [],
      applicantEmploymentHistory: [],
      applicantIncome: [],
      applicantPet: [],
      applicantVehicles: [],
      applicantReference: [],
      propertyInspected: null,
      isManaged: true,
      introduction: null,
      questions: null,
      ntdPurchased: null,
      ntdActive: null,
      ntdDate: null,
    };
  });

  if (dependentApplicants && dependentApplicants.length) {
    applicants.push(...dependentApplicants);
  }

  return applicants;
};

export const getPropertyList = (data: ApplicationState): PropertyData[] => {
  return data.landingScreen.properties
    ? data.landingScreen.properties.map((p: PropertyDetailsResponse) => ({
        propertyRefId: p.refId,
        rentOffer: p.rentalOffer,
        address: p.address,
        addressComponents: p.addressComponents,
        agency: store.getState().dashboard.agency,
        branch: store.getState().dashboard.branch,
        years: p.years || null,
        months: p.months || null,
        didInspect: true,
        state: p.state,
        bedrooms: p.bedrooms || 0,
        bathrooms: p.bathrooms || 0,
        carspaces: p.carspaces || 0,
        description: p.description || '',
        photos: p.photos || [],
        streetNumber: p.streetNumber || '',
        unitNumber: p.unitNumber || '',
        streetName: p.streetName || '',
        suburb: p.suburb || '',
        postcode: p.postcode || '',
        country: p.country || '',
        inspectionMethod: p.inspectionMethod,
        preferredMoveInDate: TypeHelper.formatDate(p.preferredMoveInDate),
        inspectionDate: p.inspectionDate ? TypeHelper.formatDate(p.inspectionDate) : null,
      }))
    : [];
};

export const getVehiclesCount = (data: ApplicationState) => {
  let primaryVehicles = get(data, 'myProfileForm.vehiclesDetails.vehicles', []);
  primaryVehicles = primaryVehicles.filter((v: Vehicles) => v.type);
  const secondaryApplicants = get(data, 'additionalPersonalDetailScreen.secondaryApplicants', []);

  let secondaryVehiclesCount = 0;
  if (secondaryApplicants.length) {
    secondaryApplicants.forEach((a: ApplicantProfile) => {
      if (
        a.vehiclesDetails &&
        a.vehiclesDetails.vehicles &&
        a.vehiclesDetails.vehicles.length &&
        a.vehiclesDetails.vehicles[0].type
      ) {
        secondaryVehiclesCount += a.vehiclesDetails.vehicles.length;
      }
    });
  }

  return primaryVehicles.length + secondaryVehiclesCount;
};

export const getPetsCount = (data: ApplicationState) => {
  let primaryPets = get(data, 'myProfileForm.petsDetails.pets', []);
  primaryPets = primaryPets.filter((p: Pets) => p.type);
  const secondaryApplicants = get(
    data,
    'additionalPersonalDetailScreen.secondaryApplicantProfiles',
    [],
  );
  let secondaryPetsCount = 0;
  if (secondaryApplicants.length) {
    secondaryApplicants.forEach((a: ApplicantProfile) => {
      if (
        a.petsDetails &&
        a.petsDetails.pets &&
        a.petsDetails.pets.length &&
        a.petsDetails.pets[0].type
      ) {
        secondaryPetsCount += a.petsDetails.pets.length;
      }
    });
  }
  return primaryPets.length + secondaryPetsCount;
};

export const convertDataToPost = (data: ApplicationState): ApplyFormPayload => {
  const { properties } = data.landingScreen;
  const { propertyDetails } = data.dashboard;
  const { personalDetails } = data.myProfileForm;
  if (!(properties && properties.length)) {
    throw Error(ERROR_MESSAGES.PROPERTIES_ERROR);
  } else if (!personalDetails) {
    throw Error(ERROR_MESSAGES.PRIMARY_PERSONAL_DETAILS_ERROR);
  }
  const { refId } = propertyDetails!;
  const convertedData: ApplyForm = {
    email: personalDetails && personalDetails.email,
    name: `${personalDetails.firstName} ${
      personalDetails.middleName ? `${personalDetails.middleName} ` : ''
    }${personalDetails.lastName}`,
    rentOffer: 0,
    // years: tenantDetails.leaseDurationYear!,
    // months: tenantDetails.leaseDurationMonth!,
    // preferredMoveInDate: TypeHelper.formatDate(tenantDetails.selectedDate),
    noOfPeople: 5,
    noOfPets: getPetsCount(data),
    noOfVehicles: getVehiclesCount(data),
    applicants: getApplicants(data, false),
  };
  return {
    applyForm: convertedData,
    refId,
  };
};

export const convertPropertyDataToPost = (
  data: ApplicationState,
  isDraft?: boolean,
): ApplyPropertyFormPayload => {
  const { tenantDetails } = data.landingScreen;
  const { personalDetails } = data.myProfileForm;
  if (!isDraft) {
    if (!personalDetails) {
      throw Error(ERROR_MESSAGES.PRIMARY_PERSONAL_DETAILS_ERROR);
    }
  }
  const convertedData: ApplyPropertyForm = {
    propertyList: getPropertyList(data),
    applicationRequest: {
      email: personalDetails! && personalDetails!.email,
      name:
        personalDetails! &&
        `${personalDetails!.firstName} ${
          personalDetails!.middleName ? `${personalDetails!.middleName} ` : ''
        }${personalDetails!.lastName}`,
      rentOffer: 0,
      years: tenantDetails! && tenantDetails!.leaseDurationYear!,
      months: tenantDetails! && tenantDetails!.leaseDurationMonth!,
      preferredMoveInDate: tenantDetails! && TypeHelper.formatDate(tenantDetails!.selectedDate),
      noOfPeople: tenantDetails! && tenantDetails!.noOfPeople,
      noOfPets: getPetsCount(data),
      noOfVehicles: getVehiclesCount(data),
      applicants: getApplicants(data, isDraft),
    },
  };
  const userId = localStorage.getItem('userId');
  const draftId = localStorage.getItem('draftId');
  const draftTitle = localStorage.getItem('draftTitle');
  if (userId && userId !== 'undefined') {
    convertedData.userId = userId;
  }
  if (draftId && draftId !== 'undefined') {
    convertedData.draftId = draftId;
  }
  if (draftTitle && draftTitle !== 'undefined') {
    convertedData.title = draftTitle;
  }
  return {
    applyForm: convertedData,
  };
};

export const getUpdatePropertyData = (data: ApplicationState): PropertyUpdateDetails => {
  const { tenantDetails, properties } = data.landingScreen;

  const propertyData = {
    rentOffer: properties![0].rentalOffer,
    years: properties![0].years || 0,
    months: properties![0].months || 0,
    didInspect: true,
    inspectionMethod: properties![0].inspectionMethod,
    preferredMoveInDate: TypeHelper.formatDate(properties![0].preferredMoveInDate),
    inspectionDate: properties![0].inspectionDate
      ? TypeHelper.formatDate(properties![0].inspectionDate)
      : null,
  };
  const result = {
    ...propertyData,
    noOfPeople: tenantDetails! && tenantDetails!.noOfPeople,
    noOfPets: getPetsCount(data),
  };
  return result;
};

export const mapRefIdsToProfiles = (stateData: ApplicationState) => {
  const { masterProfileData } = stateData.masterProfile;
  !!masterProfileData &&
    masterProfileData.applicants.length > 0 &&
    masterProfileData.applicants.forEach((applicant, index) => {
      if (applicant.applicantType === 'PRIMARY') {
        stateData.myProfileForm.refId = applicant.refId;
      }
      if (applicant.applicantType === 'ADDITIONAL') {
        stateData.additionalPersonalDetailScreen.secondaryApplicantProfiles.forEach(
          (secondaryApplicant) => {
            if (compareApplicants(applicant, secondaryApplicant.personalDetails)) {
              secondaryApplicant.refId = applicant.refId;
            }
          },
        );
      }
      // if (applicant.applicantType === 'DEPENDENT') {
      //   stateData.additionalPersonalDetailScreen.dependentProfiles.forEach((dependentApplicant) => {
      //     if (compareApplicants(applicant, dependentApplicant)) {
      //       dependentApplicant.refId = applicant.refId;
      //     }
      //   });
      // }
    });
  return stateData;
};

export const compareApplicants = (applicant: any, secondApplicant: any): boolean => {
  const masterApplicant = {
    firstName: applicant.firstName,
    lastName: applicant.lastName,
    email: applicant.email,
    dob: applicant.dob,
  };
  const secondaryApplicant = {
    firstName: secondApplicant.firstName,
    lastName: secondApplicant.lastName,
    email: secondApplicant.email,
    dob: TypeHelper.formatDate(secondApplicant.selectedDateOfBirth),
  };

  // checking a combination of the following info to deduce whether to keep the refId or create one.
  const flag =
    (masterApplicant.firstName === secondaryApplicant.firstName &&
      masterApplicant.lastName === secondaryApplicant.lastName) ||
    masterApplicant.email === secondaryApplicant.email;

  return flag;
};

export const mapApplyReferenceIdsToProfiles = (data: ApplicationState) => {
  const { masterProfileData } = data.masterProfile;
  !!masterProfileData &&
    masterProfileData.applicants.length > 0 &&
    masterProfileData.applicants.forEach((applicant, index) => {
      if (applicant.applicantType === 'PRIMARY') {
        data.myProfileForm.refId = applicant.refId;
        data.myProfileForm.employmentDetails = {
          employments: employmentFormData(masterProfileData, index),
          hasBeenEmployed:
            !TypeHelper.isNullOrUndefined(data.myProfileForm.employmentDetails) &&
            data.myProfileForm.employmentDetails!.hasBeenEmployed,
        };
        data.myProfileForm.addressDetails = {
          addresses: addressFormData(masterProfileData, index),
        };
        data.myProfileForm.referencesDetails = {
          references: refrenceFormData(masterProfileData, index),
          addReference:
            !TypeHelper.isNullOrUndefined(data.myProfileForm.referencesDetails) &&
            data.myProfileForm.referencesDetails!.addReference,
        };
      }
      if (applicant.applicantType === 'ADDITIONAL') {
        data.additionalPersonalDetailScreen.secondaryApplicantProfiles.forEach(
          (secondaryApplicant) => {
            if (compareApplicants(applicant, secondaryApplicant.personalDetails)) {
              secondaryApplicant.refId = applicant.refId;
              secondaryApplicant.employmentDetails = {
                employments: employmentFormData(masterProfileData, index),
                hasBeenEmployed:
                  !TypeHelper.isNullOrUndefined(secondaryApplicant.employmentDetails) &&
                  secondaryApplicant.employmentDetails!.hasBeenEmployed,
              };
              secondaryApplicant.addressDetails = {
                addresses: addressFormData(masterProfileData, index),
              };
              secondaryApplicant.referencesDetails = {
                references: refrenceFormData(masterProfileData, index),
                addReference:
                  !TypeHelper.isNullOrUndefined(data.myProfileForm.referencesDetails) &&
                  data.myProfileForm.referencesDetails!.addReference,
              };
            }
          },
        );
      }
      // if (applicant.applicantType === 'DEPENDENT') {
      //   data.additionalPersonalDetailScreen.dependentProfiles.forEach((dependentApplicant) => {
      //     if (compareApplicants(applicant, dependentApplicant)) {
      //       dependentApplicant.refId = applicant.refId;
      //     }
      //   });
      // }
    });
  return data;
};

export const convertReferencesBody = (data: ApplicationState) => {
  const referencesState: ReferencesPayload[] = [];
  const { refId, referencesDetails, addressDetails, employmentDetails } = data.myProfileForm;
  referencesState.push({
    profileRefId: refId || null,
    applicantAddressHistory: getApplicantAddressHistory(addressDetails as PostAddressFormData),
    applicantEmploymentHistory: getApplicantEmploymentHistory(
      employmentDetails as PostEmploymentFormData,
    ),
    applicantReference: getApplicantReference(referencesDetails as PostReferencesFormData),
  });
  const { secondaryApplicantProfiles } = data.additionalPersonalDetailScreen;

  !!data.masterProfile.masterProfileData &&
    data.masterProfile.masterProfileData.applicants.length > 0 &&
    data.masterProfile.masterProfileData.applicants.forEach((applicant, index) => {
      if (applicant.applicantType === 'PRIMARY') {
        referencesState[index].profileRefId = applicant.refId;
      }
      if (applicant.applicantType === 'ADDITIONAL') {
        secondaryApplicantProfiles.forEach((secondaryApplicant) => {
          if (compareApplicants(applicant, secondaryApplicant.personalDetails)) {
            secondaryApplicant.refId = applicant.refId;
          }
        });
      }
    });

  secondaryApplicantProfiles.forEach((applicants: ApplicantProfile) => {
    const { refId, referencesDetails, addressDetails, employmentDetails } = applicants;
    referencesState.push({
      profileRefId: refId || null,
      applicantAddressHistory: getApplicantAddressHistory(addressDetails as PostAddressFormData),
      applicantEmploymentHistory: getApplicantEmploymentHistory(
        employmentDetails as PostEmploymentFormData,
      ),
      applicantReference: getApplicantReference(referencesDetails as PostReferencesFormData),
    });
  });
  return referencesState;
};

export const getRefId = (
  masterProfileData: SubmittedApplicationResponse | null,
  personalDetails: PostPersonalDetailsFormData | undefined,
  referenceId: string | null | undefined,
): string | null => {
  let refId = null;
  // Return reference id if available
  if (referenceId) {
    return referenceId;
  }

  // Reference id will not be available in the store when the applicant is added
  // from the Application Preference page
  masterProfileData &&
    masterProfileData.applicants.forEach((applicant) => {
      if (applicant.email === personalDetails!.email) {
        refId = applicant.refId;
      }
    });
  return refId;
};
