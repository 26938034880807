import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { actionGreen, globalStyles } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  applicationProfileCard: {
    marginBottom: theme.units.spacing * 4,
  },
  profileRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  profileImageContainer: {
    width: 65,
    height: 65,
    backgroundColor: theme.colors.filterBackground,
    borderRadius: 5000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  profileImage: {
    height: '45%',
    width: '45%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  gravatar: {
    height: '65px',
    width: '65px',
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '24px',
    border: `${theme.colors.light} 1px solid`,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '50px',
      width: '50px',
    },
  },
  title: {
    marginBottom: theme.units.spacing * 2,
  },
  percentageWheel: {
    marginLeft: theme.units.spacing * 4,
  },
  applicationProfileDetails: {
    marginLeft: '20px',
    marginRight: 'auto',
  },
  applicationProfileName: {
    color: theme.colors.black87,
    fontSize: '18px',
    marginBottom: 4,
  },
  lineRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  buttonMarginRight: {
    marginRight: theme.units.spacing * 4,
  },
  statusContainer: {
    borderRadius: 500,
    minWidth: '120px',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    color: theme.colors.black87,
  },
  completeLabel: {
    backgroundColor: theme.colors.completeLabel,
  },
  optionalLabel: {
    backgroundColor: theme.colors.grey,
  },
  incompleteLabel: {
    backgroundColor: theme.colors.incompleteLabel,
  },
  statusText: {
    fontSize: 14,
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionText: {
    marginRight: theme.units.spacing * 4,
  },
  actionIcon: {
    width: theme.units.iconSize / 1.6,
    height: theme.units.iconSize / 1.6,
    objectFit: 'contain',
    objectPosition: 'center',
    transition: 'all 0.5s ease 0s',
    ...actionGreen,
  },
  actionIconActive: {
    transform: 'rotate(180deg)',
    transition: 'all 0.5s ease 0s',
  },
  content: {
    transition: 'all 0.5s ease 0s',
    overflow: 'hidden',
    height: '100%',
  },
});
