import moment from 'moment';
import { TypeHelper } from './TypeHelper';
import { RentalFrequency } from '../services/remoteSigning/lease/Lease';
import {
  firstRentMultiplier,
  firstRentMonthMultiplier,
  monthsInYear,
  daysInWeek,
} from '../constants/AppConstants';

export class CalculationHelper {
  public static isPastDate(value: any) {
    const currentDay = moment();
    const date = moment(value, 'DD/MM/YY');
    return date.diff(currentDay, 'days') < 0;
  }
  public static isAbove18(value: any) {
    return moment().diff(moment(value), 'years', true) > 18;
  }

  public static checkPhoneNumberValidity = (getPhoneDigit: object, isValidNumber: object) => {
    for (const key in isValidNumber) {
      if (
        !TypeHelper.isNullOrUndefined(getPhoneDigit[key]) &&
        getPhoneDigit[key] !== '' &&
        !isValidNumber[key]
      ) {
        return false;
      }
    }
    return true;
  };

  public static getOneCycleRentalAmount(
    rentalFrequency: RentalFrequency | string | null | undefined,
    rentalAmount: number | null | undefined,
  ): number | null {
    if (!rentalFrequency || !rentalAmount) {
      return null;
    }
    switch (rentalFrequency) {
      case RentalFrequency.Weekly:
        return rentalAmount;
      case RentalFrequency.Fortnightly:
        return rentalAmount * firstRentMultiplier;
      case RentalFrequency.Monthly:
        return (
          Math.round(
            ((rentalAmount * firstRentMonthMultiplier) / (monthsInYear * daysInWeek)) * 100,
          ) / 100
        );
      default:
        return null;
    }
  }
}
