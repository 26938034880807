import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../../../constants/AppConstants';

export const LABELS = {
  CREATE_ACCOUNT: 'Create an account with Sorted',
  SIGNUP_TITLE: 'Sign up below',
  SIGNUP_SUBTITLE: 'Enter your details below to create your Sorted account',
  LOGIN_HEADER: 'Log In',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL_ADDRESS: 'Email Address',
  PASSWORD: 'Password',
  SIGNUP_BUTTON: 'Sign up',
  LOGIN_BUTTON: 'Log in',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  WHY_SORTED: 'Why Sorted?',
};

export const ERRORS = {
  required: 'Required',
  invalidNameFormat: 'Invalid name format',
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
  requiredPassword: 'Please enter password',
  passwordRequirement: "Password doesn't fulfill the requirements",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  firstname: Yup.string()
    .required(ERRORS.required)
    .max(255)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  lastname: Yup.string()
    .required(ERRORS.required)
    .max(255)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  email: Yup.string()
    .required(ERRORS.requiredEmail)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat)
    .max(255),
  password: Yup.string()
    .required(ERRORS.requiredPassword)
    .min(8, ERRORS.passwordRequirement)
    .matches(/[A-Z]/, ERRORS.passwordRequirement)
    .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, ERRORS.passwordRequirement),
});
