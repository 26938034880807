/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useMemo } from 'react';
import { History } from 'history';
import { Typography, Paper } from '@mui/material';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './SimilarPropertiesStyles';
import propertyDefaultIcon from '../../assets/property-default.svg';
import eyeIcon from '../../assets/outline-remove-red-eye-24-px.svg';
import { LABELS } from './SimilarPropertiesConstants';
import { getRefIds, shuffle } from './SimilarPropertiesUtils';
import { routes } from '../../Routes';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { ApplicationState } from '../../store/RootReducers';
import {
  SimilarPropertiesResponse,
  SimilarPropertiesRequest,
} from '../../services/dashboard/similarProperties/GetSimilarProperties';
import { ApplicationsResponse } from '../../services/groups/Groups';
import Box from '../box/Box';
import Text from '../text/Text';

interface SimilarPropertiesProps {
  history: History;
  refIds?: string[];
  groupApplications: ApplicationsResponse | undefined;
  similarProperties: SimilarPropertiesResponse[] | undefined;
  getSimilarProperties: (data: SimilarPropertiesRequest) => void;
  setSimilarProperty: (data: SimilarPropertiesResponse | null) => void;
}

const SimilarProperties: FC<SimilarPropertiesProps> = ({
  history,
  refIds,
  groupApplications,
  similarProperties,
  getSimilarProperties,
  setSimilarProperty,
}) => {
  const classes = useStyles();
  const propertyRefIds = useMemo<string[]>(() => {
    if (refIds) {
      return shuffle(refIds);
    } else {
      return shuffle(getRefIds(groupApplications));
    }
  }, [groupApplications]);

  useEffect(() => {
    getSimilarProperties({ refIds: propertyRefIds });
  }, [propertyRefIds]);

  return (
    <>
      {similarProperties && similarProperties.length ? (
        <Box parentStyles={classes.similarPropertiesContainer}>
          <div className={classes.profileTitleContainer}>
            <Text textVariant="title" parentStyles={classes.similarTitle}>
              {LABELS.SIMILAR_PROPERTIES_TITLE}
            </Text>
            <Text textVariant="info">{LABELS.SIMILAR_PROPERTIES_SUBTITLE}</Text>
          </div>
          <div className={classes.applicationsCardsContainer}>
            {similarProperties.map((property) => (
              <div className={classes.similarPropertyCard}>
                <img
                  src={
                    property.photos && property.photos.length
                      ? property.photos[0]
                      : propertyDefaultIcon
                  }
                  className={classes.propertyImage}
                  alt="property pic"
                />
                <div className={classes.addressContainer}>
                  <Text textVariant="title" size="xl">
                    {`
                    ${
                      property.unitNumber
                        ? `${property.unitNumber}/${property.streetNumber} `
                        : `${property.streetNumber} `
                    } 
                    ${property.streetName}
                    `}
                  </Text>
                  <Text textVariant="info" size="l">
                    {property.suburb.toLowerCase()}
                  </Text>
                </div>
                <Text textVariant="title" size="xl" parentStyles={classes.rentLarge}>
                  ${property.rent}pw
                </Text>
                <div
                  className={classes.actionButton}
                  onClick={() => {
                    setSimilarProperty(property);
                    history.push(routes.viewProperty.view(`${property.refId}`));
                  }}
                >
                  View
                  <img src={eyeIcon} className={classes.actionIcon} alt="action pic" />
                </div>
              </div>
            ))}
          </div>
        </Box>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  groupApplications: state.groups.groupApplications,
  similarProperties: state.dashboard.similarProperties,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSimilarProperty: (data: SimilarPropertiesResponse | null) => {
    dispatch(DashboardActions.setSimilarProperty(data));
  },
  getSimilarProperties: (data: SimilarPropertiesRequest) => {
    dispatch(DashboardActions.getSimilarPropertiesRequest(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProperties);
