export interface CustomTermsHeaderResponse {
  readonly terms: string;
  readonly sortedTerms: string;
  readonly lifeStyleImage: string;
  readonly config: Config;
}

export interface Config {
  questions: ProfileQuestion[];
}

export interface ProfileQuestion {
  readonly type: QuestionType;
  readonly question: string;
  answer: string | null;
}

export enum QuestionType {
  BUTTON = 'BUTTON',
  TEXT = 'TEXT',
}

export interface CustomTermsHeaderPayload {
  readonly agency: string;
  readonly branch?: string | null;
}

export enum CustomTermsHeaderActionTypes {
  GET_TERMS_REQUEST = 'GET_TERMS_REQUEST',
  GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS',
  GET_TERMS_ERROR = 'GET_TERMS_ERROR',
}

export interface CustomTermsHeaderAction {
  type: CustomTermsHeaderActionTypes;
  data?: CustomTermsHeaderResponse | CustomTermsHeaderPayload | Error;
}

export class CustomTermsHeaderActions {
  public static postCustomTermsHeaderStart(
    data: CustomTermsHeaderPayload,
  ): CustomTermsHeaderAction {
    return {
      type: CustomTermsHeaderActionTypes.GET_TERMS_REQUEST,
      data,
    };
  }
  public static postCustomTermsHeaderSuccess(
    data: CustomTermsHeaderResponse,
  ): CustomTermsHeaderAction {
    return {
      type: CustomTermsHeaderActionTypes.GET_TERMS_SUCCESS,
      data,
    };
  }
  public static postCustomTermsHeaderError(error: Error): CustomTermsHeaderAction {
    return {
      type: CustomTermsHeaderActionTypes.GET_TERMS_ERROR,
      data: error,
    };
  }
}
