/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  bannerContainer: {
    width: '100%',
    maxWidth: 1200,
    minHeight: 100,
    borderRadius: theme.units.borderRadius,
    marginBottom: theme.units.spacing * 4,
    backgroundColor: theme.colors.lightGrey,
    display: 'flex',
  },
  errorBackground: {
    backgroundColor: theme.colors.lightRed,
  },
  warningBackground: {
    backgroundColor: theme.colors.lightOrange,
  },
  successBackground: {
    backgroundColor: theme.colors.lightGreen,
  },
  bannerContent: {
    padding: theme.units.spacing * 4,
    display: 'flex',
    flex: 1,
  },
  icon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginRight: theme.units.spacing * 4,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.units.spacing * 4,
  },
  button: {
    backgroundColor: theme.colors.light,
    borderColor: theme.colors.borderColor,
    color: theme.colors.black87,
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 25,
    flexShrink: 0,
  },
});
