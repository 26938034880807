/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import camelCaseKeys from 'camelcase-keys';
import { EquifaxAction, EquifaxActions, EquifaxActionTypes } from '../actions/EquifaxActions';
import EquifaxService from '../../services/equifax/EquifaxService';
import {
  EquifaxRequest,
  EquifaxResponse,
  EquifaxRetryPaymentRequest,
  EquifaxStatus,
} from '../../models/equifax/equifax';
import { store } from '../Store';
import { MasterProfileActions } from '../actions/MasterProfileActions';
import { setNtdPurchasedToTrue } from '../../helper/NTDHelper';

export function* getNTDCheck(action: EquifaxAction) {
  try {
    let response: EquifaxResponse = yield EquifaxService.getNtdCheck(action.data as EquifaxRequest);
    response.userRefId = (action.data as EquifaxRequest).ntdRequest.profile.refId!;
    if (response.status === EquifaxStatus.COMPLETED) {
      response.data = JSON.parse(response.data! as unknown as string);
    }
    const convertedToCamel: EquifaxResponse = camelCaseKeys(response, {
      deep: true,
    });
    yield put(EquifaxActions.getNTDCheckSuccess(convertedToCamel));
    if (
      convertedToCamel.status &&
      [EquifaxStatus.COMPLETED, EquifaxStatus.REQUESTED, EquifaxStatus.PROCESSING].includes(
        convertedToCamel.status,
      )
    ) {
      const newProfile = setNtdPurchasedToTrue(
        store.getState(),
        (action.data as EquifaxRequest).ntdRequest.profile.refId!,
      );
      yield put(MasterProfileActions.postSaveMasterProfile(newProfile));
    }
  } catch (error) {
    yield put(EquifaxActions.getNTDCheckError(error as Error));
  }
}

export function* retryPaymentNTDCheck(action: EquifaxAction) {
  try {
    let response: EquifaxResponse = yield EquifaxService.retryPaymentNtdCheck(
      action.data as EquifaxRetryPaymentRequest,
    );
    response.userRefId = (action.data as EquifaxRetryPaymentRequest).userRefId;
    if (response.status === EquifaxStatus.COMPLETED) {
      response.data = JSON.parse(response.data! as unknown as string);
    }
    const convertedToCamel: EquifaxResponse = camelCaseKeys(response, {
      deep: true,
    });
    yield put(EquifaxActions.retryPaymentCheckSuccess(convertedToCamel));
  } catch (error) {
    yield put(EquifaxActions.retryPaymentCheckError(error as Error));
  }
}

function* equifaxWatcher() {
  yield takeEvery(EquifaxActionTypes.GET_NTD_CHECK_REQUEST, (action: EquifaxAction) =>
    getNTDCheck(action),
  );
  yield takeEvery(EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK_REQUEST, (action: EquifaxAction) =>
    retryPaymentNTDCheck(action),
  );
}

export default equifaxWatcher;
