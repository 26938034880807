import React, { FC } from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './ResendEmailErrorModalStyle';
import { ReferenceToolActions } from '../../../store/actions/ReferenceToolActions';

interface ResendEmailErrorModalProps {
  error: Error | string;
  resendEmailError: () => void;
}

const ResendEmailErrorModal: FC<ResendEmailErrorModalProps> = ({ error, resendEmailError }) => {
  const classes = useStyles();
  return (
    <Dialog open={!!error}>
      <DialogTitle>{error}</DialogTitle>
      <div className={classes.btnWprStyle}>
        <Button className={classes.btnStyle} onClick={resendEmailError}>
          OK
        </Button>
      </div>
    </Dialog>
  );
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  resendEmailError: () => dispatch(ReferenceToolActions.resendEmailError(null)),
});

export default connect(null, mapDispatchToProps)(ResendEmailErrorModal);
