import { OverviewFormData } from '../../services/landingScreen/OverviewForm.data';
import { PropertyDetailsResponse } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

export enum LandingScreenActionTypes {
  POST_OVERVIEW_FORM_REQUEST = 'POST_OVERVIEW_FORM_REQUEST',
  POST_OVERVIEW_FORM_SUCCESS = 'POST_OVERVIEW_FORM_SUCCESS',
  POST_OVERVIEW_FORM_ERROR = 'POST_OVERVIEW_FORM_ERROR',
  SAVE_REF_ID = 'SAVE_REF_ID',
  SAVE_PROPERTIES = 'SAVE_PROPERTIES',
  LANDING_SCRREN_CLEAR_PROPERTIES = 'LANDING_SCRREN_CLEAR_PROPERTIES',
  DELETE_OTHER_APPLICANT = 'DELETE_OTHER_APPLICANT',
  DELETE_ALL_OTHER_APPLICANTS = 'DELETE_ALL_OTHER_APPLICANTS',
  SAVE_DETAILS_ON_LOGIN = 'SAVE_DETAILS_ON_LOGIN',
}

export interface LandingScreenAction {
  type: LandingScreenActionTypes;
  data?: OverviewFormData | PropertyDetailsResponse[] | string | number | Error;
}

export class LandingScreenActions {
  public static postFormDetails(data: OverviewFormData): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.POST_OVERVIEW_FORM_REQUEST,
      data,
    };
  }
  public static deleteOtherApplicantByKey(key: number): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.DELETE_OTHER_APPLICANT,
      data: key,
    };
  }
  public static postFormSuccess(data: OverviewFormData): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.POST_OVERVIEW_FORM_SUCCESS,
      data,
    };
  }
  public static postFormError(error: Error): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.POST_OVERVIEW_FORM_ERROR,
    };
  }
  public static saveRefId(data: string): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.SAVE_REF_ID,
      data,
    };
  }
  public static saveAllDetails(data: OverviewFormData): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.SAVE_DETAILS_ON_LOGIN,
      data,
    };
  }
  public static postPropertyDetails(data: PropertyDetailsResponse[]): LandingScreenAction {
    return {
      type: LandingScreenActionTypes.SAVE_PROPERTIES,
      data,
    };
  }

  public static clearProperties() {
    return {
      type: LandingScreenActionTypes.LANDING_SCRREN_CLEAR_PROPERTIES,
    };
  }

  public static deleteAllOtherApplicants() {
    return {
      type: LandingScreenActionTypes.DELETE_ALL_OTHER_APPLICANTS,
    };
  }
}
