/* eslint-disable max-lines */
import React, { FC, useState, useEffect } from 'react';
import {
  ThemeProvider,
  Grid,
  Button,
  Input,
  InputAdornment,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { FileUploadState, FileLink, FileLinkOrContent } from './FileUploadState.data';
import { FileUploadResult, FileUploadAction } from './FileUploadResult.data';
import { FileUploadMessages } from './FileUploadMessages.data';
import { Content } from '../../../../helper/Content/Content.data';
import { TypeHelper } from '../../../../helper/TypeHelper';
import { Logging } from '../../../../helper/Logging';
import { ContentType, ContentTypeHelper } from '../../../../helper/Content/ContentType.data';
import PhotoLibAdd from '../../../../assets/photolib_add.png';
import cyanGrayTheme from '../TenancyCyanGrayTheme';
import { color } from '../TenancyTheme';
import { AppIcon, AppIconSize, AppIconType } from '../../../../component/AppIcon/AppIcon';
import { useStyles } from './FileUploadStyles.data';
import { ContentCategory } from '../../../../helper/Content/ContentCategory.data';
import { compressIfImage } from '../../../../helper/FileUploadHelper';

export interface FileUploadProps {
  disabled?: boolean | undefined | null;
  id: string;
  showErrors: boolean;
  maxFileCount: number; // mandatory
  messages: FileUploadMessages;
  maxFileSize: number;
  design: FileUploadDesign;
  variant?: FileUploadDisplayVariant;
  uploadInBatch?: boolean | undefined | null; // if one of the files is invalid or more than 24, ignore the rest? default: false (the rest are uploaded)
  allowedFormats?: ContentType[] | undefined | null; // default all
  files?: Content[] | undefined | null;
  text?: string | undefined;
  hideFileName?: boolean;
  buttonStyle?: any;
  handleItemSelected?: (item: FileLinkOrContent) => void;
  handleUpload?: ((result: FileUploadResult) => void) | undefined | null; // return false to accept
}

export enum FileUploadDesign {
  Photos,
  Documents,
}

export enum FileUploadDisplayVariant {
  Grid = 'grid',
  List = 'List',
}

const FileUploadComponent: FC<FileUploadProps> = ({
  disabled,
  id,
  showErrors,
  maxFileCount,
  messages,
  maxFileSize,
  design,
  variant,
  uploadInBatch,
  allowedFormats,
  files,
  text,
  hideFileName,
  buttonStyle,
  handleItemSelected,
  handleUpload,
}) => {
  const [filesState, setFilesState] = useState<FileLinkOrContent[]>([]);
  let fileInput: HTMLInputElement;
  const classes = useStyles();

  useEffect(() => {
    setFilesState(filesState);
  }, [disabled, files]);

  const validateFileUploadItem = (file: File): string[] => {
    const errors: string[] = [];
    const ctype = ContentTypeHelper.parseContentType(file.type);

    if (
      TypeHelper.isNullOrUndefined(ctype) ||
      (allowedFormats && !allowedFormats.includes(ctype!))
    ) {
      if (allowedFormats) {
        const types = allowedFormats
          .map((t) => ContentTypeHelper.getExtensions(t).join(', '))
          .join(', ');
        errors.push(`${file.name}: ${messages.invalidFileType.replace('{types}', types)}`);
      } else {
        errors.push(`${file.name}: ${messages.missingFileType}`);
      }
    }

    if (file.size > maxFileSize) {
      errors.push(
        `${file.name}: ${messages.maxFileSizeExceeded.replace(
          '{size}',
          Math.floor(maxFileSize / 0x100000).toString(),
        )}`,
      );
    }

    return errors;
  };

  const validateFileUploadBatch = (evt: React.ChangeEvent<HTMLInputElement>): string[] => {
    const { target } = evt;
    const errors: string[] = [];

    if (!target.files || target.files.length === 0) {
      errors.push(messages.noFiles);
    } else {
      const replace = maxFileCount === 1;

      if ((replace ? 0 : filesState.length) + target.files.length > maxFileCount) {
        errors.push(messages.maxFileCountExceeded.replace('{count}', maxFileCount.toString()));
      }

      // tslint:disable-next-line
      for (let i = 0; i < target.files.length; i++) {
        for (const err of validateFileUploadItem(target.files[i])) {
          errors.push(err);
        }
      }
    }

    return errors;
  };

  const processFile = async (file: File, newFiles: Content[]): Promise<void> => {
    const ctype = ContentTypeHelper.parseContentType(file.type);

    newFiles.push({
      contentRef: { contentType: ctype!, name: file.name },
      size: file.size,
      data: file,
      dataBase64: await ContentTypeHelper.convertBlobToBase64(file),
    });
  };

  const handleFileUploaded = async (
    evt: React.ChangeEvent<HTMLInputElement> | any /* for testing */,
  ): Promise<FileUploadResult> => {
    Logging.trace(`FileUpload(${id}.handleFileUploaded`);

    const target = evt.target!;
    const newFiles: Content[] = [];
    const errors = validateFileUploadBatch(evt); // if any errors, no files are uploaded (batch upload)
    const replace = maxFileCount === 1;

    if (errors.length === 0 || !uploadInBatch) {
      const promises = new Array<Promise<void>>(); // we have to wait for all the files to upload before setting state

      // upload (into state)
      const currentLength = replace ? 0 : filesState.length;

      // tslint:disable-next-line
      for (let i = 0; i < target.files!.length && currentLength + i < maxFileCount; i++) {
        // eslint-disable-next-line no-await-in-loop
        const file = await compressIfImage(target.files![i]);

        if (validateFileUploadItem(file).length === 0) {
          promises.push(processFile(file, newFiles));
        }
      }

      // let's wait until all the files are ready
      // can't wait for Promises, will fulfill after the test has been completed (since JS is single-threaded)
      await Promise.all(promises);
    }

    // reset it, so will fire change for uploading the same file again
    fileInput.value = '';

    // process result
    const allFiles = replace ? newFiles : [...filesState, ...newFiles];

    const result: FileUploadResult = {
      action: replace ? FileUploadAction.Replace : FileUploadAction.Add,
      success: newFiles.length > 0,
      partialSuccess: errors.length > 0 && newFiles.length > 0,
      errors,
      currentFiles: allFiles,
      addedFiles: newFiles,
      removedFiles: replace ? filesState : [],
    };

    const afterUpload = () => {
      if (handleUpload) {
        handleUpload(result);
      }

      if (errors.length > 0 && showErrors) {
        window.alert(errors.join('\r\n'));
      }
    };

    if (newFiles.length > 0) {
      setFilesState(allFiles);
      afterUpload();
    } else {
      afterUpload();
    }

    return result;
  };

  const removeFile = (index: number): boolean => {
    Logging.trace(`FileUpload(${id}).removeFile: @index: ${index}`);

    if (index < filesState.length) {
      const removed = filesState[index];
      const allFiles = TypeHelper.arrayRemoveAt(filesState, index);
      setFilesState(allFiles);
      if (handleUpload) {
        handleUpload({
          action: FileUploadAction.Remove,
          success: true,
          partialSuccess: false,
          currentFiles: allFiles,
          removedFiles: [removed],
          addedFiles: [],
          errors: [],
        });
      }
      return true;
    }
    return false;
  };

  const addFiles = () => {
    Logging.trace(`FileUpload(${id}).addFiles`);
    fileInput.click();
  };

  const renderPhotosInList = () => {
    const photos = new Array<JSX.Element>();
    const max = Math.min(filesState.length, maxFileCount - 1);

    for (let i = 0; i <= max; i++) {
      if (i < filesState.length) {
        const file = filesState[i];
        let src: string | undefined;
        const { contentRef } = file as Content;
        if (
          ContentTypeHelper.getCategory(contentRef && contentRef.contentType) !==
            ContentCategory.Video &&
          !((file as FileLink).contentType && (file as FileLink).contentType.startsWith('video'))
        ) {
          src = (file as FileLink).link || (file as Content).dataBase64!;
        }

        // thumbnail
        photos.push(
          <TableRow key={i} hover className={classes.thumbnailRow}>
            <TableCell>
              {src ? (
                <img src={src} alt="img" className={classes.thumbnailCell} />
              ) : (
                <div className={classes.videoThumbnailWrapper}>
                  <PlayArrow />
                </div>
              )}
            </TableCell>
            <TableCell align="right" onClick={(e) => removeFile(i)}>
              <AppIcon
                type={AppIconType.Cross}
                size={AppIconSize.Smaller}
                style={{ cursor: 'pointer' }}
              />
            </TableCell>
          </TableRow>,
        );
      } else if (i === filesState.length && !disabled) {
        // add
        photos.push(
          <TableRow key={i} hover className={classes.thumbnailRow}>
            <TableCell>
              <img src={PhotoLibAdd} className={classes.thumbnailCell} />
            </TableCell>
            <TableCell align="right" onClick={addFiles}>
              <AppIcon
                type={AppIconType.Add}
                size={AppIconSize.Smaller}
                style={{ cursor: 'pointer' }}
              />
            </TableCell>
          </TableRow>,
        );
      }
    }

    return (
      <div id={id}>
        <Table>
          <TableBody>{photos}</TableBody>
        </Table>
      </div>
    );
  };

  const renderPhotos = () => {
    const photos = new Array<JSX.Element>();
    const max = Math.min(filesState.length, maxFileCount - 1);

    for (let i = 0; i <= max; i++) {
      if (i < filesState.length) {
        const file = filesState[i];
        const src = (file as FileLink).link || (file as Content).dataBase64!;

        // thumbnail
        photos.push(
          <Grid item id={`${id}_slot${i}`} key={i} className={classes.photoCell}>
            <div
              onClick={() => {
                if (handleItemSelected) {
                  handleItemSelected(file);
                }
              }}
              style={{ cursor: handleItemSelected ? 'pointer' : 'auto' }}
            >
              <img src={src} className={classes.photoThumbnail} />
            </div>
            {/* <div className={classes.thumbnailFrame} /> -- hovering frame to make rounded corners */}
            <div className={classes.photoDeleteIcon} onClick={(e) => removeFile(i)} />
          </Grid>,
        );
      } else if (i === filesState.length) {
        // add
        photos.push(
          <Grid item id={`${id}_slot${i}`} key={i} className={classes.photoCell}>
            <div className={classes.photoThumbnailAdd} onClick={addFiles}>
              <img src={PhotoLibAdd} />
            </div>
          </Grid>,
        );
      } else {
        // empty
        // result.push(
        //    <Grid item id={`${id}_slot${i}`} key={i} className={classes.cell} >
        //        <div className={classes.cellEmpty} />
        //    </Grid >
        // );
      }
    }

    return (
      <Grid container id={id} className={classes.photoGrid}>
        {photos}
      </Grid>
    );
  };

  const renderDocumentsDownloadIcon = (file: FileLinkOrContent, index: number) => {
    const { link } = file as FileLink;

    return (
      <div style={{ marginTop: 16 }}>
        {/* Render link */}
        {link && !handleItemSelected && (
          <a
            href={link}
            target="_blank"
            className={classes.documentDownloadIcon}
            style={{
              borderRight: !disabled ? `1px solid ${color.dark800}` : '',
            }}
          >
            <AppIcon type={AppIconType.Cloud} size={AppIconSize.Smaller} />
          </a>
        )}
        {/* Render clickable div */}
        {handleItemSelected && (
          <span
            className={classes.documentDownloadIcon}
            style={{
              borderRight: !disabled ? `1px solid ${color.dark800}` : '',
            }}
            onClick={() => handleItemSelected(file)}
          >
            <AppIcon type={AppIconType.Cloud} size={AppIconSize.Smaller} />
          </span>
        )}
        {!disabled && (
          <span className={classes.documentRemoveIcon} onClick={() => removeFile(index)}>
            {' '}
            <AppIcon type={AppIconType.Cross} size={AppIconSize.Small} />
          </span>
        )}
      </div>
    );
  };

  const renderDocumentsClickableOverlay = (file: FileLinkOrContent) => {
    const { link } = file as FileLink;

    return (
      <div className={classes.documentItemOverlayContainer}>
        {/* Render link */}
        {link && !handleItemSelected && (
          <a href={link} target="_blank">
            <div className={classes.documentItemOverlay} />
          </a>
        )}
        {/* Render clickable div */}
        {handleItemSelected && (
          <div style={{ cursor: 'point' }} onClick={() => handleItemSelected(file)}>
            <div className={classes.documentItemOverlay} />
          </div>
        )}
      </div>
    );
  };

  const renderDocuments = () => {
    const documents = new Array<JSX.Element>();

    if (filesState.length > 0) {
      for (let i = 0; i < filesState.length; i++) {
        const file = filesState[i];

        // const src = (file as FileUploadFileLink).link || (file as Content).dataBase64!;
        const name =
          ((file as Content).contentRef
            ? (file as Content).contentRef.name
            : (file as FileLink).name) || `Document #${i + 1}`;

        documents.push(
          <div id={`${id}_slot${i}`} key={i}>
            <Input
              fullWidth
              disabled
              value={name}
              className={classes.documentItem}
              endAdornment={
                <InputAdornment position="end">
                  {renderDocumentsDownloadIcon(file, i)}
                </InputAdornment>
              }
            />
            {renderDocumentsClickableOverlay(file)}
          </div>,
        );
      }
    } else {
      documents.push(
        <div id={`${id}_slot0`} key={0}>
          <Input fullWidth disabled value="" className={classes.documentItem} />
        </div>,
      );
    }

    return (
      <ThemeProvider theme={cyanGrayTheme}>
        <div id={id} className={classes.documentGrid}>
          {!disabled && (
            <div>
              <Button
                id="browse"
                color="primary"
                className={`${classes.documentBrowseButton} ${buttonStyle || null}`}
                onClick={addFiles}
              >
                {text || `Browse`}
              </Button>
            </div>
          )}
          {!hideFileName && <div className={classes.documentItemContainer}>{documents}</div>}
        </div>
      </ThemeProvider>
    );
  };
  const renderItems = () => {
    switch (design) {
      case FileUploadDesign.Photos:
        return variant === FileUploadDisplayVariant.List ? renderPhotosInList() : renderPhotos();

      case FileUploadDesign.Documents:
        return renderDocuments();

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderItems()}
      <input
        id={`${id}_input`}
        type="file"
        style={{ display: 'none' }}
        multiple
        ref={(elem) => {
          if (elem) {
            fileInput = elem;
            elem.value = '';
          }
        }}
        onChange={handleFileUploaded}
      />
    </div>
  );
};

// component with styles
export const FileUpload = FileUploadComponent;
