/* eslint-disable import/prefer-default-export */
export const LABELS = {
  SEARCH_TITLE: 'Properties',
  SEARCH_SUBTITLE: "Here are the properties you'll be submitting an application for ",
  SEARCH_TEXT: 'Search for properties to add to your application',
  SEARCHBAR_PLACEHOLDER_TEXT: 'Search for additional properties to add to your application ...',
  NOT_FOUND: 'No matching properties found',
  HELP_TITLE: 'Search to add your property',
  HELP_TEXT:
    'To get started with your application please use the search bar above to identify the property you are applying for today',
  SEARCH: 'Search',
  LOADING: 'Searching...',
};
