import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  noApplicationsTitle: {
    marginTop: theme.units.spacing * 8,
    marginBottom: theme.units.spacing * 4,
  },
  noApplicationsContainer: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDrafts: {
    marginBottom: theme.units.spacing * 6,
  },
  createNew: {
    minWidth: 40,
    backgroundColor: theme.colors.filterBackground,
    borderColor: theme.colors.filterBackground,
    color: theme.colors.grey,
  },
});
