import { takeEvery, put } from 'redux-saga/effects';
import { LoadingIndicatorActions } from '../actions/LoadingIndicatorAction';
import {
  CustomTermsHeaderAction,
  CustomTermsHeaderPayload,
  CustomTermsHeaderResponse,
  CustomTermsHeaderActions,
  CustomTermsHeaderActionTypes,
} from '../actions/CustomTermsHeaderActions';
import CustomTermsHeaderService from '../../services/customServices/CustomTermsHeaderService';

export function* getCustomTermsHeaderWorker(action: CustomTermsHeaderAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as CustomTermsHeaderPayload;
    const response: CustomTermsHeaderResponse = yield CustomTermsHeaderService.getCustomTermsHeader(
      data,
    );
    const config = response && response.config ? response.config : null;
    localStorage.setItem('config', JSON.stringify(config));
    yield put(CustomTermsHeaderActions.postCustomTermsHeaderSuccess(response));
  } catch (error) {
    yield put(CustomTermsHeaderActions.postCustomTermsHeaderError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

function* customTermsHeaderWatcher() {
  yield takeEvery(
    CustomTermsHeaderActionTypes.GET_TERMS_REQUEST,
    (action: CustomTermsHeaderAction) =>
      getCustomTermsHeaderWorker({
        type: action.type,
        data: action.data,
      }),
  );
}

export default customTermsHeaderWatcher;
