import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  wheelContainer: {
    position: 'relative',
    transition: 'all 0.6s cubic-bezier(0.4, 0.01, 0, 1.38)',
    width: 65,
    height: 65,
    flexShrink: 0,
  },
  pie: {
    transform: 'rotate(90deg)',
    transition: 'all 0.6s cubic-bezier(0.4, 0.01, 0, 1.38)',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  middle: {
    position: 'absolute',
    width: '80%',
    height: '80%',
    background: 'white',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    fontSize: 16,
    fontWeight: 500,
    color: theme.colors.secondary,
  },
});
