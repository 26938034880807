/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC, useState, useEffect } from 'react';
import { Input, InputAdornment } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import { compressIfImage } from '../../helper/FileUploadHelper';
import { useStyles } from './FileUploadStyles';
import addPhotoIcon from '../../assets/add-a-photo-24-px.svg';
import addFileIcon from '../../assets/attach-file-24-px.svg';
import cancelIcon from '../../assets/cancelIcon.svg';
import { FileUploadActions } from '../../store/actions/FileUploadAction';
import { ApplicationState } from '../../store/RootReducers';
import { LABELS } from './FileUploadConstants';
import { FileLoadingSpinnerComponent } from '../../container/fileUploaderSpinner/FileUploadSpinner';
import { FILE_SIZE_MAX } from '../../constants/AppConstants';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';

interface FileUploadProps {
  index: number;
  isMobile: boolean;
  fileNameFromParent: string;
  fileLink: string | undefined;
  isDisabled?: boolean;
  incomeType?: string;
  error?: boolean;
  disableFlag?: boolean;
  linkStateSetter: (
    fileLink: string,
    fileName: string,
    index?: number,
    incomeType?: string,
  ) => void;
  linkStateRemover: (index?: number, incomeType?: string) => void;
  uploadFile?: (data: File) => void;
  postFile: (data: any) => void;
  deleteFile: (key: any) => void;
  resetFileState: () => void;
}

const FileUpload: FC<FileUploadProps> = ({
  index,
  isMobile,
  fileNameFromParent,
  fileLink,
  isDisabled,
  incomeType,
  error,
  disableFlag,
  linkStateSetter,
  linkStateRemover,
  uploadFile,
  postFile,
  deleteFile,
  resetFileState,
}) => {
  const [fileInput, setFileInput] = useState<any>('');
  const [flag, setFlag] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(fileNameFromParent);
  const classes = useStyles();

  useEffect(() => {
    if (flag) {
      if (index || index === 0) {
        linkStateSetter(fileLink!, fileName, index, incomeType);
      } else {
        linkStateSetter(fileLink!, fileName);
      }
      resetFileState();
      setFlag(false);
    }
  }, [fileLink, index, incomeType, resetFileState]);

  const handleFileUpload = async (e: any) => {
    if (get(e, `target.files[0].name`, false)) {
      const compressedFile = await compressIfImage(e.target.files[0]);
      if (uploadFile) {
        uploadFile(compressedFile);
      } else {
        postFile(compressedFile);
      }
      setFlag(true);
      setFileName(compressedFile.size < FILE_SIZE_MAX ? compressedFile.name : '');
    }
  };

  const clearFilename = () => {
    deleteFile(fileName);
    if (index || index === 0) {
      linkStateRemover(index, incomeType); // for additional applicants
    } else {
      linkStateRemover(); // for primary applicants
    }

    setFileName('');
    setFileInput(null);
  };

  return (
    <>
      <Input
        className={classes.inputFileStyle}
        disabled
        placeholder={LABELS.NO_PLACEHOLDER}
        value={fileName || fileLink ? fileNameFromParent : ''}
        disableUnderline={isDisabled}
        onMouseDown={() => fileInput.click()}
        endAdornment={
          <InputAdornment position="end">
            <Input
              name="attachment"
              type="file"
              classes={{ root: classes.root }}
              style={{ display: 'none' }}
              id="id_input"
              inputRef={(elem) => {
                if (elem) {
                  setFileInput(elem);
                  elem.value = '';
                }
              }}
              onChange={handleFileUpload}
            />

            {flag && !disableFlag ? (
              <FileLoadingSpinnerComponent />
            ) : !fileNameFromParent ? (
              !isMobile ? (
                <>
                  <img
                    src={addFileIcon}
                    alt="upload file"
                    className={classes.fileImage}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      fileInput.click();
                    }}
                  />
                  {error && (
                    <img
                      style={{ marginLeft: 8, marginRight: '-2px' }}
                      src={IncompleteIcon}
                      alt=""
                    />
                  )}
                </>
              ) : (
                <img
                  src={addPhotoIcon}
                  alt="upload file"
                  className={classes.fileImage}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    fileInput.click();
                  }}
                />
              )
            ) : (
              <div
                onMouseDown={(e) => {
                  e.stopPropagation();
                  if (!isDisabled) {
                    clearFilename();
                  }
                }}
                style={{ display: 'flex' }}
              >
                <img src={cancelIcon} alt="delete file" className={classes.cancelIcon} />
                <img
                  style={{ marginLeft: 8, marginRight: '-2px', width: 25, height: 25 }}
                  src={CompletedIcon}
                  alt=""
                />
              </div>
            )}
          </InputAdornment>
        }
        type="text"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postFile: (data: File) => {
    dispatch(FileUploadActions.postFileUploadStart({ file: data, id: 'id' }));
  },
  deleteFile: (key: string) => {
    dispatch(FileUploadActions.deleteFile({ key }));
  },
  resetFileState: () => dispatch(FileUploadActions.resetFileState()),
});

const mapStateToProps = (state: ApplicationState) => ({
  fileLink: state.fileUpload.fileUpload as string | undefined,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
