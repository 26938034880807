import { StepLabel, StepIcon } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const StyledStepLabel = withStyles({
  label: {
    marginTop: `0.4rem !important`,
    fontSize: `0.9rem !important`,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: `0.75rem !important`,
    },
    color: theme.colors.grey,
  },
  completed: {
    color: `${theme.colors.sortedPurple} !important`,
  },
  active: {
    color: `${theme.colors.sortedPurple} !important`,
  },
})(StepLabel);

export const StyledStepIcon = withStyles({
  root: {
    fontSize: `1.45rem !important`,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: `1.25rem !important`,
    },
    color: theme.colors.grey,
  },
  completed: {
    color: `${theme.colors.sortedPurple} !important`,
  },
  active: {
    color: `${theme.colors.sortedPurple} !important`,
  },
})(StepIcon);

export const useStyles = makeStyles({
  progressBarContainer: {
    position: 'relative',
    margin: 'auto',
    top: '-38px',
    height: '75px',
    borderRadius: theme.units.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  innerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  progressBarWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  line: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.colors.borderColor,
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  stepIcon: {
    width: '24px',
    height: '24px',
    borderRadius: 5000,
    color: theme.colors.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.label,
    marginBottom: 4,
    flexShrink: 0,
    fontSize: '15px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '20px',
      height: '20px',
      fontSize: '14px',
    },
  },
  activeIcon: {
    backgroundColor: theme.colors.sortedPurple,
  },
  stepLabel: {
    textAlign: 'center',
    color: theme.colors.label,
    fontSize: '15px',
  },
  activeLabel: {
    color: theme.colors.sortedPurple,
  },
});
