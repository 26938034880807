import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { pageContainer, primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  welcomeBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: 300,
    borderRadius: theme.units.borderRadius,
    overflow: 'hidden',
  },
  welcomeLeftContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.units.spacing * 4,
    zIndex: 3,
  },
  welcomeTitle: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 6,
    marginLeft: theme.units.spacing * 2,
  },
  welcomeSubtitle: {
    marginBottom: 'auto',
    marginLeft: theme.units.spacing * 2,
    fontSize: theme.font.l,
    maxWidth: 700,
    lineHeight: 1.5,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: 600,
    },
  },
  welcomeButton: {
    height: 30,
    minWidth: 120,
    marginBottom: theme.units.spacing * 4,
  },
  welcomeImageBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  welcomeLeftImage: {
    position: 'absolute',
    width: 'calc(100% - 220px)',
    height: '100%',
    objectFit: 'cover',
    zIndex: 2,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      width: '80%',
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      width: '100%',
    },
  },
  welcomeRightImage: {
    position: 'absolute',
    right: 0,
    width: '50%',
    maxWidth: 500,
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
    zIndex: 1,
  },
  stepsContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: theme.units.spacing * 12,
    padding: theme.units.spacing * 4,
    maxWidth: 600,
    width: '100%',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      maxWidth: 500,
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: 400,
    },
    [`@media (max-width: ${SIZES.lg - 380}px)`]: {
      display: 'none',
    },
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepIconContainer: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.lightFaded,
    borderRadius: 5000,
  },
  stepIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
  },
  stepText: {
    marginTop: theme.units.spacing * 2,
    textAlign: 'center',
  },
});
