import * as React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ApplicationState } from '../../store/RootReducers';

interface FileLoadingSpinnerProps {
  readonly isUploading: boolean;
}

export const ColorCircularProgress = withStyles({
  root: {
    color: 'rgb(0, 159, 179)',
  },
})(CircularProgress);

export const FileLoadingSpinner = (props: FileLoadingSpinnerProps) => (
  <div>{props.isUploading ? <ColorCircularProgress size="20px" thickness={5} /> : ''}</div>
);

export const mapStateToProps = (state: ApplicationState) => ({
  isUploading: state.fileUploadingSpinner.isUploading,
});
export const FileLoadingSpinnerComponent = connect(mapStateToProps)(FileLoadingSpinner as any);
