import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  RDSContainer: {
    margin: 'auto',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    position: 'relative',
    marginTop: '-15px',
  },
  titleContainer: {
    padding: '30px',
    paddingBottom: '0px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: '36px',
    fontWeight: 300,
    color: theme.colors.sortedPurple,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      fontSize: '28px',
    },
  },
  subtitle: {
    marginTop: '15px',
    fontSize: '20px',
    fontWeight: 100,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  contentContainer: {
    padding: '30px',
    paddingTop: '15px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '1000px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    color: theme.colors.black87,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
      marginRight: '15px',
    },
  },
  primaryButton: {
    marginLeft: '30px',
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sortedPurple,
    borderRadius: '6px',
    color: theme.colors.light,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '0px',
      width: '100%',
    },
  },
  ownershipDetails: {
    borderRadius: 6,
    border: `${theme.colors.borderColor} 2px solid`,
    padding: 30,
    marginTop: '15px',
  },
  ownershipDetailRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  ownershipDetailkey: {
    fontSize: '18px',
    fontWeight: 500,
  },
  ownershipDetailValue: {
    fontSize: '18px',
    fontWeight: 100,
    textAlign: 'right',
  },
});
