/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';

const MOBILE_WIDTH = 86;
const DESKTOP_WIDTH = 320;

export const useStyles = makeStyles({
  menuContainer: {
    width: DESKTOP_WIDTH,
    minHeight: 'calc(100vh - 87px)',
    borderRight: `1px solid ${theme.colors.borderColorLight}`,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      width: MOBILE_WIDTH,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100vw',
      minHeight: 0,
      height: MOBILE_WIDTH,
      borderRight: 'none',
      borderTop: `1px solid ${theme.colors.borderColorLight}`,
    },
    flexShrink: 0,
    transition: 'all 0.3s ease 0.1s',
  },
  hide: {
    display: 'none',
  },
  collapsed: {
    width: MOBILE_WIDTH,
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100vw',
      height: MOBILE_WIDTH,
      borderRight: 'none',
      borderTop: `1px solid ${theme.colors.borderColorLight}`,
    },
    transition: 'all 0.3s ease 0.1s',
  },
  linksWrapper: {
    height: '100%',
  },
  links: {
    paddingTop: 140,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingTop: 0,
      flexDirection: 'row',
    },
  },
  linkItem: {
    marginBottom: theme.units.spacing * 6,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    maxWidth: 200,
    height: MOBILE_WIDTH,
    borderBottom: `3px solid ${theme.colors.light}`,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginBottom: 0,
    },
  },
  linkItemActive: {
    marginBottom: theme.units.spacing * 6,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    maxWidth: 200,
    height: MOBILE_WIDTH,
    borderBottom: `3px solid ${theme.colors.error}`,
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginBottom: 0,
    },
  },
  link: {
    color: theme.colors.black87,
    fontSize: theme.font.l,
    fontWeight: getFonts('Medium'),
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      display: 'none',
    },
  },
  icon: {
    width: theme.units.iconSize * 1.25,
    height: theme.units.iconSize * 1.25,
    objectFit: 'contain',
    objectPosition: 'center',
    margin: 'auto',
    display: 'none',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      display: 'block',
    },
  },
  collapsedShowIcon: {
    display: 'block',
  },
  collapsedHideLink: {
    display: 'none',
  },
});
