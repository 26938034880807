import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    // display: 'flex',
    // alignItems: 'center',
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.borderColor} 2px solid`,
    backgroundColor: theme.colors.inputBackground,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    fontFamily: 'GT Walsheim',
    margin: theme.units.spacing * 2,
    marginLeft: theme.units.spacing * 3,
    fontSize: theme.font.m,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    padding: 0,
  },
});
