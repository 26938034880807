// Country names object using 3-letter country codes to reference country name
// ISO 3166 Alpha-3 Format: [3 letter Country Code]: [Country Name]
// Alphabetical by Country Name
const suggestions = [
  { code: 'AFG', label: 'Afghanistan' },
  { code: 'ALB', label: 'Albania' },
  { code: 'DZA', label: 'Algeria' },
  { code: 'ASM', label: 'American Samoa' },
  { code: 'AND', label: 'Andorra' },
  { code: 'AGO', label: 'Angola' },
  { code: 'AIA', label: 'Anguilla' },
  { code: 'ATA', label: 'Antarctica' },
  { code: 'ATG', label: 'Antigua and Barbuda' },
  { code: 'ARG', label: 'Argentina' },
  { code: 'ARM', label: 'Armenia' },
  { code: 'ABW', label: 'Aruba' },
  { code: 'AUS', label: 'Australia' },
  { code: 'AUT', label: 'Austria' },
  { code: 'AZE', label: 'Azerbaijan' },
  { code: 'BHS', label: 'Bahamas (the)' },
  { code: 'BHR', label: 'Bahrain' },
  { code: 'BGD', label: 'Bangladesh' },
  { code: 'BRB', label: 'Barbados' },
  { code: 'BLR', label: 'Belarus' },
  { code: 'BEL', label: 'Belgium' },
  { code: 'BLZ', label: 'Belize' },
  { code: 'BEN', label: 'Benin' },
  { code: 'BMU', label: 'Bermuda' },
  { code: 'BTN', label: 'Bhutan' },
  { code: 'BOL', label: 'Bolivia (Plurinational State of)' },
  { code: 'BES', label: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BIH', label: 'Bosnia and Herzegovina' },
  { code: 'BWA', label: 'Botswana' },
  { code: 'BVT', label: 'Bouvet Island' },
  { code: 'BRA', label: 'Brazil' },
  { code: 'IOT', label: 'British Indian Ocean Territory (the)' },
  { code: 'BRN', label: 'Brunei Darussalam' },
  { code: 'BGR', label: 'Bulgaria' },
  { code: 'BFA', label: 'Burkina Faso' },
  { code: 'BDI', label: 'Burundi' },
  { code: 'CPV', label: 'Cabo Verde' },
  { code: 'KHM', label: 'Cambodia' },
  { code: 'CMR', label: 'Cameroon' },
  { code: 'CAN', label: 'Canada' },
  { code: 'CYM', label: 'Cayman Islands (the)' },
  { code: 'CAF', label: 'Central African Republic (the)' },
  { code: 'TCD', label: 'Chad' },
  { code: 'CHL', label: 'Chile' },
  { code: 'CHN', label: 'China' },
  { code: 'CXR', label: 'Christmas Island' },
  { code: 'CCK', label: 'Cocos (Keeling) Islands (the)' },
  { code: 'COL', label: 'Colombia' },
  { code: 'COM', label: 'Comoros (the)' },
  { code: 'COD', label: 'Congo (the Democratic Republic of the)' },
  { code: 'COG', label: 'Congo (the)' },
  { code: 'COK', label: 'Cook Islands (the)' },
  { code: 'CRI', label: 'Costa Rica' },
  { code: 'HRV', label: 'Croatia' },
  { code: 'CUB', label: 'Cuba' },
  { code: 'CUW', label: 'Curaçao' },
  { code: 'CYP', label: 'Cyprus' },
  { code: 'CZE', label: 'Czechia' },
  { code: 'CIV', label: "Cote d'Ivoire" },
  { code: 'DNK', label: 'Denmark' },
  { code: 'DJI', label: 'Djibouti' },
  { code: 'DMA', label: 'Dominica' },
  { code: 'DOM', label: 'Dominican Republic (the)' },
  { code: 'ECU', label: 'Ecuador' },
  { code: 'EGY', label: 'Egypt' },
  { code: 'SLV', label: 'El Salvador' },
  { code: 'GNQ', label: 'Equatorial Guinea' },
  { code: 'ERI', label: 'Eritrea' },
  { code: 'EST', label: 'Estonia' },
  { code: 'SWZ', label: 'Eswatini' },
  { code: 'ETH', label: 'Ethiopia' },
  { code: 'FLK', label: 'Falkland Islands (the) [Malvinas]' },
  { code: 'FRO', label: 'Faroe Islands (the)' },
  { code: 'FJI', label: 'Fiji' },
  { code: 'FIN', label: 'Finland' },
  { code: 'FRA', label: 'France' },
  { code: 'GUF', label: 'French Guiana' },
  { code: 'PYF', label: 'French Polynesia' },
  { code: 'ATF', label: 'French Southern Territories (the)' },
  { code: 'GAB', label: 'Gabon' },
  { code: 'GMB', label: 'Gambia (the)' },
  { code: 'GEO', label: 'Georgia' },
  { code: 'DEU', label: 'Germany' },
  { code: 'GHA', label: 'Ghana' },
  { code: 'GIB', label: 'Gibraltar' },
  { code: 'GRC', label: 'Greece' },
  { code: 'GRL', label: 'Greenland' },
  { code: 'GRD', label: 'Grenada' },
  { code: 'GLP', label: 'Guadeloupe' },
  { code: 'GUM', label: 'Guam' },
  { code: 'GTM', label: 'Guatemala' },
  { code: 'GGY', label: 'Guernsey' },
  { code: 'GIN', label: 'Guinea' },
  { code: 'GNB', label: 'Guinea-Bissau' },
  { code: 'GUY', label: 'Guyana' },
  { code: 'HTI', label: 'Haiti' },
  { code: 'HMD', label: 'Heard Island and McDonald Islands' },
  { code: 'VAT', label: 'Holy See (the)' },
  { code: 'HND', label: 'Honduras' },
  { code: 'HKG', label: 'Hong Kong' },
  { code: 'HUN', label: 'Hungary' },
  { code: 'ISL', label: 'Iceland' },
  { code: 'IND', label: 'India' },
  { code: 'IDN', label: 'Indonesia' },
  { code: 'IRN', label: 'Iran (Islamic Republic of)' },
  { code: 'IRQ', label: 'Iraq' },
  { code: 'IRL', label: 'Ireland' },
  { code: 'IMN', label: 'Isle of Man' },
  { code: 'ISR', label: 'Israel' },
  { code: 'ITA', label: 'Italy' },
  { code: 'JAM', label: 'Jamaica' },
  { code: 'JPN', label: 'Japan' },
  { code: 'JEY', label: 'Jersey' },
  { code: 'JOR', label: 'Jordan' },
  { code: 'KAZ', label: 'Kazakhstan' },
  { code: 'KEN', label: 'Kenya' },
  { code: 'KIR', label: 'Kiribati' },
  { code: 'PRK', label: "Korea (the Democratic People's Republic of)" },
  { code: 'KOR', label: 'Korea (the Republic of)' },
  { code: 'KWT', label: 'Kuwait' },
  { code: 'KGZ', label: 'Kyrgyzstan' },
  { code: 'LAO', label: "Lao People's Democratic Republic (the)" },
  { code: 'LVA', label: 'Latvia' },
  { code: 'LBN', label: 'Lebanon' },
  { code: 'LSO', label: 'Lesotho' },
  { code: 'LBR', label: 'Liberia' },
  { code: 'LBY', label: 'Libya' },
  { code: 'LIE', label: 'Liechtenstein' },
  { code: 'LTU', label: 'Lithuania' },
  { code: 'LUX', label: 'Luxembourg' },
  { code: 'MAC', label: 'Macao' },
  { code: 'MDG', label: 'Madagascar' },
  { code: 'MWI', label: 'Malawi' },
  { code: 'MYS', label: 'Malaysia' },
  { code: 'MDV', label: 'Maldives' },
  { code: 'MLI', label: 'Mali' },
  { code: 'MLT', label: 'Malta' },
  { code: 'MHL', label: 'Marshall Islands (the)' },
  { code: 'MTQ', label: 'Martinique' },
  { code: 'MRT', label: 'Mauritania' },
  { code: 'MUS', label: 'Mauritius' },
  { code: 'MYT', label: 'Mayotte' },
  { code: 'MEX', label: 'Mexico' },
  { code: 'FSM', label: 'Micronesia (Federated States of)' },
  { code: 'MDA', label: 'Moldova (the Republic of)' },
  { code: 'MCO', label: 'Monaco' },
  { code: 'MNG', label: 'Mongolia' },
  { code: 'MNE', label: 'Montenegro' },
  { code: 'MSR', label: 'Montserrat' },
  { code: 'MAR', label: 'Morocco' },
  { code: 'MOZ', label: 'Mozambique' },
  { code: 'MMR', label: 'Myanmar' },
  { code: 'NAM', label: 'Namibia' },
  { code: 'NRU', label: 'Nauru' },
  { code: 'NPL', label: 'Nepal' },
  { code: 'NLD', label: 'Netherlands (the)' },
  { code: 'NCL', label: 'New Caledonia' },
  { code: 'NZL', label: 'New Zealand' },
  { code: 'NIC', label: 'Nicaragua' },
  { code: 'NER', label: 'Niger (the)' },
  { code: 'NGA', label: 'Nigeria' },
  { code: 'NIU', label: 'Niue' },
  { code: 'NFK', label: 'Norfolk Island' },
  { code: 'MNP', label: 'Northern Mariana Islands (the)' },
  { code: 'NOR', label: 'Norway' },
  { code: 'OMN', label: 'Oman' },
  { code: 'PAK', label: 'Pakistan' },
  { code: 'PLW', label: 'Palau' },
  { code: 'PSE', label: 'Palestine, State of' },
  { code: 'PAN', label: 'Panama' },
  { code: 'PNG', label: 'Papua New Guinea' },
  { code: 'PRY', label: 'Paraguay' },
  { code: 'PER', label: 'Peru' },
  { code: 'PHL', label: 'Philippines (the)' },
  { code: 'PCN', label: 'Pitcairn' },
  { code: 'POL', label: 'Poland' },
  { code: 'PRT', label: 'Portugal' },
  { code: 'PRI', label: 'Puerto Rico' },
  { code: 'QAT', label: 'Qatar' },
  { code: 'MKD', label: 'Republic of North Macedonia' },
  { code: 'ROU', label: 'Romania' },
  { code: 'RUS', label: 'Russian Federation (the)' },
  { code: 'RWA', label: 'Rwanda' },
  { code: 'REU', label: 'Réunion' },
  { code: 'BLM', label: 'Saint Barthélemy' },
  { code: 'SHN', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'KNA', label: 'Saint Kitts and Nevis' },
  { code: 'LCA', label: 'Saint Lucia' },
  { code: 'MAF', label: 'Saint Martin (French part)' },
  { code: 'SPM', label: 'Saint Pierre and Miquelon' },
  { code: 'VCT', label: 'Saint Vincent and the Grenadines' },
  { code: 'WSM', label: 'Samoa' },
  { code: 'SMR', label: 'San Marino' },
  { code: 'STP', label: 'Sao Tome and Principe' },
  { code: 'SAU', label: 'Saudi Arabia' },
  { code: 'SEN', label: 'Senegal' },
  { code: 'SRB', label: 'Serbia' },
  { code: 'SYC', label: 'Seychelles' },
  { code: 'SLE', label: 'Sierra Leone' },
  { code: 'SGP', label: 'Singapore' },
  { code: 'SXM', label: 'Sint Maarten (Dutch part)' },
  { code: 'SVK', label: 'Slovakia' },
  { code: 'SVN', label: 'Slovenia' },
  { code: 'SLB', label: 'Solomon Islands' },
  { code: 'SOM', label: 'Somalia' },
  { code: 'ZAF', label: 'South Africa' },
  { code: 'SGS', label: 'South Georgia and the South Sandwich Islands' },
  { code: 'SSD', label: 'South Sudan' },
  { code: 'ESP', label: 'Spain' },
  { code: 'LKA', label: 'Sri Lanka' },
  { code: 'SDN', label: 'Sudan (the)' },
  { code: 'SUR', label: 'Suriname' },
  { code: 'SJM', label: 'Svalbard and Jan Mayen' },
  { code: 'SWE', label: 'Sweden' },
  { code: 'CHE', label: 'Switzerland' },
  { code: 'SYR', label: 'Syrian Arab Republic' },
  { code: 'TWN', label: 'Taiwan' },
  { code: 'TJK', label: 'Tajikistan' },
  { code: 'TZA', label: 'Tanzania, United Republic of' },
  { code: 'THA', label: 'Thailand' },
  { code: 'TLS', label: 'Timor-Leste' },
  { code: 'TGO', label: 'Togo' },
  { code: 'TKL', label: 'Tokelau' },
  { code: 'TON', label: 'Tonga' },
  { code: 'TTO', label: 'Trinidad and Tobago' },
  { code: 'TUN', label: 'Tunisia' },
  { code: 'TUR', label: 'Turkey' },
  { code: 'TKM', label: 'Turkmenistan' },
  { code: 'TCA', label: 'Turks and Caicos Islands (the)' },
  { code: 'TUV', label: 'Tuvalu' },
  { code: 'UGA', label: 'Uganda' },
  { code: 'UKR', label: 'Ukraine' },
  { code: 'ARE', label: 'United Arab Emirates (the)' },
  { code: 'GBR', label: 'United Kingdom of Great Britain and Northern Ireland (the)' },
  { code: 'UMI', label: 'United States Minor Outlying Islands (the)' },
  { code: 'USA', label: 'United States of America (the)' },
  { code: 'URY', label: 'Uruguay' },
  { code: 'UZB', label: 'Uzbekistan' },
  { code: 'VUT', label: 'Vanuatu' },
  { code: 'VEN', label: 'Venezuela (Bolivarian Republic of)' },
  { code: 'VNM', label: 'Viet Nam' },
  { code: 'VGB', label: 'Virgin Islands (British)' },
  { code: 'VIR', label: 'Virgin Islands (U.S.)' },
  { code: 'WLF', label: 'Wallis and Futuna' },
  { code: 'ESH', label: 'Western Sahara' },
  { code: 'YEM', label: 'Yemen' },
  { code: 'ZMB', label: 'Zambia' },
  { code: 'ZWE', label: 'Zimbabwe' },
  { code: 'ALA', label: 'Åland Islands' },
];

export default suggestions;
