import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  optionMenuIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 6,
    top: 10,
    color: 'grey',
  },
  red: {
    color: theme.colors.warning,
  },
  profileTitleContainer: {
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 3,
  },
  applicationProfileCard: {
    marginBottom: theme.units.spacing * 4,
  },
  profileRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  profileImage: {
    height: '50px',
    width: '50px',
    borderRadius: 500,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '40px',
      width: '40px',
    },
  },
  gravatar: {
    height: '65px',
    width: '65px',
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '24px',
    border: `${theme.colors.light} 1px solid`,
    flexShrink: 0,
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '50px',
      width: '50px',
    },
  },
  percentageWheel: {
    marginLeft: theme.units.spacing * 4,
  },
  applicationProfileDetails: {
    marginLeft: '20px',
    marginRight: 'auto',
  },
  applicationProfileName: {
    color: theme.colors.black87,
    fontSize: '18px',
    marginBottom: 4,
  },
  lineRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionText: {
    marginRight: theme.units.spacing * 4,
  },
  actionIcon: {
    width: theme.units.iconSize / 1.5,
    height: theme.units.iconSize / 1.5,
  },
  buttonMarginRight: {
    marginRight: theme.units.spacing * 4,
  },
  statusContainer: {
    marginLeft: theme.units.spacing * 4,
    borderRadius: 500,
    minWidth: '120px',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    color: theme.colors.black87,
  },
  completeLabel: {
    backgroundColor: theme.colors.completeLabel,
  },
  incompleteLabel: {
    backgroundColor: theme.colors.incompleteLabel,
  },
  statusText: {
    fontSize: 14,
  },
  addProfileIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '9px',
    marginRight: '10px',
  },
  addProfileText: {
    marginRight: 'auto',
    marginLeft: '20px',
    fontSize: '18px',
    color: theme.colors.black87,
  },
  arrow: {
    width: '30px',
    height: '30px',
    marginLeft: '15px',
    marginRight: '2px',
  },
  plusIcon: {
    width: '25px',
    height: '25px',
    marginLeft: '15px',
    marginRight: '5px',
  },
  verifiedBadge: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    border: `${theme.colors.darkGreen} solid 2px`,
    backgroundColor: theme.colors.lightGreen,
    width: 'auto',
    height: 30,
    marginLeft: theme.units.spacing * 4,
  },
  unverifiedBadge: {
    backgroundColor: theme.colors.lightRed,
    border: `${theme.colors.darkRed} solid 2px`,
  },
  verifiedIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginLeft: theme.units.spacing * 2,
    marginRight: theme.units.spacing * 2,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  verifiedText: {
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    color: theme.colors.secondary,
    marginRight: theme.units.spacing * 3,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
});
