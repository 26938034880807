import { makeStyles } from '@mui/styles';
import { color } from '../TenancyTheme';

export const useStyles = makeStyles({
  selectBoxRoot: {
    // margin: "10px 0px"
    marginBottom: 5,
  },
  selectBoxItem: {
    padding: '0 8px 0 8px',
  },
  selectBoxItemFirst: {
    padding: '0 8px 0 0',
  },
  selectBoxItemLast: {
    padding: '0 0 0 8px',
  },
  selectBoxButton: {
    'backgroundColor': color.dark700,
    'color': color.dark900,
    'textTransform': 'none',
    'height': 40,
    '&.active': {
      color: color.primary,
      backgroundColor: color.secondary,
    },
    '&.first': {},
  },
  multiSelectBoxButton: {
    'backgroundColor': color.dark700,
    'color': color.dark900,
    'textTransform': 'none',
    'height': 40,
    '&.active': {
      color: color.light,
      backgroundColor: color.secondary900,
    },
  },
  selectBoxCustomInput: {
    // height: 30,
    textAlign: 'center',
  },
  selectedBoxCustomInput: {
    textAlign: 'center',
    color: color.primary,
    backgroundColor: color.secondary,
    borderBottomWidth: '2px',
    borderBottomColor: color.dark,
    borderBottomStyle: 'solid',
  },
});
