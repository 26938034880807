/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { SIZES } from '../../constants/AppConstants';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  ntdPurchaseButton: {
    width: '100%',
    height: 48,
    boxSizing: 'border-box',
  },
  acknowledge: {
    marginTop: theme.units.spacing * 8,
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
    maxWidth: '600px',
  },
  statement: { marginBottom: theme.units.spacing * 4 },
  ackStatement: {
    fontWeight: getFonts('Medium'),
    color: theme.colors.subtitle,
    marginTop: -theme.units.spacing * 4,
  },
  buttonContainer: {
    marginTop: 'auto',
    width: '100%',
    maxWidth: '1000px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  primaryButton: {},
  secondaryButton: {
    marginRight: theme.units.spacing * 4,
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    color: theme.colors.label,
    border: `#ededed 2px solid`,
  },
  errorButton: {
    border: `${theme.colors.error} 2px solid`,
    backgroundColor: theme.colors.error,
    color: theme.colors.light,
  },
  step4Container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  defaultContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    borderBottom: `${theme.colors.borderColorLight} 2px solid`,
    paddingRight: theme.units.spacing,
    paddingTop: theme.units.spacing * 6,
    paddingBottom: theme.units.spacing * 6,
    marginTop: theme.units.spacing * 6,
  },
  viewInProfile: {
    marginTop: 'auto',
    width: 'auto',
    height: 30,
  },
  checks: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: '20px',
    paddingBottom: theme.units.spacing * 4,
    '& > li': {
      color: theme.colors.subtitle,
      fontWeight: getFonts('Medium'),
      fontSize: 18,
      marginBottom: theme.units.spacing * 2,
    },
  },
  addThis: {
    marginTop: theme.units.spacing * 4,
    color: theme.colors.secondary,
  },
  errorText: {
    marginBottom: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
    color: theme.colors.error,
    fontWeight: getFonts('Medium'),
    textAlign: 'center',
  },
});
