import {
  GetPaymentAuthKeyRequest,
  GetPaymentAuthKeyResponse,
  PaymentDetails,
  PostPaymentTokenRequest,
} from '../../services/remoteSigning/payment/Payment';
import { StoreAction } from '../StoreHelper';

export enum PaymentActionTypes {
  GET_PAYMENT_AUTH_KEY = 'GET_PAYMENT_AUTH_KEY',
  GET_PAYMENT_AUTH_KEY_REQUEST = 'GET_PAYMENT_AUTH_KEY_REQUEST',
  GET_PAYMENT_AUTH_KEY_SUCCESS = 'GET_PAYMENT_AUTH_KEY_SUCCESS',
  GET_PAYMENT_AUTH_KEY_ERROR = 'GET_PAYMENT_AUTH_KEY_ERROR',

  POST_PAYMENT_TOKEN = 'POST_PAYMENT_TOKEN',
  POST_PAYMENT_TOKEN_REQUEST = 'POST_PAYMENT_TOKEN_REQUEST',
  POST_PAYMENT_TOKEN_SUCCESS = 'POST_PAYMENT_TOKEN_SUCCESS',
  POST_PAYMENT_TOKEN_ERROR = 'POST_PAYMENT_TOKEN_ERROR',

  RESET_PAYMENT_ERROR = 'RESET_ERROR',
}

export type PaymentPayload =
  | GetPaymentAuthKeyRequest
  | PaymentDetails
  | PostPaymentTokenRequest
  | GetPaymentAuthKeyResponse
  | Error;

export type PaymentAction = StoreAction<PaymentActionTypes, PaymentPayload>;

export class PaymentActions {
  public static getPaymentAuthKeyRequest(data: GetPaymentAuthKeyRequest): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_REQUEST,
      data,
    };
  }
  public static getPaymentAuthKeySuccess(data: GetPaymentAuthKeyResponse): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_SUCCESS,
      data,
    };
  }
  public static getPaymentAuthKeyError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.GET_PAYMENT_AUTH_KEY_ERROR,
      data,
    };
  }
  public static postPaymentTokenRequest(data: PostPaymentTokenRequest): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_TOKEN_REQUEST,
      data,
    };
  }
  public static postPaymentTokenSuccess(data: PaymentDetails): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_TOKEN_SUCCESS,
      data,
    };
  }
  public static postPaymentTokenError(data: Error): PaymentAction {
    return {
      type: PaymentActionTypes.POST_PAYMENT_TOKEN_ERROR,
      data,
    };
  }
  public static resetPaymentError(): PaymentAction {
    return { type: PaymentActionTypes.RESET_PAYMENT_ERROR, data: null };
  }
}
