/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { globalStyles, primaryContent, secondaryContent } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  secondaryTile: globalStyles.secondaryTile as any,
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.light,
    alignItems: 'center',
    padding: '25px 30px',
  },
  title: {
    color: theme.colors.sortedPurple,
    fontSize: 28,
  },
  titleWrapper: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  questionMark: {
    position: 'relative',
    top: '1.5px',
    marginLeft: '8px',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  subtitle: {
    fontWeight: 'lighter',
    opacity: 0.87,
  },
  headerButton: {
    backgroundColor: theme.colors.light,
    border: `${theme.colors.grey} 2px solid`,
    borderRadius: '6px',
    padding: '8px 25px',
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  contentContainer: {
    padding: '30px',
    backgroundColor: theme.colors.light,
  },
  inputContainerStyle: {
    width: '100%',
    paddingBottom: '8px',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 4,
  },
  errorText: {
    color: theme.colors.error,
    fontWeight: 500,
    marginRight: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
  },
  resendButton: {
    backgroundColor: theme.colors.secondary,
    borderRadius: '6px',
    padding: '6px 15px',
    cursor: 'pointer',
    color: theme.colors.light,
    fontWeight: getFonts('Medium'),
  },
  forgotPassword: {
    color: theme.colors.sortedPurple,
    fontWeight: 300,
    width: 'max-content',
    cursor: 'pointer',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  secondaryActionContainer: {
    display: 'flex',
    justifyContent: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginTop: '30px',
    },
  },
  secondaryActionText: {
    fontWeight: 100,
  },
  secondaryAction: {
    marginLeft: '8px',
    color: theme.colors.sortedPurple,
    cursor: 'pointer',
  },
  loginButton: {
    backgroundColor: theme.colors.buttonPrimary,
    fontSize: 16,
    fontWeight: 300,
    border: 'none',
    borderRadius: '6px',
    padding: '15px 70px',
    color: theme.colors.light,
    cursor: 'pointer',
    textAlign: 'center',
    [`@media (max-width: ${SIZES.md}px)`]: {
      flex: 1,
      marginTop: '15px',
      padding: '15px',
      width: '100%',
    },
  },

  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 8,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      flexDirection: 'column',
    },
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.sm}px)`]: {
      marginLeft: 0,
    },
  },
  loginRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  signupRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  signupButton: {
    minWidth: 70,
    marginLeft: theme.units.spacing * 4,
  },
  subtitleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    maxWidth: 800,
    flexShrink: 0.7,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    maxWidth: 500,
    width: '100%',
    flexShrink: 1,

    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'none',
      marginLeft: 0,
      marginTop: theme.units.spacing * 8,
      maxWidth: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 64,
    marginTop: 24,
  },
  icon: {
    width: 40,
    height: 40,
    objectFit: 'contain',
  },
  primaryTitle: {
    marginLeft: theme.units.spacing * 4,
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  marginBottom: {
    marginBottom: theme.units.spacing * 4,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5000,
    width: 60,
    height: 60,
    marginBottom: theme.units.spacing * 4,
  },
  blogIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
  },
  image: {
    borderRadius: theme.units.borderRadius,
    width: '100%',
    height: 340,
    objectFit: 'cover',
    objectPosition: 'center',
    marginBottom: theme.units.spacing * 2,
  },
  welcomeBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: 180,
    borderRadius: theme.units.borderRadius,
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.units.spacing * 4,
  },
  welcomeLeftContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: theme.units.spacing * 4,
    padding: theme.units.spacing * 4,
    zIndex: 3,
  },
  welcomeTitle: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 6,
    marginLeft: theme.units.spacing * 2,
  },
  welcomeSubtitle: {
    maxWidth: 260,
  },
  welcomeImageBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  welcomeLeftImage: {
    position: 'absolute',
    width: '70%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 2,
    [`@media (max-width: 1300px)`]: {
      width: '90%',
    },
  },
  welcomeRightImage: {
    position: 'absolute',
    right: 0,
    width: '50%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
    zIndex: 1,
  },
});

export const OtpStyles = {
  input: {
    padding: '0.5rem',
    fontSize: theme.font.xl,
    fontWeight: getFonts('Medium'),
    color: theme.colors.inputText,
    borderRadius: theme.units.borderRadius / 2,
    border: `${theme.colors.borderColor} 2px solid`,
  },
  container: {
    gridGap: '0.5rem',
  },
};
