/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { FormControl, InputLabel, Input, FormHelperText } from '@mui/material';

/// <summary>
/// Value is string
/// </summary>
/* tslint:disable:cyclomatic-complexity */
const RenderInput = (data: any) => {
  const {
    field,
    form,
    type,
    label,
    disabled,
    labelStyle,
    overrideLabelStyles,
    overrideInputStyles,
    inputStyle,
    inputClassName,
    placeholder,
    maxLength,
    style,
    disableUnderline,
    customProps,
    inputProps,
    errorMessageMobileStyle,
    secondaryValidationMessage,
    errorMessageStyles,
    helperText,
    hidden,
  } = data;

  const hasError = !!field.error && !!field.touched;
  const { errorMessage } = field;
  const hasSecondaryError = !!field.secondaryValidationMessage;
  const highlightSecondaryError = !!field.secondaryValidationHighlight;

  return (
    <div className="item-render" style={field.itemRenderStyle}>
      <FormControl error={hasError} fullWidth>
        {label && (
          <InputLabel style={labelStyle} classes={overrideLabelStyles} htmlFor={field.name}>
            {label}
          </InputLabel>
        )}
        {!hidden && (
          <Input
            type={type}
            id={field.name}
            value={field.value || ''} // string
            onChange={(e) => {
              if (field.onChange) {
                field.onChange(e);
              }
              form.setFieldValue(field.name, e.target.value);
            }}
            onBlur={field.onBlur}
            disabled={disabled}
            classes={overrideInputStyles}
            // inputRef={(elem: HTMLInputElement) => { if (field.inputRef) { field.inputRef(elem); } }}
            style={style}
            // Browsers have no reasons to limit the year field to 4 digits by default.
            // So we should specify max attribute.
            inputRef={(e: any) => (e ? (e.max = '9999-12-31') : false)}
            inputProps={{
              style: inputStyle,
              className: inputClassName,
              placeholder,
              maxLength,
              onKeyPress: (e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              },
              ...inputProps,
            }}
            disableUnderline={disableUnderline}
            {...customProps}
          />
        )}
        {hasError && (
          <FormHelperText id={`${field.name}-error-text`} className={errorMessageMobileStyle}>
            {field.error}
          </FormHelperText>
        )}
        {hasSecondaryError && (
          <FormHelperText id={`${field.name}-error2-text`} error={highlightSecondaryError} required>
            {secondaryValidationMessage}
          </FormHelperText>
        )}
        {!hasError && !!helperText && (
          <FormHelperText id={`${field.name}-helper-text`}>{helperText}</FormHelperText>
        )}
        {errorMessage && !hasError && (
          <FormHelperText className={errorMessageStyles} id={`${field.name}-error-message`}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
/* tslint:enable */

export default RenderInput;
