/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { put, takeEvery } from 'redux-saga/effects';
import {
  GetDisclosureDetailsPayload,
  DisclosureDetailsResponse,
  DisclosureSignaturePostRequest,
} from '../../services/disclosure/Disclosure';
import {
  DisclosureAction,
  DisclosureActions,
  DisclosureActionTypes,
} from '../actions/DisclosureActions';
import DisclosureService from '../../services/disclosure/disclosureService';

function* getDisclosureDetailsWorker(action: DisclosureAction) {
  const { token, onSuccess, onError } = action.data as GetDisclosureDetailsPayload;
  try {
    const response: DisclosureDetailsResponse = yield DisclosureService.getDetails(token);
    if (onSuccess) {
      onSuccess();
    }
    yield put(DisclosureActions.getDisclosureDetailsSuccess(response));
  } catch (error) {
    if (onError) {
      onError();
    }
    yield put(DisclosureActions.getDisclosureDetailsError(error as Error));
  }
}

function* postDisclosureSignatureWorker(action: DisclosureAction) {
  const data = action.data as DisclosureSignaturePostRequest;
  const { disclosureFormRequest, signatures, onSuccess, onError } = data;
  try {
    const request: DisclosureSignaturePostRequest = {
      disclosureFormRequest,
      signatures,
    };
    yield DisclosureService.postSignature(request);
    if (onSuccess) {
      onSuccess();
    }
    yield put(DisclosureActions.postDisclosureSignSuccess());
  } catch (error) {
    if (onError) {
      onError();
    }
    yield put(DisclosureActions.postDisclosureSignError(error as Error));
  }
}

function* disclosureWatcher() {
  yield takeEvery(
    DisclosureActionTypes.GET_DISCLOSURE_DETAILS_REQUEST,
    (action: DisclosureAction) =>
      getDisclosureDetailsWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(
    DisclosureActionTypes.POST_DISCLOSURE_SIGNATURE_REQUEST,
    (action: DisclosureAction) => postDisclosureSignatureWorker(action),
  );
}

export default disclosureWatcher;
