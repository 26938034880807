/* eslint-disable import/prefer-default-export */
export const LABELS = {
  WELCOME: 'Welcome,',
  WELCOME_SUBTITLE: 'You are applying for this property',
  CANNOT_FIND_PROPERTY:
    'Can’t see your property? Log in or sign up below and using the search bar enter the property address',
  FORGOT_VIEW: 'Reset your password, to access your account and continue your application.',
  VERIFY_VIEW: 'Check your email to verify your account and continue your application.',
  DEFAULT_TEXT:
    'Log in or sign up to select the application you want to manage or to update your profile',
};
