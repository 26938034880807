import React, { FC, useEffect, useState } from 'react';
import { Paper, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LABELS, links } from './AllDoneConstants';
import { getSortedCode, openAndroidLink, openAppleLink } from './AllDoneUtils';
import { useStyles } from './AllDoneStyles';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SortedLogo from '../../../../assets/sorted_logo.svg';
import AppleStore from '../../../../assets/applestore.png';
import PlayStore from '../../../../assets/PlayStore.png';
import Oval from '../../../../assets/oval.png';
import AllDoneImg from '../../../../assets/alldone-img.png';
import AllDoneImgTA from '../../../../assets/alldone-imgTA.png';
import AllDoneImgNAX from '../../../../assets/alldone-imgNAX.png';
import wallet from '../../../../assets/wallet.png';
import SortedFooter from '../../../../component/sortedFooter/SortedFooter';
import House from '../../../../assets/setup-house.png';
import { theme } from '../../../../theme/Theme';

interface AllDoneProps {
  walletAmount: number;
  agencyCode: string;
}

const AllDone: FC<AllDoneProps> = ({ walletAmount, agencyCode }) => {
  const classes = useStyles();
  // const [showTerms, setShowTerms] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const getAppName = () => {
    switch (agencyCode) {
      case 'AU_AGCY':
        return 'Agency';
      case 'NAX':
        return 'Nelson Alexander';
      default:
        return 'Sorted';
    }
  };

  const appImg =
    agencyCode === 'NAX' ? AllDoneImgNAX : agencyCode === 'AU_AGCY' ? AllDoneImgTA : AllDoneImg;

  return (
    <div className={classes.footerContainer}>
      <div className={classes.root}>
        <div className={classes.backgroundContainer}>
          <div className={classes.backgroundIconWrapper}>
            <img src={SortedLogo} className={classes.backgroundIcon} alt="icon" />
          </div>
        </div>
        <div className={classes.contentContainer}>
          <Paper className={classes.paperBack}>
            <div className={classes.cardContentContainer}>
              <div className={classes.walletCreditsContainer}>
                <div className={classes.walletIconContainer}>
                  <AccountBalanceWalletOutlinedIcon className={classes.walletIcon} />
                </div>
                <div className={classes.walletDetails}>
                  <div className={classes.walletDetailTitle}>
                    ${walletAmount === 0 ? '25.00' : walletAmount.toFixed(2)}
                  </div>
                  <div className={classes.walletDetailText}>Promo credits</div>
                </div>
                <div className={classes.houseContainer}>
                  <img src={House} className={classes.houseIcon} />
                </div>
              </div>
              <div className={classes.yourCodeContainer}>
                <div className={classes.yourCodeTitle}>Your code</div>
                <div className={classes.yourCode}>{getSortedCode(walletAmount)}</div>
              </div>
              <div className={classes.yourCodeInfo}>
                Please ensure you write down or copy this code for later redemption
              </div>
            </div>
          </Paper>
          {/* This promotion is only for a few weeks at most, there is no need to use Labels or styles as it will be deleted soon */}
          {/* <Paper className={classes.paperBack}>
            <div className={classes.echoHeader}>
              <div className={classes.freeContainer}>
                <div className={classes.freeBig}>$25</div>
                <div className={classes.freeSmall}>.00</div>
              </div>
              <div className={classes.dotContainer}>
                <img className={classes.dotImage} src={wallet} alt="dot" />
              </div>
            </div>
            <div className={classes.echoContentContainer}>
              <div className={classes.successTitle}>Get rewarded!</div>
              <div className={classes.giveYou}>
                We'll give you $25 to spend on your first Sorted service when you sign up.
              </div>
              <div className={classes.echoSubtitle}>How to get your $25 credit</div>
            </div>
            <div className={classes.listDotRow}>
              <div className={classes.dot}>1</div>
              <div className={classes.echoText}>Download the Sorted app.</div>
            </div>
            <div className={classes.listDotRow}>
              <div className={classes.dot}>2</div>
              <div className={classes.echoText}>
                Set up Sorted electricity, Sorted internet, or both!
              </div>
            </div>
            <div className={classes.listDotRow}>
              <div className={classes.dot}>3</div>
              <div className={classes.echoText}>
                Apply your wallet credit to your first payment.
              </div>
            </div>
          </Paper> */}
          <Paper className={classes.paperBack} style={{ marginTop: '20px' }}>
            <div className={classes.cardContentContainer}>
              <div className={classes.successTitle}>{LABELS.DOWNLOAD}</div>
              <div className={classes.text}>{LABELS.DOWNLOAD_TEXT(getAppName())}</div>
            </div>
            <div className={classes.bottomContentContainer}>
              <div className={classes.leftButtonsContainer}>
                <img
                  src={AppleStore}
                  className={classes.button}
                  alt="app store"
                  onClick={() => openAppleLink()}
                />
                <img
                  src={PlayStore}
                  className={classes.button}
                  alt="app store"
                  onClick={() => openAndroidLink()}
                />
              </div>
              <div className={classes.rightContent}>
                <div className={classes.rightBgImgContainer}>
                  <img src={Oval} className={classes.rightBgImg} alt="bg" />
                </div>
                <div className={classes.rightImgContainer}>
                  <img src={appImg} className={classes.rightImg} alt="bg" />
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <SortedFooter />
    </div>
  );
};

export default AllDone;
