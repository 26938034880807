/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { Chip } from '@mui/material';
import { get, findKey } from 'lodash';
import { Formik, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { History, Location } from 'history';
import { routes } from '../../../../Routes';
import { ApplicationState } from '../../../../store/RootReducers';
import { useStyles } from './PropertiesStyles';
import { LABELS, VALIDATION_SCHEMA, INSPECTION_METHOD } from './PropertiesConstants';
import {
  Assets,
  PropertyDetailsResponse,
} from '../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import localStorage from '../../../../services/LocalStorage';
import { DashboardActions } from '../../../../store/actions/DashboardActions';
import { LandingScreenActions } from '../../../../store/actions/LandingScreenAction';
import SORTED_CIRCULAR_LOGO from '../../../../assets/logosCircle/sorted.png';
import bedrooms from '../../../../assets/bed.png';
import bathrooms from '../../../../assets/bath.png';
import carspaces from '../../../../assets/car.png';
import hot from '../../../../assets/hot.png';
import edit from '../../../../assets/edit.png';
import { getQueryParams } from '../../../../helper/URLHelper';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { extractState } from '../../../../helper/AustralianStateHelper';
import Input from '../../../../component/input/Input';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import InputDate from '../../../../component/inputDate/InputDate';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import propertyDefaultIcon from '../../../../assets/homePlaceholder.png';
import { isPropertiesValid } from './PropertiesUtils';
import { TypeHelper } from '../../../../helper/TypeHelper';
import moment from 'moment';

interface PropertiesProps {
  history: History;
  location: Location;
  selectedProperties: PropertyDetailsResponse[];
  properties: PropertyDetailsResponse[];
  applicationState: ApplicationState;
  propertyDetails: PropertyDetailsResponse;
  readonly?: boolean;
  assets: Assets | null | undefined;
  deleteProperty: (index: number) => void;
  postApplyDraft: (data: ApplicationState) => void;
  postFormData: (data: PropertyDetailsResponse[], onSubmit: () => void) => void;
  setSnackBarState: (value: boolean) => void;
  setSnackBarText: (value: string) => void;
}

const Properties: FC<PropertiesProps> = ({
  history,
  location,
  selectedProperties,
  properties,
  applicationState,
  propertyDetails,
  readonly,
  assets,
  deleteProperty,
  postApplyDraft,
  postFormData,
  setSnackBarState,
  setSnackBarText,
}) => {
  const classes = useStyles();
  const [draftCallFlag, setDraftCallFlag] = useState<boolean>(false);
  const [submissionTriggered, setSubmissionTriggered] = useState<boolean>(false);
  // TODO is this required anymore?
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');
  const initialProperties = useMemo<PropertyDetailsResponse[]>(() => {
    const result = selectedProperties || [];
    if (propertyDetails && !findKey(result, { refId: propertyDetails.refId })) {
      if (getQueryParams(location, 'rent')) {
        result.push({
          ...propertyDetails,
          ...{ rentalOffer: parseInt(getQueryParams(location, 'rent'), 10) },
          ...{ months: 0, years: 1 },
        });
      } else {
        result.push({
          ...propertyDetails,
          ...{ months: 0, years: 1 },
          ...{
            rentalOffer:
              !TypeHelper.isNullOrUndefined(propertyDetails.rentalOffer) &&
              !isNaN(propertyDetails.rentalOffer!)
                ? propertyDetails.rentalOffer!
                : propertyDetails.rent || undefined,
          },
        });
      }
    }
    return result;
  }, [selectedProperties, propertyDetails, location]);

  // This function is using closure to bind the formik's form prop to the setFieldFromOutside function
  // While rendering we call setForm(form  <- formik prop) to set the "form" value in this function

  const wrapperFunction = () => {
    let form: FormikProps<{
      properties: PropertyDetailsResponse[];
    }>;
    const setFieldValueFromOutside = (fieldName: string, value: PropertyDetailsResponse[]) => {
      const { properties: props } = form.values;
      for (let i = 0; i < value.length; i++) {
        value[i] =
          props.find((p: PropertyDetailsResponse) => p.refId === value[i].refId) || value[i];
      }
      form.setFieldValue(fieldName, value);
    };
    const setForm = (
      formikForm: FormikProps<{
        properties: PropertyDetailsResponse[];
      }>,
    ) => {
      form = formikForm;
    };
    return { setFieldValueFromOutside, setForm };
  };

  const { setFieldValueFromOutside, setForm } = wrapperFunction();

  // When a property is found, add it to the list of properties

  useEffect(() => {
    const result = selectedProperties || [];

    if (propertyDetails && !findKey(result, { refId: propertyDetails.refId })) {
      if (getQueryParams(location, 'rent')) {
        result.push({
          ...propertyDetails,
          ...{ rentalOffer: parseInt(getQueryParams(location, 'rent'), 10) },
          ...{ months: 0, years: 1 },
        });
      } else {
        result.push({
          ...propertyDetails,
          ...{ months: 0, years: 1 },
          ...{
            rentalOffer:
              !TypeHelper.isNullOrUndefined(propertyDetails.rentalOffer) &&
              !isNaN(propertyDetails.rentalOffer!)
                ? propertyDetails.rentalOffer!
                : propertyDetails.rent || undefined,
          },
        });
      }
    }

    setFieldValueFromOutside('properties', result);
  }, [propertyDetails, selectedProperties.length]);

  useEffect(() => {
    if (draftCallFlag && properties && properties.length) {
      if (!readonly) {
        postApplyDraft(applicationState);
      }
      setDraftCallFlag(false);
    }
  }, [draftCallFlag]);

  // Theres a delay with properties being saved so we are ensuring there are properties before redirecting to ensure the draft is saved
  useEffect(() => {
    if (submissionTriggered && properties && properties.length) {
      if (!readonly) {
        postApplyDraft(applicationState);
      }
      history.push(routes.applicationPreferences.new);
    }
  }, [properties, submissionTriggered]);

  const range = (start: number, end: number) => {
    const values = [];
    while (start <= end) {
      values.push(start.toString());
      // eslint-disable-next-line no-param-reassign
      start++;
    }
    return values;
  };
  const monthRange = range(0, LABELS.MONTH_RANGE);
  const yearRange = range(0, LABELS.YEAR_RANGE);

  return (
    <Formik
      enableReinitialize
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{ properties: initialProperties }}
      onSubmit={(data) => {
        postFormData(data.properties, () => {
          setSubmissionTriggered(true);
        });
      }}
      render={(form) => {
        const { values, handleSubmit, setFieldValue, setFieldTouched, isValid, errors, touched } =
          form;
        setForm(form);

        return (
          <>
            {values.properties.map((property: PropertyDetailsResponse, index: number) => (
              <Box parentStyles={classes.boxContainer} lightBorder>
                {values.properties[index].propertyStatus === 'ARCHIVED' && (
                  <div className={classes.overlay}>
                    <div className={classes.overlayText}>{LABELS.ARCHIVED}</div>
                    <Button
                      muted
                      onPress={() => {
                        setSnackBarState(true);
                        setSnackBarText('Property removed');
                        deleteProperty(index);

                        if (values.properties.length > 0) {
                          setDraftCallFlag(true);
                        }
                      }}
                    >
                      {LABELS.DELETE}
                    </Button>
                  </div>
                )}
                <div className={classes.applicationRow}>
                  <div className={classes.propertyContainer}>
                    <img
                      className={classes.property}
                      src={
                        property.photos && !!property.photos.length
                          ? property.photos[0]
                          : propertyDefaultIcon
                      }
                      alt="property"
                    />
                  </div>
                  <div className={classes.applicationDetails}>
                    <div className={classes.applicationDetailsMiddle}>
                      <div className={classes.propertyAddressContainer}>
                        <Text textVariant="title" parentStyles={classes.address1}>
                          {`${property.unitNumber ? `${property.unitNumber}/` : ''}${
                            property.streetNumber || ''
                          } ${property.streetName}`}
                        </Text>
                        <Text textVariant="info" parentStyles={classes.address2}>
                          {`${property.suburb}, ${property.state} ${property.postcode}`}
                        </Text>
                      </div>
                      <div className={classes.rentAndAgencyContainer}>
                        <div className={classes.agencyContainer}>
                          {assets && assets.circularLogo && (
                            <img
                              className={classes.agencyLogo}
                              onError={(e) => {
                                (e.target as HTMLImageElement).src = SORTED_CIRCULAR_LOGO;
                              }}
                              src={assets?.circularLogo}
                              alt="agency logo"
                            />
                          )}
                        </div>
                        <div className={classes.rentContainer}>
                          <div className={classes.rentRow}>
                            {get(touched, `properties[${index}]rentalOffer`) &&
                            get(errors, `properties[${index}]rentalOffer`) ? (
                              <Text textVariant="title" parentStyles={classes.rentError}>
                                {get(errors, `properties[${index}]rentalOffer`)}
                              </Text>
                            ) : (
                              <Text textVariant="title" parentStyles={classes.rent}>
                                {`${
                                  values.properties[index].rentalOffer
                                    ? `$${values.properties[index].rentalOffer}`
                                    : '-'
                                }`}
                              </Text>
                            )}
                          </div>

                          <Text textVariant="info" parentStyles={classes.rentInfo}>
                            {LABELS.RENT_PW}
                          </Text>
                        </div>
                        {!readonly && (
                          <div className={classes.deleteButtonContainer}>
                            <Button
                              outline
                              parentStyles={classes.deleteButton}
                              onPress={() => {
                                setSnackBarState(true);
                                setSnackBarText('Property removed');
                                deleteProperty(index);

                                if (values.properties.length > 0) {
                                  setDraftCallFlag(true);
                                }
                              }}
                            >
                              {LABELS.DELETE}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={classes.applicationDetailsFooter}>
                      <div className={classes.statsRow}>
                        {!!property.bedrooms && (
                          <div className={classes.stat}>
                            <img className={classes.statIcon} src={bedrooms} alt="people stat" />
                            <div className={classes.statTextRow}>
                              <Text textVariant="info" parentStyles={classes.statNumber}>
                                {property.bedrooms}
                              </Text>
                              <Text textVariant="info" parentStyles={classes.statText}>
                                {LABELS.BEDROOMS}
                              </Text>
                            </div>
                          </div>
                        )}
                        {!!property.bathrooms && (
                          <div className={classes.stat}>
                            <img
                              className={classes.statIcon}
                              src={bathrooms}
                              alt="applications stat"
                            />
                            <div className={classes.statTextRow}>
                              <Text textVariant="info" parentStyles={classes.statNumber}>
                                {property.bathrooms}
                              </Text>
                              <Text textVariant="info" parentStyles={classes.statText}>
                                {LABELS.BATHROOMS}
                              </Text>
                            </div>
                          </div>
                        )}
                        {!!property.carspaces && (
                          <div className={classes.stat}>
                            <img
                              className={classes.statIcon}
                              src={carspaces}
                              alt="applications stat"
                            />
                            <div className={classes.statTextRow}>
                              <Text textVariant="info" parentStyles={classes.statNumber}>
                                {property.carspaces}
                              </Text>
                              <Text textVariant="info" parentStyles={classes.statText}>
                                {LABELS.CARSPACES}
                              </Text>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className={classes.hotContainer}>
                          <Text textVariant="link" parentStyles={classes.hotText}>
                            {LABELS.HOT}
                          </Text>
                          <img src={hot} className={classes.hotIcon} alt="hot" />
                        </div> */}
                    </div>
                  </div>
                </div>

                {property.embeddedProperties && (
                  <div style={{ marginBottom: -8, marginTop: 16 }}>
                    <Chip label={LABELS.EMBEDDED_NETWORKS} />
                  </div>
                )}
                <div className={classes.lineRow} />
                <div className={classes.inputGrid}>
                  {/* RENTAL OFFER -------------------------------------------------------- // */}
                  <Input
                    placeholder={LABELS.RENTAL_OFFER}
                    title={LABELS.RENTAL_OFFER}
                    value={values.properties[index].rentalOffer || ''}
                    touched={get(touched, `properties[${index}]rentalOffer`)}
                    error={get(errors, `properties[${index}]rentalOffer`)}
                    setValue={(value: string) => {
                      setFieldValue(`properties[${index}].rentalOffer`, value);
                    }}
                    onBlur={() => {
                      setFieldTouched(`properties[${index}]rentalOffer`);
                    }}
                    dollar
                    parentStyles={classes.rentInput}
                    mandatory
                    disabled={values.properties[index].propertyStatus === 'ARCHIVED'}
                  />
                  {/* DID YOU INSPECT -------------------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.DID_YOU_INSPECT}
                    title={LABELS.DID_YOU_INSPECT}
                    value={property.inspectionMethod || ''}
                    values={INSPECTION_METHOD.map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`properties[${index}]inspectionMethod`, value);
                      setFieldValue(`properties[${index}]didInspect`, true);
                      if (value === 'I have not inspected this property') {
                        setFieldValue(`properties[${index}]inspectionDate`, null);
                      }
                    }}
                    onBlur={() => setFieldTouched(`properties[${index}]inspectionMethod`)}
                    touched={get(touched, `properties[${index}]inspectionMethod`)}
                    error={get(errors, `properties[${index}]inspectionMethod`)}
                    mandatory
                    parentStyles={classes.howInspect}
                    disabled={values.properties[index].propertyStatus === 'ARCHIVED'}
                  />
                  {/* WHEN DID YOU INSPECT -------------------------------------------------------- // */}
                  <InputDate
                    placeholder={LABELS.INSPECTION_DATE}
                    title={LABELS.INSPECTION_DATE}
                    touched={
                      get(touched, `properties[${index}]inspectionDate`) ||
                      values.properties[index].inspectionDate
                    }
                    error={get(errors, `properties[${index}]inspectionDate`)}
                    value={values.properties[index].inspectionDate}
                    setValue={(date: string | Date) => {
                      setFieldValue(`properties[${index}].inspectionDate`, date);
                    }}
                    mandatory={
                      values.properties[index].inspectionMethod !==
                      'I have not inspected this property'
                    }
                    disabled={
                      values.properties[index].inspectionMethod ===
                        'I have not inspected this property' ||
                      values.properties[index].propertyStatus === 'ARCHIVED'
                    }
                    onBlur={() => setFieldTouched(`properties[${index}].inspectionDate`)}
                    maxDate={new Date()}
                    parentStyles={classes.whenInspect}
                  />
                  {/* PREFERRED MOVE IN DATE ----------------------------------------------- // */}
                  <InputDate
                    placeholder={LABELS.PREFERRED_MOVE_IN_DATE}
                    title={LABELS.PREFERRED_MOVE_IN_DATE}
                    touched={
                      get(touched, `properties[${index}]preferredMoveInDate`) ||
                      !!values.properties[index].preferredMoveInDate
                    }
                    error={get(errors, `properties[${index}]preferredMoveInDate`)}
                    value={values.properties[index].preferredMoveInDate}
                    setValue={(date: string | Date) => {
                      setFieldValue(`properties[${index}].preferredMoveInDate`, date);
                    }}
                    mandatory
                    onBlur={() => setFieldTouched(`properties[${index}].preferredMoveInDate`)}
                    minDate={
                      values.properties[index].dateAvailable
                        ? moment(
                            values.properties[index].dateAvailable,
                            'DD/MM/YYYY',
                          ).isSameOrAfter(moment())
                          ? moment(values.properties[index].dateAvailable, 'DD/MM/YYYY').format(
                              'YYYY-MM-DD',
                            )
                          : new Date()
                        : new Date()
                    }
                    parentStyles={classes.preferredDate}
                    disabled={values.properties[index].propertyStatus === 'ARCHIVED'}
                  />

                  {/* PREFERRED LEASE DURATION ----------------------------------------------- // */}
                  <InputSelect
                    placeholder={LABELS.YEARS}
                    title={LABELS.PREFERRED_LEASE_DURATION}
                    value={property.years || ''}
                    values={yearRange.map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`properties[${index}]years`, value);
                    }}
                    onBlur={() => setFieldTouched(`properties[${index}]years`)}
                    touched={get(touched, `properties[${index}]years`)}
                    error={get(errors, `properties[${index}]years`)}
                    mandatory
                    parentStyles={classes.preferredYear}
                    disabled={values.properties[index].propertyStatus === 'ARCHIVED'}
                  />
                  <InputSelect
                    title={LABELS.MONTHS}
                    placeholder={LABELS.MONTHS}
                    value={property.months || ''}
                    values={monthRange.map((v) => ({ display: v, value: v }))}
                    setValue={(value: string) => {
                      setFieldValue(`properties[${index}]months`, value);
                    }}
                    onBlur={() => setFieldTouched(`properties[${index}]months`)}
                    touched={get(touched, `properties[${index}]months`)}
                    error={get(errors, `properties[${index}]months`)}
                    parentStyles={classes.preferredMonth}
                    disabled={values.properties[index].propertyStatus === 'ARCHIVED'}
                  />
                </div>
                {!readonly && (
                  <div className={classes.deleteButtonContainerLarge}>
                    <Button
                      outline
                      parentStyles={classes.deleteButtonLarge}
                      onPress={() => {
                        setSnackBarState(true);
                        setSnackBarText('Property removed');
                        deleteProperty(index);

                        if (values.properties.length > 0) {
                          setDraftCallFlag(true);
                        }
                      }}
                    >
                      {LABELS.DELETE}
                    </Button>
                  </div>
                )}
              </Box>
            ))}
            {!!selectedProperties && !!selectedProperties.length && (
              <div className={classes.buttonContainer}>
                <Button
                  parentStyles={classes.submitButton}
                  disabled={values.properties.length === 0 || !isPropertiesValid(values.properties)}
                  onPress={() => {
                    setSubmitAttempted(true);
                    if (userId && !!isValid) {
                      setDraftCallFlag(true);
                    }
                    handleSubmit();
                  }}
                >
                  {LABELS.NEXT}
                </Button>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  properties: state.landingScreen.properties,
  applicationState: state,
  propertyDetails: state.dashboard.propertyDetails,
  assets: state.dashboard.assets,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postApplyDraft: (data: ApplicationState) => dispatch(DashboardActions.postApplyDraft(data)),
  postFormData: (data: PropertyDetailsResponse[], onSubmit: () => void) => {
    dispatch(MasterProfileActions.setAustraliaState(extractState(data[0].address)));
    dispatch(LandingScreenActions.postPropertyDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
