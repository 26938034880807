import {
  GetPaymentAuthKeyResponse,
  PaymentDetails,
} from '../../services/remoteSigning/payment/Payment';
import { PaymentAction, PaymentActionTypes } from '../actions/PaymentActions';
import { PaymentState } from '../state/PaymentState';

export const initialState: PaymentState = {
  paymentAuthKey: {
    authKey: '',
    status: 1,
  },
  postPaymentTokenReponse: null,
};

export const paymentReducer: (state: PaymentState, action: PaymentAction) => PaymentState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case PaymentActionTypes.GET_PAYMENT_AUTH_KEY_SUCCESS:
      return {
        ...state,
        paymentAuthKey: action.data as GetPaymentAuthKeyResponse,
      };
    case PaymentActionTypes.POST_PAYMENT_TOKEN_SUCCESS:
      return {
        ...state,
        postPaymentTokenReponse: action.data as PaymentDetails,
      };

    default:
      return state;
  }
};
