import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './WelcomeToSortedStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../store/RootReducers';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import bannerPeach from '../../../../assets/banner-peach.png';
import welcomeImage from '../../../../assets/welcome-image.png';
import { LABELS } from './WelcomeToSortedConstants';
import CompletedIcon from '../../../../assets/check.svg';
import Profile from '../../../../assets/navigation/profile.png';
import ArrowRight from '../../../../assets/navigation/arrow-right.png';
import { ApplicantProfile } from '../../../../store/state/MyProfileFormState';
import { getProfilePercentage } from '../../../../helper/ProfileVerifyHelper';
import { routes } from '../../../../Routes';
import { ApplicationsResponse } from '../../../../services/groups/Groups';

interface WelcomeToSortedProps extends RouteComponentProps {
  profileFormDetails: ApplicantProfile;
  groupApplications: ApplicationsResponse | undefined;
}

const WelcomeToSorted: FC<WelcomeToSortedProps> = ({
  profileFormDetails,
  history,
  groupApplications,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.welcomeBanner}>
        <div className={classes.welcomeLeftContent}>
          <Text textVariant="title" parentStyles={classes.welcomeTitle}>
            {LABELS.TITLE}
          </Text>
          <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
            {LABELS.SUBTITLE}
          </Text>
          <Button
            onPress={() => history.push(routes.properties.generic)}
            parentStyles={classes.welcomeButton}
          >
            {LABELS.GET_STARTED}
          </Button>
        </div>
        <div className={classes.welcomeImageBackground}>
          <img src={bannerPeach} className={classes.welcomeLeftImage} alt="overlay" />
          <img src={welcomeImage} className={classes.welcomeRightImage} alt="welcome" />
        </div>
      </div>
      <div className={classes.quickStartContainer}>
        <div className={classes.quickStartRow}>
          <img src={Profile} className={classes.quickStartIcon} alt="profile" />
          <Text textVariant="contentTitle" parentStyles={classes.quickStartTitle}>
            {LABELS.QUICK_START}
          </Text>
        </div>
        <div
          className={classes.quickStartItem}
          style={{ borderTop: 'none' }}
          onClick={() => history.push(routes.properties.generic)}
        >
          <img
            src={CompletedIcon}
            className={
              groupApplications &&
              !!groupApplications.draftApplications.agencyWiseApplications.length
                ? classes.quickStartTick
                : classes.quickStartTickIncomplete
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{LABELS.CREATE_DRAFT}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div className={classes.quickStartItem} onClick={() => history.push(routes.myProfile.new)}>
          <img
            src={CompletedIcon}
            className={
              getProfilePercentage(profileFormDetails) !== 100
                ? classes.quickStartTickIncomplete
                : classes.quickStartTick
            }
            alt="tick"
          />
          <Text parentStyles={classes.quickStartText}>{LABELS.COMPLETE_PROFILE}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
        <div
          className={classes.quickStartItem}
          onClick={() => history.push(routes.applications.view)}
        >
          <img src={CompletedIcon} className={classes.quickStartTickIncomplete} alt="tick" />
          <Text parentStyles={classes.quickStartText}>{LABELS.SUBMIT}</Text>
          <img src={ArrowRight} className={classes.quickStartIconAction} alt="action" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  profileFormDetails: state.myProfileForm,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WelcomeToSorted));
