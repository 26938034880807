import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import {
  pageContainer,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
  actionGreen,
  maxContentWidth,
} from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  trianglesContainer: {
    width: 1000,
    height: '50vh',
    position: 'absolute',
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    zIndex: 0,
  },
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...pageContainer,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  backRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.units.spacing * 24,
  },
  backArrow: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
    marginRight: theme.units.spacing * 6,
  },
  titleContainer: {
    ...pageTitleContainer,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addGroup: {
    marginBottom: theme.units.spacing * 6,
  },
  pageContent: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 12,
      maxWidth: '100%',
    },
  },
  spacing: {
    marginBottom: theme.units.spacing * 12,
  },
  groupsContainer: {
    display: 'grid',
    gridGap: theme.units.spacing * 6,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',

    [`@media (max-width: 2800px)`]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [`@media (max-width: 2400px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: 1400px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
  newGroupContainer: {},
  newGroupTitle: {
    marginBottom: theme.units.spacing * 2,
  },
  newGroupInput: {
    marginTop: theme.units.spacing * 4,
    maxWidth: 300,
  },
});
