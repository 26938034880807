import {
  FileUploadStartState,
  FileUploadSuccessState,
  MultiFileUploadStartState,
  MultiFileUploadSuccessState,
} from '../../services/fileUpload/FileData';
import { FileUploadAction, FileUploadActionTypes } from '../actions/FileUploadAction';
import { FileUploadState } from '../state/FileUploadState';

export const initialState: FileUploadState = {
  fileUpload: undefined,

  success: false,
  callerId: '',
};

export function fileUploadReducer(state = initialState, action: FileUploadAction) {
  state = postFileReducer(state, action);
  return state;
}

function postFileReducer(state: FileUploadState, action: FileUploadAction): FileUploadState {
  switch (action.type) {
    case FileUploadActionTypes.POST_FILE_UPLOAD_REQUEST: {
      const data = action.data as FileUploadStartState;
      return {
        ...state,
        success: false,
        fileUpload: undefined,
        callerId: data.id,
      };
    }
    case FileUploadActionTypes.POST_REFERENCE_FILE_REQUEST:
      return {
        ...state,
        success: false,
        fileUpload: undefined,
      };
    case FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_REQUEST: {
      const data = action.data as MultiFileUploadStartState;
      return {
        ...state,
        success: false,
        fileUpload: undefined,
        callerId: data.id,
      };
    }
    case FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_SUCCESS: {
      const data = action.data as MultiFileUploadSuccessState;
      return {
        ...state,
        success: true,
        fileUpload: data.file,
      };
    }
    case FileUploadActionTypes.POST_FILE_UPLOAD_SUCCESS: {
      const data = action.data as FileUploadSuccessState;
      return {
        ...state,
        success: true,
        fileUpload: data.link,
        callerId: data.id,
      };
    }
    case FileUploadActionTypes.POST_FILE_UPLOAD_ERROR:
      return {
        ...state,
        success: false,
        fileUpload: undefined,
      };
    case FileUploadActionTypes.DELETE_FILE: {
      return {
        ...state,
        fileUpload: undefined,
      };
    }
    case FileUploadActionTypes.DELETE_FILE_SUCCESS: {
      return {
        ...state,
        fileUpload: null,
      };
    }
    case FileUploadActionTypes.RESET_FILE_UPLOAD_STATE: {
      return { ...initialState, success: false };
    }
    default:
      return state;
  }
}
