/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import { GroupsAction, GroupsActions, GroupsActionTypes } from '../actions/GroupsActions';
import {
  GroupRequest,
  GroupResponse,
  CoApplicantRequest,
  RemoveCoApplicantRequest,
  ApplicationsRequest,
  ApplicationsResponse,
  SyncApplicationRequest,
  InvitationRequest,
  UpdateCoApplicantRequest,
  PostGroupApplicationRequest,
  GroupNameRequest,
  CreateNewGroupRequest,
  WithdrawRequest,
  WithdrawResponse,
  UpdateGroupApplicationRequest,
} from '../../services/groups/Groups';
import { convertPropertyDataToPost, mapApplyReferenceIdsToProfiles } from '../../helper/PostHelper';
import GroupsService from '../../services/groups/GroupsService';
import { Logging } from '../../helper/Logging';
import { DeleteDraftRequest } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import ApplyService from '../../services/dashboard/ApplyService';

export function* getGroupMasterProfile(action: GroupsAction) {
  try {
    const data = action.data as GroupRequest;
    const response: GroupResponse[] = yield GroupsService.getGroupMasterProfile(data);
    yield put(GroupsActions.getGroupMasterProfileSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.getGroupMasterProfile', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.getGroupMasterProfileError(error as Error));
  }
}

export function* postCreateNewGroup(action: GroupsAction) {
  try {
    const data = action.data as CreateNewGroupRequest;
    const response: GroupResponse[] = yield GroupsService.postCreateNewGroup(data);
    yield put(GroupsActions.postCreateNewGroupSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.postCreateNewGroup', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.postCreateNewGroupError(error as Error));
  }
}

export function* addCoApplicant(action: GroupsAction) {
  try {
    const data = action.data as CoApplicantRequest[];
    const response: GroupResponse[] = yield GroupsService.addCoApplicant(data);
    yield put(GroupsActions.addCoApplicantSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.addCoApplicant', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.addCoApplicantError(error as Error));
  }
}

export function* updateCoApplicant(action: GroupsAction) {
  try {
    const data = action.data as UpdateCoApplicantRequest;
    const response: GroupResponse[] = yield GroupsService.updateCoApplicant(data);
    yield put(GroupsActions.updateCoApplicantSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.updateCoApplicant', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.updateCoApplicantError(error as Error));
  }
}

export function* removeCoApplicant(action: GroupsAction) {
  try {
    const data = action.data as RemoveCoApplicantRequest;
    const response: GroupResponse[] = yield GroupsService.removeCoApplicant(data);
    yield put(GroupsActions.removeCoApplicantSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.removeCoApplicant', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.removeCoApplicantError(error as Error));
  }
}

export function* postGroupApplication(action: GroupsAction) {
  try {
    const postGroupApplicationRequest = action.data as PostGroupApplicationRequest;
    const refIdMappedData = mapApplyReferenceIdsToProfiles(
      postGroupApplicationRequest.applicationState,
    );
    const convertedData = convertPropertyDataToPost(refIdMappedData);
    const response: ApplicationsResponse = yield GroupsService.postGroupApplication({
      groupRefId: postGroupApplicationRequest.groupRefId,
      applyForm: convertedData.applyForm,
    });
    yield put(GroupsActions.postGroupApplicationSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.postGroupApplication', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.postGroupApplicationError(error as Error));
  }
}

export function* getAllApplications(action: GroupsAction) {
  try {
    const response: ApplicationsResponse = yield GroupsService.getAllApplications();
    yield put(GroupsActions.getAllApplicationsSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.getAllApplications', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.getAllApplicationsError(error as Error));
  }
}

export function* syncApplications(action: GroupsAction) {
  try {
    const data = action.data as SyncApplicationRequest;
    const response: ApplicationsResponse = yield GroupsService.syncApplications(data);
    yield put(GroupsActions.syncApplicationsSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.syncApplications', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.syncApplicationsError(error as Error));
  }
}

export function* updateApplication(action: GroupsAction) {
  try {
    const data = action.data as UpdateGroupApplicationRequest;
    const response: ApplicationsResponse = yield GroupsService.updateGroupApplication(data);
    yield put(GroupsActions.updateGroupApplicationSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.updateApplication', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.updateGroupApplicationError(error as Error));
  }
}

export function* acceptInvitation(action: GroupsAction) {
  try {
    const data = action.data as InvitationRequest;
    const response: WithdrawResponse = yield GroupsService.acceptInvitation(data);
    yield put(GroupsActions.acceptInvitationSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.acceptInvitation', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.acceptInvitationError(error as Error));
  }
}

export function* updateGroupName(action: GroupsAction) {
  try {
    const data = action.data as GroupNameRequest;
    const response: GroupResponse[] = yield GroupsService.updateGroupName(data);
    yield put(GroupsActions.updateGroupNameSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.updateGroupNameError', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.updateGroupNameError(error as Error));
  }
}

export function* deleteGroup(action: GroupsAction) {
  try {
    const data = action.data as GroupRequest;
    const response: GroupResponse[] = yield GroupsService.deleteGroup(data);
    yield put(GroupsActions.deleteGroupSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.deleteGroupError', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.deleteGroupError(error as Error));
  }
}

export function* declineInvitation(action: GroupsAction) {
  try {
    const data = action.data as InvitationRequest;
    const response: WithdrawResponse = yield GroupsService.declineInvitation(data);
    yield put(GroupsActions.declineInvitationSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.declineInvitation', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.declineInvitationError(error as Error));
  }
}

export function* withdrawApplication(action: GroupsAction) {
  try {
    const data = action.data as WithdrawRequest;
    const response: WithdrawResponse = yield GroupsService.withdrawApplication(data);
    yield put(GroupsActions.withdrawApplicationSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.withdrawApplicationSuccess', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.withdrawApplicationError(error as Error));
  }
}

export function* withdrawMyself(action: GroupsAction) {
  try {
    const data = action.data as WithdrawRequest;
    const response: WithdrawResponse = yield GroupsService.withdrawMyself(data);
    yield put(GroupsActions.withdrawMyselfSuccess(response));
  } catch (error) {
    Logging.error('GroupsSaga.withdrawMyselfSuccess', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(GroupsActions.withdrawMyselfError(error as Error));
  }
}

export function* deleteDraftWorker(action: GroupsAction) {
  try {
    const response: ApplicationsResponse = yield ApplyService.deleteApplyDraft(
      action.data as DeleteDraftRequest,
    );
    yield put(GroupsActions.deleteDraftSuccess(response));
  } catch (error) {
    yield put(GroupsActions.deleteDraftError(error as Error));
  }
}

function* groupsWatcher() {
  yield takeEvery(GroupsActionTypes.GET_GROUP_MASTER_PROFILE_REQUEST, (action: GroupsAction) =>
    getGroupMasterProfile({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.POST_CREATE_NEW_GROUP_REQUEST, (action: GroupsAction) =>
    postCreateNewGroup({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.ADD_CO_APPLICANT_REQUEST, (action: GroupsAction) =>
    addCoApplicant({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.UPDATE_CO_APPLICANT_REQUEST, (action: GroupsAction) =>
    updateCoApplicant({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.REMOVE_CO_APPLICANT_REQUEST, (action: GroupsAction) =>
    removeCoApplicant({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.POST_GROUP_APPLICATION_REQUEST, (action: GroupsAction) =>
    postGroupApplication({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.GET_ALL_APPLICATIONS_REQUEST, (action: GroupsAction) =>
    getAllApplications({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.SYNC_APPLICATIONS_REQUEST, (action: GroupsAction) =>
    syncApplications({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.UPDATE_GROUP_APPLICATION_REQUEST, (action: GroupsAction) =>
    updateApplication({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.ACCEPT_INVITATION_REQUEST, (action: GroupsAction) =>
    acceptInvitation({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.UPDATE_GROUP_NAME_REQUEST, (action: GroupsAction) =>
    updateGroupName({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.DELETE_GROUP_REQUEST, (action: GroupsAction) =>
    deleteGroup({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.DECLINE_INVITATION_REQUEST, (action: GroupsAction) =>
    declineInvitation({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.WITHDRAW_APPLICATION_REQUEST, (action: GroupsAction) =>
    withdrawApplication({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.WITHDRAW_MYSELF_REQUEST, (action: GroupsAction) =>
    withdrawMyself({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(GroupsActionTypes.DELETE_APPLY_DRAFT_REQUEST, (action: GroupsAction) =>
    deleteDraftWorker({
      type: action.type,
      data: action.data,
    }),
  );
}

export default groupsWatcher;
