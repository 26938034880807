import React, { FC } from 'react';
import { useStyles } from '../RemoteSigningStyles';
import { stringToHslColor } from '../../../../../helper/GravatarHelper';
import { replaceVicTerms } from '../../../../../helper/ReplaceVicTerms';
import { convertToPercent } from '../../../../../component/paymentPopup/PaymentPopupUtils';
import Text from '../../../../../component/text/Text';

interface PersonCardProps {
  profileName: string;
  profileContext: string;
  rentSplitAmount?: number | null;
  rentalAmount?: number | undefined | null;
  setSplitAmount?: (value: string) => void;
}

const PersonCard: FC<PersonCardProps> = ({
  profileName,
  profileContext,
  rentSplitAmount,
  rentalAmount,
  setSplitAmount,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.personCard}>
      <div
        className={classes.gravatar}
        style={{ backgroundColor: stringToHslColor(`${profileName}`, 70, 70) }}
      >
        {profileName.split(' ')[0].charAt(0)}
        {profileName.split(' ')[1].charAt(0)}
      </div>
      <div className={classes.personDetails}>
        <Text textVariant="title" size="xl" parentStyles={classes.personName}>
          {profileName}
        </Text>
        <Text textVariant="info" parentStyles={classes.personContext}>
          {profileContext}
        </Text>
      </div>
      {rentSplitAmount !== undefined ? (
        <div className={classes.rentContainer}>
          <div className={classes.rentInputContainer}>
            <div className={classes.rentSymbol}>$</div>
            <input
              className={classes.rentInput}
              value={rentSplitAmount || 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSplitAmount!(e.target.value)!
              }
            />
          </div>
          <div className={classes.total}>
            {convertToPercent(rentSplitAmount, rentalAmount!).toFixed(2)}%
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PersonCard;
