import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { OverviewFormData as OverviewDetails } from '../../services/landingScreen/OverviewForm.data';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { PROFILE_FORMS as ProfileForm } from '../../constants/AppConstants';
import {
  isPersonalDetailsFilled,
  isIntroFilled,
  isCurrentIncomeDetailsFilled,
  isEmploymentDetailsFilled,
  isPetsDetailsFilled,
  isVehiclesDetailsFilled,
  isAddressDetailsFilled,
  isReferenceDetailsFilled,
  isIdentificationDetailsFilled,
  isDeclarationDetailsFilled,
} from '../../helper/ProfileVerifyHelper';
import { SectionStatus } from './components/ProfileItem/ProfileItemConstants';

export const getProfileName = (
  formData: ApplicantProfile | DependentProfile,
  isDependent: boolean | null,
): string => {
  if (isDependent === null) {
    return '';
  }
  if (isDependent) {
    return (formData as DependentProfile).firstName || '';
  } else {
    if ((formData as ApplicantProfile).personalDetails) {
      return (formData as ApplicantProfile).personalDetails?.firstName || '';
    }
    return '';
  }
};

export const preOpenForm = (
  formData: ApplicantProfile | DependentProfile,
  isDependent: boolean | null,
): ProfileForm | null => {
  if (isDependent === null) {
    return ProfileForm.PERSONAL_DETAILS_FORM;
  }
  if (
    isPersonalDetailsFilled(
      isDependent ? (formData as any) : (formData as ApplicantProfile).personalDetails,
    ) === SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.PERSONAL_DETAILS_FORM;
  }

  if (isIntroFilled((formData as ApplicantProfile).introDetails) === SectionStatus.INCOMPLETE) {
    return ProfileForm.INTRODUCTION;
  }

  if (
    ((formData as ApplicantProfile).currentIncomeDetails
      ? isCurrentIncomeDetailsFilled((formData as ApplicantProfile).currentIncomeDetails!.incomes)
      : SectionStatus.INCOMPLETE) === SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.CURRENT_INCOME_FORM;
  }

  if (
    isEmploymentDetailsFilled(
      (formData as ApplicantProfile).employmentDetails,
      (formData as ApplicantProfile).currentIncomeDetails
        ? (formData as ApplicantProfile).currentIncomeDetails!.incomes
        : undefined,
    ) === SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.EMPLOYMENT_FORM;
  }

  if (
    isPetsDetailsFilled((formData as ApplicantProfile).petsDetails) === SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.PETS_FORM;
  }

  if (
    isVehiclesDetailsFilled((formData as ApplicantProfile).vehiclesDetails) ===
    SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.VEHICLES_FORM;
  }

  if (
    ((formData as ApplicantProfile).addressDetails
      ? isAddressDetailsFilled((formData as ApplicantProfile).addressDetails!.addresses)
      : SectionStatus.INCOMPLETE) === SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.ADDRESS_FORM;
  }

  if (
    isReferenceDetailsFilled((formData as ApplicantProfile).referencesDetails) ===
    SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.REFERENCE_FORM;
  }

  if (
    isIdentificationDetailsFilled((formData as ApplicantProfile).identificationDetails) ===
    SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.IDENTIFICATION_FORM;
  }

  if (
    isDeclarationDetailsFilled((formData as ApplicantProfile).declarationDetails) ===
    SectionStatus.INCOMPLETE
  ) {
    return ProfileForm.DECLARATION_FORM;
  }

  return null;
};
