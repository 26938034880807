import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { color } from '../TenancyTheme';

export const useStyles = makeStyles({
  boxStyle: {
    marginTop: theme.units.spacing * 12,
  },
  title: {
    marginBottom: theme.units.spacing * 4,
  },
  inputContainer: {
    border: '1px solid #eee',
    backgroundColor: color.light,
    borderRadius: '5px',
  },

  inputStyle: {
    '&:after, &:before': {
      borderBottom: '0px none !important',
    },
  },

  selectTenantMenu: {
    height: '46px',
  },

  submitButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    '& > *+*': {
      marginLeft: '1em',
    },
  },

  buttonStyle: {
    borderRadius: 5,
    float: 'right',
  },

  noteStyle: {
    color: color.dark800Hover,
  },

  tenantMenuItemContainer: {
    display: 'flex',
    padding: '5px',
    border: `solid 1px ${color.dark700}`,
    borderRadius: '4px',
    width: '100%',
  },

  avatarIconContainer: {
    marginRight: '16px',
  },

  dividerContainer: {
    margin: '16px 0',
  },

  propertyDetailsHeadingContainer: {
    marginTop: '30px',
    marginLeft: '8px',
  },

  captchaContainer: {
    marginTop: '1em',
  },
});
