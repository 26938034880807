/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useFetch from '../../hooks/UseFetch';
import residentRequestService from '../../services/residentRequest/residentRequestService';
import { ResidentRequestType } from '../../services/residentRequest/residentRequestService.data';
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import GenericFormRenderer from '../../component/genericFormRenderer/GenericFormRenderer';
import { useStyles } from './ResidentRequestStyles';
import {
  ERRORS,
  LABELS,
  SWITCH_NO_BRANCH_CODE_REDIRECTION_LINK,
  SWITCH_SUCCESS_REDIRECTION_LINK,
} from './ResidentRequestConstants';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import Button from '../../component/button/Button';
import { generateValidationSchema } from '../shortApplication/ShortApplicationConstants';
import propertyService from '../../services/dashboard/PropertyService';
import { debounce } from 'lodash';
import SortedFooter from '../../component/sortedFooter/SortedFooter';
import * as Yup from 'yup';
import { theme } from '../../theme/Theme';
import { getAddressStringFromAddressComponents } from '../../helper/AddressValue';
import { AddressComponents } from '../../services/remoteSigning/equifax/Equifax';
import { convertMultipartResidentRequest } from '../../services/residentRequest/util';
import { ErrorOutline } from '@mui/icons-material';
import SwitchBG from '../../assets/switch-bg.jpg';
import SwitchLogo from '../../assets/switch-logo.png';
import Shimmer from '../../component/loader/Shimmer';
import ErrorMessage from '../../component/errorMessage/ErrorMessage';
import { getValidationSchema } from '../../component/genericFormRenderer/util';

interface ResidentRequestProps extends RouteComponentProps {}

const ResidentRequest: FC<ResidentRequestProps> = ({ location }) => {
  const [propertyValue, setPropertyValue] = useState<{
    id: number;
    address: string;
    unitNumber: string;
  } | null>(null);
  const [openAutocompleteOptions, setOpenAutocompleteOptions] = useState(false);
  const [pageError, setPageError] = useState<string>('');

  const { branchCode, type } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return { branchCode: searchParams.get('b'), type: searchParams.get('type') };
  }, []);

  const [propertySearch, setPropertySearch] = useState<string>('');

  const {
    fetchData: getFormData,
    state: { loading: fetchingForm, data: questions },
  } = useFetch(residentRequestService.getForm);

  const {
    fetchData: searchProperties,
    state: { data: propertiesData, loading: searchingProperties },
  } = useFetch(propertyService.searchProperties.bind(propertyService));

  const {
    fetchData: submitForm,
    state: { success, loading: posting, error: submitError },
  } = useFetch(residentRequestService.postForm.bind(residentRequestService));

  const debouncedSearchProperties = useCallback(debounce(searchProperties, 2000), []);

  const propertiesOptions = useMemo(() => {
    if (propertiesData) {
      return ((propertiesData || []) as (AddressComponents & { id: number })[]).map((p) => ({
        id: p.id,
        address: getAddressStringFromAddressComponents(p),
        unitNumber: p.unitNumber,
      }));
    } else {
      return [];
    }
  }, [propertiesData]);

  useEffect(() => {
    if (propertySearch.length >= 3 && branchCode) {
      debouncedSearchProperties({
        branch: branchCode,
        searchString: propertySearch,
      })?.catch((e) => {
        console.log(e);
      });
    }
  }, [propertySearch]);

  useEffect(() => {
    if (branchCode && type) {
      getFormData({ residentRequestType: type as ResidentRequestType, branchCode }).catch((e) => {
        setPageError(ERRORS.formFetchError);
      });
    }
  }, []);

  useEffect(() => {
    if (success) {
      window.location.replace(SWITCH_SUCCESS_REDIRECTION_LINK);
    }
  }, [success]);

  const classes = useStyles();

  const initialValues = useMemo(() => {
    const value: { [key: string]: any } = { propertyId: undefined };
    questions?.map((q) => {
      if (q.mandatory) value[q.field] = null;
    });

    return value;
  }, [questions]);

  if (!branchCode) {
    window.location.replace(SWITCH_NO_BRANCH_CODE_REDIRECTION_LINK);
    return null;
  }

  return (
    <Box>
      <AgencyBackground bg={SwitchBG} logo={SwitchLogo} />
      <Box className={classes.containerBox} mb={4} mt={8} px={4}>
        {pageError ? (
          <Box width="100vw" display="flex" flexDirection="column" alignItems="center" gap="1.5em">
            <ErrorOutline style={{ color: theme.colors.error, fontSize: '60px' }} />
            <Typography variant="h5">{pageError}</Typography>
          </Box>
        ) : (
          <>
            {fetchingForm && <CircularProgress style={{ color: theme.colors.secondary }} />}
            {questions && (
              <Box className={classes.contentBox}>
                <Box mb={4}>
                  <Typography style={{ fontWeight: 500 }} variant="h3">
                    {LABELS.heading(branchCode)}
                  </Typography>
                </Box>

                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    convertMultipartResidentRequest(
                      {
                        ...values,
                        residentRequestType: type as ResidentRequestType,
                      } as any,
                      questions,
                    )
                      .then((res) => {
                        submitForm(res as any).catch(() => {});
                      })
                      .catch((e) => {
                        console.log('error while processing', e);
                      });
                  }}
                  validationSchema={getValidationSchema(questions).shape({
                    propertyId: Yup.number()
                      .required('Property is required')
                      .typeError('Property is required'),
                  })}
                >
                  {({ setFieldValue, values, setFieldTouched, errors, touched, handleSubmit }) => {
                    return (
                      <Box>
                        <Box p={2} py={3} className={classes.borderedBox}>
                          <Box mb={2}>
                            <Autocomplete
                              open={openAutocompleteOptions}
                              onOpen={() => {
                                setOpenAutocompleteOptions(true);
                              }}
                              onClose={() => {
                                setOpenAutocompleteOptions(false);
                              }}
                              value={propertyValue}
                              options={propertiesOptions}
                              onChange={(_e, value) => {
                                setFieldValue('propertyId', value?.id || null);
                                setFieldValue('roomNumber', value?.unitNumber || null);
                                setPropertyValue(value as any);
                              }}
                              getOptionLabel={(op) => op.address}
                              renderOption={(props, option) => (
                                <MenuItem {...props} style={{ padding: '10px' }}>
                                  <Typography
                                    style={{ fontSize: 14, color: 'black', fontWeight: 500 }}
                                    variant="h4"
                                  >
                                    {option.address}
                                  </Typography>
                                </MenuItem>
                              )}
                              noOptionsText={
                                searchingProperties ? (
                                  <Shimmer height="20px" width="100%" />
                                ) : (
                                  <Typography
                                    style={{ fontSize: 14, color: 'black', fontWeight: 500 }}
                                    variant="h4"
                                  >
                                    {LABELS.noOptions}
                                  </Typography>
                                )
                              }
                              renderInput={(params) => (
                                <div>
                                  {errors.propertyId ? (
                                    <div
                                      style={{ color: theme.colors.error }}
                                      className={classes.titleStyle}
                                    >
                                      Address is required
                                    </div>
                                  ) : (
                                    <Box display="flex">
                                      <div className={classes.titleStyle}>
                                        {LABELS.addressFieldLabel(branchCode)}
                                      </div>
                                      <div className={classes.mandatory}>*</div>
                                    </Box>
                                  )}
                                  <TextField
                                    {...params}
                                    placeholder={
                                      errors.propertyId
                                        ? LABELS.addressFieldLabel(branchCode)
                                        : 'Enter your room address'
                                    }
                                    value={propertySearch}
                                    onChange={(e) => setPropertySearch(e.target.value)}
                                    onBlur={() => setFieldTouched('propertyId')}
                                    required
                                    variant="outlined"
                                    inputProps={{
                                      ...params.inputProps,
                                      className: `${classes.inputStyle} ${
                                        errors.propertyId && 'error'
                                      }`,
                                    }}
                                    error={!!errors.propertyId}
                                  />
                                </div>
                              )}
                            />
                          </Box>
                          <GenericFormRenderer
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            touched={touched}
                            questions={questions}
                          />
                        </Box>
                        <Box display="flex" justifyContent="flex-end" mt={3} mb={2}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="1em"
                            alignItems="flex-end"
                          >
                            <Button type="button" onPress={handleSubmit} disabled={!!posting}>
                              Submit
                            </Button>
                            {submitError && <ErrorMessage message={submitError} />}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }}
                </Formik>
              </Box>
            )}
          </>
        )}
      </Box>
      <SortedFooter />
    </Box>
  );
};

export default withRouter(ResidentRequest);
