import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  downloadSortedContainer: {
    marginTop: theme.units.spacing * 8,
    border: `${theme.colors.borderColorLight} 2px solid`,
    borderRadius: theme.units.borderRadius,
    width: '100%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 380,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
      height: '100%',
    },
  },
  textContent: {
    padding: theme.units.spacing * 8,
  },
  title: {
    maxWidth: 440,
    marginBottom: theme.units.spacing * 4,
  },
  subtitle: {
    maxWidth: 440,
    marginBottom: theme.units.spacing * 8,
  },
  button: {
    height: 30,
    minWidth: 160,
  },
  imageBg: {
    flex: 1,
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'right',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      height: 200,
    },
  },
  bottomRow: {
    backgroundColor: theme.colors.lightGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.units.spacing * 4,
  },
  sortedLogo: {
    height: 30,
    objectFit: 'contain',
    marginRight: 'auto',
  },
  apple: {
    height: 45,
    objectFit: 'contain',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  googlePlay: {
    height: 45,
    objectFit: 'contain',
    marginLeft: theme.units.spacing * 4,
    cursor: 'pointer',
  },
});
