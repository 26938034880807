/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { actionGreen, globalStyles } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  boxContainer: {
    marginBottom: theme.units.spacing * 4,
    position: 'relative',
  },
  applicationRow: {
    display: 'flex',
    alignItems: 'stretch',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  propertyContainer: {
    marginRight: theme.units.spacing * 4,
    width: 140,
    height: 140,
    borderRadius: theme.units.borderRadius / 1.5,
    backgroundColor: theme.colors.lightGrey,
    flexShrink: 0,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginRight: 0,
      width: '100%',
      height: 200,
      marginBottom: theme.units.spacing * 2,
    },
  },
  property: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  applicationDetails: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'row',
  },
  applicationDetailsMiddle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 4,
    },
  },
  applicationDetailsFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  propertyAddressContainer: {
    marginRight: 'auto',
  },
  address1: {
    marginBottom: theme.units.spacing * 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  address2: {},
  rentAndAgencyContainer: {
    marginLeft: 'auto',
    display: 'flex',
  },
  agencyContainer: {
    height: '70px',
    flexShrink: 0,
    borderRadius: 5000,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      height: '50px',
    },
  },
  agencyLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  rentContainer: {
    marginLeft: theme.units.spacing * 4,
    borderLeft: `${theme.colors.borderColorLight} 2px solid`,
    paddingLeft: theme.units.spacing * 4,
  },
  rentRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 2,
  },
  rent: {
    color: theme.colors.secondary,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  rentError: {
    color: theme.colors.error,
    fontSize: 24,
  },
  editIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginLeft: theme.units.spacing * 3,
    ...actionGreen,
    alignSelf: 'flex-start',
    position: 'relative',
    top: 2,
  },
  rentInfo: {},
  deleteButtonContainer: {
    borderLeft: `${theme.colors.borderColorLight} 2px solid`,
    paddingLeft: theme.units.spacing * 4,
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  deleteButton: {
    minWidth: 60,
    height: 'min-content',
  },
  deleteButtonContainerLarge: {
    display: 'none',
    [`@media (max-width: ${SIZES.md}px)`]: {
      display: 'flex',
    },
    width: '100%',
  },
  deleteButtonLarge: {
    flex: 1,
  },
  statsRow: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
  },
  statTextRow: {
    display: 'flex',
    alignItems: 'center',
  },
  statNumber: {
    marginLeft: theme.units.spacing * 3,
    position: 'relative',
    top: 2,
  },
  statText: {
    position: 'relative',
    top: 2,
    marginLeft: theme.units.spacing * 1,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'none',
    },
  },
  hotContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  hotText: {
    marginRight: theme.units.spacing * 2,
  },
  hotIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
  },
  lineRow: {
    marginTop: theme.units.spacing * 6,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      paddingTop: theme.units.spacing * 6,
    },
  },
  inputGrid: {
    display: 'grid',
    gridTemplateAreas: `"rent how how how"
    "when preferred year month"`,
    gridGap: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      gridTemplateAreas: `
      "rent how how how"
      "when when preferred preferred"
      "year year month month"`,
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'block',
      gridGap: theme.units.spacing * 2,
    },
  },
  rentInput: {
    gridArea: 'rent',
    minHeight: 41,
  },
  howInspect: {
    gridArea: 'how',
  },
  whenInspect: {
    gridArea: 'when',
  },
  preferredDate: {
    gridArea: 'preferred',
  },
  preferredYear: {
    gridArea: 'year',
  },
  preferredMonth: {
    gridArea: 'month',
  },
  submitButton: {
    minWidth: 200,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  overlay: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    position: 'absolute',
    whiteSpace: 'pre-line',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: theme.units.borderRadius,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  overlayText: {
    fontWeight: getFonts('Medium'),
    fontSize: theme.font.m,
    color: theme.colors.black60,
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: theme.units.spacing * 4,
  },
});
