/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/prefer-default-export */
import { AgencyWiseApplication, ApplicationsResponse } from '../../services/groups/Groups';

export const shuffle = (array: string[]) => {
  const arrayToShuffle = [...array];
  for (let i = arrayToShuffle.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayToShuffle[i], arrayToShuffle[j]] = [arrayToShuffle[j], arrayToShuffle[i]];
  }
  return arrayToShuffle;
};

export const getRefIds = (groupApplications: ApplicationsResponse | undefined): string[] => {
  const refIds: string[] = [];

  const extractIds = (agencyWiseApplications: AgencyWiseApplication[]) => {
    agencyWiseApplications.map((agency) => {
      agency.application.map((application) => {
        application.propertyList.map((property) => {
          refIds.push(property.propertyRefId);
        });
      });
    });
  };

  if (groupApplications) {
    if (groupApplications.draftApplications.agencyWiseApplications.length) {
      extractIds(groupApplications.draftApplications.agencyWiseApplications);
    }

    if (groupApplications.invitedApplicationGroups.groups.length) {
      groupApplications.invitedApplicationGroups.groups.map((group) => {
        extractIds(group.agencyWiseApplications);
      });
    }

    if (groupApplications.submittedApplicationGroups.groups.length) {
      groupApplications.submittedApplicationGroups.groups.map((group) => {
        extractIds(group.agencyWiseApplications);
      });
    }
  }

  return refIds;
};
