import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';

export const useStyles = makeStyles({
  root: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  addressEditButtonStyle: {
    paddingTop: '10px',
    paddingBottom: '10px',
    border: 'none',
    backgroundColor: theme.colors.sortedPurple,
    color: theme.colors.light,
    fontWeight: 'lighter',
    flexShrink: 1,
  },
  switchStyle: {
    margin: '0px',
    marginLeft: '-10px',
    padding: '0px',
  },
  switchLabelStyle: {
    color: theme.colors.sortedPurple,
    fontSize: '14px',
  },
  modalBackgroundStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  modalContainerStyle: {
    backgroundColor: theme.colors.light,
    borderRadius: '5px',
    padding: '20px',
    margin: '20px',
    [`@media (min-width: ${SIZES.lg}px)`]: {
      padding: '30px',
    },
  },
  inputContainerStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: theme.colors.disabled,
    },
    flex: 1,
  },
  inputStyle: {
    marginTop: '5px',
    [`@media (min-width: ${SIZES.lg}px)`]: {
      width: '356px',
    },
    [`@media (min-width: ${SIZES.md}px)`]: {
      width: '256px',
    },
  },
  optionItemStyle: {
    padding: '5px',
  },
  subTextStyle: {
    fontSize: '12px',
    color: theme.colors.darkGrey,
    fontWeight: 'normal',
    margin: '0px',
  },
  spacerStyle: {
    height: '10px',
    width: '10px',
  },
  twoRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginTop: '10px',
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginTop: '20px',
    },
  },
  buttonContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    marginTop: '20px',
    flex: 1,
    fontWeight: 'lighter',
    paddingTop: '10px',
    paddingBottom: '10px',
    [`@media (min-width: ${SIZES.lg}px)`]: {
      marginTop: '30px',
    },
  },
  cancelButtonStyle: {
    width: '100%',
    marginTop: theme.units.spacing * 4,
  },
  okButtonStyle: {
    width: '100%',
    marginLeft: theme.units.spacing * 4,
    marginTop: theme.units.spacing * 4,
  },
  errorTextStyle: {
    color: theme.colors.error,
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
  },
});
