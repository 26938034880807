/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { isArray } from 'lodash';
import { ResidentRequestType } from './residentRequestService.data';
import { processFile } from '../../container/shortApplication/ShortApplicationUtils';
import { Question } from '../../component/genericFormRenderer/GenericFormRenderer';

export const convertMultipartResidentRequest = async (
  formData: {
    propertyId: number;
    residentRequestType: ResidentRequestType;
    [key: string]: any;
  },
  questions: Question[],
) => {
  const { propertyId, residentRequestType, ...data } = formData;
  const request: any = { propertyId, residentRequestType };

  const files: File[] = [];
  const reqData: { key: string; value: any; display: string | undefined }[] = [];

  Object.entries(data).map(([key, value]) => {
    const question = questions.find((q) => q.field === key);
    const hideOnTenancy = question?.hideTenancy;
    const display = question?.display;

    if (hideOnTenancy) return;

    if (isArray(value) && value[0].type) {
      const names: string[] = [];

      value.map((f) => {
        files.push(f);
        names.push(f.name);
      });
      reqData.push({ key, value: names, display: display || '' });
    } else {
      reqData.push({ key, value, display: display || '' });
    }
  });
  request.data = reqData;

  const content = await Promise.all(files.map((file) => processFile(file)));

  return { request, files: content };
};
