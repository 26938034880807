import { makeStyles } from '@mui/styles';
import { SIZES } from '../../../../constants/AppConstants';
import { theme } from '../../../../theme/Theme';

export const useStyles = makeStyles({
  footerContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  root: {
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   minHeight: '48vh',
    // },
    // height: '100%',
  },
  paperBack: {
    margin: 'auto',
    top: '-100px',
    maxWidth: '1000px',
    borderRadius: '6px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    // [`@media (min-width: ${SIZES.md}px)`]: {
    position: 'relative',
    // },
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   margin: 0,
    //   boxShadow: 'none',
    // },
    // // ipad-pro
    // [`@media (min-device-width: ${SIZES.lg}px) and (max-device-width: ${SIZES.lg}px) and (min-device-height:${SIZES.lgMax}px) and (max-device-height:${SIZES.lgMax}px) and (orientation: portrait)`]: {
    //   position: 'relative',
    // },
    // [`@media (min-device-width:${SIZES.lgMax}px) and (max-device-width:${SIZES.lgMax}px) and (min-device-height:${SIZES.lg}px) and (max-device-height:${SIZES.lg}px) and (orientation: landscape)`]: {
    //   position: 'relative',
    // },
  },
  contentContainer: {
    paddingLeft: '80px',
    paddingRight: '80px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  backgroundContainer: {
    height: '280px',
    maxHeight: '280px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.colors.sortedPurple,
    marginTop: 'auto',
  },
  backgroundIconWrapper: {
    margin: 'auto',
    marginTop: '60px',
    maxWidth: '1000px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '80px',
    paddingRight: '80px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  backgroundIcon: {
    height: '50px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      height: '40px',
    },
  },
  cardContentContainer: {
    padding: '30px',
  },
  successTitle: {
    color: theme.colors.sortedPurple,
    fontSize: 36,
    marginBottom: '15px',
  },
  text: {
    fontSize: '20px',
    color: theme.colors.black87,
    maxWidth: '400px',
    fontWeight: 100,
    fontFamily: 'GT Walsheim',
    marginBottom: '40px',
    lineHeight: 1.5,
  },
  subtitle: {
    fontSize: 28,
    color: theme.colors.sortedPurple,
    marginTop: '30px',
  },
  listItem: {
    fontSize: '18px',
    marginTop: '10px',
    opacity: 0.5,
    fontWeight: 100,
    fontFamily: 'GT Walsheim',
  },
  bottomContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    minHeight: '400px',
    position: 'relative',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      minHeight: '300px',
    },
  },
  leftButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '100%',
    zIndex: 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  button: {
    marginBottom: '30px',
    marginLeft: '30px',
    height: '60px',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: '50px',
    },
  },
  rightContent: {
    position: 'absolute',
    right: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  rightBgImgContainer: {
    width: '400px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  rightBgImg: {
    width: '100%',
  },
  rightImgContainer: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    right: 15,
    height: '110%',
    [`@media (max-width: ${SIZES.sm}px)`]: {
      height: '100%',
    },
  },
  rightImg: {
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'bottom',
  },
  walletCreditsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  walletIconContainer: {
    marginRight: '15px',
    borderRadius: 5000,
    backgroundColor: theme.colors.success,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    flexShrink: 0,
  },
  walletIcon: {
    width: '35px',
    height: '35px',
  },
  walletDetails: {
    marginRight: 'auto',
    width: '100%',
  },
  walletDetailTitle: {
    fontSize: '24px',
    color: theme.colors.black87,
    fontWeight: 300,
    marginBottom: '5px',
  },
  walletDetailText: {
    fontSize: '18px',
    color: theme.colors.darkGrey,
    fontFamily: 'GT Walsheim',
  },
  houseContainer: {
    marginLeft: 'auto',
    height: '70px',
    marginTop: '-10px',
  },
  houseIcon: {
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  yourCodeContainer: {
    marginTop: '30px',
    backgroundColor: theme.colors.light,
    display: 'flex',
    padding: '15px 25px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  yourCodeTitle: {
    fontSize: '22px',
    color: theme.colors.black87,
    fontFamily: 'GT Walsheim',
  },
  yourCode: {
    fontSize: '24px',
    color: theme.colors.success,
    fontWeight: 500,
    fontFamily: 'GT Walsheim',
  },
  yourCodeInfo: {
    marginTop: '15px',
    fontSize: '16px',
    maxWidth: '700px',
    color: theme.colors.darkGrey,
    fontFamily: 'GT Walsheim',
  },
  echoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '30px',
    position: 'relative',
    paddingTop: '30px',
    marginBottom: '30px',
  },
  freeContainer: {
    backgroundColor: theme.colors.success,
    borderRadius: 4,

    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    padding: '10px 15px',
    width: '125px',
    position: 'relative',
  },
  freeBig: {
    color: theme.colors.light,
    fontWeight: 'bold',
    fontSize: '50px',
    textTransform: 'uppercase',
  },
  freeSmall: {
    color: theme.colors.light,
    fontWeight: 'bold',
    fontSize: '25px',
    textTransform: 'uppercase',
    marginTop: 5,
  },
  dotContainer: {
    // height: '170px',
    width: '220px',
    position: 'absolute',
    right: 15,
    top: 15,
    [`@media (max-width: 1160px)`]: {
      width: '220px',
    },
    [`@media (max-width: 1060px)`]: {
      width: '180px',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '130px',
    },
  },
  dotImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'right',
  },
  echoContentContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  echoSubtitle: {
    fontSize: 20,
    color: theme.colors.black60,
    marginTop: '20px',
    marginBottom: '-10px',
  },
  listDotRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '30px',
    marginRight: '30px',
    marginTop: '30px',
    '&:last-child': {
      marginBottom: '30px',
    },
  },
  dot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sortedPurple,
    borderRadius: 5000,
    color: theme.colors.light,
    marginRight: '15px',
    width: '40px',
    height: '40px',
    flexShrink: 0,
  },
  echoText: {
    fontSize: '20px',
    color: theme.colors.black87,
    opacity: 0.7,
    fontWeight: 100,
    fontFamily: 'GT Walsheim',
    lineHeight: 1.5,
  },
  giveYou: {
    fontSize: '20px',
    color: theme.colors.black87,
    opacity: 0.7,
    fontWeight: 100,
    fontFamily: 'GT Walsheim',
    lineHeight: 1.5,
    maxWidth: '500px',
    marginBottom: '20px',
  },
  terms: {
    padding: '30px',
    fontSize: '20px',
    color: theme.colors.success,
    fontFamily: 'GT Walsheim',
    lineHeight: 1.5,
    textDecoration: 'underline',
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
    maxWidth: '600px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  title: {
    fontSize: '36px',
  },
  closeIcon: {
    width: 30,
    height: 30,
    cursor: 'pointer',
    marginLeft: '60px',
  },
});
