import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './ErrorPageStyles';
import img404 from '../../assets/404.png';
import * as LABEL from './ErrorPageConstants';

interface ErrorPageProps {}

const ErrorPage: FC<ErrorPageProps> = ({}) => {
  const classes = useStyles(LABEL);

  return (
    <div className={classes.root}>
      <img src={img404} alt="" />
      <br />
      <Typography variant="h6" className={classes.errorMessage}>
        {LABEL.INCORRECT_LINK}
      </Typography>
    </div>
  );
};

export default ErrorPage;
