/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './ScrollBoxStyles';
import { CustomTermsHeaderResponse } from '../../../../../store/actions/CustomTermsHeaderActions';
import { store } from '../../../../../store/Store';
import { SubmittedApplicationResponse } from '../../../../../services/dashboard/postApplyForm/PostApplyForm.data';
import { replaceVicTerms } from '../../../../../helper/ReplaceVicTerms';

interface ScrollBoxProps {
  headerDetails: CustomTermsHeaderResponse | null | undefined;
}

const ScrollBox: FC<ScrollBoxProps> = ({ headerDetails }) => {
  const classes = useStyles();
  let sortedTerms = headerDetails && headerDetails.sortedTerms ? headerDetails.sortedTerms : false;
  const isVic =
    (store.getState().masterProfile.masterProfileData as SubmittedApplicationResponse).applicants[0]
      .selectedState === 'VIC';

  if (!!sortedTerms && isVic) {
    sortedTerms = sortedTerms.replace(
      '<center><h2>Tenant Declaration</h2></center>',
      '<center><h2>Renter Declaration</h2></center>',
    );
    sortedTerms = replaceVicTerms(sortedTerms);
  }

  return (
    <div className={classes.scrollBox}>
      {sortedTerms ? (
        <div
          className={`${classes.numberStyle} custom-terms`}
          dangerouslySetInnerHTML={{ __html: sortedTerms }}
        />
      ) : (
        // If there are no sorted terms, display this as a backup
        <>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            Privacy statement
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            I/We acknowledge that we have read, understood and agree to the Privacy Statement.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            Sorted Lab Pty Ltd ABN 68 616 153 738 (Sorted Services) is collecting your personal
            information to help you apply to rent one or more properties and to provide you with
            associated services.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            Sorted Services may also use your personal information to improve our services, to send
            you direct marketing and for other purposes as permitted by law.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            Sorted Services may disclose your personal information to relevant real estate agents
            (who may in turn disclose it to landlords) and to others, for example their outsourced
            service providers, as necessary for the above purposes or as otherwise permitted by law.
            Some of these entities may be located overseas, for example outsourced service providers
            may be located in the USA, Philippines, India, or New Zealand.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            Relevant real estate agents may also share your personal information with Sorted
            Services, in connection with your application for or rental of a property.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            For information on your right to seek access to or correction of the personal
            information Sorted Services hold about you, or how you can complain about a privacy
            breach and how we will deal with the complaint, see our Privacy Policy.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            Please refer to your real estate agent's privacy policy for details of how they will
            handle your personal information. Some landlords may also have their own privacy
            policies.
          </Typography>
          <Typography variant="subtitle1" className={classes.paragraphs}>
            I/We acknowledge that we have read, understood and agree to the Privacy Statement.
          </Typography>
        </>
      )}
    </div>
  );
};

export default ScrollBox;
