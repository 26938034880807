import { TypeHelper } from '../../../../helper/TypeHelper';
import { PropertyDetailsResponse } from '../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

export const isPropertiesValid = (properties: PropertyDetailsResponse[]) => {
  if (properties.length === 0) return false;
  let result = true;
  const today = new Date();
  for (const property of properties) {
    if (property.propertyStatus === 'ARCHIVED') {
      result = false;
    }
    if (
      !(
        !!property.rentalOffer &&
        !!property.inspectionMethod &&
        (property.inspectionMethod === 'I have not inspected this property' ||
          (property.inspectionMethod !== 'I have not inspected this property' &&
            !!property.inspectionDate &&
            property.inspectionDate <= today)) &&
        !!property.preferredMoveInDate &&
        property.preferredMoveInDate > today &&
        !TypeHelper.isNullOrUndefined(property.years) &&
        !TypeHelper.isNullOrUndefined(property.months)
      )
    ) {
      result = false;
    }
  }
  return result;
};
