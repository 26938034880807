import React, { FC } from 'react';
import { useStyles } from './NoApplicationsStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './NoApplicationsConstants';
import { routes } from '../../../../Routes';

interface NoApplicationsProps extends RouteComponentProps {}

const NoApplications: FC<NoApplicationsProps> = ({ history }) => {
  const classes = useStyles();

  return (
    <>
      <Text textVariant="boxTitle" parentStyles={classes.noApplicationsTitle}>
        {LABELS.TITLE}
      </Text>
      <Box lightBorder innerStyles={classes.noApplicationsContainer}>
        <Text textVariant="info" parentStyles={classes.noDrafts}>
          {LABELS.NO_DRAFTS}
        </Text>
        <Button
          parentStyles={classes.createNew}
          onPress={() => history.push(routes.properties.generic)}
        >
          {LABELS.CREATE_NEW}
        </Button>
      </Box>
    </>
  );
};

export default withRouter(NoApplications);
