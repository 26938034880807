import React, { FC, useState, useEffect, useMemo } from 'react';
import { Card, Checkbox, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { DownloadHelper } from '../../../../../helper/DownloadHelper';
import { SigningSteps, Terms } from '../../../RemoteSigningUtils';
import { theme } from '../../../../../theme/Theme';
import { AppIcon, AppIconSize, AppIconType } from '../../../../../component/AppIcon/AppIcon';
import { useStyles } from '../RemoteSigningStyles';

interface AttachedDocumentsProps {
  stepData: SigningSteps;
  acceptable?: boolean;
  setIsAllChecked?: (value: boolean) => void;
}

const AttachedDocuments: FC<AttachedDocumentsProps> = ({
  stepData,
  acceptable,
  setIsAllChecked,
}) => {
  const classes = useStyles();
  const [allChecked, setAllChecked] = useState<boolean[]>([]);

  useMemo(() => {
    setAllChecked(new Array(stepData.content.length).fill(false));
  }, [stepData]);

  useEffect(() => {
    if (acceptable && setIsAllChecked) {
      setIsAllChecked(allChecked.filter((c) => c).length === stepData.content.length);
    }
  }, [allChecked]);

  const toggleChecked = (index: number) => {
    setAllChecked(
      allChecked.map((item, idx) => {
        return idx === index ? !item : item;
      }),
    );
  };

  return (
    <div className={classes.downloadDocsContainer}>
      {stepData.content.map((docData: Terms, index) => (
        <div className={classes.downloadCard} key={index}>
          <div className={classes.downloadContent}>
            <DescriptionOutlinedIcon className={classes.docIconStyle} />
            <Typography variant="body1">{docData.title}</Typography>
          </div>
          {acceptable && (
            <div className={classes.acceptContainer}>
              <Checkbox
                style={{ color: theme.colors.success, padding: 0, paddingRight: 6 }}
                className={classes.checkboxStyle}
                checked={allChecked[index]}
                onChange={() => toggleChecked(index)}
              />
              <Typography className={classes.checkboxText} onClick={() => toggleChecked(index)}>
                I accept
              </Typography>
            </div>
          )}

          <GetAppOutlinedIcon
            className={classes.downloadButton}
            onClick={() => DownloadHelper.openInNewTab(docData.link as string)}
          />
        </div>
      ))}
    </div>
  );
};

export default AttachedDocuments;
