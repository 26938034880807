import React, { FC, useState } from 'react';
import {
  Paper,
  Typography,
  FormControl,
  InputAdornment,
  FormHelperText,
  Input,
  IconButton,
} from '@mui/material';
import { Formik, ErrorMessage } from 'formik';
import { History } from 'history';
import { useStyles } from './ResetPasswordStyles';
import { VALIDATION_SCHEMA, LABELS } from './ResetPasswordConstants';
import visibilityOff from '../../assets/outline-visibility-off-24-px.svg';
import visibilityOn from '../../assets/outline-remove-red-eye-24-px.svg';
import AgencyBackground from '../agencyBackground/AgencyBackground';
import FooterComponent from '../footer/Footer';
import { ResetPasswordPayload } from '../../services/login/LoginData';

interface ResetPasswordProps {
  history: History;
  token: string;
  user: string;
  postUpdatePassword: (data: ResetPasswordPayload) => void;
  errorMessage: string | null;
}

const ResetPassword: FC<ResetPasswordProps> = ({
  token,
  user,
  history,
  postUpdatePassword,
  errorMessage,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const classes = useStyles();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /* tslint:disable:cyclomatic-complexity */
  const initialValues: ResetPasswordPayload = {
    password: '',
  };
  return (
    <div className={classes.root}>
      <AgencyBackground />
      <Paper className={classes.paperBack}>
        <Formik
          initialValues={initialValues}
          onSubmit={(data) => {
            postUpdatePassword({
              username: user,
              password: data.password,
              forgot_token: token,
            });
          }}
          validateOnChange
          validationSchema={VALIDATION_SCHEMA}
          render={({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <div className={classes.formStyle}>
              <div className={classes.titleStyle}>
                <Typography variant="h4" className={classes.labelStyle}>
                  {LABELS.RESET_PASSWORD}
                </Typography>
              </div>
              <FormControl error={!!errors.password && !!touched.password}>
                <div className={classes.inputContainerStyle}>
                  <Typography variant="subtitle1" className={classes.inputLabelStyle}>
                    {LABELS.PASSWORD}
                  </Typography>
                  <Input
                    className={classes.inputStyle}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event) => {
                      setFieldValue('password', event.target.value);
                    }}
                    value={values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                          <img src={showPassword ? visibilityOn : visibilityOff} alt="" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <Typography variant="caption" className={classes.enterPassword}>
                  {`Enter your new password.  `}
                </Typography>
                {errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
                <FormHelperText>
                  <ErrorMessage name="password" />
                </FormHelperText>
              </FormControl>
              <div className={classes.buttonContainer}>
                <FooterComponent
                  buttonText={LABELS.UPDATE_PASSWORD}
                  history={history}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          )}
        />
      </Paper>
    </div>
  );
};

export default ResetPassword;
