/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import BlogCard from '../../component/blogCard/BlogCard';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import { prefillForms } from '../../helper/PrefillFormHelper';
import { routes } from '../../Routes';
import {
  GetApplicationsPayload,
  SubmittedApplicationResponse,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { UpdatingType } from '../../services/dashboard/updating/Updating';
import localStorage from '../../services/LocalStorage';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { LoginActions } from '../../store/actions/LoginActions';
import { ApplicationState } from '../../store/RootReducers';
import { BLOGS_BASIC, BLOGS_COMPLETE, DashboardItemType, LABELS } from './DashboardConstants';
import { useStyles } from './DashboardStyles';
import {
  CustomTermsHeaderActions,
  CustomTermsHeaderPayload,
} from '../../store/actions/CustomTermsHeaderActions';
import { getDashboardItems } from './DashboardUtils';
import { ApplicationsResponse, GroupRequest } from '../../services/groups/Groups';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import WelcomeToSorted from './components/WelcomeToSorted/WelcomeToSorted';
import ProfileSection from './components/ProfileSection/ProfileSection';
import ProfileCompletion from './components/ProfileCompletion/ProfileCompletion';
import PartneredBrands from './components/PartneredBrands/PartneredBrands';
import PartneredAgencies from './components/PartneredAgencies/PartneredAgencies';
import NoApplications from './components/NoApplications/NoApplications';
import InfoRequested from './components/InfoRequested/InfoRequested';
import DraftActive from './components/DraftActive/DraftActive';
import ApplicationInvitation from './components/ApplicationInvitation/ApplicationInvitation';
import { PROFILE_FORMS as ProfileForm } from '../../constants/AppConstants';
import DownloadSorted from './components/DownloadSorted/DownloadSorted';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';
import { GroupsActions, GroupsActionTypes } from '../../store/actions/GroupsActions';
import FindAProperty from './components/FindAProperty/FindAProperty';
import SortedApplications from './components/SortedApplications/SortedApplications';
import WhileYouWait from './components/WhileYouWait/WhileYouWait';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import SyncProfile from './components/SyncProfile/SyncProfile';
import SignNow from './components/SignNow/SignNow';
import NTDBanner from '../../component/ntdBanner/NTDBanner';
import { canShowNTDBanner } from '../../helper/NTDHelper';
import { LandingScreenActions } from '../../store/actions/LandingScreenAction';
import DeletedInfo from './components/DeletedInfo/DeletedInfo';

interface MyAccountProps extends RouteComponentProps {
  masterProfileData: SubmittedApplicationResponse;
  isPrefillHelperCalled: boolean;
  isUpdating: UpdatingType;
  groupApplications: ApplicationsResponse | undefined;
  myProfileDetails: ApplicantProfile | undefined;
  loading: boolean;
  showDeletedBanner: boolean;
  getDeletedData: () => void;
  getAllApplications: () => void;
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null,
  ) => void;
  setPrefillHelperCalled: (isCalled: boolean) => void;
  refreshMasterProfile: () => void;
  getTermsHeader: (data: CustomTermsHeaderPayload) => void;
  setMyAccountFlag: (value: boolean) => void;
  getGroupMasterProfile: (data: GroupRequest) => void;
  clearProperties: () => void;
}

const MyAccount: FC<MyAccountProps> = ({
  history,
  masterProfileData,
  isPrefillHelperCalled,
  isUpdating,
  groupApplications,
  myProfileDetails,
  loading,
  showDeletedBanner,
  getDeletedData,
  prefillData,
  setPrefillHelperCalled,
  getAllApplications,
  refreshMasterProfile,
  getTermsHeader,
  setMyAccountFlag,
  getGroupMasterProfile,
  clearProperties,
}) => {
  const classes = useStyles();

  const dashboardItems = useMemo<DashboardItemType[]>(() => {
    return getDashboardItems(groupApplications, myProfileDetails, false);
  }, [groupApplications, myProfileDetails]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Removes any preexisting properties in case you don't complete the flow
    clearProperties();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('userId') || localStorage.getItem('userId') === 'undefined') {
      history.push({
        pathname: routes.login.view,
      });
      return;
    }
    getGroupMasterProfile({});
    getAllApplications();
    getDeletedData();
    getTermsHeader({
      agency: 'SORTED',
    });
    setMyAccountFlag(true);
  }, []);

  useEffect(() => {
    if (!isPrefillHelperCalled && masterProfileData) {
      prefillData(masterProfileData, isUpdating !== false, false, null);
      setPrefillHelperCalled(true);
    } else if (!masterProfileData) {
      refreshMasterProfile();
    }
  }, [masterProfileData, isPrefillHelperCalled, isUpdating]);

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>{LABELS.TITLE(localStorage.getItem('firstname')!)}</div>
        </div>
        {loading ? (
          <div className={classes.pageContent}>
            <div className={classes.primaryContainer}>
              {[...Array(1).fill(null)].map((_, i) => (
                <>
                  <Box key={i} parentStyles={classes.loadingPrimary} loading />
                  <Box key={i} parentStyles={classes.loadingPrimary2} loading />
                  <Box key={i} parentStyles={classes.loadingPrimary} loading />
                  <Box key={i} parentStyles={classes.loadingPrimary2} loading />
                  <Box key={i} parentStyles={classes.loadingPrimary2} loading />
                </>
              ))}
            </div>
            <div className={classes.secondaryContainer}>
              {[...Array(1).fill(null)].map((_, i) => (
                <>
                  <Box key={i} parentStyles={classes.loadingSecondary2} loading />
                  <Box key={i} parentStyles={classes.loadingSecondary} loading />
                  <Box key={i} parentStyles={classes.loadingSecondary} loading />
                </>
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.pageContent}>
            <div className={classes.primaryContainer}>
              {showDeletedBanner && <DeletedInfo />}
              {dashboardItems.includes(DashboardItemType.INVITATION) && <ApplicationInvitation />}
              {dashboardItems.includes(DashboardItemType.INFO_REQUESTED) && <InfoRequested />}
              {dashboardItems.includes(DashboardItemType.SYNC_PROFILE) && <SyncProfile />}
              {dashboardItems.includes(DashboardItemType.WELCOME) && <WelcomeToSorted />}
              {dashboardItems.includes(DashboardItemType.SIGN_NOW) && <SignNow />}
              {dashboardItems.includes(DashboardItemType.WHILE_YOU_WAIT) && <WhileYouWait />}
              {dashboardItems.includes(DashboardItemType.NO_APPLICATIONS) && <NoApplications />}
              {dashboardItems.includes(DashboardItemType.DRAFT_ACTIVE) && <DraftActive />}
              {dashboardItems.includes(DashboardItemType.BRANDS) &&
                dashboardItems.includes(DashboardItemType.SIGN_NOW) && <PartneredBrands />}
              {!dashboardItems.includes(DashboardItemType.SIGN_NOW) && (
                <>
                  <Text parentStyles={classes.gettingStarted} textVariant="boxTitle">
                    {LABELS.GETTING_STARTED}
                  </Text>
                  <div className={classes.profileSectionRow}>
                    {dashboardItems.includes(
                      DashboardItemType.PROFILE_SECTION_PERSONAL_DETAILS,
                    ) && <ProfileSection type={ProfileForm.PERSONAL_DETAILS_FORM} />}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_INTRO) && (
                      <ProfileSection type={ProfileForm.INTRODUCTION} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_CURRENT_INCOME) && (
                      <ProfileSection type={ProfileForm.CURRENT_INCOME_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_EMPLOYMENT) && (
                      <ProfileSection type={ProfileForm.EMPLOYMENT_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_PETS) && (
                      <ProfileSection type={ProfileForm.PETS_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_VEHICLES) && (
                      <ProfileSection type={ProfileForm.VEHICLES_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_ADDRESS) && (
                      <ProfileSection type={ProfileForm.ADDRESS_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_REFERENCE) && (
                      <ProfileSection type={ProfileForm.REFERENCE_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_IDENTIFICATION) && (
                      <ProfileSection type={ProfileForm.IDENTIFICATION_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.PROFILE_SECTION_DECLARATION) && (
                      <ProfileSection type={ProfileForm.DECLARATION_FORM} />
                    )}
                    {dashboardItems.includes(DashboardItemType.FIND_A_PROPERTY) && (
                      <FindAProperty />
                    )}
                    {dashboardItems.includes(DashboardItemType.SORTED_APPLICATIONS) && (
                      <SortedApplications />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={classes.secondaryContainer}>
              {dashboardItems.includes(DashboardItemType.PROFILE) && <ProfileCompletion />}
              {myProfileDetails && canShowNTDBanner(myProfileDetails) && (
                <NTDBanner small variant={2} applicantProfile={myProfileDetails} />
              )}
              <Text textVariant="boxTitle">{LABELS.FROM_THE_BLOG}</Text>

              {(!dashboardItems.includes(DashboardItemType.SIGN_NOW)
                ? BLOGS_BASIC
                : BLOGS_COMPLETE
              ).map((blog, idx) => (
                <BlogCard
                  key={idx}
                  title={blog.title}
                  body={blog.body}
                  link={blog.link}
                  image={blog.image}
                />
              ))}
            </div>
          </div>
        )}
        {loading ? (
          <>
            {[...Array(2).fill(null)].map((_, i) => (
              <Box key={i} parentStyles={classes.loadingBottom} loading />
            ))}
          </>
        ) : (
          <>
            {dashboardItems.includes(DashboardItemType.BRANDS) &&
              !dashboardItems.includes(DashboardItemType.SIGN_NOW) && <PartneredBrands />}
            {dashboardItems.includes(DashboardItemType.DOWNLOAD_SORTED) && <DownloadSorted />}
            {dashboardItems.includes(DashboardItemType.AGENCIES) && <PartneredAgencies />}
          </>
        )}
      </div>
    </div>
  );
};

const loading = loadingSelector([
  GroupsActionTypes.GET_GROUP_MASTER_PROFILE,
  GroupsActionTypes.GET_ALL_APPLICATIONS,
]);

const mapStateToProps = (state: ApplicationState) => ({
  myProfileDetails: state.myProfileForm,
  groupApplications: state.groups.groupApplications,
  masterProfileData: state.masterProfile.masterProfileData!,
  isPrefillHelperCalled: state.dashboard.isPrefillHelperCalled,
  isUpdating: state.dashboard.isUpdating,
  showDeletedBanner: state.dashboard.showDataDeletedBanner,
  loading: loading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null = null,
  ) => {
    prefillForms(data, dispatch, isUpdating, propertyList, propertyRefId);
  },
  getAllApplications: () => {
    dispatch(GroupsActions.getAllApplicationsRequest());
  },
  setPrefillHelperCalled: (isCalled: boolean) => {
    dispatch(DashboardActions.setPrefillHelperCalled(isCalled));
  },
  refreshMasterProfile: () => {
    dispatch(LoginActions.refreshMasterProfile());
  },
  getTermsHeader: (data: CustomTermsHeaderPayload) => {
    dispatch(CustomTermsHeaderActions.postCustomTermsHeaderStart(data));
  },
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
  getGroupMasterProfile: (data: GroupRequest) => {
    dispatch(GroupsActions.getGroupMasterProfileRequest(data));
  },
  clearProperties: () => {
    dispatch(LandingScreenActions.clearProperties());
    dispatch(DashboardActions.clearPropertyDetails());
  },
  getDeletedData: () => {
    dispatch(DashboardActions.getDeletedInfoRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyAccount));
