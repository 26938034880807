/* eslint-disable import/prefer-default-export */
import ThankyouImage7 from '../../assets/articles/thankyou-image7.png';
import clock from '../../assets/whatsNext/clock.png';
import mail from '../../assets/whatsNext/mail.png';
import edit from '../../assets/whatsNext/edit.png';
import truck from '../../assets/whatsNext/truck.png';

export const LABELS = {
  RENTAL_APPLICATION_SUCCESSFULL: 'Your rental application has been sent successfully!',
  BUTTON_TEXT: 'SAVE',
  DRAFT_SAVED: 'Your application has been saved!',
  SUCCESS_TITLE: 'Nice work!',
  SUCCESS_SUBITLE:
    'Your application has been submitted. Take a look at your application summary below',
  SUCCESS_SYNC: (single: boolean) =>
    `Your profile has been synced with your ${single ? 'application' : 'applications'}`,
  SUCCESS_UPDATE: 'Your application has been updated',
  WHATS_NEXT: 'What happens next?',
  NEXT_1: 'The agent will review your application',
  NEXT_2: 'View or edit your application any time in your account',
  NEXT_3: 'Apply for other properties listed with',
  NEXT_4: 'Login to your Sorted profile for application status updates',
  HOw_TO: 'How to make your rental application shine',
  HOW_1: '10 ways to make your rental application stand out ...',
  HOW_2: 'Inside advice from Property Managers ...',
  HOW_3: 'How to secure a rental property with no rental history',
  READMORE: 'Read more',
  PROPERTIES: (single: boolean) => `${single ? 'Your property' : 'Your properties'}`,
  FOR_YOU: 'For you',
  BEDROOMS: 'bedrooms',
  BATHROOMS: 'bathrooms',
  CARSPACES: 'car spaces',
  RENT_PW: 'Rent pw',
};

export const links = {
  noRentHistory:
    'https://www.sortedservices.com/blog-spot/how-to-secure-a-rental-property-with-no-rental-history',
  insideAdvice: 'https://www.sortedservices.com/blog-spot/inside-advice-from-property-managers',
  standOut: 'https://www.sortedservices.com/blog-spot/application-stand-out-tips',
};

export const BLOGS_BASIC = [
  {
    title: 'How to make your next move, a stress free one with Sorted',
    body: 'Moving into a new place is exciting, however also quite stressful. So to help take the stress out, we’ve put this action plan list together so it’s one less thing you need to do ...',
    link: 'http://www.sortedservices.com/blog-spot/how-to-make-your-next-move-a-stress-free-one-with-sorted',
    image: ThankyouImage7,
  },
];

export const WHAT_HAPPENS_NEXT = [
  {
    icon: clock,
    time: '2-3 days',
    title: 'Application review',
    description: 'The agent will review your application and conduct background checks',
  },
  {
    icon: mail,
    time: '+3 days',
    title: "You'll be notified",
    description:
      "Keep an eye on your messages and email (remember to check junk spam) if successful you'll get a link to accept the property",
  },
  {
    icon: edit,
    time: '3-7 days',
    title: 'Accept your property',
    description:
      'Fingers crossed you get your preferred place! Then you have to digitaly sign your agreement! The details will be in your email',
  },
  {
    icon: truck,
    time: '+7 days',
    title: 'Move in!',
    description:
      'Download Sorted to help get you ready for the move. Removalists, services, you can managed everything using your Sorted apply account',
  },
];
