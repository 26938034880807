import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { maxContentWidth, pageContainer, smallPageContainer } from '../../../../theme/GlobalStyles';
import { getFonts } from '../../../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...pageContainer,
    paddingBottom: 0,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  boxStyling: {
    marginTop: theme.units.spacing * 8,
  },
  loadingProperty: {
    marginTop: theme.units.spacing * 8,
    height: 80,
  },
  loadingStep: {
    width: '100%',
    height: 230,
  },
  loadingStep2: {
    marginLeft: theme.units.spacing * 4,
    width: '100%',
    height: 230,
  },
  propertyContainer: {
    // marginTop: '30px',
    // border: `${theme.colors.borderColor} 2px solid`,
    // borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  propertyImageContainer: {
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.lightGrey,
    // border: `${theme.colors.borderColor} 2px solid`,
    overflow: 'hidden',
    width: '80px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  propertyImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  propertyImageBlank: {
    position: 'relative',
    top: '-1px',
  },
  propertyDetails: {
    marginRight: 'auto',
    marginLeft: theme.units.spacing * 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  propertyAddress: {
    marginBottom: theme.units.spacing * 3,
  },
  propertySuburb: {
    opacity: 0.38,
  },
  weeklyAmount: {
    fontSize: 26,
    color: theme.colors.success,
    marginRight: '15px',
  },
  naxLogoContainer: {
    height: '70px',
  },
  naxLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  stepsContainer: {
    marginTop: theme.units.spacing * 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  activeStepContainer: {
    padding: theme.units.spacing * 6,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.lightPurple,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  minorSteps: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.units.spacing * 4,
    width: '100%',
    flex: 1,
  },
  stepContainer: {
    padding: theme.units.spacing * 4,
    borderRadius: theme.units.borderRadius,
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColorLight} 2px solid`,
    flex: 1,
  },
  statusRow: {
    marginBottom: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusWhite: {
    backgroundColor: theme.colors.light,
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    padding: `${theme.units.spacing}px ${theme.units.spacing * 3}px`,
    borderRadius: theme.units.borderRadius / 3,
  },
  statusGrey: {
    backgroundColor: theme.colors.borderColorLight,
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    padding: `${theme.units.spacing}px ${theme.units.spacing * 3}px`,
    borderRadius: theme.units.borderRadius / 3,
  },
  statusDone: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.black87,
    fontWeight: getFonts('Medium'),
    fontSize: 14,
    padding: `${theme.units.spacing}px ${theme.units.spacing * 3}px`,
    borderRadius: theme.units.borderRadius / 3,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 500,
    backgroundColor: theme.colors.error,
  },
  title: {
    marginBottom: theme.units.spacing * 2,
  },
  subtitle: {},
  actionContainer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  action: {
    height: 35,
  },
  questionsContent: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    backgroundColor: theme.colors.lightGrey,
  },
  questionsContent2: {
    ...maxContentWidth,
  },
  questionsContainer: {
    ...pageContainer,
    marginTop: theme.units.spacing * 16,
    paddingTop: theme.units.spacing * 12,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  bodyText: {
    marginTop: theme.units.spacing * 4,
    marginBottom: theme.units.spacing * 8,
  },
  questionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  questionsFAQ: {
    maxWidth: 800,
  },
  questionsFAQContainer: {
    paddingTop: theme.units.spacing * 4,
  },
  FAQFooter: {
    marginTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  findAnswer: {
    fontWeight: getFonts('Medium'),
  },
  contact: {
    marginLeft: theme.units.spacing * 2,
  },
  propertyManager: {
    maxWidth: 360,
    flexShrink: 0,
    width: '100%',
    marginLeft: theme.units.spacing * 8,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.spacing * 8,
      marginLeft: 0,
    },
  },
  propertyManagerContainer: {},
  propertyManagerTopRow: {
    display: 'flex',
    alignItems: 'center',
  },
  gravatar: {
    height: '50px',
    width: '50px',
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '24px',
    border: `${theme.colors.light} 1px solid`,
    flexShrink: 0,
  },
  propertyManagerNameContainer: {
    marginLeft: theme.units.spacing * 4,
  },
  propertyManagerBottomRow: {
    marginTop: theme.units.spacing * 4,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 2px solid`,
    display: 'grid',
    gridGap: theme.units.spacing * 2,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
  },
  detail: {
    textAlign: 'right',
    fontWeight: getFonts('Medium'),
  },
});
