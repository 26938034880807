import {
  CreateMaintenanceRequestStartPayload,
  CreateMaintenanceRequestSuccessResponse,
  UpdateMaintenanceRequestStartPayload,
} from '../../services/MaintenanceRequest/CreateMaintenanceRequest/CreateMaintenanceRequestPayload';
import { StoreAction } from '../StoreHelper';

export enum MaintenanceRequestActionsTypes {
  CREATE_MAINTENANCE_REQUEST = 'CREATE_MAINTENANCE_REQUEST',
  CREATE_MAINTENANCE_REQUEST_REQUEST = 'CREATE_MAINTENANCE_REQUEST_REQUEST',
  CREATE_MAINTENANCE_REQUEST_SUCCESS = 'CREATE_MAINTENANCE_REQUEST_SUCCESS',
  CREATE_MAINTENANCE_REQUEST_RESET = 'CREATE_MAINTENANCE_REQUEST_RESET',
  CREATE_MAINTENANCE_REQUEST_ERROR = 'CREATE_MAINTENANCE_REQUEST_ERROR',

  UPDATE_MAINTENANCE_REQUEST = 'UPDATE_MAINTENANCE_REQUEST',
  UPDATE_MAINTENANCE_REQUEST_REQUEST = 'UPDATE_MAINTENANCE_REQUEST_REQUEST',
  UPDATE_MAINTENANCE_REQUEST_SUCCESS = 'UPDATE_MAINTENANCE_REQUEST_SUCCESS',
  UPDATE_MAINTENANCE_REQUEST_RESET = 'UPDATE_MAINTENANCE_REQUEST_RESET',

  MAINTENANCE_REQUEST_ERROR = 'MAINTENANCE_REQUEST_ERROR',
}

export type MaintenanceRequestErrorPayload = Error | any;

export type MaintenanceRequestPayload =
  | CreateMaintenanceRequestStartPayload
  | CreateMaintenanceRequestSuccessResponse
  | UpdateMaintenanceRequestStartPayload
  | null;

export type MaintenanceRequestAction = StoreAction<
  MaintenanceRequestActionsTypes,
  MaintenanceRequestPayload
>;

export class MaintenanceRequestActions {
  public static createMaintenanceRequestStart = (
    data: CreateMaintenanceRequestStartPayload,
  ): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_REQUEST,
    data,
  });

  public static createMaintenanceRequestSuccess = (
    data: CreateMaintenanceRequestSuccessResponse,
  ): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_SUCCESS,
    data,
  });

  public static maintenanceRequestError = (
    data: MaintenanceRequestErrorPayload,
  ): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.MAINTENANCE_REQUEST_ERROR,
    data,
  });

  public static createMaintenanceRequestReset = (): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_RESET,
    data: null,
  });

  public static createMaintenanceRequestError = (
    data: MaintenanceRequestErrorPayload,
  ): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.CREATE_MAINTENANCE_REQUEST_ERROR,
    data,
  });

  public static updateMaintenanceRequestStart = (
    data: UpdateMaintenanceRequestStartPayload,
  ): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.UPDATE_MAINTENANCE_REQUEST_REQUEST,
    data,
  });

  public static updateMaintenanceRequestSuccess = (
    data: CreateMaintenanceRequestSuccessResponse,
  ): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.UPDATE_MAINTENANCE_REQUEST_SUCCESS,
    data,
  });

  public static updateMaintenanceRequestReset = (): MaintenanceRequestAction => ({
    type: MaintenanceRequestActionsTypes.UPDATE_MAINTENANCE_REQUEST_RESET,
    data: null,
  });
}
