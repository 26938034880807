import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './BackgroundCheckBannerStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../store/RootReducers';
import { LABELS, BackgroundCheckContent, RetryMethod } from './BackgroundCheckBannerConstants';
import Text from '../text/Text';
import Button from '../button/Button';
import pendingIcon from '../../assets/pending.png';
import successIcon from '../../assets/success.png';
import errorIcon from '../../assets/error.png';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import {
  EquifaxPaymentStatus,
  EquifaxResponse,
  EquifaxResponseDictionary,
  EquifaxStatus,
} from '../../models/equifax/equifax';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { classNameGenerator } from '../../theme/GlobalStyles';
import NTDSidebar from '../ntdSidebar/NTDSidebar';
import { isExpired } from '../../helper/NTDHelper';

interface BackgroundCheckBannerProps extends RouteComponentProps {
  applicantProfile?: ApplicantProfile;
  index?: number;
  ntdChecks: EquifaxResponseDictionary | undefined;
  masterProfileData: SubmittedApplicationResponse;
}

const BackgroundCheckBanner: FC<BackgroundCheckBannerProps> = ({
  applicantProfile,
  ntdChecks,
  index,
  masterProfileData,
}) => {
  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [retryMethod, setRetryMethod] = useState<RetryMethod | undefined>(undefined);

  const ntdData = useMemo<EquifaxResponse | undefined>(() => {
    if (ntdChecks && applicantProfile && applicantProfile.refId)
      return ntdChecks[applicantProfile.refId];
    return undefined;
  }, [ntdChecks, applicantProfile]);

  const content = useMemo<BackgroundCheckContent>(() => {
    if (ntdData) {
      // If background check is pending or payment is pending, show pending bannner
      if (
        [EquifaxStatus.REQUESTED, EquifaxStatus.PROCESSING, null].includes(ntdData.status) ||
        (EquifaxStatus.COMPLETED === ntdData.status &&
          (!ntdData.payment ||
            (ntdData.payment && ntdData.payment.status === EquifaxPaymentStatus.PENDING)))
      ) {
        return {
          icon: pendingIcon,
          title: LABELS.BACKGROUND_CHECK_PENDING,
          description: LABELS.BACKGROUND_CHECK_PENDING_TEXT,
          type: 'PENDING',
        };
        // If background check failed, show failed background check banner
      } else if (
        EquifaxStatus.FAILED === ntdData.status ||
        EquifaxStatus.NOT_AVAILABLE === ntdData.status
      ) {
        return {
          icon: errorIcon,
          title: LABELS.BACKGROUND_CHECK_FAILED,
          description: LABELS.BACKGROUND_CHECK_FAILED_TEXT,
          type: 'ERROR',
          button: (
            <Button
              parentStyles={classes.button}
              onPress={() => {
                setRetryMethod(RetryMethod.RETRY_CHECK);
                setOpenSidebar(true);
              }}
            >
              {LABELS.RETRY_AGAIN}
            </Button>
          ),
        };
        // If payment failed, show failed payment banner
      } else if (
        EquifaxStatus.COMPLETED === ntdData.status &&
        ntdData.payment &&
        ntdData.payment.status === EquifaxPaymentStatus.DECLINED
      ) {
        return {
          icon: errorIcon,
          title: LABELS.PAYMENT_FAILED,
          description: `${LABELS.PAYMENT_FAILED_TEXT} ${ntdData.payment.rejectReason}`,
          type: 'ERROR',
          button: (
            <Button
              parentStyles={classes.button}
              onPress={() => {
                setRetryMethod(RetryMethod.RETRY_PAYMENT);
                setOpenSidebar(true);
              }}
            >
              {LABELS.RETRY_PAYMENT}
            </Button>
          ),
        };
      } else if (
        EquifaxStatus.ARCHIVED === ntdData.status &&
        ntdData.payment &&
        ntdData.payment.status === EquifaxPaymentStatus.APPROVED
      ) {
        return {
          icon: errorIcon,
          title: LABELS.BACKGROUND_CHECK_EXPIRED,
          description: LABELS.BACKGROUND_CHECK_EXPIRED_TEXT,
          type: 'NORMAL',
          button: (
            <Button
              parentStyles={classes.button}
              onPress={() => {
                setRetryMethod(RetryMethod.RETRY_CHECK);
                setOpenSidebar(true);
              }}
            >
              {LABELS.START_AGAIN}
            </Button>
          ),
        };
        // Otherwise show success banner
      } else {
        return {
          icon: successIcon,
          title: LABELS.BACKGROUND_CHECK_SUCCESS,
          description: LABELS.BACKGROUND_CHECK_SUCCESS_TEXT,
          type: 'SUCCESS',
        };
      }
    } else {
      // const outOf = masterProfileData.applicants.length;
      const excluded = masterProfileData.applicants.filter(
        (a) => !a.ntdActive && a.ntdPurchased,
      ).length;
      const included = masterProfileData.applicants.filter(
        (a) => a.ntdActive && a.ntdPurchased && !isExpired(a.ntdDate!),
      ).length;

      if (included >= 1) {
        return {
          icon: successIcon,
          title: LABELS.BACKGROUND_CHECK_INCLUDED(included > 1),
          description: LABELS.BACKGROUND_CHECK_INCLUDED_TEXT,
          type: 'NORMAL',
        };
      } else if (excluded >= 1) {
        return {
          icon: errorIcon,
          title: LABELS.BACKGROUND_CHECK_EXCLUDED(excluded > 1),
          description: LABELS.BACKGROUND_CHECK_EXCLUDED_TEXT,
          type: 'NORMAL',
        };
      } else {
        return {
          icon: errorIcon,
          title: LABELS.NO_BACKGROUND_CHECK,
          description: LABELS.NO_BACKGROUND_CHECK_TEXT,
          type: 'NORMAL',
        };
      }
    }
  }, [ntdData, masterProfileData]);

  return (
    <>
      <div
        className={classNameGenerator([
          classes.bannerContainer,
          content.type === 'ERROR' && classes.errorBackground,
          content.type === 'PENDING' && classes.warningBackground,
          content.type === 'SUCCESS' && classes.successBackground,
        ])}
      >
        <div className={classes.bannerContent}>
          <img src={content.icon} className={classes.icon} alt="status" />
          <div className={classes.content}>
            <Text textVariant="contentTitle">{content.title}</Text>
            <Text textVariant="info">{content.description}</Text>
          </div>
          {content.button && content.button}
        </div>
      </div>
      <NTDSidebar
        openSidebar={openSidebar}
        setOpenSidebar={(value: boolean) => setOpenSidebar(value)}
        applicantProfile={applicantProfile!}
        index={index}
        retryPayment={retryMethod === RetryMethod.RETRY_PAYMENT}
        retryCheck={retryMethod === RetryMethod.RETRY_CHECK}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ntdChecks: state.equifaxState.ntdChecks,
  masterProfileData: state.masterProfile.masterProfileData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BackgroundCheckBanner));
