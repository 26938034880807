/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { globalStyles, maxContentWidth } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    height: '100%',
  },
  paperBack: globalStyles.paperBack as any,
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  contentContainer: {
    paddingLeft: '96px',
    paddingRight: '96px',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  previewPadding: {
    margin: theme.units.spacing * 4,
  },
  previewDocumentButtonContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
    padding: theme.units.spacing * 4,
  },
});
