import { makeStyles } from '@mui/styles';
import { color, layerIndex, themeConstants } from '../TenancyTheme';
import removeIcon from '../../../../assets/remove_icon.png';

export const useStyles = makeStyles({
  root: {
    marginTop: 0,
  },
  photoGrid: {
    marginTop: 10,
  },
  photoCell: {
    width: 180,
    height: 100,
  },
  photoThumbnail: {
    width: 170,
    height: 90,
  },
  photoThumbnailAdd: {
    // backgroundImage: `url("${photolibAdd}")`,
    width: 170,
    height: 90,
    cursor: 'pointer',
  },
  photoDeleteIcon: {
    position: 'relative',
    backgroundImage: `url("${removeIcon}")`,
    width: 20,
    height: 20,
    top: -98,
    left: 159,
    fontSize: '1.8rem',
    cursor: 'pointer',
    zIndex: layerIndex.iconAboveForm,
  },
  documentGrid: {
    marginTop: 10,
    display: 'flex',
  },
  documentItemContainer: {
    width: '100%',
  },
  documentItem: {
    backgroundColor: themeConstants.inputBackgroundColor,
  },
  documentItemOverlayContainer: {
    width: 'calc(100% - 70px)',
    height: 0,
    position: 'relative',
    top: '-50',
    zIndex: layerIndex.labelAboveInput,
  },
  documentItemOverlay: {
    cursor: 'pointer',
    height: 50,
  },
  documentBrowseButton: {
    width: 100,
    height: 50,
    marginRight: 20,
  },
  documentDownloadIcon: {
    cursor: 'pointer',
    color: color.dark900,
    padding: '7px 12px 7px 12px',
    marginTop: 15,
  },
  documentRemoveIcon: {
    cursor: 'pointer',
    color: color.dark900,
    padding: '7px 12px 7px 12px',
    marginTop: 15,
  },
  thumbnailRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
    },
    '& td': {
      padding: '10px 10px',
    },
  },
  thumbnailCell: {
    width: 110,
    height: 60,
    borderRadius: 8,
  },
  videoThumbnailWrapper: {
    display: 'flex',
    width: '78px',
    height: '58px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid gray',
    borderRadius: '8%',
    background: 'rgba(0,0,0,.5)',
  },
});
