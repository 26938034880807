import { makeStyles } from '@mui/styles';
import { theme } from '../../../theme/Theme';
import referenceScreen from '../../../assets/reference-tool.jpg';

export const useStyles = makeStyles({
  screenRootStyle: {
    display: 'flex',
    justifyContent: 'space-around',
    [`@media (max-width: 37.5rem)`]: {
      position: 'absolute',
      background: `url(${referenceScreen})`,
    },
  },
  screenBodyStyle: {
    padding: '2.25rem',
    background: theme.colors.light,
    [`@media (max-width: 37.5rem)`]: {
      maxWidth: '90%',
      marginTop: '5%',
      bottom: 0,
      padding: '1rem',
    },
  },
  sortedLogoStyle: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '6.5rem',
    paddingTop: '2.25rem',
    [`@media (max-width: 43.5rem)`]: {
      paddingTop: '1rem',
    },
  },
  imageStyle: {
    height: '2rem',
    [`@media (max-width: 37.5rem)`]: {
      height: '1.5rem',
    },
  },
  referenceToolStyle: {
    paddingLeft: '1rem',
    fontFamily: 'GT Walsheim',
    fontSize: '1.5rem',
    fontWeight: 500,
    alignItems: 'center',
    color: theme.colors.sortedPurple,
    borderLeft: `0.125rem solid ${theme.colors.sortedPurple}`,
    marginLeft: '1rem',
  },
  referenceNameStyle: {
    fontWeight: 500,
    fontSize: '2.625rem',
    fontFamily: 'GT Walsheim',
    color: theme.colors.sortedPurple,
    paddingBottom: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      paddingBottom: '1.75rem',
      fontSize: '2.297rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      paddingBottom: '0.75rem',
      fontSize: '1.969rem',
    },
  },
  requestedRefereeStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: '1.375rem',
    fontWeight: 500,
    color: theme.colors.sortedPurple,
    paddingBottom: '2rem',
    [`@media (max-width: 53.125rem)`]: {
      paddingBottom: '1.75rem',
      fontSize: '1.203rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      paddingBottom: '3rem',
      fontSize: '1.o31rem',
    },
  },
  purposeDiscriptionStyle: {
    fontFamily: 'GT Walsheim',
    fontSize: '1rem',
    color: '#202020',
    letterSpacing: '0.009375rem',
    paddingBottom: '4.125rem',
    [`@media (max-width: 53.125rem)`]: {
      paddingBottom: '3.625rem',
      fontSize: '0.875rem',
      letterSpacing: '0.008125rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      paddingBottom: '14.813rem',
      fontSize: '0.75rem',
      letterSpacing: '0.006875rem',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  referenceBtnStyle: {
    maxWidth: '600px',
    width: '100%',
    height: '50px',
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
    border: `0.125rem solid ${theme.colors.secondary}`,
    marginBottom: '0.75rem',
    textTransform: 'none',
    fontFamily: 'GT Walsheim',
    fontSize: '0.775rem',
    [`@media (max-width: 53.125rem)`]: {
      marginBottom: '1.188rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      marginBottom: '1.188rem',
    },
  },
  sortedInfoStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.sortedPurple,
  },
  knowMoreStyle: {
    textAlign: 'center',
    fontSize: '0.675rem',
  },
  learnMoreStyle: {
    fontWeight: 600,
    textTransform: 'none',
    fontFamily: 'GT Walsheim',
    fontSize: '0.675rem',
    letterSpacing: '-0.0125rem',
    color: theme.colors.sortedPurple,
  },
  referenceImageStyle: {
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${referenceScreen})`,
    [`@media (max-width: 37.5rem)`]: {
      display: 'none',
    },
  },
  dataLoadingStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: theme.colors.sortedPurple,
    color: 'white',
    textAlign: 'center',
  },
});
