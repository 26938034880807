import { StoreAction } from '../StoreHelper';

export enum FileLoadingSpinnerActionTypes {
  ShowSpinner = 'SHOW_FILE_LOADING',
  HideSpinner = 'HIDE_FILE_LOADING',
}

export type FileLoadingSpinnerAction = StoreAction<FileLoadingSpinnerActionTypes, null>;

export class FileLoadingSpinnerActions {
  public static show(): FileLoadingSpinnerAction {
    return { type: FileLoadingSpinnerActionTypes.ShowSpinner, data: null };
  }
  public static hide(): FileLoadingSpinnerAction {
    return { type: FileLoadingSpinnerActionTypes.HideSpinner, data: null };
  }
}
