import React, { FC } from 'react';
import { useStyles } from './ErrorBannerStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../text/Text';
import { InfoOutlined } from '@mui/icons-material';
import { LABELS } from './ErrorBannerConstants';
import { Box } from '@mui/material';
import Button from '../button/Button';

interface ErrorBannerProps extends RouteComponentProps {
  errorMessage: string;
  showHelpButton?: boolean;
}

const ErrorBanner: FC<ErrorBannerProps> = ({ errorMessage, showHelpButton }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <InfoOutlined className={classes.infoIcon} color="error" />
      <Text textVariant="contentTitle">{errorMessage}</Text>
      {showHelpButton && (
        <Box className={classes.helpSection}>
          <Text textVariant="info">{LABELS.HELP_MESSAGE}</Text>
          <Button onPress={() => (window as any).Intercom('show')}>{LABELS.REPORT_ISSUE}</Button>
        </Box>
      )}
    </div>
  );
};

export default withRouter(ErrorBanner);
