/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  buttonContainer: {
    width: 'max-content',
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
    textAlign: 'center',
    fontFamily: theme.font.family,
    fontWeight: 500,
    fontSize: theme.font.m,
    paddingLeft: theme.units.spacing * 6,
    paddingRight: theme.units.spacing * 6,
    paddingTop: theme.units.spacing * 2,
    paddingBottom: theme.units.spacing * 2,
    borderRadius: theme.units.borderRadius / 1.5,
    border: `${theme.colors.buttonPrimary} 2px solid`,
    backgroundColor: theme.colors.buttonPrimary,
    textTransform: theme.font.buttonCase as any,
    cursor: 'pointer',
    boxSizing: 'content-box',
    position: 'relative',
  },
  muted: {
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    },
  },
  outline: {
    border: `${theme.colors.buttonPrimary} 2px solid`,
    backgroundColor: theme.colors.light,
    color: theme.colors.buttonPrimary,
    transition: 'all 0.3s ease 0s',
    '&:hover': {
      backgroundColor: theme.colors.buttonPrimary,
      color: theme.colors.light,
      border: `${theme.colors.buttonPrimary} 2px solid`,
      transition: 'all 0.3s ease 0s',
    },
  },
  disabled: {
    opacity: 0.4,
  },
  loading: {
    position: 'absolute',
    width: 16,
    height: 16,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    border: '4px solid transparent',
    borderTopColor: '#ffffff',
    borderRadius: '50%',
    animation: 'button-loading-spinner 1s ease infinite',
  },
  loadingOutline: {
    position: 'absolute',
    width: 16,
    height: 16,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    border: '4px solid transparent',
    borderTopColor: theme.colors.secondary,
    borderRadius: '50%',
    animation: 'button-loading-spinner 1s ease infinite',
  },
  hide: {
    opacity: 0,
  },
});
