import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import {
  maxContentWidth,
  pageContainer,
  pageTitleContainer,
  primaryContent,
  secondaryContent,
  smallPageContainer,
} from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  pageContainer: {
    ...smallPageContainer,
  },
  titleContainer: {
    ...pageTitleContainer,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addGroup: {
    marginBottom: theme.units.spacing * 4,
  },

  pageContent: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  primaryContainer: {
    ...primaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      maxWidth: '100%',
    },
  },
  secondaryContainer: {
    ...secondaryContent,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 12,
      maxWidth: '100%',
    },
  },
  groupsContainer: {
    display: 'grid',
    gridGap: theme.units.spacing * 6,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',
    transition: 'all 0.5s ease 0s',
    height: '100%',
  },
  showAllContainer: {
    position: 'relative',
    marginTop: -100,
    height: 150,
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0))',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  loadingCard: {
    height: 200,
  },
  notifications: {
    width: 500,
  },
  noManagedContainer: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noManaged: {
    marginBottom: theme.units.spacing * 6,
  },
  createNew: {
    minWidth: 40,
    backgroundColor: theme.colors.filterBackground,
    borderColor: theme.colors.filterBackground,
    color: theme.colors.grey,
  },
});
