import { CalculationHelper } from '../../../../helper/CalculationHelper';
import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../../../constants/AppConstants';

export const LABELS = {
  PERSONAL_DETAILS: 'Personal Details',
  EMAIL_ADDRESS: 'Email address',
  SALUTATION: 'Title',
  FIRSTNAME: 'First Name',
  MIDDLENAME: 'Middle Name',
  SURNAME: 'Surname',
  MOBILE: 'Mobile',
  DATE_OF_BIRTH: 'Date of Birth',
  DO_YOU_HAVE_A_GUARANTOR: 'Do you have a guarantor?',
  GUARANTOR_NAME: 'Guarantor name',
  CONTACT_NUMBER: 'Contact number',
  BUTTON_TEXT: 'Save and next',
  NEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  EMAIL_ERROR: "Applicant's email cannot be same",
  EMERGENCY_CONTACT: 'Emergency Contact :',
  EMERGENCY_CONTACT_RELATIONSHIP: 'Relationship to you',
  EMERGENCY_CONTACT_NAME: 'Name',
  DEPENDENT_INFO1: 'Please enter the date of birth of your managed applicant. ',
  DEPENDENT_INFO2: 'For dependents under the age of 18, no additional information is required.',
};

export const ERRORS = {
  requiredTitle: 'Title required',
  requiredFirstName: 'Enter first name',
  requiredSurname: 'Enter surname',
  requiredDob: 'Enter date of birth ',
  requiredEmail: 'Please provide your email address',
  requiredMobileNumber: 'Enter mobile number',
  requiredGuarantorName: 'Enter guarantor name',
  requiredGuarantorNumber: 'Enter guarantor contact number',
  requiredGuarantorEmail: 'Please provide guarantor email address',
  invalidEmailFormat: 'Invalid email format',
  invalidNameFormat: 'Invalid name format',
  invalidDateFormat: 'Invalid date format',
  invalidFutureDate: 'Date cannot be in the future',
  maxStringLength: 'Can be at most 255 characters',
  invalidCountryNumber: 'Invalid phone number',
  requiredEmergencyContactName: 'Enter emergency contact name',
  requiredEmergencyContactRelationship: 'Enter relationship with you',
  requiredEmergencyContactEmail: 'Please provide emergency contact email address',
  requiredEmergencyContactNumber: 'Enter emergency contact mobile number',
  noDuplicateMail: 'This e-mail has already been used for another profile',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  hasGuarantor: Yup.boolean(),
  firstName: Yup.string()
    .nullable()
    .max(255, ERRORS.maxStringLength)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  title: Yup.string()
    // .required(ERRORS.requiredTitle)
    .nullable(),
  lastName: Yup.string()
    .nullable()
    .max(255, ERRORS.maxStringLength)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
  selectedDateOfBirth: Yup.date()
    .max(new Date(), ERRORS.invalidFutureDate)
    .nullable()
    .typeError('Invalid date'),
  email: Yup.string()
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string()
        .max(255, ERRORS.maxStringLength)
        .nullable()
        // .required(ERRORS.requiredEmail)
        .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
    })
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => !CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    }),
  mobile: Yup.string()
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    })
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => !CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    }),
  emergencyContactName: Yup.string()
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string()
        .nullable()
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
    })
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => !CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    }),
  emergencyContactRelationship: Yup.string()
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string()
        .nullable()
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
    })
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => !CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    }),
  emergencyContactEmail: Yup.string()
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string()
        .max(255, ERRORS.maxStringLength)
        .nullable()
        .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
    })
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => !CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    }),
  emergencyContactMobile: Yup.string()
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    })
    .when('selectedDateOfBirth', {
      is: (selectedDateOfBirth) => !CalculationHelper.isAbove18(selectedDateOfBirth),
      then: Yup.string().nullable(),
    }),
  guarantorName: Yup.string()
    .when('hasGuarantor', {
      is: true,
      then: Yup.string()
        .nullable()
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
    })
    .when('hasGuarantor', {
      is: false,
      then: Yup.string().nullable(),
    }),
  guarantorContactNumber: Yup.string()
    .when('hasGuarantor', {
      is: true,
      then: Yup.string().nullable(),
    })
    .when('hasGuarantor', {
      is: false,
      then: Yup.string().nullable(),
    }),
  guarantorEmail: Yup.string()
    .when('hasGuarantor', {
      is: true,
      then: Yup.string()
        .max(255, ERRORS.maxStringLength)
        .nullable()
        .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
    })
    .when('hasGuarantor', {
      is: false,
      then: Yup.string().nullable(),
    }),
});
