import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  root: {
    backgroundColor: theme.colors.light,
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
    },
    maxWidth: '600px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  title: {
    fontSize: '36px',
  },
  closeIcon: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  subtitleText: {
    color: theme.colors.darkGrey,
    fontSize: '16px',
    marginBottom: '20px',
    width: '85%',
  },
  buttonContainer: {
    margin: 'auto',
    width: '100%',
    maxWidth: '1000px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    color: theme.colors.black87,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
    },
  },
  primaryButton: {
    marginLeft: '30px',
    width: '50%',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sortedPurple,
    border: `${theme.colors.sortedPurple} 2px solid`,
    borderRadius: '6px',
    color: theme.colors.light,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      marginLeft: '10px',
      width: '100%',
    },
  },
  disabled: {
    backgroundColor: theme.colors.textarea,
    border: `none`,
    height: '54px',
    color: theme.colors.label,
  },
  check: {
    width: '25px',
    height: '25px',
    marginLeft: '15px',
    marginRight: '5px',
  },
  optionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 15px 20px 20px',
    border: `1px ${theme.colors.borderColor} solid`,
    borderRadius: '6px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  optionText: {
    fontSize: '15px',
    marginRight: 'auto',
    marginLeft: '15px',
    fontWeight: 100,
  },
  optionsContainer: {
    marginBottom: '10px',
  },
  infoBox: {
    borderRadius: '6px',
    padding: '15px',
    backgroundColor: theme.colors.fadedBlue,
    color: theme.colors.success,
    marginBottom: '20px',
  },
});
