/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO: Refactor to MakeStyles with MaterialUI
import React, { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { RegisterClientResponse } from '../../services/login/LoginData';
import Like from '../../assets/like.svg';
import LinkExpired from '../../assets/shape.svg';
import { useStyles } from './RegisterConfirmationStyles';
import { LABELS } from './RegisterConfirmationConstants';
import { routes } from '../../Routes';
import { ApplicationState } from '../../store/RootReducers';
import { LoginActions, LoginActionTypes } from '../../store/actions/LoginActions';
import SortedLogo from '../../assets/powered-by-sorted-v.png';
import { maintenancePagesURLIdentifier } from '../../constants/AppConstants';
import { registerClient } from '../login/LoginUtils';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import Button from '../../component/button/Button';

interface RegistrationConfirmationProps extends RouteComponentProps {
  error: Error | string;
  registerClientDetails: RegisterClientResponse;
  postRegisterClient: () => void;
  verifyUserDetails: (token: string) => void;
  classes: any;
}

const RegistrationConfirmation: FC<RegistrationConfirmationProps> = ({
  history,
  location,
  error,
  verifyUserDetails,
  registerClientDetails,
  postRegisterClient,
}) => {
  const classes = useStyles();
  // const [maintenance] = useState<boolean>(
  //   location.search.split('redirectLink=')[1].includes(maintenancePagesURLIdentifier),
  // );

  const getToken = (): string => {
    const pair = location.search.split('token=');
    return decodeURIComponent(pair[1]);
  };

  useEffect(() => {
    const clientId = localStorage.getItem('clientId');
    if (!clientId || clientId === 'undefined') {
      postRegisterClient();
    }
  }, []);

  useEffect(() => {
    if (registerClientDetails) {
      registerClient(registerClientDetails);
    }
  }, [registerClientDetails]);

  useEffect(() => {
    const token = getToken();
    verifyUserDetails(token);
  }, []);

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const registerRedirect = () => {
    const pair = location.search.split('redirectLink=');
    const baseURL = window.location.toString().split('#/')[0];
    window.location.replace(`${baseURL}#/${pair[1]}`);
  };
  return (
    <div>
      {error ? (
        <div className={classes.registrationConfirmationContainer}>
          <img className={classes.successLogo} src={LinkExpired} alt="link expired" />
          <Typography variant="h6">{LABELS.LINK_EXPIRED}</Typography>
          <Typography variant="body2">{LABELS.GO_TO_LOGIN}</Typography>
          <div className={classes.buttonContainer}>
            <Button onPress={() => redirectTo(routes.login.view)}>{LABELS.BUTTON_TEXT}</Button>
          </div>
        </div>
      ) : (
        <div className={classes.registrationConfirmationContainer}>
          <div className={classes.registrationConfirmationWrapper}>
            <img className={classes.successLogo} src={Like} alt="success logo" />
            <div className={classes.titleText}>{LABELS.EMAIL_VERIFIED}</div>
            <div className={classes.bodyText}>{LABELS.MOVE_BACK}</div>
            <div className={classes.buttonContainer}>
              <Button onPress={() => registerRedirect()}>{LABELS.BUTTON_TEXT}</Button>
            </div>
            <img className={classes.sortedLogo} src={SortedLogo} alt="sorted logo" />
          </div>
        </div>
      )}
    </div>
  );
};

const error = errorMessageSelector([LoginActionTypes.REGISTER_USER]);

const mapStateToProps = (state: ApplicationState) => ({
  error: error(state),
  registerClientDetails: state.login.registerClientDetails,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  verifyUserDetails: (token: string) => {
    dispatch(LoginActions.postVerifyUserStart({ token }));
  },
  postRegisterClient: () => {
    dispatch(LoginActions.postRegisterClientStart());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationConfirmation));
