import * as Yup from 'yup';
import { EMAIL_PATTERN } from '../../../../constants/AppConstants';
import redBanner from '../../../../assets/banner-blue.png';
import peachBanner from '../../../../assets/banner-peach.png';
import purpleBanner from '../../../../assets/banner-purple.png';
import { theme } from '../../../../theme/Theme';
import welcomeImage from '../../../../assets/welcome-image.png';
import acceptedImage from '../../../../assets/accepted-image.png';
import waitingImage from '../../../../assets/waiting-image.jpg';

export const LABELS = {
  LOGIN_TITLE: 'Log in with your Sorted account',
  LOGIN_SUBTITLE: 'Enter your log in details below to get started!',
  SIGNUP_HEADER: 'Sign up',
  EMAIL_ADDRESS: 'Email',
  PASSWORD: 'Password',
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  FORGOT_PASSWORD: 'Forgot Password?',
  RESEND_LINK: 'Resend Link',
  LOG_INTO_SORTED: 'Log into Sorted',
  LOGIN_BELOW: 'Log in below',
  ENTER: 'Enter your log in details below to log into your Sorted account',
  FORGOT: 'Forgot password?',
  LOGIN: 'Log in',
  DONT: "Don't have an account?",
  SIGNUP: 'Sign up',
  EMAIL: 'Email',
  WHY_SORTED: 'Why Sorted?',
};

export const ERRORS = {
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
  requiredPassword: 'Please enter password',
  minimumRequired: 'Minimum 8 characters required',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  password: Yup.string().required(ERRORS.requiredPassword).min(8, ERRORS.minimumRequired),
});

export const WHY_SORTED = [
  {
    image: welcomeImage,
    background: peachBanner,
    title: 'Simple services',
    body: 'Sorted is Australia’s first all-in-one home services marketplace – allowing you to connect everything you need for your home, all in one place.',
  },
  {
    image: acceptedImage,
    background: redBanner,
    title: 'Reuseable profile',
    body: 'Use your profile across all our platforms, from the Sorted app to the marketplace.',
  },
  {
    image: waitingImage,
    background: purpleBanner,
    title: 'Real nice humans',
    body: 'Our chat service is powered by real, local team members, ready to give you a hand if you need help with your profile or your applications.',
  },
];
