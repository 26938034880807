import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './AppReviewStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './AppReviewConstants';
import { routes } from '../../../../Routes';

interface AppReviewProps {}

const AppReview: FC<AppReviewProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text textVariant="contentTitle">{LABELS.TITLE}</Text>
      <Text textVariant="info">{LABELS.SUBTITLE}</Text>
      <Button
        parentStyles={classes.button}
        onPress={() => window.open('https://g.page/r/CXsOlIz2jmyiEBM/review', '_blank')}
      >
        {LABELS.BUTTON}
      </Button>
    </div>
  );
};

export default AppReview;
