import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { loadingAnimation } from '../../theme/GlobalStyles';

export const useStyles = makeStyles({
  agencyBackgroundContainer: {
    height: '280px',
    maxHeight: '280px',
    width: '100%',
    position: 'relative',
    ...loadingAnimation,
  },
  agencyBackground: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  agencyLogoContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    // padding: '30px',
    // [`@media (max-width: ${SIZES.md}px)`]: {
    //   paddingLeft: '15px',
    // },
    margin: 'auto',
  },
  agencyText: {
    fontSize: '38px',
    color: theme.colors.black87,
    maxWidth: '350px',
    position: 'relative',
    top: '30px',
    left: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '30px',
    },
  },
  agencyLogo: {
    backgroundColor: 'unset !important',
    position: 'relative',
    top: '48px',
    left: '96px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      top: '15px',
      left: '15px',
    },
    maxHeight: '120px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  reipLogo: {
    position: 'relative',
    right: '30px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      right: '0px',
    },
    maxHeight: '15vh',
  },
});
