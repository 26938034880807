import { UserType } from '../remoteSigning/user/User';
import { PaymentProvider } from '../remoteSigning/payment/Payment';

export interface AvailableServicesRequest {
  postcode: string;
  branchCode?: string;
}

export interface Logo {
  active: string;
  hero: string;
  inactive: string;
}

export interface Supplier {
  signupFlow: string;
  supplierId: string;
  supplierName: string;
  supplierLabel: string;
  description: string;
  descriptionBanner: string;
  descriptionFooter: string | null;
  pageTitle: string | null;
  logo: Logo;
  startingPrice: string | null;
}

export enum ServiceType {
  Rent = 'Rent',
  Electricity = 'Electricity',
  Gas = 'Gas',
  Broadband = 'Broadband',
  Water = 'Water',
  Callback = 'UtilityCallback',
  OtherService = 'OtherService',
}

export interface AvailableServicesResponse {
  serviceType: ServiceType;
  serviceName: string;
  serviceNameLabel: string | null;
  startingPrice: number | null;
  serviceabilityRequired: boolean;
  suppliers: Supplier[];
  logo: Logo;
}

export interface ProcurementCallbackRequest {
  id: number;
  userType: UserType;
  name: string;
  surname: string;
  email: string;
  phone: string;
  availableServices: string[];
  contactMe: boolean;
  agency: string; // agency trading name
  paymentType: PaymentProvider | string | undefined | null;
  leaseType: string;
  leaseId: number;
  onSuccess?: () => void;
}
