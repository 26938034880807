/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { FC, useState, useEffect, useRef } from 'react';

import { History } from 'history';
import { FormControl, Typography, FormHelperText } from '@mui/material';
import { Formik, ErrorMessage, FormikValues } from 'formik';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useStyles } from './IntroductionFormStyles';
import { LABELS, MAX_CHARACTERS_ALLOW } from './IntroductionFormConstants';
import FooterComponent from '../../../../component/footer/Footer';
import { PostIntroductionFormData } from '../../../../services/introduction/postFormDetails/PostIntroductionForm.data';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { routes } from '../../../../Routes';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { PostFormData } from '../../../../store/state/MyProfileFormState';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import localStorage from '../../../../services/LocalStorage';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import Input from '../../../../component/input/Input';
import InputDate from '../../../../component/inputDate/InputDate';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { isEmpty } from '../../../../helper/Validation';

interface IntroductionFormProps {
  introductionDetails: PostIntroductionFormData | undefined;
  index: number | undefined;
  updateProfileChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postIntroDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
}

const IntroductionForm: FC<IntroductionFormProps> = ({
  introductionDetails,
  index,
  updateProfileChangesFlag,
  setSelected,
  setChildFormRef,
  postIntroDetails,
}) => {
  const classes = useStyles();
  const countRemaining = (text: string, total: number = MAX_CHARACTERS_ALLOW): number => {
    if (!text) {
      return total;
    }
    return total - text.length;
  };
  const formRef = useRef<Formik<PostIntroductionFormData> | null>(null);
  const [remainingCharactersCount, setRemainingCharactersCount] = useState<number>(
    countRemaining(introductionDetails ? (introductionDetails.introText as string) : ''),
  );
  const [profileChangedFlag, setProfileChangedFlag] = useState<boolean>(false);

  const updateProfileState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChangedFlag) {
      updateProfileChangesFlag(true);
      setProfileChangedFlag(true);
    }
  };

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  const initialValues: PostIntroductionFormData = {
    introText: introductionDetails ? (introductionDetails.introText as string) : '',
  };

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      onSubmit={(formData) => {
        updateProfileState();
        postIntroDetails(
          formData,
          () => {
            setSelected(ProfileForm.CURRENT_INCOME_FORM);
          },
          index,
        );
      }}
      render={({ values, handleSubmit, setFieldValue }) => (
        <div className={classes.formStyle}>
          <FormControl className={classes.inputContainerStyle}>
            <Text parentStyles={classes.question}>{LABELS.FIELD}</Text>
            <textarea
              className={classes.inputLabelStyle}
              name="introText"
              placeholder={LABELS.PLACEHOLDER}
              onChange={(event) => {
                setFieldValue('introText', event.target.value);
                setRemainingCharactersCount(countRemaining(event.target.value));
                updateProfileState();
              }}
              value={values.introText || ''}
              maxLength={MAX_CHARACTERS_ALLOW}
            />
            <FormHelperText>
              <ErrorMessage name="introText" />
            </FormHelperText>
          </FormControl>
          <Text textVariant="info">{remainingCharactersCount} characters remaining</Text>
          <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
            {LABELS.BUTTON_TEXT}
          </Button>
        </div>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProfileChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateProfileChangesFlag(isChanged));
  },
  postIntroDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postIntroductionDetails(data, id))
      : dispatch(MyProfileAction.postIntroductionDetails(data));
    onSubmit();
  },
});

export default connect(null, mapDispatchToProps)(IntroductionForm);
