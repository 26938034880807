import { History, Location } from 'history';
import { routes } from '../../Routes';
import localStorage from '../../services/LocalStorage';
import { LoginResponse, RegisterClientResponse } from '../../services/login/LoginData';

// Clear auth state in local storage, keep other items like agency code intact
export const clearStorage = () => {
  localStorage.setItem('authToken', '');
  localStorage.setItem('refreshToken', '');
  localStorage.setItem('firstname', '');
  localStorage.setItem('lastname', '');
  localStorage.setItem('email', '');
  localStorage.setItem('userId', '');
  localStorage.setItem('draftId', '');
  localStorage.setItem('draftTitle', '');
  localStorage.setItem('state', '');
  localStorage.setItem('stateCreatedDate', '');
};

// If user verifies email, use the previous login URL with the property data
export const checkPreviousURL = () => {
  const previousLoginURL = localStorage.getItem('previousLoginURL');
  if (previousLoginURL) {
    localStorage.removeItem('previousLoginURL');
    window.location.replace(previousLoginURL);
  }
};

// Registers the client window to allow authenticated API requests
export const registerClient = (registerClientDetails: RegisterClientResponse | undefined) => {
  if (registerClientDetails) {
    const { client_id, client_secret } = registerClientDetails;
    localStorage.setItem('clientId', client_id);
    localStorage.setItem('clientSecret', client_secret);
    const basicAuthHash = window.btoa(`${client_id}:${client_secret}`);
    localStorage.setItem('authTokenBasic', basicAuthHash);
  }
};

// Log in the client and redirect them based on their previous route
export const loginClient = (loginDetails: LoginResponse | undefined) => {
  if (loginDetails && !localStorage.getItem('userId')) {
    const { authToken, refreshToken, title, name, surname, email, userId } = loginDetails;
    if (authToken) {
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('title', title);
      localStorage.setItem('firstname', name);
      localStorage.setItem('lastname', surname);
      localStorage.setItem('email', email);
      localStorage.setItem('userId', userId.toString());

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }
  }
};

export const redirect = (history: History, location: Location) => {
  const setPreviousPath = history.location.pathname ? history.location.pathname : null;
  const setPreviousSearch = history.location.search ? history.location.search : null;
  if (setPreviousPath) {
    history.location.state = setPreviousPath;
  }
  if (setPreviousSearch) {
    history.location.state = `${history.location.state}${setPreviousSearch}`;
  }
  if (location.state && location.state.prevPath === 'overview') {
    history.push(routes.applicationPreferences.new);
  } else if (location.state === '/login') {
    history.push(routes.home.view);
  } else {
    history.push(history.location.state);
  }
};
