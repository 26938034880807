import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helper/GetFonts';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '1.375rem',
    color: theme.colors.darkGrey,
    textAlign: 'justify',
    paddingBottom: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '1.203rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '1.031rem',
    },
  },
  questionLabel: {
    color: theme.colors.darkGrey,
    fontFamily: 'GT Walsheim',
    fontSize: '1rem',
    letterSpacing: '0.009375rem',
    paddingTop: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '0.875rem',
      letterSpacing: '0.008125rem',
      color: theme.colors.darkGrey,
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '0.75rem',
      letterSpacing: '0.006875rem',
      color: theme.colors.grey,
    },
  },
  buttonContainer: {
    width: '100%',
    padding: '1rem 0',
  },
  buttonStyle: {
    textTransform: 'capitalize',
    borderRadius: theme.units.borderRadius / 2,
    color: theme.colors.black87,
    background: theme.colors.lightGrey,
    // margin: '0.125rem 1rem',
    marginRight: theme.units.spacing * 4,
    width: '15%',
    fontFamily: 'GT Walsheim',
    fontWeight: getFonts('Medium'),
  },
  buttonChecked: {
    color: theme.colors.light,
    background: theme.colors.secondary,
    [`@media (max-width: 43.5rem)`]: {
      '&:hover': {
        backgroundColor: theme.colors.secondary,
      },
    },
    [`@media (min-width: 43.5rem)`]: {
      '&:hover': {
        backgroundColor: theme.colors.secondary,
      },
    },
  },
  buttonWrapperStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nextButtonStyle: {
    width: '14.688rem',
    height: '3rem',
    fontFamily: 'GT Walsheim',
    fontWeight: getFonts('Medium'),
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
    '&:hover': {
      backgroundColor: theme.colors.secondary,
    },
  },
  requiredErrorStyle: {
    margin: '5px',
    color: theme.colors.error,
    display: 'flex',
    justifyContent: 'flex-start',
    width: 'inherit',
    fontFamily: 'GT Walsheim',
    fontSize: '0.875rem',
    letterSpacing: '0.008125rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '0.788rem',
      letterSpacing: '0.0075rem',
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '0.675rem',
      letterSpacing: '0.00625rem',
    },
  },
  htmlQuestionLabel: {
    color: theme.colors.darkGrey,
    fontFamily: 'GT Walsheim',
    fontSize: '1rem',
    letterSpacing: '0.009375rem',
    paddingTop: '1rem',
    [`@media (max-width: 53.125rem)`]: {
      fontSize: '0.875rem',
      letterSpacing: '0.008125rem',
      color: theme.colors.darkGrey,
    },
    [`@media (max-width: 43.5rem)`]: {
      fontSize: '0.75rem',
      letterSpacing: '0.006875rem',
      color: theme.colors.grey,
    },
    fontWeight: 300
  }
});
