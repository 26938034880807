/* eslint-disable no-prototype-builtins */
/* eslint-disable import/prefer-default-export */
import { FileLink } from '../FileUpload/FileUploadState.data';
import { CreateMaintenanceRequestFormData } from './CreateMaintenanceRequestForm.data';
import { CreateMaintenanceRequestStartPayload } from '../../../../services/MaintenanceRequest/CreateMaintenanceRequest/CreateMaintenanceRequestPayload';
import { MaintenanceRequestStatus } from '../../../../models/MaintenanceRequest/MaintenanceRequest.model';
import { Content } from '../../../../helper/Content/Content.data';
import { PersonalDetailsFormValues } from '../PersonalDetails/PersonalDetailsConstants';

const isBoolean = (val: any) => typeof val === 'boolean';
export class MaintenanceRequestConverter {
  public static convertFormDataToStartPayload = (
    data: CreateMaintenanceRequestFormData,
    propertyId: number,

    requestingUser: PersonalDetailsFormValues,
    leaseId?: number,
  ): CreateMaintenanceRequestStartPayload => {
    return {
      data: {
        propertyId,
        leaseId,
        status: MaintenanceRequestStatus.RECEIVED,
        location: data.location!,
        tenantIds: data.tenant ? [data.tenant.id] : [],
        details: {
          images: data.photos
            ? (data.photos.filter((image) => image.hasOwnProperty('link')) as FileLink[])
            : [],
          title: data.title,
          description: data.description,
          parkingAvailability: isBoolean(data.parkingAvailability)
            ? data.parkingAvailability
            : null,
          clearAccess: isBoolean(data.clearAccess) ? data.clearAccess : null,
          propertyAccess: data.propertyAccess
            ? {
                propertyAccessType: data.propertyAccess.propertyAccessType!,
                description: '',
              }
            : isBoolean(data.propertyAccess)
            ? data.propertyAccess
            : null,
          unSupervisedMinors: data.unSupervisedMinors,
          preferredAvailability: data.preferredAvailability
            ? {
                timeSlot: data.preferredAvailability.timeSlot!,
                otherTime: data.preferredAvailability.otherTime,
                days: data.preferredAvailability.days.map((day) => day.toUpperCase()),
              }
            : null,
          appliance: data.appliance
            ? {
                present: data.appliance.present!,
                make: data.appliance.make,
                model: data.appliance.model,
                warranty: data.appliance.warranty,
              }
            : isBoolean(data.appliance)
            ? data.appliance
            : null,
          pet: data.pet
            ? {
                present: data.pet.present!,
                description: data.pet.description!,
              }
            : isBoolean(data.pet)
            ? data.pet
            : null,
          stairs: data.stairs
            ? {
                present: data.stairs.present!,
                description: data.stairs.description!,
              }
            : isBoolean(data.stairs)
            ? data.stairs
            : null,
        },
        openRequestDetail: {
          reCaptchaResponseToken: data.reCaptchaResponseToken,
          requestingUser,
          notes: '',
        },
      },
      images: data.photos
        ? (data.photos.filter((image) => image.hasOwnProperty('contentRef')) as Content[])
        : [],
    };
  };
}
