import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  gravatar: {
    height: 35,
    width: 35,
    minWidth: 35,
    marginLeft: -8,
    zIndex: 2,
    borderRadius: 5000,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.light,
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
    border: `${theme.colors.light} 1px solid`,
  },
});
