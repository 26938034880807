/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';

export const useStyles = makeStyles({
  triangle: {
    position: 'relative',
    width: 0,
    height: 0,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightColor: 'transparent',
    borderBottomStyle: 'solid',
    borderBottomColor: '#f2f2f2',
    display: 'flex',
    justifyContent: 'center',
    // animation: 'spin 300s linear infinite',
  },
  innerTriangle: {
    position: 'relative',
    width: 0,
    height: 0,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightColor: 'transparent',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.light,
  },
});
