/* eslint-disable import/prefer-default-export */
export const LABELS = {
  SUBMIT_TITLE: 'Submit application',
  SYNC_PROFILE: 'Sync profile',
  UPDATE_TITLE: 'Update application',
  SUBMIT_SUBTITLE: (single: boolean) =>
    `Confirm your details below and your selected ${
      single ? 'property' : 'properties'
    } will be added to your application`,
  UPDATE_SUBTITLE: (single: boolean, sync: boolean) =>
    `Your ${single ? 'application' : 'applications'} will be ${
      sync ? 'synced' : 'updated'
    } with the following details and the selected ${single ? 'property' : 'properties'} below`,
  PROPERTIES: (single: boolean) => `${single ? 'Your property' : 'Your properties'}`,
  PROPERTIES_TEXT: (single: boolean) =>
    `The following ${
      single ? 'property' : 'properties'
    } will be added to your application when you submit your application`,
  UPDATE_TEXT: (single: boolean, sync: boolean) =>
    `Your ${single ? 'application' : 'applications'} will be ${
      sync ? 'synced' : 'updated'
    } with the following ${single ? 'property' : 'properties'} when you hit submit`,
  SYNC: 'Sync',
  UPDATE: 'Update',
  SUBMIT: 'Submit',
  BACK: 'Back',
  BEDROOMS: 'bedrooms',
  BATHROOMS: 'bathrooms',
  CARSPACES: 'car spaces',
  RENTAL_OFFER: 'Rental offer',
  RENT_PW: 'Rent pw',
  DID_YOU_INSPECT: 'Have you inspected this property?',
  INSPECTION_DATE: 'When did you inspect this property?',
  PREFERRED_MOVE_IN_DATE: 'Preferred move in date',
  PREFERRED_LEASE_DURATION: 'Preferred lease duration',
  GROUP: 'Group',
};
