import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { withRouter, match, RouteComponentProps } from 'react-router';
import { Dispatch, AnyAction } from 'redux';
import { PropertyDetailsResponse } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { ApplicationState } from '../../store/RootReducers';
import ResetPassword from '../../component/resetPassword/ResetPassword';
import { ResetPasswordPayload } from '../../services/login/LoginData';
import { LoginActions, LoginActionTypes } from '../../store/actions/LoginActions';
import { routes } from '../../Routes';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';

interface Props {
  history: History;
  match: match;
}

interface StateProps {
  errorMessage: string | null;
}
interface PropsFromState {
  propertyDetails: PropertyDetailsResponse;
  selectedProperties: PropertyDetailsResponse[];
  resetPasswordError: any; // Error object
}

interface PropsFromDispatch {
  readonly postUpdatePassword: (data: ResetPasswordPayload, onSubmit: () => void) => void;
}

type OwnProps = PropsFromState & Props & PropsFromDispatch & RouteComponentProps;

class ResetPasswordContainer extends React.Component<OwnProps, StateProps> {
  constructor(props: OwnProps) {
    super(props);
    this.state = { errorMessage: null };
  }
  public componentWillReceiveProps(nextProps: OwnProps) {
    if (
      nextProps.resetPasswordError &&
      nextProps.resetPasswordError.response &&
      nextProps.resetPasswordError.response.status === 400
    ) {
      this.setState({
        errorMessage: 'Your password reset link is already used',
      });
    }
  }

  public getToken(param: string): string {
    const vars = this.props.location.search.split('&');
    const pair = vars[0].split('token=');
    return decodeURIComponent(pair[1]);
  }

  public getUser(param: string): string {
    const pair = this.props.location.search.split('user=');
    return decodeURIComponent(pair[1]);
  }

  private updatePassword = (data: ResetPasswordPayload) => {
    this.props.postUpdatePassword(data, () => {
      this.setState({
        errorMessage: null,
      });
      this.props.history.push(routes.resetPasswordConfirmation.new);
    });
  };

  public render() {
    const { history, propertyDetails, selectedProperties } = this.props;
    const token = this.getToken('token');
    const user = this.getUser('user');
    return (
      <ResetPassword
        history={history}
        token={token}
        user={user}
        postUpdatePassword={this.updatePassword}
        errorMessage={this.state.errorMessage}
      />
    );
  }
}

const error = errorMessageSelector([LoginActionTypes.RESET_PASSWORD]);

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  propertyDetails: state.dashboard.propertyDetails as PropertyDetailsResponse,
  selectedProperties: state.landingScreen.properties as PropertyDetailsResponse[],
  resetPasswordError: error(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PropsFromDispatch => ({
  postUpdatePassword: (data: ResetPasswordPayload, onSuccess: () => void) => {
    dispatch(LoginActions.resetPasswordStart({ ...data, onSuccess }));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer));
