import { takeEvery, put } from 'redux-saga/effects';
import { routes } from '../../Routes';
import {
  ReferenceToolActionTypes,
  ReferenceToolActions,
  ReferenceToolAction,
} from '../actions/ReferenceToolActions';
import { LoadingIndicatorActions } from '../actions/LoadingIndicatorAction';
import ReferenceToolService from '../../services/referenceTool/ReferenceToolService';
import { store } from '../Store';
import { SuccessToastActions } from '../actions/ SuccessToastAction';
import { Logging } from '../../helper/Logging';
import { ReferenceToolDataState } from '../state/ReferenceToolState';

export function* getReferencesDataWorker(action: ReferenceToolAction) {
  const { token, history } = action.data;
  try {
    yield put(LoadingIndicatorActions.show());
    const response: ReferenceToolDataState = yield ReferenceToolService.getReferencesData(token);
    yield put(ReferenceToolActions.setReferencesData(response));
  } catch (error) {
    Logging.error('ReferenceToolSaga.getReferencesDataWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(ReferenceToolActions.getReferencesDataFailure(error as Error));
    history.push(routes.referenceToolError.view);
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}
export function* postSaveReferencesDataWorker(action: ReferenceToolAction): IterableIterator<any> {
  const { questionnaireData, onSuccess, history, sendAdditionalComment } = action.data;
  try {
    yield put(LoadingIndicatorActions.show());
    const postData = [...questionnaireData.questionnaireData];
    if (sendAdditionalComment) {
      postData.push({
        question: 'Additional comments',
        type: 'textbox',
        answer: questionnaireData['Additional comments'] || '',
      });
    }
    const { tokenId } = store.getState().referenceToolData;
    yield ReferenceToolService.postReferencesData(postData, tokenId!);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    Logging.error('ReferenceToolSaga.postSaveReferencesDataWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(ReferenceToolActions.postSaveReferencesDataFailure(error as Error));
    history.push(routes.referenceToolError.view);
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}
function* postResendEmailWorker(action: ReferenceToolAction): IterableIterator<any> {
  try {
    const data = action.data as number;
    yield put(LoadingIndicatorActions.show());
    yield ReferenceToolService.postResendEmail(data);
    yield put(SuccessToastActions.show('Email sent!'));
  } catch (error) {
    Logging.error('ReferenceToolSaga.postResendEmailWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(ReferenceToolActions.resendEmailError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

function* referenceToolWatcher() {
  yield takeEvery(ReferenceToolActionTypes.GET_REFERENCES_DATA, (action: ReferenceToolAction) =>
    getReferencesDataWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(
    ReferenceToolActionTypes.POST_SAVE_REFERENCES_DATA,
    (action: ReferenceToolAction) =>
      postSaveReferencesDataWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(ReferenceToolActionTypes.RESEND_EMAIL_REQUEST, (action: ReferenceToolAction) =>
    postResendEmailWorker({
      type: action.type,
      data: action.data,
    }),
  );
}

export default referenceToolWatcher;
