import {
  AvailableServicesResponse,
  ServiceType,
  Logo,
} from '../../../../services/marketplace/Marketplace';
import wifi from '../../../../assets/wifi.png';
import electricity from '../../../../assets/electricity.png';

const essentialServices = ['Electricity', 'Broadband', 'Gas'];

export interface Service extends AvailableServicesResponse {
  active?: boolean;
}

export const getEssentialServices = (
  availableServices: Service[] | undefined,
  agencyCode: string,
): Service[] => {
  if (!availableServices) return [];
  let filteredServices = [];
  // Turned off for everyone
  // availableServices.filter((service) =>
  //   essentialServices.includes(service.serviceName),
  // );

  if (agencyCode === 'AU_MERI') {
    filteredServices.push({
      serviceName: ServiceType.Electricity.toString(),
      serviceType: ServiceType.Electricity,
      active: false,
      logo: { active: electricity, inactive: '', hero: '' },
    } as Service);
    filteredServices.push({
      serviceName: ServiceType.Broadband.toString(),
      serviceType: ServiceType.Broadband,
      active: false,
      logo: { active: wifi, inactive: '', hero: '' },
    } as Service);
  }

  return filteredServices.map((service) => {
    service.active = false;
    return service;
  });
};

export const getOptionalServices = (availableServices: Service[] | undefined): Service[] => {
  if (!availableServices) return [];
  const filteredServices = availableServices.filter(
    (service) =>
      !essentialServices.includes(service.serviceName) &&
      service.serviceName !== 'Utility Callback' &&
      service.serviceName !== 'Finance',
  );
  return filteredServices.map((service) => {
    service.active = false;
    return service;
  });
};

export const getWalletValue = (serviceType: ServiceType): number => {
  switch (serviceType) {
    case ServiceType.Electricity:
      return 25;
    case ServiceType.Broadband:
      return 25;
    case ServiceType.Gas:
      return 0;
    default:
      return 0;
  }
};

export const getServiceName = (agencyCode: string, serviceName: string) => {
  if (agencyCode === 'AU_MERI' && serviceName === 'Broadband') {
    return 'Broadband - Occom';
  } else if (agencyCode === 'AU_MERI' && serviceName === 'Electricity') {
    return 'Electricity - Meriton Energy';
  } else {
    return serviceName;
  }
};
