/* eslint-disable max-lines */
import { GooglePlacesAutoComplete } from './GooglePlacesAutoComplete';
import { TypeHelper } from './TypeHelper';
import { AddressValue } from './AddressValue';

export const StreetTypeList = [
  {
    abbreviation: '',
    street_type: 'N/A',
  },
  {
    abbreviation: 'Accs',
    street_type: 'Access',
  },
  {
    abbreviation: 'Ally',
    street_type: 'Alley',
  },
  {
    abbreviation: 'Alwy',
    street_type: 'Alleyway',
  },
  {
    abbreviation: 'Ambl',
    street_type: 'Amble',
  },
  {
    abbreviation: 'App',
    street_type: 'Approach',
  },
  {
    abbreviation: 'Arc',
    street_type: 'Arcade',
  },
  {
    abbreviation: 'Artl',
    street_type: 'Arterial',
  },
  {
    abbreviation: 'Arty',
    street_type: 'Artery',
  },
  {
    abbreviation: 'Av',
    street_type: 'Avenue',
  },
  {
    abbreviation: 'Ave',
    street_type: 'Ave',
  },
  {
    abbreviation: 'Ba',
    street_type: 'Banan',
  },
  {
    abbreviation: 'Bend',
    street_type: 'Bend',
  },
  {
    abbreviation: 'Bwlk',
    street_type: 'Boardwalk',
  },
  {
    abbreviation: 'Bvd',
    street_type: 'Boulevard',
  },
  {
    abbreviation: 'Br',
    street_type: 'Brace',
  },
  {
    abbreviation: 'Brae',
    street_type: 'Brae',
  },
  {
    abbreviation: 'Brk',
    street_type: 'Break',
  },
  {
    abbreviation: 'Broadway',
    street_type: 'Broadway',
  },
  {
    abbreviation: 'Brow',
    street_type: 'Brow',
  },
  {
    abbreviation: 'Bypa',
    street_type: 'Bypass',
  },
  {
    abbreviation: 'Bywy',
    street_type: 'Byway',
  },
  {
    abbreviation: 'Cswy',
    street_type: 'Causeway',
  },
  {
    abbreviation: 'Ctr',
    street_type: 'Centre',
  },
  {
    abbreviation: 'Ch',
    street_type: 'Chase',
  },
  {
    abbreviation: 'Cir',
    street_type: 'Circle',
  },
  {
    abbreviation: 'Cct',
    street_type: 'Circuit',
  },
  {
    abbreviation: 'Crcs',
    street_type: 'Circus',
  },
  {
    abbreviation: 'Cl',
    street_type: 'Close',
  },
  {
    abbreviation: 'Con',
    street_type: 'Concourse',
  },
  {
    abbreviation: 'Cps',
    street_type: 'Copse',
  },
  {
    abbreviation: 'Cnr',
    street_type: 'Corner',
  },
  {
    abbreviation: 'Ct',
    street_type: 'Court',
  },
  {
    abbreviation: 'Ctyd',
    street_type: 'Courtyard',
  },
  {
    abbreviation: 'Cove',
    street_type: 'Cove',
  },
  {
    abbreviation: 'Cr',
    street_type: 'Crescent',
  },
  {
    abbreviation: 'Cres',
    street_type: 'Cres',
  },
  {
    abbreviation: 'Crst',
    street_type: 'Crest',
  },
  {
    abbreviation: 'Crss',
    street_type: 'Cross',
  },
  {
    abbreviation: 'Csac',
    street_type: 'Cul-de-sac',
  },
  {
    abbreviation: 'Cutt',
    street_type: 'Cutting',
  },
  {
    abbreviation: 'Dale',
    street_type: 'Dale',
  },
  {
    abbreviation: 'Dip',
    street_type: 'Dip',
  },
  {
    abbreviation: 'Dr',
    street_type: 'Drive',
  },
  {
    abbreviation: 'Dvwy',
    street_type: 'Driveway',
  },
  {
    abbreviation: 'Edge',
    street_type: 'Edge',
  },
  {
    abbreviation: 'Elb',
    street_type: 'Elbow',
  },
  {
    abbreviation: 'End',
    street_type: 'End',
  },
  {
    abbreviation: 'Ent',
    street_type: 'Entrance',
  },
  {
    abbreviation: 'Esp',
    street_type: 'Esplanade',
  },
  {
    abbreviation: 'Exp',
    street_type: 'Expressway',
  },
  {
    abbreviation: 'Fawy',
    street_type: 'Fairway',
  },
  {
    abbreviation: 'Folw',
    street_type: 'Follow',
  },
  {
    abbreviation: 'Ftwy',
    street_type: 'Footway',
  },
  {
    abbreviation: 'Form',
    street_type: 'Formation',
  },
  {
    abbreviation: 'Fwy',
    street_type: 'Freeway',
  },
  {
    abbreviation: 'Frtg',
    street_type: 'Frontage',
  },
  {
    abbreviation: 'Gap',
    street_type: 'Gap',
  },
  {
    abbreviation: 'Gateway',
    street_type: 'Gateway',
  },
  {
    abbreviation: 'Gdns',
    street_type: 'Gardens',
  },
  {
    abbreviation: 'Gte',
    street_type: 'Gate',
  },
  {
    abbreviation: 'Glde',
    street_type: 'Glade',
  },
  {
    abbreviation: 'Glen',
    street_type: 'Glen',
  },
  {
    abbreviation: 'Gra',
    street_type: 'Grange',
  },
  {
    abbreviation: 'Grn',
    street_type: 'Green',
  },
  {
    abbreviation: 'Gr',
    street_type: 'Grove',
  },
  {
    abbreviation: 'Hts',
    street_type: 'Heights',
  },
  {
    abbreviation: 'Hird',
    street_type: 'Highroad',
  },
  {
    abbreviation: 'Hwy',
    street_type: 'Highway',
  },
  {
    abbreviation: 'Hill',
    street_type: 'Hill',
  },
  {
    abbreviation: 'Intg',
    street_type: 'Interchange',
  },
  {
    abbreviation: 'Jnc',
    street_type: 'Junction',
  },
  {
    abbreviation: 'Key',
    street_type: 'Key',
  },
  {
    abbreviation: 'Landings',
    street_type: 'Landings',
  },
  {
    abbreviation: 'Ln',
    street_type: 'Lane',
  },
  {
    abbreviation: 'Lnwy',
    street_type: 'Laneway',
  },
  {
    abbreviation: 'Line',
    street_type: 'Line',
  },
  {
    abbreviation: 'Link',
    street_type: 'Link',
  },
  {
    abbreviation: 'Lkt',
    street_type: 'Lookout',
  },
  {
    abbreviation: 'Loop',
    street_type: 'Loop',
  },
  {
    abbreviation: 'Mall',
    street_type: 'Mall',
  },
  {
    abbreviation: 'Mndr',
    street_type: 'Meander',
  },
  {
    abbreviation: 'Mews',
    street_type: 'Mews',
  },
  {
    abbreviation: 'Mtwy',
    street_type: 'Motorway',
  },
  {
    abbreviation: 'Nook',
    street_type: 'Nook',
  },
  {
    abbreviation: 'Otlk',
    street_type: 'Outlook',
  },
  {
    abbreviation: 'Park',
    street_type: 'Park',
  },
  {
    abbreviation: 'Pde',
    street_type: 'Parade',
  },
  {
    abbreviation: 'Pl',
    street_type: 'Place',
  },
  {
    abbreviation: 'Pwy',
    street_type: 'Parkway',
  },
  {
    abbreviation: 'Pass',
    street_type: 'Pass',
  },
  {
    abbreviation: 'Psge',
    street_type: 'Passage',
  },
  {
    abbreviation: 'Path',
    street_type: 'Path',
  },
  {
    abbreviation: 'Pway',
    street_type: 'Pathway',
  },
  {
    abbreviation: 'Piaz',
    street_type: 'Piazza',
  },
  {
    abbreviation: 'Plza',
    street_type: 'Plaza',
  },
  {
    abbreviation: 'Pkt',
    street_type: 'Pocket',
  },
  {
    abbreviation: 'Pnt',
    street_type: 'Point',
  },
  {
    abbreviation: 'Port',
    street_type: 'Port',
  },
  {
    abbreviation: 'Prom',
    street_type: 'Promenade',
  },
  {
    abbreviation: 'Qdrt',
    street_type: 'Quadrant',
  },
  {
    abbreviation: 'Qys',
    street_type: 'Quays',
  },
  {
    abbreviation: 'Quay',
    street_type: 'Quay',
  },
  {
    abbreviation: 'Rmbl',
    street_type: 'Ramble',
  },
  {
    abbreviation: 'Rest',
    street_type: 'Rest',
  },
  {
    abbreviation: 'Rtt',
    street_type: 'Retreat',
  },
  {
    abbreviation: 'Rdge',
    street_type: 'Ridge',
  },
  {
    abbreviation: 'Rise',
    street_type: 'Rise',
  },
  {
    abbreviation: 'Rd',
    street_type: 'Road',
  },
  {
    abbreviation: 'Road East',
    street_type: 'Road East',
  },
  {
    abbreviation: 'Road North',
    street_type: 'Road North',
  },
  {
    abbreviation: 'Road South',
    street_type: 'Road South',
  },
  {
    abbreviation: 'Road West',
    street_type: 'Road West',
  },
  {
    abbreviation: 'Rty',
    street_type: 'Rotary',
  },
  {
    abbreviation: 'Rte',
    street_type: 'Route',
  },
  {
    abbreviation: 'Row',
    street_type: 'Row',
  },
  {
    abbreviation: 'Rue',
    street_type: 'Rue',
  },
  {
    abbreviation: 'Svwy',
    street_type: 'Serviceway',
  },
  {
    abbreviation: 'Shun',
    street_type: 'Shunt',
  },
  {
    abbreviation: 'Spur',
    street_type: 'Spur',
  },
  {
    abbreviation: 'Sq',
    street_type: 'Square',
  },
  {
    abbreviation: 'St',
    street_type: 'Street',
  },
  {
    abbreviation: 'Street East',
    street_type: 'Street East',
  },
  {
    abbreviation: 'Street North',
    street_type: 'Street North',
  },
  {
    abbreviation: 'Street South',
    street_type: 'Street South',
  },
  {
    abbreviation: 'Street West',
    street_type: 'Street West',
  },
  {
    abbreviation: 'Sbwy',
    street_type: 'Subway',
  },
  {
    abbreviation: 'Tarn',
    street_type: 'Tarn',
  },
  {
    abbreviation: 'Tce',
    street_type: 'Terrace',
  },
  {
    abbreviation: 'Thfr',
    street_type: 'Thoroughfare',
  },
  {
    abbreviation: 'Tlwy',
    street_type: 'Tollway',
  },
  {
    abbreviation: 'Top',
    street_type: 'Top',
  },
  {
    abbreviation: 'Tor',
    street_type: 'Tor',
  },
  {
    abbreviation: 'Trk',
    street_type: 'Track',
  },
  {
    abbreviation: 'Trl',
    street_type: 'Trail',
  },
  {
    abbreviation: 'Turn',
    street_type: 'Turn',
  },
  {
    abbreviation: 'Upas',
    street_type: 'Underpass',
  },
  {
    abbreviation: 'Vale',
    street_type: 'Vale',
  },
  {
    abbreviation: 'Viad',
    street_type: 'Viaduct',
  },
  {
    abbreviation: 'View',
    street_type: 'View',
  },
  {
    abbreviation: 'Vsta',
    street_type: 'Vista',
  },
  {
    abbreviation: 'Walk',
    street_type: 'Walk',
  },
  {
    abbreviation: 'Way',
    street_type: 'Way',
  },
  {
    abbreviation: 'Wkwy',
    street_type: 'Walkway',
  },
  {
    abbreviation: 'Whrf',
    street_type: 'Wharf',
  },
  {
    abbreviation: 'Woods',
    street_type: 'Woods',
  },
  {
    abbreviation: 'Wynd',
    street_type: 'Wynd',
  },
];

export type GooglePlaceResult = google.maps.places.PlaceResult;

export interface GoogleAddressData {
  address: string;
  unitNumber?: string | null;
  streetNumber: string;
  streetName?: string | null;
  suburb: string;
  state: string;
  postcode: string;
  country?: string | null;
  streetType?: string | null;
}

export class GooglePlacesHelper {
  // google is not avaiable when running the tests in jest
  public static createGooglePlacesAutoComplete(
    input: HTMLInputElement,
    listener: (value: AddressValue) => void,
    auOnly?: boolean,
  ): GooglePlacesAutoComplete {
    let ctr: google.maps.places.Autocomplete | null = null;

    if (typeof google !== 'undefined') {
      ctr = new google.maps.places.Autocomplete(input, {});
      if (auOnly) {
        ctr.setComponentRestrictions({ country: ['au'] });
      }
      ctr.addListener('place_changed', () =>
        listener({
          inputValue: input.value,
          place: (ctr as google.maps.places.Autocomplete).getPlace(),
        }),
      );
    } else {
      // Mock implementation for testing
      input.addEventListener('change', (e) => listener({ inputValue: input.value, place: null }));
    }

    return { input, listener, autoComplete: ctr };
  }

  /// <summary>
  /// Fix address according to this article.
  /// Google Places Autocomplete suggestions with Unit No/Subpremise is not coming in the response array
  /// https://stackoverflow.com/questions/17936689/google-places-autocomplete-suggestions-with-unit-no-subpremise-is-not-coming-in
  /// </summary>
  public static fixAddressSubPremise(address: AddressValue): AddressValue {
    if (address && address.inputValue && address.place && address.place.address_components) {
      const { inputValue } = address;
      const { place } = address;
      const route = place.address_components!.find(
        (t) => t.types && !!t.types.find((t2) => t2 === 'route'),
      );

      if (route && route.long_name) {
        const routePrefix = route.long_name.split(' ', 1)[0];
        const i = inputValue.indexOf(routePrefix);
        const j = place.formatted_address!.indexOf(routePrefix);

        if (i >= 0 && j >= 0) {
          const fullStreetNumber = inputValue.substr(0, i).trim();
          const routeIndex = place.address_components!.findIndex(
            (t) =>
              !!t.long_name &&
              t.long_name !== fullStreetNumber &&
              t.types &&
              !!t.types.find((t2) => t2 === 'street_number'),
          );

          if (routeIndex >= 0) {
            const newFormattedAddress = `${fullStreetNumber} ${place.formatted_address!.substr(j)}`;

            return {
              ...address,
              place: {
                ...place,
                formatted_address: newFormattedAddress,
                // arrayReplaceAt() handles invalid index
                address_components: TypeHelper.arrayReplaceAt(
                  place.address_components!,
                  routeIndex,
                  {
                    ...place.address_components![routeIndex],
                    long_name: fullStreetNumber,
                    short_name: fullStreetNumber,
                  },
                ),
              },
            };
          }
        }
      }
    }

    return address;
  }

  public static getAddressFieldsFromGoogleAPI(apiData: any) {
    let data: GoogleAddressData = {
      address: '',
      unitNumber: '',
      streetType: '',
      streetNumber: '',
      streetName: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    };
    if (apiData.place.address_components) {
      apiData.place.address_components.forEach((element: any) => {
        if (element.types.includes('street_number')) {
          data.streetNumber = element.long_name;
        } else if (element.types.includes('route')) {
          data.streetName = element.short_name;
        } else if (element.types.includes('locality')) {
          data.suburb = element.long_name;
        } else if (element.types.includes('administrative_area_level_1')) {
          data.state = element.short_name;
        } else if (element.types.includes('postal_code')) {
          data.postcode = element.long_name;
        } else if (element.types.includes('country')) {
          data.country = element.long_name;
        }
      });
      data = { ...data, ...GooglePlacesHelper.extractStreetTypeAndName(data.streetName) };
    }
    const { unitNumber, streetNumber, streetName, state, suburb, postcode, streetType } = data;
    const address = `${unitNumber ? `${unitNumber}/` : ''}${
      streetNumber || ''
    } ${streetName} ${streetType}, ${suburb}, ${state} ${postcode}`;
    data.address = address;
    return data;
  }

  public static extractStreetTypeAndName(street: string | undefined | null) {
    if (street) {
      const list = street.trim().split(' ');
      const lastValue = list.pop();
      const lastValueTemp = lastValue ? lastValue.toLowerCase() : '';
      const gotStreetType = StreetTypeList.some(
        (e) =>
          e.abbreviation.toLowerCase() === lastValueTemp ||
          e.street_type.toLowerCase() === lastValueTemp,
      );
      const streetType = gotStreetType ? lastValue : '';
      const streetName = gotStreetType ? list.join(' ') : street;
      return { streetType, streetName };
    }
    return { streetType: '', streetName: street };
  }
}
