import moment from 'moment';
import { DateFormats, daysInWeek } from '../constants/AppConstants';
import { convertDurationApiToUi } from './DurationHelper';
import { RentalFrequency } from '../services/remoteSigning/lease/Lease';

// export const DateFormat = (date: string) => {

//    Logging.trace(date);

//    const regex = /^\d*\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
//    if (date && !regex.test(date)) {
//        const dateFactors = date.split('/');
//        const cloneDate = new Date(Number(dateFactors[2]), Number(dateFactors[1]) - 1, Number(dateFactors[0]));
//        return moment(cloneDate).format(DATE_FORMATS.returnFormat);
//    }
//    return date || '';
// }

export const dateValidator = (expiryDate: string, type: string) => {
  const date = expiryDate.split('/');
  const expiryMonth = parseInt(date[0], 10);
  const expiryYear = parseInt(date[1], 10);

  const currentYear = new Date().getFullYear() % 100;
  let currentMonth = new Date().getMonth();
  switch (type) {
    case 'paymentCard': {
      currentMonth = new Date().getMonth() + 1;
      break;
    }
    case 'concessionCard': {
      currentMonth = new Date().getMonth() + 4;
      break;
    }
  }
  if (expiryYear < currentYear) {
    return true;
  } else if (expiryYear === currentYear && expiryMonth < currentMonth) {
    return true;
  } else if (expiryMonth > 12) {
    return true;
  }
  return false;
};

export class DateHelper {
  public static convertApiToUi(date: string | undefined | null): string | undefined {
    return date ? moment(date, DateFormats.apiFormat).format(DateFormats.uiFormat) : undefined;
  }

  public static convertApiToDisplay(date: string | undefined | null): string {
    return date ? moment(date, DateFormats.apiFormat).format(DateFormats.displayFormat) : '';
  }

  public static convertUiToApi(date: string | undefined | null): string | undefined {
    return date ? moment(date, DateFormats.uiFormat).format(DateFormats.apiFormat) : undefined;
  }

  public static convertApiToDisplayDays(date?: string): string | undefined {
    const fromNow = moment(date, DateFormats.apiFormat).fromNow(true);
    return fromNow;
  }

  /// <summary>
  /// Returns date without time portion.
  /// </summary>
  public static today(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public static formatDurationMonthsOnly(
    leaseStartDate?: string | null,
    leaseEndDate?: string | null,
  ): string {
    if (!leaseStartDate || !leaseEndDate) {
      return '';
    }

    const leaseDuration = moment(leaseEndDate, DateFormats.apiFormat).diff(
      moment(leaseStartDate, DateFormats.apiFormat),
      'months',
      true,
    );
    return leaseDuration ? `${Math.round(leaseDuration)} month(s)` : '';
  }

  public static formatDurationWeeksOnly(leaseDuration?: null | number) {
    return !leaseDuration ? '' : `${Math.trunc(leaseDuration / daysInWeek)} week(s)`;
  }

  public static formatDuration(
    duration?: number | null,
    frequency?: RentalFrequency | string | null,
    leaseStartDate?: string | null,
    leaseEndDate?: string | null,
  ): string {
    if (!duration) {
      if (!leaseStartDate || !leaseEndDate) {
        return '';
      }

      if (frequency === RentalFrequency.Monthly) {
        const months = this.formatDurationMonthsOnly(leaseStartDate, leaseEndDate);
        return `${months ? `${months}` : ''}`;
      }

      const diffDuration =
        moment(leaseEndDate, DateFormats.apiFormat).diff(
          moment(leaseStartDate, DateFormats.apiFormat),
          'days',
        ) + 1;
      const leaseDuration = convertDurationApiToUi(diffDuration);
      return `${leaseDuration.weeks ? `${leaseDuration.weeks} week(s)` : ''} ${
        leaseDuration.days ? `${leaseDuration.days} day(s)` : ''
      }`;
    }

    const leaseDuration = convertDurationApiToUi(duration, frequency);
    if (frequency === RentalFrequency.Monthly) {
      return `${leaseDuration.months ? `${leaseDuration.months} month(s)` : ''}`;
    }

    return `${leaseDuration.weeks ? `${leaseDuration.weeks} week(s)` : ''} ${
      leaseDuration.days ? `${leaseDuration.days} day(s)` : ''
    }`;
  }
}
