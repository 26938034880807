/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { theme } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  dialog: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    overflow: 'scroll',
  },
  dialogContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.units.spacing * 4,
  },
  headerContainer: {
    position: 'relative',
    minHeight: 50,
    flexShrink: 0,
  },
  closeButtonContainer: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.lightGrey,
    borderRadius: 5000,
    position: 'absolute',
    left: theme.units.spacing * 4,
    top: theme.units.spacing * 4,
  },
  close: {
    width: theme.units.iconSize / 1.4,
    height: theme.units.iconSize / 1.4,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  headerImageContainer: {
    width: '100%',
    height: 320,
    position: 'relative',
  },
  headerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  whiteSplash: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    zIndex: 1,
    left: 0,
  },
  stepperRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 8,
  },
  backButtonContainer: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.lightGrey,
    borderRadius: 5000,
  },
  back: {
    width: theme.units.iconSize / 1.4,
    height: theme.units.iconSize / 1.4,
    objectPosition: 'center',
    objectFit: 'contain',
  },
  title: {
    marginBottom: theme.units.spacing * 3,
  },
  subtitle: {
    marginBottom: theme.units.spacing * 8,
  },
  buttonsContainer: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.units.spacing * 8,
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    minWidth: 50,
  },
  children: {
    maxHeight: 'calc(100vh - 320px)',
    overflow: 'scroll',
    height: '100%',
  },
  noMaxHeight: {
    maxHeight: '100%',
  },
});
