/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import sortedIcon from '../../../../assets/sorted-icon-dark.png';
import { Formik, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import { VALIDATION_SCHEMA, LABELS } from './ForgotPasswordFormConstants';
import { useStyles } from './ForgotPasswordFormStyles';
import { ApplicationState } from '../../../../store/RootReducers';
import { ForgotPasswordPayload, RegisterUserPayload } from '../../../../services/login/LoginData';
import { LoginActions } from '../../../../store/actions/LoginActions';
import UserConfirmationDialog from '../../../../component/userConfirmationDialog/UserConfirmationDialog';
import { View } from '../../Login';
import Input from '../../../../component/input/Input';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import Button from '../../../../component/button/Button';

interface ForgotPasswordProps {
  forgotPasswordError: any;
  userDetails: RegisterUserPayload | null;
  postForgotPasswordEmail: (data: ForgotPasswordPayload, onSubmit: () => void) => void;
  setView: (view: View) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordProps> = ({
  forgotPasswordError,
  userDetails,
  postForgotPasswordEmail,
  setView,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    if (forgotPasswordError && forgotPasswordError.response.status === 400) {
      setErrorMessage('User does not exist');
    }
  }, [forgotPasswordError]);

  const sendForgotPasswordEmail = (data: ForgotPasswordPayload) => {
    postForgotPasswordEmail(data, () => {
      setErrorMessage(null);
      setOpenConfirmDialog(true);
    });
  };

  return (
    <>
      <div className={classes.titleContainer}>
        <img src={sortedIcon} className={classes.icon} />
        <div className={classes.primaryTitle}>{LABELS.FORGOT_PASSWORD}</div>
      </div>
      <div className={classes.pageContent}>
        <div className={classes.primaryContainer}>
          <Formik
            initialValues={{
              username: userDetails && userDetails.email ? userDetails.email : '',
            }}
            validateOnChange
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(data) => {
              sendForgotPasswordEmail(data);
            }}
            render={({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Text textVariant="boxTitle">{LABELS.FORGOT_PASSWORD}</Text>
                <Box>
                  <Text textVariant="info">{LABELS.FORGOT_PASSWORD_SUBTITLE}</Text>
                  <div className={classes.inputRow}>
                    <Input
                      placeholder={LABELS.EMAIL}
                      key={LABELS.EMAIL}
                      value={values.username}
                      setValue={(value: string) => setFieldValue('username', value)}
                      onBlur={() => setFieldTouched('username')}
                      touched={touched.username}
                      error={errors.username || errorMessage}
                      title={LABELS.EMAIL}
                      mandatory
                    />
                  </div>

                  <div className={classes.forgotRow}>
                    <Button outline onPress={() => setView(View.Login)}>
                      {LABELS.BACK}
                    </Button>
                    <Button
                      parentStyles={classes.forgotButton}
                      type="submit"
                      onPress={() => handleSubmit()}
                    >
                      {LABELS.RESET_PASSWORD}
                    </Button>
                  </div>
                </Box>
              </form>
            )}
          />
        </div>
      </div>
      <UserConfirmationDialog
        isOpen={openConfirmDialog}
        title="A reset password email has been sent!"
        content={
          <div>
            If you don’t see it in your inbox, please make sure to also check your spam folders.
          </div>
        }
        primaryButtonTitle="OK"
        onPrimaryClick={() => setOpenConfirmDialog(false)}
        onClose={() => setOpenConfirmDialog(false)}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  postForgotPasswordEmail: (data: ForgotPasswordPayload, onSuccess: () => void) => {
    dispatch(LoginActions.postForgotPasswordLinkStart({ ...data, onSuccess }));
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  forgotPasswordError: state.login.forgotPasswordError,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
