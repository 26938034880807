import React from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { FileUpload } from './FileUpload';

/// <summary>
/// Value is FileUploadFile[] | null -- validation needs null
/// </summary>
const RenderFileUpload = (fieldData: any) => {
  const {
    field,
    disabled,
    variant,
    allowedFormats,
    maxFileCount,
    maxFileSize,
    messages,
    showErrors,
    uploadInBatch,
    design,
    handleUpload,
    handleItemSelected,
    onChange,
  } = fieldData;

  const hasError = !!field.error && !!field.touched;
  const hasCustomError = !!field.customError;
  // const hasSecondaryError = !!field.secondaryValidationMessage;
  return (
    <div className="item-render-fileupload">
      {field.label && <InputLabel htmlFor={field.name}>{field.label}</InputLabel>}
      <FormControl error={hasError} fullWidth>
        <FileUpload
          id={field.name}
          variant={variant}
          allowedFormats={allowedFormats}
          disabled={disabled}
          files={field.value} // FileUploadFile[]
          maxFileCount={maxFileCount}
          maxFileSize={maxFileSize}
          messages={messages}
          showErrors={showErrors}
          uploadInBatch={uploadInBatch}
          design={design}
          handleUpload={(result) => {
            if (result.success) {
              onChange(
                result.currentFiles && result.currentFiles.length > 0 ? result.currentFiles : null,
              );
            }

            if (handleUpload) {
              handleUpload(result);
            }
          }}
          handleItemSelected={(item) => {
            if (handleItemSelected) {
              handleItemSelected(item);
            }
          }}
        />
        {hasError && <FormHelperText id={`${field.name}-error-text`}>{field.error}</FormHelperText>}
        {!hasError && (
          <FormHelperText id={`${field.name}-helper-text`}>{field.helperText}</FormHelperText>
        )}
        {hasCustomError && (
          <FormHelperText id={`${field.name}-customerror-text`} error>
            {field.customError}
          </FormHelperText>
        )}
        {/* {hasSecondaryError && <FormHelperText id={`${field.name}-error2-text`} error={false} required={true}>{field.secondaryValidationMessage}</FormHelperText>} */}
      </FormControl>
    </div>
  );
};

export default RenderFileUpload;
