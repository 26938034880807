/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { takeEvery, put } from 'redux-saga/effects';
import {
  DashboardAction,
  DashboardActions,
  DashboardActionTypes,
  GetPropertiesPayload,
  GetPropertyByAddressPayload,
  GetAgencyByAgentPayload,
  GetPropertyByReaxmlPayload,
  GetPropertyByReaxmlSearchPayload,
} from '../actions/DashboardActions';
import {
  SimilarPropertiesResponse,
  SimilarPropertiesRequest,
} from '../../services/dashboard/similarProperties/GetSimilarProperties';
import PropertyService from '../../services/dashboard/PropertyService';
import {
  PropertyDetailsResponse,
  AgencyDetails,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { LoadingIndicatorActions } from '../actions/LoadingIndicatorAction';
import { Logging } from '../../helper/Logging';
import {
  AppToApplyTransitionRequest,
  AppToApplyTransitionResponse,
} from '../../services/dashboard/applyTransition/applyTransition';
import { CustomTermsHeaderActions } from '../actions/CustomTermsHeaderActions';
import localStorage from '../../services/LocalStorage';
import { getStatus } from '../../container/applications/ApplicationsUtils';
import { notInFlightStatus } from '../../container/applications/ApplicationsConstants';
import { GroupsActions } from '../actions/GroupsActions';
import { store } from '../Store';
import { prefillForms } from '../../helper/PrefillFormHelper';
import { routes } from '../../Routes';
import ApplyService from '../../services/dashboard/ApplyService';
import { MasterProfileActions } from '../actions/MasterProfileActions';
import {
  MasterProfilePayload,
  ApplicantResponsePayload,
  MasterProfileResponsePayload,
} from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import LogRocket from 'logrocket';
import { UPDATING_TYPE } from '../../services/dashboard/updating/Updating';

export function* getPropertyWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as string;
    const response: PropertyDetailsResponse = yield PropertyService.getProperty(data);
    yield put(DashboardActions.onGetPropertySuccess(response));
  } catch (error) {
    yield put(DashboardActions.onGetPropertyFailure({ error: error as Error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getPropertiesWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetPropertiesPayload;
    const response: PropertyDetailsResponse[] = yield PropertyService.getProperties(data);
    yield put(DashboardActions.getPropertiesSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getPropertiesWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getPropertiesFailure({ error: error as Error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getPropertyByAddressWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetPropertyByAddressPayload;
    const response: PropertyDetailsResponse = yield PropertyService.getPropertyByAddress(data);
    yield put(DashboardActions.getPropertyByAddressSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getPropertyByAddressWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getPropertyByAddressFailure({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getAgencyByAgentWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetAgencyByAgentPayload;
    const response: AgencyDetails = yield PropertyService.getAgencyByAgent(data);
    yield put(DashboardActions.getAgencyByAgentSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getAgencyByAgentWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getAgencyByAgentFailure({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getPropertyByReaxmlWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetPropertyByReaxmlPayload;
    const response: PropertyDetailsResponse = yield PropertyService.getPropertyByReaxml(data);
    yield put(DashboardActions.getPropertyByReaxmlSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getPropertyByReaxmlWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getPropertyByReaxmlFailure({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getPropertyByReaxmlSearchWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as GetPropertyByReaxmlSearchPayload;
    const response: PropertyDetailsResponse = yield PropertyService.getPropertyByReaxmlSearch(data);
    yield put(DashboardActions.getPropertyByReaxmlSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getPropertyByReaxmlSearchWorker', {
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getPropertyByReaxmlFailure({ error }));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* getSimilarPropertiesWorker(action: DashboardAction) {
  try {
    yield put(LoadingIndicatorActions.show());
    const data = action.data as SimilarPropertiesRequest;
    const response: SimilarPropertiesResponse[] = yield PropertyService.getSimiliarProperties(data);
    yield put(DashboardActions.getSimilarPropertiesSuccess(response));
  } catch (error) {
    Logging.error('PropertySaga.getSimiliarPropertiesWorker', {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.getSimilarPropertiesError(error as Error));
  } finally {
    yield put(LoadingIndicatorActions.hide());
  }
}

export function* appToApplyTransitionWorker(action: DashboardAction) {
  try {
    const data = action.data as AppToApplyTransitionRequest;
    // ----- Load groups and master profile ------ //
    yield put(GroupsActions.getGroupMasterProfileRequest({}));
    const masterProfileResponse: MasterProfileResponsePayload =
      yield ApplyService.getMasterProfileData();

    const resData: MasterProfilePayload = {
      applicants: [masterProfileResponse.primary.profileData],
    };
    let key = 0;
    if (masterProfileResponse.secondary.length > 0) {
      masterProfileResponse.secondary.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    if (masterProfileResponse.dependents.length > 0) {
      masterProfileResponse.dependents.forEach((element: ApplicantResponsePayload) => {
        element.profileData.key = key;
        resData.applicants.push(element.profileData);
        key++;
      });
    }
    yield put(MasterProfileActions.setMasterProfile(resData));
    LogRocket.identify(resData.applicants[0].email!, {
      email: resData.applicants[0].email!,
    });
    // ----- Start Apply transition ------ //
    const response: AppToApplyTransitionResponse = yield PropertyService.appToApplyTransition(data);
    const { agency, properties, responseType, updatingOne } = response;
    yield put(DashboardActions.storePropertyAgency({ agency }));
    yield put(CustomTermsHeaderActions.postCustomTermsHeaderStart({ agency }));
    let propertyList: any[] = [];
    if (updatingOne) {
      propertyList = properties;
    } else if (responseType === 'DRAFT') {
      localStorage.setItem('draftId', properties[0].draftId!);
      propertyList = properties;
    } else {
      properties.map((property) => {
        if (!notInFlightStatus.includes(getStatus(property.status!))) {
          propertyList.push(property);
        }
      });
    }
    if (response.groupRefId) {
      yield put(GroupsActions.setSelectedGroupRef(response.groupRefId));
    }

    yield put(
      DashboardActions.setIsUpdating(
        updatingOne
          ? UPDATING_TYPE.UPDATING
          : responseType === 'SUBMITTED'
          ? UPDATING_TYPE.SYNCING
          : false,
      ),
    );
    yield put(DashboardActions.setIsAcceptingInvitation(responseType === 'INVITE'));
    const { masterProfileData } = store.getState().masterProfile;
    prefillForms(masterProfileData!, store.dispatch, false, propertyList, null);
    yield put(DashboardActions.setPrefillHelperCalled(true));
    yield put(DashboardActions.appToApplyTransitionSuccess(response));
    if ((responseType === 'SUBMITTED' && updatingOne) || responseType === 'DRAFT') {
      data.history.push(routes.properties.view(agency));
    } else if (responseType === 'SUBMITTED' && !updatingOne) {
      data.history.push(routes.submitProperties.new);
    } else {
      data.history.push(routes.applicationPreferences.new);
    }
  } catch (error) {
    Logging.error('PropertySaga.appToApplyTransitionSuccess', {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error,
      type: action.type,
      data: action.data,
    });
    yield put(DashboardActions.appToApplyTransitionError(error as Error));
  }
}

function* propertyWatcher() {
  yield takeEvery(DashboardActionTypes.FETCH_PROPERTY_DETAILS_REQUEST, (action: DashboardAction) =>
    getPropertyWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.FETCH_PROPERTIES_REQUEST, (action: DashboardAction) =>
    getPropertiesWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.GET_PROPERTY_BY_ADDRESS_REQUEST, (action: DashboardAction) =>
    getPropertyByAddressWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.GET_AGENCY_BY_AGENT_REQUEST, (action: DashboardAction) =>
    getAgencyByAgentWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.GET_PROPERTY_BY_REAXML_REQUEST, (action: DashboardAction) =>
    getPropertyByReaxmlWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(
    DashboardActionTypes.GET_PROPERTY_BY_REAXML_SEARCH_REQUEST,
    (action: DashboardAction) =>
      getPropertyByReaxmlSearchWorker({
        type: action.type,
        data: action.data,
      }),
  );
  yield takeEvery(DashboardActionTypes.GET_SIMILAR_PROPERTIES_REQUEST, (action: DashboardAction) =>
    getSimilarPropertiesWorker({
      type: action.type,
      data: action.data,
    }),
  );
  yield takeEvery(DashboardActionTypes.APPTOAPPLY_TRANSITION_REQUEST, (action: DashboardAction) =>
    appToApplyTransitionWorker({
      type: action.type,
      data: action.data,
    }),
  );
}

export default propertyWatcher;
