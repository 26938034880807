import { Content } from '../../helper/Content/Content.data';
import ApiService from '../TenancyApiService';
import ApiServiceBase from '../TenancyApiServiceBase';
import { MultipartFormData } from '../MultipartFormData.data';
import { ServiceType } from '../ServiceType.data';
import { DisclosureDetailsResponse, DisclosureSignaturePostRequest } from './Disclosure';

export class DisclosureService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.Disclosure);

  public getDetails(token: string): Promise<DisclosureDetailsResponse> | DisclosureDetailsResponse {
    const route: string[] = ['details', token];
    return this.service.get<DisclosureDetailsResponse>({ route });
  }

  public postSignature(request: DisclosureSignaturePostRequest) {
    const route: string[] = ['signatures'];
    const signatures: MultipartFormData[] = [
      {
        name: 'disclosureFormRequest',
        content: JSON.stringify(request.disclosureFormRequest),
      },
    ];

    request.signatures.map((signature: Content) =>
      signatures.push({
        name: 'signatures',
        content: signature,
      }),
    );

    return this.service.postMultipartData({ route }, signatures);
  }
}

const disclosureService = new DisclosureService();
export default disclosureService;
