/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { useStyles } from './ScrollBoxStyles';
import { CustomTermsHeaderResponse } from '../../../../../store/actions/CustomTermsHeaderActions';
import { store } from '../../../../../store/Store';
import { replaceVicTerms } from '../../../../../helper/ReplaceVicTerms';
import { SubmittedApplicationResponse } from '../../../../../services/dashboard/postApplyForm/PostApplyForm.data';
import { PropertyDetailsResponse } from '../../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';

interface ScrollBoxProps {
  headerDetails: CustomTermsHeaderResponse | null | undefined;
  selectedProperties?: PropertyDetailsResponse[];
}

const ScrollBox: FC<ScrollBoxProps> = ({ headerDetails, selectedProperties }) => {
  const classes = useStyles();
  const isVic =
    (store.getState().masterProfile.masterProfileData as SubmittedApplicationResponse).applicants[0]
      .selectedState === 'VIC';
  const hasNoInspection =
    selectedProperties &&
    selectedProperties.some((p) => p.inspectionMethod === 'I have not inspected this property');
  let terms = headerDetails && headerDetails.terms ? headerDetails.terms : undefined;

  if (terms) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = terms;

    if (!isVic) {
      const vicIframe = tempElement.querySelector('#vic-pdf');
      if (vicIframe) vicIframe.remove();
    }
    if (!hasNoInspection) {
      const noInspectionIframe = tempElement.querySelector('#no-inspection-date');
      if (noInspectionIframe) noInspectionIframe.remove();
    }
    terms = tempElement.innerHTML;
    if (isVic) {
      terms = replaceVicTerms(terms);
    }
  }

  return (
    <div className={classes.scrollBox}>
      {terms && (
        <div
          className={`${classes.numberStyle} custom-terms`}
          dangerouslySetInnerHTML={{ __html: terms }}
        />
      )}
    </div>
  );
};

export default ScrollBox;
