import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './NTDSidebarStyles';
import { LABELS, STEPS } from './NTDSidebarConstants';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../text/Text';
import Button from '../button/Button';
import Sidebar from '../sidebar/Sidebar';
import { Dialog } from '@mui/material';
import AddCard from '../addCard/AddCard';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store/RootReducers';
import { PaymentActions, PaymentActionTypes } from '../../store/actions/PaymentActions';
import { loadingSelector } from '../../store/selectors/LoadingSelector';
import { errorMessageSelector } from '../../store/selectors/ErrorSelector';
import { CreditCardRequest } from '../../services/remoteSigning/payment/Payment';
import { EquifaxActions, EquifaxActionTypes } from '../../store/actions/EquifaxActions';
import { EquifaxResponseDictionary, EquifaxStatus, NTDRequest } from '../../models/equifax/equifax';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { canCompleteNTDBanner, formNTDRequest } from '../../helper/NTDHelper';
import { ApplicationsResponse } from '../../services/groups/Groups';
import { MyProfileAction } from '../../store/actions/MyProfileFormActions';
import { AdditionalPersonalDetailsScreenActions } from '../../store/actions/AdditionalPersonalDetailsAction';
import { routes } from '../../Routes';
import { PROFILE_FORMS as ProfileForm } from '../../constants/AppConstants';

interface NTDSidebarProps extends RouteComponentProps {
  openSidebar: boolean;
  retryPayment?: boolean;
  retryCheck?: boolean;
  loading: boolean;
  error: any;
  applicantProfile: ApplicantProfile;
  groupApplications: ApplicationsResponse;
  ntdChecks: EquifaxResponseDictionary | undefined;
  index?: number;
  setOpenSidebar: (value: boolean) => void;
  startEquifaxRequest: (
    creditCardDetails: CreditCardRequest,
    ntdRequest: NTDRequest,
    retryPayment?: boolean,
  ) => void;
  setNTDPurchased: (data: boolean, id?: number) => void;
}

const NTDSidebar: FC<NTDSidebarProps> = ({
  openSidebar,
  retryPayment,
  retryCheck,
  loading,
  error,
  applicantProfile,
  groupApplications,
  ntdChecks,
  index,
  history,
  startEquifaxRequest,
  setNTDPurchased,
  setOpenSidebar,
}) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [creditDetails, setCreditDetails] = useState<CreditCardRequest | null>(null);
  const [step, setStep] = useState<number>(retryPayment ? 2 : 0);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const classes = useStyles();

  const openConfirmBox = (creditCardDetails: CreditCardRequest) => {
    setOpenConfirm(true);
    setCreditDetails(creditCardDetails);
  };

  const onFinish = () => {
    if (retryPayment || retryCheck) {
      setSubmitted(false);
      setOpenSidebar(false);
    } else {
      setNTDPurchased(true, index);
      if (index !== undefined) {
        history.push(routes.additionalApplicant.view(index, ProfileForm.IDENTIFICATION_FORM));
      } else {
        history.push(routes.myProfile.view(ProfileForm.IDENTIFICATION_FORM));
      }
      setOpenSidebar(false);
    }
  };

  const selectedStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  useEffect(() => {
    if (retryPayment) {
      setStep(2);
    }
  }, [retryPayment, openSidebar]);

  useEffect(() => {
    if (
      loading === false &&
      ntdChecks &&
      applicantProfile &&
      applicantProfile.refId &&
      ntdChecks[applicantProfile.refId] !== null &&
      submitted === true
    ) {
      setStep(3);
    }

    if (error) {
      setSubmitted(false);
    }
  }, [error, loading, ntdChecks, submitted]);

  return (
    <>
      <Sidebar
        open={openSidebar}
        title={selectedStep.title}
        subtitle={selectedStep.subtitle}
        onClose={() => {
          setOpenSidebar(false);
          setStep(0);
        }}
        secondaryButtonText={step === 2 ? undefined : selectedStep.secondary}
        primaryButtonText={
          step === 2 || !canCompleteNTDBanner(applicantProfile, groupApplications)
            ? undefined
            : selectedStep.primary
        }
        onPrimary={() => setStep(step + 1)}
        onSecondary={() => (step === 0 ? setOpenSidebar(false) : setStep(step - 1))}
        headerImage={selectedStep.headerImage}
        steps={step <= 1 ? `STEP ${step + 1}/3` : undefined}
        hideClose={step === 3}
      >
        {step === 0 && (
          <>
            <ul className={classes.checks}>
              <li>Make you stand out from the crowd</li>
              <li>Check your identity and rental history</li>
              <li>Help property managers evaluate you faster</li>
            </ul>

            <Text textVariant="info" size="l">
              Boost your application for only $14.99
            </Text>
            {!canCompleteNTDBanner(applicantProfile, groupApplications) && (
              <Text textVariant="info" size="l" parentStyles={classes.addThis}>
                Add this to your application when you apply for your first property
              </Text>
            )}
            <Text textVariant="info" size="l" parentStyles={classes.addThis}>
              Do you already have a Background Check that you have purchased elsewhere? No need to
              buy another one here, simply upload it in the ID section.
            </Text>
          </>
        )}
        {step === 1 && (
          <>
            <ul className={classes.checks}>
              <li>Ensure that your name in your profile matches that on your id documents</li>
              <li>
                After you have ordered a Background Check, the Report will be visible within your
                profile under the identification section.
              </li>
              <li>
                You can update your application to include or remove the report. This however does
                not stop the Property Manager from running their own background check on you
              </li>
            </ul>
            <Button parentStyles={classes.ntdPurchaseButton} onPress={() => setStep(step + 1)}>
              {LABELS.PURCHASE_NTD}
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <AddCard
              goBack={() => (retryPayment ? setOpenSidebar(false) : setStep(step - 1))}
              onSubmit={(creditCardDetails: CreditCardRequest) => openConfirmBox(creditCardDetails)}
              submitted={submitted}
              terms={
                <>
                  <div className={classes.defaultContainer}>
                    <Text textVariant="info" size="l">
                      Amount
                    </Text>
                    <Text textVariant="link">$14.99</Text>
                  </div>

                  <Text textVariant="boxTitle" parentStyles={classes.acknowledge}>
                    {LABELS.ACKNOWLEDGE}
                  </Text>

                  <div
                    className={classes.ackStatement}
                    dangerouslySetInnerHTML={{ __html: LABELS.ACKNOWLEDGE_INFO }}
                  />
                </>
              }
            />
          </>
        )}
        {step === 3 && (
          <div className={classes.step4Container}>
            <Button onPress={() => onFinish()} parentStyles={classes.viewInProfile}>
              {LABELS.VIEW_IN_PROFILE}
            </Button>
          </div>
        )}
      </Sidebar>
      <Dialog
        open={openConfirm}
        style={{
          zIndex: 2147483005, // Just above intercom
        }}
      >
        <div className={classes.formStyle}>
          <Text textVariant="contentTitle">{LABELS.COLLECTION_STATEMENT_TITLE}</Text>
          <div
            className={classes.statement}
            dangerouslySetInnerHTML={{ __html: LABELS.COLLECTION_STATEMENT_CONTENT }}
          />
          {/* {error && <div className={classes.errorText}>{error}</div>} */}
          <div className={classes.buttonContainer}>
            <Button
              outline
              parentStyles={classes.secondaryButton}
              onPress={() => setOpenConfirm(false)}
            >
              {LABELS.BACK}
            </Button>
            <Button
              parentStyles={error ? classes.errorButton : classes.primaryButton}
              disabled={loading}
              onPress={() => {
                setSubmitted(true);
                startEquifaxRequest(
                  creditDetails!,
                  formNTDRequest(applicantProfile, groupApplications),
                  retryPayment,
                );
                setOpenConfirm(false);
              }}
            >
              {loading ? 'Processing' : error ? 'Failed' : LABELS.PAY_NOW}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const loading = loadingSelector([
  PaymentActionTypes.GET_PAYMENT_AUTH_KEY,
  PaymentActionTypes.POST_PAYMENT_TOKEN,
  EquifaxActionTypes.GET_NTD_CHECK,
  EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK,
]);

const error = errorMessageSelector([
  PaymentActionTypes.GET_PAYMENT_AUTH_KEY,
  PaymentActionTypes.POST_PAYMENT_TOKEN,
  EquifaxActionTypes.GET_NTD_CHECK,
  EquifaxActionTypes.RETRY_PAYMENT_NTD_CHECK,
]);

const mapStateToProps = (state: ApplicationState) => ({
  loading: loading(state),
  error: error(state),
  groupApplications: state.groups.groupApplications,
  ntdChecks: state.equifaxState.ntdChecks,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  startEquifaxRequest: (
    creditCardDetails: CreditCardRequest,
    ntdRequest: NTDRequest,
    retryPayment?: boolean,
  ) =>
    dispatch(
      PaymentActions.getPaymentAuthKeyRequest({
        data: creditCardDetails,
        onSuccess: (resultKey: string) => {
          if (retryPayment) {
            dispatch(
              EquifaxActions.retryPaymentCheckRequest({
                resultKey,
                userRefId: ntdRequest.profile.refId!,
              }),
            );
          } else {
            dispatch(EquifaxActions.getNTDCheckRequest({ resultKey, ntdRequest }));
          }
        },
        onError: (error: Error) => {
          dispatch(PaymentActions.getPaymentAuthKeyError(error));
          setTimeout(() => {
            dispatch(PaymentActions.resetPaymentError());
          }, 5000);
        },
      }),
    ),
  setNTDActive: (data: boolean, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.setNTDActive(data, id))
      : dispatch(MyProfileAction.setNTDActive(data));
  },
  setNTDPurchased: (data: boolean, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.setNTDPurchased(data, id))
      : dispatch(MyProfileAction.setNTDPurchased(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NTDSidebar));
