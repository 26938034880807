import React, { FC, useEffect, useState, useMemo } from 'react';
import { useStyles } from './SyncProfileStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import { LABELS } from './SyncProfileConstants';
import { routes } from '../../../../Routes';

interface SyncProfileProps extends RouteComponentProps {}

const SyncProfile: FC<SyncProfileProps> = ({ history }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text textVariant="contentTitle">{LABELS.TITLE}</Text>
      <Text textVariant="info">{LABELS.SUBTITLE}</Text>
      <Button parentStyles={classes.button} onPress={() => history.push(routes.applications.view)}>
        {LABELS.BUTTON}
      </Button>
    </div>
  );
};

export default withRouter(SyncProfile);
