import React, { FC, useEffect, useState, useMemo } from 'react';
import { isEmpty } from '../../helper/Validation';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputGoogleStyles';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { ClickAwayListener } from '@mui/material';
import { GoogleAddressData, GooglePlacesHelper } from '../../helper/GooglePlacesHelper';
import { AddressValue } from '../../helper/AddressValue';
import Button from '../button/Button';
import EditAddressModal from '../editAddressModal/EditAddressModal';
import { ManualAddressData } from '../../store/state/MyProfileFormState';

interface InputGoogleProps {
  ref?: any;
  placeholder: string;
  value: string | number;
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  parentStyles?: string;
  parentInputStyles?: string;
  endElement?: any;
  showRequiredError?: boolean;
  hideEndElement?: boolean;
  name?: string;
  autoComplete?: boolean;
  onBlur?: () => void;
  setValue: (value: GoogleAddressData) => void;
}

const InputGoogle: FC<InputGoogleProps> = ({
  ref,
  title,
  placeholder,
  value,
  mandatory,
  touched,
  error,
  disabled,
  parentStyles,
  parentInputStyles,
  endElement,
  showRequiredError,
  hideEndElement,
  name,
  autoComplete,
  onBlur,
  setValue,
}) => {
  const classes = useStyles();
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [addressComponents, setAddressComponents] = useState<GoogleAddressData | null>();
  const [address, setAddress] = useState<AddressValue>({
    inputValue: '',
    place: null,
  });

  let addressInput: HTMLInputElement;

  const handleAddressChanged = (value: AddressValue) => {
    if (value.place) {
      const formattedAddress = GooglePlacesHelper.getAddressFieldsFromGoogleAPI(value);
      if (
        !(formattedAddress.streetNumber && formattedAddress.streetName && formattedAddress.suburb)
      ) {
        setShowEditAddressModal(true);
      }
      setAddress(value);
      setValue(formattedAddress);
      setAddressComponents(formattedAddress);
    }
  };

  const makeFlatAddress = (address: ManualAddressData) => {
    let ret = '';
    if (address.unitNumber) ret += ` ${address.unitNumber}/`;
    if (address.streetNumber) ret += `${address.streetNumber}`;
    // EditAddressModal.tsx already combines streetName and streetType into streetName
    if (address.streetName) ret += ` ${address.streetName}`;
    if (address.streetType) ret += ` ${address.streetType}`;
    if (address.suburb) ret += `, ${address.suburb}`;
    if (address.state) ret += ` ${address.state}`;
    if (address.postcode) ret += `, ${address.postcode}`;
    if (address.country) ret += `, ${address.country}`;

    return ret.trim();
  };

  useEffect(() => {
    GooglePlacesHelper.createGooglePlacesAutoComplete(addressInput, (value) =>
      handleAddressChanged(value),
    );
  }, []);

  return (
    <div className={classNameGenerator([classes.inputCard, parentStyles])}>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div className={classes.titleContainer}>
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}

      <div
        className={classNameGenerator([
          classes.inputContainer,
          parentInputStyles,
          ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
        ])}
      >
        <input
          ref={(elem: HTMLInputElement) => (addressInput = elem)}
          inputMode="text"
          name={name}
          autoComplete={autoComplete && name ? name : undefined}
          type="text"
          className={classNameGenerator([
            classes.inputStyle,
            parentInputStyles,
            disabled && classes.disabled,
          ])}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent) => {
            setAddress({
              inputValue: (e.target as HTMLInputElement).value,
              place: null,
            });
          }}
          value={AddressValue.format(address)}
          onBlur={onBlur}
          disabled={disabled}
        />

        <div className={classes.endElement}>{endElement}</div>
        {((touched && error) || (showRequiredError && isEmpty(value)) || value) &&
          !hideEndElement && (
            <img
              className={classes.endStatus}
              src={
                (touched && error) || (showRequiredError && isEmpty(value))
                  ? IncompleteIcon
                  : value
                  ? CompletedIcon
                  : ''
              }
              alt=""
            />
          )}
      </div>
      <Button parentStyles={classes.manualButton} onPress={() => setShowEditAddressModal(true)}>
        or enter address manually
      </Button>
      {showEditAddressModal && (
        <EditAddressModal
          initialData={{
            address: addressComponents || {
              unitNumber: '',
              streetType: '',
              streetNumber: '',
              streetName: '',
              suburb: '',
              state: '',
              postcode: '',
              country: '',
            },
            idx: 0,
          }}
          open
          onClose={() => setShowEditAddressModal(false)}
          onSubmit={(e) => {
            const flatAddress = makeFlatAddress(e.address);
            setValue({ address: flatAddress, ...e.address });
            setShowEditAddressModal(false);
            setAddressComponents({ address: flatAddress, ...e.address });
            setAddress({
              inputValue: flatAddress,
              place: null,
            });
          }}
        />
      )}
    </div>
  );
};

export default InputGoogle;
