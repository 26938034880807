import home from '../../../../assets/navigation/home.png';
import people from '../../../../assets/navigation/people.png';
import profile from '../../../../assets/navigation/profile.png';
import email from '../../../../assets/email.png';

export const LABELS = {
  TITLE: 'First steps, your application',
  SUBTITLE:
    "To kick start your application we just need the basics - which property or properties you're applying for, who you are and who you're applying with. That's it!",
};

export const STEPS = [
  {
    icon: home,
    title: 'Properties',
  },
  {
    icon: profile,
    title: 'Profile',
  },
  {
    icon: people,
    title: 'Housemates',
  },
  {
    icon: email,
    title: 'Submit',
  },
];
