import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { maxContentWidth, pageContainer, smallPageContainer } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
    ...maxContentWidth,
  },
  remoteSigningContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  completeSigningGridStyle: {
    background: theme.colors.light,
    height: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  desktopBanner: {
    padding: '20px 40px',
  },
  signatureListContainer: {
    padding: '0 40px',
  },
  signatureListContainerMobile: {
    padding: '0 20px',
  },
  buttonStyle: {
    backgroundColor: theme.colors.sortedPurple,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.light,
    minHeight: 40,
    textTransform: 'none',
    margin: 40,
    marginTop: 'auto',
  },
  docIconStyle: {
    paddingRight: 10,
  },
  signatureDivStyle: {
    width: '100vw',
    height: 260,
    backgroundColor: theme.colors.disabled,
  },
  panelStyles: {
    paddingLeft: 0,
  },
  remoteSigningSubtitle: {
    marginTop: 15,
  },
  RDSContainer: {
    ...pageContainer,
    paddingBottom: 0,
    paddingTop: 0,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      ...smallPageContainer,
    },
  },
  signingTitleContainer: {
    padding: '30px',
    paddingBottom: '0px',
    [`@media (max-width: ${SIZES.md}px)`]: {
      padding: '15px',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  signingTitlesContainer: {
    marginBottom: theme.units.spacing * 12,
  },
  signingTitle: {
    marginBottom: theme.units.spacing * 4,
  },
  signingSubtitle: {
    marginTop: '15px',
    fontSize: '20px',
    fontWeight: 100,
    [`@media (max-width: ${SIZES.md}px)`]: {
      fontSize: '18px',
    },
  },
  personTitle: {
    marginBottom: theme.units.spacing * 2,
  },
  contentContainer: {
    paddingTop: theme.units.spacing * 4,
    paddingBottom: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.md}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonContainer: {
    margin: 'auto',
    marginTop: '20px',
    borderRadius: '6px',
    marginBottom: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondaryButton: {
    width: '50%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.light,
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    color: theme.colors.black87,
    cursor: 'pointer',
    [`@media (max-width: ${SIZES.md}px)`]: {
      width: '100%',
      marginRight: '15px',
    },
  },
  primaryButton: {
    minWidth: 200,
  },
  personRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 4,
    paddingBottom: theme.units.spacing * 4,
    borderBottom: `${theme.colors.borderColor} 2px solid`,
  },
  textContent: {
    marginRight: 'auto',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: theme.units.spacing * 2,
    filter: 'grayscale(100%)',
    opacity: 0.3,
  },
  iconActive: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: theme.units.spacing * 2,
  },
});
