import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './SignNowStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../store/RootReducers';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import bannerPeach from '../../../../assets/banner-blue.png';
import welcomeImage from '../../../../assets/accepted-image.png';
import { LABELS } from './SignNowConstants';
import CompletedIcon from '../../../../assets/check.svg';
import Profile from '../../../../assets/navigation/profile.png';
import ArrowRight from '../../../../assets/navigation/arrow-right.png';
import { ApplicantProfile } from '../../../../store/state/MyProfileFormState';
import { getProfilePercentage } from '../../../../helper/ProfileVerifyHelper';
import { routes } from '../../../../Routes';
import { ApplicationsResponse } from '../../../../services/groups/Groups';
import { containsSigningLink } from '../../DashboardUtils';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';

interface SignNowProps extends RouteComponentProps {
  profileFormDetails: ApplicantProfile;
  groupApplications: ApplicationsResponse | undefined;
  setMyAccountFlag: (value: boolean) => void;
}

const SignNow: FC<SignNowProps> = ({
  profileFormDetails,
  history,
  groupApplications,
  setMyAccountFlag,
}) => {
  const classes = useStyles();
  const applicationToSign = containsSigningLink(groupApplications);

  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.welcomeBanner}>
        <div className={classes.welcomeLeftContent}>
          <Text textVariant="title" parentStyles={classes.welcomeTitle}>
            {LABELS.TITLE(applicationToSign!.appointmentTokens![0].firstName)}
          </Text>
          <Text textVariant="info" parentStyles={classes.welcomeSubtitle}>
            {LABELS.SUBTITLE}
          </Text>
          <Button
            onPress={() => {
              setMyAccountFlag(false);
              history.push(
                routes.remoteSigning.viewApply(applicationToSign!.appointmentTokens![0].token),
              );
            }}
            parentStyles={classes.welcomeButton}
          >
            {LABELS.GET_STARTED}
          </Button>
        </div>
        <div className={classes.welcomeImageBackground}>
          <img src={bannerPeach} className={classes.welcomeLeftImage} alt="overlay" />
          <img src={welcomeImage} className={classes.welcomeRightImage} alt="welcome" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  profileFormDetails: state.myProfileForm,
  groupApplications: state.groups.groupApplications,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMyAccountFlag: (data: boolean) => {
    dispatch(MyProfileAction.setMyAccountFlag(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignNow));
