/* eslint-disable import/prefer-default-export */
import { FileUploadMessages } from './FileUpload/FileUploadMessages.data';
import { TypeHelper } from '../../../helper/TypeHelper';
import sortedIcon from '../assets/sorted-icon.png';
import personIcon from '../assets/person-white.png';
import { AppIconType } from '../../../component/AppIcon/AppIcon';

export class Messages {
  public static readonly forms = {
    maintenanceRequestForm: {
      photoUploadMessages: TypeHelper.cast<FileUploadMessages>({
        noFiles: 'Please select the images to upload.',
        maxFileCountExceeded:
          'You can upload a maximum of {count} images. Some of your images have not been uploaded.',
        maxFileSizeExceeded:
          'The maximum file upload size is {size}MB. Please reduce the size and upload again.',
        missingFileType: 'Missing file types. Please select and upload again.',
        invalidFileType: 'The expected file types : {types}. Please select and upload again.',
      }),
      validationMessages: {
        required: 'This field is required',
      },
    },
  };
}
