import React, { FC } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputMultiSelectStyles';
import { isEmpty } from '../../helper/Validation';
import { SelectValues } from './InputMultiSelectConstants';
import { Checkbox } from '@mui/material';
import { theme } from '../../theme/Theme';

interface InputMultiSelectProps {
  value: string[] | undefined | null;
  values: SelectValues[];
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  parentStyles?: string;
  showRequiredError?: boolean;
  maxSelection?: number;
  setValue: (value: string[]) => void;
}

const InputMultiSelect: FC<InputMultiSelectProps> = ({
  title,
  value,
  values,
  mandatory,
  touched,
  error,
  parentStyles,
  showRequiredError,
  maxSelection,
  setValue,
}) => {
  const classes = useStyles();

  const handleChange = (checked: string) => {
    const newSelectedList = value ? [...value] : [];
    const index = newSelectedList.indexOf(checked);

    if (index === -1 && (!maxSelection || newSelectedList.length < maxSelection)) {
      // Add the value to the list if it's not already in the list
      newSelectedList.push(checked);
    } else if (index !== -1) {
      // Remove the value from the list if it's already in the list
      newSelectedList.splice(index, 1);
    }

    setValue(newSelectedList);
  };

  return (
    <div className={classNameGenerator([classes.inputCard, parentStyles])}>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div className={classes.titleContainer}>
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      {values.map((v, i) => (
        <div
          className={classes.checkboxRow}
          key={i}
          onMouseDown={() => handleChange(v.value as string)}
        >
          <Checkbox
            style={{ color: theme.colors.secondary }}
            className={classes.checkBoxStyle}
            checked={!!value && value.includes(v.value as string)}
          />
          {v.display}
        </div>
      ))}
    </div>
  );
};

export default InputMultiSelect;
