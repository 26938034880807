import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { SelectBoxButtonDef } from './SelectBoxButtonDef.data';
import { useStyles } from './SelectBoxStyles.data';
import Button from '../../../../component/button/Button';

interface MultiSelectBoxProps {
  readonly options: SelectBoxButtonDef[];
  readonly values: any[];
  readonly disabled?: boolean;
  handleChange: (item: any) => void;
}

const MultiselectBoxComponent: FC<MultiSelectBoxProps> = ({
  options,
  values,
  handleChange,
  disabled,
}) => {
  const classes = useStyles();
  const renderItems = (option: SelectBoxButtonDef, index: number): JSX.Element => {
    return (
      <Grid item key={index}>
        <Button
          outline={!values.includes(option.key)}
          disabled={disabled}
          onPress={() => handleChange(option.key)}
        >
          {option.title}
        </Button>
      </Grid>
    );
  };
  return (
    <Grid container spacing={2}>
      {options.map((option, index) => renderItems(option, index))}
    </Grid>
  );
};

export const MultiselectBox = MultiselectBoxComponent;
