import * as Yup from 'yup';
import { EMAIL_PATTERN } from '../../../../constants/AppConstants';

export const LABELS = {
  FORGOT_PASSWORD: 'Forgot Password',
  FORGOT_PASSWORD_SUBTITLE: 'Enter your email address to receive a reset password link',
  EMAIL: 'Email',
  RESET_PASSWORD: 'Reset password',
  BACK: 'Back',
};

export const ERRORS = {
  requiredEmail: 'Please enter your email address',
  invalidEmailFormat: 'Invalid email format',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required(ERRORS.requiredEmail)
    .max(255)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
});
