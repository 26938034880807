import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { AgencyLimited } from '../../models/agencies/Agencies';

export class SearchService {
  private readonly serviceTypeAgencies: ApiServiceBase = new ApiService(ServiceType.Agencies);

  public getAgencies(): Promise<AgencyLimited[]> | AgencyLimited[] {
    return this.serviceTypeAgencies.get<AgencyLimited[]>({});
  }
}

const searchService = new SearchService();
export default searchService;
