import { makeStyles } from '@mui/styles';
import { CompassCalibrationRounded } from '@mui/icons-material';
import { color } from '../TenancyTheme';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: color.irisBlue,
  },
  detailsWrapper: {
    marginBottom: '32px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '1em',
    '& > * + *': {
      marginLeft: '20px',
    },
  },
  heading: {
    textAlign: 'center',
    fontSize: '27.2px',
    fontWeight: 500,
  },
  centered: {
    textAlign: 'center',
  },
  description: {
    fontSize: '16.3px',
    fontWeight: 'normal',
  },
  icon: {
    width: '40px',
    height: '40px',
  },
  button: {
    width: '124px',
    height: '40px',
    boxShadow: 'none',
    color: 'white',
    alignItems: 'center',
    textTransform: 'none',
    fontSize: '14px',
    padding: '0 10px',
  },
});
