import { StoreAction } from '../StoreHelper';
import {
  RemoteSigningGetResponse,
  GetRemoteSigningPayload,
  PostRemoteSigningPayload,
  RemoteSigningPostResponse,
  MeritonServiceCallbackRequest,
  GuestAuthDetailsRequest,
  GuestAuthDetailsResponse,
} from '../../services/remoteSigning/RemoteSigning';

export enum RemoteSigningActionTypes {
  GET_REMOTE_SIGNING = 'GET_REMOTE_SIGNING',
  GET_REMOTE_SIGNING_REQUEST = 'GET_REMOTE_SIGNING_REQUEST',
  GET_REMOTE_SIGNING_SUCCESS = 'GET_REMOTE_SIGNING_SUCCESS',
  GET_REMOTE_SIGNING_ERROR = 'GET_REMOTE_SIGNING_ERROR',

  POST_SIGN = 'POST_SIGN',
  POST_SIGN_REQUEST = 'POST_SIGN_REQUEST',
  POST_SIGN_SUCCESS = 'POST_SIGN_SUCCESS',
  POST_SIGN_ERROR = 'POST_SIGN_ERROR',

  RESET_PAYMENT_ERROR = 'RESET_ERROR',
  POST_MERITON_CALLBACK_REQUEST = 'POST_MERITON_CALLBACK_REQUEST',
  POST_MERITON_CALLBACK_SUCCESS = 'POST_MERITON_CALLBACK_SUCCESS',
  POST_MERITON_CALLBACK_ERROR = 'POST_MERITON_CALLBACK_ERROR',

  GET_GUEST_AUTH_DETAILS_REQUEST = 'GET_GUEST_AUTH_DETAILS_REQUEST',
  GET_GUEST_AUTH_DETAILS_SUCCESS = 'GET_GUEST_AUTH_DETAILS_SUCCESS',
  GET_GUEST_AUTH_DETAILS_ERROR = 'GET_GUEST_AUTH_DETAILS_ERROR',
}

export type RemoteSigningActionPayload =
  | GetRemoteSigningPayload
  | RemoteSigningGetResponse
  | PostRemoteSigningPayload
  | RemoteSigningPostResponse
  | MeritonServiceCallbackRequest
  | GuestAuthDetailsRequest
  | GuestAuthDetailsResponse
  | undefined
  | Error
  | null;

export type RemoteSigningAction = StoreAction<RemoteSigningActionTypes, RemoteSigningActionPayload>;

export class RemoteSigningActions {
  // get Remote singing data(lease,witness,tenant)
  public static getRemoteSigningStart(data?: GetRemoteSigningPayload): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.GET_REMOTE_SIGNING_REQUEST, data };
  }

  public static getRemoteSigningSuccess(data: RemoteSigningGetResponse): RemoteSigningAction {
    return {
      type: RemoteSigningActionTypes.GET_REMOTE_SIGNING_SUCCESS,
      data,
    };
  }

  public static getRemoteSigningError(data: Error): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.GET_REMOTE_SIGNING_ERROR, data };
  }

  // post sign
  public static postSignStart(data: PostRemoteSigningPayload): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.POST_SIGN_REQUEST, data };
  }

  public static postSignSuccess(data: RemoteSigningPostResponse): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.POST_SIGN_SUCCESS, data };
  }

  public static postSignError(data: Error): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.POST_SIGN_ERROR, data };
  }

  public static resetPaymentError(): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.RESET_PAYMENT_ERROR, data: null };
  }

  // post procurement email
  public static postMeritonCallbackStart(
    data?: MeritonServiceCallbackRequest,
  ): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.POST_MERITON_CALLBACK_REQUEST, data };
  }

  public static postMeritonCallbackSuccess(): RemoteSigningAction {
    return {
      type: RemoteSigningActionTypes.POST_MERITON_CALLBACK_SUCCESS,
      data: null,
    };
  }

  public static postMeritonCallbackError(data: Error): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.POST_MERITON_CALLBACK_ERROR, data };
  }

  // get guest auth token
  public static getGuestTokenStart(data: GuestAuthDetailsRequest): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.GET_GUEST_AUTH_DETAILS_REQUEST, data };
  }

  public static getGuestTokenSuccess(data: GuestAuthDetailsResponse): RemoteSigningAction {
    return {
      type: RemoteSigningActionTypes.GET_GUEST_AUTH_DETAILS_SUCCESS,
      data,
    };
  }

  public static getGuestTokenError(data: Error): RemoteSigningAction {
    return { type: RemoteSigningActionTypes.GET_GUEST_AUTH_DETAILS_ERROR, data };
  }
}
