import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  LoginPayload,
  RegisterUserPayload,
  RefreshTokenPayload,
  VerifyUserPayload,
  VerificationLinkPayload,
  FindUserPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
} from './LoginData';

const serviceType = ServiceType.Auth;

export class LoginService {
  private readonly service: ApiServiceBase = new ApiService(serviceType);

  public postLogin(payload: LoginPayload): Promise<void> | void {
    return this.service.post({ route: ['login'] }, payload);
  }

  public postRegisterUser(payload: RegisterUserPayload): Promise<void> | void {
    return this.service.post({ route: ['registerUser'] }, payload);
  }

  public postRegisterClient(): Promise<void> | void {
    return this.service.post({ route: ['registerClient'] }, {});
  }

  public postRefreshToken(payload: RefreshTokenPayload): Promise<void> | void {
    return this.service.post({ route: ['refreshToken'] }, payload);
  }

  public postVerifyUser(payload: VerifyUserPayload): Promise<void> | void {
    return this.service.post({ route: ['verifyUser'], query: { token: payload.token } }, {});
  }

  public postVerificationLink(payload: VerificationLinkPayload): Promise<void> | void {
    return this.service.post({ route: ['sendVerificationLink'] }, payload);
  }

  public getUserDetails(payload: FindUserPayload): Promise<void> | void {
    return this.service.get({
      route: ['api', 'apply', 'user'],
      query: { username: payload.username },
    });
  }

  public postForgotPasswordLink(payload: ForgotPasswordPayload): Promise<void> | void {
    return this.service.post({ route: ['forgotPassword', payload.username] }, {});
  }

  public postResetPassword(payload: ResetPasswordPayload): Promise<void> | void {
    return this.service.post(
      {
        route: ['forgotPassword', 'token', 'verify'],
      },
      payload,
    );
  }

  public getGuestToken(): Promise<void> | void {
    return this.service.get({
      route: ['guestToken'],
    });
  }
}

const loginService = new LoginService();
export default loginService;
