import { GroupsState } from '../state/GroupsState';
import { GroupsAction, GroupsActionTypes } from '../actions/GroupsActions';
import {
  GroupResponse,
  ApplicationsResponse,
  WithdrawResponse,
} from '../../services/groups/Groups';

export const initialState: GroupsState = {
  selectedGroupRefId: null,
  groups: undefined,
  groupApplications: undefined,
  applicationSubmitted: false,
};

export const groupsReducer: (state: GroupsState, action: GroupsAction) => GroupsState = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GroupsActionTypes.ADD_CO_APPLICANT_SUCCESS:
    case GroupsActionTypes.UPDATE_CO_APPLICANT_SUCCESS:
    case GroupsActionTypes.REMOVE_CO_APPLICANT_SUCCESS:
    case GroupsActionTypes.UPDATE_GROUP_NAME_SUCCESS:
    case GroupsActionTypes.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groups: action.data as GroupResponse[],
      };

    case GroupsActionTypes.GET_GROUP_MASTER_PROFILE_SUCCESS: {
      if ((action.data as GroupResponse[]).length === 1) {
        return {
          ...state,
          selectedGroupRefId: (action.data as GroupResponse[])[0].groupRefId,
          groups: action.data as GroupResponse[],
        };
      } else {
        return {
          ...state,
          groups: action.data as GroupResponse[],
        };
      }
    }

    case GroupsActionTypes.POST_CREATE_NEW_GROUP_SUCCESS: {
      const oldGroupRefs: string[] = [];
      if (state.groups) {
        state.groups.map((group) => {
          oldGroupRefs.push(group.groupRefId);
        });
      }

      const newGroupRefs: string[] = [];
      (action.data as GroupResponse[]).map((group) => {
        newGroupRefs.push(group.groupRefId);
      });

      const newSelectedGroupRef = newGroupRefs.filter((x) => !oldGroupRefs.includes(x));

      return {
        ...state,
        selectedGroupRefId: newSelectedGroupRef[0],
        groups: action.data as GroupResponse[],
      };
    }

    case GroupsActionTypes.GET_ALL_APPLICATIONS_SUCCESS:
    case GroupsActionTypes.DELETE_APPLY_DRAFT_SUCCESS:
      return {
        ...state,
        groupApplications: action.data as ApplicationsResponse,
      };

    case GroupsActionTypes.SYNC_APPLICATIONS_SUCCESS:
    case GroupsActionTypes.POST_GROUP_APPLICATION_SUCCESS:
    case GroupsActionTypes.UPDATE_GROUP_APPLICATION_SUCCESS:
      return {
        ...state,
        applicationSubmitted: true,
        groupApplications: action.data as ApplicationsResponse,
      };

    case GroupsActionTypes.RESET_GROUPSUBMIT_SUCCESS:
      return {
        ...state,
        applicationSubmitted: false,
      };

    case GroupsActionTypes.POST_GROUP_APPLICATION_ERROR:
    case GroupsActionTypes.UPDATE_GROUP_APPLICATION_ERROR:
      return {
        ...state,
        applicationSubmitted: false,
      };

    case GroupsActionTypes.DECLINE_INVITATION_SUCCESS:
    case GroupsActionTypes.WITHDRAW_MYSELF_SUCCESS:
    case GroupsActionTypes.WITHDRAW_APPLICATION_SUCCESS:
      return {
        ...state,
        groupApplications: (action.data as WithdrawResponse).applicationGroupResponse,
        groups: (action.data as WithdrawResponse).groups,
      };

    case GroupsActionTypes.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        groupApplications: (action.data as WithdrawResponse).applicationGroupResponse,
        groups: (action.data as WithdrawResponse).groups,
        applicationSubmitted: true,
      };

    case GroupsActionTypes.SET_SELECTED_GROUP_REF:
      return {
        ...state,
        selectedGroupRefId: action.data as string | null,
      };

    default:
      return state;
  }
};
