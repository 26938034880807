import { ReferenceToolState } from '../state/ReferenceToolState';
import { ReferenceToolActionTypes, ReferenceToolAction } from '../actions/ReferenceToolActions';

export const initialState: ReferenceToolState = {
  referencesData: null,
  getError: null,
  postError: null,
  tokenId: null,
};

/* tslint:disable:cyclomatic-complexity */
export const referenceToolReducer: (
  state: ReferenceToolState | undefined,
  action: ReferenceToolAction,
) => ReferenceToolState = (state = initialState, action) => {
  switch (action.type) {
    case ReferenceToolActionTypes.SET_REFERENCES_DATA: {
      return {
        ...state,
        referencesData: action.data,
      };
    }
    case ReferenceToolActionTypes.GET_REFERENCES_DATA_FAILURE: {
      return {
        ...state,
        getError: action.data,
      };
    }
    case ReferenceToolActionTypes.POST_SAVE_REFERENCES_DATA_FAILURE: {
      return {
        ...state,
        postError: action.data,
      };
    }
    case ReferenceToolActionTypes.SAVE_TOKEN: {
      return {
        ...state,
        tokenId: action.data,
      };
    }
    default:
      return state;
  }
};
export default referenceToolReducer;
