/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-lines */
/* eslint-disable no-lonely-if */
/* eslint-disable no-console */
import React, { FC, useState, useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import { useStyles } from './ProfileStyles';
import { ApplicationState } from '../../store/RootReducers';
import { ApplicantProfile } from '../../store/state/MyProfileFormState';
import { OverviewFormData as OverviewDetails } from '../../services/landingScreen/OverviewForm.data';
import { PROFILE_FORMS as ProfileForm, SIZES } from '../../constants/AppConstants';
import { MasterProfileActions } from '../../store/actions/MasterProfileActions';
import SignatureErrorModal from '../../component/signatureErrorModal/SignatureErrorModal';
import { routes } from '../../Routes';
import { CalculationHelper } from '../../helper/CalculationHelper';
import { DependentProfile } from '../../services/additionalPersonalDetailsScreen/postFormDetails/PostAdditionalPersonalDetailsForm.data';
import { BLOGS, LABELS, STATES } from './ProfileConstants';
import { AustraliaState } from '../../services/dashboard/australiaStates/australiaStates';
import { store } from '../../store/Store';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { prefillForms } from '../../helper/PrefillFormHelper';
import { DashboardActions } from '../../store/actions/DashboardActions';
import { LoginActions } from '../../store/actions/LoginActions';
import { UpdatingType } from '../../services/dashboard/updating/Updating';
import Text from '../../component/text/Text';
import BlogCard from '../../component/blogCard/BlogCard';
import ProfileItem from './components/ProfileItem/ProfileItem';
import {
  isPersonalDetailsFilled,
  isIntroFilled,
  isCurrentIncomeDetailsFilled,
  isEmploymentDetailsFilled,
  isPetsDetailsFilled,
  isVehiclesDetailsFilled,
  isAddressDetailsFilled,
  isReferenceDetailsFilled,
  isIdentificationDetailsFilled,
  isDeclarationDetailsFilled,
  personalDetailsPercentage,
  introPercentage,
  currentIncomePercentage,
  employmentPercentage,
  petsPercentage,
  vehiclesPercentage,
  addressPercentage,
  referencePercentage,
  identificationPercentage,
  declarationPercentage,
  personalDetailsSummary,
  introSummary,
  currentIncomeSummary,
  employmentSummary,
  petsSummary,
  vehiclesSummary,
  addressSummary,
  referenceSummary,
  identificationSummary,
  declarationSummary,
} from '../../helper/ProfileVerifyHelper';
import { getPercentage } from '../../helper/PercentageHelper';
import PersonalDetailsForm from './components/PersonalDetailsForm/PersonalDetailsForm';
import IntroductionForm from './components/IntroductionForm/IntroductionForm';
import CurrentIncomeForm from './components/CurrentIncomeForm/CurrentIncomeForm';
import EmploymentForm from './components/EmploymentForm/EmploymentForm';
import PetsForm from './components/PetsForm/PetsForm';
import VehiclesForm from './components/VehiclesForm/VehiclesForm';
import AddressForm from './components/AddressForm/AddressForm';
import ReferenceForm from './components/ReferencesForm/ReferencesForm';
import IdentificationForm from './components/IdentificationForm/IdentificationForm';
import DeclarationForm from './components/DeclarationForm/DeclarationForm';
import { SectionStatus } from './components/ProfileItem/ProfileItemConstants';
import { getProfileName, preOpenForm } from './ProfileUtils';
import backArrow from '../../assets/navigation/arrow-left.png';
import NTDBanner from '../../component/ntdBanner/NTDBanner';
import { canShowNTDBanner } from '../../helper/NTDHelper';
import { ApplicationsResponse } from '../../services/groups/Groups';

interface MatchProps {
  index?: string | undefined;
  formName?: ProfileForm;
}

interface ProfileProps extends RouteComponentProps<MatchProps> {
  profileFormDetails: ApplicantProfile;
  overviewDetails: OverviewDetails;
  secondaryProfiles: ApplicantProfile[];
  dependentProfiles: DependentProfile[];
  signatureError: Error | string | null;
  myAccountFlag: boolean;
  selectedState: AustraliaState | null;
  masterProfileData: SubmittedApplicationResponse;
  isPrefillHelperCalled: boolean;
  isUpdating: UpdatingType;
  isProfileStateChanged: boolean;
  isReferencesStateChanged: boolean;
  groupApplications: ApplicationsResponse | undefined;
  postSaveMasterProfile: (data: ApplicationState) => void;
  saveSignatureError: () => void;
  setAustraliaState: (data: AustraliaState) => void;
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null,
  ) => void;
  setPrefillHelperCalled: (isCalled: boolean) => void;
  refreshMasterProfile: () => void;
  postSaveReferencesProfile: (data: ApplicationState) => void;
  updateProfileChangesFlag: (shouldReset: boolean) => void;
  updateReferencesChangesFlag: (shouldReset: boolean) => void;
}

const Profile: FC<ProfileProps> = ({
  history,
  match,
  location,
  profileFormDetails,
  overviewDetails,
  secondaryProfiles,
  dependentProfiles,
  signatureError,
  myAccountFlag,
  selectedState,
  masterProfileData,
  isPrefillHelperCalled,
  isUpdating,
  isProfileStateChanged,
  isReferencesStateChanged,
  groupApplications,
  saveSignatureError,
  setAustraliaState,
  postSaveMasterProfile,
  prefillData,
  setPrefillHelperCalled,
  refreshMasterProfile,
  postSaveReferencesProfile,
  updateProfileChangesFlag,
  updateReferencesChangesFlag,
}) => {
  const classes = useStyles();
  const { index, formName } = match.params;
  const childFormRef = useRef<Formik<FormikValues> | null>();
  const sectionsRef = useRef<HTMLDivElement[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= SIZES.md);
  const [selected, setSelected] = useState<ProfileForm | null>(formName || null);
  const [isAbove18, setIsAbove18] = useState<boolean>(true);
  const [isDependent, setIsDependent] = useState<boolean | null>(null);
  const [formData, setFormData] = useState<ApplicantProfile | DependentProfile>(() => {
    if (index === undefined) {
      setIsDependent(false);
      return profileFormDetails;
    }
    if (secondaryProfiles && secondaryProfiles.length) {
      if (
        secondaryProfiles.find((s) =>
          s.personalDetails ? s.personalDetails.key === parseInt(index) : false,
        )
      ) {
        setIsDependent(false);
        return secondaryProfiles.find((s) =>
          s.personalDetails ? s.personalDetails.key === parseInt(index) : false,
        ) as ApplicantProfile;
      }
    }

    if (dependentProfiles && dependentProfiles.length) {
      if (dependentProfiles.find((s) => s.key === parseInt(index))) {
        setIsDependent(true);
        return dependentProfiles.find((s) => s.key === parseInt(index)) as DependentProfile;
      }
    }
    return new ApplicantProfile();
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (formName && formName !== selected) {
      if (formName === 'Menu') {
        setSelected(ProfileForm.PERSONAL_DETAILS_FORM);
      } else {
        setSelected(formName);
      }
    }
    if (formName && sectionsRef && sectionsRef.current) {
      setTimeout(() => {
        let ele = document.getElementById(formName)!;
        const y = ele.getBoundingClientRect().top + window.pageYOffset - 100;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 1000);
    }
  }, [formName, sectionsRef]);

  useEffect(() => {
    if (selected === null) {
      setSelected(preOpenForm(formData, isDependent));
    }
  }, [formData]);

  useEffect(() => {
    if (index === undefined) {
      setIsDependent(false);
      setFormData(profileFormDetails);
    } else {
      if (secondaryProfiles && secondaryProfiles.length) {
        if (
          secondaryProfiles.find((s) =>
            s.personalDetails ? s.personalDetails.key === parseInt(index) : false,
          )
        ) {
          setIsDependent(false);
          setFormData(
            secondaryProfiles.find((s) =>
              s.personalDetails ? s.personalDetails.key === parseInt(index) : false,
            ) as ApplicantProfile,
          );
        }
      } else if (dependentProfiles && dependentProfiles.length) {
        if (dependentProfiles.find((s) => s.key === parseInt(index))) {
          setIsDependent(true);
          setFormData(dependentProfiles.find((s) => s.key === parseInt(index)) as DependentProfile);
        }
      } else {
        setFormData(new ApplicantProfile());
      }
    }
  }, [profileFormDetails, secondaryProfiles]);

  const handleLocationChange = (state: string) => {
    const applicationState = store.getState();
    setAustraliaState(state as AustraliaState);
    (
      applicationState.masterProfile.masterProfileData as SubmittedApplicationResponse
    ).applicants[0].selectedState = state as AustraliaState;
    postSaveMasterProfile(applicationState);
  };

  const navigate = (nextSection: ProfileForm | null) => {
    if (index === undefined) {
      history.push(routes.myProfile.view(nextSection || ''));
    } else {
      history.push(routes.additionalApplicant.view(parseInt(index), nextSection || ''));
    }
  };

  // Keeping incase this is needed in the future
  // const handleNavClick = (nextSection: ProfileForm | null, goBack?: boolean) => {
  //   const navigateOutOfSection = () => {
  //     if (goBack) {
  //       if (myAccountFlag) {
  //         history.push(routes.home.view);
  //       } else {
  //         history.push(routes.applicationPreferences.new);
  //       }
  //     } else if (nextSection) {
  //       navigate(nextSection);
  //     }
  //   };
  //   if (selected === ProfileForm.DECLARATION_FORM) {
  //     navigateOutOfSection();
  //   } else if (childFormRef.current) {
  //     const { errors } = childFormRef.current.state;

  //     // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //     childFormRef.current.submitForm();

  //     if (Object.keys(errors).length === 0 && errors.constructor === Object) {
  //       childFormRef.current
  //         .submitForm()
  //         .then(() => {
  //           navigateOutOfSection();
  //         })
  //         .catch((err) => console.log('Profile Submit error', err));
  //     }
  //   } else {
  //     navigateOutOfSection();
  //   }
  // };

  const isChildAgeAbove18 = (date: any) => {
    if (index === undefined) {
      setIsAbove18(true);
    } else {
      setIsAbove18(CalculationHelper.isAbove18(date));
    }
  };

  useEffect(() => {
    const resize = () => {
      const isMobileSize = window.innerWidth <= SIZES.md;
      setIsMobile(isMobileSize);
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    if ((formData as DependentProfile).selectedDateOfBirth) {
      isChildAgeAbove18((formData as DependentProfile).selectedDateOfBirth);
    } else if (
      (formData as ApplicantProfile).personalDetails &&
      (formData as ApplicantProfile).personalDetails!.selectedDateOfBirth
    ) {
      isChildAgeAbove18((formData as ApplicantProfile).personalDetails!.selectedDateOfBirth);
    } else {
      isChildAgeAbove18(false);
    }
  }, []);

  useEffect(() => {
    if (!isPrefillHelperCalled && masterProfileData) {
      prefillData(masterProfileData, isUpdating !== false, false, null);
      setPrefillHelperCalled(true);
    } else if (!masterProfileData) {
      refreshMasterProfile();
    }
  }, [masterProfileData, isPrefillHelperCalled, isUpdating]);

  const setSelectedOnSuccess = (nextSection: ProfileForm | null) => {
    const applicationState = store.getState();
    if (
      isReferencesStateChanged &&
      (selected === ProfileForm.EMPLOYMENT_FORM ||
        selected === ProfileForm.ADDRESS_FORM ||
        selected === ProfileForm.REFERENCE_FORM)
    ) {
      postSaveReferencesProfile(applicationState);
      updateReferencesChangesFlag(false);
    } else if (isProfileStateChanged) {
      postSaveMasterProfile(applicationState);
      updateProfileChangesFlag(false);
    }
    if (selected === ProfileForm.DECLARATION_FORM) {
      if (myAccountFlag) {
        history.push(routes.home.view);
      } else {
        history.push(routes.applicationPreferences.new);
      }
    } else {
      setSelected(nextSection);
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.pageContainer}>
        {(index !== undefined || location.pathname.includes(routes.applicationMyProfile.new)) && (
          <div className={classes.backRow} onClick={() => history.goBack()}>
            <img className={classes.backArrow} src={backArrow} alt="back arrow" />
            <Text textVariant="link" size="l">
              {LABELS.BACK}
            </Text>
          </div>
        )}
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {index === undefined
              ? `${LABELS.TITLE} ${getProfileName(formData, isDependent)}!`
              : LABELS.ADDITIONAL_PROFILE}
          </div>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <Text textVariant="boxTitle">{LABELS.ABOUT}</Text>
            {myAccountFlag || !selectedState ? (
              <div className={classes.locationContainer}>
                <div className={classes.stateQuestion}>{LABELS.SELECT_STATE}</div>
                <div className={classes.stateButtonContainer}>
                  {STATES.map((state, idx) => (
                    <div
                      key={idx}
                      id={`${state}`}
                      className={`${
                        selectedState === state ? classes.selectedButton : classes.stateButton
                      }`}
                      onMouseDown={() => handleLocationChange(state)}
                    >
                      {state}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {selectedState && (
              <>
                <ProfileItem
                  id={ProfileForm.PERSONAL_DETAILS_FORM}
                  key={ProfileForm.PERSONAL_DETAILS_FORM}
                  title={ProfileForm.PERSONAL_DETAILS_FORM}
                  subtitle={personalDetailsSummary(
                    isDependent
                      ? (formData as any)
                      : (formData as ApplicantProfile).personalDetails,
                  )}
                  percentage={getPercentage(
                    personalDetailsPercentage(
                      isDependent
                        ? (formData as any)
                        : (formData as ApplicantProfile).personalDetails,
                      index === undefined,
                    ),
                  )}
                  status={isPersonalDetailsFilled(
                    isDependent
                      ? (formData as any)
                      : (formData as ApplicantProfile).personalDetails,
                  )}
                  active={selected}
                  setActive={(value: ProfileForm | null) => setSelected(value)}
                  formData={formData}
                  isDependent={isDependent}
                  content={
                    <PersonalDetailsForm
                      setChildFormRef={(ref: Formik<FormikValues> | null) => {
                        childFormRef.current = ref;
                      }}
                      history={history}
                      personalDetails={
                        isDependent
                          ? (formData as any)
                          : (formData as ApplicantProfile).personalDetails
                      }
                      index={index === undefined ? undefined : parseInt(index)}
                      setSelected={(nextSection: ProfileForm | null) =>
                        setSelectedOnSuccess(nextSection)
                      }
                      isAbove18={isAbove18}
                      setIsChildAgeAbove18={(date: any) => isChildAgeAbove18(date)}
                    />
                  }
                />
                {isAbove18 && (
                  <>
                    <ProfileItem
                      id={ProfileForm.INTRODUCTION}
                      key={ProfileForm.INTRODUCTION}
                      isDependent={isDependent}
                      title={ProfileForm.INTRODUCTION}
                      subtitle={introSummary((formData as ApplicantProfile).introDetails)}
                      percentage={getPercentage(
                        introPercentage((formData as ApplicantProfile).introDetails),
                      )}
                      status={isIntroFilled((formData as ApplicantProfile).introDetails)}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <IntroductionForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          introductionDetails={(formData as ApplicantProfile).introDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.CURRENT_INCOME_FORM}
                      key={ProfileForm.CURRENT_INCOME_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.CURRENT_INCOME_FORM}
                      subtitle={
                        (formData as ApplicantProfile).currentIncomeDetails
                          ? currentIncomeSummary(
                              (formData as ApplicantProfile).currentIncomeDetails!.incomes,
                            )
                          : 'No financial details recorded'
                      }
                      percentage={
                        (formData as ApplicantProfile).currentIncomeDetails
                          ? getPercentage(
                              currentIncomePercentage(
                                (formData as ApplicantProfile).currentIncomeDetails!.incomes,
                              ),
                            )
                          : 0
                      }
                      status={
                        (formData as ApplicantProfile).currentIncomeDetails
                          ? isCurrentIncomeDetailsFilled(
                              (formData as ApplicantProfile).currentIncomeDetails!.incomes,
                            )
                          : SectionStatus.INCOMPLETE
                      }
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <CurrentIncomeForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          currentIncomeDetails={(formData as ApplicantProfile).currentIncomeDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.EMPLOYMENT_FORM}
                      key={ProfileForm.EMPLOYMENT_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.EMPLOYMENT_FORM}
                      subtitle={employmentSummary((formData as ApplicantProfile).employmentDetails)}
                      percentage={getPercentage(
                        employmentPercentage(
                          (formData as ApplicantProfile).employmentDetails,
                          (formData as ApplicantProfile).currentIncomeDetails
                            ? (formData as ApplicantProfile).currentIncomeDetails!.incomes
                            : undefined,
                        ),
                      )}
                      status={isEmploymentDetailsFilled(
                        (formData as ApplicantProfile).employmentDetails,
                        (formData as ApplicantProfile).currentIncomeDetails
                          ? (formData as ApplicantProfile).currentIncomeDetails!.incomes
                          : undefined,
                      )}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <EmploymentForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          isMobile={isMobile}
                          employmentDetails={(formData as ApplicantProfile).employmentDetails}
                          currentIncomeDetails={(formData as ApplicantProfile).currentIncomeDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.PETS_FORM}
                      key={ProfileForm.PETS_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.PETS_FORM}
                      subtitle={petsSummary((formData as ApplicantProfile).petsDetails)}
                      percentage={getPercentage(
                        petsPercentage((formData as ApplicantProfile).petsDetails),
                      )}
                      status={isPetsDetailsFilled((formData as ApplicantProfile).petsDetails)}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <PetsForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          petsDetails={(formData as ApplicantProfile).petsDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.VEHICLES_FORM}
                      key={ProfileForm.VEHICLES_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.VEHICLES_FORM}
                      subtitle={vehiclesSummary((formData as ApplicantProfile).vehiclesDetails)}
                      percentage={getPercentage(
                        vehiclesPercentage((formData as ApplicantProfile).vehiclesDetails),
                      )}
                      status={isVehiclesDetailsFilled(
                        (formData as ApplicantProfile).vehiclesDetails,
                      )}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <VehiclesForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          vehiclesDetails={(formData as ApplicantProfile).vehiclesDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.ADDRESS_FORM}
                      key={ProfileForm.ADDRESS_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.ADDRESS_FORM}
                      subtitle={
                        (formData as ApplicantProfile).addressDetails
                          ? addressSummary((formData as ApplicantProfile).addressDetails!.addresses)
                          : 'None'
                      }
                      percentage={
                        (formData as ApplicantProfile).addressDetails
                          ? getPercentage(
                              addressPercentage(
                                (formData as ApplicantProfile).addressDetails!.addresses,
                              ),
                            )
                          : 0
                      }
                      status={
                        (formData as ApplicantProfile).addressDetails
                          ? isAddressDetailsFilled(
                              (formData as ApplicantProfile).addressDetails!.addresses,
                            )
                          : SectionStatus.INCOMPLETE
                      }
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <AddressForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          addressDetails={(formData as ApplicantProfile).addressDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.REFERENCE_FORM}
                      key={ProfileForm.REFERENCE_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.REFERENCE_FORM}
                      subtitle={referenceSummary((formData as ApplicantProfile).referencesDetails)}
                      percentage={getPercentage(
                        referencePercentage((formData as ApplicantProfile).referencesDetails),
                      )}
                      status={isReferenceDetailsFilled(
                        (formData as ApplicantProfile).referencesDetails,
                      )}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <ReferenceForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          referencesDetails={(formData as ApplicantProfile).referencesDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.IDENTIFICATION_FORM}
                      key={ProfileForm.IDENTIFICATION_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.IDENTIFICATION_FORM}
                      subtitle={identificationSummary(
                        (formData as ApplicantProfile).identificationDetails,
                      )}
                      percentage={getPercentage(
                        identificationPercentage(
                          (formData as ApplicantProfile).identificationDetails,
                        ),
                      )}
                      status={isIdentificationDetailsFilled(
                        (formData as ApplicantProfile).identificationDetails,
                      )}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <IdentificationForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          isMobile={isMobile}
                          formData={formData as ApplicantProfile}
                          identificationDetails={
                            (formData as ApplicantProfile).identificationDetails
                          }
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                        />
                      }
                    />
                    <ProfileItem
                      id={ProfileForm.DECLARATION_FORM}
                      key={ProfileForm.DECLARATION_FORM}
                      isDependent={isDependent}
                      title={ProfileForm.DECLARATION_FORM}
                      subtitle={declarationSummary(
                        (formData as ApplicantProfile).declarationDetails,
                      )}
                      percentage={getPercentage(
                        declarationPercentage((formData as ApplicantProfile).declarationDetails),
                      )}
                      status={isDeclarationDetailsFilled(
                        (formData as ApplicantProfile).declarationDetails,
                      )}
                      active={selected}
                      setActive={(value: ProfileForm | null) => setSelected(value)}
                      content={
                        <DeclarationForm
                          setChildFormRef={(ref: Formik<FormikValues> | null) => {
                            childFormRef.current = ref;
                          }}
                          declarationDetails={(formData as ApplicantProfile).declarationDetails}
                          index={index === undefined ? undefined : parseInt(index)}
                          setSelected={(nextSection: ProfileForm | null) =>
                            setSelectedOnSuccess(nextSection)
                          }
                          personalDetails={(formData as ApplicantProfile).personalDetails}
                          refId={formData.refId}
                        />
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className={classes.secondaryContainer}>
            <Text textVariant="boxTitle">{LABELS.FROM_THE_BLOG}</Text>
            {BLOGS.map((blog, idx) => (
              <BlogCard
                key={idx}
                title={blog.title}
                body={blog.body}
                link={blog.link}
                image={blog.image}
              />
            ))}
            {canShowNTDBanner(formData as ApplicantProfile) && (
              <NTDBanner
                variant={2}
                small
                applicantProfile={formData as ApplicantProfile}
                index={index === undefined ? undefined : parseInt(index)}
              />
            )}
          </div>
        </div>
        <SignatureErrorModal
          message={signatureError ? signatureError.toString() : null}
          handleClose={() => saveSignatureError()}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  groupApplications: state.groups.groupApplications,
  isProfileStateChanged: state.masterProfile.isProfileStateChanged,
  isReferencesStateChanged: state.masterProfile.isReferencesStateChanged,
  profileFormDetails: state.myProfileForm,
  secondaryProfiles: state.additionalPersonalDetailScreen.secondaryApplicantProfiles,
  dependentProfiles: state.additionalPersonalDetailScreen.dependentProfiles,
  overviewDetails: state.landingScreen.tenantDetails,
  signatureError: state.masterProfile.signatureError,
  myAccountFlag: state.myProfileForm.myAccountFlag,
  selectedState: (state.masterProfile.masterProfileData as SubmittedApplicationResponse)
    .applicants[0].selectedState,
  masterProfileData: state.masterProfile.masterProfileData!,
  isPrefillHelperCalled: state.dashboard.isPrefillHelperCalled,
  isUpdating: state.dashboard.isUpdating,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postSaveMasterProfile: (data: ApplicationState) => {
    dispatch(MasterProfileActions.postSaveMasterProfile(data));
  },
  saveSignatureError: () => {
    dispatch(MasterProfileActions.saveSignatureError(null));
  },
  setAustraliaState: (data: AustraliaState) => {
    dispatch(MasterProfileActions.setAustraliaState(data));
  },
  prefillData: (
    data: SubmittedApplicationResponse,
    isUpdating: boolean,
    propertyList: any,
    propertyRefId: string | null = null,
  ) => {
    prefillForms(data, dispatch, isUpdating, propertyList, propertyRefId);
  },
  setPrefillHelperCalled: (isCalled: boolean) => {
    dispatch(DashboardActions.setPrefillHelperCalled(isCalled));
  },
  refreshMasterProfile: () => {
    dispatch(LoginActions.refreshMasterProfile());
  },
  postSaveReferencesProfile: (data: ApplicationState) => {
    dispatch(MasterProfileActions.postSaveReferencesProfile(data));
  },
  updateProfileChangesFlag: (shouldReset: boolean) =>
    dispatch(MasterProfileActions.updateProfileChangesFlag(shouldReset)),
  updateReferencesChangesFlag: (isChanged: boolean) =>
    dispatch(MasterProfileActions.updateReferencesChangesFlag(isChanged)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
