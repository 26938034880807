/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */

interface StreetType {
  abbreviation: string;
  street_type: string;
}
export class StreetTypeHelper {
  public static readonly streetTypeList: StreetType[] = [
    {
      abbreviation: '',
      street_type: 'N/A',
    },
    {
      abbreviation: 'Accs',
      street_type: 'Access',
    },
    {
      abbreviation: 'Ally',
      street_type: 'Alley',
    },
    {
      abbreviation: 'Alwy',
      street_type: 'Alleyway',
    },
    {
      abbreviation: 'Ambl',
      street_type: 'Amble',
    },
    {
      abbreviation: 'App',
      street_type: 'Approach',
    },
    {
      abbreviation: 'Arc',
      street_type: 'Arcade',
    },
    {
      abbreviation: 'Artl',
      street_type: 'Arterial',
    },
    {
      abbreviation: 'Arty',
      street_type: 'Artery',
    },
    {
      abbreviation: 'Av',
      street_type: 'Avenue',
    },
    {
      abbreviation: 'Ave',
      street_type: 'Ave',
    },
    {
      abbreviation: 'Ba',
      street_type: 'Banan',
    },
    {
      abbreviation: 'Bend',
      street_type: 'Bend',
    },
    {
      abbreviation: 'Bwlk',
      street_type: 'Boardwalk',
    },
    {
      abbreviation: 'Bvd',
      street_type: 'Boulevard',
    },
    {
      abbreviation: 'Br',
      street_type: 'Brace',
    },
    {
      abbreviation: 'Brae',
      street_type: 'Brae',
    },
    {
      abbreviation: 'Brk',
      street_type: 'Break',
    },
    {
      abbreviation: 'Broadway',
      street_type: 'Broadway',
    },
    {
      abbreviation: 'Brow',
      street_type: 'Brow',
    },
    {
      abbreviation: 'Bypa',
      street_type: 'Bypass',
    },
    {
      abbreviation: 'Bywy',
      street_type: 'Byway',
    },
    {
      abbreviation: 'Cswy',
      street_type: 'Causeway',
    },
    {
      abbreviation: 'Ctr',
      street_type: 'Centre',
    },
    {
      abbreviation: 'Ch',
      street_type: 'Chase',
    },
    {
      abbreviation: 'Cir',
      street_type: 'Circle',
    },
    {
      abbreviation: 'Cct',
      street_type: 'Circuit',
    },
    {
      abbreviation: 'Crcs',
      street_type: 'Circus',
    },
    {
      abbreviation: 'Cl',
      street_type: 'Close',
    },
    {
      abbreviation: 'Con',
      street_type: 'Concourse',
    },
    {
      abbreviation: 'Cps',
      street_type: 'Copse',
    },
    {
      abbreviation: 'Cnr',
      street_type: 'Corner',
    },
    {
      abbreviation: 'Ct',
      street_type: 'Court',
    },
    {
      abbreviation: 'Ctyd',
      street_type: 'Courtyard',
    },
    {
      abbreviation: 'Cove',
      street_type: 'Cove',
    },
    {
      abbreviation: 'Cr',
      street_type: 'Crescent',
    },
    {
      abbreviation: 'Cres',
      street_type: 'Cres',
    },
    {
      abbreviation: 'Crst',
      street_type: 'Crest',
    },
    {
      abbreviation: 'Crss',
      street_type: 'Cross',
    },
    {
      abbreviation: 'Csac',
      street_type: 'Cul-de-sac',
    },
    {
      abbreviation: 'Cutt',
      street_type: 'Cutting',
    },
    {
      abbreviation: 'Dale',
      street_type: 'Dale',
    },
    {
      abbreviation: 'Dip',
      street_type: 'Dip',
    },
    {
      abbreviation: 'Dr',
      street_type: 'Drive',
    },
    {
      abbreviation: 'Dvwy',
      street_type: 'Driveway',
    },
    {
      abbreviation: 'Edge',
      street_type: 'Edge',
    },
    {
      abbreviation: 'Elb',
      street_type: 'Elbow',
    },
    {
      abbreviation: 'End',
      street_type: 'End',
    },
    {
      abbreviation: 'Ent',
      street_type: 'Entrance',
    },
    {
      abbreviation: 'Esp',
      street_type: 'Esplanade',
    },
    {
      abbreviation: 'Exp',
      street_type: 'Expressway',
    },
    {
      abbreviation: 'Fawy',
      street_type: 'Fairway',
    },
    {
      abbreviation: 'Folw',
      street_type: 'Follow',
    },
    {
      abbreviation: 'Ftwy',
      street_type: 'Footway',
    },
    {
      abbreviation: 'Form',
      street_type: 'Formation',
    },
    {
      abbreviation: 'Fwy',
      street_type: 'Freeway',
    },
    {
      abbreviation: 'Frtg',
      street_type: 'Frontage',
    },
    {
      abbreviation: 'Gap',
      street_type: 'Gap',
    },
    {
      abbreviation: 'Gateway',
      street_type: 'Gateway',
    },
    {
      abbreviation: 'Gdns',
      street_type: 'Gardens',
    },
    {
      abbreviation: 'Gte',
      street_type: 'Gate',
    },
    {
      abbreviation: 'Glde',
      street_type: 'Glade',
    },
    {
      abbreviation: 'Glen',
      street_type: 'Glen',
    },
    {
      abbreviation: 'Gra',
      street_type: 'Grange',
    },
    {
      abbreviation: 'Grn',
      street_type: 'Green',
    },
    {
      abbreviation: 'Gr',
      street_type: 'Grove',
    },
    {
      abbreviation: 'Hts',
      street_type: 'Heights',
    },
    {
      abbreviation: 'Hird',
      street_type: 'Highroad',
    },
    {
      abbreviation: 'Hwy',
      street_type: 'Highway',
    },
    {
      abbreviation: 'Hill',
      street_type: 'Hill',
    },
    {
      abbreviation: 'Intg',
      street_type: 'Interchange',
    },
    {
      abbreviation: 'Jnc',
      street_type: 'Junction',
    },
    {
      abbreviation: 'Key',
      street_type: 'Key',
    },
    {
      abbreviation: 'Landings',
      street_type: 'Landings',
    },
    {
      abbreviation: 'Ln',
      street_type: 'Lane',
    },
    {
      abbreviation: 'Lnwy',
      street_type: 'Laneway',
    },
    {
      abbreviation: 'Line',
      street_type: 'Line',
    },
    {
      abbreviation: 'Link',
      street_type: 'Link',
    },
    {
      abbreviation: 'Lkt',
      street_type: 'Lookout',
    },
    {
      abbreviation: 'Loop',
      street_type: 'Loop',
    },
    {
      abbreviation: 'Mall',
      street_type: 'Mall',
    },
    {
      abbreviation: 'Mndr',
      street_type: 'Meander',
    },
    {
      abbreviation: 'Mews',
      street_type: 'Mews',
    },
    {
      abbreviation: 'Mtwy',
      street_type: 'Motorway',
    },
    {
      abbreviation: 'Nook',
      street_type: 'Nook',
    },
    {
      abbreviation: 'Otlk',
      street_type: 'Outlook',
    },
    {
      abbreviation: 'Park',
      street_type: 'Park',
    },
    {
      abbreviation: 'Pde',
      street_type: 'Parade',
    },
    {
      abbreviation: 'Pl',
      street_type: 'Place',
    },
    {
      abbreviation: 'Pwy',
      street_type: 'Parkway',
    },
    {
      abbreviation: 'Pass',
      street_type: 'Pass',
    },
    {
      abbreviation: 'Psge',
      street_type: 'Passage',
    },
    {
      abbreviation: 'Path',
      street_type: 'Path',
    },
    {
      abbreviation: 'Pway',
      street_type: 'Pathway',
    },
    {
      abbreviation: 'Piaz',
      street_type: 'Piazza',
    },
    {
      abbreviation: 'Plza',
      street_type: 'Plaza',
    },
    {
      abbreviation: 'Pkt',
      street_type: 'Pocket',
    },
    {
      abbreviation: 'Pnt',
      street_type: 'Point',
    },
    {
      abbreviation: 'Port',
      street_type: 'Port',
    },
    {
      abbreviation: 'Prom',
      street_type: 'Promenade',
    },
    {
      abbreviation: 'Qdrt',
      street_type: 'Quadrant',
    },
    {
      abbreviation: 'Qys',
      street_type: 'Quays',
    },
    {
      abbreviation: 'Quay',
      street_type: 'Quay',
    },
    {
      abbreviation: 'Rmbl',
      street_type: 'Ramble',
    },
    {
      abbreviation: 'Rest',
      street_type: 'Rest',
    },
    {
      abbreviation: 'Rtt',
      street_type: 'Retreat',
    },
    {
      abbreviation: 'Rdge',
      street_type: 'Ridge',
    },
    {
      abbreviation: 'Rise',
      street_type: 'Rise',
    },
    {
      abbreviation: 'Rd',
      street_type: 'Road',
    },
    {
      abbreviation: 'Road East',
      street_type: 'Road East',
    },
    {
      abbreviation: 'Road North',
      street_type: 'Road North',
    },
    {
      abbreviation: 'Road South',
      street_type: 'Road South',
    },
    {
      abbreviation: 'Road West',
      street_type: 'Road West',
    },
    {
      abbreviation: 'Rty',
      street_type: 'Rotary',
    },
    {
      abbreviation: 'Rte',
      street_type: 'Route',
    },
    {
      abbreviation: 'Row',
      street_type: 'Row',
    },
    {
      abbreviation: 'Rue',
      street_type: 'Rue',
    },
    {
      abbreviation: 'Svwy',
      street_type: 'Serviceway',
    },
    {
      abbreviation: 'Shun',
      street_type: 'Shunt',
    },
    {
      abbreviation: 'Spur',
      street_type: 'Spur',
    },
    {
      abbreviation: 'Sq',
      street_type: 'Square',
    },
    {
      abbreviation: 'St',
      street_type: 'Street',
    },
    {
      abbreviation: 'Street East',
      street_type: 'Street East',
    },
    {
      abbreviation: 'Street North',
      street_type: 'Street North',
    },
    {
      abbreviation: 'Street South',
      street_type: 'Street South',
    },
    {
      abbreviation: 'Street West',
      street_type: 'Street West',
    },
    {
      abbreviation: 'Sbwy',
      street_type: 'Subway',
    },
    {
      abbreviation: 'Tarn',
      street_type: 'Tarn',
    },
    {
      abbreviation: 'Tce',
      street_type: 'Terrace',
    },
    {
      abbreviation: 'Thfr',
      street_type: 'Thoroughfare',
    },
    {
      abbreviation: 'Tlwy',
      street_type: 'Tollway',
    },
    {
      abbreviation: 'Top',
      street_type: 'Top',
    },
    {
      abbreviation: 'Tor',
      street_type: 'Tor',
    },
    {
      abbreviation: 'Trk',
      street_type: 'Track',
    },
    {
      abbreviation: 'Trl',
      street_type: 'Trail',
    },
    {
      abbreviation: 'Turn',
      street_type: 'Turn',
    },
    {
      abbreviation: 'Upas',
      street_type: 'Underpass',
    },
    {
      abbreviation: 'Vale',
      street_type: 'Vale',
    },
    {
      abbreviation: 'Viad',
      street_type: 'Viaduct',
    },
    {
      abbreviation: 'View',
      street_type: 'View',
    },
    {
      abbreviation: 'Vsta',
      street_type: 'Vista',
    },
    {
      abbreviation: 'Walk',
      street_type: 'Walk',
    },
    {
      abbreviation: 'Way',
      street_type: 'Way',
    },
    {
      abbreviation: 'Wkwy',
      street_type: 'Walkway',
    },
    {
      abbreviation: 'Whrf',
      street_type: 'Wharf',
    },
    {
      abbreviation: 'Woods',
      street_type: 'Woods',
    },
    {
      abbreviation: 'Wynd',
      street_type: 'Wynd',
    },
  ];

  public static extractStreetTypeAndName(street: string | undefined | null): {
    streetType: string;
    streetName: string;
  } {
    if (street) {
      const list = street.trim().split(' ');
      let lastValue = list.pop();
      const secondLastValue = list[list.length - 1];

      if (lastValue) {
        // TODO: For more street types that contain more than 1 noun, create a list.
        if (secondLastValue === 'Street' || secondLastValue === 'Road') {
          lastValue = `${secondLastValue} ${lastValue}`;
          list.pop();
        }

        const gotStreetType = StreetTypeHelper.streetTypeList.some(
          (e) =>
            e.abbreviation.toLowerCase() === lastValue!.toLowerCase() ||
            e.street_type.toLowerCase() === lastValue!.toLowerCase(),
        );
        const streetType = gotStreetType ? lastValue : '';
        const streetName = gotStreetType ? list[0] : street;
        return { streetType, streetName };
      }
    }
    return { streetType: '', streetName: '' };
  }

  public static getStreetTypeAbbreviation(streetType: string) {
    if (
      StreetTypeHelper.streetTypeList.some(
        (e) => e.abbreviation.toLowerCase() === streetType.toLowerCase(),
      )
    ) {
      return streetType;
    }
    const street = StreetTypeHelper.streetTypeList.find(
      (e) => e.street_type.toLowerCase() === streetType.toLowerCase(),
    );
    if (street) {
      return street.abbreviation;
    }
    return '';
  }

  public static getStreetTypeName(streetType: string) {
    if (
      StreetTypeHelper.streetTypeList.some(
        (e) => e.street_type.toLowerCase() === streetType.toLowerCase(),
      )
    ) {
      return streetType;
    }
    const street = StreetTypeHelper.streetTypeList.find(
      (e) => e.abbreviation.toLowerCase() === streetType.toLowerCase(),
    );
    if (street) {
      return street.street_type;
    }
    return '';
  }
}
