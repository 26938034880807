/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState, useEffect } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ErrorMessage, Formik } from 'formik';
import { History } from 'history';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PhoneInput from 'react-phone-number-input';
import additionalProfileIcon from '../../../../assets/person-add.svg';
import errorIcon from '../../../../assets/error-outline-24-px.svg';
import minusIcon from '../../../../assets/minus-outline.png';
import plusIcon from '../../../../assets/plus-green.png';
import MandatoryError from '../../../../component/errorPage/MandatoryError';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import UserConfirmationDialog from '../../../../component/userConfirmationDialog/UserConfirmationDialog';
import { SALUTATIONS } from '../../../../constants/AppConstants';
import localStorage from '../../../../services/LocalStorage';
import { routes } from '../../../../Routes';
import { ApplicationState } from '../../../../store/RootReducers';
import { ERRORS, LABELS, VALIDATION_SCHEMA } from './CoApplicantsFormConstants';
import { useStyles } from './CoApplicantsFormStyles';
import { isEmailSame, isGroupDuplicated } from './CoApplicantsFormUtils';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import {
  GroupResponse,
  AcceptedEnum,
  LimitedApplicantResponse,
} from '../../../../services/groups/Groups';
import { GroupsActions } from '../../../../store/actions/GroupsActions';
import { theme } from '../../../../theme/Theme';
import { TypeHelper } from '../../../../helper/TypeHelper';
import { UpdatingType } from '../../../../services/dashboard/updating/Updating';
import { SuccessToastActions } from '../../../../store/actions/ SuccessToastAction';
import Text from '../../../../component/text/Text';
import Box from '../../../../component/box/Box';
import Input from '../../../../component/input/Input';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import { stringToHslColor } from '../../../../helper/GravatarHelper';
import Button from '../../../../component/button/Button';
import { Selected } from '../../../editGroup/EditGroup';

interface CoApplicantsFormProps {
  parentGroupName: string;
  groupRefSelected: string | null;
  groups: GroupResponse[] | undefined;
  selected: Selected;
  showSavedToast: () => void;
  setManagingProfile?: (value: boolean) => void;
  postFormDetails: (
    data: CoApplicantFormData,
    onSubmit: () => void,
    selected: Selected,
    oldCoApplicantsData: OldCoApplicantData[],
    groupRefSelected: string,
    groupName: string,
  ) => void;
}

export interface CoApplicantFormData {
  coApplicants: LimitedApplicantResponse[];
}

interface OldCoApplicantData {
  oldEmail: string;
  newEmail?: string;
  shouldDelete: boolean;
  didUpdate: boolean;
}

const CoApplicantsForm: FC<CoApplicantsFormProps> = ({
  parentGroupName,
  groupRefSelected,
  groups,
  selected,
  showSavedToast,
  setManagingProfile,
  postFormDetails,
}) => {
  const classes = useStyles();
  const [isValidNumber, setIsValidNumber] = useState<boolean>(true);
  const [focusedCo, setFocusedCo] = useState<number | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmDialogContent, setConfirmDialogContent] = useState<{
    title: string;
    content: JSX.Element;
  }>({
    title: 'A new confirmation email has been sent!',
    content: (
      <div>Please verify your account by clicking the link in the email within 24 hours.</div>
    ),
  });
  const [oldCoApplicantsData, setOldCoApplicantsData] = useState<OldCoApplicantData[]>([]);
  const [groupName, setGroupName] = useState<string>(`My new group ${groups ? groups.length : 1}`);

  useEffect(() => {
    if (selected === Selected.newApplication) {
      setGroupName(parentGroupName);
    }
  }, [parentGroupName]);

  useEffect(() => {
    if (groups && selected === Selected.group) {
      const foundGroup = groups.find((group) => group.groupRefId === groupRefSelected)!;
      const result: OldCoApplicantData[] = [];

      for (let i = 0; i < foundGroup.coApplicants.length; i++) {
        result.push({
          oldEmail: foundGroup.coApplicants[i]!.primary.email!,
          shouldDelete: false,
          didUpdate: false,
        });
      }
      setOldCoApplicantsData(result);
    } else {
      setOldCoApplicantsData([]);
    }
  }, [selected, groups, groupRefSelected]);

  const postCoApplicantsForm = (formData: CoApplicantFormData) => {
    postFormDetails(
      formData,
      () => {
        showSavedToast();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      selected,
      oldCoApplicantsData,
      groupRefSelected!,
      groupName,
    );
  };

  const getCoApplicants = (group: GroupResponse): LimitedApplicantResponse[] => {
    const result: LimitedApplicantResponse[] = [];
    group.coApplicants.map((co) => {
      result.push(co.primary);
    });
    return result;
  };

  const updateOldCoApplicant = (coApplicant: LimitedApplicantResponse, email?: string) => {
    if (coApplicant.accepted !== AcceptedEnum.ACCEPTED || coApplicant.accepted !== undefined) {
      if (setManagingProfile) {
        setManagingProfile(true);
      }
      setOldCoApplicantsData(
        oldCoApplicantsData.map((co) =>
          co.oldEmail === coApplicant.email || co.newEmail === coApplicant.email
            ? { ...co, didUpdate: true, newEmail: email || '' }
            : co,
        ),
      );
    }
  };

  const initialValues: CoApplicantFormData =
    groups && selected === Selected.group
      ? {
          coApplicants: getCoApplicants(
            groups.find((group) => group.groupRefId === groupRefSelected)!,
          ),
        }
      : {
          coApplicants: [],
        };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(formData) => {
        if (groups && isGroupDuplicated(groups, formData, groupRefSelected, selected)) {
          setConfirmDialogContent({
            title: 'Duplicate Group',
            content: <div>This group already exists with the same people</div>,
          });
          setOpenConfirmDialog(true);
        } else {
          postCoApplicantsForm(formData);
        }
      }}
      validateOnChange
      validationSchema={VALIDATION_SCHEMA}
      render={({
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        dirty,
      }) => (
        <>
          <Text textVariant="title" parentStyles={classes.applicationCardsTitle}>
            {LABELS.COAPPLICANTS_TITLE}
          </Text>
          <Text size="l" textVariant="info">
            {LABELS.COAPPLICANTS_SUBTITLE}
          </Text>
          <div className={`${classes.howManyOthersContainer}`}>
            <div className={classes.counterContainer}>
              <Text size="l" parentStyles={classes.howManyOthers}>
                {LABELS.HOW_MANY_PEOPLE}
              </Text>
            </div>
            <div className={classes.counterWrapper}>
              <img
                src={minusIcon}
                alt="minus icon"
                className={classes.numberChangeStyle}
                style={
                  values.coApplicants.length ===
                  values.coApplicants.filter((co) => co.accepted === AcceptedEnum.ACCEPTED).length
                    ? { opacity: 0.3 }
                    : { opacity: 1 }
                }
                onClick={() => {
                  // To make sure we don't remove accepted applicants and if a new application, apply with no one
                  if (
                    values.coApplicants.length >
                    values.coApplicants.filter((co) => co.accepted === AcceptedEnum.ACCEPTED).length
                  ) {
                    const newList = values.coApplicants;
                    const itemToRemove = values.coApplicants.find(
                      (co) => co.accepted !== AcceptedEnum.ACCEPTED,
                    );

                    for (let i = newList.length - 1; i >= 0; i--) {
                      if (newList[i].accepted !== AcceptedEnum.ACCEPTED) {
                        newList.splice(i, 1);
                        break;
                      }
                    }

                    setFieldValue('coApplicants', newList);
                    if (setManagingProfile) {
                      setManagingProfile(true);
                    }
                    setOldCoApplicantsData(
                      oldCoApplicantsData.map((co) =>
                        co.oldEmail === itemToRemove!.email || co.newEmail === itemToRemove!.email
                          ? { ...co, shouldDelete: true, didUpdate: false }
                          : co,
                      ),
                    );
                  }
                }}
              />

              <Text size="l" textVariant="info" parentStyles={classes.counter}>
                {values.coApplicants.length}
              </Text>
              <img
                src={plusIcon}
                alt="plus icon"
                className={classes.numberChangeStyle}
                onClick={() => {
                  const newList = values.coApplicants;
                  const newApplicant: LimitedApplicantResponse = {
                    firstName: null,
                    email: null,
                    lastName: null,
                    title: null,
                    mobile: null,
                  };
                  newList.push(newApplicant);
                  setFieldValue('coApplicants', newList);
                  if (setManagingProfile) {
                    setManagingProfile(true);
                  }
                }}
              />
            </div>
          </div>

          {!!values.coApplicants.length && (
            <>
              {values.coApplicants.map((a: LimitedApplicantResponse, index: number) => (
                <Box parentStyles={classes.boxStyles}>
                  <>
                    <div className={classes.additionalPeopleTitleContainer}>
                      <div
                        className={classes.gravatar}
                        style={{
                          backgroundColor: stringToHslColor(
                            `${values.coApplicants[index].firstName || 'C'}${
                              values.coApplicants[index].lastName || 'O'
                            }`,
                            70,
                            70,
                          ),
                        }}
                      >
                        {values.coApplicants[index].firstName
                          ? values.coApplicants[index]!.firstName!.charAt(0)
                          : 'C'}
                        {values.coApplicants[index].lastName
                          ? values.coApplicants[index]!.lastName!.charAt(0)
                          : 'O'}
                      </div>
                      <div className={classes.coApplicantTitleContainer}>
                        <Text textVariant="title" parentStyles={classes.basicDetails}>
                          {LABELS.BASIC_DETAILS}
                        </Text>

                        <Text textVariant="info" size="l">
                          {values.coApplicants[index].firstName ||
                          values.coApplicants[index].lastName ? (
                            `${values.coApplicants[index].firstName} ${values.coApplicants[index].lastName}`
                          ) : (
                            <>Co-applicant {index + 1}</>
                          )}
                        </Text>
                      </div>
                      {values.coApplicants.length > 1 &&
                        values.coApplicants[index].accepted !== AcceptedEnum.ACCEPTED && (
                          <DeleteOutlinedIcon
                            style={{ width: 40, height: 40 }}
                            onClick={() => {
                              if (setManagingProfile) {
                                setManagingProfile(true);
                              }
                              setOldCoApplicantsData(
                                oldCoApplicantsData.map((co) =>
                                  co.oldEmail === values.coApplicants[index]!.email ||
                                  co.newEmail === values.coApplicants[index]!.email
                                    ? { ...co, shouldDelete: true, didUpdate: false }
                                    : co,
                                ),
                              );
                              const newCoApplicants = [...values.coApplicants];
                              newCoApplicants.splice(index, 1);
                              setFieldValue('coApplicants', newCoApplicants);
                            }}
                            className={classes.crossLogo}
                          />
                        )}
                    </div>
                    <div className={classes.basicDetailsRow}>
                      <div className={classes.titleAndFirstName}>
                        <InputSelect
                          placeholder={LABELS.SALUTATION}
                          value={values.coApplicants[index].title || ''}
                          values={SALUTATIONS.map((v) => ({ display: v, value: v }))}
                          setValue={(value: string) => {
                            setFieldValue(`coApplicants[${index}]title`, value);
                            updateOldCoApplicant(values.coApplicants[index]!);
                          }}
                          onBlur={() => setFieldTouched(`coApplicants[${index}]title`)}
                          touched={get(touched, `coApplicants[${index}]title`)}
                          error={get(errors, `coApplicants[${index}]title`)}
                          title={LABELS.SALUTATION}
                          mandatory
                          disabled={values.coApplicants[index].accepted === AcceptedEnum.ACCEPTED}
                        />
                        <Input
                          placeholder={LABELS.FIRSTNAME}
                          value={values.coApplicants[index].firstName || ''}
                          setValue={(value: string) => {
                            setFieldValue(`coApplicants[${index}]firstName`, value);
                            updateOldCoApplicant(values.coApplicants[index]!);
                          }}
                          onBlur={() => setFieldTouched(`coApplicants[${index}]firstName`)}
                          touched={get(touched, `coApplicants[${index}]firstName`)}
                          error={get(errors, `coApplicants[${index}]firstName`)}
                          title={LABELS.FIRSTNAME}
                          parentStyles={classes.secondInput}
                          mandatory
                          disabled={
                            values.coApplicants[index].accepted === AcceptedEnum.ACCEPTED ||
                            values.coApplicants[index].exists
                          }
                        />
                      </div>
                      <Input
                        placeholder={LABELS.SURNAME}
                        value={values.coApplicants[index].lastName || ''}
                        setValue={(value: string) => {
                          setFieldValue(`coApplicants[${index}]lastName`, value);
                          updateOldCoApplicant(values.coApplicants[index]!);
                        }}
                        onBlur={() => setFieldTouched(`coApplicants[${index}]lastName`)}
                        touched={get(touched, `coApplicants[${index}]lastName`)}
                        error={get(errors, `coApplicants[${index}]lastName`)}
                        title={LABELS.SURNAME}
                        parentStyles={classes.lastNameInput}
                        mandatory
                        disabled={
                          values.coApplicants[index].accepted === AcceptedEnum.ACCEPTED ||
                          values.coApplicants[index].exists
                        }
                      />
                    </div>
                    <Input
                      placeholder={LABELS.EMAIL_ADDRESS}
                      value={values.coApplicants[index].email || ''}
                      setValue={(value: string) => {
                        setFieldValue(`coApplicants[${index}]email`, value);
                        updateOldCoApplicant(values.coApplicants[index]!, value);
                      }}
                      onBlur={() => setFieldTouched(`coApplicants[${index}]email`)}
                      touched={get(touched, `coApplicants[${index}]email`)}
                      error={
                        get(errors, `coApplicants[${index}]email`)
                          ? get(errors, `coApplicants[${index}]email`)
                          : get(touched, `coApplicants[${index}]email`)
                          ? isEmailSame(values, index)
                          : get(errors, `coApplicants[${index}]email`)
                      }
                      title={LABELS.EMAIL_ADDRESS}
                      mandatory
                      disabled={values.coApplicants[index].accepted === AcceptedEnum.ACCEPTED}
                    />
                    <InputMobile
                      placeholder={LABELS.MOBILE}
                      value={values.coApplicants[index].mobile || ''}
                      setValue={(value: string) => {
                        setFocusedCo(index);
                        setIsValidNumber(!!(value && TypeHelper.validatePhone(value)));
                        setFieldValue(`coApplicants[${index}]mobile`, value || '');
                        updateOldCoApplicant(values.coApplicants[index]!);
                      }}
                      onBlur={() => setFieldTouched(`coApplicants[${index}]mobile`)}
                      touched={get(touched, `coApplicants[${index}]mobile`)}
                      error={
                        get(errors, `coApplicants[${index}]mobile`)
                          ? get(errors, `coApplicants[${index}]mobile`)
                          : !isValidNumber && focusedCo === index
                          ? ERRORS.invalidPhoneNumber
                          : get(errors, `coApplicants[${index}]mobile`)
                      }
                      title={LABELS.MOBILE}
                      mandatory
                      disabled={values.coApplicants[index].accepted === AcceptedEnum.ACCEPTED}
                    />
                    {values.coApplicants[index].accepted === AcceptedEnum.PENDING ||
                    values.coApplicants[index].accepted === undefined ? (
                      <div className={classes.invitationContainer}>
                        <MailOutlineIcon className={classes.mailIcon} />
                        <div className={classes.invitationText}>{LABELS.AN_INVITATION}</div>
                      </div>
                    ) : (
                      <div className={classes.invitationContainer}>
                        <CheckCircleOutlineIcon className={classes.mailIcon} />
                        <div className={classes.invitationText}>{LABELS.ACCEPTED}</div>
                      </div>
                    )}
                  </>
                </Box>
              ))}
            </>
          )}
          <div className={classes.buttonContainer}>
            {(!!dirty ||
              initialValues.coApplicants.length !== values.coApplicants.length ||
              (initialValues.coApplicants.length === 0 && values.coApplicants.length === 0)) && (
              <Button parentStyles={classes.primaryButton} onPress={() => handleSubmit()}>
                Save
              </Button>
            )}
          </div>
          <UserConfirmationDialog
            isOpen={openConfirmDialog}
            title={confirmDialogContent.title}
            content={confirmDialogContent.content}
            primaryButtonTitle="OK"
            onPrimaryClick={() => setOpenConfirmDialog(false)}
            onClose={() => setOpenConfirmDialog(false)}
          />
        </>
      )}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  groups: state.groups.groups,
  isUpdating: state.dashboard.isUpdating,
  isAcceptingInvitation: state.dashboard.isAcceptingInvitation,
  applicationState: state,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  postFormDetails: (
    data: CoApplicantFormData,
    onSubmit: () => void,
    selected: Selected,
    oldCoApplicantsData: OldCoApplicantData[],
    groupRefSelected: string,
    groupName: string,
  ) => {
    // if selected is a new application, that means create a new group
    if (selected === Selected.newApplication) {
      dispatch(
        GroupsActions.postCreateNewGroupRequest({
          coApplicants: data.coApplicants,
          groupName,
        }),
      );
    } else {
      // if selected is a group, well...
      oldCoApplicantsData.map((co) => {
        if (co.shouldDelete) {
          dispatch(
            GroupsActions.removeCoApplicantRequest({
              groupRefId: groupRefSelected,
              email: co.oldEmail,
            }),
          );
        } else if (co.didUpdate) {
          dispatch(
            GroupsActions.updateCoApplicantRequest({
              groupRefId: groupRefSelected,
              currentEmail: co.oldEmail,
              coApplicant: data.coApplicants.find(
                (newCo) => newCo.email === co.newEmail || newCo.email === co.oldEmail,
              )!,
            }),
          );
        }
      });

      if (data.coApplicants.length > oldCoApplicantsData.length) {
        const oldEmails: string[] = [];
        oldCoApplicantsData.map((old) => {
          oldEmails.push(old.oldEmail);
        });

        const toAdd: LimitedApplicantResponse[] = [];

        data.coApplicants.map((newCo) => {
          if (!oldEmails.includes(newCo.email!)) {
            toAdd.push({ ...newCo, groupRefId: groupRefSelected });
          }
        });
        if (toAdd.length) {
          dispatch(GroupsActions.addCoApplicantRequest(toAdd));
        }
      }
    }

    onSubmit();
    dispatch(GroupsActions.setSelectedGroupRef(groupRefSelected));
  },
  showSavedToast: () => dispatch(SuccessToastActions.show('Your group has been saved')),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoApplicantsForm);
