import React, { FC, useState, useEffect, useMemo } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LABELS, POWERED_BY_ROUTES } from './HeaderConstants';
import { useStyles } from './HeaderStyles';
import { ApplicationState } from '../../store/RootReducers';
import { SuccessActions } from '../../store/actions/SuccessActions';
import poweredByText from '../../assets/poweredByText.png';
import sortedLogoHeader from '../../assets/sortedLogoHeader.png';
import localStorage from '../../services/LocalStorage';
import { routes } from '../../Routes';
import { AGENCIES, NAX_LONG } from '../../AppNameHelper';
import { classNameGenerator } from '../../theme/GlobalStyles';
import profile from '../../assets/navigation/profile.png';
import {
  CustomTermsHeaderActions,
  CustomTermsHeaderPayload,
} from '../../store/actions/CustomTermsHeaderActions';
import { DashboardActions, StoreAgencyPayload } from '../../store/actions/DashboardActions';
import {
  containsAgencyCode,
  getAgencyFromMatchParams,
  getAllQueryParams,
  getMatchParams,
  MatchParams,
} from '../../helper/URLHelper';
import { Assets } from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { SearchActions } from '../../store/actions/SearchActions';
import SearchBar from '../searchBar/SearchBar';
import { ClickAwayListener } from '@mui/material';

interface HeaderProps extends RouteComponentProps {
  myAccountFlag: boolean;
  assets: Assets | null | undefined;
  agencyCode: string | null | undefined;
  branchCode: string | null | undefined;
  getAgencies: () => void;
  flushData: () => void;
  getTermsHeader: (data: CustomTermsHeaderPayload) => void;
  getAgencyByAgent: (agentId: string, type: string, branch?: string | null) => void;
  storePropertyAgency: (data: StoreAgencyPayload) => void;
  getPropertyDetails: (refId: string) => void;
  getPropertyByReaxml: (agentId: string, reaxml: string, type: string) => void;
  getPropertyByReaxmlSearch: (agentCode: string, reaxml: string) => void;
  getPropertyByAddress: (
    agentId: string,
    suburb: string,
    state: string,
    postcode: string,
    streetNumber: string,
    streetName: string,
    unitNumber: string,
  ) => void;
}

const Header: FC<HeaderProps> = ({
  history,
  location,
  match,
  assets,
  branchCode,
  agencyCode,
  myAccountFlag,
  getAgencies,
  flushData,
  getTermsHeader,
  getAgencyByAgent,
  storePropertyAgency,
  getPropertyDetails,
  getPropertyByReaxml,
  getPropertyByReaxmlSearch,
  getPropertyByAddress,
}) => {
  const classes = useStyles();

  const getIsPoweredBy = () => {
    if (
      window.location.origin.includes(NAX_LONG) ||
      POWERED_BY_ROUTES.some((pbr) => window.location.href.includes(pbr))
    )
      return true;
    return !(
      [routes.applications.view, routes.home.view, routes.groups.view, routes.login.view].some(
        (s) => location.pathname.includes(s),
      ) ||
      myAccountFlag ||
      agencyCode === '' ||
      agencyCode === AGENCIES.SORTED
    );
  };
  const [isPoweredBy, setIsPoweredBy] = useState(getIsPoweredBy());
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const isUserLoggedIn = !!(
    localStorage.getItem('userId') && localStorage.getItem('userId') !== 'undefined'
  );

  useEffect(() => {
    if (isUserLoggedIn) {
      getAgencies();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (
      location.pathname.includes('properties') ||
      location.pathname.includes('login') ||
      containsAgencyCode(location.pathname) ||
      window.location.origin.includes(NAX_LONG)
    ) {
      const matchParams = getMatchParams(match.params[0]);

      // SelectProperties logic
      const {
        agency,
        branch,
        refId,
        type,
        agentId,
        address,
        suburb,
        state,
        postcode,
        streetNumber,
        streetName,
        unitNumber,
      } = getAllQueryParams(location, matchParams);

      // This gets property details depending on URL
      if (refId && type === 't') {
        getPropertyDetails(refId);
      } else if (agentId && refId && (type === 'r' || type === 'd')) {
        getPropertyByReaxml(agentId, refId, type);
      } else if (agentId && refId && type === 'a') {
        getPropertyByReaxmlSearch(agentId.toUpperCase(), refId);
      } else if (agentId && address && suburb && state && postcode && streetNumber && streetName) {
        getPropertyByAddress(
          agentId,
          suburb,
          state,
          postcode,
          streetNumber,
          streetName,
          unitNumber,
        );
      }

      // Get agency assets and code
      if (agentId) {
        getAgencyByAgent(agentId, type);
      } else if (window.location.origin.includes(NAX_LONG)) {
        storePropertyAgency({ agency: AGENCIES.NAX });
      } else if (
        (agency && agency.toUpperCase() !== agencyCode) ||
        (branch && branch.toUpperCase() !== branchCode)
      ) {
        storePropertyAgency({
          agency: agency.toUpperCase(),
          branch: branch.toUpperCase(),
        });

        // check if the code is in the URL e.g AU_LUTN
      } else if (!refId && !type && !agentId) {
        const foundAgency = getAgencyFromMatchParams(matchParams.agencyCode, location.pathname);
        if (
          !!foundAgency &&
          foundAgency.toUpperCase() !== agencyCode &&
          foundAgency.toUpperCase() !== 'LOGIN'
        ) {
          storePropertyAgency({
            agency: getAgencyFromMatchParams(matchParams.agencyCode, location.pathname),
          });
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsPoweredBy(getIsPoweredBy());
  }, [location.pathname, myAccountFlag, agencyCode]);

  useMemo(() => {
    if ((!assets && agencyCode) || (assets && agencyCode && agencyCode !== assets.agencyLoaded)) {
      getAgencyByAgent(agencyCode, 'a', branchCode);
      getTermsHeader({ agency: agencyCode, branch: branchCode });
    }
  }, [agencyCode, branchCode, assets]);

  const signOut = () => {
    flushData();
    setMenuOpen(false);
    localStorage.setItem('userId', '');
    history.push(routes.login.view);
  };

  const goToProfile = () => {
    setMenuOpen(false);
    history.push(routes.myProfile.new);
  };

  return (
    <div className={classes.headerContainer}>
      <div
        className={classes.sortedLogoContainer}
        style={isUserLoggedIn ? { cursor: 'pointer' } : {}}
        onClick={() => {
          if (isUserLoggedIn) {
            history.push(routes.home.view);
          }
        }}
      >
        <img
          src={sortedLogoHeader}
          className={`${classes.sortedLogo} ${
            isPoweredBy ? classes.poweredBySortedLogo : undefined
          }`}
          alt="sorted-logo"
        />
        <img
          src={poweredByText}
          className={`${classes.sortedLogoText} ${
            isPoweredBy ? classes.poweredByLogoText : undefined
          }`}
          alt="sorted-logo-text"
        />
      </div>
      {![
        'signing',
        'disclosure',
        'shortApplication',
        'tenant-maintenance-request',
        'residentRequests',
      ].some((s) => location.pathname.includes(s)) && (
        <>
          {isUserLoggedIn ? (
            <div className={classes.rightContent}>
              {![routes.properties.generic].some((s) => location.pathname.includes(s)) && (
                <SearchBar history={history} />
              )}
              <div className={classes.divider} />
              <div
                className={classNameGenerator([
                  classes.profileContainer,
                  [routes.myProfile.new].includes(location.pathname) && classes.selected,
                ])}
                onClick={() => setMenuOpen(true)}
              >
                <img className={classes.profileIcon} src={profile} alt="profile" />
                <div className={classes.profileText}>{LABELS.YOU}</div>
              </div>
            </div>
          ) : (
            <div className={classes.rightContent}>
              <div className={classes.divider} />
              <div
                className={classNameGenerator([
                  classes.profileContainer,
                  [routes.login.view, '/'].includes(location.pathname) && classes.selected,
                ])}
                onClick={() => history.push(routes.login.view)}
              >
                <img className={classes.profileIcon} src={profile} alt="profile" />
                <div className={classes.profileText}>{LABELS.LOGIN}</div>
              </div>
            </div>
          )}
        </>
      )}
      {menuOpen && (
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
          <div className={classes.menu}>
            <div className={classes.menuItem} onClick={() => goToProfile()}>
              Profile
            </div>
            <div className={classes.menuDivider} />
            <div className={classes.menuItemLight} onClick={() => (window as any).Intercom('show')}>
              Help
            </div>
            <div className={classes.menuItemLight} onClick={() => signOut()}>
              Log out
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  myAccountFlag: state.myProfileForm.myAccountFlag,
  agencyCode: state.dashboard.agency,
  assets: state.dashboard.assets,
  branchCode: state.dashboard.branch,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAgencies: () => dispatch(SearchActions.getAgenciesRequest()),
  flushData: () => {
    dispatch(SuccessActions.flushData());
  },
  getAgencyByAgent: (agentId: string, type: string, branch?: string | null) => {
    dispatch(DashboardActions.getAgencyByAgent({ agentId, type, branch }));
  },
  getTermsHeader: (data: CustomTermsHeaderPayload) => {
    dispatch(CustomTermsHeaderActions.postCustomTermsHeaderStart(data));
  },
  storePropertyAgency: (data: StoreAgencyPayload) =>
    dispatch(
      DashboardActions.storePropertyAgency({ agency: data.agency, branch: data.branch || '' }),
    ),
  getPropertyDetails: (refId: string) => {
    dispatch(DashboardActions.doGetPropertyDetails(refId));
  },
  getPropertyByReaxml: (agentId: string, reaxmlId: string, type: string) => {
    dispatch(DashboardActions.getPropertyByReaxml({ agentId, reaxmlId, type }));
  },
  getPropertyByReaxmlSearch: (agencyCode: string, reaxmlId: string) => {
    dispatch(DashboardActions.getPropertyByReaxmlSearch({ agencyCode, reaxmlId }));
  },
  getPropertyByAddress: (
    agentId: string,
    suburb: string,
    state: string,
    postcode: string,
    streetNumber: string,
    streetName: string,
    unitNumber: string,
  ) => {
    dispatch(
      DashboardActions.getPropertyByAddress({
        agentId,
        suburb,
        state,
        postcode,
        streetNumber,
        streetName,
        unitNumber,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
