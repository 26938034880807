import { ContentTypeHelper, ContentCategory } from '../../../../helper/Content/ContentType.data';
import { TypeHelper } from '../../../../helper/TypeHelper';

export const LABELS = {
  DECLARATION: 'Declaration',
  UPDATE: 'Update Application',
  SYNC: 'Sync profile',
  UPDATING_TEXT: (single: boolean) =>
    `By confirming below, ${
      single ? 'this application' : 'all applications'
    } will receive the updated version of your profile`,
  DECLARE: 'I declare that:',
  PRIVACY_STATEMENT: 'PRIVACY STATEMENT',
  I_AGREE: 'I AGREE',
  LATER: "I'LL DO IT LATER",
  SIGN_HERE: 'To sign, please type your name',
  SIGN_AGAIN: 'Sign again',
  UPLOAD_SIGN: 'Upload Signature',
  AGREE_ON_SIGN: `Please sign here to indicate you agree`,
  UPDATE_SING: `You have added your signature previously. If you want, you can add your 
    signature by selecting the update button below`,
  CONFIRMATION_MESSGE: `I acknowledge the information provided in this application is true and correct and I have read, understood and agreed to the declaration and privacy statements. I confirm I am authorised to submit all profiles managed under my account and for co applicants I am inviting to apply with me.`,
  UPDATE_SIGNATURE: `Update Signature`,
  UPLOAD_IMAGE_GUIDELINE: `View image upload guidelines here`,
  ACCEPT: 'ACCEPT',
};
export enum SignatureOptions {
  SIGN = 'SIGN',
  UPLOAD = 'UPLOAD',
  UPDATE_SIGN_BUTTON = 'UPDATE_SIGN_BUTTON',
}
const maxSizeInBytes = 0x200000; // 2mb
const errorMessages = {
  maxFileSizeExceeded:
    'The file upload size is {size}MB. Please reduce the size and upload again. Max allowed size is 2MB.',
  missingFileType: 'Missing file types. Please select and upload again.',
  invalidFileType: 'The expected file types : {types}. Please select and upload again.',
};

export function validateFileUploadItem(file: File): string[] {
  const allowedFormats = ContentTypeHelper.getAllByCategory(ContentCategory.Image);
  const maxFileSize = maxSizeInBytes;
  const errors: string[] = [];
  const ctype = ContentTypeHelper.parseContentType(file.type);

  if (TypeHelper.isNullOrUndefined(ctype) || (allowedFormats && !allowedFormats.includes(ctype!))) {
    if (allowedFormats) {
      const types = allowedFormats
        .map((t) => ContentTypeHelper.getExtensions(t).join(', '))
        .join(', ');
      errors.push(`${file.name}: ${errorMessages.invalidFileType.replace('{types}', types)}`);
    } else {
      errors.push(`${file.name}: ${errorMessages.missingFileType}`);
    }
  }
  if (file.size > maxFileSize) {
    errors.push(
      `${file.name}: ${errorMessages.maxFileSizeExceeded.replace(
        '{size}',
        Math.floor(maxFileSize / 0x400).toString(),
      )}`,
    );
  }
  return errors;
}
