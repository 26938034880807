/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';

export const getBrowserVisibilityProp = (): string => {
  if (typeof (document as any).hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return '';
};

export const getBrowserDocumentHiddenProp = (): string => {
  if (typeof (document as any).hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return '';
};

export const getIsDocumentHidden = () => {
  return !document[getBrowserDocumentHiddenProp()];
};

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange: string = getBrowserVisibilityProp();

    (document as any).addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      (document as any).removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
};

export default usePageVisibility;
