import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';
import { SIZES } from '../../constants/AppConstants';
import { pageTitleContainer, pageContainer, actionGreen } from '../../theme/GlobalStyles';
import { getFonts } from '../../helper/GetFonts';

export const useStyles = makeStyles({
  content: {
    zIndex: 1,
    position: 'relative',
    width: '100%',
  },
  pageContainer: {
    position: 'relative',
    margin: 'auto',
    padding: 96,
    paddingTop: 0,
    width: 0,
    maxWidth: 1200,
    minWidth: 'calc(100% - 192px)',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      paddingLeft: 96,
      paddingRight: 24,
      minWidth: 'calc(100% - 120px)',
    },
    [`@media (max-width: ${SIZES.lg}px)`]: {
      paddingLeft: 48,
      paddingRight: 24,
      minWidth: 'calc(100% - 72px)',
    },
    [`@media (max-width: ${SIZES.md}px)`]: {
      paddingLeft: 12,
      paddingRight: 12,
      minWidth: 'calc(100% - 24px)',
    },
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100vh',
  },
  titleContainer: {
    ...pageTitleContainer,
    paddingTop: 24,
  },
  title: {
    color: theme.colors.title,
    fontSize: theme.font.xxxl,
    fontWeight: getFonts('Medium'),
  },
  loadingApplicationContainer: {
    marginBottom: theme.units.spacing * 4,
    height: 192,
  },
  filterContainer: {
    paddingTop: 96,
    width: 350,
    marginRight: 24,
    paddingRight: 24,
    borderRight: `${theme.colors.borderColor} 1px solid`,
  },
  resultsContainer: {
    width: '100%',
    paddingTop: 128,
  },
  resultsWrapper: {
    display: 'flex',
  },
  searchResults: {
    maxWidth: 640,
    width: '100%',
  },
  map: {
    marginLeft: theme.units.spacing * 6,
    width: '100%',
    height: 'calc(100vh - 430px)',
    borderRadius: theme.units.borderRadius,
  },
  filterSection: {
    marginBottom: theme.units.spacing * 12,
  },
  filterTitle: {
    fontWeight: getFonts('Medium'),
    fontSize: 18,
    marginBottom: theme.units.spacing * 4,
  },
  agencyPickerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  agencyPickerWrapper: {
    marginBottom: 0,
    maxWidth: 200,
    width: '100%',
    marginLeft: 'auto',
  },
  agency: {
    height: 40,
    width: 40,
    objectFit: 'contain',
    objectPosition: 'center',
    flexShrink: 0,
    borderRadius: 500,
    border: `${theme.colors.borderColorLight} 1px solid`,
    marginRight: theme.units.spacing * 3,
  },
  agencySearch: {
    width: '100%',
  },
  propertyTypes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.units.spacing * 3,
  },
  typeContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#E0E9E7',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  activeTypeContainer: {
    backgroundColor: theme.colors.secondary,
  },
  typeIcon: {
    marginTop: theme.units.spacing * 4,
    width: theme.units.iconSize * 1.2,
    height: theme.units.iconSize * 1.2,
    ...actionGreen,
  },
  activeTypeIcon: {
    filter: 'invert(100%)',
  },
  typeText: {
    marginTop: theme.units.spacing * 2,
    color: theme.colors.secondary,
    fontWeight: 500,
    fontSize: 18,
    marginBottom: theme.units.spacing * 3,
  },
  activeTypeText: {
    color: theme.colors.light,
  },
  postcode: {
    // marginBottom: -theme.units.spacing * 4,
  },
  priceRangeInputRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: -theme.units.spacing * 4,
  },
  secondInput: {
    marginLeft: theme.units.spacing * 4,
  },
  bedroomsBathroomsRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.units.spacing * 3,
  },
  bedrooms: {
    width: '100%',
  },
  bathrooms: {
    width: '100%',
  },
  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 25,
  },
  leftCounter: {
    width: 25,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.secondary,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    color: theme.colors.light,
    fontWeight: 600,
    cursor: 'pointer',
  },
  disabledCounter: {
    backgroundColor: theme.colors.darkGrey,
    opacity: 0.5,
    cursor: 'default',
  },
  middleCounter: {
    width: 35,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E0E9E7',
    color: theme.colors.secondary,
    fontWeight: 600,
  },
  rightCounter: {
    width: 25,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.secondary,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    color: theme.colors.light,
    fontWeight: 600,
    cursor: 'pointer',
  },
  boxContainer: {
    marginBottom: theme.units.spacing * 4,
  },
  applicationRow: {
    display: 'flex',
    alignItems: 'stretch',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
    },
  },
  propertyContainer: {
    marginRight: theme.units.spacing * 4,
    width: 160,
    height: 160,
    borderRadius: theme.units.borderRadius / 1.5,
    backgroundColor: theme.colors.lightGrey,
    flexShrink: 0,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginRight: 0,
      width: '100%',
      height: 200,
      marginBottom: theme.units.spacing * 2,
    },
  },
  property: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  applicationDetails: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'row',
  },
  applicationDetailsMiddle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginTop: theme.units.spacing * 2,
      marginBottom: theme.units.spacing * 4,
    },
  },
  applicationDetailsFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  propertyAddressContainer: {
    marginRight: 'auto',
  },
  address1: {
    marginBottom: theme.units.spacing * 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  address2: {},
  bookMark: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer',
  },
  statsRow: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
  },
  statTextRow: {
    display: 'flex',
    alignItems: 'center',
  },
  statNumber: {
    marginLeft: theme.units.spacing * 3,
    position: 'relative',
    top: 2,
  },
  statText: {
    position: 'relative',
    top: 2,
    marginLeft: theme.units.spacing * 1,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'none',
    },
  },
  rentRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.units.spacing * 4,
  },
  rent: {
    color: theme.colors.secondary,
    fontSize: 32,
    marginRight: theme.units.spacing * 2,
  },
  pw: {
    position: 'relative',
    top: 2,
    fontSize: 16,
    color: theme.colors.secondary,
  },
});
