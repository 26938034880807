import { makeStyles } from '@mui/styles';
import { theme } from '../../theme/Theme';

export const useStyles = makeStyles({
  cancelButtonContainer: {
    marginBottom: '0px',
  },
  titleContainerStyle: {
    paddingTop: '0px',
  },
  titleStyle: {
    textAlign: 'center',
    lineHeight: 1.29,
  },
  contentStyle: {
    color: theme.colors.black60,
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  buttonStyle: {
    height: '36px',
    width: '200px',
    marginBottom: '30px',
  },
  buttonsPosition: {
    justifyContent: 'center',
  },
});
