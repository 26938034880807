import {
  DeleteFilePayload,
  FileUploadResponse,
  FileUploadStartState,
  FileUploadSuccessState,
  MultiFileUploadStartState,
  MultiFileUploadSuccessState,
  ReferenceFileUploadStart,
} from '../../services/fileUpload/FileData';
import { FileData } from '../state/FileUploadState';

export enum FileUploadActionTypes {
  POST_FILE_UPLOAD_REQUEST = 'POST_FILE_UPLOAD_REQUEST',
  POST_FILE_UPLOAD_SUCCESS = 'POST_FILE_UPLOAD_SUCCESS',
  POST_FILE_UPLOAD_ERROR = 'POST_FILE_UPLOAD_ERROR',
  DELETE_FILE = 'DELETE_FILE',
  DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR = 'DELETE_FILE_ERROR',

  POST_MULTI_FILE_UPLOAD_REQUEST = 'POST_MULTI_FILE_UPLOAD_REQUEST',
  POST_MULTI_FILE_UPLOAD_SUCCESS = 'POST_MULTI_FILE_UPLOAD_SUCCESS',

  POST_REFERENCE_FILE_REQUEST = 'UPLOAD_REFERENCE_FILE_REQUEST',

  RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE',
}

export interface FileUploadAction {
  type: FileUploadActionTypes;
  data?:
    | FileUploadStartState
    | ReferenceFileUploadStart
    | FileUploadSuccessState
    | MultiFileUploadStartState
    | MultiFileUploadSuccessState
    | Error
    | string
    | DeleteFilePayload
    | FileUploadResponse[];
}

export class FileUploadActions {
  public static postFileUploadStart(data: FileUploadStartState): FileUploadAction {
    return {
      type: FileUploadActionTypes.POST_FILE_UPLOAD_REQUEST,
      data,
    };
  }
  public static postFileUploadSuccess(data: FileUploadSuccessState): FileUploadAction {
    return {
      type: FileUploadActionTypes.POST_FILE_UPLOAD_SUCCESS,
      data,
    };
  }
  public static postFileUploadError(error: Error): FileUploadAction {
    return {
      type: FileUploadActionTypes.POST_FILE_UPLOAD_ERROR,
      data: error,
    };
  }
  public static deleteFile(data: DeleteFilePayload): FileUploadAction {
    return {
      type: FileUploadActionTypes.DELETE_FILE,
      data,
    };
  }
  public static deleteFileSuccess(): FileUploadAction {
    return {
      type: FileUploadActionTypes.DELETE_FILE_SUCCESS,
      data: undefined,
    };
  }
  public static deleteFileError(error: Error): FileUploadAction {
    return {
      type: FileUploadActionTypes.DELETE_FILE_ERROR,
      data: error,
    };
  }

  public static postReferenceFileStart(token: string, data: File) {
    return { type: FileUploadActionTypes.POST_REFERENCE_FILE_REQUEST, data: { token, data } };
  }

  public static postMultiFileUploadStart(data: MultiFileUploadStartState) {
    return { type: FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_REQUEST, data };
  }

  public static postMultiFileUploadSuccess(data: MultiFileUploadSuccessState) {
    return { type: FileUploadActionTypes.POST_MULTI_FILE_UPLOAD_SUCCESS, data };
  }
  public static resetFileState() {
    return { type: FileUploadActionTypes.RESET_FILE_UPLOAD_STATE };
  }
}
