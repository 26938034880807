import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';

export const useStyles = makeStyles({
  root: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      backgroundColor: theme.colors.light,
    },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleStyle: {
    textAlign: 'center',
    padding: '35px 0 8px 0',
  },
  inputStyle: {
    paddingTop: '5px',
    paddingBottom: '10px',
    color: theme.colors.black87,
  },
  selectStyles: {
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  sectionTitle: {
    marginBottom: 0,
  },
  inputBoxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 6,
  },
  removeButton: {},
  removeLabelStyle: {
    color: '#F88379',
    fontSize: '12px',
    lineHeight: 0,
    padding: 3,
  },
  inputContainerStyle: {
    paddingBottom: '8px',
  },
  inputLabelStyle: {
    paddingLeft: '16px',
  },
  questionContainer: {
    marginRight: '-16px',
    height: '70px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addVehicleButtonContainer: {
    marginBottom: '30px',
    textAlign: 'center',
    border: `1px  solid ${theme.colors.borderColor}`,
  },
  addVehicleButton: {
    width: 'auto',
  },
  fieldDescription: {
    fontSize: '14px',
  },
  buttonContainer: {
    [`@media (min-width: ${SIZES.md}px)`]: {
      marginTop: '10px',
      textAlign: 'right',
    },
    marginTop: '8px',
    textAlign: 'center',
  },
  buttonText: {
    color: theme.colors.light,
  },
  laterbutton: {
    display: 'block',
    padding: '28px 0',
    [`@media (min-width: ${SIZES.md}px)`]: {
      display: 'none',
    },
  },
  laterLabelStyle: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  errorText: {
    color: theme.colors.error,
  },
  hide: {
    display: 'none',
  },
  extrabox: {
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.colors.borderColor} 2px solid`,
    borderRadius: '6px',
    padding: '20px 20px 20px 20px',
    marginBottom: '20px',
  },
  buttonStyles: {
    marginTop: theme.units.spacing * 4,
    marginLeft: 'auto',
    marginBottom: theme.units.spacing * 2,
  },
  fileInputTitle: {
    marginBottom: theme.units.spacing * 2,
    fontWeight: 500,
  },
  fileInputDescription: {
    marginBottom: theme.units.spacing * 2,
  },
});
