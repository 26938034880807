export const LABELS = {
  TITLE: 'Application submitted!',
  SUBTITLE:
    "Congratulations, you've submitted your application! Its with the agent for review. Take a look below at some things you can do while you wait",
  GET_STARTED: 'Get started',
  QUICK_START: 'What you can do while you wait',
  VIEW_OR_EDIT: 'View or edit your application anytime in your applications',
  CHECK_OUT: 'Check out other properties with our listed partners',
  CHECK_BLOGS: 'Check the Sorted blog for tips on what makes a great rental application',
};
