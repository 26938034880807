import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme/Theme';
import { SIZES } from '../../../../constants/AppConstants';
import { getFonts } from '../../../../helper/GetFonts';
import { actionGreen } from '../../../../theme/GlobalStyles';

export const useStyles = makeStyles({
  applicationsTitle: {
    marginTop: theme.units.spacing * 8,
    marginBottom: theme.units.spacing * 4,
  },
  applicationContainer: {
    marginBottom: theme.units.spacing * 8,
  },
  applicationRow: {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: theme.units.spacing * 4,
    paddingTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColorLight} 1px solid`,
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      flexDirection: 'column',
    },
  },
  propertyContainer: {
    marginRight: theme.units.spacing * 4,
    width: 160,
    height: 160,
    borderRadius: theme.units.borderRadius / 1.5,
    backgroundColor: theme.colors.lightGrey,
    flexShrink: 0,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lgMax}px)`]: {
      marginRight: 0,
      width: '100%',
      height: 200,
      marginBottom: theme.units.spacing * 2,
    },
  },
  property: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  applicationDetails: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'row',
  },
  applicationDetailsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridColumn: '1 / 1',
      gridRow: '3 / 4',
      flexDirection: 'row-reverse',
    },
  },
  applicationDetailsMiddle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridColumn: '1 / 1',
      gridRow: '1 / 2',
      marginBottom: theme.units.spacing * 2,
    },
  },
  applicationDetailsFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      gridColumn: '1 / 1',
      gridRow: '2 / 3',
      marginBottom: theme.units.spacing * 4,
    },
  },
  propertyAddressContainer: {
    marginRight: 'auto',
  },
  address1: {
    marginBottom: theme.units.spacing * 2,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  address2: {},
  rentAndAgencyContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  agencyContainer: {
    height: '70px',
    flexShrink: 0,
    borderRadius: 5000,
    overflow: 'hidden',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      height: '50px',
    },
  },
  agencyLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  rentContainer: {
    marginLeft: theme.units.spacing * 4,
    borderLeft: `${theme.colors.secondary} 1px solid`,
    paddingLeft: theme.units.spacing * 4,
  },
  rentRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.units.spacing * 2,
  },
  rent: {
    color: theme.colors.secondary,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      fontSize: 28,
    },
  },
  editIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    marginLeft: theme.units.spacing * 3,
    ...actionGreen,
  },
  rentInfo: {},
  statsRow: {
    display: 'grid',
    gridGap: theme.units.spacing * 4,
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
  },
  statIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
  },
  statTextRow: {
    display: 'flex',
    alignItems: 'center',
  },
  statNumber: {
    marginLeft: theme.units.spacing * 3,
    position: 'relative',
    top: 2,
  },
  statText: {
    position: 'relative',
    top: 2,
    marginLeft: theme.units.spacing * 1,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      display: 'none',
    },
  },
  hotContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  hotText: {
    marginRight: theme.units.spacing * 2,
  },
  hotIcon: {
    width: theme.units.iconSize,
    height: theme.units.iconSize,
    objectFit: 'contain',
    objectPosition: 'center',
    ...actionGreen,
  },
  statusContainer: {
    borderRadius: 500,
    minWidth: '120px',
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    color: theme.colors.black87,
  },
  unsuccessfulLabel: {
    backgroundColor: theme.colors.black,
  },
  yellowLabel: {
    backgroundColor: theme.colors.warning,
  },
  completeLabel: {
    backgroundColor: theme.colors.completeLabel,
  },
  incompleteLabel: {
    backgroundColor: theme.colors.black,
  },
  lastEditedDate: {
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginRight: 'auto',
    },
  },
  submissionRow: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  submittedDate: {
    marginRight: 'auto',
    marginLeft: theme.units.spacing * 4,
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      marginTop: theme.units.spacing * 2,
    },
  },
  gravatarsRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [`@media (max-width: ${SIZES.lg}px)`]: {
      marginLeft: 0,
      flexDirection: 'column-reverse',
    },
  },
  gravatars: {
    display: 'flex',
    marginBottom: theme.units.spacing,
    flexShrink: 0,
  },
  groupName: {
    color: theme.colors.black87,
    marginRight: theme.units.spacing * 4,
  },
  lineRow: {
    marginTop: theme.units.spacing * 4,
    borderTop: `${theme.colors.borderColor} 2px solid`,
    paddingTop: theme.units.spacing * 4,
    display: 'flex',
    alignItems: 'center',
  },
  buttonMarginRight: {
    marginRight: theme.units.spacing * 4,
  },
  actionContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionText: {
    marginRight: theme.units.spacing * 4,
  },
  actionIcon: {
    width: theme.units.iconSize / 1.5,
    height: theme.units.iconSize / 1.5,
    objectFit: 'contain',
    objectPosition: 'center',
    transition: 'all 0.5s ease 0s',
  },
  actionIconActive: {
    transform: 'rotate(180deg)',
    transition: 'all 0.5s ease 0s',
  },
  contactDetailsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: 'all 0.5s ease 0s',
    maxHeight: 0,
    overflow: 'hidden',
    opacity: 0,
  },
  contactDetailsRowActive: {
    marginTop: theme.units.spacing * 4,
    maxHeight: 80,
    transition: 'all 0.5s ease 0s',
    opacity: 1,
  },
  contactDetail: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.units.spacing * 8,
  },
  loadingApplicationContainer: {
    marginBottom: theme.units.spacing * 8,
    height: 200,
  },
});
