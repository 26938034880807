import moment from 'moment';
import { RentalFrequency } from '../services/remoteSigning/lease/Lease';

export interface LeaseDuration {
  months?: number | null;
  weeks?: number | null;
  days: number | undefined | null;
}

export function convertDurationUiToApi(leaseDuration: LeaseDuration): number | undefined {
  if (!leaseDuration.weeks && !leaseDuration.days) {
    return undefined;
  }
  return (leaseDuration.weeks || 0) * 7 + (leaseDuration.days || 0);
}

export function convertDurationApiToUi(
  durationInDays: number | undefined | null,
  frequency?: RentalFrequency | string | null,
): LeaseDuration {
  if (!durationInDays) {
    return { weeks: undefined, days: 0 };
  } // default value for days should be 0
  if (!!frequency && frequency === RentalFrequency.Monthly)
    return {
      months: Math.trunc(durationInDays / 30), // trunc to get rid of decimal values
      days: durationInDays % 30,
    };
  return {
    weeks: Math.trunc(durationInDays / 7), // trunc to get rid of decimal values
    days: durationInDays % 7,
  };
}

export function getDuration(leaseStartDate: string, leaseEndDate: string): LeaseDuration {
  if (leaseStartDate && leaseEndDate) {
    return convertDurationApiToUi(moment(leaseEndDate).diff(moment(leaseStartDate), 'days') + 1);
  }
  return { days: null, weeks: null };
}
