import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

export interface SignaturePadProps {
  readonly width?: string | number | undefined;
  readonly height?: string | number | undefined;
  readonly contentBase64: string | undefined | null; // png
  readonly remoteAppointment?: boolean;
}

export interface SignaturePadActions {
  readonly onChange?: ((contentBase64: string) => void) | undefined | null;
}

type OwnProps = SignaturePadProps & SignaturePadActions;

/// <summary>
/// https://www.npmjs.com/package/react-signature-canvas
/// </summary>
export class SignaturePad extends React.Component<OwnProps, {}> {
  private sigCanvas: SignatureCanvas;

  public componentWillReceiveProps(nextProps: OwnProps) {
    if (nextProps.contentBase64 !== this.props.contentBase64) {
      if (!nextProps.contentBase64) {
        this.sigCanvas.clear();
      }
    }
  }

  public componentDidMount() {
    const { contentBase64 } = { ...this.props };

    if (contentBase64) {
      this.sigCanvas.fromDataURL(contentBase64);
    }
  }

  public render(): JSX.Element | null {
    const { onChange, height, width } = { ...this.props };

    // gives error when testing
    return (
      <SignatureCanvas
        ref={(elem: SignatureCanvas) => (this.sigCanvas = elem)}
        canvasProps={{ style: { height, width } }}
        onEnd={() => {
          if (onChange) {
            onChange(this.sigCanvas.toDataURL('image/png'));
          }
        }}
        clearOnResize={false}
      />
    );
  }
}
