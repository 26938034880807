import { ServiceType } from '../ServiceType.data';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import {
  EquifaxRequest,
  EquifaxResponse,
  EquifaxRetryPaymentRequest,
} from '../../models/equifax/equifax';

export class EquifaxService {
  private readonly serviceType: ApiServiceBase = new ApiService(ServiceType.Equifax);

  public getNtdCheck(payload: EquifaxRequest): Promise<EquifaxResponse> | EquifaxResponse {
    return this.serviceType.post<EquifaxResponse>({}, payload);
  }

  public retryPaymentNtdCheck(
    payload: EquifaxRetryPaymentRequest,
  ): Promise<EquifaxResponse> | EquifaxResponse {
    return this.serviceType.post<EquifaxResponse>({ route: ['retry-payment'] }, payload);
  }
}

const equifaxService = new EquifaxService();
export default equifaxService;
