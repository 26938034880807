import MasterProfileState from '../state/MasterProfileState';
import { SubmittedApplicationResponse } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { MasterProfileAction, MasterProfileActionTypes } from '../actions/MasterProfileActions';
import { AustraliaState } from '../../services/dashboard/australiaStates/australiaStates';

export const initialState: MasterProfileState = {
  masterProfileData: null,
  masterProfileFailure: null,
  saveAsMasterProfileFailure: null,
  isProfileStateChanged: false,
  isReferencesStateChanged: false,
  saveReferencesProfileFailure: null,
  signatureError: null,
  currentMasterProfileVersion: 0,
};

/* tslint:disable:cyclomatic-complexity */
export const masterProfileReducer: (
  state: MasterProfileState | undefined,
  action: MasterProfileAction,
) => MasterProfileState = (state = initialState, action) => {
  switch (action.type) {
    case MasterProfileActionTypes.SET_MASTER_PROFILE: {
      return {
        ...state,
        masterProfileData: action.data as SubmittedApplicationResponse,
      };
    }
    case MasterProfileActionTypes.GET_MASTER_PROFILE_ERROR: {
      const error = action.data ? action.data.toString() : null;
      return {
        ...state,
        masterProfileFailure: error,
      };
    }
    case MasterProfileActionTypes.POST_SAVE_AS_MASTER_PROFILE_ERROR: {
      const error = action.data ? action.data.toString() : null;
      return {
        ...state,
        saveAsMasterProfileFailure: error,
      };
    }
    case MasterProfileActionTypes.PROFILE_STATE_CHANGED: {
      return {
        ...state,
        isProfileStateChanged: !!action.data,
      };
    }
    case MasterProfileActionTypes.REFERENCES_STATE_CHANGED: {
      return {
        ...state,
        isReferencesStateChanged: !!action.data,
      };
    }
    case MasterProfileActionTypes.POST_SAVE_REFERENCES_PROFILE_ERROR: {
      const error = action.data ? action.data.toString() : null;
      return {
        ...state,
        saveReferencesProfileFailure: error,
      };
    }
    case MasterProfileActionTypes.POST_SAVE_SIGNATURE_ERROR: {
      const error = action.data ? action.data.toString() : null;
      return {
        ...state,
        signatureError: error,
      };
    }
    case MasterProfileActionTypes.SET_CURRENT_MASTER_PROFILE_VERSION: {
      return {
        ...state,
        currentMasterProfileVersion: action.data as number,
      };
    }
    case MasterProfileActionTypes.SET_AUSTRALIA_STATE: {
      const { masterProfileData } = state;
      (masterProfileData as SubmittedApplicationResponse).applicants[0].selectedState = action.data as AustraliaState | null;
      return {
        ...state,
        masterProfileData,
      };
    }
    default:
      return state;
  }
};

export default masterProfileReducer;
